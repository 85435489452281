import React from "react";
import Avatar from "components/Avatar/Avatar.jsx";
import { AccessTime } from "@material-ui/icons";
import { withApollo } from "react-apollo";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import logStyle from "./ProjectLogStyle";
import { Redirect } from "react-router-dom";

class LogLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false
    };
    this.unsubscribe = null;
  }

  /** [
        'project-invite',
        'project-removal',
        'user-change',
        'content-change',
        'content-addition',
        'content-comment',
        'juridical-change',
        'juridical-addition',
        'juridical-comment',
        'document-addition',
    ] */
  createLogTimestamp(log) {
    return moment(log.creationDate).format("DD/MM/YY hh:mm");
  }
  logRedirect(log) {
    let redirectUrl = "";
    // Comentado por hora porque nenhuma acao gera uma notificacao que precise ir para
    // lista de conteudos, mas é possivel que isso mude.
    // let contentSubfolder = "0";
    // let contentFolder = "0";

    // if(!log.juridical && log.content.folder.parentFolder){
    //     contentSubfolder = log.content.folder.id;
    //     contentFolder = log.content.folder.parentFolder.id;
    // } else if(!log.juridical){
    //     contentFolder = log.content.folder.id;
    // }

    // if(log.juridical && log.content.juridicalFolder.parentFolder){
    //     contentSubfolder = log.content.juridicalFolder.id;
    //     contentFolder = log.content.juridicalFolder.parentFolder.id;
    // } else if(log.juridical){
    //     contentFolder = log.content.juridicalFolder.id;
    // }

    switch (log.type) {
      case "project-invite":
        redirectUrl = `/project/${log.project.id}/contents/0/0`;
        break;
      case "project-removal":
        redirectUrl = "/admin/projects";
        break;
      case "user-change":
        redirectUrl = "/admin/perfil";
        break;
      case "content-change":
        redirectUrl = `/content/view/${log.content.id}`;
        break;
      case "content-addition":
        redirectUrl = `/content/view/${log.content.id}`;
        break;
      case "content-comment":
        redirectUrl = `/content/view/${log.content.id}`;
        break;
      case "juridical-change":
        redirectUrl = `/content/juridical-view/${log.content.id}`;
        break;
      case "juridical-addition":
        redirectUrl = `/content/juridical-view/${log.content.id}`;
        break;
      case "juridical-comment":
        redirectUrl = `/content/juridical-view/${log.content.id}`;
        break;
      case "document-addition":
        redirectUrl = `/content/juridical-view/${log.content.id}`;
        break;
      default:
        break;
    }
    this.setState({ isRedirected: redirectUrl });
  }

  render() {
    const { classes, log } = this.props;
    const { isRedirected } = this.state;

    let logImage = "";

    if (log.agent && log.agent.image) logImage = log.agent.image.imageUrl;
    else if (log.image) logImage = log.image.imageUrl;

    return (
      <div className={classes.logBody}>
        {isRedirected && <Redirect from={"/"} to={isRedirected} />}
        <span className={classes.logImage}>
          <Avatar img={logImage} userId={log.agent ? log.agent.id : ""} />
        </span>
        <div
          className={classes.logWrapper}
          onClick={async e => {
            //stops event from bubbling to other components and triggering their functions
            e.stopPropagation();
            this.logRedirect(log);
          }}
        >
          <div className={classes.logText}>
            <span className={classes.logDescription}> {log.text} </span>
          </div>
          <div className={classes.logFooter} key={log.id}>
            <AccessTime classes={{ root: classes.accessTime }} />
            {this.createLogTimestamp(log)}
          </div>
        </div>
      </div>
    );
  }
}
export default withApollo(withStyles(logStyle)(LogLine));
