import {
  whiteColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

const pagesStyle = theme => ({
  wrapper: {
    height: "100%",
    position: "relative",
    top: "0"
  },
  fullPage: {
    padding: "120px 0",
    position: "relative",
    minHeight: "100vh",
    display: "flex!important",
    margin: "0",
    border: "0",
    color: whiteColor,
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      minHeight: "fit-content!important"
    },
  },
  content: {
    height: '100%',
  },
  container: {
    height: '100%',
  }
});

export default pagesStyle;
