
const style = {
  root: {
    minHeight: '350px',
    padding: '10px 20px',
    width: '500px',
    backgroundColor: 'white',
    margin: '0 auto',
    marginTop: '100px',
    overflow: 'visible'
  },
  header: {
    padding: '20px',
    width: '100%',
    textAlign: 'center'
  },
  title: {
    color: '#3F2C78',
    fontWeight: '300',
    fontSize: '26px',
    width: '100%',
    textAlign: 'center',
    marginTop: 10,
  },
  subtitle: {
    fontSize: 16,
    color: '#7d7d7d',
    fontWeight: 400,
    marginTop: 30,
    textAlign: 'center',
  },
  manager: {
    width: '100%',
    position: 'relative',
    maxWidth: '350px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '50px'
  },
  saveBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 40px',
    marginTop: '50px'
  },
  scrollableModal: {
    overflowY: 'auto',
  },
  dropDownButtonText: {
    fontFamily: "Roboto, sans-serif !important",
    fontWeight: "600",
    fontSize: '14px'
  },
  dropDown: {
    backgroundColor: 'transparent',
    color: '#AAAAAA',
    boxShadow: 'none',
    padding: "10px 5px 5px 0px",
    borderBottom: '1px solid #D2D2D2',
    maxWidth: '400px',
    borderRadius: 0,
    width: '100%',
    marginBottom: '20px',
    textTransform: 'initial',
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: '#AAAAAA',
      boxShadow: 'none',
    },
  },
  buttonTextWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%',
  },
}

export default style;