import React from "react";
import { withStyles, Tooltip } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ReactTable from "react-table";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import AddCircle from "@material-ui/icons/AddCircle";
import UsersAndPermissionsStyle from "../UsersAndPermissionsStyle.js";
import Menu from "@material-ui/core/Menu";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import RoleConfig from "components/Role/RoleConfig/RoleConfig";
import RoleBadge from "../../../components/Role/RoleBadge/RoleBadge.jsx";
import { gql } from "apollo-boost";
import { MY_ORG_QUERY_WITH_ROLES_MEMBERS_AND_COLLABORATORS } from "queries/organizationQueries";
import { withApollo } from "react-apollo";
import EditRoleModal from "../../../components/Role/RoleConfig/EditRoleModal.jsx";
import VerificationDialog from "components/VerificationDialog/VerificationDialog";

const DELETE_ROLE_MUTATION = gql`
  mutation DELETE_ROLE_MUTATION($id: ID!) {
    deleteRole(id: $id) {
      id
      name
    }
  }
`;

class PermissionsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      moreVertOpen: null,
      anchorMoreVert: null
    };
  }

  async deleteRole(roleId, numberOfUsers, numberOfCollaborations) {
    try {
      await this.props.client.mutate({
        mutation: DELETE_ROLE_MUTATION,
        variables: { id: roleId },
        refetchQueries: () => [
          { query: MY_ORG_QUERY_WITH_ROLES_MEMBERS_AND_COLLABORATORS }
        ]
      });
    } catch (error) {
      if (
        error.message ===
        "GraphQL error: Cannot remove a role currently in use."
      )
        window.alert(
          `Não é possível deletar o cargo porque ele é usado por ${numberOfUsers} usuários e ${numberOfCollaborations} colaborações.`
        );
    }
  }

  getOptions = ({ currentRow, role }) => {
    const { classes } = this.props;
    const {
      moreVertOpen,
      anchorMoreVert,
      activeRow,
      editRoleModalOpen
    } = this.state;
    const moreOptions = [
      {
        name: "Deletar",
        func: async () =>
          this.deleteRole(
            role.id,
            role.numberOfUsers,
            role.numberOfCollaborations
          )
      },
      {
        name: "Editar Cargo",
        func: () =>
          this.setState({ editingWarning: currentRow, activeRow: currentRow })
      }
    ];
    return (
      <div>
        {this.state.editingWarning === currentRow && (
          <VerificationDialog
            open={this.state.editingWarning === currentRow}
            title={`Você tem certeza que deseja alterar este cargo? Ele é usado por ${
              role.numberOfUsers
            } usuários e ${role.numberOfCollaborations} colaborações.`}
            handleClose={() => this.setState({ editingWarning: null })}
            onAccept={() => {
              this.setState({ editingWarning: null });
              this.setState({
                selectedEditRole: role,
                editRoleModalOpen: true
              });
            }}
          />
        )}
        <IconButton
          aria-label="More"
          aria-haspopup="true"
          onClick={e => {
            e.stopPropagation();
            if (e)
              this.setState({
                anchorMoreVert: e.currentTarget,
                moreVertOpen: currentRow
              });
          }}
          classes={{ root: classes.iconButton }}
        >
          <div className="">
            <MoreVertIcon />
          </div>
        </IconButton>
        {editRoleModalOpen && activeRow === currentRow && (
          <EditRoleModal
            selectedRole={role}
            open={editRoleModalOpen}
            toggleModal={() => {
              this.setState({
                editRoleModalOpen: !editRoleModalOpen,
                moreVertOpen: -1
              });
            }}
          />
        )}
        {!editRoleModalOpen && (
          <Menu
            id="long-menu"
            open={moreVertOpen === currentRow}
            anchorEl={anchorMoreVert}
            onClose={e => {
              e.stopPropagation();
              this.setState({ moreVertOpen: -1 });
            }}
            PaperProps={{
              style: {
                maxHeight: 45 * 4.5,
                width: 150,
                marginTop: 20,
                marginLeft: -50,
                textAlign: "center"
              }
            }}
          >
            <MenuList classes={{ root: classes.menuList }}>
              {moreOptions.map((option, index) => (
                <MenuItem
                  key={index}
                  onClick={async () => await option.func({ id: role.id, role })}
                >
                  <div style={{ display: "flex" }}>
                    <Icon className={classes.iconMenuList}>{option.icon}</Icon>
                    <div className="optionText"> {option.name} </div>
                  </div>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
      </div>
    );
  };

  toggleModal = () => {
    const { isModalOpen } = this.state;
    this.setState({ isModalOpen: !isModalOpen });
  };

  listPermissions({ permissions, area }) {
    let permissionString = "";
    if (area === "content") {
      if (permissions.includes("read-content"))
        permissionString += permissionString ? "/ Acessar " : "Acessar ";
      if (permissions.includes("create-content"))
        permissionString += permissionString ? "/ Criar " : "Criar ";
      if (permissions.includes("edit-content"))
        permissionString += permissionString ? "/ Editar " : "Editar ";
      if (permissions.includes("edit-content-status"))
        permissionString += permissionString ? "e Aprovar " : "Aprovar ";
      return permissionString ? permissionString : "N/A";
    }
    if (area === "juridical") {
      if (permissions.includes("read-juridical"))
        permissionString += permissionString
          ? "/ Acessar e Criar "
          : "Acessar e Criar ";
      if (permissions.includes("edit-juridical"))
        permissionString += permissionString
          ? "/ Editar e Deletar "
          : "Editar e Deletar ";
      if (permissions.includes("edit-juridical-status"))
        permissionString += permissionString ? "/ Aprovar " : "Aprovar ";
      return permissionString ? permissionString : "N/A";
    }
    if (area === "organization") {
      if (permissions.includes("edit-user"))
        permissionString += permissionString
          ? "/ Alterar Cargo "
          : "Alterar Cargo ";
      if (permissions.includes("edit-organization"))
        permissionString += permissionString
          ? "/ Excluir Usuário "
          : "Excluir Usuário ";
      return permissionString ? permissionString : "N/A";
    }
    if (area === "planning") {
      if (permissions.includes("read-planning"))
        permissionString += permissionString ? "/ Acessar " : "Acessar ";
      if (permissions.includes("edit-planning"))
        permissionString += permissionString ? "/ Editar " : "Editar ";
      return permissionString ? permissionString : "N/A";
    }
  }

  wrapTooltip(permissionsString, index) {
    let { classes } = this.props;
    return (
      <Tooltip
        id="tooltip-top"
        key={index}
        title={permissionsString}
        placement="top"
        classes={{ tooltip: classes.tooltip + " " + classes.customTooltip }}
      >
        <span>{permissionsString}</span>
      </Tooltip>
    );
  }

  render() {
    const { classes, roleData } = this.props;
    const { isModalOpen, searchText } = this.state;

    let data = roleData.map((role, index) => {
      return {
        permission: (
          <div
            className={classes.avatarHeaderWrapper}
            permission-name={role.name}
          >
            <RoleBadge
              style={{
                height: "30px",
                width: "30px",
                backgroundColor: "#d8d8d8",
                paddingTop: "5px",
                color: "#3F2C78",
                border: "1px solid #3F2C78"
              }}
              role={role}
            />
            <span className={classes.ColumnRole}>{role.name}</span>
          </div>
        ),
        projects: role.permissions.includes("edit-project")
          ? this.wrapTooltip("Criar/Editar")
          : "N/A",
        team: role.permissions.includes("edit-group")
          ? this.wrapTooltip("Criar/Editar")
          : "N/A",
        content: this.wrapTooltip(
          this.listPermissions({
            permissions: role.permissions,
            area: "content"
          })
        ),
        legal: this.wrapTooltip(
          this.listPermissions({
            permissions: role.permissions,
            area: "juridical"
          })
        ),
        // confidencial: role.permissions.includes('read-secret') ? this.wrapTooltip('Acessar') : "N/A",
        organization: this.wrapTooltip(
          this.listPermissions({
            permissions: role.permissions,
            area: "organization"
          })
        ),
        planning: this.wrapTooltip(
          this.listPermissions({
            permissions: role.permissions,
            area: "planning"
          })
        ),
        option: this.getOptions({ currentRow: index, roleId: role.id, role })
      };
    });
    if (searchText)
      data = data.filter((d, index) => {
        return (
          <Tooltip
            id="tooltip-top"
            key={index}
            title={d.permission.props["permission-name"]
              .toLowerCase()
              .includes(searchText.toLowerCase())}
            placement="top"
            classes={{ tooltip: classes.tooltip + " " + classes.customTooltip }}
          >
            {d.permission.props["permission-name"]
              .toLowerCase()
              .includes(searchText.toLowerCase())}
          </Tooltip>
        );
      });
    return (
      <Card classes={{ card: classes.card }}>
        <RoleConfig
          open={isModalOpen}
          toggleModal={() => {
            this.toggleModal();
          }}
        />
        <GridItem className={classes.headerWrapper} xs={12} md={12}>
          <Button
            onClick={() => this.toggleModal()}
            classes={{ root: classes.uploadContent }}
            color="primary"
          >
            <span>
              <AddCircle />
            </span>
            Novo Cargo
          </Button>
          <div className={classes.inputPermissionWrapper}>
            {/* <CustomDropdown // removido porque o daniel acha desnecessario, n estou deletando porque acho que isso pode mudar em breve
              buttonText={'Filtrar por Permissão'}
              onClick={(type) => { }}
              classes={{ button: classes.dropDown, butonText: classes.dropDownButtonText, buttonTextWrapper: classes.buttonTextWrapper }}
              dropdownList={[//todo: make the list not hardcoded
                "image"
              ]}
            /> */}
            <CustomInput
              classes={{
                labelRoot: classes.inputLabel,
                formControl: classes.restrainInputWidth,
                underline: classes.underlineInput
              }}
              labelText={<span>Buscar Cargo</span>}
              id="firstname"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event =>
                  this.setState({ searchText: event.target.value }),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Search className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
          </div>
        </GridItem>
        <CardBody>
          <div className={classes.contentViewTable}>
            <ReactTable
              data={data}
              noDataText="Não foram encontradas Cargos"
              resizable={false}
              showPagination={false}
              pageSize={data.length}
              columns={[
                {
                  Header: (
                    <div className={classes.titleHeader}>
                      <div>Cargo</div>
                    </div>
                  ),
                  accessor: "permission",
                  sortMethod: (a, b) => {
                    a = a.props["permission-name"]
                      ? a.props["permission-name"].toLowerCase()
                      : null;
                    b = b.props["permission-name"]
                      ? b.props["permission-name"].toLowerCase()
                      : null;
                    if (a > b) return 1;
                    if (a < b) return -1;
                  },
                  width: 250
                },
                {
                  Header: (
                    <div className={classes.titleHeader}>
                      <div>Projetos</div>
                    </div>
                  ),
                  accessor: "projects",
                  maxWidth: 200,
                  minWidth: 150
                },
                {
                  Header: (
                    <div className={classes.titleHeader}>
                      <div>Equipe</div>
                    </div>
                  ),
                  accessor: "team",
                  maxWidth: 150
                },
                {
                  Header: (
                    <div className={classes.titleHeader}>
                      <div>Conteúdo</div>
                    </div>
                  ),
                  accessor: "content",
                  maxWidth: 200
                },
                {
                  Header: (
                    <div className={classes.titleHeader}>
                      <div>Jurídico</div>
                    </div>
                  ),
                  accessor: "legal",
                  maxWidth: 200
                },
                // {
                //   Header: <div className={classes.titleHeader}>
                //     <div>Pasta Confidencial</div>
                //   </div>,
                //   accessor: "confidencial",
                //   width: 220,
                // },
                {
                  Header: (
                    <div className={classes.titleHeader}>
                      <div>Organização</div>
                    </div>
                  ),
                  accessor: "organization",
                  width: 220
                },
                {
                  Header: (
                    <div className={classes.titleHeader}>
                      <div>Planejamento</div>
                    </div>
                  ),
                  accessor: "planning",
                  width: 220
                },
                {
                  accessor: "option",
                  maxWidth: 50
                }
              ]}
              defaze={10}
              minRows={4}
              showPaginationTop={false}
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default withApollo(
  withStyles(UsersAndPermissionsStyle)(PermissionsTable)
);
