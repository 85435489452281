import gql from "graphql-tag";
import { myUser as MY_USER_QUERY } from "queries/queries.js";

const GET_USER_DATA_QUERY = gql`
  query GET_USER_DATA_QUERY {
    myUser {
      id
      name
      image {
        id
        imageUrl
      }
      organization {
        id
        name
        projects {
          id
          name
          owner {
            id
            name
          }
          members {
            id
            name
          }
          image {
            id
            imageUrl
          }
          collaborativeOrgs {
            id
            organization {
              id
              name
            }
            role {
              id
              name
              permissions
            }
          }
        }
      }
      role {
        id
        name
      }
      email
      phoneNumber
      cellphoneNumber
    }
  }
`;

const GET_ANY_USER_DATA_QUERY = gql`
  query GET_ANY_USER_DATA_QUERY($id: ID!) {
    user(id: $id) {
      id
      name
      image {
        id
        imageUrl
      }
      organization {
        id
        name
        projects {
          id
          name
          owner {
            id
            name
          }
          members {
            id
            name
          }
          image {
            id
            imageUrl
          }
          collaborativeOrgs {
            id
            organization {
              id
              name
            }
            role {
              id
              name
              permissions
            }
          }
        }
      }
      role {
        id
        name
      }
      email
      phoneNumber
      cellphoneNumber
    }
  }
`;

const EDIT_USER_MUTATION = gql`
  mutation EDIT_USER_MUTATION(
    $name: String
    $password: String
    $email: String
    $phoneNumber: String
    $roleId: ID
    $imageId: ID
    $cellphoneNumber: String
  ) {
    editUser(
      name: $name
      password: $password
      email: $email
      phoneNumber: $phoneNumber
      roleId: $roleId
      imageId: $imageId
      cellphoneNumber: $cellphoneNumber
    ) {
      name
      id
      image {
        id
        imageUrl
      }
      organization {
        id
        name
        projects {
          id
          name
          members {
            id
            name
          }
          image {
            id
            imageUrl
          }
        }
      }
      role {
        id
        name
      }
      email
      phoneNumber
    }
  }
`;

const editUsermutation = async ({
  params: { id, userName, userEmail, userPhone, userCellphone, imageId },
  client
}) => {
  let mutationVars = { id };
  let currState = [
    { name: "name", value: userName },
    { name: "email", value: userEmail },
    { name: "phoneNumber", value: userPhone },
    { name: "cellphoneNumber", value: userCellphone },
    { name: "imageId", value: imageId }
  ];
  currState.forEach(variable => {
    if (variable) {
      mutationVars[variable.name] = variable.value;
    }
  });

  await client.mutate({
    mutation: EDIT_USER_MUTATION,
    variables: mutationVars,
    refetchQueries: () => [{ query: MY_USER_QUERY }]
  });
};

export {
  GET_ANY_USER_DATA_QUERY,
  GET_USER_DATA_QUERY,
  EDIT_USER_MUTATION,
  editUsermutation
};
