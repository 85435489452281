export default function(index, id) {
  const { checkedRows } = this.state;
  let _checkedRows = [...checkedRows];
  const _index = _checkedRows.indexOf(id);
  index = _index >= 0 ? _index : _checkedRows.length;
  if (_checkedRows[index]) {
    _checkedRows.splice(index, 1);
  } else {
    _checkedRows[index] = id;
  }

  _checkedRows = _checkedRows.filter(row => row);
  this.setState({ checkedRows: _checkedRows });
}
