import React from "react";
import Logo from "../../shared/Logo";

function InvalidTokenPage({ ...props }) {
  const { classes } = props;

  return (
    <div className={classes.wizzardContainer}>
      <div className={classes.logo}>
        <Logo /> <span> manager </span>
      </div>
      <div
        style={{
          textAlign: "center",
          fontSize: "39px",
          paddingTop: "15%"
        }}
      >
        Esse convite expirou.
      </div>
    </div>
  );
}

export default InvalidTokenPage;
