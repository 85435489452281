import { gql } from "apollo-boost";

const ORG_TASKS_FRAGMENT = gql`
  fragment orgTasksFrag on OrgTask {
    id
    title
    startDate
    endDate
    creator {
      id
      name
      image {
        id
        imageUrl
      }
    }
  }
`;

const ORG_BOARDS_FRAGMENT = gql`
  ${ORG_TASKS_FRAGMENT}
  fragment orgBoardsFrag on OrgBoard {
    id
    title
    tasks {
      ...orgTasksFrag
    }
  }
`;

const GET_ORG_TRELLO = gql`
  ${ORG_BOARDS_FRAGMENT}
  query GET_ORG_TRELLO {
    orgTrello {
      boards {
        ...orgBoardsFrag
      }
    }
  }
`;

const ADD_ORG_BOARD_MUTATION = gql`
  mutation ADD_ORG_BOARD_MUTATION($title: String!) {
    addOrgBoard(title: $title) {
      id
    }
  }
`;

const ADD_ORG_CARD_MUTATION = gql`
  ${ORG_BOARDS_FRAGMENT}
  mutation ADD_ORG_CARD_MUTATION(
    $boardId: ID!
    $title: String!
    $description: String
    $startDate: String
    $endDate: String
  ) {
    addOrgTask(
      boardId: $boardId
      title: $title
      description: $description
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      boards {
        ...orgBoardsFrag
      }
    }
  }
`;

const EDIT_ORG_CARD_MUTATION = gql`
  mutation EDIT_ORG_CARD_MUTATION(
    $id: ID!
    $boardId: ID
    $title: String
    $status: String
    $startDate: String
    $endDate: String
    $description: String
    $order: Int
  ) {
    editOrgTask(
      id: $id
      boardId: $boardId
      title: $title
      status: $status
      description: $description
      startDate: $startDate
      endDate: $endDate
      order: $order
    ) {
      id
    }
  }
`;
const DELETE_ORG_BOARD_MUTATION = gql`
  mutation DELETE_ORG_BOARD_MUTATION($id: ID!) {
    deleteOrgBoard(id: $id) {
      id
    }
  }
`;

const DELETE_ORG_TASK_MUTATION = gql`
  mutation DELETE_ORG_TASK_MUTATION($id: ID!) {
    deleteOrgTask(id: $id) {
      id
    }
  }
`;

const SUBSCRIPTION = gql`
  subscription SUBSCRIPTION {
    orgTrelloChange {
      boards {
        id
        title
        startDate
        endDate
        tasks {
          id
          title
          status
          startDate
          endDate
          members {
            id
            name
          }
        }
      }
    }
  }
`;

const EDIT_ORG_BOARD_MUTATION = gql`
  mutation EDIT_ORG_BOARD_MUTATION($id: ID!, $title: String, $order: Int) {
    editOrgBoard(id: $id, title: $title, order: $order) {
      id
    }
  }
`;

const moveCard = ({ params, client }) => {
  client
    .mutate({
      mutation: EDIT_ORG_CARD_MUTATION,
      variables: {
        id: params.taskId,
        boardId: params.boardId,
        order: params.order
      }
    })
    .catch(() => {
      console.error("Error");
    });
};

// const getBoards = async ({params, client, onSuccess}) => {
//     const result = client.query({
//     query: GET_ORG_TRELLO,
//   }).then(res => {
//     onSuccess(res.data.boards);
//   })
//   await result;
//   return result;
// }

const addBoard = ({ params, client }) => {
  client.mutate({
    mutation: ADD_ORG_BOARD_MUTATION,
    variables: { title: params.title }
  });
};

const addCard = ({ params, client, onSucess }) => {
  client
    .mutate({
      mutation: ADD_ORG_CARD_MUTATION,
      variables: {
        boardId: params.boardId,
        title: params.title,
        description: params.description,
        startDate: params.startDate,
        endDate: params.endDate
      }
    })
    .then(() => {
      if (onSucess) onSucess();
    });
};

const editCard = ({ params, client, onSucess }) => {
  client
    .mutate({
      mutation: EDIT_ORG_CARD_MUTATION,
      variables: {
        id: params.id,
        title: params.title,
        description: params.description,
        status: params.status,
        startDate: params.startDate,
        endDate: params.endDate
      }
    })
    .then(() => {
      if (onSucess) onSucess();
    });
};

const deleteBoard = ({ params, client }) => {
  client.mutate({
    mutation: DELETE_ORG_BOARD_MUTATION,
    variables: { id: params.boardId, projectId: params.projectId }
  });
};

const editBoard = ({ params, client }) => {
  client.mutate({
    mutation: EDIT_ORG_BOARD_MUTATION,
    variables: { id: params.boardId, title: params.title }
  });
};

const moveBoard = ({ params, client }) => {
  client.mutate({
    mutation: EDIT_ORG_BOARD_MUTATION,
    variables: { id: params.boardId, order: params.order }
  });
};

const deleteCard = ({ params, client }) => {
  client.mutate({
    mutation: DELETE_ORG_TASK_MUTATION,
    variables: { id: params.cardId }
  });
};

export {
  addCard,
  editCard,
  moveCard,
  addBoard,
  moveBoard,
  editBoard,
  deleteCard,
  deleteBoard,
  GET_ORG_TRELLO,
  SUBSCRIPTION,
  ADD_ORG_BOARD_MUTATION
};
