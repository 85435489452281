
import { gql } from "apollo-boost";

const MEMBERS_INFO_FRAG = gql `
  fragment membersFrag on User {
    id
    name
    organization {
      name
      id
    }
    role {
      id
      name
    }
    image {
      id
      imageUrl
    }
  }
`;

const MY_ORG_QUERY_WITH_ROLES_MEMBERS_AND_COLLABORATORS  = gql`
  ${MEMBERS_INFO_FRAG}
  query MY_ORG_QUERY_WITH_ROLES_MEMBERS_AND_COLLABORATORS{
    myOrganization{
      id
      name
      roles{
        id
        name
        higherThanUser
        permissions
        color
        numberOfUsers
        numberOfCollaborations
      }
      image{
        id
        imageUrl
      }
      members{
        ...membersFrag
      }
      projects{
        collaborativeOrgs{
          organization{
            id
            name
            image{
              id
              imageUrl
            }
          }
        }
      }
    }
  }
`;

const GET_MY_ORGANIZATION_QUERY = gql`
  ${MEMBERS_INFO_FRAG}
  query GET_MY_ORGANIZATION_QUERY {
    myOrganization {
      id
      name
      members {
        ...membersFrag
      }
      roles{
        id
        name
      }
    }
  }
`;

export {
    MY_ORG_QUERY_WITH_ROLES_MEMBERS_AND_COLLABORATORS,
    GET_MY_ORGANIZATION_QUERY
}