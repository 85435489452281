export default {
  card: {
    margin: '15px 0',
    height: '131px',
    position: 'relative',
    overflow: 'visible',
    marginBottom: 40,
    marginTop: 20,
  },
  linearProgress: {
    height: '20px',
    paddingTop: '7px',
    width: '96%'
  },
  numberCardRed: {
    height: '89px',	
    width: '89px',
    position: 'absolute',	
    backgroundColor: '#FF574A', 
    background: 'linear-gradient(90deg, #FF574A 0%, #FF574A 100%)',	
    boxShadow: '0 4px 20px 0 rgba(0,0,0,0.14)',
    borderRadius: 3,
    top: '-15px',
    left: '15px',
  },
  numberCardOrange: {
    height: '89px',	
    width: '89px',
    position: 'absolute',	
    backgroundColor: '#ff994a', 
    background: 'linear-gradient(90deg, #ff994a 0%, #ff994a 100%)',	
    boxShadow: '0 4px 20px 0 rgba(0,0,0,0.14)',
    borderRadius: 3,
    top: '-15px',
    left: '15px',
  },
  footerText: {
    color: '#373737'
  },
  numberCardGreen: {
    height: '89px',	
    width: '89px',
    position: 'absolute',	
    backgroundColor: '#4da851', 
    background: 'linear-gradient(90deg, #4da851 0%, #4da851 100%)',	
    boxShadow: '0 4px 20px 0 rgba(0,0,0,0.14)',
    borderRadius: 3,
    top: '-15px',
    left: '15px',
  },
  numberCardPurple: {
    height: '89px',	
    width: '89px',
    position: 'absolute',	
    background: 'linear-gradient(90deg, #3f2c78 0%, #3f2c78 100%)',	
    boxShadow: '0 4px 20px 0 rgba(0,0,0,0.14)',
    borderRadius: 3,
    top: '-15px',
    left: '15px',
  },
  number: {
    height: '38px',	
    width: '86px',	  
    color: '#FFFFFF',	
    fontFamily: 'Roboto',	
    fontSize: '32px',	
    lineHeight: '38px',	
    textAlign: 'center',
    paddingTop: '22px'
  },
  cardInfo: {
    float: 'right',
    marginRight: '10px',
    marginTop: 10,
  },
  title: {
    fontWeight: 300,
    fontSieze: 14,
    color: '#a2a2a2',
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 15,
    color: '#4d5866',
    fontFamily: 'Montserrat, sans-serif'
  },
  footer: {
    borderTop: '1px solid #f3f3f3',
    marginBottom: 0,
    marginTop: 'auto',
    height: '5px',
    position: 'absolute',
    width: '100%',
    bottom: '25px', 
    color: '#a2a2a2'  ,
    display: 'flex',
    paddingLeft: '10px',
    paddingTop: '5px',
    lineHeight: '25px',
    cursor: 'pointer',
    '& svg': {
      marginRight: '15px'
    }
  }
}