import { gql } from "apollo-boost";
import moment from "moment";
import { GET_PROJECT_QUERY } from "views/Planning/PlanningQueries";
import validPhases from "utils/phases";

const DASHBOARD_PROJECTS_QUERY = gql`
  query DASHBOARD_PROJECTS_QUERY {
    projects {
      id
      name
      startDate
      endDate
      owner {
        id
        name
        image {
          id
          imageUrl
        }
      }
      schedule {
        id
        name
        phases {
          id
          name
          completed
          startDate
          endDate
        }
      }
      deliverySchedule {
        id
        phases {
          id
          name
          type
          elements {
            id
            name
            juridical
            content {
              id
              name
              status
              juridicalStatus
            }
          }
        }
        elements {
          id
          name
        }
      }
    }
  }
`;

const EDIT_DASHBOARD_PROJECTS_QUERY = gql`
  mutation EDIT_DASHBOARD_PROJECTS_QUERY(
    $projectId: ID!
    $phases: [PhaseInput]
  ) {
    editProjectSchedule(projectId: $projectId, phases: $phases) {
      id
      name
      startDate
      endDate
      schedule {
        id
        phases {
          id
          name
          completed
          startDate
          endDate
          elements {
            id
            name
            completed
            startDate
            endDate
            juridical
          }
        }
      }
    }
  }
`;

const EDIT_PHASE_ELEMENTS_MUTATION = gql`
  mutation EDIT_PHASE_ELEMENTS_MUTATION(
    $projectId: ID!
    $phaseId: ID!
    $elements: [ElementInput]
  ) {
    editProjectPhaseElements(
      projectId: $projectId
      phaseId: $phaseId
      elements: $elements
    ) {
      id
      name
      startDate
      endDate
      schedule {
        id
        phases {
          id
          name
          completed
          startDate
          endDate
          elements {
            id
            name
            completed
            startDate
            endDate
          }
        }
      }
    }
  }
`;

function editPhases({ params: { projectId, phases }, client, getPhases }) {
  return client
    .mutate({
      mutation: EDIT_DASHBOARD_PROJECTS_QUERY,
      variables: { projectId, phases },
      refetchQueries: () => {
        return [
          {
            query: GET_PROJECT_QUERY,
            variables: { projectId }
          }
        ];
      }
    })
    .then(res => {
      if (getPhases) getPhases(res.data.editProjectSchedule.schedule.phases);
    });
}

function editElements({
  params: { projectId, phaseId, elements },
  client,
  getPhases
}) {
  return client
    .mutate({
      mutation: EDIT_PHASE_ELEMENTS_MUTATION,
      variables: { projectId, phaseId, elements },
      refetchQueries: () => {
        return [
          {
            query: GET_PROJECT_QUERY,
            variables: { projectId }
          }
        ];
      }
    })
    .then(res => {
      if (getPhases) {
        getPhases(res.data.editProjectPhaseElements.schedule.phases);
      }
    });
}

function formattedPhases({ projects }) {
  let now = Date.now();
  let formattedProjects = projects
    .map(project => {
      if (!project.deliverySchedule) return null;

      for (let phase of project.deliverySchedule.phases) {
        let completedElements = 0;
        let associatedElements = 0;
        for (let element of phase.elements) {
          if (!element.content) {
            continue;
          }
          associatedElements += 1;
          if (
            (element.juridical &&
              element.content.juridicalStatus === "Versão Final") ||
            (!element.juridical && element.content.status === "Versão Final")
          ) {
            completedElements += 1;
          }
        }
        if (completedElements >= phase.elements.length) {
          phase.status = "Concluído";
          phase.color = "green";
          phase.completed = true;
          continue;
        } else if (associatedElements === 0) {
          phase.status = "Não iniciado";
          phase.color = "grey";
          continue;
        } else {
          phase.status = "Em Andamento";
          phase.color = "orange";
          continue;
        }
      }
      project.completed = !project.deliverySchedule.phases.some(
        phase => !phase.completed
      );
      if (project.completed) project.status = "Concluído";
      else if (
        project.deliverySchedule.phases.some(
          phase => phase.status === "Em Andamento"
        )
      )
        project.status = "Em Andamento";
      else if (moment(project.startDate, "DD-MM-YYYY").isAfter(now))
        project.status = "Não Iniciado";
      else project.status = "Em Andamento";

      // Filters the non stardard phases. Eventually phases creation will be a drop down so every phase will be standard
      project.schedule.phases = project.schedule.phases.filter(phase =>
        validPhases.includes(phase.name)
      );

      let projectEndDate = moment(project.endDate, "DD-MM-YYYY");
      let projectStartDate = moment(project.startDate, "DD-MM-YYYY");

      let projectTotalTime = projectEndDate.diff(projectStartDate, "days");
      let projectUsedTime = moment(now).diff(projectStartDate, "days");
      project.timeline = projectUsedTime / projectTotalTime;
      if (project.timeline > 1) project.timeline = 1;
      project.timeline *= 100;
      return project;
    })
    .filter(project => project !== null);

  let allPhases = formattedProjects.map(
    project => project.deliverySchedule.phases
  );
  let tablePhases = validPhases.filter(phase =>
    allPhases.some(usedPhases =>
      usedPhases.some(usedPhase => phase === usedPhase.name)
    )
  );

  return { projects: formattedProjects, phases: tablePhases };
}

export { DASHBOARD_PROJECTS_QUERY, formattedPhases, editElements, editPhases };
