import React from "react";
import ContentLoader from 'react-content-loader';
import Fade from '@material-ui/core/Fade';

function animateAndExit(close, animationTimeout, onLoadComplete){
  setTimeout(()=> { 
    close(); 
    onLoadComplete();
  }, animationTimeout);
}

function buildLoadingLines(linePropList){
  let loaderLines = [];
  for(let { x, y, rx, ry, width, height } of linePropList)
    loaderLines.push(<rect x={x} y={y} rx={rx} ry={ry} width={width} height={height} key={Math.random()%linePropList.length}/>)
  return loaderLines;
}

function DocumentLoader({ height = 500, animationTimeout = 5000, fadeOutTimeOut = 500, onLoadComplete}){
  const [ open, setOpen ] = React.useState(true);
  const [ rendered, setRendered ] = React.useState(true);
  const close = () => {
    setOpen(false);
    setTimeout(()=> setRendered(false), fadeOutTimeOut + 50 );
  }
  React.useEffect(() => { animateAndExit(close, animationTimeout, onLoadComplete); })
  const rowProps = [ 
    {x:"12", y:"6", rx:"6", ry:"6", width:"70%", height:"12"},
    {x:"12", y:"24", rx:"6", ry:"6", width:"30%", height:"12"},
    {x:"12", y:"70", rx:"6", ry:"6", width:"90%", height:"7"},
    {x:"12", y:"84", rx:"6", ry:"6", width:"90%", height:"7"},
    {x:"12", y:"98", rx:"6", ry:"6", width:"85%", height:"7"},
    {x:"12", y:"144", rx:"6", ry:"6", width:"90%", height:"7"},
    {x:"12", y:"158", rx:"6", ry:"6", width:"90%", height:"7"},
    {x:"12", y:"172", rx:"6", ry:"6", width:"90%", height:"7"},
    {x:"12", y:"186", rx:"6", ry:"6", width:"55%", height:"7"},
    {x:"12", y:"232", rx:"6", ry:"6", width:"90%", height:"7"},
    {x:"12", y:"246", rx:"6", ry:"6", width:"90%", height:"7"},
    {x:"12", y:"260", rx:"6", ry:"6", width:"90%", height:"7"},
    {x:"12", y:"306", rx:"6", ry:"6", width:"90%", height:"7"},
    {x:"12", y:"320", rx:"6", ry:"6", width:"90%", height:"7"},
    {x:"12", y:"334", rx:"6", ry:"6", width:"25%", height:"7"},
    {x:"12", y:"380", rx:"6", ry:"6", width:"90%", height:"7"},
    {x:"12", y:"394", rx:"6", ry:"6", width:"80%", height:"7"},
    {x:"12", y:"440", rx:"6", ry:"6", width:"90%", height:"7"},
    {x:"12", y:"454", rx:"6", ry:"6", width:"76%", height:"7"},
  ]
  if(rendered)
    return(
      <Fade in={open} timeout={fadeOutTimeOut}>
        <div style={{ backgroundColor: "white"}}>
          <ContentLoader height={height} style={{marginTop: "50px"}}  >
            {buildLoadingLines(rowProps)}
          </ContentLoader>
        </div>
      </Fade>
    )
  else return null;
}
export default DocumentLoader;