import React from "react"
import Modal from 'components/ModalWrapper/ModalWrapper';

import style from "./style";
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Loading from "components/Loading/Loading";
import { withStyles } from "@material-ui/core";
import { GET_ROLES_QUERY } from "components/TeamRoleTable/TeamRoleTableQueries.js";
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { withApollo, Query } from "react-apollo";


class CollaborationModal extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      role: props.role,
      alert: null,
      missingRole: false,
    }
  }
  
  save = async () => {
    const { save } = this.props;
    const { role } = this.state;
    if (!role) return this.setState({missingRole: true});
    await save(role.id);
    this.successAlert();
  } 

  hideAlert = () =>  this.setState({alert: null})
  
  successAlert() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Cargo Alterado"
          onConfirm={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          Cargo alterado com sucesso.
        </SweetAlert>
      )
    });
  }

  render () {
    const { classes, open, onClose } = this.props;
    const { role, alert, missingRole } = this.state;
    if (!open && !alert) return null;

    return (
      <div>
      {alert && <Modal  open={alert}> 
        <div> {alert} </div> 
      </Modal>}
      {open && <Modal
        
        open={open}
        onBackdropClick={() => onClose()}
        classes={{ root: classes.scrollableModal }}
      >
          <div className={classes.root}>
              <div className={classes.title}>
                Editar Permissões em Colaboração
              </div>
              <div className={classes.subtitle}>
                As permissões em colaboração irão definir o limite do que as organizações convidadas podem fazer dentro do projeto
              </div>

            <Query query={GET_ROLES_QUERY}>
              {({data, loading, error}) => {
                if (loading) return <Loading/>
                if (error) console.error('error editRole ', error);

                const roles = data.roles.map(currentRole => ({
                  name: currentRole.name,
                  id: currentRole.id,
                  obj: true
                }))
                return (
                  <div >
                    <CustomDropdown
                      buttonText={role ? role.name : 'Selecione Cargo'}
                      error={missingRole}
                      onClick={(role) => {
                        this.setState({role, missingRole: false});
                      }}
                      classes={{ button: classes.dropDown, butonText: classes.dropDownButtonText, buttonTextWrapper: classes.buttonTextWrapper, manager: classes.manager }}
                      dropdownList={roles}
                    />
                  </div>
                )
              }
            }
            </Query>
            <div className={classes.saveBtn}>
            <Button
                color="gray"
                onClick={() => onClose()}
                classes={{root: classes.btn}}
              >
                Fechar
              </Button>
              <Button
                color="purple"
                onClick={() => this.save()}
                classes={{root: classes.btn}}
              >
                Salvar Alteração
              </Button>
            </div>
          </div>
      </Modal>}
      </div>
    )
  }
}

export default withApollo(withStyles({...sweetAlertStyle,...style})(CollaborationModal));