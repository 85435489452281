import React from "react";
import ReactTable from "react-table";
import { withStyles, Icon } from "@material-ui/core";

import DraggableTableStyle from "./DraggableTableStyle.js";
class DragTrComponent extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      isDragging: false,
      cursorUpdate: 0,
    }
    this.draggableTimer = null;
    this.updateDraggable = true;
    this.cursorX = 500;
    this.cursorY = 500;

    document.addEventListener('dragover', this.dragOver);
  }

  dragOver = (ev) => {
    const shouldIUpdatePosition = this.updateDraggable === true;
    this.updateDraggable = false;
    this.cursorX = ev.clientX - 20;
    this.cursorY = ev.clientY - 20;
    this.draggableTimer = setTimeout(() => {
      if (shouldIUpdatePosition) {
        clearTimeout(this.draggableTimer)
        this.forceUpdate();
        this.updateDraggable = true;
      }
    }
      , 1000/30)
  }

  componentWillUnmount () {
    document.removeEventListener("dragover", this.dragOver); 
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.isDragging !== this.state.isDragging) {
      this.props.setDraggin(this.state.isDragging);
    }
  }

  render() {
    const { children, rowInfo, classes, onClickRow, onDbClickRow, draggingIds, isSomeRowDraggin } = this.props;
    const { isDragging } = this.state;
    const draggingNumber = draggingIds ? draggingIds.length : 0;
    if (rowInfo) {
      return (
        <div className={classes.trWrapper} style={rowInfo.original.checked ? {backgroundColor: '#ebe9e9', position: 'relative'} : {position: 'relative'}}>
          {
            isSomeRowDraggin && (isDragging || (draggingIds.filter(d => d === rowInfo.original.id).length > 0) )&&
            <div style={{position: 'relative'}}>
              {isDragging && <div className={classes.draggableIcon} style={{top: this.cursorY, left: this.cursorX}}>
                {draggingNumber > 1 ? <Icon> {'filter_' + (draggingNumber > 9 ? '9_plus' : draggingNumber)} </Icon> : rowInfo.original.icon()}
              </div>}
              <div className={classes.draggablePlaceholder}/>
            </div>
          }
          <div 
            onClick= {
              (e) => { 
                e.preventDefault(); 
                rowInfo.original.expand(rowInfo.viewIndex, rowInfo.original.id)
              }
            } 
            style={{ position: 'absolute', cursor: 'pointer', width: '35px', zIndex: 999, height: '43px'}}
          />
          <div
            onClick={() => {
              onClickRow(rowInfo.index, rowInfo.original.id)
            }}
            onDoubleClick={() => onDbClickRow(rowInfo.index, rowInfo.original.id)} 
            draggable
            id={rowInfo.original.id}
            style={isDragging ? {opacity: '0'} : {}}
            onDragStart={(ev) => {
              ev.dataTransfer.setData("id", rowInfo.original.id);
              this.cursorX = ev.clientX - 30;
              this.cursorY = ev.clientY - 20;
              this.setState({isDragging: true})
            }}

            onDragEnd={() => {
              this.setState({isDragging: false})
              clearTimeout(this.draggableTimer);
              this.updateDraggable = true;
            }}
            >
            <ReactTable.defaultProps.TrComponent>
              {!isDragging && !(isDragging && draggingIds.filter(d => d === rowInfo.original.id).length > 0)? children : <div style={{opacity: '10', backgroundColor: 'transparent', height: '44px'}}> children </div>}
            </ReactTable.defaultProps.TrComponent>
          </div>
        </div>
      );
        }
        else { return (
          <ReactTable.defaultProps.TrComponent>
              {children}
            </ReactTable.defaultProps.TrComponent>
        )
        }
    }
}
const DraggableTable = function ({
  classes, 
  innerRef, 
  juridical, 
  expandedRows, 
  toggleRow, 
  draggableIcon, 
  setIsDraggin, 
  onDrop, 
  draggableProvided,
  draggingIds,
  setDraggin, 
  isSomeRowDraggin, 
  onClickRow,
  onClick,
  onDbClickRow,
  ...rest}) {
  const getTrProps = (state, rowInfo) => {
    return { 
      state, 
      classes, 
      onClick, 
      rowInfo, 
      juridical,
      toggleRow, 
      onClickRow, 
      setDraggin, 
      draggingIds, 
      onDbClickRow, 
      isSomeRowDraggin, 
     };
  };

  return (
      <div style={{ height:'100%' }}>
        <ReactTable 
          {...rest}
          noDataText='Nenhum conteúdo foi encontrado'
          TrComponent={DragTrComponent}
          getTrProps={getTrProps}
          expanded={expandedRows}
        />
      </div>
  )
}

export default withStyles(DraggableTableStyle)(DraggableTable);