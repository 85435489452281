import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Modal from 'components/ModalWrapper/ModalWrapper';

import Button from "components/CustomButtons/Button.jsx";
import { ChromePicker } from 'react-color';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import BedgePlaceHolder from "components/BedgePlaceHolder/BedgePlaceHolder.jsx";
import colorPicker from "assets/img/colorPicker.png";
import EditRoleStyles from "./EditRoleStyles.js";
import SwitchesTable from "components/SwitchesTable/SwitchesTable.jsx";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { MY_ORG_QUERY_WITH_ROLES_MEMBERS_AND_COLLABORATORS } from "queries/organizationQueries";
import { GET_ROLES_QUERY } from "components/TeamRoleTable/TeamRoleTableQueries.js";
import { ContextConsumer } from 'ContextController.js';

const EDIT_ROLE_MUTATION = gql`
  mutation EDIT_ROLE_MUTATION ($name: String!, $permissions: [String]! , $color: String, $id: ID! ){
    editRole(name: $name, permissions: $permissions, color: $color, id:$id){
      id
      name
      color
      permissions
      numberOfUsers
    }
  }
`;

class EditRoleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.selectedRole.name,
      color: this.props.selectedRole.color,
      colors: ['#DA5656', '#56DAB1', '#8F56DA', '#84DA56', '#5668DA', '#56DA9D', '#CFDA56', '#DA5656', '#109864', '#151B58'],
      colorPickOpen: false,
      permissions:[]
    };
    this.generatePermissionsList = this.generatePermissionsList.bind(this);
    //this.handleTemplateSelect = this.handleTemplateSelect.bind(this);
  }
  
  sendState() {
    return this.state;
  }
  
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  isValidated() {
    return true;
  }

  generatePermissionsList({ userPermissions }) {
    let projectPermissions = [];
    let groupPermissions = [];
    let contentPermissions = [];
    let juridicalPermissions = [];
    let secretPermission = [];
    let orgPermission = [];
    let planningPermission = [];
    //we need centralized permission names
    if( userPermissions.includes('edit-project') )          projectPermissions.push('Criar / Editar');
    if( userPermissions.includes('edit-group') )            groupPermissions.push('Criar / Editar');
    if( userPermissions.includes('read-content') )          contentPermissions.push('Acessar');
    if( userPermissions.includes('create-content') )        contentPermissions.push('Criar / Versionar');
    if( userPermissions.includes('edit-content-status') )   contentPermissions.push('Aprovar');
    if( userPermissions.includes('edit-content') )          contentPermissions.push('Editar / Excluir');
    if( userPermissions.includes('read-juridical') )        juridicalPermissions.push('Acessar / Criar');
    if( userPermissions.includes('edit-juridical-status') ) juridicalPermissions.push('Aprovar');
    if( userPermissions.includes('edit-juridical'))         juridicalPermissions.push('Editar / Excluir');
    if( userPermissions.includes('read-secret') )           secretPermission.push('Acessar');
    if( userPermissions.includes('edit-organization') )     orgPermission.push('Alterar Cargo');
    if( userPermissions.includes('edit-user') )             orgPermission.push('Excluir Usuário');
    if( userPermissions.includes('read-planning') )         planningPermission.push('Acessar');
    if( userPermissions.includes('edit-planning') )         planningPermission.push('Editar');

    return { projectPermissions, groupPermissions, contentPermissions, juridicalPermissions, secretPermission, orgPermission, planningPermission };
  }

  editRole(){
    if(this.state.name && this.state.permissions){
      this.props.client.mutate({
        mutation:EDIT_ROLE_MUTATION, 
        variables:{
          name:this.state.name, 
          color:this.state.color,
          permissions: this.state.permissions,
          id: this.props.selectedRole.id
        },
        refetchQueries:() => [{ query:MY_ORG_QUERY_WITH_ROLES_MEMBERS_AND_COLLABORATORS }, {query: GET_ROLES_QUERY}]
      }).then(() => {this.props.toggleModal()});
    }
  }

  setPermissions(selectedPermission){
    if(!this.state.permissions.includes(selectedPermission))
      this.setState({ permissions: [ ...this.state.permissions, selectedPermission ]});
    else
      this.setState({ permissions: this.state.permissions.filter((permission) => permission !== selectedPermission) });
  }

  componentDidMount(){
    if(this.state.permissions.length === 0){
      this.setState({ permissions: this.props.selectedRole.permissions})
    }
  }
  render() {
    const { classes, open, toggleModal } = this.props;
    const { name, colors, color, colorPickOpen, loggedUser } = this.state;
    return (
      <>
      <ContextConsumer>
        {(context) => {
          let userPermissions = [];
          if(!loggedUser){
            let _loggedUser = context.getUser();
            this.setState({ loggedUser: _loggedUser });                                            
            userPermissions = _loggedUser.role.permissions;
          }
          else 
            userPermissions = loggedUser.role.permissions;
          let { projectPermissions,
            groupPermissions,
            contentPermissions,
            juridicalPermissions,
            //  secretPermission,
            orgPermission,
            planningPermission 
          } = this.generatePermissionsList({ userPermissions });
          return (
            <Modal 
              
              open={open}
              onBackdropClick={()=>{ this.setState({ permissions:[] }); toggleModal(); }}//
              onClose={()=>{this.setState({ permissions:[] }); toggleModal();}}
              classes={{root: classes.scrollableModal}}
            >
                <GridContainer className={classes.templateModal} justify="center" >
                  <GridItem style={{ padding: 0 }} xs={12} sm={12}>
                    <h3 className={classes.titleNewModel}> Editar Cargos </h3>
                    <div className={classes.infoWrapper}>
                      <div className={classes.newModelInputWrapper}>
                      <BedgePlaceHolder style={{
                        height: '35px', 
                        width: '35px', 
                        backgroundColor: color, 
                        paddingTop: '7px', 
                        color: 'white',
                        position: 'absolute',
                        right: '50px',
                        top: '5px',
                      }} 
                      text={name}/> 
                        <CustomInput
                          classes={{labelRoot: classes.inputLabelNewModel, formControl: classes.formControl}}
                          labelText={
                            <span>
                            </span>
                          }
                          inputProps={{
                            classes: {input: classes.newModelInput, formControl: classes.inputModelName},
                            onChange: e => this.setState({name: e.target.value}),
                            value: name,
                          }} 
                        />
                      </div>
                    </div>
                  </GridItem>
                  <GridItem style={{ padding: '2px 25px' }} sm={12}>
                    <div className={classes.colorpickerWrapper}>
                      <div className={classes.pickAColor}> Selecione uma cor para o cargo </div>
                      <div className={classes.flex}>
                        {colors.map((c, k) => {
                          return (
                            <div onClick={() => this.setState({color: c})} key={k} style={{backgroundColor: c}} className={classes.colorPickerCircle}/>
                          )
                        })}
                        <img alt='colorPicker' src={colorPicker} onClick={() => this.setState({colorPickOpen: true})} className={classes.colorPickerCircle}/>
                        <div className={classes.colorPickerWrapper}>
                          {colorPickOpen && 
                            <>
                              <div className={ classes.cover } onClick={ () => this.setState({colorPickOpen: false}) }/>
                              <ChromePicker color={color} onChangeComplete={(color) => this.setState({color: color.hex})}/>
                            </>}
                        </div>
                      </div>
                    </div>
                  </GridItem>
                  <GridContainer className={classes.flex} xs={12} style={{ overflowY:"auto", maxHeight:"calc(100vh - 400px)", minHeight: "50px" }}>
                    <GridItem xs={6}>
                        <SwitchesTable
                          checked={this.state.permissions} 
                          title='Organização'
                          permissions={orgPermission}
                          onChange={(permission) => this.setPermissions(permission)}
                        />
                    </GridItem>
                    <GridItem xs={6}>
                      <SwitchesTable
                        checked={this.state.permissions} 
                        title='Projetos'
                        permissions={projectPermissions}
                        onChange={(permission) => this.setPermissions(permission)}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                        <SwitchesTable
                          checked={this.state.permissions} 
                          title='Equipe'
                          permissions={groupPermissions}
                          onChange={(permission) => this.setPermissions(permission)}
                        />
                    </GridItem>
                    <GridItem xs={6}>
                        <SwitchesTable
                          checked={this.state.permissions} 
                          title='Planejamento'
                          permissions={planningPermission}
                          onChange={(permission) => this.setPermissions(permission)}
                        />
                    </GridItem>
                    <GridItem xs={6}>
                        <SwitchesTable
                          checked={this.state.permissions} 
                          title='Conteúdo'
                          permissions={contentPermissions}
                          onChange={(permission) => this.setPermissions(permission)}
                        />
                    </GridItem>
                    <GridItem xs={6}>
                        <SwitchesTable
                          checked={this.state.permissions} 
                          title='Jurídico'
                          permissions={juridicalPermissions}
                          onChange={(permission) => this.setPermissions(permission)}
                        />
                    </GridItem>
                    {/* <GridItem xs={6}>
                        <SwitchesTable
                          checked={this.state.permissions}
                          title='Pastas Privadas'
                          permissions={secretPermission}
                          onChange={(permission) => this.setPermissions(permission)}
                        />
                    </GridItem> */}
                    
                  </GridContainer>
                  <GridItem style={{ padding: '20px 25px' }} sm={12}>
                    <div className={classes.centerContent}>
                      <Button 
                        color="purple"
                        className={classes.btnCreate}
                        data-id="addProjectTemplate" onClick={() => { this.editRole() }}
                      >
                        Editar Cargo
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
            </Modal>
          );
        }}
        </ContextConsumer>
      </>
    );
  }
}

export default withApollo(withStyles(EditRoleStyles)(EditRoleModal));