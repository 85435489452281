const style = {
    root: {
      margin: '0 auto',
      padding: '30px 25px',
      marginTop: '40px',
      backgroundColor: 'white',
      overflow: 'auto',
      borderRadius: '7px',
      '& h3': {
        textAlign: 'center',
        marginBottom: '15px'
      }
    },
    inputWrapper: {
      width: '100%',
      margin: '0',
      marginLeft:"15px",
      marginTop: '-20px',
      display: "flex",
      justifyContent: "space-between",
    },
    closeIcon: {
      float: 'right',
      marginTop: '-20px',
      marginRight: '-10px',
      cursor: 'pointer'
    },
    newDocument: {
      color:"white",
      backgroundColor: '#3F2C78',
      height: '46px',
      minWidth: '93px',
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '14px',
      lineHeight: '14px',
      padding: '11px 10px',
      borderRadius: '6px',
      boxShadow: '0 4px 23px 0 rgba(0,0,0,0.12)',
      display: 'flex',
      fontWeight: '500',
      justifyContent: 'space-between',
      marginBottom: '45px',
      marginTop: '26px',
      marginRight: "14px",
      "&:hover": {
        backgroundColor: '#796aa9',
        boxShadow: "none",
      },
      "&:focus": {
        backgroundColor: '#3F2C78',
        boxShadow: "none",
      },
      '& svg': {
        color: "white",
        height: '24px'
      },
    },
    
  }
  export default style;
