import React from "react";
import { withApollo } from 'react-apollo';

import TeamRoleTableStyle from "components/TeamRoleTable/TeamRoleTableStyle.js";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import EmailIcon from "@material-ui/icons/Email";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import _ from 'lodash'
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";

class InviteOrg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      role: null,
      sentEmails:[],
      inputCount:1,
      roles:[],
      emails:[]
    };
  }
  
  inputContainer(last, key){
    const { classes, setSentEmails } = this.props;
    let inputAcessKey = key;
    return(<div>
      <GridContainer style={{ height: '100%', minHeight:"55px" }} direction='row'>
        <GridItem className={classes.inviteEmail} sm={7}>
          <CustomInput
            id="email"
            classes={{ labelRoot: classes.labelNormal, formControl: classes.noPaddingInput }}
            labelText={
              <span>
                E-mail da Organização
              </span>
            }

            formControlProps={{
              fullWidth: true
            }}

            inputProps={{
              onBlur:async e => {
                let _emails = [...this.state.emails];
                _emails[inputAcessKey] = e.target.value;
                await this.setState({ emails: _emails });
                let emailVars = this.state.emails.map((email) => {
                  return { destination: email };
                }) 
                setSentEmails(emailVars);
              },
              endAdornment: (
              <InputAdornment
                position="end"
                className={classes.inputAdornment}
              >
                <EmailIcon
                  className={classes.inputAdornmentIcon}
                />
              </InputAdornment>
              )
            }}
          />
        </GridItem>
        {/* <GridItem xs={1} md={1} sm={1}>
        { 
          !last && 
          <Button onClick={() => this.setState({ inputCount: this.state.inputCount + 1 })} classes={{ root: classes.sendMailWrapper }} color='white' round justIcon>
            <span className={classes.sendMail}> 
              <AddCircle/> 
            </span>
          </Button>
        }
        </GridItem> */}
      </GridContainer>
    </div>);
  }

  render() {
    return (
      <div style={{marginTop:"5px"}}>
      {
        _.times(this.state.inputCount, (i) =>{
          return(
            this.inputContainer((i < this.state.inputCount - 1), i)
          );
        })
      }
      </div>      
    );
  }
}

InviteOrg.propTypes = {
  email: PropTypes.string,
  classes: PropTypes.object.isRequired,
  setSentEmails: PropTypes.func.isRequired,
  rolesData: PropTypes.arrayOf(PropTypes.object).isRequired, 
};

let InviteWithStyle = withStyles({...regularFormsStyle, ...TeamRoleTableStyle})(InviteOrg)

export default withApollo(InviteWithStyle);