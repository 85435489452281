import React, { Component } from 'react';
import Datetime from "react-datetime";
import style from "./style";
import { withStyles } from '@material-ui/core';

export class DatePickerWrapper extends Component {
  constructor (props) {
    super(props);
    this.anchor = null;
    this.state = {
      scroll: false
    }
    this.debounce = null;
  }

  componentDidMount() {
    document.querySelector('.rt-tbody').addEventListener('scroll', () => {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.setState({scroll: !this.state.scroll})
      }, 300)
    });
  }
  
  position = (id) => {
    //dont mess with this code unless you know what you're doing or else this is going to mess up the calendar position
    let top = this.anchorEl ? (this.anchorEl.getBoundingClientRect().top + 35)  : 0;
    const picker = document.querySelector('#' + id + ' .rdtPicker');

    if (picker)  {
      setTimeout(() => {
        if (top + 300 > window.innerHeight) { top = window.innerHeight - 300}
        picker.setAttribute('style', 'top: ' + top + 'px; transition: none !important; display: block !important')
      }, 100)
    }
  }

  render() {
    const { id, dateFormat, defaultValue, placeholder, onChange, key, classes } = this.props;


    return (
      <div 
        class="root"
        key={key}
        ref={node => {
          this.anchorEl = node;
        }}
      >
        <div className={classes.dateWrapper} id={id} style={this.anchorEl ? {} : {}}>
        <Datetime
          closeOnSelect
          style={{marginTop: 300}}
          closeOnTab
          key={id}
          dateFormat={dateFormat}
          timeFormat={false}
          defaultValue={defaultValue}
          inputProps={{
            placeholder,
            onClick: () => this.position(id)
          }}
          onChange={onChange}
        />
      </div>
    </div>
    );
  }
}

export default withStyles(style)(DatePickerWrapper);
