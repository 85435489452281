export default function() {
  window.addEventListener("resize", () => {
    clearTimeout(this.resizeId);
    this.resizeId = setTimeout(() => {
      const { player } = this.state;
      if (player && document && document.getElementById("vimeoPlayerId")) {
        const that = this;
        const videoWidth = document.getElementById("vimeoPlayerId").clientWidth;
        Promise.all([player.getVideoWidth(), player.getVideoHeight()]).then(
          function(dimensions) {
            var width = dimensions[0];
            var height = dimensions[1];
            const ratio = width / height;
            that.setState({
              videoSize: { x: videoWidth, y: videoWidth / ratio, ratio: ratio }
            });
          }
        );
      } else {
        if (this.imgRef)
          this.setState({
            img: { x: this.imgRef.width, y: this.imgRef.height }
          });
      }
      this.resizeId = null;
    }, 500);
  });
}
