// // core components
import React from "react";

import IconButton from "@material-ui/core/IconButton";

import CustomMenu from "components/CustomMenu/CustomMenu.jsx";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Checkbox from "@material-ui/core/Checkbox";
import StatusBedge from "components/StatusBedge/StatusBedge.jsx";
import ModeComment from "@material-ui/icons/ModeComment";
import Check from "@material-ui/icons/Check";
import UploadVersion from "components/Upload/UploadVersion.jsx";
import ArrowRight from "@material-ui/icons/ArrowRight";

import JuridicoIcon from "assets/icons/Juridico";
import {
  GET_JURIDICAL_FOLDERS_QUERY,
  addJuridicalFolder
} from "views/Juridical/JuridicalQueries";
import { moveManyContentsToJuridical } from "views/Content/ContentView/ContentDirectoryQueries";
import avatar from "assets/img/avatar.png";
import pickRowIcon from "utils/pickRowIcon.jsx";
import moment from "moment";
import ModalFolders from "components/ModalFolders/ModalFolders";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";

const filterJuridical = options => {
  const filterCheck = option => {
    let juridicalFilterList = [
      "Enviar ao Jurídico",
      "Renomear",
      "Adicionar Versão",
      "Associar"
    ];
    return juridicalFilterList.includes(option.name);
  };
  return options.filter(option => !filterCheck(option));
};

function ContentListing({
  currentRow,
  content,
  deleteContent,
  juridical,
  downloadContent,
  downloadManyFiles,
  renameContent,
  checkedRows,
  classes,
  moreVertOpen,
  toggleAssociateModal,
  anchorMoreVert,
  toggleTag,
  toggleRow,
  folderId,
  modalOpen,
  projectId,
  cbJuridical,
  editStatus,
  setState,
  userPermissions
}) {
  const markChecked = id => {
    let _checkedRows = [...checkedRows];

    let index = _checkedRows.indexOf(id);
    index = index >= 0 ? index : _checkedRows.length;

    if (_checkedRows[index]) {
      _checkedRows.splice(index, 1);
    } else {
      _checkedRows[index] = id;
    }

    return _checkedRows;
  };

  let moreOptions = [];

  if (
    userPermissions.includes("create-content") &&
    content.status !== "Versão Final" &&
    !juridical
  )
    moreOptions.push({
      name: "Adicionar Versão",
      func: () => this.setState({ modalOpen: currentRow }),
      icon: "add_circle"
    });
  if (userPermissions.includes("edit-content-status"))
    moreOptions.push({
      name: "Status",
      func: () => {},
      icon: "donut_large",
      subOptions: [
        {
          name: "Em Revisão",
          colorIcon: "red",
          icon: "fiber_manual_record",
          func: ({ id, name: status }) => editStatus({ status, id })
        },
        {
          name: "Em Andamento",
          colorIcon: "orange",
          icon: "fiber_manual_record",
          func: ({ id, name: status }) => editStatus({ status, id })
        },
        {
          name: "Versão Final",
          colorIcon: "green",
          icon: "fiber_manual_record",
          func: ({ id, name: status }) => editStatus({ status, id })
        }
      ]
    });
  if (userPermissions.includes("edit-content") && !content.juridical)
    moreOptions.push({
      name: "Enviar ao Jurídico",
      func: () => {},
      icon: JuridicoIcon,
      subOptions: [
        {
          name: "Folders",
          component: (
            <ModalFolders
              projectId={projectId}
              folderId={folderId}
              moveToFolder={obj =>
                moveManyContentsToJuridical({
                  ...obj,
                  cb: error => cbJuridical(error)
                })
              }
              addFolders={addJuridicalFolder}
              foldersQuery={GET_JURIDICAL_FOLDERS_QUERY}
            />
          ),
          isComponent: true
        }
      ]
    });

  moreOptions.push({ type: "hr", name: "hr" });

  moreOptions.push({
    name: content.element
      ? `${content.element.name} - ${content.phase.name}`
      : "Associar",
    disabled: content.status === "Versão Final",
    func: ({ id, cb, phase, element }) =>
      toggleAssociateModal({ id, cb, phase, element }),
    icon: "input"
  });

  moreOptions.push({ type: "hr", name: "hr" });

  if (userPermissions.includes("edit-content"))
    moreOptions.push({
      name: "Renomear",
      disabled: content.status === "Versão Final",
      func: ({ id, name }) => renameContent(id, name),
      icon: "edit"
    });

  if (juridical && downloadManyFiles) {
    moreOptions.push({
      name: "Download",
      func: () => {},
      icon: "cloud_download",
      subOptions: [
        {
          name: "Baixar Arquivo",
          icon: "cloud_download",
          func: ({ id }) => downloadContent(id)
        },
        {
          name: "Baixar Todos Documentos",
          icon: "cloud_download",
          func: () => {
            let fileUrls = [];
            for (let document of content.documents) {
              fileUrls.push(document.downloadLink);
            }
            downloadManyFiles(fileUrls);
          }
        },
        { name: "comentários - CSV", icon: "cloud_download" },
        { name: "comentários - XLS", icon: "cloud_download" }
      ]
    });
  } else {
    moreOptions.push({
      name: "Download",
      func: () => {
        /*downloadContent(id)*/
      },
      icon: "cloud_download",
      subOptions: [
        {
          name: "Baixar Arquivo",
          icon: "cloud_download",
          func: ({ id }) => {
            downloadContent(id);
          }
        },
        { name: "comentários - CSV", icon: "cloud_download" },
        { name: "comentários - XLS", icon: "cloud_download" }
      ]
    });
  }

  if (userPermissions.includes("edit-content"))
    moreOptions.push({
      name: "Deletar",
      func: ({ folderId }) => deleteContent(folderId),
      icon: "delete"
    });

  if (juridical) moreOptions = filterJuridical(moreOptions);
  let row = {};
  const contentStatus = content.status ? content.status : "noStatus";
  const juridicalStatus = content.juridicalStatus
    ? content.juridicalStatus
    : "noStatus";
  const version = content.latestVersion;
  let displayName;
  if (juridical) {
    displayName =
      toggleTag && content.juridicalElement
        ? content.juridicalElement.name + " - " + content.juridicalPhase.name
        : content.name;
  } else {
    displayName =
      toggleTag && content.element
        ? content.element.name + " - " + content.phase.name
        : content.name;
  }
  const contentName = (
    <span>
      <Tooltip
        id="tooltip-top"
        title={displayName}
        placement="top"
        classes={{
          tooltip: classes.tooltip
        }}
      >
        <div className={classes.versionNameEllipses}>{displayName}</div>
      </Tooltip>
    </span>
  );
  row._name =
    toggleTag && content.element
      ? content.element.name + " - " + content.phase.name
      : content.name;
  row.icon = () => pickRowIcon(content.type);
  row.key = currentRow;
  row.addVersion = (
    <UploadVersion
      projectId={content.project.id}
      folderId={content.folder.id}
      contentId={content.id}
      contentType={content.type}
      classes={classes}
      menuOpen={modalOpen === currentRow}
      setMenuModalOpen={() => setState({ modalOpen: -1 })}
      versionName={version.name}
    />
  );

  row.versionCount = (
    <div className={classes.versionText}>{version.count} </div>
  );
  row.id = content.id;
  row.type = content.type;
  row.filter = version.name;
  row.expand = (index, id) => toggleRow(index, id);
  row.arrow = (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
      style={
        content.documents && content.documents.length > 0
          ? { cursor: "pointer" }
          : { visibility: "hidden" }
      }
    >
      <ArrowRight />
    </div>
  );
  row.arrow = !juridical ? null : row.arrow;
  row.documents = content.documents;
  row.checked = checkedRows.some(id => id === content.id);
  row.checkbox = (
    <div
      className={"checkboxTypeWrapper " + (row.checked ? "markedChecked" : "")}
    >
      <Checkbox
        checked={row.checked}
        tabIndex={-1}
        onClick={e => {
          this.setState({ checkedRows: markChecked(content.id) });
          e.stopPropagation();
        }}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{
          checked: classes.checked,
          root: classes.checkRoot
        }}
      />
      {pickRowIcon(content.type)}
    </div>
  );
  row.name = (
    <div
      data-name={displayName}
      style={{ display: "flex", maxWidth: "Calc(100% - 80px)" }}
    >
      <div style={{ display: "flex", width: "100%" }}>
        <span className={classes.title}> {contentName} </span>
      </div>
      <div
        className={classes.versionWrapper}
        onClick={e => {
          this.setState({ modalOpen: currentRow });
          e.stopPropagation();
        }}
      >
        <div className={classes.version}>
          <div>{row.versionCount}</div>
          {content.status !== "Versão Final" && !juridical && (
            <span> {row.addVersion} </span>
          )}
        </div>
      </div>
    </div>
  );
  row.status = (
    <div data-name={juridical ? juridicalStatus : contentStatus}>
      {" "}
      <StatusBedge
        contentJuridical={content.juridical}
        juridical={juridical}
        status={contentStatus}
        juridicalStatus={juridicalStatus}
      />{" "}
    </div>
  );
  row.comment = (
    <div
      data-name={
        juridical
          ? content.latestVersion.juridicalComments.length
          : content.latestVersion.comments.length
      }
      className={classes.comment}
    >
      <ModeComment />
      <div>
        {" "}
        {juridical
          ? content.latestVersion.juridicalComments.length
          : content.latestVersion.comments.length}{" "}
      </div>
    </div>
  );
  row.upload = (
    <span data-date={moment(content.latestVersion.creationDate)}>
      {" "}
      {moment(content.latestVersion.creationDate).format("DD/MM/YYYY")}{" "}
    </span>
  );

  row.por = (
    <div
      data-name={content.latestVersion.uploader ? content.latestVersion.uploader.name : ""}
      className={classes.avatar}
    >
      {
        version.uploader && 
        <Link to={`/admin/perfil/${version.uploader.id}`}>
        <img
          src={
            (content.latestVersion.uploader && content.latestVersion.uploader.image)
              ? content.latestVersion.uploader.image.imageUrl
              : avatar
          }
          alt=""
        />
      </Link>
      }
      
    </div>
  );
  row.option = (
    <div>
      <IconButton
        aria-label="More"
        aria-haspopup="true"
        onClick={e => {
          if (e)
            this.setState({
              anchorMoreVert: e.currentTarget,
              moreVertOpen: currentRow
            });
          e.stopPropagation();
        }}
        classes={{ root: classes.iconButton }}
      >
        <div className="">
          <MoreVertIcon />
        </div>
      </IconButton>
      {moreVertOpen === currentRow && (
        <CustomMenu
          options={moreOptions}
          callBackArgs={{
            id: content.id,
            folderId: content.folder.id,
            phase: content.phase,
            element: content.element
          }}
          isOpen={moreVertOpen === currentRow}
          onClose={e => {
            this.setState({ moreVertOpen: -1 });
            e.stopPropagation();
          }}
          anchorEl={anchorMoreVert}
          style={{
            style: {
              maxHeight: 450,
              width: 210,
              marginTop: 45,
              marginLeft: -40,
              textAlign: "center",
              borderRadius: "15px"
            }
          }}
        />
      )}
    </div>
  );
  return row;
}

export default ContentListing;
