import React from "react";
import classNames from "classnames";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import ExitAppIcon from '@material-ui/icons/ExitToApp';
import { myUser as MY_USER_QUERY} from 'queries/queries.js';

// @material-ui/icons
import Notifications from "@material-ui/icons/Notifications";

// core components
import Button from "components/CustomButtons/Button.jsx";
import avatar from "assets/img/avatar.png";
import { Query, Mutation } from 'react-apollo'
import { Button as MaterialButton } from '@material-ui/core';

import Avatar from 'components/Avatar/Avatar.jsx';
import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";
import Notification from "components/Notification/Notification.jsx";
// import Loading from 'components/Loading/Loading';
import { NOTIFICATION_SUBSCRIPTION, NOTIFICATIONS_QUERY, SEE_NOTIFICATION_MUTATION } from 'components/Notification/notificationQueries.js';
import { ContextConsumer } from 'ContextController.js'
class HeaderLinks extends React.Component {
  constructor(props){
    super(props)
    this.unsubscribe = null;
    this.state = {
      open: false,
      unseenNotifications: null
    }
  }

  handleClick = async (seeNotifications) => {
    if( this.state.open === true ) return;
    // This await is actually necessary for some reason
    await this.setState({ open: true });
    seeNotifications({ refetchQueries: () => [{ query: MY_USER_QUERY }] });
    this.clearUnseenNotifications();
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClickAway = async () => {
    setTimeout(() => {
      this.setState({ open: false });
    }, 100);
  };

  pushUnseenNotifiction(){
    this.setState({ unseenNotifications: this.state.unseenNotifications + 1 });
  };

  clearUnseenNotifications(){
    this.setState({ unseenNotifications: 0 });
  }

  render() {
    const { classes, rtlActive } = this.props;
    const { open, loggedUser, unseenNotifications, isRedirected } = this.state;

    const dropdownItem = classNames(
      classes.dropdownItem,
      classes.primaryHover
    );
    const managerClasses = classNames({
      [classes.managerClasses]: true
    });
    let notificationsToShow = 10;
    return (
      <div style={{display: "flex", justifyContent:"space-around"}}>
        <ContextConsumer>
          {(context)=>{
            let _loggedUser = context.myUser;
            if((!this.state.loggedUser || _loggedUser !== this.state.loggedUser)|| !this.state.unseenNotifications){
              let shouldUpdateNotification = (this.state.unseenNotifications === null || this.state.unseenNotifications !==_loggedUser.unseenNotifications);
              if((!this.state.loggedUser || _loggedUser !== this.state.loggedUser)){
                this.setState({ loggedUser: _loggedUser });
              }
              if(shouldUpdateNotification){
                this.setState({ unseenNotifications: _loggedUser.unseenNotifications ? _loggedUser.unseenNotifications : 0 });
              }
            }
        
            return(
              <span style={{display: "flex", justifyContent:"space-around", alignItems:"center"}}>
              <div className={managerClasses}>
                <Mutation mutation={SEE_NOTIFICATION_MUTATION}>
                  {(seeNotifications)=>{
                    return(
                    <Button
                      color="transparent"
                      justIcon
                      aria-label="Notifications"
                      aria-owns={open ? "menu-list" : null}
                      aria-haspopup="true"
                      onClick={() => open ? null : this.handleClick(seeNotifications)}
                      className={classes.buttonLink}
                      buttonRef={node => {
                        this.anchorEl = node;
                      }}
                    >
                      <Notifications
                        className={
                          classes.notificationIcon + ' ' + 
                          classes.headerLinksSvg +
                          ' ' + classes.links
                        }
                      />
                      { unseenNotifications !== 0 &&
                        <span className={classes.notifications}>{unseenNotifications}</span>
                      }
                      <Hidden mdUp implementation="css">
                        <span onClick={() => open ? null : this.handleClick} className={classes.linkText}>
                          {"Notification"}
                        </span>
                      </Hidden>
                    </Button>);
                  }}
                </Mutation>
                <Query query={NOTIFICATIONS_QUERY} variables={{limit:notificationsToShow}}>
                {({ data, error, loading, subscribeToMore, fetchMore, refetch }) => {
                  if(error) return <span/>;
                  if(loading) return null;
                  if(!this.unsubscribe) {
                      this.unsubscribe = subscribeToMore({
                        document: NOTIFICATION_SUBSCRIPTION,

                        updateQuery: (prev, { subscriptionData }) => {
                          this.pushUnseenNotifiction();
                          if (!subscriptionData.data) return prev;
                          let { newNotification } = subscriptionData.data;
                          return { notifications:[newNotification, ...prev.notifications.filter((notification) => notification.id !== newNotification.id)] };
                        }
                      });
                  } if(data.notifications) {
                    return(
                          <Popper
                            open={open}
                            anchorEl={this.anchorEl}
                            transition
                            disablePortal
                            placement="bottom"
                            className={classNames({
                              [classes.popperClose]: !open,
                              [classes.pooperResponsive]: true,
                              [classes.pooperNav]: true
                            })}
                          >
                            {({ TransitionProps, placement }) => (
                              <ClickAwayListener onClickAway={this.handleClickAway}>
                              <Grow
                                {...TransitionProps}
                                id="menu-list"
                                style={{ transformOrigin: "0 0 0" }}
                              >
                                  <Paper className={classes.dropdown}>
                                    <MenuList role="menu">
                                      <div className={classes.notificationsWrapper} key={data.notifications.length*999}>
                                        <>
                                        <MenuItem
                                          onClick={this.handleClose}
                                          className={dropdownItem}
                                          key='Header'
                                          classes={{primaryHover: classes.primaryHover}}
                                          >
                                            <div className={classes.header}>
                                              Notificações
                                            </div>
                                        </MenuItem>
                                        {data.notifications.map((notification, index) => {
                                          return( 
                                            <MenuItem
                                              onClick={this.handleClose}
                                              className={dropdownItem}
                                              key={index*999 + notification.type}
                                              classes={{primaryHover: classes.primaryHover}}
                                            >
                                              <Notification 
                                                notification={notification} 
                                                notificationRefetch={(params) => refetch(params)} 
                                                refetchLimit={data.notifications.length} 
                                              />
                                            </MenuItem>
                                          )
                                        })}
                                        </>
                                        <div style={{display:"flex", justifyContent:"space-around"}}>
                                          <MaterialButton
                                            variant="outlined"
                                            size={'small'}
                                            color={"primary"} 
                                            onClick={()=>{
                                              fetchMore({
                                                variables: {
                                                  offset: data.notifications.length,
                                                  limit: notificationsToShow
                                                },
                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                  if (!fetchMoreResult) return prev;
                                                  return Object.assign({}, prev, {
                                                    notifications: [...prev.notifications, ...fetchMoreResult.notifications]
                                                  });
                                                }
                                              })
                                            }}
                                          >
                                            mais
                                          </MaterialButton>
                                        </div>
                                      </div>
                                    </MenuList>
                                    <div className={classes.notificationMenuFooter}>
                                      <Link from={"/"} to={"/admin/notifications"}>
                                        <MaterialButton  color={"primary"} variant="outlined" size={'small'} >
                                          Ver Tudo
                                        </MaterialButton>
                                      </Link>
                                    </div>
                                  </Paper>
                              </Grow>
                              </ClickAwayListener>
                            )}
                          </Popper>
                    );
                  }
                  else return null;
                  }}
                </Query>
              </div>
                {loggedUser && 
                  <div>
                    <Avatar 
                      img={loggedUser.image ? loggedUser.image.imageUrl : avatar} 
                      userId={loggedUser.id} 
                      style={{marginTop:"5px", marginLeft:"5px" ,marginRight:"5px" }}
                    />
                  </div>
                }
                  {isRedirected  && <Redirect  to={isRedirected}></Redirect>}
                  <Button
                    color="transparent"
                    justIcon
                    aria-label="ExitAppIcon"
                    aria-haspopup="true"
                    onClick={() => this.setState({ isRedirected:"/logout" })}
                    aria-owns={open ? "menu-list" : null}
                    className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                    muiClasses={{
                      label: rtlActive ? classes.labelRTL : ""
                    }}
                  >
                    <ExitAppIcon />
                 </Button>
              </span>)
          }}
        </ContextConsumer>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(adminNavbarLinksStyle)(HeaderLinks);
