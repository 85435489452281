import React, { Component, createRef } from 'react';

import withStyles from "@material-ui/core/styles/withStyles";
//import 'src/assets/jss/material-dashboard-pro-react/customSelectStyle.jsx'
import Modal from '@material-ui/core/Modal';
// @material-ui/icons
import { Attachment, AddCircle } from "@material-ui/icons";
// core components
import Button from "components/CustomButtons/Button.jsx";
//import  { vimeoToken } from "queries/queries";
import UploadContentStyle from "./UploadContentStyle.js";

import Dropzone from 'components/Dropzone/Dropzone.jsx';
import VerificationDialog from "components/VerificationDialog/VerificationDialog";

import { 
    setUploadType,
    queueUpload,
    onProgress,
    onSuccess,
    handleChange,
    removeFile,
    onDragOver,
    onDragLeave,
    fileUpload,
    handleClose,
    setPhase,
    setSelectingElements,
    handleClickOpen,
    setElement,
    vimeoUpload,
    isObjectEmpty
} from "components/Upload/uploadUtils.js"
import FileUploadCard from './FileUploadCard.jsx';

class UploadVersion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            uploadForm: {},
            modalOpen: false,
            progressPercentage: {},
            done: false,
            event: null,
            fileStack: null,
            nextFile: null,
            nUploads:[],
            uploads: [],
            uploadedFiles: 0,
            successUploads: [],
            selectingElements: true,
            sweetAlert: false,
            elements: [],
            element: null,
            phases: [],
            phase: null,
            onMenu: this.props.menuOpen,
            loading: false
        };
        // ||| this is here so that we can use this.state and this.props 
        // ||| inside functions from uploadUtils
        // VVV EVEN THE ONES NOT USED IN THE  FILED DO *NOT* DELETE!
        this.handleChange = handleChange.bind(this);
        this.queueUpload = queueUpload.bind(this);
        this.onProgress = onProgress.bind(this);
        this.onDragOver = onDragOver.bind(this);
        this.onSuccess  = onSuccess.bind(this);
        this.removeFile = removeFile.bind(this);
        this.onDragLeave = onDragLeave.bind(this);
        this.fileSelector = createRef();
        this.fileUpload = fileUpload.bind(this);
        this.handleClose = handleClose.bind(this);
        this.setPhase = setPhase.bind(this);
        this.setSelectingElements = setSelectingElements.bind(this);
        this.handleClickOpen = handleClickOpen.bind(this);
        this.setElement = setElement.bind(this);
        this.vimeoUpload = vimeoUpload.bind(this);
    }

    componentDidUpdate(prevState) {
        const { done } = prevState;
        if (done) {
            this.handleClose("modalOpen");
            this.setState({ done: false });
        }
    }

    componentDidMount() {
        if (this.props.menuOpen) this.setState({ onMenu: true });
    }

    render() {
        const { classes, menuOpen, contentType } = this.props;
        const { 
            fileStack, 
            progressPercentage, 
            modalOpen, 
            successUploads, 
            isDragging, 
            sweetAlert, 
            nUploads,
            loading
        } = this.state;
        const uploads = fileStack ? fileStack.map(file => file.name) : [];
        let uploadType = setUploadType({ contentType });
        return (
            <div>
                {sweetAlert && isObjectEmpty(progressPercentage) && <VerificationDialog
                    open={sweetAlert && isObjectEmpty(progressPercentage)}
                    title='Você tem certeza que deseja fechar este modal?'
                    handleClose={() => { this.setState({ sweetAlert: false })}}
                    onAccept={() => { this.handleClose("modalOpen"); this.setState({ sweetAlert: false }); }}
                />}
                <span onClick={() => this.handleClickOpen("modalOpen")}>
                    <div className={classes.plusCount}
                        color="primary"
                    >
                        <AddCircle />
                    </div>
                </span>
                <div style={{ height: "30vh !important" }}>
                    <Modal
                        onBackdropClick={(e) => { 
                            if(uploads.length > 0) this.setState({ sweetAlert: true });
                            else this.handleClose("modalOpen");
                            e.stopPropagation(); 
                        }}
                        open={modalOpen || menuOpen}
                    >
                        <div className={classes.wrapper} >
                            <div 
                                onClick={(e) => { 
                                    if(uploads.length > 0) this.setState({ sweetAlert: true });
                                    else this.handleClose("modalOpen");
                                    e.stopPropagation(); 
                                }} 
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => e.preventDefault()} 
                                className={classes.mask} 
                            />
                            <div className={classes.header}>
                                <div className={classes.tabWrapper}>
                                    <input
                                        accept={uploadType}
                                        ref={ref => {
                                            if (ref && modalOpen && this.fileSelector && this.fileSelector.current === null) {
                                                ref.click();
                                            }
                                            this.fileSelector = ref;
                                        }}
                                        className={classes.inputNone}
                                        type="file"
                                        onChange={this.handleChange}
                                    />
                                    <Button onClick={() => this.fileSelector.click()} color="circlePurple"  >
                                        <Attachment classes={{ root: classes.btnIcon }} />
                                        Escolher Arquivo
                                    </Button>
                                </div>
                            </div>
                            <div onDragOver={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()} className={classes.modalBody} style={{ minHeight: "260px", zIndex:999 }}>
                                {!isDragging && uploads && uploads.length > 0 &&
                                    <FileUploadCard 
                                        progressPercentage={progressPercentage} 
                                        successUploads={successUploads} 
                                        nUploads={nUploads} 
                                        uploads={uploads}
                                        onDragOver={this.onDragOver}
                                        handleClose={this.handleClose}
                                        removeFile={this.removeFile}
                                    />   
                                }
                                {(uploads.length === 0 || isDragging) && <Dropzone onDragOver={this.onDragOver} onDragLeave={this.onDragLeave} handleChange={this.handleChange}  />}
                                <div style={{ display: "flex", width: "100%", flexDirection:"row-reverse" }}>
                                    <Button loading={loading} style={{ marginRight: "30px", marginBottom:"20px", zIndex:999 }} color="purple" onClick={()=> this.queueUpload()}>Salvar</Button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}
let { plusCount, wrapper, mask, header, tabWrapper, inputNone, btnIcon, adjustedMargin } = UploadContentStyle;
export default withStyles({ plusCount, wrapper, mask, header, tabWrapper, inputNone, btnIcon, adjustedMargin })(UploadVersion);
