const style =  {
  standAlone: {
    '& .ReactTable .rt-thead': {
      display: 'inherit !important',
      '& .rt-th': {
        boxShadow: "none !important"
      },
    },
    '& .ReactTable': {
      border: 'none !important'
    },
    '& .ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after': {
      boxShadow: "none !important"
    
    },
    '& .rt-th': {
      border: 'none !important',
      boxShadow: "none !important"
    },
    
  },
  root: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '15px',
    fontWeight: 'normal',
    '& .ReactTable .rt-thead': {
      display: 'none',
    },
    '& .ReactTable': {
      borderTop: '1px solid #6e619773',
      overflow: "hidden"
    },
    '& .rt-tr-group': {
      paddingLeft: '45px',
      backgroundColor: 'transparent !important',
      '&:hover': {
        backgroundColor: 'white',
      }
    },
    '& .rt-tr:not(.-padRow):hover': {
      backgroundColor: 'white !important',
    },
    '& .rt-td:first-child svg': {
      color: '#5a4b88'
    },
    '& .rt-td': {
      padding: '1px'
    },
    '& .rt-tbody .rt-tr-group:nth-child(odd), .rt-tr.-odd': {
      backgroundColor: 'white !important'
    },
    '& + $standAlone': {
      backgroundColor: 'black'
    }
  },
  avatar: {
    '& img': {
      minWidth: '25px',
      width: '25px',
      height: '25px',
      minHeight: '25px',
      borderRadius: '50%',
      border: '1px solid black',
      marginLeft: '5px',
      marginTop: '8px'
    }
  },
  iconButton: {
    padding: 0,
    cursor: 'default',
    transition: 'all 0.5s linear',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'rgba(0, 0, 0, 0.77)'
    }
  },
  date: {
    fontWeight: '300',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px'
  },
  noComments: {
    height: '2px',
    width: '26px',
    backgroundColor: '#d2d1d6d9'
  },
  statusOrange: {
    color: 'orange',
    fontSize: 14,
    marginTop: 4,
    marginRight: 5
  },
  statusRed: {
    color: 'red',
    fontSize: 14,
    marginTop: 4,
    marginRight: 5
  },
  statusGreen: {
    color: 'green',
    fontSize: 14,
    marginTop: 4,
    marginRight: 5
  },
  statusWrapper: {
    display: 'flex',
    '& span': {
      fontSize: 13,
      marginRight: 10,
    },
  },
  nameWrapper: {
    paddingTop:"5px",
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: "50px",
    maxWidth: "80%"
  },
  titleWrapper: {
    display:"flex",
    justifyContent:"flex-start",
    alignItems:"baseline",
    flexGrow:1
  }
}
export default style;