import React from 'react';
import { withStyles, Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

const colorPickerStyles = {
  root:{
    display:"flex",
    width:"100%",
    padding:"5px 10px 5px 10px",
    justifyContent:"center",
  },
  colorsWrapper:{
    minHeight:"150px",
    width:"250px",
    display:"flex",
    flexWrap:"wrap",
    justifyContent:"center",
    marginTop:"5px"
  },
  colorBtn:{
    borderRadius:"4px",
    width:"32px",
    height:"24px",
    marginRight:"5px",
    marginTop:"2px !important",
    display:"flex",
    color:"white"
  },
  colorBtnIcon:{
    marginTop:"-6px"
  }
}


function ColorPicker({ classes, pallet, setColor }) {
    const [ selectedColor, setSelectedColor ] = React.useState(null);
    return (
      <div className={classes.root}>
        <div className={classes.colorsWrapper}>
          <span style={{ marginTop:"6px" }}>Selecione uma cor para a nova tag:</span>
          {pallet.map((color, index) => {
            if(!color) return null;
            return(
            <Button 
              classes={{ root:classes.colorBtn }} 
              style={{ backgroundColor:color }} 
              onClick={()=> { setSelectedColor(index); setColor(color) }}
            >
              {selectedColor === index && <CheckIcon className={classes.colorBtnIcon}/>}
            </Button>)
          })}
        </div>
      </div>
    );
}
export default withStyles(colorPickerStyles)(ColorPicker);