import { cardTitle, card } from "assets/jss/material-dashboard-pro-react.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const FoldersContentTableStyle = theme => ({
  ...regularFormsStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  card: {
    ...card,
    marginTop: '40px',
    marginBottom: 0,
    overflow: 'hidden !important',
  },
  contentCard: {
    height: '100%'
  },
  juridicalCard: {
    height: 'Calc(100% - 40px)'
  },
  emptyState: {
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '50px',
    cursor: 'pointer'
  },
  loading: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  arrow: {
    cursor: 'pointer'
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  tagLabel: {
    fontSize: 14,
    fontWeight: 500,
    color: '#2c1c59',
  },
  clipIcon: {
    marginRight: '15px',
  },
  btnDocument: {
    backgroundColor: 'transparent',
    border: '1px solid #3F2C78',
    color: 'black',
    marginRight: '15px',
    padding: '0 10px',
    height: 32,
    marginTop: 10,
    borderRadius: 10,
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #b5a8de'
    }, 
    '&:focus': {
      backgroundColor: 'transparent',
      color: 'black',
      boxShadow: 'none'
    }
  },
  restrainInputWidth: {
    maxWidth: '197px',
    minWidth: '197px',
    paddingTop: 0,
    marginTop: '-18px',
    marginRight: '15px'
  },
  underlineInput: {
    marginTop: '25px !important'
  },
  inputAdornment: {
    '& svg': {
      color: '#AAAAAA',
      height: '16px'
    }
  },
  filterDropDownManager: {
    width: '115px'
  },
  attachmentWrapper: {
    display: 'flex',
    color: '#3f2c78',
    cursor: 'pointer',
    '& div': {
      fontSize: 15,
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'normal',
      marginLeft: 5
    }
  },
  dropDown: {
    backgroundColor: 'transparent',
    color: '#AAAAAA',
    boxShadow: 'none',
    padding: "6px 5px 5px 0px",
    borderBottom: '1px solid #D2D2D2',
    maxWidth: '160px',
    borderRadius: 0,
    width: '100%',
    textTransform: 'initial',
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: '#AAAAAA',
      boxShadow: 'none',
    },
  },
  dropDownButtonText: {
    fontFamily: 'Roboto, sans-sarif !important',
    fontSize: '14px'
  },
  buttonTextWrapper: {
    flex: '1',
    display: 'flex',
    justifyContent: 'space-between'
  },  
  headerIconWrapper: {
    minWidth: '155px',
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '15px'
  },
  headerIcon: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    textAlign: 'center',
    marginTop: '10px',
    transition: 'all 0.5s ease',
    cursor: 'pointer',
    '& svg': {
      color: '#555555',
      height: '18px',
      marginTop: '6px',
      '& .icon': {
        fill: '#555555',
      }
    },
    '&:hover': {
      backgroundColor: '#3F2C78',
      '& svg': {
        color: 'white',
        '& .icon': {
          fill: 'white',
        }
      }
    }
  },
  tooltip: {
    fontSize: '15px',
    backgroundColor: 'black'
  },
  iconDisabled: {
    cursor: 'default',
    pointerEvents: 'none',
    '& svg': {
      color: '#d2d2d2 !important',
      '& .icon': {
        fill: '#d2d2d2 !important',
      }
    },
    '&:hover': {
      backgroundColor: "transparent",
      '& svg': {
        color: '#d2d2d2',
        '& .icon': {
          fill: '#d2d2d2',
        }
      }
    }
  },
  checkRoot: {
    padding: '2px',
  },
  tableDiv: {
    height: "100%"
  },
  contentViewTable: {
    '& .ReactTable': {
      border: 'none',
      '& .rt-noData': {
        top: '80px !important'
      },
      '& .rt-table': {
        width: '100%',
        overflowX: 'hidden',
        '& .checkboxTypeWrapper ': {
          cursor: 'pointer',
          display: 'flex',
          '& svg:nth-child(1)': {
            display: 'none',
            marginLeft: '13px',
          },
          '& svg:nth-child(2)': {
            lineHeight: '10px',
            color: '#3F2C78',
            marginLeft: '5px',
          },
          '& input': {
            height: '42px',
            marginTop: '-12px',
            width: '55px',
            marginLeft: '-10px',
          }
        },
       '& .markedChecked': {
          '& svg:nth-child(2)': {
            display: 'none',
            '& $checkRoot': {
              padding: 0,
            }
          },
          '& span svg:nth-child(1)': {
            display: 'block',
          }
        },
        '& .rt-expandable': {
          display: 'none !important',
        },
        '& ::-webkit-scrollbar': {
          width: '9px',
          height: '9px',
        },
        '& ::-webkit-scrollbar-button': {
          width: '0px',
          height: '0px',
        },
        '& ::-webkit-scrollbar-thumb': {
          background: '#a5abba',
          border: '0px none #ffffff',
          borderRadius: '2px',
        },
        '& ::-webkit-scrollbar-thumb:hover': {
          background: '#a5abba',
        },
        '& ::-webkit-scrollbar-thumb:active': {
          background: '#a5abba',
        },
        '& ::-webkit-scrollbar-track': {
          background: '#eaeaea',
          border: '0px none #ffffff',
          borderRadius: '2px',
        },
        '& ::-webkit-scrollbar-track:hover': {
          background: '#babcd6',
        },
        '& ::-webkit-scrollbar-track:active': {
          background:'#babcd6',
        },
        '& ::-webkit-scrollbar-corner': {
          background: 'transparent',
        }
      },
      '&, .rt-thead.-header': {
        boxShadow: 'none',
        borderBottom: 'solid 1px #DDDDDD',
      },
      '& .rt-tbody .rt-tr-group:nth-child(odd)': {
        background: '#F9F9F9'
      },
      '& .rt-tr-group': {
        borderBottom: 'solid 1px #DDDDDD',
        cursor: 'pointer',
        minWidth: 'fit-content',
        maxWidth: '100%',
        height: 'auto'
      },
      '& .rt-tr': {
        display: 'flex',
      },
      '& .checkboxTypeWrapper + .markedChecked': {
        '& svg:nth-child(2)': {
          display: 'none',
          '& $checkRoot': {
            padding: 0,
          }
        },
        '& svg:nth-child(1)': {
          display: 'block',
        }
      },
      '& .rt-tr-group:hover': {
        '& .checkboxTypeWrapper': {
          '& svg:nth-child(2)': {
            display: 'none',
            '& $checkRoot': {
              padding: 0,
            }
          },
          '& svg:nth-child(1)': {
            display: 'block',
          }
        },
      },
      '& .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after': {
      },
      '& .rt-thead .rt-resizable-header:last-child': {
        textAlign: 'left'
      },
      '& .rt-tbody': {
        overflowY: 'auto',
        overflowX: 'auto',
        minWidth: '100% !important',
        '&::-webkit-scrollbar': {
          width: 5,
          height: 5,
        },
        '&::-webkit-scrollbar-button': {
          width: 0,
          height: 0,
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#e1e1e1',
          border: '0px none #ffffff',
          borderRadius: 50,
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#ffffff',
        },
        '&::-webkit-scrollbar-thumb:active': {
          background: '#000000',
        },
        '&::-webkit-scrollbar-track': {
          background: '#8e9092',
          border: '0px none #ffffff',
          borderRadius: 59,
        },
        '&::-webkit-scrollbar-track:hover': {
          background: '#8e9092',
        },
        '&::-webkit-scrollbar-track:active': {
          background: '#333333',
        },
        '&::-webkit-scrollbar-corner': {
          background: 'transparent',
        },
      },
      '& .rt-tbody .rt-td': {
        border: 'none',
        textAlign: 'left',
        display: 'flex',
        overflow: 'visible'
      },
      '& .rt-th': {
        '& div': {
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          marginLeft: '0px !important',
        },
        '&.-cursor-pointer > div:first-of-type:after': {
          display: 'none',
          fontSize: '13px',
          marginTop: '1px',
          height: '14px',
        }, 
        '&:hover': {
          '&.-cursor-pointer > div:first-of-type:after': {
            display: "block",
          }
        }
      },
      '& .rt-thead .rt-th': {
        fontFamily: 'Montserrat',
        fontSize: '18px',
        fontWeight: '500',
        color: '#3C4858',
        textAlign: 'left',
        border: 'none',
        boxShadow: 'none'
      },
      '& .rt-th:first-child': {
        width: '0px !important',
        padding: 0,
        margin: 0,
        display:'none'
      }, 
      '& .rt-th:nth-child(2)': {
       maxWidth: '30px !important',
       minWidth: '28px !important',
        padding: 0,
        margin: 0,
        visibility: 'hidden',
      }, 
      '& .rt-thead .rt-th:first-child, .rt-thead .rt-th:nth-child(2)': {
        marginLeft: 0,
        marginRight: '5px'
      },
      '& .rt-tbody .rt-td:first-child, .rt-tbody .rt-td:nth-child(2)': {
        marginLeft: 0,
        marginRight: '5px'
      },
      '& .rt-tbody .rt-td:first-child, .rt-tbody .rt-td:last-child': {
        // marginBottom: 0,
        // padding: '0',
        // marginRight: '5px',
        // marginTop: 0,
        // '& svg': {
        //   marginTop: '10px'
        // }
      },
      '& .rt-thead .rt-th:last-child, .rt-thead .rt-th:nth-child(6)': {
        marginRight: 0,
        marginLeft: 0,
      },
      '& .rt-tbody .rt-td:last-child, .rt-tbody .rt-td:nth-child(6)': {
        marginRight: 0,
        marginLeft: 0,
      },
    },
  },

  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '45px'
  },
})

export default FoldersContentTableStyle;