import React, { Component } from 'react';
import { LinearProgress, withStyles } from '@material-ui/core';
import style from "./style"; 
export class LinearProgressWrapper extends Component {
  render() {
    const { startDate, endDate, classes, ...rest } = this.props;
    return (
      <div className={classes.root}>
        <LinearProgress variant='determinate' {...rest} />
        <div className={classes.endDate}> {endDate} </div>
      </div>
    );
  }
}

export default withStyles(style)(LinearProgressWrapper);
