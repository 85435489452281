import moment from "moment";

export default function(date) {
  try {
    let verifiedDate = moment(date).format("DD/MM/YYYY");
    if (verifiedDate === "Invalid date") return false;
    return verifiedDate;
  } catch (error) {
    return false;
  }
}
