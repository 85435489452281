import React from "react";

// core components
import Search from "@material-ui/icons/Search";
import ReactTable from "react-table";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import TeamRoleTableStyle from "./TeamRoleTableStyle.js";
import { withStyles } from "@material-ui/core";
import { withApollo } from "react-apollo";

class OrgTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      emails: [],
      email: "",
      roles: [],
      emailVerification: '',
      inputRef: React.createRef(),
    }
  }
  searchBar = () => {
    const { classes } = this.props;
    const { inputRef } = this.state;
    return (
      <div>
        <CustomInput
          classes={{ formControl: classes.searchInput }}
          disableUnderline
          formControlProps={{
            className: classes.top + " " + classes.search
          }}
          inputProps={{
            onChange: (e) => this.setState({ search: e.target.value }),
            inputProps: {
              ref: (ref) => this.setState({inputRef: ref}),
              "aria-label": "Nome",
              className: classes.searchInput
            }
          }}
        />
        <Button
          style={{marginLeft:"-15px"}}
          onClick={() => inputRef ? inputRef.focus() : null}
          color="white"
          aria-label="edit"
          justIcon
          round
        >
          <Search
            className={classes.headerLinksSvg + " " + classes.searchIcon}
          />
        </Button>
      </div>
    )
  }


  render() {
    const { classes, data, header = 'Organização' } = this.props;
    const { search } = this.state;
    let collumns = [{
      Header: header,
      accessor: "name",
    },{
      Header: (this.searchBar()),
      accessor: "search",
    }];
    const _data = data.filter((organization) => organization._name.toLowerCase().includes(search.toLowerCase()))
    return (
      <div className={classes.tableWrapper}>
        <ReactTable
          data={_data}
          showPagination={false}
          pageSize={_data.length}
          sortable={false}
          noDataText='Nenhuma Organização foi encontrada'
          resizable={false}
          getTheadProps={() => {
            return {
              style: {
                height: '70px',
              }
            }
          }}
          columns={collumns}
          defaultPageSize={data.length}
          showPaginationBottom={false}
        />
      </div>
    )
  }
}

export default withApollo(withStyles({ ...regularFormsStyle, ...TeamRoleTableStyle })(OrgTable));