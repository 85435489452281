import { gql } from "apollo-boost";

const REGISTER_USER_MUTATION = gql`
  mutation REGISTER_USER_MUTATION(
    $name: String!
    $phoneNumber: String
    $cellphoneNumber: String!
    $password: String!
    $passwordSalt: String!
    $email: String!
    $imageId: ID
  ) {
    registerUser(
      name: $name
      password: $password
      phoneNumber: $phoneNumber
      cellphoneNumber: $cellphoneNumber
      passwordSalt: $passwordSalt
      email: $email
      imageId: $imageId
    ) {
      id
      name
      organization {
        id
        name
      }
      role {
        id
        name
      }
      passwordSalt
    }
  }
`;

export { REGISTER_USER_MUTATION };
