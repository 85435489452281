import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Input from '@material-ui/core/Input';

const style = {
    underline: {
        '&::before': {
            border: 'none'
        }
    },
    input: {

    },
    focused: {
        
    }
  };

const EditableField = ({classes, text,key, onChange, id, wrapperId, placeholder, multiline = undefined}) => {
    return (
        <div id={wrapperId} className={classes.root}>
            <Input
                key={key}
                classes={{
                    underline: classes.underline, 
                    input: classes.input,
                    focused: classes.focused,
                }}
                multiline={multiline}
                value={text} 
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value, id)} 
            />
        </div>
    )
}

export default withStyles(style)(EditableField);