import React from "react";
import VideoLibrary from "@material-ui/icons/VideoLibrary";
import Description from "@material-ui/icons/Description";
import Image from '@material-ui/icons/Image';
import AttachFile from '@material-ui/icons/AttachFile';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
const pickRowIcon = (type) => {
  switch (type) {
    case 'vimeo':
      return < VideoLibrary />
    case 'onlyoffice':
      return < Description />
    case 'attach':
      return <AttachFile/>
    case 'audio':
      return <LibraryMusicIcon/>
    default:
      return <Image />;
  }
};

export default pickRowIcon;
