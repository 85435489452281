import React from "react";

// @material-ui/icons
import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import mascote from "assets/img/mascote.png";
import addProjectWizardStyle from "../addProjectWizardStyle.jsx";
import DateVerification from "utils/DateVerification";

class Step1 extends React.Component {

  
  constructor(props) {
    super(props);

    this.updateFile = this.updateFile.bind(this);
    this.state = {
      projectName: props.stepProps ? props.stepProps.projectName : '',
      projectNameState: "",
      description: props.stepProps ? props.stepProps.description : '',
      genre: props.stepProps && props.stepProps.genre ? props.stepProps.genre : "Gênero",
      genreState: "",
      file:null,
      image: props.stepProps ? props.stepProps.image : '',
      startDate: props.stepProps ? props.stepProps.startDate : '',
      endDate: props.stepProps ? props.stepProps.endDate : '',
      startDateState: "",
      endDateState: "",
    };
  }
  
  onClickDropdown = (genre) => {
    this.setState({genreState: 'success', genre: genre})
  }

  sendState() {
    return this.state;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  updateFile(file) {
    this.setState({ file: file });
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  verifyGenre = () => {
    const { genre } = this.state;
    if (genre === 'Gênero' || genre === '' || !genre) {
      this.setState({genreState: 'error'})
      return false;
    } else {
      this.setState({genreState: 'success'})
      return true;
    }
  }

  isValidated() {
    const genreState = this.verifyGenre(this.state.genre);
    const {startDateState, endDateState, startDate, endDate } = this.state;
    if ((this.state.projectName.length > 0 )&& 
      genreState !== 'error' && 
      startDateState !== 'error' &&  
      endDateState !== 'error' && startDate !== "" && endDate !== "") {
        return true
      } else {
        if (this.state.projectName.length === 0) {
          this.setState({projectNameState: 'error'});
        } if (!genreState) {
          this.setState({genreState: 'error'});
        } if (!endDate) {
          this.setState({endDateState: 'error'});
        } if (!startDate) {
          this.setState({startDateState: 'error'});
        }
        return false;
      }
  }
  render() {
    const { classes } = this.props;

    const {
      genre, 
      startDate,
      startDateState,
      endDate, 
      endDateState,
      description,
      projectName,
      image 
    } = this.state;

      return (
        <GridContainer justify="space-between">
          <GridItem style={{paddingLeft: '40px'}} xs={12} sm={8}>
            <CustomInput
              success={this.state.projectNameState === "success"}
              error={this.state.projectNameState === "error"}
              classes={{labelRoot: classes.inputLabel, formControl: classes.restrainInputWidth}}
              labelText={
                <span>
                  Nome Do Projeto
                </span>
              }
              id="projectName"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                classes:{input: classes.customInput},
                value: projectName,
                onChange: event => this.change(event, "projectName", "length", 3),
                autoFocus: true
              }}
            />
            <CustomDropdown
              buttonText={genre}
              error={this.state.genreState === 'error'}
              onClick={this.onClickDropdown.bind(this)}
              classes={{button: classes.dropDown, butonText: classes.dropDownButtonText, buttonTextWrapper: classes.buttonTextWrapper, manager: classes.restrainInputWidth}}
              dropdownList={[//todo: make the list not hardcoded
                "Animação",
                "Auditório",
                "Documentário",
                "Dramaturgia",
                "Variedade",
                "Publicidade",
                "Reality Show",
                "Série",
                "Outros"
              ]}
            />
            <GridContainer style={{marginTop: '15px'}}> 
              <GridItem classes={{grid: classes.gridNoPadding}} xs={12} sm={12} md={6}>
                <InputLabel 
                  classes={{root: classes.inputLabel}} 
                  error = {startDateState === "error"}
                >
                  Data de Inicio
                </InputLabel>
                <br />
                <FormControl classes={{root: classes.formControlDate}} fullWidth>
                  <Datetime
                    closeOnSelect
                    closeOnTab
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    defaultValue={startDate}
                    onChange={ (date) => {
                      let formatedDate = DateVerification(date)
                      if(formatedDate) this.change({target: {value: formatedDate}}, 'startDate', 'length',  5) 
                      else if(this.state.startDateState !== "error") this.setState({ startDateState: "error" })
                    }
                    }
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <InputLabel 
                  classes={{root: classes.inputLabel}}
                  error = {endDateState === "error"}
                >
                  Data de Conclusão
                </InputLabel>
                <br />
                <FormControl classes={{root: classes.formControlDate}} fullWidth>
                  <Datetime
                    closeOnSelect
                    closeOnTab
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    defaultValue={endDate}
                    onChange={ (date) => {
                      let formatedDate = DateVerification(date);
                      if(formatedDate) this.change({target: {value: formatedDate}}, 'endDate', 'length',  5); 
                      else if(this.state.endDateState !== "error") this.setState({ endDateState: "error" })
                    }
                    }
                  />
                </FormControl>
              </GridItem>
            </GridContainer> 
            <CustomInput
              classes={{labelRoot: classes.inputLabelTextArea, formControl: classes.inputTextareaFormControl}}
              autosize
              labelText={
                <span>
                  Descrição do Projeto
                </span>
              }
              id="description"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                multiline: true,
                value: description,
                onChange: event => this.change(event, "description", "length", 3),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4} className={classes.imgUploadWrapper}>
            <ImageUpload
              updateFile={this.updateFile}
              classes={{imgUpload: classes.imgUpload}}
              defaultImg={image ? image : mascote}
              addPhotoButtonText='Adicionar Foto'
              ratio={2.16}
              addButtonProps={{
                  color: "purple",
                  classes: {root: classes.imageUploadButton + ' imgUploadButton'}
                }}
              avatar
            />
          </GridItem>
        </GridContainer>)
  }
}

export default withStyles(addProjectWizardStyle)(Step1);
