import React, { Component } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import { Query } from 'react-apollo';
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Style from "./AssociateFileStyle";
import gql from 'graphql-tag';
import Button from "components/CustomButtons/Button.jsx";
import { withStyles } from '@material-ui/core';
const GET_PROJECT_WITH_PHASES_AND_ELEMENTS = gql`
    query GET_PROJECT_WITH_PHASES_AND_ELEMENTS( $id: ID! ){
        project( id:$id ){
            id
            deliverySchedule {
                id
                phases {
                    id
                    name
                    type
                    elements {
                        id
                        name
                        juridical
                        content {
                            id
                            name
                        }
                    }
                }
                elements {
                    id 
                    name
                }
            }
        }
    }
`;
class AssociateFile extends Component {
    constructor (props) {
        super(props);
        this.state = {
            missingFields: null
        }
    }
    
  render() {
    const { 
      classes, 
      uploads, 
      contentId, 
      projectId ,
      element,
      phase,
      save,
      setElement,
      setPhase,
      standalone,
      loading,
    } = this.props;
    return (
      <div className={classes.footer}>
      <GridContainer style={{ marginTop: "15px" }}>
          {(standalone || (uploads && uploads.length === 1 && !contentId)) &&
              <>
                  <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.title}> Vincular arquivo à entrega </div>
                  </GridItem>
                  {this.state.missingFields && this.props.fieldsRequired && 
                  <GridItem xs={12} sm={12} md={12}>
                      <div>  {this.state.missingFields} </div> 
                  </GridItem> }
                  <GridItem xs={2}>
                      
                  </GridItem>
              </>
          }
          { (standalone || (uploads && uploads.length === 1 && !contentId )) &&
            (element && element.content && element.content.name) &&
            <GridItem xs={7} md={12}>
                <SnackbarContent
                    message={
                        `Entrega já associada ao conteudo "${element.content.name}", associar ele irá remover a associação anterior.`
                    }
                    color="warning"
                />
            </GridItem>
          }
          {
             (standalone || (uploads && uploads.length === 1 && !contentId )) &&
              <GridItem xs={7}>
                  <div >
                      <Query query={GET_PROJECT_WITH_PHASES_AND_ELEMENTS} variables={{ id: projectId }}>
                          {({ error, data, loading }) => {
                              if (error) return <h3>Erro: {error}</h3>
                              if (loading) return null
                              // This is to set the phase when editing a content
                              // Since the initial data comes from a content query, it doesn't have all the elements under the phase
                              if( phase && !phase.elements ) {
                                  let newPhase = data.project.deliverySchedule.phases.filter(p => String(p.id)===String(phase.id))[0];
                                  if( newPhase ) setPhase(newPhase);
                              }
                              let elements = phase ? phase.elements : data.project.deliverySchedule.elements;
                              let phases = data.project.deliverySchedule.phases;
                              return (
                                  <span style={{ display: "flex", justifyContent: "space-evenly" }}>
                                      <CustomDropdown
                                          onClick={(phase) => { setPhase(phase); setElement() }}
                                          buttonText={phase ? phase.name : "Entregas"}
                                          classes={{button: classes.dropDown, butonText: classes.dropDownButtonText, buttonTextWrapper: classes.buttonTextWrapper, manager: classes.restrainInputWidth}}
                                          buttonProps={{
                                              className: classes.navLink,
                                              style: { marginRight: "25px" }
                                          }}
                                          dropdownList={phases.filter(p=> p.type !== 'juridical').map(p => ({ ...p, obj: true}))}
                                      />
                                      <CustomDropdown
                                          onClick={(element) => { setElement(element) }}
                                          buttonText={element ? element.name : "Episódios"}
                                          classes={{button: classes.dropDown, butonText: classes.dropDownButtonText, buttonTextWrapper: classes.buttonTextWrapper, manager: classes.restrainInputWidth}}
                                          buttonProps={{
                                              className: classes.navLink,
                                              style: { marginRight: "20px" }
                                          }}
                                          dropdownList={elements ? elements.map(e => ({ ...e, obj: true, gray: e.content ? true : false })) : []}
                                      />
                                  </span>
                              );
                          }}
                      </Query>
                  </div>
              </GridItem>
          }
          {((uploads && uploads.length > 1) || contentId) && !standalone && <GridItem xs={8}></GridItem>}
          {(((uploads && uploads.length > 0)) || standalone) && 
              <GridItem
                  xs={3}
                  sm={3}
                  md={3}
                  style={ { justifyContent: "flex-end" }}
              >
                  <Button
                      color="purple"
                      loading={loading}
                      onClick={() => (((element && phase) || !standalone) ? save() : this.setState({missingFields: 'Ambos os campos são obrigatórios.'}))}
                      classes={{ purple: classes.btnUpload }}
                  >
                      Salvar
                  </Button>
              </GridItem>
          }
      </GridContainer>
  </div>
    );
  }
}

export default withStyles(Style)(AssociateFile);
