import { gql } from "apollo-boost";
import { GET_GROUPS_QUERY } from 'views/Team/TeamQueries.js';

const ADD_PROJECT_MEMBERS = gql`
  mutation addProjectMembers($id: ID!, $membersIds:[ID]!) {
    addProjectMembers(id:$id, membersIds:$membersIds) {
      id
      name
    }
  }
`;

const addProjectMembers = async ({ params, client }) => {
    client.mutate({
      mutation: ADD_PROJECT_MEMBERS,
      variables: { membersIds: params.membersIds, id: params.id },
      refetchQueries: () => [{
        query: GET_GROUPS_QUERY,
        variables: { id: params.id }
      }]
    })
  }
  
  export {
    ADD_PROJECT_MEMBERS,
    addProjectMembers
  }