import {
  successColor,
  dangerColor,
  tooltip,
  cardTitle,
  grayColor
} from "assets/jss/material-dashboard-pro-react.jsx";

import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.jsx";

const dashboardStyle = {
  ...hoverCardStyle,
  tooltip,
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "left",
    fontSize: "20px",
    color: "#3F2C78"
  },
  projectImg: {
    height:"220px",
    width:"100%",
    backgroundSize: 'cover !important',
    borderRadius: '6px',
    boxShadow: '0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px !important',
    color: '#dadada'
  },
  cardProductTitleProject: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "left",
    fontWeight: "400 !important",
    "& a": {
      color: "#3F2C78 !important",
      fontFamily: "Montserrat, sans-serif",
      fontWeight: '400 !important',
      fontSize: '18px',
      lineHeight: '24px'
    }
  },
  cardProjectFooter: {
    display: 'flex',
    flexDirection: 'column',
    width: "100%",
  },
  projectHeader: {
      display: "flex",
      "& svg": {
        marginTop: "5px",
        marginLeft: "15px",
        fontSize: "24px",
        height: "30px",
        width: "30px",
        color: "#3f39a6",
      }
  },
  cardProjectBottomFooter: {
    color: "#999999",
    fontSize: "12px",
    "& span": {
      fontWeight: "400",
    }
  },
  cardFooterProjects: {
    width: "100%",
  },
  underChartIconsProjects: {
    width: "17px",
    height: "17px",
    marginRight: "5px",
    color: "#3F2C78",
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0"
  },
  cardProductDesciprion: {
    textAlign: "center",
    color: grayColor[0]
  },
  stats: {
    color: grayColor[0],
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px"
    }
  },
  productStats: {
    paddingTop: "7px",
    paddingBottom: "7px",
    margin: "0"
  },
  successText: {
    color: successColor[0]
  },
  successButton: {
    backgroundColor: successColor[0] + ' !important',
  },
  dangerButton: {
    backgroundColor: dangerColor[0] + '  !important',
  },
  upArrowCardCategory: {
    width: 14,
    height: 14
  },
  underChartIcons: {
    width: "17px",
    height: "17px"
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px"
    }
  }
};

export default dashboardStyle;
