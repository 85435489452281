import { Modal, withStyles, FormControl } from '@material-ui/core';
import Button from "components/CustomButtons/Button.jsx";
import PropTypes from "prop-types";
import React, { Component } from 'react';
import Avatar from "components/Avatar/Avatar";
import AvatarImg from "assets/img/avatar.png";
import style from "./ModalCardStyle";
import EditableField from 'components/EditableField/EditableField';
import Loading from "components/Loading/Loading";
import Datetime from "react-datetime";
import DateVerification from "utils/DateVerification";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import { Subtitles, Close } from "@material-ui/icons";
import { Query } from 'react-apollo';

class ModalCard extends Component {
  constructor (props) {
    super(props);
    this.state = {
      title: props.card.title,
      description: props.card.description,
      status: props.card.status,
      startDate: props.card.startDate,
      endDate: props.card.endDate,
      boardTitle: props.card && props.card && props.card.board ? props.card.board.title : '',
      creator: props.card.creator ? props.card.creator : {},
      id: props.card.id,
      boardId: props.card && props.card && props.card.board ? props.card.board.id : undefined,
    }
  }

  onClickDropdown = (status) => {
    this.setState({status})
  }

  selectBoards = (board) => {
    this.setState({boardId: board.id, boardTitle: board.name})
  }

  render() {
    const { 
      classes, 
      card, 
      open, 
      onClose, 
      onSave, 
      newTask, 
      projectId,
      GET_BOARDS,
    } = this.props;

    const { 
      id, 
      title, 
      boardId,
      status, 
      creator, 
      endDate, 
      startDate, 
      boardTitle, 
      description, 
    } = this.state;

    if (!card && !newTask) {
      console.error('No card avaiable for this modal');
      return null;
    } 

    return (
      <Modal 
        open={open}
        onBackdropClick={onClose}
        classes={{root: classes.modal}}
      >
        <Query query={GET_BOARDS} variables={{projectId}}>
          {({data, loading, error}) => {
            if (loading) return <div className={classes.root}> <Loading/> </div>;
            if (error) console.error('Query error: ', error);
            if (!loading && data && data.orgTrello) {
              data.boards = data.orgTrello.boards;
            }
            const boards = data.boards.map((board) => ({obj: true, name: board.title, id: board.id}))
            return (
              <div className={classes.root}>
                <Close onClick={onClose} classes={{root: classes.close}}/>
                <div className={classes.title}>
                    <Subtitles/>
                    <div className={classes.titleInput}>
                      <EditableField 
                        text={title}
                        placeholder='TÍTULO'
                        classes={{
                          input: classes.titleText,
                          focused: classes.descriptionFocused,
                        }}
                        onChange={(value) => this.setState({title: value})}
                        />
                    </div>
                </div>
                <div className={classes.subtitle}>
                      {boardTitle ? `Na board ${boardTitle}` : 'Criar Tarefa'}
                </div>
                  <div style={{display: 'flex'}}>
                    <div>
                      <div className={classes.section + ' ' + classes.dropdownWrapper}>
                        <CustomDropdown 
                          buttonText={boardTitle ? boardTitle : 'Status'} 
                          dropdownList={boards}
                          onClick={this.selectBoards}
                          classes={{
                            button: classes.dropDown, 
                            butonText: classes.dropDownButtonText, 
                            buttonTextWrapper: classes.buttonTextWrapper, 
                            manager: classes.restrainInputWidth
                          }}
                          />
                    </div>
                    <div className={classes.section + ' ' + classes.dropdownWrapper}>
                        <CustomDropdown 
                          buttonText={status ? status : 'Coluna'} 
                          dropdownList={['TODO', 'DOING', 'DONE']}
                          onClick={this.onClickDropdown}
                          classes={{
                            button: classes.dropDown, 
                            butonText: classes.dropDownButtonText, 
                            buttonTextWrapper: classes.buttonTextWrapper, 
                            manager: classes.restrainInputWidth
                          }}
                          />
                    </div>
                  </div>
                  <div className={classes.section + ' ' + classes.userWrapper}>
                    <div> Criador </div>
                      <Avatar 
                        classes={{root: classes.avatar}} 
                        userId={creator ? creator.id: ""} 
                        img={(creator && creator.image) ? creator.image.imageUrl : AvatarImg}/>
                  </div>
                </div>
                <div className={classes.section + ' ' + classes.dateWrapper}>
                  <FormControl classes={{root: classes.formControlDate}}>
                      Início <Datetime
                        closeOnSelect
                        closeOnTab
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        defaultValue={startDate ? startDate : '00/00/0000'} 
                        onChange={ (date) => {
                          date = DateVerification(date); 
                          if(date) this.setState({startDate: date})
                        }}
                      />
                    </FormControl>
                  <br/> 
                  <FormControl classes={{root: classes.formControlDate}}>
                    Prazo <Datetime
                        closeOnSelect
                        closeOnTab
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        defaultValue={endDate ? endDate : '00/00/0000'} 
                        onChange={ (date) => {
                          date = DateVerification(date); 
                          if(date) this.setState({endDate: date})
                        }}
                      />
                    </FormControl>
                </div>

                <div className={classes.body}>
                    <EditableField 
                      text={description}
                      placeholder='Descrição'
                      classes={{
                        input: classes.descriptionText, 
                        focused: classes.descriptionFocused,
                        underline: classes.underline,
                      }}
                      multiline={8}
                      onChange={(value) => this.setState({description: value})}
                    />
                </div>
                <div className={classes.footer}>
                  <Button onClick={onClose}> 
                    Cancelar 
                  </Button>
                  <Button 
                    onClick={() => onSave({boardId, id, title, description, status, startDate, endDate})}
                    color="purple"
                  > 
                    Salvar 
                  </Button>
                </div>
              </div>
            )
          }}
        </Query>
      </Modal>
    );
  }
}


ModalCard.propTypes = {
    classes: PropTypes.object,
    card: PropTypes.object,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    open: PropTypes.bool,
};


export default withStyles(style)(ModalCard);

