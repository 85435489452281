import { card } from "assets/jss/material-dashboard-pro-react.jsx";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.jsx";

const style = {
  ...tooltipsStyle,
  card: {
    ...card,
    paddingBottom: "15px",
    marginTop: "30px",
    height: "auto",
    minHeight: "100%"
  },
  root: {
    display: "flex",
    overflow: "visible"
  },
  ColumnRole: {
    marginTop: "6px"
  },
  numbers: {
    color: "#3f2c78",
    fontWeight: "600",
    marginLeft: "auto",
    marginRight: "20px"
  },
  contentViewTable: {
    "& .ReactTable": {
      border: "none",
      "& .rt-thead.-header": {
        boxShadow: "none",
        borderBottom: "solid 1px #DDDDDD"
      },
      "& .-striped .rt-tr.-odd": {
        background: "#F9F9F9"
      },
      "& .rt-tr-group": {
        borderBottom: "solid 1px #DDDDDD",
        cursor: "pointer"
      },
      "& $checkboxTypeWrapper + $markedChecked": {
        "& svg:nth-child(2)": {
          display: "none",
          "& $checkRoot": {
            padding: 0
          }
        },
        "& svg:nth-child(1)": {
          display: "block"
        }
      },
      "& .rt-tr-group:hover": {
        "& $checkboxTypeWrapper": {
          "& svg:nth-child(2)": {
            display: "none",
            "& $checkRoot": {
              padding: 0
            }
          },
          "& svg:nth-child(1)": {
            display: "block"
          }
        }
      },
      "& .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {},
      "& .rt-thead .rt-resizable-header:last-child": {
        textAlign: "left"
      },
      "& .rt-tbody": {
        overflow: "hidden"
      },
      "& .rt-tbody .rt-td": {
        border: "none",
        textAlign: "left",
        display: "flex"
      },
      "& .rt-th": {
        "& div": {
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row"
        },
        "&.-cursor-pointer > div:first-of-type:after": {
          display: "none",
          fontSize: "13px",
          marginTop: "1px",
          height: "14px"
        },
        "&:hover": {
          "&.-cursor-pointer > div:first-of-type:after": {
            display: "block"
          }
        }
      },
      "& .rt-thead .rt-th": {
        fontFamily: "Montserrat",
        fontSize: "18px",
        fontWeight: "500",
        color: "#3C4858",
        textAlign: "left",
        border: "none",
        boxShadow: "none"
      },
      "& .rt-thead .rt-th:first-child, .rt-thead .rt-th:nth-child(2)": {
        marginLeft: 0,
        marginRight: "5px"
      },
      "& .rt-tbody .rt-td:first-child, .rt-tbody .rt-td:nth-child(2)": {
        marginLeft: 0,
        marginRight: "5px"
      },
      "& .rt-thead .rt-th:last-child, .rt-thead .rt-th:nth-child(6)": {
        marginRight: 0,
        marginLeft: 0
      },
      "& .rt-tbody .rt-td:last-child, .rt-tbody .rt-td:nth-child(6)": {
        marginRight: 0,
        marginLeft: 0
      }
    }
  },
  tabsWrapper: {
    width: "400px",
    minWidth: "370px"
  },
  mainOrg: {
    display: "flex",
    height: "50px",
    width: "100%",
    paddingTop: "15px",
    paddingLeft: "15px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f0f0f0"
    }
  },
  avatarHeaderWrapper: {
    display: "flex",
    paddingTop: 8,
    margin: "5px 0",
    "& div": {
      marginRight: "10px"
    }
  },
  avatar: {
    height: 39,
    width: 39,
    "& img": {
      height: 39,
      width: 39
    }
  },
  tableCollumn: {
    fontWeight: "normal",
    color: "#3C4858",
    fontSize: "14px"
  },
  org: {
    display: "flex",
    height: "50px",
    width: "100%",
    paddingTop: "15px",
    paddingLeft: "15px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f0f0f0"
    }
  },
  collabOrgName:{
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  list: {
    backgroundColor: "white",
    height: "61px",
    width: "100%",
    marginTop: "5px",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.11)"
  },
  listItem: {
    padding: "0 10px !important",
    "&:nth-child(2)": {
      display: "flex",
      justifyContent: "flex-end"
    }
  },
  inputAdornment: {
    "& svg": {
      color: "#AAAAAA",
      height: "16px"
    }
  },
  underlineInput: {
    marginTop: "25px !important"
  },
  inputWrapper: {
    display: "flex",
    width: "210px",
    justifyContent: "space-between"
  },
  inputPermissionWrapper: {
    display: "flex",
    width: "auto",
    justifyContent: "space-between"
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "20px",
    fontWeight: "normal",
    color: "#3F2C78",
    marginTop: "0px"
  },
  subtitle: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "16px",
    fontWeight: "normal",
    color: "#3F2C78",
    padding: "15px 5px",
    display: "flex",
    "& svg": {
      marginRight: "15px",
      marginLeft: "auto",
      color: "black"
    }
  },
  tooltip: {
    backgroundColor: "black",
    fontSize: "12px",
    top: "20px"
  },
  iconMenuList: {
    color: "#3F2C78",
    fontSize: "15px",
    height: "17px",
    marginTop: "4px",
    marginLeft: "-5px",
    marginRight: "5px"
  },
  iconButton: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
    zIndex: "3",
    position: "relative"
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    maxHeight: "100px"
  },
  headersOrgTable: {
    maxHeight: "55px",
    padding: "0 10px 0px 5px"
  },
  orgWrapper: {
    marginTop: "10px",
    marginBottom: "10px",
    "& span": {
      marginLeft: "10px",
      fontWeight: "normal",
    }
  },
  restrainInputWidth: {
    width: "250px",
    paddingTop: 0,
    marginTop: "-18px"
  },
  uploadContent: {
    backgroundColor: "#3F2C78",
    height: "41px",
    minWidth: "50px",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "14px",
    lineHeight: "14px",
    padding: "9px 10px",
    borderRadius: "6px",
    boxShadow: "0 4px 23px 0 rgba(0,0,0,0.12)",
    display: "flex",
    fontWeight: "500",
    justifyContent: "space-between",
    marginBottom: "45px",
    marginTop: "10px",
    "&:hover": {
      backgroundColor: "#796aa9",
      boxShadow: "none"
    },
    "&:focus": {
      backgroundColor: "#3F2C78",
      boxShadow: "none"
    },
    "& svg": {
      color: "white",
      height: "24px"
    }
  },
  buttonTextWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  dropDownButtonText: {
    fontFamily: "Roboto, sans-serif !important",
    fontWeight: "normal",
    fontSize: "14px"
  },
  dropDown: {
    backgroundColor: "transparent",
    color: "#AAAAAA",
    boxShadow: "none",
    padding: "10px 5px 5px 0px",
    borderBottom: "1px solid #D2D2D2",
    maxWidth: "200px",
    borderRadius: 0,
    width: "100%",
    marginBottom: "20px",
    textTransform: "initial",
    "&:hover,&:focus": {
      backgroundColor: "transparent",
      color: "#AAAAAA",
      boxShadow: "none"
    }
  }
};

export default style;
