import React from "react";
import { myUser as MY_USER_QUERY } from "queries/queries.js";
import { withApollo, Query } from "react-apollo";

let GlobalContext = React.createContext();
const ContextProvider = GlobalContext.Provider;
const ContextConsumer = GlobalContext.Consumer;

function ContextController(props) {
  if (localStorage.getItem("token"))
    return (
      <Query query={MY_USER_QUERY} fetchPolicy="network-only">
        {({ error, loading, data: userData, refetch: userRefetch }) => {
          if (loading) return null;
          if (error || !userData) {
            localStorage.setItem("token", "");
            return (window.location.href = "/login");
          }
          let contextVariables = {
            getUser: () => userData.myUser,
            userRefetch: () => userRefetch(),
            myUser: userData.myUser
          };
          return (
            <ContextProvider value={contextVariables}>
              {props.children}
            </ContextProvider>
          );
        }}
      </Query>
    );
  else return <ContextProvider value={{}}>{props.children}</ContextProvider>;
}
export { ContextConsumer, GlobalContext };
export default withApollo(ContextController);
