import React from 'react';
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import UploadContentStyle from "./UploadContentStyle.js";
import { LinearProgress } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { Close } from "@material-ui/icons";

import SwitchesTableStyle from "components/SwitchesTable/SwitchesTableStyle";
import { useState } from 'react';
import wait from 'waait';

function FileUploadCard({ classes, progressPercentage, successUploads, nUploads, uploads, onDragOver, handleClose, removeFile, loading, nextFile }) {
    const [setupBarValues, setSetupBarValues] = useState(uploads.map(() => 0));
    const startSetup = async(i, value) =>{
        if(value > 5) return;
        let _setupBarValues = setupBarValues;
        _setupBarValues[i] = value + 1;
        await wait(1000) 
        setSetupBarValues(_setupBarValues);
        return startSetup(i, _setupBarValues[i]);
    }
    return (
        <Card 
            onDragOver={(e) => onDragOver(e)} 
            onDrop={(e) => e.preventDefault()} 
            classes={{ root: classes.listUploadsWrapper }} 
            style={{ overflowY: "auto" }}
        >
            <div className={classes.listUploads}>
                {uploads.map((upload, i) => {
                    if(upload === nextFile) console.log("setuptimer", setupBarValues[i], i);
                    
                    if(upload === nextFile && setupBarValues[i] === 0) startSetup(i,0); 
                    if ((successUploads ? successUploads.length : -1) === nUploads) {
                        setTimeout(() => handleClose(), 1000);
                    }
                    let progressBarValue = parseInt(progressPercentage[upload]);
                    progressBarValue = isNaN(progressBarValue) ? 0 : progressBarValue;
                    progressBarValue = successUploads[i] ? 100 : progressBarValue;

                    if(upload === nextFile) {
                     if(progressBarValue < setupBarValues[i]){
                        progressBarValue = setupBarValues[i];
                     }   
                    }

                    return (
                        <div key={i} className={classes.uploadedFile} style={{ display: 'block' }}>
                            <div className={classes.fileWrapper}>
                                <span> {upload} </span>
                                <span> <Close onClick={() => removeFile(i)} /> </span>
                            </div>
                            <LinearProgress 
                                classes={{ root: classes.linearProgress }} 
                                value={progressBarValue} 
                                variant='determinate' 
                            />
                            {uploads.length > 1 && <div className={classes.divisor} />}
                        </div>
                    )
                })}
            </div>
        </Card>
    );
}

export default withStyles({ ...modalStyle, ...SwitchesTableStyle, ...UploadContentStyle })(FileUploadCard);
