import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import routes from "routes.js";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";

import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/tvmanager.png";

import { Query } from 'react-apollo'
import { myUser } from 'queries/queries.js';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: true,
      image: image,
      color: "blue",
      bgColor: "purple",
      hasImage: true,
      fixedClasses: "dropdown"
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      if (this.refs.mainPanel) this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleBgColorClick = bgColor => {
    this.setState({ bgColor: bgColor });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/admin/full-screen-maps";
  }
  getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
      });
    return (
      <Query query={myUser}>
      {({ data, loading, error }) => {

        if (loading) {
          return null;
        }

        if (!data) {
          window.location.href = "/login";
        }

        const loggedUser = data.myUser;
        
        return (
          <div className={classes.wrapper}>
            <Sidebar
              routes={routes}
              logo={logo}
              loggedUser={loggedUser}
              handleDrawerToggle={this.handleDrawerToggle}
              logoText='manager'
              open={this.state.mobileOpen}
              color={this.state.color}
              bgColor={this.state.bgColor}
              miniActive={this.state.miniActive}
              {...rest}
            />
            <div className={mainPanel} ref="mainPanel">
              <AdminNavbar
                sidebarMinimize={this.sidebarMinimize.bind(this)}
                miniActive={this.state.miniActive}
                barTitle='Todos os Projetos'
                handleDrawerToggle={this.handleDrawerToggle}
                {...rest}
              />
              {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              {this.getRoute() ? (
                <div className={classes.content}>
                  <div className={classes.container}>
                    <Switch>{this.getRoutes(routes)}</Switch>
                  </div>
                </div>
              ) : (
                <div className={classes.map}>
                  <Switch>{this.getRoutes(routes)}</Switch>
                </div>
              )}
            </div>
        </div>
      )}}
      </Query>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(appStyle)(Dashboard);
