import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import StatusTable from "components/StatusTable/StatusTable.jsx";
import DashboardCard from "components/DashboardCard/DashboardCard.jsx";
import Loading from "components/Loading/Loading.jsx";
import Gantt from "components/Gantt/Gantt.jsx";
import { DASHBOARD_PROJECTS_QUERY, formattedPhases } from "./DashboardUtils";
import { Query } from "react-apollo";
import convertDate from "utils/convertDate";

const Dashboard = () => {
  return (
    <Query query={DASHBOARD_PROJECTS_QUERY}>
      {({ data, loading }) => {
        if (loading || !data) return <Loading />;
        data = data.projects;
        const formatedProjects = formattedPhases({ projects: data });
        const completedProjects = formatedProjects.projects.filter(
          project => project.status === "Concluído"
        ).length;
        const lateProjects = formatedProjects.projects.filter(
          project => project.status === "Atrasado"
        ).length;
        const ongoingProjects = formatedProjects.projects.filter(
          project => project.status === "Em Andamento"
        ).length;
        // Ainda não temos um card para projetos não iniciados, mas é um dos 4 estados possiveis que um projeto pode ter
        //  logo acho possivel que no futuro usemos de algum jeito essa informação
        // const notStartedProjects = formatedProjects.projects.filter(project => project.status === 'Não Iniciado').length;

        let _data = {
          data: data.map(project => ({
            id: project.id,
            text: project.name,
            start_date:
              project.startDate !== ""
                ? convertDate(project.startDate)
                : new Date(),
            end_date:
              project.endDate !== ""
                ? convertDate(project.endDate)
                : new Date(),
            duration: 1,
            progress: 1
          }))
        };
        return (
          <GridContainer justify="center" style={{ marginTop: "20px" }}>
            <GridItem xs={3}>
              <DashboardCard number={lateProjects} color="red" />
            </GridItem>
            <GridItem xs={3}>
              <DashboardCard
                number={ongoingProjects}
                subtitle="Em Andamento"
                color="orange"
              />
            </GridItem>
            <GridItem xs={3}>
              <DashboardCard
                number={completedProjects}
                subtitle="Concluidos"
                color="green"
              />
            </GridItem>
            <GridItem xs={3}>
              <DashboardCard
                footer={true}
                linearValue={data.length / 3}
                number={data.length}
                title="Total de"
                subtitle="Projetos"
                color="purple"
              />
            </GridItem>
            <GridContainer>
              <GridItem md={12}>
                <StatusTable
                  title="Projetos"
                  projects={formatedProjects.projects}
                  rows={data.map(project => project.name)}
                  header={["Líder", ...formatedProjects.phases, "Timeline"]}
                  columnWidth="120px"
                />
              </GridItem>
            </GridContainer>
            <GridItem style={{ height: "500px" }} xs={12} sm={12} md={12}>
              <Gantt month tasks={_data} />
            </GridItem>
          </GridContainer>
        );
      }}
    </Query>
  );
};

export default Dashboard;
