import {
  ADD_VERSION_MUTATION,
  ADD_CONTENT_MUTATION
} from "components/Upload/uploadQueries.js";

import { GET_CONTENT_QUERY } from "views/Vimeo/ContentViewQueries";

import { GET_CONTENT_FROM_FOLDER_QUERY } from "views/Content/ContentView/ContentDirectoryQueries.js";
import client from "index.js";
import { createRef } from "react";
import tus from "tus-js-client";
import submitFile from "utils/uploadFile.js";
import axios from "axios";

const vimeoToken = "edea5af47d49897d65676a298c576eee";
const headers = {
  Authorization: "bearer " + vimeoToken,
  "Content-Type": "application/json",
  Accept: "application/vnd.vimeo.*+json;version=3.4"
};

//sets upload variables for content or version
function setSelectedUploadMethodVars({ type, fileKey, name }) {
  const { contentId, projectId, folderId } = this.props;
  const { element, phase, uploads } = this.state;
  if (!contentId) {
    let newUploads = [
      ...uploads,
      {
        name,
        type,
        fileKey,
        folderId,
        projectId
      }
    ];
    if (element && phase) {
      newUploads[0] = { ...newUploads[0], element, phase };
    }
    this.setState({
      uploads: newUploads
    });
  } else if (contentId)
    this.setState({
      uploads: [
        ...uploads,
        {
          name: name,
          fileKey,
          contentId
        }
      ]
    });
}

//translates our internal file types to css accepted types
function setUploadType({ contentType }) {
  if (contentType === "vimeo") return "video/*";
  if (contentType === "image") return "image/*";
  if (contentType === "audio") return "audio/*";
  if (contentType === "onlyoffice")
    return ".docx,.doc,.odt,.rtf,.txt,.xls,.xlsx,.ods,.csv,.pptx,.ppt,.odp,.pdf";
  if (contentType === "") return "";
}

//stacks fileUpload instances
async function queueUpload() {
  const { projectId, folderId, contentId } = this.props;
  const {
    uploadedFiles,
    loading,
    fileStack,
    phase,
    element,
    uploads
  } = this.state;
  let refetchQueries = [
    {
      query: GET_CONTENT_FROM_FOLDER_QUERY,
      variables: { projectId: projectId, folderId: folderId }
    }
  ];

  if (contentId)
    refetchQueries.push({
      query: GET_CONTENT_QUERY,
      variables: { contentId: contentId }
    });

  if (!loading) this.setState({ loading: true });
  if (!fileStack) return;
  if ((fileStack ? fileStack.length : -1) === uploadedFiles) {
    await this.setState({ done: true });
    for (let upload of uploads) {
      await client.mutate({
        mutation: contentId ? ADD_VERSION_MUTATION : ADD_CONTENT_MUTATION,
        variables: {
          ...upload,
          elementId: element ? element.id : null,
          phaseId: phase ? phase.id : null
        },
        refetchQueries: () => refetchQueries
      });
    }
    handleClose.bind(this)("modalOpen");

    return;
  }
  const currentFile = uploadedFiles;
  const nextFile = fileStack ? fileStack[currentFile] : {};
  this.setState({ fileStack, nextFile, uploadedFiles: uploadedFiles + 1 });

  // TODO: Add more supported types
  if (nextFile && nextFile.type.split("/")[0] === "video") {
    await this.vimeoUpload(nextFile);
  } else if (
    nextFile &&
    (nextFile.type.split("/")[0] === "text" ||
      nextFile.type.split("/")[0] === "application")
  ) {
    await this.fileUpload(nextFile, "onlyoffice");
  } else if (nextFile && nextFile.type.split("/")[0] === "image") {
    await this.fileUpload(nextFile, "image");
  } else if (nextFile && nextFile.type.split("/")[0] === "audio") {
    await this.fileUpload(nextFile, "audio");
  } else {
    if (nextFile) console.error("File unsupported: ", nextFile.type);
    else console.error("nextFile is null");
    window.alert("File unsupported");
  }
}

function onProgress(bytesUploaded, bytesTotal) {
  const { nextFile, fileStack, progressPercentage } = this.state;
  if (nextFile || (fileStack && fileStack.length === 1)) {
    let progress = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
    let progressPercentageState = {
      ...progressPercentage,
      [nextFile.name]: progress
    };
    this.setState({
      progressPercentage: progressPercentageState,
      loading: true
    });
  }
}

function isObjectEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

function onSuccess() {
  const {
    uploadedFiles,
    fileStack,
    successUploads,
    progressPercentage,
    nextFile
  } = this.state;
  let temp = progressPercentage; //gets list of progress % being tracked then removes it
  let _successUploads = successUploads;
  const that = this;
  _successUploads[uploadedFiles - 1] = true;
  delete temp[nextFile ? nextFile.name : ""];
  this.setState({
    progressPercentage: temp,
    successUploads: _successUploads,
    loading: false
  });
  if (fileStack && uploadedFiles <= fileStack.length) this.queueUpload();
  else {
    setTimeout(() => {
      that.handleClose();
    }, 1000);
  }
}

function removeFile(index) {
  let { fileStack } = this.state;
  if (!fileStack) return;
  if (index > -1) {
    fileStack.splice(index, 1);
  }
  this.setState({ fileStack });
}

/*----------------Dropzone functions start----------------*/

//dropzone`s on change logic, adds new file to filestack to be uploaded
function handleChange(e) {
  const { fileStack } = this.state;
  if (e.preventDefault) e.preventDefault();
  this.onDragLeave();
  let newFiles = Array.from(e.target.files);
  if (fileStack) {
    newFiles = newFiles.filter(
      newFile =>
        !fileStack.some(
          oldFile => oldFile.name && oldFile.name === newFile.name
        )
    );
    this.setState({
      fileStack: [...fileStack, ...newFiles],
      nUploads: fileStack + (newFiles ? newFiles.length : 0)
    });
  } else {
    this.setState({
      fileStack: [...newFiles],
      nUploads: newFiles ? newFiles.length : 0
    });
  }
}

function onDragOver(e) {
  const { isDragging } = this.state;
  clearTimeout(this.dragLeave);
  e.preventDefault();
  this.isDragging = setTimeout(() => {
    if (!isDragging) this.setState({ isDragging: true });
  }, 250);
}

function onDragLeave() {
  const { isDragging } = this.state;
  clearTimeout(this.isDragging);
  this.dragLeave = setTimeout(() => {
    if (isDragging) this.setState({ isDragging: false });
  }, 500);
}

function handleClickOpen() {
  this.setState({
    modalOpen: true
  });
}
/*-----------------Dropzone functions end-----------------*/

function setSelectingElements() {
  this.setState({
    selectingElements: !this.state.selectingElements
  });
}

function setElement(element) {
  this.setState({ element });
}

function setPhase(phase) {
  this.setState({ phase });
}

//handle close from upload modal
function handleClose() {
  this.setState({
    data: [],
    uploadForm: {},
    modalOpen: false,
    progressPercentage: {},
    done: false,
    event: null,
    fileStack: null,
    nextFile: null,
    loading: false,
    uploads: [],
    uploadedFiles: 0,
    successUploads: [],
    onMenu: false,
    element: "",
    phase: "",
    selectingElements: true,
    nUploads: 0
  });
  const that = this;
  setTimeout(() => {
    if (that.props.menuOpen) that.props.setMenuModalOpen();
  }, 1000);
  that.setState({ loading: false });
  this.fileSelector = createRef();
}

//uploads single file to s3 instances
async function fileUpload(nextFile, type) {
  if (!nextFile) return null;
  if (nextFile)
    try {
      const fileUrl = await submitFile(nextFile, onProgress.bind(this)); //actual upload
      //the bind(this) here is to use function from utils that use state and props
      await setSelectedUploadMethodVars.bind(this)({
        type,
        fileKey: fileUrl,
        name: nextFile.name
      });
      this.onSuccess();
    } catch (error) {
      console.error(error);
      this.setState({ error: error.message });
    }
}

//uploads single file to vimeo
async function vimeoUpload(nextFile) {
  if (!nextFile) return;
  /** to backends start */
  const reqBody = {
    upload: {
      approach: "tus",
      size: nextFile.size.toString()
    },
    name: nextFile.name,
    privacy: {
      add: false,
      comments: "nobody",
      download: false,
      embed: "public",
      view: "disable"
    }
  };
  const upLink = await axios({
    url: "https://api.vimeo.com/me/videos",
    method: "POST",
    headers: headers,
    data: reqBody
  });
  /** to backend`s end */
  let data = upLink.data;
  let vimeoID = data.link.split("/")[3];
  //the bind(this) here is to use function from utils that use state and props
  setSelectedUploadMethodVars.bind(this)({
    type: "vimeo",
    fileKey: vimeoID,
    name: data.name
  });
  let uploadUrl = upLink.data.upload.upload_link;
  let chunkSize = 128000000; // 128 MB
  const upload = await new tus.Upload(nextFile, {
    //actual upload. make  the "methods" (ex: onProgress) change the state of the upload component
    resume: true,
    endpoint: uploadUrl,
    uploadUrl: uploadUrl,
    chunkSize: chunkSize,
    retryDelays: [0, 3000, 5000, 5000, 10000, 10000, 20000],
    metadata: {
      filename: nextFile.name,
      filetype: nextFile.type
    },
    onError: error => console.error("Failed because: " + error),
    onProgress: this.onProgress,
    onSuccess: this.onSuccess
  });
  await upload.start();
}

export {
  setSelectedUploadMethodVars,
  setUploadType,
  queueUpload,
  onProgress,
  onSuccess,
  handleChange,
  removeFile,
  onDragOver,
  onDragLeave,
  fileUpload,
  handleClose,
  setPhase,
  setSelectingElements,
  handleClickOpen,
  setElement,
  vimeoUpload,
  isObjectEmpty
};
