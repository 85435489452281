import {
    container,
    defaultFont,
    cardTitle,
    whiteColor,
    grayColor,
    hexToRgb
  } from "assets/jss/material-dashboard-pro-react.jsx";
  
  const pricingPageStyle = theme => ({
    container: {
      ...container,
      maxWidth: "700px",
      marginLeft: "auto",
      marginRight: "auto",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "100px"
      }
    },
    dateInput: {
      marginTop: "15px",
      marginBottom: "5px"
    },
    title: {
      ...defaultFont,
      color: "#AAA",
      marginTop: "10px",
      marginBottom: "15px",
      textAlign: "center"
    },
    numberEps: {
      fontSize: "16px",
      color: "#AAA",
      textAlign: "center",
      paddingTop: "12px"
    },
    description: {
      fontSize: "18px",
      color: "#AAA",
      textAlign: "center"
    },
    cardTitleWhite: {
      ...cardTitle,
      color: "#AAA"
    },
    cardCategory: {
      color: "#AAA"[0],
      marginTop: "10px"
    },
    cardCategoryWhite: {
      color: "#AAA",
      marginTop: "10px"
    },
    iconWhite: {
      cursor: "pointer",
      color: "#AAA"
    },
    iconRose: {
      cursor: "pointer",
      color: "#3F2C78"
    },
    icon: {
      color: "rgba(" + hexToRgb(whiteColor) + ", 0.76)",
      margin: "10px auto 0",
      width: "90px",
      height: "90px",
      border: "1px solid " + grayColor[11],
      borderRadius: "50%",
      lineHeight: "133px",
      "& svg": {
        width: "55px",
        height: "55px"
      },
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        width: "55px",
        fontSize: "55px"
      },
      textAlign: "center"
    },
    marginTop30: {
      marginTop: "30px"
    },
    formControlDate: {
      textAlign: "center"
    },
    inputLabel: {
      fontSize: "12px"
    }
  });
  
  export default pricingPageStyle;
  