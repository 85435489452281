import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

export default {
  ...customCheckboxRadioSwitch,
  root: {
    overflow: 'auto',
    '& b': {
      color: 'black !important'
    }
  },
  stepsCard: {
    height: 'Calc(100vh - 300px)',
    width: '100%',
    marginTop: '30px !important',
    // overflow: 'visible !important'
    overflow: 'hidden'

  },
  step: {
    marginTop: 10,
  },
  input: {
    borderBottom: '1px solid #d2d2d2'
  },
  action: {
    marginTop: '0px',
    borderRadius:'50%',
    width: '30px',
    '& svg': {
      height: '20px',
    }
  },
  dropDownButtonText: {
    fontFamily: "Roboto, sans-serif !important",
    fontWeight: "500",
    color: 'rgb(60, 72, 88)',
    fontSize: '14px',
    paddingLeft: 5,
  },
  checkedIcon: {
    width: 20,
    height: 20,
  },
  checked: {
    color: '#009688 !important'
  },
  deleteIcon: {
    color: '#af282899 !important',
  },
  actionWrapper: {
    backgroundColor: 'transparent',
    fontSize: '36px',
    width: '30px',
    height: '30px',
    lineHeight: '16px',
    color: '#aeaeae',
    minWidth: 0,
    minHeight: 0,
    paddingTop: 5,
    marginTop: '10px',
    marginLeft: '5px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      background: 'transparent',
    },
    '&:active': {
      boxShadow: 'none',
      background: 'transparent',
    },
     '&:focus': {
      boxShadow: 'none',
      background: 'transparent',
    }
  },
  indent: {
    backgroundColor: 'transparent',
    fontSize: '25px',
    height: '30px !important',
    color: '#5f5f5f',
    minWidth: 0,
    transform: 'rotate(90deg)',
    minHeight: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    boxShadow: 'none',
    background: 'transparent',
    marginLeft: '-4px',
    transition: 'all 0.3s linear',
    '&:hover': {
      boxShadow: 'none',
      background: 'transparent',
    },
    '&:active': {
      boxShadow: 'none',
      background: 'transparent',
    },
     '&:focus': {
      boxShadow: 'none',
      background: 'transparent',
    }
  },
  stepsTree: {
    height: 50,
    width: 'Calc(100% + 20px)',
    marginLeft: '-10px',
    paddingLeft: 30,
    fontWeight: 400,
    paddingTop: 15,
    display: 'flex',
    borderRadius: 6,
    boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px #3f2c7766',
    '& svg': {
      poaddingTop: 5,
      height: 20,
      width: 30,
      fontSize: 18,
      color: '#3f2c78'
    },
    '& div': {
      cursor: 'pointer'
    }
  },
  save: {
    '& button': {
      marginLeft: '-100px',
      marginTop: '-21px',
      height: 40,
      padding: '5px 15px',
    }
  },
  deleted: {
    marginLeft: '-60px',
    width: '45px !important',
    marginTop: '-3px !important',
    transition: 'all 0.3s linear',
    '&:hover': {
      color: 'red',
      opacity: 0.5
    }
  },
  dropdownSteps: {
    paddingTop: 0,
  },
  btnWrapper: {
    marginTop: '-15px',
  },
  tableWrapper: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '10px',
    paddingTop: 0,
    margin: '10px 0',
    width: '100%',
    height: '100% !important',
    position: 'relative',
    minHeight: 500,
    border: 'none',
    overflow: 'auto',
    '& .rdt': {
      marginLeft: 10,
    },
    '& .rdt .rdtPicker': {
      display: 'none',
    },
    '& .rdtOpen .rdtPicker': {
      display: 'block'
    },
    '& .rdt input, .rdt input::placeholder': {
      color: 'black',
      fontSize: '12px',
      fontFamily: 'Roboto, sans-serif !important',
      fontWeight: 300,
    },
    '& .rt-table': {
      overflow: 'visible',
      marginTop: 15,
      height: 'Calc(100% - 110px)'
    },
    '& .ReactTable': {
        border: 'none',
        overflow: 'visible',
        height: 'Calc(100% - 110px) !important',
        position: 'relative'
      },
    '& .ReactTable .rt-tbody': {
        marginTop: '45px',
        overflowY: 'visible',
        overflowX: 'hidden',
        maxHeight: 'Calc(100%)',
        display: 'flex !important',
        marginLeft: '-6px'
    },
    '& .ReactTable .rt-tbody .rt-td': {
        border: 'none',
        height: 30,
    },
    '& .ReactTable .rt-thead.-header': {
        boxShadow: 'none'
    },
    '& .rt-td': {
        overflow: 'visible',
        marginLeft: '-5px',
        marginTop: "-10px",
        marginBottom: 0,
        paddingTop: 15,
        paddingBottom: 15,
        marginRight: '0 !important'
  
    },
    '& .-even': {
      backgroundColor: '#f9f9f9'
    },
    '& .ReactTable .rt-tbody .rt-tr-group .rt-tr': {
        border: 'none',
        borderBottom: 'solid 1px rgba(210, 210, 210, 0.7)',
        minHeight: '49px'
    },
    '& .ReactTable .rt-thead .rt-th input, .ReactTable .rt-thead .rt-th input::placeholder, .ReactTable .rt-thead .rt-td input::placeholder': {
        color: '#3C4858',
        fontWeight: 'normal',
        opacity: 1,
        fontSize: '18px',
    },
    '& .ReactTable .rt-tbody .rt-tr-group': {
        borderBottom: 'none',
        border: 'none !important',
        maxHeight: '60px'
    }, 
    '& .rt-thead': {
      height: '10px !important',
      maxHeight: '10px !important',
    },
    '& .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td': {
        border: 'none',
        margin: 0,
        padding: 0,

    }
  },
  switchWrapper: {
    width: 0,
    margin: '0px !important',
  }
}