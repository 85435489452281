import React from "react";

// @material-ui/core components
import { Tooltip, withStyles } from "@material-ui/core";

import ReactTable from "react-table";
import CreateNewFolder from '@material-ui/icons/CreateNewFolder';
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Folder from '@material-ui/icons/Folder';
import OpenFolder from "assets/icons/OpenFolder";
import classnames from 'classnames';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import FoldersTableStyle from "./FoldersTableStyle";
import VerificationDialog from "components/VerificationDialog/VerificationDialog";

class FoldersTable extends React.Component {
  constructor (props) {
    super(props);
    this.folderSelectedTimerEnter = null;
    this.folderSelectedTimerLeave = null;
    this.state = {
      hoveredFolder: '',
      sweetAlert: false,
    }
  }
  
  setTimerEnter = (id, time) => {
    const { hoveredFolder } = this.state;
    if (hoveredFolder === "" || hoveredFolder !== id) {
      if (this.folderSelectedTimerEnter) {
        clearTimeout(this.folderSelectedTimerLeave);
        clearTimeout(this.folderSelectedTimerEnter);
      }
      this.folderSelectedTimerEnter = setTimeout(() => {
        this.setState({hoveredFolder: id})
      }, time)
    };
  }
  
  setTimerLeave = (time) => {
    if (this.folderSelectedTimerLeave) clearTimeout(this.folderSelectedTimerLeave);
    this.folderSelectedTimerLeave = setTimeout(() => {
      this.setState({hoveredFolder: ''})
    }, time)
  }

  createFolderRow = ({ folder, currentRow, parentFolderIndex = false }) => {
    const { classes, 
      openFolders,
      selectedFolderId, 
      setExpand, 
      addFolder, 
      editFolder, 
      deleteFolder,
      onDrop,
      checkedRows,
      disableEdit,
      selectedSubFolder,
    } = this.props;
    const { hoveredFolder} = this.state;
    let row = [];
    let isSelected = false;
    const isSubfolder = parentFolderIndex || parentFolderIndex === 0; //if theres parentFolderIndex isSubfolder is true
    const folderRow = isSubfolder ? parentFolderIndex : currentRow; // if theres subfolder use parentFolderIndex else currentRow
    const subFolderRow = isSubfolder ? currentRow : null; //if theres subFolder use currentRow or else the value shouldnt change
    let hasSubFolders = false;
    if( !isSubfolder && folder.subFolders && folder.subFolders.length !== 0) {
      hasSubFolders = true;
    }

   
    if (isSubfolder) {
      isSelected = selectedSubFolder === currentRow;
    }

    if(folder.id === selectedFolderId) isSelected = true;
    const folderClasses = classnames({
      [classes.folderIcon]: true,
      [classes.folderSelected]: isSelected,
      [classes.subFolder]: isSubfolder,
      [classes.hovered]: hoveredFolder === folder.id,
    })

    const arrow = openFolders.some(openFolder => openFolder === currentRow) && !isSubfolder ? <ArrowDropDown/> : <ArrowRight/>;

    row.push(
        <div         
          index={parseInt(folder.id)}
          onDragOver={(e) => {
            const _e = e;
            this.setTimerEnter(folder.id, 30);
            _e.stopPropagation();
            _e.preventDefault();
          }}
          onDragLeave={(e) => {
            this.setTimerLeave(100);
          }}
          onDrop={(e) => {
            onDrop([...checkedRows.filter(c => c), e.dataTransfer.getData("id")], folder.id)
            this.setTimerLeave(100);

            }
          }          
          onClick={(e) => { 
            e.stopPropagation(); 
            setExpand({
              selectedFolder: folderRow, 
              selectedSubFolder: subFolderRow, 
              id: folder.id, 
              hasSubFolders , 
              isSubfolder, 
              parentId: folder.parentFolder ? folder.parentFolder.id : ""}) 
          }}
          className={folderClasses}
          style={{width: '100%', height: '30px'}}
        >
          <div>
            {hasSubFolders && arrow}
            {!hasSubFolders && <span className={classes.invisibleSvg}> <ArrowDropDown/> </span>}
            {isSelected ? <OpenFolder /> : <Folder />}
            <Tooltip
              id="tooltip-top"
              key={folder.id}
              title={folder.name}
              placement="top"
              enterDelay={150}
              classes={{ tooltip: classes.customTooltip}}

            >
              <div> {folder.name} </div>
            </Tooltip>
            
          </div>
          {!disableEdit && <div className={classes.editDeleteOptions}>
            <Edit onClick={() => editFolder(folder.id, folder.name)} />
            <Delete onClick={() => {
              if ((folder.contents && folder.contents.length > 0) || hasSubFolders ) {
                this.setState({sweetAlert: true})
              } else deleteFolder(folder.id)
            }} />
            {!isSubfolder && <CreateNewFolder onClick={() => addFolder(folder.id)} />}
          </div>}
        </div>
    );
    row.push(folder.id);
    //   This is to populate the subFolders
    if (folder.subFolders) {
      let subFolders = folder.subFolders.map((subFolder, index) => {
        return this.createFolderRow({ folder: subFolder, currentRow: subFolder.id, parentFolderIndex: currentRow })
      }
      );
      row.push(subFolders);
    }

    if (folder.subFolders && folder.subFolders.length > 0) {
      row.subFolders = folder.subFolders.map((folder) => { return { name: folder.name } });
    }
    return row;

  }

  createFoldersData = () => {
    const { rootFolders } = this.props;
    let foldersData = rootFolders.map((folder, i) => this.createFolderRow({ folder, parentFolderIndex: false, currentRow: i }));
    return foldersData.map((prop, _) => {
      return {
        id: prop[1],
        name: prop[0],
        subFolders: prop[2] ? prop[2].map(sub => ({ name: sub[0], id: sub[1] })) : []
      };
    })
  }

  sweetAlert () {
    const { sweetAlert } = this.state ;
    if (!sweetAlert) return null;
    return (<VerificationDialog
            open={sweetAlert}
            title={'Não é possível apagar uma pasta com conteúdos ou subpastas'}
            handleClose={() => this.setState({sweetAlert: false})}
            hideButtonAccept
            btnDeclineText='Ok'
          />)
  }

  render() {
    const { classes, openFolders, setExpand, addFolder, disableEdit, juridical } = this.props;
    const foldersData = this.createFoldersData();
    return (
      <Card classes={juridical ? {card: classes.card + ' ' + classes.juridicalCard} : { card: classes.card + ' ' + classes.contentCard }}>
        {!disableEdit && <GridItem className={classes.newFolderWrapper} xs={3} md={3}>
          <Button onClick={() => addFolder(null)} classes={{ root: classes.newFolder }} color="primary"> <CreateNewFolder /> Nova Pasta </Button>
        </GridItem>}
        {this.sweetAlert()}
        <GridItem style={{ padding: 0 }} xs={12}>
          <div className={classes.folderTable + ' ' + (disableEdit ? classes.simpleTable :  '')} >
            <ReactTable
              data={foldersData}
              minRows={foldersData.length}
              showPagination={false}
              pageSize={foldersData.length}
              noDataText=''
              onExpandedChange={(e, index) => {
                setExpand(index[0]);
              }}
              expanded={foldersData.map((folder, k) => openFolders.some(f=> k===f ))}
              getTableProps={() => {
                return ({
                  style: { border: 'none' }
                })
              }}
              getTheadProps={() => {
                return ({
                  style: { display: 'none' }
                })
              }}
              columns={[
                {
                  accessor: "name",
                  sortable: false,
                },
              ]}
              SubComponent={row => {
                return (
                  <ReactTable
                    minRows={0}
                    noDataText=''
                    getTrProps={(state, rowInfo, column) => {
                      return {
                        onClick: (e) => {
                          setExpand(null, rowInfo.index);
                        },
                        style: {
                        }
                      }
                    }}
                    getTableProps={() => {
                      return ({
                        style: { border: 'none' }
                      })
                    }}
                    getTheadProps={() => {
                      return ({
                        style: { display: 'none' }
                      })
                    }}
                    getTbodyProps={() => {
                      return ({
                        style: {
                          border: 'none',
                        },
                      })
                    }}
                    showPagination={false}
                    pageSize={row.original.subFolders.length}
                    data={row.original.subFolders}
                    columns={[
                      {
                        accessor: "name",
                        sortable: false,
                      }
                    ]}
                  />
                );
              }}
            />
          </div>
        </GridItem>
      </Card>
    )
  }
}

export default withStyles(FoldersTableStyle)(FoldersTable);