import React from "react"
import Modal from 'components/ModalWrapper/ModalWrapper';
import { withStyles } from "@material-ui/core";
import { withApollo, Query } from "react-apollo";
import Tabs from "components/Tabs/Tabs.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Tab1 from "./Tabs/Tab1.jsx";
import Tab2 from "./Tabs/Tab2.jsx";
import { union } from 'lodash';
import CollaborationInviteModalStyle from "./CollaborationInviteModalStyle.js";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { createNewGroup, editGroup, GET_MY_ORGANIZATION_QUERY, ADD_COLLAB_MUTATION } from "./CollaborationModalQueries";
import { GET_GROUPS_QUERY } from "../../../views/Team/TeamQueries.js";

class CollaborationModal extends React.Component {
  constructor (props) {

    super(props);
    const membersIds = props.currentGroup ? props.currentGroup.members.map((member) => member.id) : [];
    this.state = {
      currentTab: 'ORGANIZAÇÕES PARCEIRAS',
      tabs:['ORGANIZAÇÕES PARCEIRAS', 'NOVO CONVITE'],
      checkRowTab1: membersIds,
      checkRowTab2: membersIds,
      name: props.currentGroup ? props.currentGroup.name : props.currentGroup,
      selectedRole:{}
    }
  }

  onClickRowTab1 = RowIdClicked => {
    const { checkRowTab1 } = this.state;
    let tmp = checkRowTab1;
    
    if (checkRowTab1.indexOf(RowIdClicked) >= 0) tmp.splice(checkRowTab1.indexOf(RowIdClicked), 1);
    else tmp.push(RowIdClicked);

    this.setState({checkRowTab1: tmp})
  }

  onClickRowTab2 = RowIdClicked => {
    const { checkRowTab2 } = this.state;
    let tmp = checkRowTab2;
    if (checkRowTab2.indexOf(RowIdClicked) >= 0) tmp.splice(checkRowTab2.indexOf(RowIdClicked), 1);
    else tmp.push(RowIdClicked);
    
    this.setState({checkRowTab2: tmp})
  }

  createNewGroup = () => {
    const { client, projectId, onClose, currentGroup } = this.props;
    const { name, checkRowTab1, checkRowTab2 } = this.state;
    
    if (name === '' || !name)
      return this.setState({nameState: "error"});
    if (currentGroup) {
      editGroup({params: {name: name, id: currentGroup.id, 
        membersIds: union(checkRowTab1, checkRowTab2), 
        projectId}, client});
    } else createNewGroup({params: {name: name, 
      membersIds: union(checkRowTab1, checkRowTab2), 
      projectId}, client});
    onClose();
  }

  inviteCollaboration(myOrgId){
    const { client, projectId, onClose } = this.props;
    const { checkRowTab2, selectedRole } = this.state;
    checkRowTab2.forEach( async organizationId => {
      if((!myOrgId || organizationId !== myOrgId) && selectedRole && selectedRole.roleId){
        await client.mutate({
          mutation:ADD_COLLAB_MUTATION, 
          variables:{
            id:projectId,
            organizationId,
            roleId:selectedRole.roleId
          },
          refetchQueries: () => [{
            query: GET_GROUPS_QUERY,
            variables: {id: projectId}
          }]
        })
      } else
          window.alert("Todos os campos precisam ser preenchidos.")
    });
    onClose();
  }

  setRoles(selectedRole){
    this.setState({ selectedRole });
  }

  render () {
    const { classes, organizations, open, onClose, projectId, client, showAlert } = this.props;
    const { currentTab, tabs, checkRowTab1, checkRowTab2 } = this.state;

    const orgData = organizations ? organizations.map((organization)=>{
      let orgRow = {
        _name: organization.name,
        search: <div className={classes.tableDataWrapper} style={{display:"flex", justifyContent:"flex-start"}}> 
                <Checkbox
                  checked={checkRowTab1.indexOf(organization.id) >= 0}
                  tabIndex={-1}
                  onClick={() => this.onClickRowTab1(organization.id)}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />
                <span style={{marginRight:"15px"}}>{organization.name} </span>
              </div>,
      };
      return orgRow;
    }) : <div/>;

    if (!open) return null;

    return (
      <Modal
        
        open={open}
        onBackdropClick={() => onClose()}
        classes={{ root: classes.scrollableModal }}
      >
          <div className={classes.root}>
            <div className={classes.header}>
              <span className={classes.title}>
                Convidar organização
              </span>
            </div>
            <div className={classes.tabs}> 
              <Tabs 
                currentTab={currentTab}
                setCurrentTab={(tab) => this.setState({currentTab: tab})}
                tabs={tabs}
              />
            </div>
            
            <Query query={GET_MY_ORGANIZATION_QUERY}>
              {({data, loading, error}) => {
                return (currentTab === tabs[1]) && !loading ?
                  <Tab1 
                    setPreviousTab={() =>this.setState({currentTab: tabs[0]})}
                    organization={data.myOrganization}
                    checkRowTab={checkRowTab2}
                    onClickRowTab={(id) => this.onClickRowTab2(id)}
                    createTeam={() => this.inviteCollaboration()}
                    onClose={()=>onClose()}
                    projectId={projectId}
                    roles={data.myOrganization.roles}
                    client={client}
                    showAlert={showAlert}

                  />
                 : null 
              }
            }
            </Query>
            <Query query={GET_MY_ORGANIZATION_QUERY}>
              {({data, loading, error}) => {
                return (currentTab === tabs[0]) && !loading ?
                <Tab2 
                  inviteCollab={() => this.inviteCollaboration(data.myOrganization.id)}
                  setPreviousTab={() =>this.setState({currentTab: tabs[0]})}
                  roles={data.myOrganization.roles}
                  setNextTab={() => this.setState({currentTab: tabs[1]})}
                  handleClose={() => onClose()}
                  setRoles={this.setRoles.bind(this)}
                  data={orgData}
                  selectedRole={this.state.selectedRole}
                  showAlert={showAlert}

                />
                 : null 
              }
            }
            </Query>
            
          </div>
      </Modal>
    )
  }
}

export default withApollo(withStyles({...regularFormsStyle, ...CollaborationInviteModalStyle})(CollaborationModal));