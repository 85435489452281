

export default {
  list: {
    backgroundColor: 'white',
    height: '61px',
    width: '100%',
    marginBottom: '70px',
    marginTop: '40px',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.11)'
  },
  listItem: {
    padding: '0 10px !important',
    '&:nth-child(2)': {
      display: 'flex',
      justifyContent: 'flex-end',
    }
  },
  purpleBtn: {
    backgroundColor: '#3F2C78 !important',
    boxShadow: '0 2px 2px 0 rgba(102, 76, 175, 0.14), 0 3px 1px -2px rgba(84, 61, 166, 0.2), 0 1px 5px 0 rgba(76, 81, 175, 0.12)',
    '&:hover': {
      boxShadow: '0 2px 2px 0 rgba(102, 76, 175, 0.14), 0 3px 1px -2px rgba(84, 61, 166, 0.2), 0 1px 5px 0 rgba(76, 81, 175, 0.12)'
    },
    '&:focus': {
      boxShadow: '0 2px 2px 0 rgba(102, 76, 175, 0.14), 0 3px 1px -2px rgba(84, 61, 166, 0.2), 0 1px 5px 0 rgba(76, 81, 175, 0.12)'
    }
  },
  whiteBtn: {
    backgroundColor: 'white !important',
    color: '#3F2C78 !important',
    border: '1px solid #3f2c7880 !important',
    boxShadow: '0 2px 2px 0 rgba(102, 76, 175, 0.14), 0 3px 1px -2px rgba(84, 61, 166, 0.2), 0 1px 5px 0 rgba(76, 81, 175, 0.12)',
    '&:hover': {
      boxShadow: '0 2px 2px 0 rgba(102, 76, 175, 0.14), 0 3px 1px -2px rgba(84, 61, 166, 0.2), 0 1px 5px 0 rgba(76, 81, 175, 0.12)',
    },
    '&:focus': {
      boxShadow: '0 2px 2px 0 rgba(102, 76, 175, 0.14), 0 3px 1px -2px rgba(84, 61, 166, 0.2), 0 1px 5px 0 rgba(76, 81, 175, 0.12)',
    }
  },
  searchIcon: {
    color: 'black',
  },
  archivedProject: {
    backgroundColor: 'rgba(63,44,120,0.85)',
    height:"220px",
    width:"100%",
    position: 'absolute',
    borderRadius: '6px',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  archivedProjectVertical: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  archivedProjectIcon: {
    color: 'white !important',
    fontSize: '70px',
    zIndex: 1000,
  },
  cardBody: {
    flex: 0,
  },
  cardProjects: {
    minHeight: '430px',
  },
  newProject: {
    backgroundColor: '#3F2C78',
    height: '41px',
    minWidth: '162px',
    fontFamily: 'Montserrato, sans-serif',
    fontSize: '14px',
    lineHeight: '14px',
    padding: '10px 12px',
    borderRadius: '6px',
    boxShadow: '0 4px 23px 0 rgba(0,0,0,0.12)',
    display: 'flex',
    justifyContent: 'space-between',
    "&:hover": {
      backgroundColor: '#796aa9',
      boxShadow: "none",
    },
    '& svg': {
      color: "white",
    },
    },
    underline: {
      '&:after': {
        borderColor: "#5A4B88 !important",
      },
      '& input': {
        color: 'black',
      }
  },
  newProjectPlus: {
    fontSize: "27px",
  }
};

