import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import { gql } from "apollo-boost";
import { graphql, Mutation } from "react-apollo";

import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Button from "@material-ui/core/Button";

import Logo from "../../shared/Logo";
import authenticationBg from "../../shared/authenticationBg.svg";
import { ApolloConsumer } from "react-apollo";
import bcrypt from "bcryptjs";

const getUserAuthMutation = gql`
  mutation getUserAuthMutation($email: String!, $password: String!) {
    authUser(email: $email, password: $password)
  }
`;
const GET_USER_PWD_SALT_QUERY = gql`
  query GET_USER_PWD_SALT_QUERY($email: String!) {
    getUserPasswordSalt(email: $email)
  }
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.state = {
      redirect: false,
      error: null,
      email: "",
      password: "",
      showPassword: false
    };
  }

  async login(authUser, client) {
    let saltResponse;

    try {
      saltResponse = await client.query({
        query: GET_USER_PWD_SALT_QUERY,
        variables: { email: this.state.email }
      });
    } catch (error) {
      console.log("error", error);
      if (error.message.includes("User Not Found"))
        this.setState({
          error: "Não existe um usuário cadastrado com esse email."
        });
      else
        this.setState({
          error: "Ops! Algo deu errado. Tente novamente mais tarde."
        });
      return error;
    }
    try {
      let salt = saltResponse.data.getUserPasswordSalt;
      let hash = await bcrypt.hash(this.state.password, salt);
      const token = authUser({
        variables: {
          email: this.state.email,
          password: hash
        }
      });
      if ((await token).data) {
        localStorage.setItem("token", (await token).data.authUser);
        // this.setState({redirect: true});
        // this.props.history.push('/admin/projects');
        window.location.href = "/admin/projects";
      }
    } catch (error) {
      console.log(error);
      if (error.message.includes("User not found while trying to authenticate"))
        this.setState({ error: "Email ou senha incorreta." });
      else
        this.setState({
          error: "Ops! Algo deu errado. Tente novamente mais tarde."
        });
    }
  }

  handleChange(prop, event) {
    this.setState({ [prop]: event.target.value });
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    const classes = this.props.classes;

    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <div className={classes.logo}>
            <Logo />
          </div>
          <Typography component="h1" variant="h5">
            {this.props.title}
          </Typography>
          <ApolloConsumer>
            {client => (
              <Mutation mutation={getUserAuthMutation}>
                {authUser => (
                  <form
                    className={classes.form}
                    onSubmit={async event => {
                      event.preventDefault();
                      this.login(authUser, client);
                    }}
                  >
                    {this.state.error ? (
                      <div style={{ color: "RED" }}> {this.state.error} </div>
                    ) : (
                      ""
                    )}
                    <TextField
                      id="email"
                      label="E-mail"
                      //type='email'
                      name="email"
                      autoComplete="email"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      autoFocus
                      required
                      value={this.state.email}
                      onChange={event => this.handleChange("email", event)}
                    />
                    <TextField
                      id="password"
                      label="Senha"
                      type={this.state.showPassword ? "text" : "password"}
                      name="password"
                      autoComplete="current-password"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      required
                      value={this.state.password}
                      onChange={event => this.handleChange("password", event)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Mostrar ou esconder senha"
                              onClick={this.handleClickShowPassword}
                            >
                              {this.state.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      size="large"
                      fullWidth
                    >
                      Entrar
                    </Button>
                  </form>
                )}
              </Mutation>
            )}
          </ApolloConsumer>
          <RouterLink to="/forgot-password" className={classes.link}>
            Esqueci minha senha
          </RouterLink>
        </Paper>
      </main>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
};

const styles = theme => ({
  form: {
    width: "100%",
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit
  },
  link: {
    marginTop: theme.spacing.unit * 3,
    display: "block",
    textDecoration: "none"
  },
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "100vw",
    minHeight: "100vh",
    backgroundColor: "#ffd600",
    backgroundImage: `url(${authenticationBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center center"
  },
  paper: {
    textAlign: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400
    }
  },
  logo: {
    width: "128px",
    height: "128px",
    margin: "0 auto",
    marginBottom: theme.spacing.unit * 3
  }
});

const StyledLogin = withStyles(styles)(Login);

export default graphql(getUserAuthMutation)(StyledLogin);
