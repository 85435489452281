const style = {
    root: {
        backgroundColor: 'white',
        width: '340px',
        height: '200px',
        margin: '0 auto',
        top: '30%',
        zIndex: '131',
        position: 'relative',
        padding: '15px 25px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '6px'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    input: {
        width: '100%'
    },
    modalName: {
        fontSize: '20px'
    },
    close: {
        cursor: 'pointer',
    },
    ok: {
        paddingLeft: 20,
        paddingRight: 20,
    }
}

export default style;