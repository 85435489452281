export default {
  root: {
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '1px 1px 1px 2px rgba(0,0,0,0.15)',
    cursor: 'pointer',
    paddingTop: 15,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    borderRadius: 3,
    backgroundColor: '#f9f9f9ad',
    height: 50,
    transition: 'all 0.5 linear',
    '& > span': {
      color: '#3f2c78 !important',
      marginLeft: '-35px'
    },
    '&:hover': {
      backgroundColor: '#e1e1e1'
    }
  },
  moreEpisodes: {
    fontWeight: 400,
    fontSize: 18,
    marginLeft: 10,
  }
}