import React, { Component } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { AddCircle, RemoveCircle } from "@material-ui/icons";
import ProjectRoleStyle from './ProjectRoleStyle';
import { withStyles, Card } from '@material-ui/core';
import { GET_PROJECTS, GET_MY_ORGANIZATION_QUERY } from "./ProjectRoleQueries";

export class ProjectRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: true,
      projects: [],
      roles: [],
      project: undefined,
      role: undefined,
      addedProjects: []
    }
  }

  componentDidMount() {
    const { client } = this.props;
    client.query({
      query: GET_PROJECTS,
    }).then(res => {
      this.setState({ projects: res.data.projects })
    })
    
    client.query({
      query: GET_MY_ORGANIZATION_QUERY,
    }).then(res => {
      this.setState({ roles: res.data.myOrganization.roles })
    })
  }
  removeProject = (index) => {
    const { addedProjects } = this.state;
    let _projects = addedProjects;
    _projects.splice(index, 1);
    this.setState({addedProjects: _projects})
  }
  insertNewProject = () => {
    const { addedProjects, project, role } = this.state;
    const { setProjects } = this.props;
    if (!project || !role) return;
    let _addedProjects = addedProjects;

    _addedProjects[addedProjects.length] = { project: project, role: role} 
    this.setState({addedProjects: _addedProjects, project: undefined, role: undefined})
    setProjects(_addedProjects);
  }
  renderProjects = (isNew, index) => {
    const { classes } = this.props;
    const { roles, projects, addedProjects } = this.state;
    const dropdownRoles = roles.map(role => ({ obj: true, name: role.name, id: role.id }))
    const dropdownProjects = projects.map(project => ({ obj: true, name: project.name, id: project.id }))
    const project = isNew ? this.state.project : addedProjects[index].project;
    const role = isNew ? this.state.role : addedProjects[index].role;
    return (
      <GridContainer direction='row'>
        <GridItem xs={5}>
          <CustomDropdown
            buttonText={project ? project.name : 'Projeto'}
            onClick={(project) => {
              if (isNew) {
                this.setState({ project: { name: project.name, id: project.id } })
              } else {
                const _addedProjects = addedProjects;
                _addedProjects[index].project = project;
                this.setState({addedProjects: _addedProjects})
              }
            }}
            classes={{ button: classes.dropDown, butonText: classes.dropDownButtonText, buttonTextWrapper: classes.buttonTextWrapper, manager: classes.manager }}
            dropdownList={dropdownProjects}
          />
        </GridItem>
        <GridItem xs={5}>
          <CustomDropdown
            buttonText={role ? role.name : 'Papel'}
            onClick={(role) => {
              if (isNew) {
                this.setState({ role: { name: role.name, id: role.id } })
              } else {
                const _addedProjects = addedProjects;
                _addedProjects[index].role = role;
                this.setState({addedProjects: _addedProjects})
              }
            }}
            classes={{ button: classes.dropDown, butonText: classes.dropDownButtonText, buttonTextWrapper: classes.buttonTextWrapper, manager: classes.manager }}
            dropdownList={dropdownRoles}
          />
        </GridItem>
        {isNew && <GridItem xs={1}>
          <Button onClick={() => { }} classes={{ root: classes.inviteWrapper }} color='white' round justIcon>
            <span className={classes.invite}>
              <AddCircle
                onClick={() => this.insertNewProject()}
              />
            </span>
          </Button>
        </GridItem>}
        {!isNew && <GridItem xs={1}>
          <Button onClick={() => { }} classes={{ root: classes.inviteWrapper }} color='white' round justIcon>
            <span className={classes.invite}>
              <RemoveCircle
                onClick={() => this.removeProject(index)}
              />
            </span>
          </Button>
        </GridItem>}
      </GridContainer>
    )
  }

  render() {
    const { classes } = this.props;
    const { addedProjects } = this.state;
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          {addedProjects.map((project, index) => this.renderProjects(false, index))}
          {this.renderProjects(true)}
        </Card>
      </div>
    );
  }
}

export default withStyles(ProjectRoleStyle)(ProjectRole);
