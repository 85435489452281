import React, { Component } from 'react';
import { gantt } from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';
import 'dhtmlx-gantt/codebase/ext/dhtmlxgantt_marker.js';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.js';
import { withStyles, Button } from '@material-ui/core';
import GanttStyle from "./GanttStyle";
import { UnfoldLess, UnfoldMore } from "@material-ui/icons";
import moment from 'moment';
require('dhtmlx-gantt/codebase/locale/locale_pt.js');
require('dhtmlx-gantt/codebase/ext/dhtmlxgantt_tooltip.js');

class Gantt extends Component {
  constructor (props) {
    super(props);
    this.state = {
      scale: "month"
    }
    this.debounce = null;

  }

  componentWillUnmount () {
    gantt.refreshData();
    gantt.clearAll();
  }

  phaseDateSort = (a, b) => {
    let dateComparison = moment(b.start_date, 'DD-MM-YYYY').isAfter(moment(a.start_date, 'DD-MM-YYYY'));
    if(!a.start_date && !b.start_date) return this.rowDateSort(a, b);  
    if (dateComparison) {
        return -1;
    } else if (!dateComparison) {
        return 1;
    }
    return 0;
  }

  componentDidMount() {
    gantt.config.xml_date = "%dd/%mm/%YYYY";
    const { tasks, hideGrid, editRow, onDrag } = this.props;
    
    let sortedTasks = {
      data:tasks ? tasks.data.sort(this.phaseDateSort) : []
    }

    
    gantt.config.columns = [
        {name:"text",       label:"Nome do Projeto",  width:"*", tree:true },
        {name:"start_date", label:"Data de Início", align:"center" },
        {name:"duration",   label:"Duração",   align:"center" },
        {name:"add",        label:"",           width:44 }
      ];
      // reordering tasks within the whole gantt
      gantt.config.order_branch = true;
      gantt.config.order_branch_free = true;
      gantt.attachEvent("onTaskDblClick", function(id,e){
        //any custom logic here
        return false;
    });

    gantt.templates.task_class = function(start, end, task){
      if (task.type === 'row')
        return "event-element";
      return "";
    };

    gantt.config.readonly = true;
    
    gantt.attachEvent("onTaskDrag", function(id, mode, task, original){
      if (this.debounce) clearTimeout(this.debounce);

      if (this.debounce) clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        editRow({id: id, startDate: task.start_date, endDate: task.end_date})
        onDrag(task);
      }, 100)

    });

    var markerId = gantt.addMarker({  
      start_date: new Date(), 
      css: "today", 
      text: "Hoje", 
      title:  'Hoje' 
    });

    gantt.getMarker(markerId);
    if (hideGrid) gantt.config.show_grid = false;
    else gantt.config.show_grid = true;

    gantt.config.scale_unit = "month";

    gantt.init(this.ganttContainer);
    gantt.parse(sortedTasks ? sortedTasks : tasks);
}

  ganttConfig(){
    const { tasks  } = this.props;
    const { scale  } = this.state;
    gantt.refreshData(); //cleanData
    gantt.clearAll();
    let sortedTasks = {
      data:tasks ? tasks.data.sort(this.phaseDateSort) : []
    }
    gantt.parse(sortedTasks ? sortedTasks : tasks);
    var markerId = gantt.addMarker({  
      start_date: new Date(), 
      css: "today", 
      text: "Hoje", 
      title:  'Hoje' 
    });
    gantt.getMarker(markerId); //reload marker after clearAll (clearAll is necessary to update gantt)
    if(scale) gantt.config.scale_unit = scale;
  }
  componentDidUpdate () {
    this.ganttConfig();
  }

  async setScale(newScale){
    const { scale } = this.state;
    if(scale !== newScale) await this.setState({ scale: newScale});
    this.ganttConfig();
  }

  setActiveStyle(scale){
    const { classes } = this.props;
    if(this.state.scale === scale) return classes.activeScale;
    else return ""  
  }

  render() {
    const { classes, microView, setMicroView } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.ganttHeader}>
          {setMicroView && 
          <div onClick={()=> setMicroView(true)} className={classes.microViewToggle} >
            {!microView ? 
              <Button className="openIcon" small variant="outlined"><UnfoldMore/> </Button> 
              : 
              <Button className="openIcon" small variant="outlined"><UnfoldLess/> </Button> }
          </div>}
          <div className={classes.btnGroup}> 
            <button onClick={()=>{ this.setScale("month")}} className={classes.rbcbutton + " " + this.setActiveStyle("month")}>MÊS</button> 
            <button onClick={()=>{ this.setScale("week")}} className={classes.rbcbutton + " " + this.setActiveStyle("week")}>SEMANA</button>
            <button onClick={()=>{ this.setScale("day")}} className={classes.rbcbutton + " " + this.setActiveStyle("day")}>DIA</button>
          </div>

        </div>
        <div style={{ width:"100%", backgroundColor:"white", height:"30px", marginTop:"26px", marginBottom :"-30px"}}> </div>
        <div
          ref={(input) => { this.ganttContainer = input }}
          //this style is here because putting it in a class at ganttstyle doesnt work in this instance (beats me why that is)
          style={{ height:'Calc(100vh - 261px)', marginTop: "30px" }}
        />
        </div>
    );
  }
}

export default withStyles(GanttStyle)(Gantt);
