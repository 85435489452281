import moment from "moment";
import timeCodeConvert from "./timeCodes";

function formatSingleComment({ comment, isResponse }) {
  return {
    author: comment.creator?.name,
    text: comment.text,
    response: isResponse ? "Resposta:" : "",
    date: moment(comment.creationDate).format("DD/MM/YY hh:mm"),
    status: comment.checked ? "✓" : "",
    timecode: timeCodeConvert(comment.timeStamp),
    version: comment.version.count
  };
}

export function headerByFileType(fileType) {
  let header = [];
  if (fileType === "vimeo" || fileType === "audio") {
    header = [
      { label: "Versão", key: "version" },
      { label: "Data - Hora", key: "date" },
      { label: "Timecode", key: "timecode" },
      { label: "Resposta", key: "response" },
      { label: "Texto", key: "text" },
      { label: "Autor", key: "author" },
      { label: "Status", key: "status" }
    ];
  } else if (fileType === "image") {
    header = [
      { label: "Versão", key: "version" },
      { label: "Data - Hora", key: "date" },
      { label: "Resposta", key: "response" },
      { label: "Texto", key: "text" },
      { label: "Autor", key: "author" },
      { label: "Status", key: "status" }
    ];
  } else {
    header = [
      { label: "Versão", key: "version" },
      { label: "Data - Hora", key: "date" },
      { label: "Resposta", key: "response" },
      { label: "Texto", key: "text" },
      { label: "Autor", key: "author" },
      { label: "Status", key: "status" }
    ];
  }
  return header;
}

export function commentsToCSV({ comments }) {
  const formatedComments = [];
  comments.forEach(comment => {
    if (!comment.parent)
      formatedComments.push(
        formatSingleComment({ comment, isResponse: false })
      );
    if (comment.subComments) {
      comment.subComments.forEach(subComment =>
        formatedComments.push(
          formatSingleComment({
            comment: subComment,
            isResponse: true
          })
        )
      );
    }
  });
  return formatedComments;
}
