import React, { createRef } from "react";

import withStyles from "@material-ui/core/styles/withStyles";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Header from "components/Header/Header";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import FilterNone from "@material-ui/icons/FilterNone";
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import LinkIcon from "@material-ui/icons/Link";
import CloudDownload from "@material-ui/icons/CloudDownload";
import DeleteIcon from "@material-ui/icons/Delete";
import CanvasDraw, { EMPTY_CANVAS } from "components/CanvasDraw/index.jsx";

import avatar from "assets/img/avatar.png";

// @material-ui/icons
import JuridicoIcon from "assets/icons/Juridico";
import { Brush, AccessTime, Close } from "@material-ui/icons";
import Paperclip from "@material-ui/icons/AttachFile";
import LocalOffer from "@material-ui/icons/LocalOfferOutlined";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { compressToBase64, decompressFromBase64 } from "lz-string";
import Player from "views/Vimeo/vimeoPlayer";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import SectionComments from "../Comments/SectionComments";
import OnlyOffice from "views/Content/OnlyOffice.jsx";
import CleanOnlyOffice from "views/Content/CleanOnlyOffice.jsx";
import getPlayerDimensions from "./utils/getPlayerDimensions.js";
import { myUser } from "queries/queries.js";
import {
  GET_CONTENT_QUERY,
  GET_VERSION_QUERY,
  EDIT_CONTENT_STATUS_MUTATION,
  EDIT_JURIDICAL_STATUS_MUTATION,
  ADD_COMMENT_MUTATION,
  ADD_JURIDICAL_COMMENT_MUTATION,
  GET_VERSION_WITH_LINKS_QUERY,
  deleteVersion
} from "./ContentViewQueries";
import { Mutation, Query, withApollo } from "react-apollo";
import moment, { now } from "moment";
import { compareCountDescending } from "views/Comments/utils/sort&filter.js";
import UploadAttachments from "../../components/Upload/UploadAttachment";
import Loading from "../../components/Loading/Loading";
import { Redirect } from "react-router-dom";
import { GET_CONTENT_FROM_FOLDER_QUERY } from "../Content/ContentView/ContentDirectoryQueries";
import { GET_JURIDICAL_FOLDERS_QUERY } from "../Juridical/JuridicalQueries";
import AudioPlayer from "components/AudioPlayer/AudioPlayer.jsx";
import PdfReader from "components/PdfReader/PdfReader.jsx";

class EditView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentText: "",
      commentFocus: false,
      timeStamp: "",
      versionId: "",
      playerTime: 0.0,
      commentTags: [],
      currentVersion: {},
      moreOpen: false,
      anchorEl: null,
      filekey: "",
      inputFrequency: 500,
      commentRefetch: null,
      reviewLink: "",
      downloadLink: "",
      latestVersionState: false,
      fileKeys: [],
      fileNames: [],
      fileTypes: [],
      type: "",
      AttachedDocumentIds: [],
      commentsAttachmentOpen: false,
      img: { width: 100, height: 100 },
      showCanvas: false,
      commentCanvas: EMPTY_CANVAS,
      ogCommentCanvas: EMPTY_CANVAS,
      commentClick: false,
      requestSaveCanvas: false,
      videoSize: {},
      isCommentingAllowed: true,
      audioPlayer: null
    };
    getPlayerDimensions.bind(this)();
    this.imgRef = createRef();
    this.timeStampRedirect = this.timeStampRedirect.bind(this);
    this.onClick = this.onClick.bind(this);
    this.setCommentRefetch = this.setCommentRefetch.bind(this);
    this.setVimeoPlayer = this.setVimeoPlayer.bind(this);
    this.setAudioPlayer = this.setAudioPlayer.bind(this);
    this.resizeId = null;
  }

  setLatestVersion(latestVersionState) {
    this.setState({ latestVersionState });
  }

  setCommentRefetch(commentRefetch) {
    this.setState({ commentRefetch });
  }

  setVimeoPlayer(player) {
    this.setState({ player });
  }

  setAudioPlayer(audioPlayer) {
    this.setState({ audioPlayer });
  }

  async addTag(commentTag) {
    if (!this.state.commentTags.includes(commentTag))
      await this.setState({
        commentTags: [...this.state.commentTags, commentTag]
      });
  }

  setCommentCanvas(canvasDraw, canvasDimensions) {
    const { type } = this.state;
    const _decompressed =
      type === "onlyoffice"
        ? JSON.parse(canvasDraw)
        : decompressFromBase64(canvasDraw);

    this.setState(
      { showCanvas: true, commentClick: true, canvasDimensions },
      () => this.setState({ ogCommentCanvas: _decompressed })
    );
  }

  requestSaveCanvas() {
    this.setState({ requestSaveCanvas: true }, () =>
      this.setState({ requestSaveCanvas: false })
    );
  }

  async submitComment(addComment, refetch) {
    let { type, commentCanvas, commentClick, canvasDimensions } = this.state;
    if (commentClick) commentCanvas = EMPTY_CANVAS;
    this.requestSaveCanvas();
    let commentTags = [];
    if (this.state.commentTags.length > 0)
      commentTags = [...this.state.commentTags];
    let commentVariables;
    if (
      this.state.AttachedDocumentIds &&
      this.state.AttachedDocumentIds.length > 0
    )
      commentVariables = {
        text: this.state.commentText,
        versionId: this.state.versionId,
        timeStamp: this.state.timeStamp
          ? this.state.timeStamp.toString()
          : null,
        commentTags,
        fileKeys: this.state.fileKeys,
        fileNames: this.state.fileNames,
        fileTypes: this.state.fileTypes,
        canvasDraw:
          type === "onlyoffice"
            ? JSON.stringify(commentCanvas)
            : compressToBase64(commentCanvas),
        attachmentsIds: this.state.AttachedDocumentIds,
        canvasHeight: canvasDimensions
          ? Math.ceil(canvasDimensions.height)
          : null,
        canvasWidth: canvasDimensions ? Math.ceil(canvasDimensions.width) : null
      };
    else
      commentVariables = {
        text: this.state.commentText,
        versionId: this.state.versionId,
        timeStamp: this.state.timeStamp
          ? this.state.timeStamp.toString()
          : null,
        commentTags,
        fileKeys: this.state.fileKeys,
        canvasDraw:
          type === "onlyoffice"
            ? JSON.stringify(commentCanvas)
            : compressToBase64(commentCanvas),
        fileNames: this.state.fileNames,
        fileTypes: this.state.fileTypes,
        canvasHeight: canvasDimensions
          ? Math.ceil(canvasDimensions.height)
          : null,
        canvasWidth: canvasDimensions ? Math.ceil(canvasDimensions.width) : null
      };

    await addComment({
      variables: commentVariables
    });
    await this.state.commentRefetch({ versionId: this.state.versionId });
    await this.setState({
      commentText: "",
      commentPosted: true,
      commentTags: [],
      timeStamp: null,
      fileNames: [],
      fileKeys: [],
      fileTypes: [],
      AttachedDocumentIds: [],
      commentCanvas: EMPTY_CANVAS,
      canvasDimensions: {}
    });
    refetch();
  }

  setStatusColor(status) {
    switch (status) {
      case "Em Andamento":
        return " buttonOrange";
      case "Em Revisão":
        return " buttonRed";
      case "Versão Final":
        return " buttonGreen";
      default:
        return " buttonBlack";
    }
  }

  async removeTag(tag) {
    let index = await this.state.commentTags.indexOf(tag); //finds index of the tag removes it and sets new state
    let newTags = this.state.commentTags;
    if (index > -1) {
      await newTags.splice(index, 1);
    }
    await this.setState({ commentTags: newTags });
  }

  timeStampRedirect(playerTime) {
    let {
      audioPlayer,
      player
    } = this.state;
    let _tempCurrent;
    if(audioPlayer)
      _tempCurrent = audioPlayer.current;
    this.setState({ playerTime });
    if (player) {
      if (this.state.playerTime !== playerTime) {
        player.pause();
      }
      player.setCurrentTime(playerTime);
    } else if (_tempCurrent) {
      _tempCurrent.currentTime = playerTime;
      console.log("audioPlayerCurrent", _tempCurrent);
    }
  }

  onClick(item) {
    this.setState({ currentVersion: item.version, isCommentingAllowed: true });
    this.setState({ versionId: item.version.id });
  }

  handleComment = e => {
    const { commentClick } = this.state;
    e.preventDefault();
    this.setState({ commentText: e.target.value });
    this.setState({ commentFocus: !this.state.commentFocus });
    if (commentClick) {
      this.setState({ commentClick: false, commentCanvas: EMPTY_CANVAS });
    }
  };

  async setVimeoTimeStamp() {
    if (!this.state.commentFocus) await this.setState({ commentFocus: true });
  }

  async setAudioTimeStamp() { console.log("ops")
    if (this.state.audioPlayer) {
      this.state.audioPlayer.current.pause();
      this.parentHandleTimeStamp(this.state.audioPlayer.current.currentTime);
    }
    if (!this.state.commentFocus) await this.setState({ commentFocus: true });
  }

  erasePlayerTime() {
    this.setState({ playerTime: null });
  }

  toggleAttachUploadModal() {
    this.setState({ attachIsOpen: !this.state.attachIsOpen });
  }

  setFilesData({ fileNames, fileKeys, fileTypes }) {
    this.setState({
      fileNames: [...this.state.fileNames, ...fileNames],
      fileKeys: [...this.state.fileKeys, ...fileKeys],
      fileTypes: [...this.state.fileTypes, ...fileTypes]
    });
  }

  async setAttachedDocumentIds({ AttachedDocumentIds }) {
    this.setState({
      AttachedDocumentIds: [
        ...this.state.AttachedDocumentIds,
        ...AttachedDocumentIds
      ]
    });
  }

  setMoreOpen = newState => this.setState({ moreOpen: newState });
  setAnchorEl = newState => this.setState({ anchorEl: newState });

  timeCodeConvert(timeCode) {
    if (!timeCode) return "";
    const subStrings = timeCode.split(".");
    let seconds = subStrings[0];
    let minutes = 0;
    let hours = 0;
    while (seconds >= 60) {
      minutes++;
      seconds -= 60;
    }
    while (minutes >= 60) {
      hours++;
      minutes -= 60;
    }

    minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
    hours = hours.toString().length === 1 ? "0" + hours : hours;
    seconds = seconds.length === 1 ? "0" + seconds : seconds;
    seconds = seconds.length === 0 ? "00" : seconds;

    if (parseInt(hours) === 0) return minutes + ":" + seconds;
    else return hours + ":" + minutes + ":" + seconds;
  }

  async changeStatus(editContent, newStatus) {
    let contentId = this.state.currentVersion.content.id;
    await editContent({ variables: { id: contentId, status: newStatus } });
    this.setState({ status: newStatus });
  }

  getVimeoTimeStamp() {
    if (this.state.player) {
      this.state.player.pause();
      this.state.player.getCurrentTime().then(timeStamp => {
        this.parentHandleTimeStamp(timeStamp);
      });
    }
  }

  parentHandleTimeStamp(timeStamp) {
    timeStamp = timeStamp.toString();
    if (!this.state.timeStamp) {
      this.setState({ timeStamp });
      return;
    }
    if (timeStamp !== this.state.timeStamp) {
      this.setState({ timeStamp });
      return;
    }
  }

  render() {
    const {
      moreOpen,
      type,
      commentClick,
      ogCommentCanvas,
      showCanvas,
      anchorEl,
      attachIsOpen,
      contentRefetch,
      commentsAttachmentOpen,
      isCommentingAllowed,
      canvasDimensions
    } = this.state;

    const {
      classes,
      match: { path }
    } = this.props;
    const juridical = path ? path.includes("juridical") : false;
    let tags = [
      <span key="Correção" className={classes.dropdownTag}>
        Correção
      </span>,
      <span key="Atenção" className={classes.dropdownTag}>
        Atenção
      </span>,
      <span key="Dúvida" className={classes.dropdownTag}>
        Dúvida
      </span>,
      <span key="BLUR" className={classes.dropdownTag}>
        BLUR
      </span>,
      <span key="Por mim, aprovado" className={classes.dropdownTag}>
        Por mim, aprovado!
      </span>
    ];
    if (juridical)
      tags = [
        <span key="Autorização - Imagem e Voz" className={classes.dropdownTag}>
          Autorização - Imagem e Voz
        </span>,
        <span key="Autorização - Cartaz" className={classes.dropdownTag}>
          Autorização - Cartaz
        </span>,
        <span key="Locação" className={classes.dropdownTag}>
          Locação
        </span>,
        <span key="Obra Musical" className={classes.dropdownTag}>
          Obra Musical
        </span>,
        <span key="Domínio Público" className={classes.dropdownTag}>
          Domínio Público
        </span>,
        <span key="Arquivo / Imagem" className={classes.dropdownTag}>
          Arquivo / Imagem
        </span>,
        { divider: true },
        <span key="PENDENTE - Contrato" className={classes.dropdownTag}>
          PENDENTE - Contrato
        </span>,
        <span key="PENDENTE - Identificação" className={classes.dropdownTag}>
          PENDENTE - Identificação
        </span>,
        <span key="ERRO" className={classes.dropdownTag}>
          ERRO
        </span>,
        <span key="BLUR" className={classes.dropdownTag}>
          BLUR
        </span>
      ];

    if (this.state.contentNotFound) return <Redirect to="/admin/projects" />;
    return (
      <Query
        query={GET_CONTENT_QUERY}
        variables={{ contentId: this.props.match.params.contentId }}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data: contentData, refetch }) => {
          let NotAllowedCss = {};
          if (loading) return <Loading />;

          if (error) {
            console.log(`Error! ${error.message}`);
            if (error.message.includes("Content Not Found")) {
              setTimeout(() => this.setState({ contentNotFound: true }), 2500);
              return (
                <div>
                  <h1>
                    Ops, este conteúdo foi deletado! Redirecionando de volta...
                  </h1>
                </div>
              );
            }
            return null;
          }

          let contentStatus = juridical
            ? contentData.content.juridicalStatus
            : contentData.content.status;

          if (!this.state.projectId)
            this.setState({ projectId: contentData.content.project.id });
          if (this.state.versionId === "") {
            this.setState({
              status: contentStatus,
              versionId: contentData.content.latestVersion.id,
              currentVersion: contentData.content.latestVersion,
              latestVersionId: contentData.content.latestVersion.id
            });
          }

          const versionFileExtension = contentData.content.latestVersion.name
            .split(".")
            .pop();
          const cleanOnlyOfficeExtension = [
            "csv",
            "fods",
            "ods",
            "ots",
            "xls",
            "xlsm",
            "xlsx",
            "xlt",
            "xltm",
            "xltx",
            "fodp",
            "odp",
            "otp",
            "pot",
            "potm",
            "potx",
            "pps",
            "ppsm",
            "ppsx",
            "ppt",
            "pptm",
            "pptx"
          ];
          const useCleanOnlyOffice = cleanOnlyOfficeExtension.includes(
            versionFileExtension
          );

          if (contentData && !this.state.contentRefetch) {
            this.setState({ contentRefetch: refetch });
          }
          if (contentData.content.status && !this.state.status)
            this.setState({ status: contentStatus });

          if (
            isCommentingAllowed &&
            (this.state.currentVersion.id !== this.state.latestVersionId ||
              (this.state.currentVersion.content &&
                this.state.status === "Versão Final"))
          ) {
            this.setState({ isCommentingAllowed: false });
          }

          if (
            !this.state.latestVersionState &&
            this.state.currentVersion.id === this.state.latestVersionId
          ) {
            this.setState({ latestVersionState: true });
          }

          if (!isCommentingAllowed) {
            NotAllowedCss = { cursor: "not-allowed" };
          }

          let versionNameList = contentData.content.versions;
          versionNameList = contentData.content.versions
            .sort(compareCountDescending)
            .map(version => {
              let highlighted = this.state.currentVersion.id === version.id;
              return {
                obj: true,
                highlighted,
                name: (
                  <div style={{ width: "100%", height: "100%" }}>
                    <span>
                      {contentData.content.latestVersion.id !== version.id ? (
                        <span>
                          <b>{`Versão ${version.count}`}</b> |{" "}
                          {moment(version.creationDate).format(
                            "DD/MM/YY (HH:mm)"
                          )}
                        </span>
                      ) : (
                        <span>
                          <b>Versão atual</b> |{" "}
                          {moment(version.creationDate).format(
                            "DD/MM/YY (HH:mm)"
                          )}
                        </span>
                      )}
                    </span>
                    <br />
                    <span>{version.name}</span>
                  </div>
                ),
                version
              };
            });

          let projectRedirectUrl;

          if (juridical) {
            if (
              contentData.content.juridicalFolder &&
              contentData.content.juridicalFolder.parentFolder
            )
              projectRedirectUrl = `${
                contentData.content.juridicalFolder.parentFolder.id
              }/${contentData.content.juridicalFolder.id}`;
            else if (contentData.content.juridicalFolder)
              projectRedirectUrl = `${
                contentData.content.juridicalFolder.id
              }/0`;
            else
              projectRedirectUrl = `${
                contentData.content.project.defaultJuridicalFolder.id
              }/0`;
            if (contentData.content)
              projectRedirectUrl =
                `/project/${contentData.content.project.id}/juridico/` +
                projectRedirectUrl;
          } else {
            if (contentData.content.folder.parentFolder)
              projectRedirectUrl = `${
                contentData.content.folder.parentFolder.id
              }/${contentData.content.folder.id}`;
            else projectRedirectUrl = `${contentData.content.folder.id}/0`;
            if (contentData.content)
              projectRedirectUrl =
                `/project/${contentData.content.project.id}/contents/` +
                projectRedirectUrl;
          }

          return (
            <Query query={myUser}>
              {({ data, loading, error }) => {
                if (loading) {
                  return null;
                }
                if (error) {
                  return null;
                }
                if (!data) {
                  window.location.href = "/login";
                }
                let userPermissions = data.myUser.role.permissions;
                let userData = data.myUser;
                return (
                  <GridContainer
                    style={{
                      height: "100%",
                      backgroundColor: "#0f1014",
                      pointerEvents:
                        commentsAttachmentOpen || attachIsOpen ? "none" : ""
                    }}
                  >
                    <GridItem
                      className="videoEditorContainer"
                      xs={12}
                      sm={12}
                      md={8}
                      lg={9}
                      xl={9}
                    >
                      <div className="playerContainer">
                        <Header
                          style={{ height: "10vh" }}
                          customStyle={{
                            marginBottom: "5px",
                            backgroundColor: "#1f2229",
                            boxShadow: "none",
                            height: "50px",
                            borderRadius: "0"
                          }}
                          color="primary"
                          classes={{ container: "headerContainer" }}
                          noLiPadding={true}
                          links={
                            <List
                              className="navList removePadding"
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                paddingLeft: "5px"
                              }}
                            >
                              <ListItem
                                className="navList"
                                classes={{ root: "listSmallPadding" }}
                              >
                                <div
                                  style={{ display: "flex", marginTop: "7px" }}
                                >
                                  {this.state.isRedirected && (
                                    <Redirect
                                      from="/content/view/:contentId"
                                      to={projectRedirectUrl}
                                    />
                                  )}
                                  <div
                                    onClick={() =>
                                      this.setState({ isRedirected: true })
                                    }
                                  >
                                    {" "}
                                    <ArrowBackIos
                                      classes={{ root: "chevron" }}
                                    />{" "}
                                  </div>
                                  <div className="videoTitle">
                                    {contentData.content.name}
                                  </div>
                                </div>
                              </ListItem>

                              <ListItem className="navItem">
                                <CustomDropdown
                                  buttonText={
                                    <span>
                                      {contentData.content.latestVersion.id !==
                                      this.state.currentVersion.id
                                        ? "Versão " +
                                          this.state.currentVersion.count
                                        : "Versão atual"}
                                    </span>
                                  }
                                  buttonIcon={FilterNone}
                                  buttonProps={{ size: "sm" }}
                                  classes={{ button: "buttonDropDown" }}
                                  width={"250px"}
                                  dropdownList={versionNameList}
                                  onClick={this.onClick}
                                />
                              </ListItem>
                              <ListItem
                                className="navItem"
                                style={{ flex: 1, justifyContent: "flex-end" }}
                              >
                                {this.state.currentVersion &&
                                  this.state.currentVersion.content && (
                                    <Mutation
                                      mutation={
                                        juridical
                                          ? EDIT_JURIDICAL_STATUS_MUTATION
                                          : EDIT_CONTENT_STATUS_MUTATION
                                      }
                                      refetchQueries={() => {
                                        return [
                                          {
                                            query: GET_CONTENT_FROM_FOLDER_QUERY,
                                            variables: {
                                              projectId: this.state.projectId,
                                              folderId:
                                                contentData.content.folder.id
                                            }
                                          },
                                          {
                                            query: GET_JURIDICAL_FOLDERS_QUERY,
                                            variables: {
                                              projectId: this.state.projectId,
                                              folderId:
                                                contentData.content &&
                                                contentData.content
                                                  .juridicalFolder
                                                  ? contentData.content
                                                      .juridicalFolder.id
                                                  : ""
                                            }
                                          },
                                          {
                                            query: GET_CONTENT_QUERY,
                                            variables: {
                                              contentId: this.props.match.params
                                                .contentId
                                            }
                                          }
                                        ];
                                      }}
                                    >
                                      {editContent => {
                                        let statusColor;
                                        let isNotPermitted =
                                          (juridical &&
                                            !userPermissions.includes(
                                              "edit-juridical-status"
                                            )) ||
                                          (!juridical &&
                                            !userPermissions.includes(
                                              "edit-content-status"
                                            ));
                                        statusColor = this.setStatusColor(
                                          this.state.status
                                        );
                                        let statusList = [
                                          // TODO: change this to be built from a function based in a config file
                                          {
                                            icon: (
                                              <CircleIcon className="buttonOrange" />
                                            ),
                                            text: <span>Em Andamento</span>,
                                            disabled: isNotPermitted
                                          },
                                          {
                                            icon: (
                                              <CircleIcon className="buttonRed" />
                                            ),
                                            text: <span>Em Revisão</span>,
                                            disabled: isNotPermitted
                                          }
                                        ];
                                        // If there are documents that need to be approved, the
                                        // final version button becomes disabled, in juridical
                                        if (
                                          contentData.content
                                            .finalVersionDependsOn > 0 &&
                                          juridical
                                        ) {
                                          statusList.push({
                                            icon: (
                                              <CircleIcon className="buttonGreen" />
                                            ),
                                            text: <span> Versão Final </span>,
                                            disabled: true
                                          });
                                        } else {
                                          statusList.push({
                                            icon: (
                                              <CircleIcon className="buttonGreen" />
                                            ),
                                            text: <span>Versão Final</span>,
                                            disabled: isNotPermitted
                                          });
                                        }

                                        if (
                                          contentData.content
                                            .finalVersionDependsOn > 0
                                        ) {
                                          return (
                                            <>
                                              {juridical && (
                                                <Tooltip
                                                  id="tooltip-juridical-alert"
                                                  title={`Existem ${
                                                    contentData.content
                                                      .finalVersionDependsOn
                                                  } documentos não aprovados em juridico.`}
                                                  placement="top"
                                                  classes={{
                                                    tooltip:
                                                      classes.tooltip +
                                                      " " +
                                                      classes.customTooltip
                                                  }}
                                                >
                                                  <div>
                                                    <JuridicoIcon color="#ff0000" />
                                                  </div>
                                                </Tooltip>
                                              )}
                                              <CustomDropdown
                                                disabled={
                                                  !userPermissions.includes(
                                                    "edit-content-status"
                                                  )
                                                }
                                                classes={{
                                                  button: "buttonDropDown",
                                                  buttonIcon:
                                                    "circleIcon " + statusColor,
                                                  iconDropddown: "iconDropdDown"
                                                }}
                                                buttonIcon={CircleIcon}
                                                buttonProps={{
                                                  style: { alignSelf: "center" }
                                                }}
                                                hoverColor="danger"
                                                buttonText={this.state.status}
                                                onClick={e => {
                                                  this.changeStatus(
                                                    editContent,
                                                    e.text.props.children,
                                                    refetch
                                                  );
                                                  if (
                                                    e.text.props.children ===
                                                    "Versão Final"
                                                  ) {
                                                    this.setState({
                                                      isCommentingAllowed: false,
                                                      status:
                                                        e.text.props.children
                                                    });
                                                  } else if (
                                                    this.state
                                                      .latestVersionState
                                                  ) {
                                                    this.setState({
                                                      isCommentingAllowed: true,
                                                      status:
                                                        e.text.props.children
                                                    });
                                                  }
                                                }}
                                                dropdownList={statusList}
                                              />
                                            </>
                                          );
                                        }
                                        return (
                                          <CustomDropdown
                                            classes={{
                                              button: "buttonDropDown",
                                              buttonIcon:
                                                "circleIcon " + statusColor,
                                              iconDropddown: "iconDropdDown"
                                            }}
                                            buttonIcon={CircleIcon}
                                            buttonProps={{
                                              style: { alignSelf: "center" }
                                            }}
                                            hoverColor="danger"
                                            buttonText={this.state.status}
                                            onClick={e => {
                                              this.changeStatus(
                                                editContent,
                                                e.text.props.children,
                                                refetch
                                              );
                                              if (
                                                e.text.props.children ===
                                                "Versão Final"
                                              ) {
                                                this.setState({
                                                  isCommentingAllowed: false,
                                                  status: e.text.props.children
                                                });
                                              } else if (
                                                this.state.latestVersionState
                                              ) {
                                                this.setState({
                                                  isCommentingAllowed: true,
                                                  status: e.text.props.children
                                                });
                                              }
                                            }}
                                            dropdownList={statusList}
                                          />
                                        );
                                      }}
                                    </Mutation>
                                  )}
                                <IconButton
                                  aria-label="More"
                                  aria-haspopup="true"
                                  onClick={e => {
                                    if (e) this.setAnchorEl(e.currentTarget);
                                    this.setMoreOpen(true);
                                  }}
                                  classes={{ root: "iconButton" }}
                                >
                                  <div className="moreContainer">
                                    <MoreVertIcon
                                      classes={{ root: "moreIcon" }}
                                    />
                                  </div>
                                </IconButton>
                                <Query
                                  query={GET_VERSION_WITH_LINKS_QUERY}
                                  variables={{
                                    versionId: this.state.currentVersion.id
                                      ? this.state.currentVersion.id
                                      : contentData.content.latestVersion.id
                                  }}
                                >
                                  {({ loading, error, data: versionData }) => {
                                    if (error) {
                                      console.log("Error:", error);

                                      return <h2>error at query:{error}</h2>;
                                    }
                                    if (loading) {
                                      return null; //this should not have loading
                                    }
                                    let videoLinkOptions = [];
                                    if (type === "")
                                      this.setState({
                                        type: versionData.version.content.type
                                      });
                                    if (
                                      versionData.version.reviewLink &&
                                      versionData.version.content.type ===
                                        "vimeo"
                                    )
                                      videoLinkOptions.push({
                                        text: "Gerar Link do Vídeo ",
                                        icon: LinkIcon,
                                        func: () => {
                                          let downloadWindow = window.open(
                                            versionData.version.reviewLink,
                                            "_blank"
                                          );
                                          downloadWindow.focus();
                                        }
                                      });
                                    if (versionData.version.downloadLink)
                                      videoLinkOptions.push({
                                        text: "Download de Conteúdo",
                                        icon: CloudDownload,
                                        func: () => {
                                          let downloadWindow = window.open(
                                            versionData.version.downloadLink,
                                            "_blank"
                                          );
                                          downloadWindow.focus();
                                        }
                                      });
                                    if (
                                      userData &&
                                      userData.role &&
                                      userPermissions.includes(
                                        "edit-content"
                                      ) &&
                                      contentData.content.versions.length > 1 &&
                                      contentData.content.status !==
                                        "Versão Final"
                                    ) {
                                      videoLinkOptions.push({
                                        text: "Deletar Versão",
                                        icon: DeleteIcon,
                                        func: async () => {
                                          await deleteVersion({
                                            id: versionData.version.id,
                                            client: this.props.client,
                                            contentId: this.props.match.params
                                              .contentId,
                                            contentRefetch
                                          });
                                          window.location.reload(); //refetchqueries and  using contentRefetch is not updating the page properly, this is a provisory fix.
                                        }
                                      });
                                    }

                                    return (
                                      <Menu
                                        id="long-menu"
                                        open={moreOpen}
                                        anchorEl={anchorEl}
                                        onClose={() => this.setMoreOpen(false)}
                                        PaperProps={{
                                          style: {
                                            maxHeight: 45 * 4.5,
                                            width: 200,
                                            marginTop: 20,
                                            marginLeft: 10
                                          }
                                        }}
                                      >
                                        <MenuList>
                                          {videoLinkOptions.map(option => {
                                            return (
                                              <MenuItem
                                                onClick={option.func}
                                                key={option.text}
                                                selected={option === "teste1"}
                                              >
                                                <div
                                                  style={{ display: "flex" }}
                                                >
                                                  <div className="optionIcon">
                                                    <option.icon />
                                                  </div>
                                                  <div className="optionText">
                                                    {option.text}
                                                  </div>
                                                </div>
                                              </MenuItem>
                                            );
                                          })}
                                        </MenuList>
                                      </Menu>
                                    );
                                  }}
                                </Query>
                              </ListItem>
                            </List>
                          }
                        />

                        <Query
                          variables={{ versionId: this.state.versionId }}
                          query={GET_VERSION_QUERY}
                          key={contentData.content.versions.length}
                        >
                          {({ loading, error, data }) => {
                            if (loading)
                              return (
                                <div className="responsivePlayerWrapper">
                                  <Loading />
                                </div>
                              );
                            if (error) {
                              console.log(`Error! ${error.message}`);
                              return null;
                            }

                            if (
                              this.state.viewingType !==
                              data.version.content.type
                            )
                              this.setState({
                                viewingType: data.version.content.type
                              });
                            if (
                              data.version &&
                              data.version.content.type === "vimeo"
                            ) {
                              return (
                                <Player
                                  setVimeoPlayer={this.setVimeoPlayer}
                                  contentId={data.version.content.id}
                                  playerTime={this.state.playerTime}
                                  fileKey={data.version.fileKey}
                                  classes={{ title: "playerTit" }}
                                  videoTitle={data.version.content.name}
                                  showCanvas={showCanvas}
                                  commentClick={commentClick}
                                  saveData={
                                    commentClick
                                      ? ogCommentCanvas
                                      : EMPTY_CANVAS
                                  }
                                  getSaveData={data =>
                                    this.setState({ commentCanvas: data })
                                  }
                                  setShowCanvas={showCanvas =>
                                    this.setState({ showCanvas })
                                  }
                                  videoSize={this.state.videoSize}
                                />
                              );
                            } else if (
                              data.version &&
                              data.version.content.type === "onlyoffice"
                            ) {
                              if (versionFileExtension === "pdf") {
                                return (
                                  <PdfReader
                                    setCanvas={(
                                      commentCanvas,
                                      canvasDimensions
                                    ) =>
                                      this.setState({
                                        commentCanvas,
                                        canvasDimensions
                                      })
                                    }
                                    commentClick={commentClick}
                                    canvasDimensions={canvasDimensions}
                                    showCanvas={showCanvas}
                                    fileKey={data.version.fileKey}
                                    fileName={data.version.name}
                                    commentCanvas={ogCommentCanvas}
                                    key={data.version.fileKey}
                                  />
                                );
                              }
                              if (useCleanOnlyOffice) {
                                return (
                                  <CleanOnlyOffice
                                    fileKey={data.version.fileKey}
                                    fileName={data.version.name}
                                    key={data.version.fileKey}
                                  />
                                );
                              }
                              return (
                                <OnlyOffice
                                  setCanvas={(
                                    commentCanvas,
                                    canvasDimensions
                                  ) =>
                                    this.setState({
                                      commentCanvas,
                                      canvasDimensions
                                    })
                                  }
                                  commentClick={commentClick}
                                  canvasDimensions={canvasDimensions}
                                  showCanvas={showCanvas}
                                  fileKey={data.version.fileKey}
                                  fileName={data.version.name}
                                  commentCanvas={ogCommentCanvas}
                                  key={data.version.fileKey}
                                />
                              );
                            } else if (
                              data.version &&
                              data.version.content.type === "image"
                            ) {
                              return (
                                <div
                                  className="imageContent"
                                  style={{ position: "relative" }}
                                >
                                  <img
                                    ref={ref => (this.imgRef = ref)}
                                    alt="fileKey"
                                    className="imageContent"
                                    src={data.version.fileKey}
                                    onLoad={e => {
                                      this.setState({
                                        img: {
                                          x: e.target.width,
                                          y: e.target.height
                                        }
                                      });
                                    }}
                                  />
                                  {showCanvas && (
                                    <div
                                      style={
                                        commentClick
                                          ? { pointerEvents: "none" }
                                          : {}
                                      }
                                    >
                                      {" "}
                                      <CanvasDraw
                                        brushColor="red"
                                        hideGrid={true}
                                        disabled={
                                          commentClick && !isCommentingAllowed
                                        }
                                        immediateLoading
                                        brushRadius={3}
                                        lazyRadius={0}
                                        canvasWidth={this.state.img.x}
                                        canvasHeight={this.state.img.y}
                                        saveData={
                                          commentClick
                                            ? ogCommentCanvas
                                            : EMPTY_CANVAS
                                        }
                                        getSaveData={data =>
                                          this.setState({ commentCanvas: data })
                                        }
                                        style={{
                                          backgroundColor: "transparent",
                                          position: "absolute",
                                          zIndex: 1,
                                          top: 20
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              );
                            } else if (
                              data.version &&
                              data.version.content.type === "audio"
                            ) {
                              return (
                                <AudioPlayer
                                  setAudioPlayer={this.setAudioPlayer}
                                  fileUrl={data.version.fileKey}
                                />
                              );
                            }
                            return <div />;
                          }}
                        </Query>
                      </div>
                      <div
                        classes={{ grid: "gridItemCommentBox" }}
                        style={{ ...NotAllowedCss }}
                        key={`${this.state.isCommentingAllowed}`}
                      >
                        <div
                          style={{
                            ...{
                              display: "flex",
                              justifyContent: "center",
                              padding: "0",
                              paddingBottom: "10px"
                            },
                            ...NotAllowedCss
                          }}
                        >
                          {" "}
                          {/* horizontal flex */}
                          <div className="commentBox">
                            <Query query={myUser}>
                              {({ data }) => {
                                data = data.myUser;

                                return (
                                  <div className="commentAvatar">
                                    <img
                                      src={
                                        data && data.image
                                          ? data.image.imageUrl
                                          : avatar
                                      }
                                      alt=""
                                    />
                                  </div>
                                );
                              }}
                            </Query>
                            <CustomInput
                              disableUnderline={true}
                              labelText={
                                this.state.isCommentingAllowed
                                  ? "Escreva seu comentário aqui"
                                  : ""
                              }
                              id="nice"
                              formControlProps={{
                                fullWidth: true
                              }}
                              classes={{
                                input: "input",
                                labelRoot: "customInputLabel",
                                formControl: "formControlCommentBox"
                              }}
                              inputProps={{
                                disabled: !isCommentingAllowed,
                                multiline: true,
                                rows: 4,
                                type: "text",
                                style: NotAllowedCss,
                                value: this.state.isCommentingAllowed
                                  ? this.state.commentText
                                  : "Alterações não são permitidas em versões antigas ou versão final",
                                onClick: () => {
                                  this.erasePlayerTime();
                                  this.getVimeoTimeStamp();
                                  this.setVimeoTimeStamp();
                                  this.setAudioTimeStamp();
                                },
                                onChange: e => {
                                  this.handleComment(e, now());
                                }
                              }}
                            />
                            <Mutation
                              mutation={
                                juridical
                                  ? ADD_JURIDICAL_COMMENT_MUTATION
                                  : ADD_COMMENT_MUTATION
                              }
                            >
                              {ADD_COMMENT_MUTATION => (
                                <div
                                  className="menuOptions"
                                  style={NotAllowedCss}
                                >
                                  <div className="rowFlex">
                                    <div className="commentTimestampContainer">
                                      {this.state.viewingType === "vimeo" ||
                                      this.state.viewingType === "audio" ? (
                                        <div className="commentTimestamp">
                                          <AccessTime
                                            classes={{ root: "clock" }}
                                          />
                                          <span className="timestamp">
                                            {this.timeCodeConvert(
                                              this.state.timeStamp
                                            )}
                                          </span>
                                        </div>
                                      ) : (
                                        <div style={{ width: "2.5vw" }} />
                                      )}
                                    </div>
                                    <CustomDropdown
                                      style={{
                                        ...NotAllowedCss
                                      }}
                                      onClick={e =>
                                        this.addTag(e.props.children)
                                      }
                                      buttonIcon={LocalOffer}
                                      buttonText="Tags"
                                      dropup
                                      classes={{
                                        button: "buttonDropDown addTagDropdown",
                                        buttonIcon: "buttonDropDownIcon",
                                        caret: "customCaret"
                                      }}
                                      dropdownList={tags}
                                      buttonProps={{
                                        disabled: !isCommentingAllowed
                                      }}
                                    />
                                    <div
                                      style={{
                                        marginTop: "11px",
                                        display: "flex",
                                        flexDirection: "row",
                                        marginBottom: "11px",
                                        marginLeft: "7px"
                                      }}
                                    >
                                      {this.state.commentTags &&
                                        this.state.commentTags.length > 0 &&
                                        this.state.commentTags.map(
                                          (commentTag, index) => (
                                            <div
                                              key={index}
                                              className="tag"
                                              style={{
                                                marginRight: "5px",
                                                cursor: "pointer",
                                                whiteSpace: "nowrap"
                                              }}
                                              onClick={e =>
                                                this.removeTag(
                                                  e.target.textContent
                                                )
                                              }
                                            >
                                              {commentTag}
                                            </div>
                                          )
                                        )}
                                    </div>
                                  </div>
                                  <div className="rowFlex">
                                    {(this.state.fileKeys.length > 0 ||
                                      this.state.AttachedDocumentIds.length >
                                        0) && (
                                      <div className="rowFlex">
                                        <div className="commentTimestampContainer">
                                          <div className="commentTimestamp">
                                            <span
                                              style={{
                                                display: "flex",
                                                color: "#8861FF",
                                                fontFamily:
                                                  "Montserrat, sans-serif !important",
                                                fontWeight: "normal",
                                                textAlign: "center",
                                                marginTop: "1px",
                                                fontSize: "15px"
                                              }}
                                            >
                                              <div
                                                style={{
                                                  marginTop: "0px",
                                                  marginRight: "5px"
                                                }}
                                              >
                                                {this.state.fileNames.length +
                                                  this.state.AttachedDocumentIds
                                                    .length}
                                              </div>
                                              {/* Essa linha serve para determinar se a palavra 'anexo' será singular ou plural */}
                                              anexo
                                              {this.state.fileNames.length +
                                                this.state.AttachedDocumentIds
                                                  .length >
                                                1 && "s"}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    <Paperclip
                                      onClick={() => {
                                        if (
                                          !this.state.attachIsOpen &&
                                          isCommentingAllowed
                                        )
                                          this.setState({ attachIsOpen: true });
                                      }}
                                      style={NotAllowedCss}
                                      classes={{ root: "paperclip" }}
                                      disabled={!this.state.isCommentingAllowed}
                                    />
                                    {!useCleanOnlyOffice && (
                                      <>
                                        {showCanvas && !commentClick ? (
                                          <Close
                                            onClick={() => {
                                              if (this.state.player)
                                                this.state.player.play();
                                              this.setState({
                                                showCanvas: false,
                                                commentClick: false,
                                                ogCommentCanvas: EMPTY_CANVAS
                                              });
                                            }}
                                            classes={{ root: "paperclip" }}
                                          />
                                        ) : (
                                          <Brush
                                            onClick={() => {
                                              if (this.state.player) {
                                                this.erasePlayerTime();
                                                this.getVimeoTimeStamp();
                                                this.setVimeoTimeStamp();
                                                this.state.player.pause();
                                              }
                                              this.setState({
                                                playerTime: 0.0,
                                                showCanvas: true,
                                                commentClick: false,
                                                commentCanvas: EMPTY_CANVAS
                                              });
                                            }}
                                            style={NotAllowedCss}
                                            classes={{ root: "paperclip" }}
                                            disabled={
                                              !this.state.isCommentingAllowed
                                            }
                                          />
                                        )}
                                      </>
                                    )}
                                    <UploadAttachments
                                      classes={classes}
                                      commentId={""}
                                      commentClick={commentClick}
                                      open={this.state.attachIsOpen}
                                      toggleModal={this.toggleAttachUploadModal.bind(
                                        this
                                      )}
                                      setFilesData={this.setFilesData.bind(
                                        this
                                      )}
                                      setAttachedDocumentIds={this.setAttachedDocumentIds.bind(
                                        this
                                      )}
                                      projectId={
                                        this.state.projectId
                                          ? this.state.projectId
                                          : ""
                                      }
                                      disabled={!this.state.isCommentingAllowed}
                                    />
                                    <Button
                                      style={NotAllowedCss}
                                      color="primary"
                                      id="myInput"
                                      classes={{ button: "buttonSend" }}
                                      onClick={e => {
                                        e.stopPropagation();
                                        if (
                                          isCommentingAllowed &&
                                          (this.state.commentText.length > 0 ||
                                            (this.state.fileKeys.length > 0 ||
                                              this.state.AttachedDocumentIds
                                                .length > 0))
                                        ) {
                                          this.submitComment(
                                            ADD_COMMENT_MUTATION,
                                            refetch
                                          );
                                        }
                                        this.setState({ showCanvas: false });
                                      }}
                                      disabled={!this.state.isCommentingAllowed}
                                    >
                                      Enviar
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </Mutation>
                          </div>
                        </div>
                      </div>
                    </GridItem>
                    <GridItem
                      classes={{ grid: "sectionCommentsGrid" }}
                      style={{
                        padding: "0 !important",
                        backgroundColor: "#20222b",
                        height: "100%"
                      }}
                      xs={12}
                      sm={12}
                      md={4}
                      lg={3}
                      xl={3}
                    >
                      {this.state.versionId && (
                        <SectionComments
                          tags={tags ? tags : []}
                          juridical={juridical}
                          content={contentData.content}
                          contentStatus={contentStatus ? contentStatus : ""}
                          toggleCommentsAttachmentOpen={commentsAttachmentOpen =>
                            this.setState({
                              commentsAttachmentOpen: commentsAttachmentOpen
                            })
                          }
                          history={this.props.history}
                          shouldRefetch={this.state.shouldRefetch}
                          setCommentRefetch={this.setCommentRefetch}
                          setPlaybackTime={this.timeStampRedirect}
                          versionId={this.state.versionId}
                          setVimeoTimeStamp={this.timeStampRedirect}
                          projectId={
                            this.state.projectId ? this.state.projectId : ""
                          }
                          setCommentCanvas={(canvasDraw, canvasDimensions) =>
                            this.setCommentCanvas(canvasDraw, canvasDimensions)
                          }
                        />
                      )}
                    </GridItem>
                  </GridContainer>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

let EditViewWithStyles = withStyles(regularFormsStyle)(EditView);

export default withApollo(EditViewWithStyles);
