import React from "react";
// used for making the prop types of this component
import { withStyles } from "@material-ui/core";
import { withApollo } from "react-apollo";
import { REGISTER_USER_MUTATION } from "./registrationQueries.js";
import axios from "axios";

import jwt from "jsonwebtoken";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import userRegistrationStyle from "./userRegistrationStyle.js";
import wizardStyles from "assets/jss/material-dashboard-pro-react/components/wizardStyle";
import Step1 from "views/Register/WizardSteps/Step1.jsx";
import Step2 from "views/Register/WizardSteps/Step2.jsx";

import submitImage from "utils/uploadImage.js";
import Logo from "../../shared/Logo";
import { Redirect } from "react-router-dom";
import { hashPassword } from "utils/hashPassword.js";

import InvalidTokenPage from "../ErrorPages/InvalidTokenPage.jsx";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      password: "",
      email: "",
      passConfirm: "",
      orgName: ""
    };
  }

  async emailVerifier(email) {
    //this verification can breakdown if the api is down and should not be used or
    //used only after checking a known good email first
    const verified = await axios({
      url: `https://api.trumail.io/v2/lookups/json?email=${email}`, //email verification open api
      method: "GET"
    }).then((response, error) => {
      if (error) console.log(error);
      return response.data;
    });

    if (
      verified.deliverable &&
      !verified.disposable &&
      verified.hostExists &&
      verified.validFormat
    ) {
      this.setState({ email });
    }
  }

  async addUser() {
    const { token } = this.props.match.params;
    if (
      this.state.name &&
      this.state.passwordConfirm &&
      this.state.password &&
      this.state.email
    )
      await this.props.client.mutate({
        mutation: REGISTER_USER_MUTATION,
        context: {
          headers: { authorization: `Bearer ${token}`, register: true }
        },
        variables: {
          name: this.state.name,
          password: this.state.passwordConfirm,
          email: this.state.email
        }
      });
  }

  tokenVerification(token) {
    var decoded = jwt.decode(token);
    // exp is in seconds, while Date.now() returns in milliseconds
    // thus we need to divite Date.now() by 1000
    return decoded.exp > Date.now() / 1000;
  }

  async handleFinish(wizardData) {
    try {
      const { token } = this.props.match.params;
      let { salt, password } = await hashPassword(wizardData.Cadastro.password);
      let imageId;
      if (wizardData.Informações.file)
        ({ imageId } = await submitImage(wizardData.Informações.file, {
          headers: { newUserToken: `${token}`, register: true }
        }));

      let addUserVariables = {
        name:
          wizardData.Informações.name + " " + wizardData.Informações.surname,
        phoneNumber: String(wizardData.Informações.phone),
        cellphoneNumber: String(wizardData.Informações.cellphone),
        email: wizardData.Cadastro.email,
        password,
        passwordSalt: salt,
        imageId: imageId
      };

      await this.props.client.mutate({
        mutation: REGISTER_USER_MUTATION,
        context: { headers: { newUserToken: `${token}`, register: true } },
        variables: addUserVariables
      });
      this.setState({ isRedirected: "/login" });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { classes } = this.props;
    const { isRedirected } = this.state;
    const { token } = this.props.match.params;
    if (!this.tokenVerification(token)) {
      return <InvalidTokenPage classes={classes} />;
    }
    return (
      <div className={classes.wizzardContainer}>
        {isRedirected && <Redirect to={isRedirected} />}
        <div className={classes.logo}>
          <Logo /> <span> manager </span>
        </div>
        <Wizard
          validate
          classes={{
            wizardHeader: classes.wizardHeader,
            wizardContainer: classes.wizardContainer + " " + classes.root,
            content: classes.wizardContent,
            card: classes.wizardCard,
            footer: classes.wizardFooter,
            left: classes.wizardLeft,
            right: classes.wizardRight
          }}
          steps={[
            { stepName: "Cadastro", stepComponent: Step1, stepId: "Cadastro" },
            {
              stepName: "Informações",
              stepComponent: Step2,
              stepId: "Informações"
            }
          ]}
          title="Luz, câmera, ação!"
          subtitle="Preencha o seu cadastro para começar a usar a TVManager"
          finishButtonClick={e => {
            this.handleFinish(e);
          }}
          nextButtonText="Próximo"
          finishButtonText="Concluir Cadastro"
        />
      </div>
    );
  }
}

let RegisterWithStyles = withStyles({
  ...wizardStyles,
  ...userRegistrationStyle
})(Register);
export default withApollo(RegisterWithStyles);
