import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

// material-ui icons
import {Menu, Today} from "@material-ui/icons";

// core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import Button from "components/CustomButtons/Button.jsx";
import classnames from 'classnames';
import adminNavbarStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.jsx";
import { Query } from "react-apollo";
import { GET_PROJECTS_QUERY } from "views/Project/ProjectsQueries";
import { Redirect } from "react-router-dom"
import { ContextConsumer } from "ContextController.js"

function AdminNavbar({ ...props }) {
  const [isRedirected, setRedirect ] = useState("");
  const { classes, color, rtlActive } = props;
  const currentProjectId = window.location.href.includes('project/') ? window.location.href.split('project/')[1].split('/')[0] : undefined;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive
    });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div>
          <Hidden smDown implementation="css">
            <div className={sidebarMinimize}>
              <Button
                justIcon
                color="white"
                onClick={props.sidebarMinimize}
                classes={{root: classes.hamburgerIcon}}
              >
                <Menu className={classes.sidebarMiniIcon} />
              </Button>
            </div>
          </Hidden>
        </div>
        <div className={classnames(classes.flex, classes.titleWrapper)}>
          {/* Here we create navbar brand, based on route name */}
          <Query query={GET_PROJECTS_QUERY}>
            {(({data, loading}) => {
                const dropDownListData =( !loading && data) ? data.projects.map((data) => ({
                  name: data.name, 
                  id: data.id,
                  obj: true, 
                })) : []
                const currentProject = data && data.projects ? data.projects.filter(project => project.id === currentProjectId)[0] : {};
                return (
                  <>
                  {isRedirected  && <Redirect  to={isRedirected} ></Redirect>}
                  <ContextConsumer>
                    {
                        context => (
                          <CustomDropdown
                            buttonText={currentProject ? currentProject.name : context.getUser().organization.name}
                            buttonProps={{size: 'sm'}}
                            classes={{button: classes.titleNavBar, dropdown: classes.dropdown, dropdownItem: classes.dropdownItem}}
                            dropdownList={dropDownListData}
                            onClick= {({id}) => setRedirect(`/project/${id}/contents/0/0`)}
                          />
                        )
                    }
                    </ContextConsumer>
                  {currentProject && <div className={classes.channelName}>
                   <Today/> 
                   <div>{currentProject.startDate} - {currentProject.endDate} </div>
                  </div>}
                </>
               )
              })}
            </Query>
        </div>
        {/* <Hidden smDown implementation="css"> */}
          <AdminNavbarLinks rtlActive={rtlActive} />
        {/* </Hidden> */}
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="white"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  barTitle: PropTypes.string
};

export default withStyles(adminNavbarStyle)(AdminNavbar);
