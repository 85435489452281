import React, { Component } from "react";
import { graphql } from "react-apollo";
import { getTargetVersionFile } from "queries/queries.js";
import Player from "@vimeo/player";
import propTypes from "prop-types";
import CanvasDraw, { EMPTY_CANVAS } from "components/CanvasDraw/index.jsx";

import "views/Vimeo/vimeoPlayerCss.css";

class VPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projectId: "",
      data: [],
      vimeoURL:
        "https://player.vimeo.com/video/" + props.fileKey + "?autoplay=1",
      pageLoad: false,
      player: null,
      moreOpen: false,
      anchorEl: null,
      downloadLink: "",
      reviewLink: "",
      videoSize: {}
    };
  }

  player = async () => {
    //initialize vimeo player sdk
    const iframe = await document.getElementById("vimeoPlayerId");
    await this.setState({ player: await new Player(iframe) });
  };

  async componentDidMount() {
    const { setShowCanvas } = this.props;
    if (!this.state.player) {
      await this.player();
      this.state.player.play();
    }
    this.props.setVimeoPlayer(this.state.player);
    this.state.player.on("play", function() {
      setShowCanvas(false);
    });
  }

  componentDidUpdate(prevState, prevProps) {
    let playbackTime = this.props.playerTime;
    if (
      playbackTime &&
      this.state.player &&
      prevProps.showCanvas === this.props.showCanvas
    ) {
      this.state.player.pause();
      this.state.player.setCurrentTime(playbackTime);
    }
  }

  componentWillReceiveProps(props) {
    if (props && props.fileKey && this.props !== props) {
      this.setState({
        vimeoURL:
          "https://player.vimeo.com/video/" + props.fileKey + "?autoplay=1"
      });
    }
    if (
      props.videoSize !== {} &&
      this.props.videoSize !== {} &&
      props.videoSize.x !== this.props.videoSize.x
    ) {
      this.setState({ videoSize: props.videoSize });
    }
  }

  setMoreOpen = newState => this.setState({ moreOpen: newState });
  setAnchorEl = newState => this.setState({ anchorEl: newState });

  render() {
    const { videoSize } = this.state;
    const { showCanvas, commentClick } = this.props;
    const that = this; //give context to promises
    return (
      <div style={{ height: "60 vh" }}>
        <style scoped />
        <div className="responsivePlayerWrapper">
          <iframe
            id="vimeoPlayerId"
            className="vimeoPlayer"
            src={this.state.vimeoURL}
            frameBorder="0"
            allowFullScreen
            allow="autoplay; fullscreen"
            title="embededPlayer"
            onLoad={() => {
              const { player } = this.state;
              const videoWidth = document.getElementById("vimeoPlayerId")
                .clientWidth;
              Promise.all([
                player.getVideoWidth(),
                player.getVideoHeight()
              ]).then(function(dimensions) {
                var width = dimensions[0];
                var height = dimensions[1];
                const ratio = width / height;
                that.setState({
                  videoSize: {
                    x: videoWidth,
                    y: videoWidth / ratio,
                    ratio: ratio
                  }
                });
              });
            }}
          />
          {showCanvas && (
            <div
              className="canvasWrapper"
              style={{ pointerEvents: commentClick ? "none" : "" }}
            >
              <CanvasDraw
                brushColor="red"
                hideGrid={true}
                disabled={this.props.commentClick}
                immediateLoading={true}
                brushRadius={3}
                lazyRadius={0}
                canvasWidth={videoSize.x}
                canvasHeight={videoSize.y}
                saveData={
                  this.props.saveData ? this.props.saveData : EMPTY_CANVAS
                }
                getSaveData={data => this.props.getSaveData(data)}
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  zIndex: 1,
                  pointerEvents: commentClick ? "none" : ""
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

VPlayer.propTypes = {
  shouldGetTimeStamp: propTypes.bool,
  videoTitle: propTypes.string
};
VPlayer.defaultProps = {
  shouldGetTimeStamp: false,
  videoTitle: "Título"
};

export default graphql(getTargetVersionFile)(VPlayer);
