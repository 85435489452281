export default {
  root: {
    
  },
  expandableWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    padding: '10px 20px',
  },
  expandable: {
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'flex-start',
   padding: '10px 30px',
   boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
   borderRadius: '6px',
   maxHeight: '39px',
   transition: '1s all',
   width: '100%',
   transitionTimingFunction: 'ease-out', 
  },
  expandableOpen: {
    maxHeight: '600px',
    height: 'auto'
  },
  expandableText: {
    fontWeight: 'normal',
    color: '#6F6F6F',
    '& span': {
      fontWeight: 'bold'
    }
  },
  topLine: {
    height: '1px',	
    width: '90%',	
    backgroundColor: '#DDDDDD',
    margin: '12px 10px'
  },
  expandableHeader: {
    width: '100%',
    margin: '10 auto',
    display: 'flex',
    marginBottom: '25px',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '& div': {
      fontWeight: 'bold',
      fontSize: '14px'
    },
    '& svg': {
      cursor: 'pointer'
    }
  },
}