import React, { useRef, useCallback } from 'react'
import { withStyles } from '@material-ui/core';
import DropzoneStyle from './DropzoneStyle';
import { useDropzone } from "react-dropzone"
import Button from "components/CustomButtons/Button.jsx";
const Dropzone =  ({classes, handleChange, onDragLeave, onDragOver}) => {
  const onDrop = useCallback(acceptedFiles => {
    
    // Do something with the files
  }, [])
  
  const {getRootProps, getInputProps} = useDropzone({onDrop})

  const fileSelector = useRef(null);
  return (
  <div 
    {...getRootProps()}
    onClick={() => fileSelector.current.click()}
    onDrop={ (e) => {
      e.stopPropagation();
      e.preventDefault();
      onDrop(e.dataTransfer.files);
      let eventProxy = { // this is here because the handlechangee function expects the following data structure
        target:{
          files:e.dataTransfer.files
        }
      }
      handleChange(eventProxy);
      return false;
    }}
    onDragOver={(e) => {
      e.preventDefault();
      onDragOver(e);
      
    }}
    onDragLeave={() => onDragLeave()} 
    className={classes.root} 
  >
      <input 
        {...getInputProps()}
        ref={fileSelector} 
        className={classes.input} 
        multiple
        onChange={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleChange(e);
          return false;
        }} 
        type="file"
      />
      <div className={classes.body}>
        Arraste os arquivos para cá <br/> 
         - ou - <br/>
           <Button
              color='lightPurple'
              style={{pointerEvents: 'none'}}
              onClick={(e) => {
                e.stopPropagation()
                fileSelector.current.click()
              }}
            > 
              Selecione Arquivos no Computador 
            </Button>
      </div>
    </div>
  )
}

export default withStyles(DropzoneStyle)(Dropzone);