import React from 'react';
import Popper from '@material-ui/core/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@material-ui/core/Fade';
import { ClickAwayListener} from '@material-ui/core';


export default function CustomPopper(props){  
  return (
    <PopupState  variant="popper" popupId={props.key}>
      {(popupState) => {
        const closeOnClickAway = () => setTimeout(() => popupState.close(), 200); 
        return(
        <div onClick={(e)=> e.stopPropagation()} style={{width:"fit-content"}}>  
          <div {...bindToggle(popupState)} style={{width:"fit-content"}}><props.buttonComponent/></div>
          <Popper 
            { ...bindPopper(popupState) }
            transition
            disablePortal={props.disablePortal}
            modifiers={{
              flip: {
                enabled: true,
              },
              preventOverflow: {
                enabled: props.coverParent ? true : false,
                boundariesElement: 'scrollParent',
              },
            }}
            style={{zIndex:9999999}}
            >
            {({ TransitionProps }) => {
              if(props.enableClickAway)
                return(
                  <ClickAwayListener onClickAway={closeOnClickAway} >
                    <Fade {...TransitionProps} timeout={350}>
                      {props.children}
                    </Fade>
                  </ClickAwayListener>)
              if(!props.enableClickAway)
                return(
                  <Fade {...TransitionProps} timeout={350}>
                    {props.children}
                  </Fade>
                )
            }}
          </Popper>
        </div>
      )}}
    </PopupState>
  );
}