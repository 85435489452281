import { gql } from "apollo-boost";

const LOGS_QUERY = gql`
  query LOGS_QUERY($offset: Int, $limit: Int, $projectId: ID) {
    logs(offset: $offset, limit: $limit, projectId: $projectId) {
      id
      text
      targetUser {
        id
        name
        image {
          id
          imageUrl
        }
      }
      type
      creationDate
      project {
        id
        name
        image {
          id
          imageUrl
        }
      }
      agent {
        id
        name
        image {
          id
          imageUrl
        }
      }
      content {
        id
        name
        folder {
          id
          parentFolder {
            id
          }
        }
        juridicalFolder {
          id
          parentFolder {
            id
          }
        }
      }
      comment {
        id
        text
      }
    }
  }
`;

const LOGS_SUBSCRIPTION = gql`
  subscription LOGS_SUBSCRIPTION($projectId: ID!) {
    newLog(projectId: $projectId) {
      id
      text
      targetUser {
        id
        name
        image {
          id
          imageUrl
        }
      }
      type
      creationDate
      project {
        id
        name
        image {
          id
          imageUrl
        }
      }
      agent {
        id
        name
        image {
          id
          imageUrl
        }
      }
      content {
        id
        name
        folder {
          id
          parentFolder {
            id
          }
        }
        juridicalFolder {
          id
          parentFolder {
            id
          }
        }
      }
      comment {
        id
        text
      }
    }
  }
`;

export { LOGS_SUBSCRIPTION, LOGS_QUERY };
