import React, { useState } from "react";
import { withStyles, Tooltip } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { AddCircle, InfoOutlined } from "@material-ui/icons";

import UsersAndPermissionsStyle from "../UsersAndPermissionsStyle.js";
import Avatar from "components/Avatar/Avatar.jsx";
import NewOrgInviteModal from "components/Organization/NewOrgInviteModal/NewOrgInviteModal.jsx";
import { withApollo } from "react-apollo";

const OrgsTable = ({
  classes,
  partnerOrgs,
  orgName,
  orgMembers,
  successAlert,
  myOrgImg,
  myOrgId
}) => {
  const [newOrgModalOpen, setNewOrgModalOpen] = useState(false);
  partnerOrgs.map(org => {
    return { name: org.name, img: "", numbers: "33" }; // numbers:org.members.length numbers should hold the number of users from given org collaborating with myOrg
  });

  return (
    <Card style={{ maxWidth: "255px", marginRight: "15px" }}>
      {newOrgModalOpen && (
        <NewOrgInviteModal
          classes={classes}
          open={newOrgModalOpen}
          onClose={() => {
            setNewOrgModalOpen(!newOrgModalOpen);
          }}
          modalName="Convidar Organização"
          successAlert={successAlert}
        />
      )}
      <GridItem className={classes.headersOrgTable} xs={12}>
        <Button
          onClick={() => {
            setNewOrgModalOpen(true);
          }}
          classes={{ root: classes.uploadContent }}
          color="primary"
        >
          <span>
            <AddCircle />
          </span>
          Convidar Organização
        </Button>
      </GridItem>
      <GridItem style={{ padding: 0 }} xs={12}>
        <div className={classes.orgWrapper}>
          <div className={classes.mainOrg}>
            <Avatar img={myOrgImg} orgId={myOrgId} />
            <span> {orgName} </span>
            <div className={classes.numbers}> ( {orgMembers} ) </div>
          </div>
          <div style={{ marginLeft: "5px" }} className={classes.subtitle}>
            Organizações Parceiras
            <Tooltip
              id="tooltip-right"
              title="Organizações que colaboram com você em projetos em comum."
              placement="right"
              classes={{ tooltip: classes.tooltip }}
            >
              <InfoOutlined />
            </Tooltip>
          </div>
          {partnerOrgs.map((org, index) => (
            <div className={classes.org} key={index}>
              <Avatar img={org.img} orgId={org.id} />
              <Tooltip
                title={org.name}
                enterDelay={300}
                classes={{ tooltip: classes.tooltip }}
              >
                <span className={classes.collabOrgName}> {org.name} </span>
              </Tooltip>
              {/* <div className={classes.numbers}> ( {org.numbers} ) </div> */}
            </div>
          ))}
        </div>
      </GridItem>
    </Card>
  );
};

export default withApollo(withStyles(UsersAndPermissionsStyle)(OrgsTable));
