import React, { Component } from 'react';
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import contentStyle from './contentStyle';
import animateScrollTo from 'animated-scroll-to';
import "views/Content/OnlyOfficeCss.css"

class OnlyOffice extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            markers: [],
        };
        this.scriptLoaded = this.scriptLoaded.bind(this);
        this.el = React.createRef();
    }

    setOnlyofficeType( extension ) {
        let text = [ "doc", "docm", "docx", "dot", "dotm", "dotx", "epub", "fodt", "htm", "html", "mht", "odt", "ott", "pdf", "rtf", "txt", "djvu", "xps"];
        let spreadsheet = [ "csv", "fods", "ods", "ots", "xls", "xlsm", "xlsx", "xlt", "xltm", "xltx" ];
        let presentation = ["fodp", "odp", "otp", "pot", "potm", "potx", "pps", "ppsm", "ppsx", "ppt", "pptm", "pptx" ];
        if( presentation.includes(extension) )
            return "presentation";
        if ( spreadsheet.includes(extension) )
            return "spreadsheet";
        if ( text.includes(extension) )
            return "text";
        throw new Error(`Unsupported Onlyoffice format: ${extension}`);
    }

    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://doc.server.tvmanager.com.br/web-apps/apps/api/documents/api.js";
        script.async = true;
        script.onload = async () => await this.scriptLoaded();  
        document.body.appendChild(script);
        
    }


    scriptLoaded() {
        let fileExtension = this.props.fileName.split('.').pop();
        let onlyofficeType = this.setOnlyofficeType( fileExtension );
        new window.DocsAPI.DocEditor("placeholder", {
            "document": {
                "fileType": fileExtension,
                "key": (Math.random()*999999).toString(),
                "title": this.props.fileName,
                "url": this.props.fileKey
            },
            "documentType": onlyofficeType,
            "editorConfig": {
                "mode": this.props.OnlyOfficeMode ? this.props.OnlyOfficeMode : "view",
                "customization": {
                    "chat": false,
                    "comments": false,
                    "help": false,
                    "zoom": -2
                }
            },
            "type":this.props.OnlyOfficeType ? this.props.OnlyOfficeType : "desktop"
        });
    }

    setPointer = (e) => {
        const { setCanvas, commentClick } = this.props;
        if( !setCanvas ) return;
        if (commentClick) return;
        const bounding = this.el.getBoundingClientRect();
        let currentCanvasDimensions = { width: bounding.width, height: bounding.height };
        const newMarker = {x: e.clientX + (-1*bounding.left) - 15, y: e.clientY + (-1*bounding.top) - 15};

        this.setState({markers:  newMarker});
        setCanvas(newMarker , currentCanvasDimensions);
    }   
  
    getMarkers = () => {
        const bounding = this.el.getBoundingClientRect();

        const { classes, commentCanvas, commentClick, canvasDimensions } = this.props;        
        const { markers } = this.state;
        let ogCanvasHeight = canvasDimensions ? canvasDimensions.height : bounding.height;
        let ogCanvasWidth = canvasDimensions ? canvasDimensions.width : bounding.width;
        let xRatio = (bounding.width / ogCanvasWidth);
        let yRatio = (bounding.height / ogCanvasHeight);

        if (!commentClick && Object.keys(markers).length > 0) return <div className={classes.ballMarker} style={{top: markers.y*yRatio, left: markers.x*xRatio}}/>
        if (commentCanvas && commentCanvas.x && commentClick) {
            if (Object.keys(markers).length === 0 && markers.constructor === Object) 
                this.setState({markers: {}}); // if obj is empty
            return <div className={classes.ballMarker} style={{top: commentCanvas.y*yRatio, left: commentCanvas.x*xRatio}}/>
        }
    }
    componentDidUpdate (prevProps, prevState) {
        const { commentCanvas, showCanvas } = this.props;
        if (showCanvas !== prevProps.showCanvas) this.setState({markers:  []});
        if (prevProps.commentCanvas !== commentCanvas) {
            const scrollTo = commentCanvas ? commentCanvas.y : 0;
            const options = {
                elementToScroll: document.querySelector('#marker'),
                maxDuration: 1000,
                minDuration: 350,
                speed: 1000,
                verticalOffset: scrollTo === 0 ? 0 : -200,
            };
            setTimeout( () => animateScrollTo(scrollTo, options), 500)
        }
    }

    render() {
        const { classes, style, showCanvas } = this.props;
        return(
            <div style={{height: '93%', maxWidth:"", ...style}}>
                <style scoped>
                </style>
                <div id="marker" style={{overflow: 'auto !important'}} className="responsivePlayerWrapper onlyofficeWrapper">
                    {showCanvas && this.getMarkers()}
                    <div  ref={(el) => this.el = el}  className={classes.markerWrapper} style={{height: "25000px"}}>
                        <div onClick={this.setPointer} id="mark" className={classes.markerCanvas}/>
                        <div id="placeholder" className={classes.placeholder}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles({...contentStyle(), ...regularFormsStyle})(OnlyOffice);
        