import { gql } from "apollo-boost";
import { GET_GROUPS_QUERY } from 'views/Team/TeamQueries.js';

// const GET_ORGANIZATIONS_QUERY = gql `
//   query GET_ORGANIZATIONS_QUERY {
//     organizations {
//       name
//       id
//     }
//   }
// `;

const MEMBERS_INFO_FRAG = gql `
  fragment membersFrag on User {
    id
    name
    organization {
      name
      id
    }
    role {
      id
      name
    }
    image {
      id
      imageUrl
    }
  }
`;



const ADD_COLLAB_MUTATION = gql`
  mutation ADD_COLLAB_MUTATION($id: ID!
      $organizationId: ID!
      $roleId: ID!
      $membersIds: [ID]){
    addCollaboration(
      id:$id
      organizationId:$organizationId
      roleId:$roleId
      membersIds:$membersIds
    ){
      id
      name
      collaborativeOrgs{
        organization{
          id
          name
        }
      }  
    }
  }
`;

const SEND_NEW_ORG_EMAIL_MUTATION = gql`
  mutation SEND_NEW_ORG_EMAIL_MUTATION(
      $destination: String!
      $rolesIds: [ID]
      $projectsIds: [ID]){
    sendNewOrganizationEmail(
      destination:$destination
      rolesIds:$rolesIds
      projectsIds:$projectsIds
    )
  }
`;

const GROUPS_WITH_MEMBERS_FRAG = gql`
${MEMBERS_INFO_FRAG}
  fragment groupsMembersFrag on Group {
    id
    name
    members {
      ...membersFrag
    }
  }
`;

const GET_ORGANIZATIONS_WITH_MEMBERS_QUERY = gql`
${MEMBERS_INFO_FRAG}
  query GET_ORGANIZATIONS_WITH_MEMBERS_QUERY{
    organizations {
      id
      name
      members {
        ...membersFrag
      }
      roles {
        id
        name
      }
    }
  }
`;

const GET_MY_ORGANIZATION_QUERY = gql`
  ${MEMBERS_INFO_FRAG}
  query GET_MY_ORGANIZATION_QUERY {
    myOrganization {
      id
      name
      members {
        ...membersFrag
      }
      roles{
        id
        name
      }
    }
  }
`;

const NEW_GROUP_MUTATION = gql`
${GROUPS_WITH_MEMBERS_FRAG}
  mutation NEW_GROUP_MUTATION($name: String!, $projectId: ID!, $membersIds: [ID]){
    addGroup(name:$name, projectId:$projectId, membersIds: $membersIds) {
      ...groupsMembersFrag
    }
  }
`;
const EDIT_GROUP_MUTATION = gql`
${GROUPS_WITH_MEMBERS_FRAG}
  mutation NEW_GROUP_MUTATION($name: String!, $id: ID!, $membersIds: [ID]){
    editGroup(name:$name, id:$id, membersIds: $membersIds) {
      ...groupsMembersFrag
    }
  }
`;



const createNewGroup =  ({params, client}) => {
  client.mutate({
    mutation: NEW_GROUP_MUTATION,
    variables: { name: params.name, membersIds: params.membersIds, projectId: params.projectId },
    refetchQueries: () => [{
      query: GET_GROUPS_QUERY,
      variables: { id: params.projectId }
    }]
  })
}



const editGroup =  ({params, client}) => {
  client.mutate({
    mutation: EDIT_GROUP_MUTATION,
    variables: { name: params.name, membersIds: params.membersIds, id: params.id },
    refetchQueries: () => [{
      query: GET_GROUPS_QUERY,
      variables: { id: params.projectId }
    }]
  })
}


export {
  SEND_NEW_ORG_EMAIL_MUTATION,
  GET_ORGANIZATIONS_WITH_MEMBERS_QUERY,
  ADD_COLLAB_MUTATION,
  NEW_GROUP_MUTATION,
  GET_MY_ORGANIZATION_QUERY,
  createNewGroup,
  editGroup,
}