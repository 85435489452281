import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import submitImage from "utils/uploadImage";
import defaultUserRegistrationAvatar from "assets/img/defaultUserRegistrationAvatar.png";
import ProfileStyle from "./ProfileStyle.js";

import { Query, withApollo } from "react-apollo";
import {
  GET_USER_DATA_QUERY,
  editUsermutation,
  GET_ANY_USER_DATA_QUERY
} from "./ProfileQueries";
import ProjectCard from "../../components/ProjectCard/ProjectCard.jsx";
import { Edit, Save } from "@material-ui/icons";
import Loading from "components/Loading/Loading.jsx";
import { ContextConsumer } from "ContextController.js";
import { Redirect } from "react-router-dom";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editingDataOnUserInfo: false,
      editingDataOnOrgInfo: false,
      isRedirected: false
    };
  }

  setDefaultValues({ user }) {
    this.setState({
      userPhone: user.phoneNumber
        ? user.phoneNumber
        : `Nenhum número cadastrado`,
      userEmail: user.email,
      userCellphone: user.cellphoneNumber
        ? user.cellphoneNumber
        : `Nenhum número cadastrado`,
      userName: user.name
    });
  }

  async updateFile(file, userRefetch) {
    const { id } = this.state;
    const { client } = this.props;
    const { imageId } = file ? await submitImage(file) : {};
    editUsermutation({ params: { id, imageId }, client, userRefetch });
  }

  render() {
    const { classes, client } = this.props;
    const {
      id,
      userEmail,
      isRedirected,
      userName,
      userPhone,
      userCellphone,
      editingDataOnUserInfo
    } = this.state;

    const profileId = this.props.match.params.userProfileId;
    let QUERY_IN_USE;
    let queryVariables;
    if (profileId) {
      QUERY_IN_USE = GET_ANY_USER_DATA_QUERY;
      queryVariables = { id: profileId };
    } else {
      QUERY_IN_USE = GET_USER_DATA_QUERY;
      queryVariables = {};
    }

    return (
      <ContextConsumer>
        {context => {
          let _loggedUser = context.getUser();
          //this checks my user at the global state and if the profile being accessed is mine, then redirects to
          //a editable version
          if (
            _loggedUser.id === profileId &&
            isRedirected === false &&
            profileId
          )
            this.setState({ isRedirected: "/admin/perfil" });

          return (
            <Query query={QUERY_IN_USE} variables={queryVariables}>
              {({ error, loading, data }) => {
                let user;
                let projects;

                if (loading) {
                  return <Loading />;
                }

                if (error) {
                  return <div>Error fetching user data: ${error}</div>;
                }

                user = data.myUser ? data.myUser : data.user;
                projects = user.organization.projects.filter(project => {
                  return project.members.some(
                    member => member && member.id === user.id
                  );
                });
                if (!userName || !userEmail || !userPhone || !userCellphone)
                  this.setDefaultValues({ user });
                return (
                  <div>
                    <GridContainer className={classes.container} xs={12}>
                      {isRedirected && <Redirect to={isRedirected} />}
                      <GridItem xs={12} sm={12} md={9}>
                        <Card
                          profile
                          style={!profileId ? { paddingTop: 105 } : {}}
                        >
                          {profileId && (
                            <CardAvatar profile>
                              <span onClick={e => e.preventDefault()}>
                                <img
                                  src={user.image ? user.image.imageUrl : ""}
                                  alt=""
                                />
                              </span>
                            </CardAvatar>
                          )}
                          {!profileId && (
                            <ImageUpload
                              updateFile={file =>
                                this.updateFile(file, context.userRefetch)
                              }
                              instantUpload
                              classes={{
                                imgUpload: classes.imgUpload,
                                buttonWrapper: classes.buttonWrapperUpload,
                                fileInput: classes.fileInput
                              }}
                              defaultImg={
                                user.image
                                  ? user.image.imageUrl
                                  : defaultUserRegistrationAvatar
                              }
                              addPhotoButtonText="Alterar Imagem"
                              addButtonProps={{
                                color: "purple",
                                classes: {
                                  root:
                                    classes.imageUploadButton +
                                    " imgUploadButton"
                                }
                              }}
                              avatar
                            />
                          )}
                          {editingDataOnUserInfo ? (
                            <div style={{ width: "100%", marginTop: 20 }}>
                              <CustomInput
                                classes={{ labelRoot: classes.label }}
                                id="user-name"
                                inputProps={{
                                  value: this.state.userName,
                                  disabled: !editingDataOnUserInfo,
                                  onChange: e => {
                                    this.setState({ userName: e.target.value });
                                  }
                                }}
                              />
                            </div>
                          ) : (
                            <h1
                              style={
                                !profileId
                                  ? { marginTop: 40, marginLeft: "-24px" }
                                  : {}
                              }
                            >
                              {" "}
                              {user.name}{" "}
                            </h1>
                          )}
                          <h4 style={{ marginLeft: "-24px" }}>
                            {user.role.name}
                          </h4>
                          {!editingDataOnUserInfo && !profileId && (
                            <Button
                              justIcon
                              className={classes.icon}
                              onClick={() =>
                                this.setState({ editingDataOnUserInfo: true })
                              }
                            >
                              <Edit fontSize="large" style={{ fontSize: 40 }} />
                            </Button>
                          )}
                          {editingDataOnUserInfo && !profileId && (
                            <Button
                              className={classes.icon}
                              onClick={() => {
                                this.setState({
                                  editingDataOnUserInfo: !this.state
                                    .editingDataOnUserInfo
                                });
                                editUsermutation({
                                  params: {
                                    id,
                                    userName,
                                    userEmail,
                                    userPhone,
                                    userCellphone
                                  },
                                  client,
                                  userRefetch: context.userRefetch
                                });
                              }}
                            >
                              <Save />
                            </Button>
                          )}
                        </Card>
                        <GridContainer xs={12} className={classes.infoWrapper}>
                          <GridItem xs={12} lg={6} className={classes.info}>
                            <Card profile>
                              <CardBody profile>
                                <GridContainer>
                                  {/* {
                                      !editingDataOnUserInfo && !profileId && 
                                      <Button className={classes.icon} onClick={() => this.setState({ editingDataOnUserInfo: true})}>
                                        <Edit />
                                      </Button>
                                    }
                                    {editingDataOnUserInfo && !profileId && 
                                    <Button 
                                      className={classes.icon} onClick={() => 
                                      {
                                        this.setState({ editingDataOnUserInfo: !this.state.editingDataOnUserInfo });
                                        editUsermutation({params:{id, userName, userEmail, userPhone, userCellphone}, client, userRefetch: context.userRefetch})
                                      }
                                    }>
                                    <Save />
                                  </Button>} */}
                                  <GridItem xs={12} style={{ marginTop: 30 }}>
                                    <label> E-mail </label>
                                    <CustomInput
                                      id="email-address"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      classes={{ labelRoot: classes.label }}
                                      inputProps={{
                                        value: this.state.userEmail,
                                        onChange: e => {
                                          this.setState({
                                            userEmail: e.target.value
                                          });
                                        },
                                        disabled: !editingDataOnUserInfo
                                      }}
                                    />
                                  </GridItem>
                                  <GridItem xs={12}>
                                    <label> Telefone </label>
                                    <CustomInput
                                      classes={{ labelRoot: classes.label }}
                                      id="phone-address"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        value: this.state.userPhone,
                                        disabled: !editingDataOnUserInfo,
                                        onChange: e => {
                                          this.setState({
                                            userPhone: e.target.value
                                          });
                                        }
                                      }}
                                    />
                                  </GridItem>
                                  <GridItem xs={12}>
                                    <label> Celular </label>
                                    <CustomInput
                                      classes={{ labelRoot: classes.label }}
                                      id="phone-address"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        value: this.state.userCellphone,
                                        onChange: e =>
                                          this.setState({
                                            userCellphone: e.target.value
                                          }),
                                        disabled: !editingDataOnUserInfo
                                      }}
                                    />
                                  </GridItem>
                                </GridContainer>
                              </CardBody>
                            </Card>
                          </GridItem>
                          <GridItem className={classes.info} xs={12} lg={6}>
                            <Card profile>
                              <CardBody profile>
                                <GridContainer>
                                  <GridItem xs={12} style={{ marginTop: 30 }}>
                                    <label> Organização </label>
                                    <CustomInput
                                      classes={{ labelRoot: classes.label }}
                                      id="company-disabled"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        value: user.organization.name,
                                        disabled: true
                                      }}
                                    />
                                  </GridItem>
                                  <GridItem xs={12}>
                                    <label> Cargo </label>
                                    <CustomInput
                                      classes={{ labelRoot: classes.label }}
                                      id="username"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        value: user.role.name,
                                        disabled: true,
                                        onChange: e => {
                                          this.setState({
                                            userRole: e.target.value
                                          });
                                        }
                                      }}
                                    />
                                  </GridItem>
                                </GridContainer>
                              </CardBody>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem className={classes.noWrap} xs={12} md={9}>
                        {projects.map((project) =>
                          <GridItem xs={12} md={6} lg={4}>
                            <ProjectCard 
                              project={project}
                              user={_loggedUser}
                              openDeleteAlert={() => {}}  
                            />
                          </GridItem>
                        )}
                      </GridItem>
                    </GridContainer>
                  </div>
                );
              }}
            </Query>
          );
        }}
      </ContextConsumer>
    );
  }
}

let userProfileWithStyles = withStyles(ProfileStyle)(UserProfile);
export default withApollo(userProfileWithStyles);
