export default {
  root: {
    backgroundColor: '#EEEEEE',
    borderRadius: '6px',
    width: '550px',
    minHeight: '210px',
    height: 'auto',
    maxHeight: '800px !important',
    margin: '0 auto',
    marginTop: '200px',
    display: 'flex',
    flexDirection: 'column',
  },
  searchInput:{
    "& div":{
      width:"100%",
      height: "2em",
      paddingBottom: "0px",
      backgroundColor:"white",
    },
    "& fieldset":{
      // border:"",
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
      borderTopLeftRadius: "7px",
      borderTopRightRadius: "7px"
    }
  },
  uploadContent: {
    backgroundColor: '#3F2C78',
    height: '41px',
    minWidth: '50px',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '14px',
    lineHeight: '14px',
    padding: '12px 10px',
    borderRadius: '6px',
    boxShadow: '0 4px 23px 0 rgba(0,0,0,0.12)',
    display: 'flex',
    fontWeight: '500',
    justifyContent: 'space-between',
    marginBottom: '45px',
    marginTop: '6px',
    "&:focus": {
      backgroundColor: '#3F2C78',
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: '#796aa9',
      boxShadow: "none",
    },
    '& svg': {
      color: "white",
      height: '24px'
    },
  },
  plusCount: {
    height: 'auto'
  },
  linearProgress: {
    width: '90%',
    margin: '5px 5%'
  },
  btnIcon: {
    height: '22px !important',
    width: '22px !important',
    marginRight: '10px !important',
    fontSize: '20px !important'
  },
  fileWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      marginTop: '7px',
      fontSize: '16px',
      height: '15px',
      cursor: 'pointer'
    },
    '& span': {
      padding: '0 10px !important'
    }
  },
  uploadedFiles: {
    width: '90% !important',
    marginLeft: '5%',
  },
  listUploads: {
    marginTop: "1em",
    width: '100%',
    maxHeight: '150px',
    height: 'auto',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '9px',
      height: '9px',
    },
    '&::-webkit-scrollbar-button': {
      width: '0px',
      height: '0px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#a5abba',
      border: '0px none #ffffff',
      borderRadius: '3px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#a5abba',
    },
    '&::-webkit-scrollbar-thumb:active': {
      background: '#a5abba',
    },
    '&::-webkit-scrollbar-track': {
      background: '#d8d8d8',
      border: '0px none #ffffff',
      borderRadius: '3px',
    },
    '&::-webkit-scrollbar-track:hover': {
      background: '#babcd6',
    },
    '&::-webkit-scrollbar-track:active': {
      background:'#babcd6',
    },
    '&::-webkit-scrollbar-corner': {
      background: 'transparent',
    }
  },
  header: {
    paddingTop: '25px',
    marginBottom: '5px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  tabWrapper: {
    width: '180px'
  },
  listUploadsWrapper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '7px',
    width: '96%',
    minHeight: '50px',
    marginLeft: '2%'
  },
  uploadedFile:{
    cursor: "pointer",
    color: '#3C4858',
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'space-between',
    '& span': {
      padding: '5px 10px',
      lineHeight: '30px',
    },
    '& svg': {
      color: '#8272b3',
      marginTop: '10px',
      marginRight: '15px'
    },
    '&:hover':{
      backgroundColor:"#ebecee"
    }
  },
  inputNone: {
    display: 'none'
  },
  btnUpload: {
    float: 'right',
    marginRight: '2%',
    marginTop: '15px',
    paddingTop: '5px',
    paddingBottom: '5px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
    marginBottom: '20px',
  },
  modalBody: {
    zIndex: '99999', 
  },
  mask: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.1)',
    zIndex: '999'
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexShrink: "",
    width: "100%",
    padding: '0 10%',
    zIndex: '99999', 
  },
  placeholder: {
    height: '100px',
    width: '80%',
    marginLeft: '10%'
  },
  footer: {
    height: '50px'
  },
  divisor: {
    height: '1px',
    backgroundColor: '#AAAAAA',
    width: '98%',
    marginLeft: '1%'
  }
}