import {
  containerFluid,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const headerStyle = theme => ({
  appBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute",
    width: "100%",
    paddingTop: "10px",
    zIndex: "1029",
    color: grayColor[6],
    border: "0",
    borderRadius: "3px",
    padding: "10px 25px 10px 0px",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block"
  },
  container: {
    ...containerFluid,
    minHeight: "50px",
    zIndex: '999',
  },
  flex: {
    flex: 1
  },
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    margin: "0 !important",
    "&:hover,&:focus": {
      background: "transparent"
    }
  },
  hamburgerIcon: {
    backgroundColor: 'white !important',
    borderRadius: '50%',
    boxShadow: 'none',
    "&:hover,&:focus": {
      boxShadow: 'none',
    }
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  dropdown: {
    width: 'fit-content'
  },
  dropdownItem: {
    width: 'auto'
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  sidebarMinimize: {
    float: "left",
    padding: "0 0 0 15px",
    display: "block",
    lineHeight: '15px',
    color: '#5A4B88 !important',
    '& svg': {
      color: '#5A4B88'
    }
  },
  sidebarMinimizeRTL: {
    padding: "0 15px 0 0 !important"
  },
  sidebarMiniIcon: {
    width: "20px",
    height: "17px"
  },
  labelSchedule: {
    '& svg': {
      height: '12px',
    }
  },
  rootSchedule: {
    width: '30px',
    minWidth: '30px',
    cursor: 'default',
    height: '20px',
    paddingTop: '3px'
  },
  titleNavBar: {
    fontFamily: "Montserrat, sans-serif !important",
    marginLeft: "10px !important",
    marginBottom: "0px !important",
    display: "flex",
    justifyContent: 'flex-start',
    flexDirection: "column",
    padding: 0,
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '24px',
    backgroundColor: "transparent !important",
    boxShadow: "none !important",
    color: '#555555 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: "transparent",
      boxShadow: "none",
      color: '##555555',
    },
  },
  channelName: {
    fontSize: '12px !important',
    marginLeft: '12px',
    fontFamily: 'Roboto !important',
    fontWeight: '400',
    display: 'flex',
    marginTop: '5px',
    '& svg': {
      marginRight:'5px',
      fontSize: '21px'
    },
    "& div": {
      marginTop: '0px',
    }
  },
  titleWrapper: {
    marginLeft: '15px',
    color: '##555555',
  }
});

export default headerStyle;
