import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Query, withApollo } from "react-apollo";
import contentViewStyle from "views/Content/contentStyle";
import FoldersTable from "components/FoldersTable/FoldersTable.jsx";
import FoldersContentTable from "components/FoldersContentTable/FoldersContentTable.jsx";
import RenameBox from "components/RenameBox/RenameBox.jsx";
import "react-table/react-table.css";
import VerificationDialog from "components/VerificationDialog/VerificationDialog";
import markChecked from "utils/markCheckedRow";
import {
  GET_JURIDICAL_FOLDERS_QUERY,
  GET_CONTENT_FROM_FOLDER_QUERY,
  downloadManyContents,
  JURIDICAL_CONTENT_SUBSCRIPTION
} from './JuridicalQueries.js';
import { myUser } from 'queries/queries.js';

import { 
  onDrop,
  setSelectedFolder,
  selectRenameBoxPropsUsingRenameType,
  toggleAll,
  rename,
  downloadManyFiles,
  createFoldersContentData,
  editManyContents,
  openVerifyContentDialog
} from "./utils/utils";
import Loading from "../../components/Loading/Loading.jsx";


class ContentView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      moreVertOpen: -1,
      anchorMoreVert: null,
      selectedFolder: 0,
      selectedSubFolder: null,
      openFolders: [0],
      RenameBoxOpen: false,
      parentFolderId: null,
      checkedRows: [],
      renameType: "", //types folderEdit, contentEdit, folderAdd
      currentEditingName: "", //renameBox state
      currentEditingId: "", //renameBox state
      modalOpen: false,
      isVerificationDialogOpen: false,
      verificationDialogCall: () => { },
      isDraggin: false,
      expandedRows: [],
      isTagToggled: false,
    };
    this.createFoldersContentData = createFoldersContentData.bind(this);
    this.unsubscribe = null;
  }

  toggleRow = (row, id) => {
    const { expandedRows } = this.state;
    const _expandedRows = expandedRows;
    _expandedRows[row] = _expandedRows[row] ? false : id;
    this.setState({expandedRows: _expandedRows});
  }

  toggleTag = () => {
    this.setState({isTagToggled: !this.state.isTagToggled})
  }

  render() {
    const { classes } = this.props;
    const { openFolders,
      selectedFolder, 
      selectedFolderId, 
      selectedSubFolder, 
      RenameBoxOpen, 
      checkedRows, 
      currentEditingName, 
      isVerificationDialogOpen, 
      verificationDialogCall,
      expandedRows,
      isTagToggled
    } = this.state;
    const { match: { params }, client } = this.props;

    return (
      <div className={classes.root}>
        {RenameBoxOpen && 
          <RenameBox
            open={RenameBoxOpen}
            onClose={() => this.setState({ RenameBoxOpen: false })}
            onClick={rename.bind(this)}
            value={currentEditingName}
            {...selectRenameBoxPropsUsingRenameType.bind(this)}
          />
        }
        {
          isVerificationDialogOpen && 
          <VerificationDialog
            open={isVerificationDialogOpen}
            handleClose={() => this.setState({ isVerificationDialogOpen: false })}
            onAccept={() => verificationDialogCall()}
          />
        }
        <Query query={myUser}>
        {({ data, loading, error }) => {
          if (loading) {
            return null;
          }
          if (!data) {
            window.location.href = "/login";
          }
          let userPermissions = data.myUser.role.permissions;
          return (
            <Query
              query={GET_JURIDICAL_FOLDERS_QUERY}
              variables={{ projectId: params.projectId }}
            >
              {({ loading: loadingFolder, error: errorFolder, data }) => {
                if(errorFolder) return `erro em juridical folders query: ${errorFolder}`
                if(loadingFolder) return <Loading/>
                const rootFolders = data ? data.juridicalFolders : []; //get root folder
                
                let folderId;
                let urlFolder;
                let urlSubFolder;
                let newOpenFolders;
                
                // If the id in the url does not match any of the folders currently displayed then selected folder id is cleansed 
                if( !rootFolders.some(folder => folder.id === selectedFolderId || folder.subFolders.some(subFolder => subFolder.id === selectedFolderId))) {
                  if ( this.state.selectedFolderId ) this.setState({ selectedFolderId: "" });
                }
  
                //if there is no selected id searches the displayed folders for the ids in the url 
                if( !this.state.selectedFolderId && rootFolders.length > 0 ) {
                    urlFolder = rootFolders.find((rootFolder, index) => {
                      if (rootFolder.id === params.folderId) {
                        newOpenFolders = [...openFolders, index];
                        return true;
                      }
                      return false;
                    });
                  
                  //if there is a folderid on the url tries to set the subfolder id, if there is no subfolder id it defaults to first folder
                  if(urlFolder) {
                    urlSubFolder = urlFolder.subFolders.find((subFolder)=> subFolder.id === params.subFolderId);
                    if( urlSubFolder )
                      folderId = urlSubFolder.id;
                    else
                      folderId = urlFolder.id;
                  } else
                    folderId = rootFolders[0] ? rootFolders[0].id : "";
                  
                  if( newOpenFolders )
                    this.setState({ selectedFolderId: folderId, openFolders: newOpenFolders });
                  else
                    this.setState({ selectedFolderId: folderId });
                } else {
                  // if there is a selected folder that is one of the displayed currently sets the contents being displayed to those inside the selected folder
                  folderId = selectedFolderId;
                }
  
                return (
                  <Query
                    query={GET_CONTENT_FROM_FOLDER_QUERY}
                    variables={{ projectId: params.projectId, folderId }}
                  >
                    {({ loading: loadingContent, error: errorContent, data, subscribeToMore }) => {
                      let tableData = [];
                      if(errorContent) return `erro em juridical folders query: ${errorContent}`
                      if (!loadingContent) tableData = createFoldersContentData.bind(this)({dataContent: data ? data.juridicalContents : [], toggleTag: isTagToggled, folderId, userPermissions }); //create content from selected folder

                      if (!this.unsubscribe) {
                        this.unsubscribe = subscribeToMore({
                          document: JURIDICAL_CONTENT_SUBSCRIPTION,
                          variables: { juridicalFolderId:folderId },
                          updateQuery: (prev, {subscriptionData}) => {
                            if (!subscriptionData.data) return prev;
                            const { contentChange } = subscriptionData.data;
                            return { ...prev, juridicalContents:contentChange };
                          }
                        })
                      }
                      if (expandedRows.length === 0 && data.juridicalContents && data.juridicalContents.length !== 0 ) {
                        const expanded = data.juridicalContents.map((content) => false); //init open rows
                        this.setState({expandedRows: expanded});
                      }
                      return (
                        <>
                          <FoldersTable 
                            juridical
                            openFolders={openFolders}
                            selectedFolder={selectedFolder}
                            selectedFolderId={selectedFolderId}
                            selectedSubFolder={selectedSubFolder}
                            onDrop={onDrop.bind(this)}
                            checkedRows={checkedRows}
                            rootFolders={rootFolders ? rootFolders : []}
                            deleteFolder={(folderId) => openVerifyContentDialog.call(this, "deleteFolder", {params: {folderId, projectId: params.projectId}, client})}
                            addFolder={(parentFolderId) => this.setState({RenameBoxOpen: true, parentFolderId, renameType: "addFolder", currentEditingName: ""})}
                            editFolder={(folderId, folderName) => this.setState({RenameBoxOpen: true, currentEditingId: folderId, renameType: "editFolder", currentEditingName: folderName})}
                            setExpand={setSelectedFolder.bind(this)}
                          />
                          <FoldersContentTable
                            juridical
                            params={this.props.match.params}
                            data={loadingContent ? [] : tableData}
                            userPermissions={userPermissions}
                            loading={loadingContent}
                            rawData={data.juridicalContents}
                            isEmpty={rootFolders ? rootFolders.length > 0 : false}
                            // classes={classes}
                            checkedRows={checkedRows}
                            markChecked={markChecked.bind(this)}
                            expandedRows={expandedRows}
                            isTagToggled={isTagToggled}
                            toggleTag={this.toggleTag}
                            editManyContents={editManyContents.bind(this)}
                            toggleRow={this.toggleRow}
                            downloadManyContents={() => downloadManyContents({client, contentsId: checkedRows, cb: downloadManyFiles.bind(this)})}
                            addFolder={(parentFolderId) => this.setState({RenameBoxOpen: true, parentFolderId, renameType: "addFolder", currentEditingName: ""})}
                            selectedFolderId={folderId}
                            deleteManyContents={(folderId) => openVerifyContentDialog.call(this,  "deleteMany", {ids: checkedRows, client, params: {folderId, projectId: params.projectId}})}
                            toggleAllChecked={toggleAll.bind(this)}
                          />
                        </>
                      )
                    }}
                  </Query>
                )
              }}
            </Query>
          );
        }}
        </Query>
      </div>
    );
  }
}

export default withApollo(withStyles(contentViewStyle)(ContentView));
