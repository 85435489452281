import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { Query } from "react-apollo";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import adminNavbarLinksStyle from "./allNotificationsStyle";
import Notification from "components/Notification/Notification.jsx";
import classNames from "classnames";
// import Loading from 'components/Loading/Loading';
import {
  NOTIFICATION_SUBSCRIPTION,
  NOTIFICATIONS_QUERY
} from "components/Notification/notificationQueries.js";
import Loading from "../../components/Loading/Loading";
import { Button } from "@material-ui/core";
class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.unsubscribe = null;
  }
  /** [
        'project-invite',
        'project-removal',
        'user-change',
        'content-change',
        'content-addition',
        'content-comment',
        'juridical-change',
        'juridical-addition',
        'juridical-comment',
        'document-addition',
    ] */
  createNotificationTimestamp(notification) {
    return moment(notification.creationDate).format("DD/MM/YY hh:mm");
  }

  componentDidMount() {
    this.props.history.push("/admin/notifications");
  }

  render() {
    const { classes } = this.props;
    const dropdownItem = classNames(classes.dropdownItem);
    let notificationsToShow = 30;

    return (
      <Query
        query={NOTIFICATIONS_QUERY}
        variables={{ limit: notificationsToShow }}
      >
        {({ data, error, loading, subscribeToMore, fetchMore, refetch }) => {
          if (error) return <span>ERRO: {error.message}</span>;
          if (loading) return <Loading />;
          if (!this.unsubscribe) {
            this.unsubscribe = subscribeToMore({
              document: NOTIFICATION_SUBSCRIPTION,
              // Na maioria dos casos não é usado, ainda precisamos estudar mais.
              // É recomendado manter mesmo sendo aparentemente desnecessário
              updateQuery: (prev, { subscriptionData }) => {
                this.pushUnseenNotifiction();
                if (!subscriptionData.data) return prev;
                let { newNotification } = subscriptionData.data;
                return {
                  notifications: [
                    newNotification,
                    ...prev.notifications.filter(
                      notification => notification.id !== newNotification.id
                    )
                  ]
                };
              }
            });
          }
          if (data.notifications) {
            return (
              <>
                <div className={classes.titleWrapper}>
                  <span className={classes.title}>Notificações</span>
                </div>
                <div className={classes.notificationListWrapper}>
                  <MenuList role="menu">
                    <div key={data.notifications.length * 999}>
                      {data.notifications.map((notification, index) => {
                        return (
                          <MenuItem
                            onClick={this.handleClose}
                            className={dropdownItem}
                            classes={{ primaryHover: classes.primaryHover }}
                            key={index * 999 + notification.type}
                          >
                            <Notification
                              notification={notification}
                              notificationRefetch={params => refetch(params)}
                              refetchLimit={data.notifications.length}
                            />
                          </MenuItem>
                        );
                      })}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around"
                        }}
                      >
                        <Button
                          size={"small"}
                          variant="outlined"
                          onClick={() => {
                            fetchMore({
                              variables: {
                                offset: data.notifications.length,
                                limit: notificationsToShow
                              },
                              updateQuery: (prev, { fetchMoreResult }) => {
                                if (!fetchMoreResult) return prev;
                                return Object.assign({}, prev, {
                                  notifications: [
                                    ...prev.notifications,
                                    ...fetchMoreResult.notifications
                                  ]
                                });
                              }
                            });
                          }}
                        >
                          Mais
                        </Button>
                      </div>
                    </div>
                  </MenuList>
                </div>
              </>
            );
          } else return null;
        }}
      </Query>
    );
  }
}
export default withStyles(adminNavbarLinksStyle)(Notifications);
