import React, { Component } from "react";
import { gql } from "apollo-boost";
import { graphql, Mutation } from "react-apollo";

import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Logo from "../../shared/Logo";
import authenticationBg from "../../shared/authenticationBg.svg";
import { ApolloConsumer } from "react-apollo";
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const sendNewPasswordEmail = gql`
  mutation sendNewPasswordEmail($destination: String!) {
    sendNewPasswordEmail(destination: $destination)
  }
`;

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.state = {
      redirect: false,
      error: null,
      destination: "",
      alert: null
    };
    this.successAlert = this.successAlert.bind(this);
  }

  async sendPassword(sendMail) {
    await sendMail({
      variables: {
        destination: this.state.destination
      }
    });
    this.successAlert();
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  successAlert() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-200px" }}
          title={"E-mail de recuperação enviado!"}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.purpleButton + " " + this.props.classes.success
          }
        />
      )
    });
  }

  handleChange(prop, event) {
    this.setState({ [prop]: event.target.value });
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    const classes = this.props.classes;

    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          {this.state.alert}
          <div className={classes.logo}>
            <Logo />
          </div>
          <Typography component="h1" variant="h5">
            {this.props.title}
          </Typography>
          <ApolloConsumer>
            {client => (
              <Mutation mutation={sendNewPasswordEmail}>
                {sendPassword => (
                  <form
                    className={classes.form}
                    onSubmit={async event => {
                      event.preventDefault();
                      await this.sendPassword(sendPassword, client);
                      this.successAlert();
                    }}
                  >
                    {this.state.error ? (
                      <div style={{ color: "RED" }}> {this.state.error} </div>
                    ) : (
                      ""
                    )}
                    <div className={classes.title}> Esqueceu a senha? </div>
                    <div className={classes.subtitle}>
                      {" "}
                      Digite o seu e-mail abaixo para recuperar a senha{" "}
                    </div>
                    <TextField
                      id="email"
                      label="E-mail"
                      //type='email'
                      name="email"
                      autoComplete="email"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      autoFocus
                      required
                      value={this.state.email}
                      onChange={event =>
                        this.handleChange("destination", event)
                      }
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      size="large"
                      fullWidth
                    >
                      Enviar E-mail
                    </Button>
                  </form>
                )}
              </Mutation>
            )}
          </ApolloConsumer>
        </Paper>
      </main>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

const styles = theme => ({
  form: {
    width: "100%",
    marginTop: theme.spacing.unit
  },
  title: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "montserrat, sans-serif",
    margintop: 10,
    marginBottom: 10
  },
  subtitle: {
    marginBottom: 20
  },
  submit: {
    marginTop: theme.spacing.unit
  },
  link: {
    marginTop: theme.spacing.unit * 3,
    display: "block",
    textDecoration: "none"
  },
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "100vw",
    minHeight: "100vh",
    backgroundColor: "#ffd600",
    backgroundImage: `url(${authenticationBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center center"
  },
  paper: {
    textAlign: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400
    }
  },
  logo: {
    width: "128px",
    height: "128px",
    margin: "0 auto",
    marginBottom: theme.spacing.unit * 3
  },
  ...sweetAlertStyle
});

const StyledForgotPassword = withStyles(styles)(ForgotPassword);

export default graphql(sendNewPasswordEmail)(StyledForgotPassword);
