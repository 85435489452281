import { cardTitle, card } from "assets/jss/material-dashboard-pro-react.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const contentViewStyle = theme => ({
  ...regularFormsStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  card: {
    ...card,
    paddingBottom: '10px',
    marginTop: '40px',
    marginBottom: 0,
    overflow: 'auto',
    height: '100%'
  },
  root: {
    display: 'flex',
    maxHeight: '85vh',
    height: '100%',
    '& .ReactTable': {
      maxHeight: '100%'
    },
  },
  modal: {
    backgroundColor: 'white',
  },
  markerWrapper: {
    position: 'relative'
  },
  ballMarker: {
    height: '40px', 
    backgroundColor: 'yellow',
    opacity: '0.5', 
    width: '40px', 
    borderRadius: '50%',
    zIndex: 1000, 
    position: 'absolute', 
    border: '1px solid black'
  },
  markerCanvas: {
    height: '100%', 
    zIndex:999, 
    width: '100%', 
    backgroundColor: 'transparent', 
    top: 0, 
    left: 0, 
    position: 'absolute'
  },
  placeholder: {
    width: '100%'
  },
  dropDown: {
    backgroundColor: 'transparent',
    color: '#AAAAAA',
    boxShadow: 'none',
    padding: "10px 5px 5px 0px",
    borderBottom: '1px solid #D2D2D2',
    maxWidth: '160px',
    borderRadius: 0,
    width: '100%',
    textTransform: 'initial',
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: '#AAAAAA',
      boxShadow: 'none',
    },
  },
  tooltip: {
    backgroundColor: 'black',
    fontSize: 15,
    padding: '5px 10px'
  },
  emptyState: {
    display: 'flex',
    padding: '20px 0',
    justifyContent: 'center',
    '& img': {
      cursor: "pointer",
    }
  },
  versionNameEllipses: {
    whiteSpace: "nowrap",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    OTextOverflow:"ellipsis",
    MozBinding:"url('bindings.xml#ellipsis')",//issue for Firefox
  },
  dropDownButtonText: {
    fontFamily: 'Roboto, sans-sarif !important',
    fontSize: '14px'
  },
  inputAdornment: {
    '& svg': {
      color: '#AAAAAA',
      height: '16px'
    }
  },
  moreOptions: {
    marginRight: 0,
    marginLeft: 'auto',
    cursor: 'default'
  },
  restrainInputWidth: {
    width: '400px',
    paddingTop: 0,
    marginTop: '-18px',
    marginRight: '15px'
  },
  filterDropDownManager: {
    width: '115px'
  },
  underlineInput: {
    marginTop: '25px !important'
  },
  inputWrapper: {
    display: 'flex',
    width: 'auto',
    justifyContent: 'space-between'
  },
  headerIconWrapper: {
    minWidth: '155px',
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '15px'
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    maxHeight: '100px'
  },
  headerIcon: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    textAlign: 'center',
    marginTop: '10px',
    transition: 'all 0.5s ease',
    cursor: 'pointer',
    '& svg': {
      color: '#555555',
      height: '18px',
      marginTop: '6px',
      '& .icon': {
        fill: '#555555',
      }
    },
    '&:hover': {
      backgroundColor: '#3F2C78',
      '& svg': {
        color: 'white',
        '& .icon': {
          fill: 'white',
        }
      }
    }
  },
  checkboxTypeWrapper: {
    display: 'flex',
    cursor: 'pointer',
    '& svg:nth-child(1)': {
      display: 'none',
      marginLeft: '13px',
    },
    '& svg:nth-child(2)': {
      lineHeight: '10px',
      color: '#3F2C78',
      marginLeft: '5px',
    },
    '& input': {
      height: '47px',
      marginTop: '-12px',
      width: '55px',
      marginLeft: '-10px',
    }
  },
  markedChecked: {
    '& svg:nth-child(2)': {
      display: 'none',
      '& $checkRoot': {
        padding: 0,
      }
    },
    '& svg:nth-child(1)': {
      display: 'block',
    }
  },
  plusCount: {
    color: '#3F2C78',
    fontSize: '11px',
    borderRadius: '50%',
    justifyContent: 'center',
    fontWeight: '400',
    marginLeft: '3px',
    transition: 'all linear 0.5s',
    cursor: 'pointer',
    '& svg': {
      width: '14px',
      marginTop: '-4px',
    },
  },
  checkRoot: {
    padding: '0 5px 0px 0px',
  },
  title: {
    width: '100%',
    '& span': {
      fontFamily: 'Montserrat',
      fontWeight: '500',
      color: '#3C4858',
      marginLeft: '13px',
      fontSize: '15px',
      display: 'flex',
    }
  },
  version: {
    border: '1px solid #5A4B88',
    borderRadius: '13px',
    height: '17px',
    padding: '0 5px',
    marginLeft: '5px',
    fontSize: '12px',
    lineHeight: '16px',
    display: 'flex',
    marginTop: '2px',
    maxHeight: '200px',
    width: '30px',
    cursor: 'pointer',
    transition:"width 0.2s linear",
    '& $plusCount': {
      opacity: "0",
      visibility: "hidden",
      transition:"opacity 0.6s ease-out",
    },
    "&:before": {
      content: "'V'", // string inside of a string
      fontSize: '12px',
      visibility: 'visible',
      display: 'block',
      top: 0,
      fontWeight: 'bold',
    }
  },
  versionWrapper: {
    padding: '2px 5px',
    cursor: 'pointer',
    '&:hover $version': {
      width: '75px',
      '& $plusCount': {
        visibility: "visible",
        opacity: 1,
      },
      "&:before": {
        content: "'Versão '", // string inside of a string
        whiteSpace: 'pre',
        fontWeight: 'bold'
      },
    },
  },
  versionText: {
    fontWeight: 'bold',
  },
  contentStatus: {
    position: 'absolute',
    right: '20px',
    top: '-30px',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '500',
    color: '#929292',
    fontSize: '15px',
    '& span': {
      marginRight: '5px',
      '& span': {
        color: 'orange',
        marginRight: '2px',
      },
    },
    '& span:nth-child(2)': {
      marginRight: '5px',
      '& span': {
        color: 'red'
      },
    },
    '& span:nth-child(3)': {
      marginRight: '5px',
      '& span': {
        color: 'green'
      },
    }
  },

  comment: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    '& div': {
      marginTop: '1px'
    },
    '& svg': {
      color: '#8a7faa',
      height: '23px',
      width: '20px',
      marginRight: '5px'
    }
  },
  avatar: {
    '& img': {
      borderRadius: '50%',
      border: '1px solid #3F2C78',
      height: '35px',
      width: '35px'
    },
  },
  iconButton: {
    padding: 0,
    cursor: 'pointer',
    transition: 'all 0.5s linear',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'rgba(0, 0, 0, 0.77)'
    }
  },
  titleHeader: {
    display: 'flex',
  },
  buttonTextWrapper: {
    flex: '1',
    display: 'flex',
    justifyContent: 'space-between'
  },  
  imgWrapper: {
    overflow: 'auto', 
    width: '100%', 
    height: '100%'
  }

})

export default contentViewStyle;