export default {
  dropDownButton: {
    backgroundColor: 'transparent',
    color: '#3f2c78',
    boxShadow: 'none',
    marginTop: '0px',
    marginRight: '0',
    marginLeft: 'auto',
    padding: "10px 5px 5px 0px",
    border: '1px solid #3f2c78',
    borderRadius: '10px',
    maxWidth: '400px',
    width: '100%',
    height: '20px',
    paddingTop: 6,
    minWidth: '60px !important',
    textTransform: 'initial',
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: '#3f2c78',
      boxShadow: 'none',
    },
  },
  dropdownGreen: {
    border: '1px solid green !important',
    color: 'green !important',
  },
  dropdownOrange: {
    border: '1px solid orange !important',
    color: 'orange !important'
  },
  dropdown: {
    minWidth: '110px !important'
  },
  borderOrange: {
    borderLeft: '2px solid orange !important',
  },
  borderGreen: {
    borderLeft: '2px solid green !important',
  },
  dropDownButtonText: {
    fontFamily: "Roboto, sans-serif !important",
    fontWeight: "300",
    fontSize: '12px',
  },
  buttonTextWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%',
    marginTop: '-11px !important',
  },
  caret: {
    marginTop: '7px'
  },
  dropdownWrapper: {
    display: 'flex',
    overflow: 'visible',
    marginTop: '7px'
  },
  pooper: {
    marginLeft: '-35px !important'
  }
}