import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import Group from "@material-ui/icons/Group";
import Bedge from "components/BedgePlaceHolder/BedgePlaceHolder.jsx";
import AvatarImg from "assets/img/avatar.png";
import Avatar from "components/Avatar/Avatar.jsx";
import BottomButton from 'components/BottomButton/BottomButton';
import Icon from "@material-ui/core/Icon";
import TeamTableStyle from "./TeamTableStyle.js";
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Stars from './Stars';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import VerificationDialog from "components/VerificationDialog/VerificationDialog";
import { IconButton, Snackbar } from "@material-ui/core";
import { deleteCollab } from "../../views/Team/TeamQueries.js";
import EditRole from "../../components/EditRole/EditRole.jsx";
import { editCollab } from "./TeamTableQueries.js";

export class TeamTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVerificationDialogOpen: false,
      moreVertOpen: false,
      isCollabMenuOpen: false,
      anchorMoreVert: undefined,
      error: undefined,
      editRoleOpen: false,
    }
  }
  setIsVerificationDialogOpen = (value) => this.setState({ moreVertOpen: value })

  setIsCollabMenuOpen = (value) => this.setState({ isCollabMenuOpen: value })

  setAnchorMoreVert = (value) => this.setState({ anchorMoreVert: value })

  setError = (value) => this.setState({ error: value })

  setMoreVertOpen = (row) => this.setState({ moreVertOpen: row })

  setEditRoleOpen = () => this.setState({editRoleOpen: !this.state.editRoleOpen}) 

  saveRole = async (roleId) => {
    const { client, projectId, collab } = this.props
    await editCollab({client, params: { projectId, roleId, collaborationId: collab.id }});
    this.setEditRoleOpen();
  }

  render() {
    const {
      users,
      title,
      collab,
      leader,
      client,
      classes,
      showBedge,
      projectId,
      deleteGroup,
      defaultGroup,
      onClickBottom,
      userPermissions,
      showMoreOptions,
      showBottomButton,
      deleteMemberGroup,
      deleteMemberProject
    } = this.props;

    const {
      error,
      moreVertOpen,
      isCollabMenuOpen,
      anchorMoreVert,
      isVerificationDialogOpen,
      editRoleOpen,
    } = this.state;

    const collabRole = collab && collab.role ? collab.role.name : '';

    let moreOptions = [];
    let collabOptions = [
      {
        name: 'Desfazer',
        icon: 'delete',
        func: async ({ id }) => {
          this.setState({isCollabMenuOpen: false})
          await deleteCollab({ client, params: { projectId: projectId, organizationId: collab.organization.id } })
        }
      },
      {
        name: 'Editar',
        icon: 'edit',
        func: ({id}) => {
          this.setState({isCollabMenuOpen: false})
          this.setEditRoleOpen();
        }
      }];
    if (userPermissions.includes('edit-group') && !defaultGroup) {
      moreOptions.push({
        icon: 'delete',
        name: 'Remover da Equipe',
        func: async ({ id }) => await deleteMemberGroup(id, this.setError)
      });
    }
    if (userPermissions.includes('edit-project')) {
      moreOptions.push({
        icon: 'block',
        name: 'Remover do Projeto',
        func: async ({ id }) => await deleteMemberProject(id, this.setError)
      });
    }

    let leaderData = null;
    let filteredUsers = [...users];

    if( leader && users.some( user => user.id === leader.id ) ) {
      leaderData = {
        avatar: <Avatar img={leader.image ? leader.image.imageUrl : AvatarImg} userId={leader.id} style={{marginTop: '2px'}}/>, 
        name: <div className={classes.owner}> <div className={classes.leaderName}> {leader.name} </div> <Stars/> </div>,
        role: <Bedge text={leader.role ? leader.role.name : ''}/>
      };
      filteredUsers = users.filter( user => user.id !== leader.id );
    }

    let data = filteredUsers.map((user, index) => {
      if(user) return ({
        avatar: <Avatar img={user.image ? user.image.imageUrl : AvatarImg} userId={user.id} style={{marginTop: '2px'}}/>,
        name: user.name,
        role: <Bedge text={user.role.name}/>,
        option: (<div>
          {moreOptions.length > 0 &&
            !showBedge &&
            !user.role.permissions.includes('read-project') &&
            <>
              <IconButton
                aria-label="More"
                aria-haspopup="true"
                onClick={(e) => {
                  e.stopPropagation();
                  if (e) {
                    this.setAnchorMoreVert(e.currentTarget);
                    this.setMoreVertOpen(index);
                  }

                }}
                classes={{ root: classes.iconButton }}
              >
                <div className=''>
                  <MoreVertIcon />
                </div>
              </IconButton>
              <Menu
                id="long-menu"
                open={moreVertOpen === index}
                anchorEl={anchorMoreVert}
                onClose={(e) => {
                  e.stopPropagation();
                  this.setMoreVertOpen(-1)
                }}
                PaperProps={{
                  style: {
                    maxHeight: 45 * 4.5,
                    width: 190,
                    marginTop: 50,
                    marginLeft: -10,
                    textAlign: 'center'
                  },
                }}
              >
                <MenuList classes={{ root: classes.menuList }}>
                  {moreOptions.map((option, index) =>
                    <MenuItem key={index} onClick={async () => await option.func({ id: user.id })}>
                      <div style={{ display: "flex" }}>
                        <Icon className={classes.iconMenuList}>{option.icon}</Icon>
                        <div className='optionText'> {option.name} </div>
                      </div>
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </>
          }</div>)
      })
      else return ({
        avatar: null,
        name: null,
        role: null,
        option: null
      })
    })

    if (leaderData) {
      data = [leaderData, 
      ...data];
    }

    const minRows = 4;
    let badgeRootStyle = showMoreOptions ? classes.bedgeHeader : (classes.bedgeHeader + " " + classes.standaloneBadge);
    return (
      <Card classes={{ card: classes.card }}>
        <EditRole 
          open={editRoleOpen} 
          onClose={this.setEditRoleOpen}
          save={(roleId) => this.saveRole(roleId)}
        />
        <Snackbar
          open={!!error}
          place='tc'
          close={true}
          color={'danger'}
          message={error}
          closeNotification={() => this.setError(undefined)}
        />
        {isVerificationDialogOpen && <VerificationDialog
          open={isVerificationDialogOpen}
          handleClose={() => this.setIsVerificationDialogOpen(false)}
          onAccept={() => {
            deleteGroup()
            this.setIsVerificationDialogOpen(false);
          }}
        />}
        <div className={classes.titleWrapper}>
          <div className={classes.title}>
            <div className={classes.teamCardTitleWrapper}>
              <Group />
              <div style={{width: 'inherit'}}> <p className={classes.truncatedTitle}>{title}</p> </div>
            </div>
            {showBedge && 
            <div className={classes.flex + ' ' + classes.bedgeWrapper}>
              <Bedge text={collabRole} tooltipText='Permissões da colaboração:' classes={{ root: badgeRootStyle  }} />
            </div>}
            {showMoreOptions && <IconButton
                aria-label="More"
                aria-haspopup="true"
                onClick={(e) => {
                  e.stopPropagation();
                  if (e) {
                    this.setIsCollabMenuOpen(true);
                    this.setAnchorMoreVert(e.currentTarget);
                  }

                }}
                classes={{ root: classes.optionTitle }}
              >
                <div style={{ marginTop:"-3px" }}>
                  <MoreVertIcon />
                </div>
              </IconButton>}
              <Menu
                id="long-menu"
                open={isCollabMenuOpen}
                anchorEl={anchorMoreVert}
                onClose={(e) => { e.stopPropagation(); this.setIsCollabMenuOpen(false) }}
                PaperProps={{
                  style: {
                    maxHeight: 45 * 4.5,
                    width: 190,
                    marginTop: 50,
                    marginLeft: -10,
                    textAlign: 'center'
                  },
                }}
              >
                <MenuList classes={{ root: classes.menuList }}>
                  {collabOptions.map((option, index) =>
                    <MenuItem key={index} onClick={async () => option.func({ id: 'placeholder' })}>
                      <div style={{ display: "flex" }}>
                        <Icon className={classes.iconMenuList}>{option.icon}</Icon>
                        <div className='optionText'> {option.name} </div>
                      </div>
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            {/* { !defaultGroup && <Edit onClick={() => openEditTeam(id)}/> }
                { !defaultGroup && <Delete onClick ={() => setIsVerificationDialogOpen(true)} />} */}
          </div>
        </div>
        <div className={classes.tableWrapper}>
          <ReactTable
            data={data}
            noDataText='Não foram encontrados membros'
            sortable={false}
            showPagination={false}
            getTheadProps={() => {
              return {
                style: {
                  height: '0',
                }
              }
            }}
            getTfootProps={() => {
              return {
                style: {
                  boxShadow: 'none'
                }
              }
            }}
            columns={[
              { accessor: "avatar", width: 47 },
              { accessor: "name" },
              { accessor: "role", width: 45 },
              { accessor: "option", maxWidth: 30, minWidth: 30 },
            ]}
            minRows={data.length > minRows ? data.length : minRows}
            showPaginationBottom={false}
            pageSize={data.length}
          />
          
        </div>
        <div className={classes.bottomButton}>
            {showBottomButton && <BottomButton
              onClick={() => onClickBottom()}
              text='Membro Interno'
              icon='add_circle'
            />}
          </div>
      </Card>
    )
  }
}

export default withStyles(TeamTableStyle)(TeamTable);