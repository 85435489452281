import AddProject from "views/ProjectTemplate/AddProjectWizard.jsx";
import EditProject from "views/ProjectTemplate/EditProjectWizard.jsx";
import ContentDirectory from "views/Content/ContentView/ContentDirectoryView.jsx";
import Juridico from "views/Juridical/JuridicalHub.jsx";
import Projects from "views/Project/Projects.jsx";
import Planning from "views/Planning/Planning";
import ContentPage from "views/Vimeo/ContentView";
import ProjectDashboard from "views/ProjectDashboard/ProjectDashboard";
import Team from "views/Team/Team";
import Panel from "views/Panel/Panel";
import ProjectLog from "views/Log/ProjectLog";
import UsersAndPermissions from "views/UsersAndPermissions/UsersAndPermissions.jsx";

// @material-ui/icons
import {
  Apps,
  Dashboard,
  PieChart,
  AssignmentInd,
  Movie,
  Group,
  LiveTv,
  Book,
  PlaylistAddCheck
} from "@material-ui/icons";
import JuridicoIcon from "assets/icons/Juridico";
import UserProfile from "./views/Profile/Profile";
import Notifications from "views/Notifications/Notifications.jsx";
var dashRoutes = [
  {
    path: "/perfil/:userProfileId",
    name: "Perfil",
    icon: Group,
    component: UserProfile,
    layout: "/admin",
    user: true,
    redirect: true,
    production: true
  },
  {
    path: "/notifications",
    name: "Notificações",
    icon: Group,
    component: Notifications,
    layout: "/admin",
    user: true,
    redirect: true,
    production: true
  },
  {
    path: "/perfil",
    name: "Meu Perfil",
    icon: Group,
    component: UserProfile,
    layout: "/admin",
    user: true,
    redirect: true,
    production: true
  },
  {
    path: "/projects",
    name: "Projetos",
    icon: Apps,
    component: Projects,
    layout: "/admin",
    user: true,
    production: true
  },
  {
    path: "/panel",
    name: "Dashboard Central",
    icon: PieChart,
    component: Panel,
    layout: "/admin",
    user: true
  },
  {
    path: "/users",
    name: "Organização e Permissões",
    icon: AssignmentInd,
    component: UsersAndPermissions,
    layout: "/admin",
    user: true
  },
  {
    path: "/view/:contentId",
    name: "Conteudo",
    icon: Movie,
    component: ContentPage,
    layout: "/content",
    user: true,
    redirect: true,
    production: true
  },
  {
    path: "/juridical-view/:contentId",
    name: "Conteudo",
    icon: Movie,
    component: ContentPage,
    layout: "/content",
    user: true,
    redirect: true,
    production: true
  },
  {
    path: "/add-project",
    name: "Add Project",
    icon: "content_paste",
    component: AddProject,
    layout: "/admin",
    user: true,
    redirect: true
  },
  {
    path: "/edit-project/:projectId",
    name: " Project",
    icon: "content_paste",
    component: EditProject,
    layout: "/admin",
    user: true,
    redirect: true
  },
  {
    path: "/projects",
    name: "Projects",
    icon: "content_paste",
    component: Projects,
    layout: "/admin",
    user: true,
    redirect: true
  },
  {
    path: "/:projectId/dashboard",
    name: "Quadro de Entregas",
    icon: Dashboard,
    component: ProjectDashboard,
    layout: "/project",
    onlyOn: "project"
  },
  {
    path: "/:projectId/equipe",
    name: "Equipe",
    icon: Group,
    component: Team,
    layout: "/project",
    onlyOn: "project"
  },
  {
    path: "/:projectId/planejamento",
    name: "Planejamento",
    icon: PlaylistAddCheck,
    component: Planning,
    layout: "/project",
    onlyOn: "project"
  },
  {
    path: "/:projectId/contents/:folderId/:subFolderId",
    name: "Conteúdo",
    icon: LiveTv,
    component: ContentDirectory,
    layout: "/project",
    onlyOn: "project",
    exact: true
  },
  {
    path: "/:projectId/juridico/:folderId/:subFolderId",
    name: "Jurídico",
    icon: JuridicoIcon,
    component: Juridico,
    layout: "/project",
    onlyOn: "project"
  },
  {
    path: "/:projectId/logs",
    name: "Historico de Atividades",
    icon: Book,
    component: ProjectLog,
    layout: "/project",
    onlyOn: "project",
    bottom: true
  }
];
export default dashRoutes;
