
import React, { Component } from 'react';
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import Header from 'components/Header/Header';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { CloudDownload, LocalOffer } from '@material-ui/icons';
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// import Draggable from 'react-draggable';
import OnlyOffice from "views/Content/OnlyOffice.jsx";
import PdfReader from "components/PdfReader/PdfReader.jsx";
import PropTypes from "prop-types";

import { Modal, Button } from '@material-ui/core';
import DocumentStatusDropdown from "./DocumentStatusDropdown";
import  TagPopper  from "views/Juridical/TagPopper.jsx";
import { downloadFile } from 'views/Juridical/utils/utils.js';
import DocumentTags from '../../components/DocumentTags/DocumentTags';
const style = {
  onlyOfficeContentWrapper:{
    height: '100%',
    '& .responsivePlayerWrapper': {
      height: '94%',
      width: '100% !important',
      maxWidth: '100% !important',
      marginTop: '55px',
      marginLeft: '0px',
    }
  },
  close:{ marginLeft:"15px", cursor:"pointer" },
  draggableContainer:{
    width:"900px",
    height:"85vh", 
    marginLeft:"auto",
    marginRight: 'auto', 
    marginTop:"3vh", 
    backgroundColor:'#3c4858', 
    overflow:'hidden', 
    borderRadius:'10px'
  },
  viewerHeader:{
    backgroundColor: "#1f2229", 
    boxShadow: "none", 
    height: '50px', 
    borderRadius: '10',
    marginBottom:"-50px !important"
    // position: 'absolute'
  },
  headerLinksContainer:{
    width: "100%", 
    display: "flex", 
    justifyContent: "space-between", 
    paddingLeft: "5px"
  },
  onlyOfficeProps:{ 
    height:"100%", 
    width:"100%", 
    overflowY:"hide", 
    maxWidth: '100% !important' 
  },
  ellipsisOnOverflow:{
    textOverflow:"ellipsis",
    whiteSpace:"nowrap",
    overflow:"hidden"
  },
  flatTag: {
    width: "fit-content", 
    padding:"1px 6px 3px 6px", 
    borderRadius:"4px", 
    minWidth:"45px", 
    marginLeft:"4px", 
    marginRight:"4px",
    height:"80%",
    color:"white",
    fontFamily: "Montserrat,sans-serif",
    fontSize:"0.7em",
  },
  flatTagsWrapper:{ 
    display: "flex", 
    justifyContent:"space-between", 
    padding:"2px 2px 2px 2px", 
    marginLeft:"15px"
  },
  downloadBtn:{ marginRight:"10px", color:"white" },
  appBar: {
    marginBottom:"-50px !important"
  },
  popperbtn: {
    backgroundColor:"#323641",
    height:"28px"
  },
}

class DocumentModal extends Component {
    constructor(props) {
      super(props);
      this.state = {
        data: [],
        modalOpen: true,
        event: null,
        status: this.props.document ? this.props.document.status : "",
      };
    }

    setModalOpen(modalOpen){
      this.setState({modalOpen})
    }
    async changeStatus(editContent, newStatus){
      let attachmentId = this.props.document.id;
      await editContent({ variables: { id: attachmentId, status: newStatus }});
      this.setState({ status: newStatus })
    }

    render() {
      const { classes, projectId, versionId } = this.props;
      let fileType = this.props.document.name.split('.');
      fileType = fileType[fileType.length -1];
      if(fileType === 'jpg'
        || fileType === 'gif'
        || fileType === 'ico'
        || fileType === 'bmp'
        || fileType === 'jpeg'
        || fileType === 'png'
        || fileType === 'svg'
        || fileType === 'tif'
        || fileType === 'tiff'
        || fileType === 'webp'
      ) fileType = 'img';
      else if(fileType !== 'pdf')
        fileType = 'doc'
      if(this.props.modalOpen)
        return (
          <Modal 
            open={this.props.modalOpen}
            onClose={() => this.props.toggleModal(false)}
            onBackdropClick={() => this.props.toggleModal(false)}
            classes={{root: classes.scrollableModal}}
          >
          {/* <Draggable cancel=".not-draggable"> */}
            <div 
              className={classes.draggableContainer}
            >
              <Header
                customStyle={style.viewerHeader}
                color="primary"
                classes={{ container: 'headerContainer', appBar:classes.appBar }}
                noLiPadding={true}
                marginBottom={"-50px !important"}
                links={
                  <List 
                    className="navList removePadding" 
                    style={style.headerLinksContainer}
                  >
                    <ListItem className="navList" classes={{root: 'listSmallPadding'}} style={{ maxWidth:"250px"}}>
                        <div style={{display: 'flex', marginTop: '3px', maxWidth:"270px"}}>
                          <div className='videoTitle'>
                            {this.props.document.name}
                          </div>
                        </div>
                    </ListItem>
                    <ListItem style={{ maxWidth:"250px"}} > 
                      <DocumentTags 
                        className="not-draggable"
                        id="documentModalTagDisplay"
                        documentId={this.props.document.id}/>
                    </ListItem>
                    <ListItem className="navItem" style={{flex:1, justifyContent:"flex-end"}}>
                      
                      <Button 
                        justIcon 
                        className={classes.downloadBtn}
                        onClick={() => downloadFile(this.props.document.downloadLink, this.props.document.id )}>
                        <CloudDownload/>
                      </Button>
                      <span style={{marginRight:"5px"}}>
                        <TagPopper 
                          customButton={() => (
                            <Button size="small" variant="contained" color="primary" classes={{ root: classes.popperbtn  }}>
                              <LocalOffer fontSize="small"/>
                               TAGS
                            </Button>
                          )}
                          className="not-draggable"
                          documentTags={this.props.document.tags} 
                          projectId={projectId}
                          documentId={this.props.document.id}
                          disablePortal
                          enableClickAway
                          />
                      </span>
                      

                      { 
                        this.props.document && 
                        <DocumentStatusDropdown versionId={versionId} status={this.state.status} changeStatus={this.changeStatus.bind(this)}/>
                      }
                      <Close style={style.close} onClick={() => this.props.toggleModal(false)}/>
                    </ListItem>
                  </List>
                }
              />
              
            {
                fileType === 'doc' &&
                <div className={classes.onlyOfficeContentWrapper}>
                  <OnlyOffice 
                    fileKey={this.props.document.fileKey} 
                    fileName={this.props.document.name} 
                    OnlyOfficeMode='view'
                    OnlyOfficeType='embedded'
                    style={style.onlyOfficeProps}
                  />
                </div>
            }
            {
              fileType === 'pdf' &&
              <div>
                <PdfReader 
                  fileKey={this.props.document.fileKey} 
                  modal
                />
              </div>
            }
            {
                fileType === 'img' &&
                <div className='imgWrapper'>
                  <img alt='anexo' src={this.props.document.fileKey}></img>
                </div>
              }
            </div>
          {/* </Draggable>  */}
        </Modal>
      );
    else
      return <span></span>//<Paperclip onClick={()=> this.setModalOpen(true)}className={classes.paperclip}/>; 
  }
}

DocumentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  attachment: PropTypes.object.isRequired
};

export default withStyles({...modalStyle, ...style})(DocumentModal);
