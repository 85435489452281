import { gql } from 'apollo-boost';

const EDIT_CONTENT_STATUS_MUTATION = gql`
  mutation EDIT_CONTENT_STATUS_MUTATION($id:ID!, $status: String!){ 
    editContentStatus(id:$id, status: $status){
      status
    }
  }
`;

const EDIT_DOCUMENT_STATUS_MUTATION = gql`
  mutation EDIT_DOCUMENT_STATUS_MUTATION($id:ID!, $status: String!){ 
    editDocumentStatus(id: $id , status: $status ) {
      id
      status
    }
  }
`;

const GET_VERSION_WITH_CONTENT_STATUS_QUERY = gql`
  query GET_VERSION_WITH_CONTENT_STATUS_QUERY($versionId: ID!) {
    version(id: $versionId) {
      id
      content{
        id
        status
      }  
    }
  }
`;

const ADD_ATTACHMENTS_MUTATION = gql`
  mutation ADD_ATTACHMENTS_MUTATION($commentId: ID!, $fileKeys: [String]!, $fileNames: [String]!, $fileTypes:[String]!) {
    addDocuments(commentId: $commentId, fileKeys: $fileKeys, fileNames: $fileNames, fileTypes:$fileTypes) {
      id
      name 
      fileKey
      status
    }
  }
`;

export {
  ADD_ATTACHMENTS_MUTATION,
  EDIT_CONTENT_STATUS_MUTATION,
  EDIT_DOCUMENT_STATUS_MUTATION,
  GET_VERSION_WITH_CONTENT_STATUS_QUERY,
}