import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Cropper from "components/Cropper/Cropper.jsx";
import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.defaultImg,
      cropping: false,
    };

    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  static defaultProps = {
    addPhotoButtonText: 'Adicionar Foto',
    ratio: 1,
  }
  
  handleImageChange(e, url, blob, cropping) {
    const { instantUpload } = this.props;

    let reader = new FileReader();
    this.setState({cropping: true})
    if (this.refs.fileInput.value.length === 0) return;
    if (e.target){
      let file = e.target.files[0];
      reader.readAsDataURL(file);
      if( this.props.updateFile  && !instantUpload ) this.props.updateFile(file);
    }
    else {
      this.setState({
        file: blob,
        imagePreviewUrl: url
      }, () => {
        if( this.props.updateFile) this.props.updateFile(blob);
        this.setState({cropping: this.state.cropping  && !url ? true  : false, file: null})
        
      });
    }

    reader.onloadend = () => {
      this.setState({
        file: blob ? blob : url,
        imagePreviewUrl: reader.result
      }, () => {
        this.setState({cropping: this.state.cropping  && !url ? true  : false})
      });
    };
    
  }

  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? this.props.avatar : defaultImage
    });
    this.refs.fileInput.value = null;
  }
  handleCancel = () => this.setState({cropping: false, file: null, imagePreviewUrl: this.props.avatar ? this.props.defaultImg : defaultAvatar})
  render() {
    var {
      avatar,
      addButtonProps,
      changeButtonProps,
      removeButtonProps,
      classes,
      ratio,
      addPhotoButtonText,
      instantUpload,
    } = this.props;

    const { imagePreviewUrl, cropping } = this.state;
    const rectangle = {borderRadius: 0, maxWidth: '200px', height: '88px', marginLeft: '-30px'}
    return (
      <div className={"fileinput text-center " + classes.fileInput}>
          <input type="file" onChange={this.handleImageChange} ref="fileInput" />
          <div className={"thumbnail" + (avatar ? " img-circle " : " ") + classes.imgUpload} style={(ratio === 1 || imagePreviewUrl === this.props.defaultImg) ?  {} : rectangle}>
            <img src={instantUpload ? this.props.defaultImg : this.state.imagePreviewUrl} alt="..." />
          </div>
          <div>
            {this.state.file === null ? (
              <Button {...addButtonProps} onClick={() => this.handleClick()}>
                {addPhotoButtonText}
              </Button>
            ) : (
              <span style={{display: 'flex', marginLeft: '-30px'}} className={classes.buttonWrapper}>
                 {imagePreviewUrl && cropping && <Cropper
                  standalone
                  width='150'
                  height='150'
                  ratio={ratio}
                  image={ imagePreviewUrl }
                  handleSave={ this.handleImageChange.bind(this)} /*this.handleSave*/
                  handleCancel={ this.handleCancel.bind(this) } /*this.handleCancel*/
                  handleSmallSize={ (c) => c} /*this.handleSmallSize*/
                  handleGoodSize={ (c) => c} /*this.handleGoodSize*/
                /> }
                {!instantUpload && 
                <>
                <Button {...changeButtonProps} onClick={() => this.handleClick()}>
                  Alterar
                </Button>
                {avatar ? <br /> : null}
                <Button
                  {...removeButtonProps}
                  onClick={() => this.handleRemove()}
                >
                  <i className="fas fa-times" /> Remover
                </Button>
                </>
                }
              </span>
            )}
          </div>
        </div>
    )
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  updateFile: PropTypes.func,
  classes: PropTypes.object,
  img: PropTypes.string,
  defaultImg: PropTypes.string,
  addPhotoButtonText: PropTypes.string,
};

export default ImageUpload;
