export default {
  root: {
    height: '230px',
    width: '90%',
    margin: '20px auto',
    backgroundColor: 'white',
    borderRadius: '8px',
    border: '1px #373C4A dashed',
    padding: '20px',
    cursor: 'pointer',
  },
  body: {
    marginRight: 'auto',
    textAlign: 'center',
    lineHeight: '38px',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '16px',
    fontWeight: '500',
    marginTop: '20px',
    pointerEvents: 'none'
  },
  input: {
    display: 'none',
    pointerEvents: 'none'
  },
}