import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
import Chartist from "chartist";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";

// core components
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";

class AdminCharts extends React.Component {
  render() {
    const { classes, series, labels, title } = this.props;
    const chartData = {
      labels,
      series: [series]
    };
    const chartOptions = {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 10
      }),
      axisX: {
        showGrid: true
      },
      low: 0,
      high: series[series.length - 1] + 10,
      showPoint: true,
      scaleMinSpace: 20,
      onlyInteger: true,
      height: "250px"
    };
    return (
      <GridItem md={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Timeline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
            <ChartistGraph
              data={chartData}
              type="Line"
              options={chartOptions}
            />
          </CardBody>
        </Card>
      </GridItem>
    );
  }
}

export default withStyles(chartsStyle)(AdminCharts);
