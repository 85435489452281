const style = {
    audioContent: {
        height: "auto",
        maxHeight: "100%",
        width: "auto",
        maxWidth: "90%",
        marginTop: "300px",
        marginLeft: "auto",
        marginRight: "auto",
        verticalAlign: "middle",

    }
}
export default style