import { gql } from "apollo-boost";
import moment from "moment";
import axios from "axios";
import client from "index.js";

let formatFilename = filename => {
  const date = moment().format("YYYYMMDD");
  const randomString = Math.random()
    .toString(36)
    .substring(2, 7);
  const cleanFileName = filename.toLowerCase().replace(/[^a-z0-9]/g, "-");
  const newFilename = `files/${date}-${randomString}-${cleanFileName}`;
  return newFilename.substring(0, 60);
};

let submitFile = async (file, onProgressCallback, onSuccessCallback) => {
  // TODO: Better error handling
  try {
    const res = await client.mutate({
      mutation: s3SignMutation,
      variables: {
        name: formatFilename(file.name),
        fileType: file.type
      }
    });
    const { request, fileUrl } = res.data.uploadFile;

    await uploadToS3(file, request, onProgressCallback, onSuccessCallback);

    return fileUrl;
  } catch (e) {
    console.log("Error uploading File: ", e);
  }
};

let uploadToS3 = async (
  file,
  signedRequest,
  onProgressCallback,
  onSuccessCallback
) => {
  const params = {
    headers: {
      "Content-Type": file.type
    },
    onUploadProgress: progressEvent => {
      if (onProgressCallback) {
        onProgressCallback(progressEvent.loaded, progressEvent.total);
        if (onSuccessCallback && progressEvent.total === progressEvent.loaded) {
          onSuccessCallback();
        }
      }
    }
  };
  await axios.put(signedRequest, file, params);
};

const s3SignMutation = gql`
  mutation s3SignMutation($name: String!, $fileType: String!) {
    uploadFile(name: $name, fileType: $fileType) {
      fileUrl
      request
    }
  }
`;

export default submitFile;
