import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./StatusBoxStyle";
import { Link } from "react-router-dom";

const pickColor = status => {
  if (status === "Em Andamento") {
    return "orange";
  }
  if (status === "Em Revisão") {
    return "red";
  }
  if (status === "Versão Final") {
    return "green";
  }
};

const ProjectStatusBox = ({ classes, element }) => {
  let statusColor = "N/A";
  /** Juridical element */
  if (element.juridical && element.content) {
    statusColor = pickColor(element.content.juridicalStatus);
    return (
      <Link to={`/content/juridical-view/${element.content.id}`}>
        <div className={classes.new}>
          <div className={classes.root + " " + classes["status" + statusColor]}>
            {" "}
            {element.content.juridicalStatus}{" "}
          </div>
        </div>
      </Link>
    );
  }
  /** Content element */
  if (element.content) {
    statusColor = pickColor(element.content.status);
    return (
      <Link to={`/content/view/${element.content.id}`}>
        <div className={classes.new}>
          <div className={classes.root + " " + classes["status" + statusColor]}>
            {" "}
            {element.content.status}{" "}
          </div>
        </div>
      </Link>
    );
  }
  /** Not associated element */
  return (
    <div className={classes.new}>
      <div className={classes.root + " " + classes["status" + statusColor]}>
        {" "}
        {""}{" "}
      </div>
    </div>
  );
};

export default withStyles(style)(ProjectStatusBox);
