const style = {
  root: {
    height: "Calc(100% - 100px)",
    width: "100%"
  },
  list: {
    backgroundColor: "white",
    height: "61px",
    width: "100%",
    marginTop: "41px",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.11)",
    marginBottom: "0px"
  },
  body: {
    padding: "0 0",
    minHeight: "80vh",
    height: "98%",
    "& .react-trello-board": {
      backgroundColor: "#eeeeee",
      height: "80vh !important"
    },
    "& .smooth-dnd-container": {
      height: "auto",
      width: "98%"
    },
    "& .smooth-dnd-draggable-wrapper": {
      overflow: "visible !important"
    },
    "& .react-trello-lane": {
      maxHeight: "78vh",
      cursor: "pointer"
    },
    "& .react-trello-card": {
      cursor: "default"
    },
    "& .rt-th:last-child": {
      textAlign: "center !important"
    },
    "& .react-trello-board .smooth-dnd-container:nth-child(2) section": {
      backgroundColor: "#3f2c78 !important",
      "& button": {
        backgroundColor: "#3f2c78 !important"
      }
    }
  },
  listItem: {
    padding: "0 10px !important",
    "&:nth-child(2)": {
      display: "flex",
      justifyContent: "space-between"
    }
  },
  planningTitle: {
    fontFamily: "montserrat, sans-serif",
    fontWeight: "bold",
    fontSize: "16px"
  },
  tabsWrapper: {
    minWidth: "490px",
    maxWidth: "530px"
  },
  stepsCard: {
    height: "100%",
    width: "100%",
    marginTop: "31px"
  },
  steps: {
    height: "Calc(100% - 40px)",
    display: "flex",
    "& .gantt_layout_root > div:first-child >  div:first-child": {
      display: "none"
    },
    "& .gantt_layout_outer_scroll_horizontal": {
      width: "100% !important"
    }
  },
  newEquipPlus: {
    fontSize: "27px"
  },
  newEquip: {
    backgroundColor: "#3F2C78",
    height: "41px",
    minWidth: "142px",
    fontFamily: "Montserrato, sans-serif",
    fontSize: "14px",
    lineHeight: "14px",
    padding: "10px 12px",
    borderRadius: "6px",
    boxShadow: "0 4px 23px 0 rgba(0,0,0,0.12)",
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "#796aa9",
      boxShadow: "none"
    },
    "&:active": {
      backgroundColor: "#3F2C78",
      boxShadow: "none"
    },
    "&:focus": {
      backgroundColor: "#3F2C78",
      boxShadow: "none"
    },
    "& svg": {
      color: "white"
    }
  },
  underline: {
    "&:after": {
      borderColor: "#5A4B88 !important"
    },
    "& input": {
      color: "black"
    }
  }
};

export default style;
