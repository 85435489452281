export default {
  dropDownButtonText: {
    fontFamily: "Roboto, sans-serif !important",
    fontWeight: "600",
    fontSize: '14px'
  },
  dropDown: {
    backgroundColor: 'transparent',
    color: '#AAAAAA',
    boxShadow: 'none',
    padding: "10px 5px 5px 0px",
    borderBottom: '1px solid #D2D2D2',
    maxWidth: '400px',
    borderRadius: 0,
    width: '100%',
    marginBottom: '20px',
    textTransform: 'initial',
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: '#AAAAAA',
      boxShadow: 'none',
    },
  },
  buttonTextWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%',
  },
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F9F9F9',
    overflow: 'visible',
    marginTop: '20px',
    marginBottom: '20px'
  },
  inviteWrapper: {
    backgroundColor: 'transparent',
    fontSize: '36px',
    borderRadius:'50%',
    width: '30px',
    height: '30px',
    lineHeight: '16px',
    color: '#aeaeae',
    minWidth: 0,
    minHeight: 0,
    paddingTop: 5,
    marginTop: '10px',
    marginLeft: '-15px'
  },
  invite: {
    marginTop: '0px',
    borderRadius:'50%',
    width: '30px',
    '& svg': {
      height: '20px',
      width: '20px'
    }
  }
}