function compareCreationDateDescending(a, b) {
  return compareCreationDateAscending(a, b) * -1;
}

function compareCreationDateAscending(a, b) {
  const timeA = new Date(a.creationDate);
  const timeB = new Date(b.creationDate);

  let comparison = 0;
  if (timeA > timeB) {
    comparison = -1;
  } else if (timeA < timeB) {
    comparison = 1;
  }
  return comparison;
}

function compareTimeCodeInverse(a, b) {
  return compareTimeCode(a, b) * -1;
}

function compareTimeCode(a, b) {
  const timeA = parseFloat(a.timeStamp);
  const timeB = parseFloat(b.timeStamp);

  let comparison = 0;
  if (timeA > timeB) {
    comparison = 1;
  } else if (timeA < timeB) {
    comparison = -1;
  }
  return comparison;
}

function compareCountDescending(a, b) {
  const timeA = a.count;
  const timeB = b.count;

  let comparison = 0;
  if (timeA > timeB) {
    comparison = -1;
  } else if (timeA < timeB) {
    comparison = 1;
  }
  return comparison;
}

function applyFilter(filterAtribute, filterValue, isFilterOn) {
  if (!isFilterOn) return false;
  if (filterAtribute.length) {
    if (filterAtribute.includes(filterValue)) return true;
    else return false;
  }
  if (filterAtribute === filterValue) return true;
  else return false;
}

export {
  applyFilter,
  compareCountDescending,
  compareTimeCode,
  compareTimeCodeInverse,
  compareCreationDateAscending,
  compareCreationDateDescending
};
