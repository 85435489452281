import { gql } from 'apollo-boost';

const ADD_CONTENT_MUTATION = gql`
    mutation ADD_CONTENT_MUTATION($name: String!, $type: String!, $projectId: ID!, $fileKey: String!, $folderId: ID, $elementId: ID, $phaseId: ID ){
        addContent(projectId:$projectId, name: $name, type: $type, fileKey: $fileKey, folderId: $folderId, elementId: $elementId, phaseId: $phaseId){
            id
            name
            project{
                id
                projectTemplate{
                    id
                    phases
                    elements
                }
            }
            element {
                id
                name
            }
            phase {
                id
                name
            }
        }
    }
`;

const ADD_VERSION_MUTATION = gql`
    mutation ADD_VERSION_MUTATION($name: String!, $contentId: ID!, $fileKey: String!){
        addVersion(contentId:$contentId, name: $name, fileKey: $fileKey){
            id
            name
        }
    }
`;

export {
    ADD_VERSION_MUTATION,
    ADD_CONTENT_MUTATION,
}