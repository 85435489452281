import { gql } from "apollo-boost";

const COMMENT_FRAG = gql`
  fragment commentFrag on Comment {
    id
    timeStamp
    text
    creator {
      id
      name
    }
    creationDate
    version {
      id
      name
    }
    checked
    attachments {
      id
      name
      fileKey
      status
      project {
        id
        documentTags {
          id
          name
          color
        }
      }
    }
    tags
    parent {
      id
      creator {
        id
        name
      }
    }
  }
`;

const VERSION_FRAG = gql`
  fragment versionFrag on Version {
    fileKey
    name
    id
    content {
      status
      id
      type
    }
    count
    creationDate
  }
`;

const ADD_COMMENT_MUTATION = gql`
  mutation addCommentMutation(
    $text: String!
    $versionId: ID!
    $attachmentsIds: [ID]
    $timeStamp: String
    $parentId: ID
    $commentTags: [String]
    $fileKeys: [String]
    $fileNames: [String]
    $fileTypes: [String]
    $canvasDraw: String
    $canvasHeight: Int
    $canvasWidth: Int
  ) {
    addComment(
      text: $text
      versionId: $versionId
      timeStamp: $timeStamp
      attachmentsIds: $attachmentsIds
      parentId: $parentId
      tags: $commentTags
      fileKeys: $fileKeys
      fileNames: $fileNames
      fileTypes: $fileTypes
      canvasDraw: $canvasDraw
      canvasHeight: $canvasHeight
      canvasWidth: $canvasWidth
    ) {
      id
      text
      timeStamp
      canvasDraw
      canvasHeight
      canvasWidth
      parent {
        id
        text
      }
      tags
    }
  }
`;

const ADD_JURIDICAL_COMMENT_MUTATION = gql`
  mutation addJuridicalComment(
    $text: String!
    $versionId: ID!
    $attachmentsIds: [ID]
    $timeStamp: String
    $parentId: ID
    $commentTags: [String]
    $fileKeys: [String]
    $fileNames: [String]
    $fileTypes: [String]
    $canvasDraw: String
    $canvasHeight: Int
    $canvasWidth: Int
  ) {
    addJuridicalComment(
      text: $text
      versionId: $versionId
      timeStamp: $timeStamp
      attachmentsIds: $attachmentsIds
      parentId: $parentId
      tags: $commentTags
      fileKeys: $fileKeys
      fileNames: $fileNames
      fileTypes: $fileTypes
      canvasDraw: $canvasDraw
      canvasHeight: $canvasHeight
      canvasWidth: $canvasWidth
    ) {
      id
      text
      timeStamp
      canvasDraw
      canvasHeight
      canvasWidth
      parent {
        id
        text
      }
      tags
    }
  }
`;

const GET_COMMENTS_QUERY = gql`
  ${COMMENT_FRAG}
  query getComments($versionId: ID!) {
    comments(versionId: $versionId) {
      ...commentFrag
      subComments {
        ...commentFrag
      }
    }
  }
`;

const EDIT_CONTENT_STATUS_MUTATION = gql`
  mutation EDIT_CONTENT_STATUS_MUTATION($id: ID!, $status: String!) {
    editContentStatus(id: $id, status: $status) {
      status
    }
  }
`;
const EDIT_JURIDICAL_STATUS_MUTATION = gql`
  mutation EDIT_JURIDICAL_STATUS_MUTATION($id: ID!, $status: String!) {
    editJuridicalContentStatus(id: $id, status: $status) {
      status
    }
  }
`;

const GET_VERSION_QUERY = gql`
  ${VERSION_FRAG}
  query GET_VERSION_QUERY($versionId: ID!) {
    version(id: $versionId) {
      ...versionFrag
    }
  }
`;

// This query is slow, so separating it makes sense
const GET_VERSION_WITH_LINKS_QUERY = gql`
  query GET_VERSION_WITH_LINKS_QUERY($versionId: ID!) {
    version(id: $versionId) {
      id
      content {
        id
        type
      }
      downloadLink
      reviewLink
    }
  }
`;

const DELETE_VERSION_MUTATION = gql`
  mutation DELETE_VERSION_MUTATION($id: ID!) {
    deleteVersion(id: $id) {
      id
      name
    }
  }
`;

const GET_CONTENT_QUERY = gql`
  ${VERSION_FRAG}
  query GET_CONTENT_QUERY($contentId: ID!) {
    content(id: $contentId) {
      name
      latestVersion {
        ...versionFrag
      }
      type
      status
      juridicalStatus
      folder {
        id
        parentFolder {
          id
        }
      }
      juridicalFolder {
        id
        parentFolder {
          id
        }
      }
      versions {
        ...versionFrag
      }
      project {
        id
        name
        defaultJuridicalFolder {
          id
        }
      }
      finalVersionDependsOn
    }
  }
`;

const deleteVersion = ({ client, id, contentRefetch }) => {
  client.mutate({
    mutation: DELETE_VERSION_MUTATION,
    variables: { id }
  });
  contentRefetch();
};

export {
  GET_CONTENT_QUERY,
  GET_VERSION_QUERY,
  GET_COMMENTS_QUERY,
  ADD_COMMENT_MUTATION,
  ADD_JURIDICAL_COMMENT_MUTATION,
  EDIT_CONTENT_STATUS_MUTATION,
  EDIT_JURIDICAL_STATUS_MUTATION,
  GET_VERSION_WITH_LINKS_QUERY,
  deleteVersion
};
