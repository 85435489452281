import React, { Component } from "react";
import { ListItem, withStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import Dashboard from "./Dashboard";
import Tabs from "components/Tabs/Tabs.jsx";
import Trello from "components/Trello/Trello";
import PanelStyle from "./PanelStyle.js";
import {
  GET_ORG_TRELLO,
  SUBSCRIPTION,
  addBoard,
  addCard,
  deleteBoard,
  deleteCard,
  editBoard,
  moveBoard,
  editCard,
  moveCard
} from "./PanelQueries.jsx";

class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: ["INDICADORES", "PORTFÓLIO"],
      icons: ["bar_chart", "menu", "today"],
      currentTab: "INDICADORES"
    };
  }

  render() {
    const { classes } = this.props;
    const { tabs, icons, currentTab } = this.state;

    return (
      <div className={classes.root}>
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <span className={classes.planningTitle}> Dashboard Central </span>
          </ListItem>
          <ListItem className={classes.listItem + " " + classes.tabsWrapper}>
            <Tabs
              tabs={tabs}
              icons={icons}
              currentTab={currentTab}
              setCurrentTab={tab => this.setState({ currentTab: tab })}
            />
          </ListItem>
        </List>
        <div className={classes.body}>
          {currentTab === tabs[0] && <Dashboard />}
          {currentTab === tabs[1] && (
            <Trello
              BOARD_SUBSCRIPTION={SUBSCRIPTION}
              org={true}
              GET_BOARDS={GET_ORG_TRELLO}
              addBoard={addBoard}
              addCard={addCard}
              editBoard={editBoard}
              editCard={editCard}
              moveCard={moveCard}
              moveBoard={moveBoard}
              deleteBoard={deleteBoard}
              deleteCard={deleteCard}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(PanelStyle)(Panel);
