import React, { useState } from "react";
import WithStyle from "@material-ui/core/styles/withStyles";
import Modal from 'components/ModalWrapper/ModalWrapper';

import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";
import UserInviteModalStyle from './OrgInviteModalStyle';
import { withApollo } from "react-apollo";
import OrgInvite from 'components/Organization/NewOrgInviteModal/OrgInvite.jsx';
import Switch from "@material-ui/core/Switch";
import { SEND_NEW_ORG_EMAIL_MUTATION } from "./orgInviteQueries";
import ExpandableInfo from "components/ExpandableInfo/ExpandableInfo";
import ProjectRole from "components/ProjectRole/ProjectRole";
import SwitchesTableStyle from "components/SwitchesTable/SwitchesTableStyle";

async function sendEmails({ client, sentEmails, onClose, projects, successAlert }){
  if(client && sentEmails){
    await Promise.all(sentEmails.map(async (emailVars) => {
      if(emailVars){ 
        const projectsIds = projects.map(project => project.project.id);
        const rolesIds = projects.map(project => project.role.id);
        return (
          await client.mutate({ 
            mutation: SEND_NEW_ORG_EMAIL_MUTATION, 
            variables: {...emailVars, projectsIds, rolesIds} 
          })
        )
      }
    }))
    onClose();
    successAlert();
  }
}

const InviteModal = ({ 
  classes, 
  open, 
  onClose = {}, 
  modalName = 'Convidar Organização', 
  client,
  successAlert
}) => {
  const [ sentEmails, setSentEmails ] = useState([]);
  const [ projects, setProjects ] = useState([]);
  const [ switchChecked, setSwitchChecked ] = useState(false);
  
  const texts = [{
    text: 'Organização é uma empresa externa que pode colaborar nos seus projetos ! Como por exemplo uma Produtora Independe, Canal de TV, agência de comunicação entre outros',
    title: 'O que é uma Organização'
  }]
  
  return (
    <Modal 
      open={open}
      onClose={() => onClose()}
    >
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.headerExpander}>
            <span className={classes.modalName}> {modalName} </span>
            <Close 
              onClick={() => onClose()}
              classes={{root: classes.close}}
              />
          </div>
        </div>
        <ExpandableInfo classes={{expandable: classes.expandable}} title='Sobre a Organização' texts={texts}/>
        <div className={classes.body}>
          <OrgInvite 
            classes={classes} 
            setSentEmails={async (emailVars) => { await setSentEmails(emailVars) }} 
          />
          <div className={classes.switchForm}>
            <div> Deseja dar acesso a algum projeto existente? </div>
            <Switch
              checked={switchChecked}
              onChange={() => setSwitchChecked(!switchChecked)}
              value="checkedA"
              classes={{
                icon: classes.switchIcon,
                switchBase: classes.switchBase,
                bar: classes.switchBarRegular,
                root: classes.switchChecked,
                checked: classes.switchChecked,
              }}
            />
          </div>
        </div>
        {switchChecked && <ProjectRole setProjects={projects => setProjects(projects)} client={client}/>}
        <div className={classes.footer}>
          <Button 
            color="transparent"
            onClick={() => onClose()}
          >
            Cancelar
          </Button>
          <Button 
            color="purple"
            onClick={() => sendEmails({ projects, client, sentEmails, onClose, successAlert })} 
          >
            Enviar
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default withApollo(WithStyle({...UserInviteModalStyle, ...SwitchesTableStyle})(InviteModal));