import { gql } from "apollo-boost";

const GET_ROLES_QUERY = gql`
  query GET_ROLES_QUERY {
    roles {
      id
      name
      higherThanUser
    }
  }
`
const SEND_NEW_USER_EMAIL = gql `
  mutation SEND_NEW_USER_EMAIL($destination: String!, $roleId: ID!, $projectId: ID, $groupId: ID) {
    sendNewUserEmail(destination: $destination, roleId:$roleId, projectId: $projectId, groupId: $groupId)
  }
`;

const sendEmail = async({client, params}) => {
    let { data } = await client.mutate({
        mutation: SEND_NEW_USER_EMAIL,
        variables: {
            destination: params.destination,
            roleId: params.roleId,
            groupId: params.groupId,
            projectId: params.projectId
        }
    })
    return data.sendNewUserEmail === "Ok!";
}
export {
  GET_ROLES_QUERY,
  sendEmail
}