import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { pdfjs, Document, Page } from "react-pdf";
import PdfReaderStyle from "./PdfReaderStyle";
import animateScrollTo from "animated-scroll-to";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import DocumentLoader from "components/Loading/DocumentLoader";

import "react-pdf/dist/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true
};

class PdfReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: [],
      numPages: null,
      zoom: 1,
      viewBottom: 10,
      fixedLoading: true
    };
    this.canvasWrapper = React.createRef();
    this.pageWrapper = React.createRef();
    this.viewWrapper = React.createRef();
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  setPointer = e => {
    const { setCanvas, commentClick } = this.props;
    if (!setCanvas) return;
    if (commentClick) return;
    const bounding = this.canvasWrapper.getBoundingClientRect();
    let currentCanvasDimensions = {
      width: bounding.width,
      height: bounding.height
    };
    const newMarker = {
      x: e.clientX + -1 * bounding.left - 15,
      y: e.clientY + -1 * bounding.top - 15
    };

    this.setState({ markers: newMarker });
    setCanvas(newMarker, currentCanvasDimensions);
  };

  getRatios() {
    const bounding = this.canvasWrapper && 
                      this.canvasWrapper.getBoundingClientRect ? 
                        this.canvasWrapper.getBoundingClientRect() : null;
    if(!bounding)
      return { xRatio: 1, yRatio: 1 };
    const { canvasDimensions } = this.props;        
    const ogCanvasHeight = canvasDimensions ? canvasDimensions.height : bounding.height;
    const ogCanvasWidth = canvasDimensions ? canvasDimensions.width : bounding.width;
    const xRatio = (bounding.width / ogCanvasWidth);
    const yRatio = (bounding.height / ogCanvasHeight);
    return { xRatio, yRatio }
  }

  getMarkers = () => {
    const { markers } = this.state;
    const { classes, commentCanvas, commentClick } = this.props;        
    const { xRatio, yRatio } = this.getRatios();

    if (!commentClick && Object.keys(markers).length > 0){
      return (
        <div
          className={classes.ballMarker}
          style={{ top: markers.y * yRatio, left: markers.x * xRatio }}
        />
      );
    }
    if (commentCanvas && commentCanvas.x && commentClick) {
      if (Object.keys(markers).length === 0 && markers.constructor === Object)
        this.setState({ markers: {} }); // if obj is empty
      return (
        <div
          className={classes.ballMarker}
          style={{
            top: commentCanvas.y * yRatio,
            left: commentCanvas.x * xRatio
          }}
        />
      );
    }
  };

  componentDidMount() {
    const viewBoundingRec = this.viewWrapper.getBoundingClientRect
      ? this.viewWrapper.getBoundingClientRect()
      : null;
    this.setState({
      viewBottom: viewBoundingRec ? viewBoundingRec.bottom : 10
    });
  }

  componentDidUpdate(prevProps) {
    const { commentCanvas, showCanvas } = this.props;
    const pageBoundingRec = this.pageWrapper.getBoundingClientRect ? this.pageWrapper.getBoundingClientRect() : null;
    const viewBoundingRec = this.viewWrapper.getBoundingClientRect ? this.viewWrapper.getBoundingClientRect() : null;
    const { yRatio } = this.getRatios();
    if (pageBoundingRec && pageBoundingRec.width 
      && this.state.pageWidthInPx !== pageBoundingRec.width) 
      this.setState({ pageWidthInPx: pageBoundingRec.width, viewBottom: viewBoundingRec ? viewBoundingRec.bottom : 10  })
    if (showCanvas !== prevProps.showCanvas) this.setState({markers:  []});
    if (prevProps.commentCanvas !== commentCanvas) {
        const scrollTo = commentCanvas ? commentCanvas.y*yRatio : 0;
        const options = {
            elementToScroll: document.querySelector('#marker'),
            maxDuration: 1000,
            minDuration: 350,
            speed: 1000,
            verticalOffset: scrollTo === 0 ? 0 : -200,
        };
        setTimeout( () => animateScrollTo(scrollTo, options), 500)
    }
    const docViewWrapper = document.querySelector("#marker");
    if (docViewWrapper)
      if (docViewWrapper.clientHeight !== this.state.docViewHeight)
        this.setState({ docViewHeight: docViewWrapper.clientHeight });
  }

  render() {
    const {
      numPages,
      pageWidthInPx,
      loadingComplete,
      docViewHeight
    } = this.state;
    const { fileKey, showCanvas, classes, modal } = this.props;

    let canvasStyle;
    let documentClassName = "";
    if (modal) {
      canvasStyle =
        classes.modalDocumentWrapper + " " + classes.documentWrapper;
    } else {
      canvasStyle = classes.documentWrapper;
    }
    const shouldUseFixedLoading = numPages && numPages > 8;
    if (!loadingComplete && shouldUseFixedLoading)
      documentClassName = classes.displayNone;

    return (
      <div style={{ position:"relative" }} ref={(el) => this.viewWrapper = el}>
        {/* <span className={classes.zoomWrapper} >
          <ZoomInIcon fontSize="large" className={classes.zoomRight} onClick={() => this.setState((state) => ({ zoom: state.zoom + 0.1 }))}/> 
          <ZoomOutIcon fontSize="large" className={classes.zoomLeft} onClick={() => this.setState((state) => ({ zoom: state.zoom - 0.1 }))}/>
        </span>
         */}
        <div id="marker" className={canvasStyle}>
          {showCanvas && this.getMarkers()}
          <div
            ref={el => (this.canvasWrapper = el)}
            className={classes.markerWrapper}
          >
            <div
              onClick={this.setPointer}
              id="mark"
              className={classes.markerCanvas}
            />
            {shouldUseFixedLoading && (
              <DocumentLoader
                onLoadComplete={() => this.setState({ loadingComplete: true })}
              />
            )}
            <Document
              file={fileKey}
              onLoadSuccess={this.onDocumentLoadSuccess}
              options={options}
              className={documentClassName}
              loading={
                <div
                  style={{ height: docViewHeight ? docViewHeight : "600px" }}
                  className={classes.documentDataLoading}
                >
                  <p>Carregando o documento...</p>
                </div>
              }
            >
              {Array.from(new Array(numPages), (el, index) => (
                <div
                  ref={el => (this.pageWrapper = el)}
                  style={{ paddingBottom: "20px" }}
                >
                  <Page
                    className={this.state.zoom < 1 ? classes.centeredPage : ""}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={pageWidthInPx}
                    scale={this.state.zoom}
                  />
                </div>
              ))}
            </Document>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(PdfReaderStyle)(PdfReader);
