import { gql } from "apollo-boost";

const ADD_REPLY_MUTATION = gql`
  mutation ADD_REPLY_MUTATION($parentId: ID!, $text: String!) {
    addReply(parentId: $parentId, text: $text) {
      id
      text
    }
  }
`;

const COMMENT_FRAG = gql`
  fragment commentFrag on Comment {
    id
    canvasDraw
    canvasHeight
    canvasWidth
    attachments {
      id
      name
      fileKey
      downloadLink
      status
      project {
        id
      }
      tags {
        id
        name
        color
      }
    }
    checked
    timeStamp
    text
    tags
    creator {
      id
      name
      image {
        id
        imageUrl
      }
    }
    creationDate
    version {
      id
      name
      count
    }
  }
`;

const GET_ALL_COMMENTS_BY_VERSIONS = gql`
  ${COMMENT_FRAG}
  query GET_ALL_COMMENTS_BY_VERSIONS($versionId: ID!) {
    version(id: $versionId) {
      id
      content {
        id
        versions {
          id
          name
          count
          juridicalComments {
            ...commentFrag
            parent {
              id
              creator {
                id
                name
              }
            }
            subComments {
              ...commentFrag
              parent {
                id
              }
            }
          }
          comments {
            ...commentFrag
            parent {
              id
              creator {
                id
                name
              }
            }
            subComments {
              ...commentFrag
              parent {
                id
              }
            }
          }
        }
      }
    }
  }
`;

const MY_USER_QUERY = gql`
  query MY_USER_QUERY {
    myUser {
      id
    }
  }
`;

const EDIT_COMMENT = gql`
  mutation EDIT_COMMENT($id: ID!, $text: String, $checked: Boolean) {
    editComment(id: $id, text: $text, checked: $checked) {
      id
      text
      checked
    }
  }
`;

const DELETE_COMMENT = gql`
  mutation DELETE_COMMENT($id: ID!) {
    deleteComment(id: $id) {
      id
    }
  }
`;

function mutateEditComment(text, id, versionId, client) {
  client.mutate({
    mutation: EDIT_COMMENT,
    variables: {
      id,
      text
    },
    refetchQueries: () => [
      { query: GET_ALL_COMMENTS_BY_VERSIONS, variables: { versionId } }
    ]
  });
}

function mutateDeleteComment(id, versionId, client) {
  client.mutate({
    mutation: DELETE_COMMENT,
    variables: {
      id
    },
    refetchQueries: () => [
      { query: GET_ALL_COMMENTS_BY_VERSIONS, variables: { versionId } }
    ]
  });
}

async function mutateReply(target, parentId, versionId, client) {
  let text = target.value;
  client.mutate({
    mutation: ADD_REPLY_MUTATION,
    variables: {
      text,
      parentId
    },
    refetchQueries: () => [
      { query: GET_ALL_COMMENTS_BY_VERSIONS, variables: { versionId } }
    ]
  });
  target.value = "";
}

export {
  EDIT_COMMENT,
  MY_USER_QUERY,
  DELETE_COMMENT,
  ADD_REPLY_MUTATION,
  GET_ALL_COMMENTS_BY_VERSIONS,
  mutateReply,
  mutateEditComment,
  mutateDeleteComment
};
