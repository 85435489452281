export default {
  root: {
    height: '100%',
    '& .gantt_add': {
      display: 'none',
    },
    '& .gantt_marker .gantt_marker_content': {
      fontSize: 14,
      backgroundColor: 'red',
      opacity: '0.5'
    },
    '& .gantt_task_line': {
      backgroundColor: '#8071ad',
    },
    '& .gantt_task_progress': {
      backgroundColor: '#3f2c78'
    },
    '& .gantt_grid_head_add': {
      display: 'none'
    },
    '& .gantt_layout_root': {
      borderBottomRightRadius: "6px",
      borderBottomLeftRadius: "6px",
      boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
      marginBottom: '20px',
    },
    '& .gantt_task_scale':{
      borderTop: "1px solid #d7d7d7"
    },
    "& .gantt_data_area":{
      overflowY:"auto !important"
    }
  },
  microViewToggle:{
    cursor: "pointer"
  },
  ganttHeader: {
    height: 50,
    justifyContent: "space-between",
    marginBottom: "-32px",
    marginTop: "30px",
    zIndex: 99,
    backgroundColor: "white",
    paddingLeft: 30,
    fontWeight: 400,
    display: 'flex',
    paddingTop: "15px",

    boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px #3f2c7766',
    position: "relative",
    borderRadius: "6px",
    '& .openIcon': {
      marginTop:"-7px",
      marginLeft:"-16px",
      width: 30,
      fontSize: 18,
      color: '#3f2c78'
    },

  },

  rbcbutton: {
    color: "#373a3c",
    display: "inline-block",
    margin: 0,
    textAlign:"center",
    verticalAlign: "middle",
    background: "none",
    backgroundImage: "none",
    border: "1px solid #ccc",
    padding: ".375rem 1rem",
    borderRadius: "4px",
    lineHeight: "normal",
    whiteSpace: "nowrap",
    marginTop: "-5px",
    '&:active':{
      backgroundImage: "none",
      boxShadow: "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
      backgroundColor: "#e6e6e6",
      borderColor: "#adadad",
      '&:hover':{
        color: "#373a3c",
        backgroundColor: "#d4d4d4",
        borderColor:" #8c8c8c",
      },
      '&:focus':{
        color: "#373a3c",
        backgroundColor: "#d4d4d4",
        borderColor: "#8c8c8c",
      }
    },
    '&:hover':{
      color: "#373a3c",
      backgroundColor: "#e6e6e6",
      borderColor: "#adadad"
    },
    '&:focus':{
      color: "#373a3c",
      backgroundColor: "#e6e6e6",
      borderColor: "#adadad",
    }
  },
  activeScale:{
    backgroundImage: "none",
    boxShadow: "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
    backgroundColor: "#e6e6e6",
    borderColor: "#adadad",
  },
  btnGroup:{
    display: "inline-block",
    marginRight: "15px",
    whiteSpace: "nowrap",
    '& button:not(:first-child):not(:last-child)':{
      borderRadius: 0,
    },
    '& button:first-child:not(:last-child)':{
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    '& button:last-child:not(:first-child)':{
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    }
  },

}
