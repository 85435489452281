export default {
  dropDownButtonText: {
    fontFamily: "Roboto, sans-serif !important",
    fontWeight: "300",
    color: 'black',
    fontSize: '14px',
  },
  dropDown: {
    backgroundColor: 'transparent',
    color: '#AAAAAA',
    boxShadow: 'none',
    padding: "10px 5px 5px 0px",
    borderBottom: '1px solid #D2D2D2',
    maxWidth: '400px',
    borderRadius: 0,
    width: '100%',
    marginBottom: '20px',
    marginTop: '8px',
    textAlign: 'left',
    textTransform: 'initial',
    display: 'block !important',
    paddingTop: '22px',
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: '#AAAAAA',
      boxShadow: 'none',
    },
  },
  buttonTextWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  caret: {
    marginTop: '5px'
  },
  dropDownError: {
    backgroundColor: 'red',
    color: 'red',
    boxShadow: 'none',
    padding: "10px 5px 5px 0px",
    borderBottom: '1px solid #D2D2D2',
    maxWidth: '400px',
    borderRadius: 0,
    width: '100%',
    marginBottom: '20px',
    textTransform: 'initial',
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: '#AAAAAA',
      boxShadow: 'none',
    },
  },
  dropDownNoMargin: {
    marginBottom: '5px',
  },
}