import React, { Component } from 'react';

import { GET_DOCUMENTS_FROM_PROJECT } from 'views/Juridical/DocumentsQueries';
import { Query } from 'react-apollo';
import AttachmentTable from 'components/AttachmentTable/AttachmentTable';
import { withStyles, Button, InputAdornment } from '@material-ui/core';
import DocumentsStyle from "./DocumentsStyle.js";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Loading from 'components/Loading/Loading';
import UploadDocuments from "./UploadDocuments";
import { AddCircle, Search } from "@material-ui/icons";
import DocumentTagsFilter from "components/DocumentTags/DocumentTagsFilter.jsx";
import StandaloneTagCreator from 'components/DocumentTags/StandaloneTagCreator';


class Documents extends Component {
  
  constructor (props) {
    super(props);
    this.state = {
      search: '',
      uploadOpen: false,
      tagFilter:"",
    }
  }

  toggleUploadModal(){
    let uploadOpen = this.state.uploadOpen;
    this.setState({uploadOpen: uploadOpen ? false : true});
  }

  setTagFilter(tag){
    if(this.state.tagFilter === tag) 
      this.setState({ tagFilter: "" })
    else this.setState({ tagFilter: tag })
  }

  filterTags(document, filter){
    if( filter === "" || document.tags.some( tag => (tag && tag.name && tag.name === filter ) ) ) 
      return true;
    else return false;
  }

  applyFiltersToDocuments(documents){
    const { search, tagFilter } = this.state;
    const filtered = documents.filter(doc => {
      if(doc && doc.name) 
        return (doc.name.toUpperCase().includes(search.toUpperCase()) && this.filterTags(doc, tagFilter));
      else return false;
    });
    return filtered;
  }

  setRefetch(refetch){
    if(!this.state.documentsRefetch && refetch) 
      this.setState({ documentsRefetch: refetch });
  }

  render() {
    const { match: { params }, classes, client } = this.props;
    const { search, uploadOpen, tagFilter, documentsRefetch } = this.state;
    const projectId = params.projectId;
    return (
        <div className={classes.root}>
          <h3> Todos os Documentos </h3>
            
            <UploadDocuments 
                classes={classes} 
                projectId={projectId} 
                open={uploadOpen} 
                client={client}
                toggleModal={this.toggleUploadModal.bind(this)}
            />
          <div className={classes.inputWrapper}>
            <span style={{display:"flex"}}>
              <StandaloneTagCreator projectId={projectId} refetch={()=> documentsRefetch ? documentsRefetch() : {}}/>
              <Button 
                onClick={()=>{
                  if(!this.state.uploadOpen)
                    this.toggleUploadModal()
                }}
                classes={{ root: classes.newDocument }} 
              >
                  <span>
                      <AddCircle />
                  </span>
                  <span style={{marginLeft:"5px"}}>
                  Novo
                  </span>
              </Button> 
            </span>
            <CustomInput
              classes={{ labelRoot: classes.labelNormal, formControl: classes.noPaddingInput }}
              labelText={
                <span>
                  Procure por Documento
                </span>
              }
              labelProps={{
                classes: { shrink: classes.shrink }
              }}
              inputProps={{
                style: { minWidth: "370px", marginRight:"75px" },
                value: search,
                onChange: (e) => {
                  this.setState({search: e.target.value});
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Query 
            variables={{ projectId: projectId }}
            query={GET_DOCUMENTS_FROM_PROJECT}
          >
            {({error, data, loading, refetch}) => {
              if (loading) return <Loading/>;
              this.setRefetch(refetch);
              if (error) 
              {
                console.error('error ', error)
                return <h2> error </h2>
              }
              let documents = [];
              if(data && data.project)
                documents = data.project.documents;
              if (documents.length === 0) return <h3>  O projeto não tem documentos em juridico </h3>
              documents = this.applyFiltersToDocuments(documents);

              return (<>
                      <DocumentTagsFilter 
                        tags={data.project.documentTags} 
                        projectId={projectId}
                        handleClick={(tag) => this.setTagFilter(tag.name)}
                        tagFilter={tagFilter}/>                  
                      {
                        documents.length === 0 ?
                        <h3>  Nenhum arquivo com esse nome foi encontrado. </h3>
                        :
                        <AttachmentTable projectId={projectId} standAlone data={documents} documentsRefetch={()=> documentsRefetch ? documentsRefetch() : {}}/>
                      }
                      </>)
            }}
          </Query>
        </div>
    );
  }
}

export default withStyles(DocumentsStyle)(Documents);
