
export default {
  root: {},
  statusgreen: {},
  statusred: {},
  statusorange: {},
  statusgrey: {},
  new: {
      '& $root': {
          backgroundColor: '#fbfbfb',
          color: '#a2a2a2a2',
          height: '45px',
          width: '100%',
          maxWidth: '120px',
          borderRadius: '0px',
          borderLeft: '1px solid #b2b2b2',
          paddingTop: '12px',
          textAlign: 'center',
          fontWeight: 400,
      },
      '& $statusgreen': {
          backgroundColor: 'green',
          color: 'white'
      },
      '& $statusred': {
          backgroundColor: 'red',
          color: 'white'
      },
      '& $statusorange': {
          backgroundColor: 'orange',
          color: 'white'
      },
      '& $statusgrey': {
          backgroundColor: '#e3e3e3',
          color: '#9f9f9f',
      },
  },
  old: {
      '& $root': {
          backgroundColor: '#c6c2d1',
          boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
          height: '25px',
          width: '80%',
          maxWidth: '80px',
          borderRadius: '6px'
      },
  }
};