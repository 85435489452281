import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.jsx";

//#f50057
import {
  blackColor,
  whiteColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.jsx";
export default {
  ...tooltipsStyle,
  card: {
    marginBottom: '40px',
    padding: '20px',
    backgroundColor: '#F9F9F9',
    height: '90%'
  },
  title: {
    fontSize: '18px',
    fontWeight: 'normal',
    marginBottom: '10px',
  },
  divider: {
    width: '98%',
    marginLeft: '1%',
    height: '1px',
    backgroundColor: '#DDDDDD'
  },
  label: {
    fontWeight: 'normal',
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '10px'
  },
  switchWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  switchChecked: {
    "& + $switchBarSmall": {
      backgroundColor: '#3F2C78 !important',
      opacity: '1 !important'
    },
    "& + $switchBarRegular": {
      backgroundColor: '#3F2C78 !important',
      opacity: '1 !important'
    }
  },
  switchBarSmall: {
    ...customCheckboxRadioSwitch.switchBar,
    width: '20px',
    height: '11px',
    marginTop: '-5px',
    marginLeft: '-10px',
    opacity: "1!important",
  },
  switchBarRegular: {
    ...customCheckboxRadioSwitch.switchBar,
    opacity: "1!important",
  },
  switchIcon: {
    boxShadow: "0 1px 3px 1px rgba(" + hexToRgb(blackColor) + ", 0.4)",
    color: whiteColor + " !important",
    border: "1px solid #3F2C78",
    transform: "translateX(-4px)!important"
  },
  switchIconChecked: {
    borderColor: '#3F2C78',
    transform: "translateX(0px)!important"
  },
}