import { gql } from "apollo-boost";
import {
  GET_JURIDICAL_FOLDERS_QUERY,
  GET_CONTENT_FROM_FOLDER_QUERY as GET_CONTENT_FROM_JURIDICAL_FOLDER_QUERY
} from "views/Juridical/JuridicalQueries.js";

// const GET_PROJECT_QUERY = gql`
//   query($projectId: ID!) {
//     project(id:$projectId) {
//       name
//     }
//   }
// `;

// const GET_CONTENT_QUERY = gql`
//   query GET_CONTENT_QUERY($projectId: ID!){
//     contents(projectId:$projectId){
//       id
//       name
//       type
//       status
//       latestVersion{
//         comments{
//           id
//         }
//         name
//         id
//         fileKey
//         count
//         uploader{
//           id
//           name
//         }
//       }
//     }
//   }
// `;
const EDIT_CONTENT_STATUS = gql`
  mutation EDIT_CONTENT_STATUS($id: ID!, $status: String!) {
    editContentStatus(id: $id, status: $status) {
      status
    }
  }
`;

const GET_CONTENT_FROM_FOLDER_QUERY = gql`
  query GET_CONTENT_FROM_FOLDER_QUERY($projectId: ID!, $folderId: ID) {
    contents(projectId: $projectId, folderId: $folderId) {
      id
      name
      type
      status
      phase {
        id
        name
      }
      element {
        id
        name
      }
      juridicalStatus
      juridical
      finalVersionDependsOn
      folder {
        id
      }
      project {
        id
        deliverySchedule {
          id
          phases {
            id
            name
          }
          elements {
            id
            name
          }
        }
      }
      latestVersion {
        comments {
          id
        }
        name
        id
        fileKey
        count
        creationDate
        uploader {
          id
          name
          image {
            id
            imageUrl
          }
        }
      }
    }
  }
`;

const EDIT_CONTENT_MUTATION = gql`
  mutation EDIT_CONTENT_MUTATION(
    $id: ID!
    $name: String
    $elementId: ID
    $phaseId: ID
  ) {
    editContent(
      id: $id
      name: $name
      elementId: $elementId
      phaseId: $phaseId
    ) {
      id
      name
    }
  }
`;

const GET_CONTENT_DOWNLOAD_LINK = gql`
  query GET_CONTENT_DOWNLOAD_LINK($id: ID!) {
    downloadContent(id: $id)
  }
`;

const GET_MANY_CONTENTS_DOWNLOAD_LINK = gql`
  query GET_MANY_CONTENTS_DOWNLOAD_LINK($ids: [ID]!) {
    downloadManyContents(ids: $ids)
  }
`;

const DELETE_MANY_CONTENTS_MUTATION = gql`
  mutation DELETE_MANY_CONTENTS_MUTATION($ids: [ID]!) {
    deleteManyContents(ids: $ids) {
      id
      name
    }
  }
`;

const EDIT_MANY_CONTENTS = gql`
  mutation EDIT_MANY_CONTENTS(
    $ids: [ID!]!
    $folderId: ID
    $juridicalFolderId: ID
    $juridical: Boolean
  ) {
    editManyContents(
      ids: $ids
      folderId: $folderId
      juridicalFolderId: $juridicalFolderId
      juridical: $juridical
    ) {
      id
      name
      juridical
    }
  }
`;

const EDIT_MANY_CONTENTS_STATUS = gql`
  mutation EDIT_MANY_CONTENTS_STATUS($ids: [ID!]!, $status: String!) {
    editManyContentsStatus(ids: $ids, status: $status) {
      id
      name
      status
    }
  }
`;

const CHANGE_JURIDICAL_FOLDER_MUTATION = gql`
  mutation CHANGE_JURIDICAL_FOLDER_MUTATION($id: ID!, $folderId: ID!) {
    editJuridicalContent(id: $id, folderId: $folderId) {
      id
      name
    }
  }
`;

const CHANGE_FOLDER_MUTATION = gql`
  mutation CHANGE_FOLDER_MUTATION($id: ID!, $folderId: ID!) {
    editContent(id: $id, folderId: $folderId) {
      id
      name
    }
  }
`;

const EDIT_FOLDER_MUTATION = gql`
  mutation EDIT_FOLDER_MUTATION($id: ID!, $name: String) {
    editFolder(id: $id, name: $name) {
      id
      name
    }
  }
`;

const GET_ROOT_FOLDERS_QUERY = gql`
  query GET_ROOT_FOLDERS_QUERY($projectId: ID!) {
    rootFolders(projectId: $projectId) {
      id
      name
      contents {
        id
        name
      }
      subFolders {
        id
        name
        parentFolder {
          id
        }
      }
    }
  }
`;

const ADD_FOLDER_MUTATION = gql`
  mutation ADD_FOLDER_MUTATION(
    $projectId: ID!
    $name: String!
    $parentFolderId: ID
  ) {
    addFolder(
      projectId: $projectId
      name: $name
      parentFolderId: $parentFolderId
    ) {
      id
      name
    }
  }
`;

const DELETE_CONTENT_MUTATION = gql`
  mutation DELETE_CONTENT_MUTATION($id: ID!) {
    deleteContent(id: $id) {
      id
      name
    }
  }
`;

const DELETE_FOLDER_MUTATION = gql`
  mutation DELETE_FOLDER_MUTATION($id: ID!) {
    deleteFolder(id: $id) {
      id
      name
    }
  }
`;

const editStatus = ({ params, client }) => {
  client.mutate({
    mutation: EDIT_CONTENT_STATUS,
    variables: { id: params.id, status: params.status },
    refetchQueries: () => [
      {
        query: GET_CONTENT_FROM_FOLDER_QUERY,
        variables: { projectId: params.projectId, folderId: params.folderId }
      }
    ]
  });
};

const moveContentToJuridical = ({ params, client }) => {
  client.mutate({
    mutation: CHANGE_JURIDICAL_FOLDER_MUTATION,
    variables: {
      id: params.contentId,
      folderId: params.folderId,
      juridical: true
    }
  });
};

const moveManyContentsToJuridical = ({ params, client, cb }) => {
  client
    .mutate({
      mutation: EDIT_MANY_CONTENTS,
      variables: {
        ids: params.contentIds,
        juridicalFolderId: params.folderId,
        juridical: true
      },
      refetchQueries: () => {
        return [
          {
            query: GET_JURIDICAL_FOLDERS_QUERY,
            variables: { projectId: params.projectId }
          },
          {
            query: GET_CONTENT_FROM_JURIDICAL_FOLDER_QUERY,
            variables: {
              projectId: params.projectId,
              folderId: params.folderId
            }
          }
        ];
      }
    })
    .then(res => {
      cb(res.errors);
    });
};

const deleteContent = ({ contentId, client, params }) => {
  //const folderId = this.state.foldersData[this.state.expandedRow].id;
  //const { match: { params } } = this.props;
  client.mutate({
    mutation: DELETE_CONTENT_MUTATION,
    variables: { id: contentId },
    refetchQueries: () => [
      {
        query: GET_CONTENT_FROM_FOLDER_QUERY,
        variables: { projectId: params.projectId, folderId: params.folderId }
      },
      {
        query: GET_ROOT_FOLDERS_QUERY,
        variables: { projectId: params.projectId }
      }
    ]
  });
};

const downloadContent = ({ contentId, client, cb }) => {
  client
    .query({
      query: GET_CONTENT_DOWNLOAD_LINK,
      variables: { id: contentId }
    })
    .then(res => {
      cb(res.data.downloadContent);
    });
};

const downloadManyContents = ({ contentsId, client, cb }) => {
  contentsId = contentsId.filter(id => !!id);
  client
    .query({
      query: GET_MANY_CONTENTS_DOWNLOAD_LINK,
      variables: { ids: contentsId }
    })
    .then(res => {
      cb(res.data.downloadManyContents);
    });
};

const deleteManyContents = ({ ids, client, params }) => {
  ids = ids.filter(data => data);
  client.mutate({
    mutation: DELETE_MANY_CONTENTS_MUTATION,
    variables: { ids },
    refetchQueries: () => [
      {
        query: GET_CONTENT_FROM_FOLDER_QUERY,
        variables: { projectId: params.projectId, folderId: params.folderId }
      },
      {
        query: GET_ROOT_FOLDERS_QUERY,
        variables: { projectId: params.projectId }
      }
    ]
  });
};

const editManyContentsMutation = ({ client, params }) => {
  const ids = params.ids.filter(data => data);
  client.mutate({
    mutation: EDIT_MANY_CONTENTS_STATUS,
    variables: { ids: ids, status: params.status },
    refetchQueries: () => [
      {
        query: GET_CONTENT_FROM_FOLDER_QUERY,
        variables: { projectId: params.projectId, folderId: params.folderId }
      },
      {
        query: GET_ROOT_FOLDERS_QUERY,
        variables: { projectId: params.projectId }
      }
    ]
  });
};

const changeManyFolders = ({ client, params }) => {
  const ids = params.ids.filter(data => data);
  client.mutate({
    mutation: EDIT_MANY_CONTENTS,
    variables: { ids: ids, folderId: params.folderId },
    refetchQueries: () => [
      {
        query: GET_CONTENT_FROM_FOLDER_QUERY,
        variables: {
          projectId: params.projectId,
          folderId: params.selectedFolderId
        }
      },
      {
        query: GET_CONTENT_FROM_FOLDER_QUERY,
        variables: { projectId: params.projectId, folderId: params.folderId }
      },
      {
        query: GET_ROOT_FOLDERS_QUERY,
        variables: { projectId: params.projectId }
      }
    ]
  });
};

const addFolder = ({ params, client }) => {
  client.mutate({
    mutation: ADD_FOLDER_MUTATION,
    variables: {
      projectId: params.projectId,
      name: params.folderName ? params.folderName : "Sem Nome",
      parentFolderId: params.parentFolderId
    },
    refetchQueries: () => [
      {
        query: GET_ROOT_FOLDERS_QUERY,
        variables: {
          projectId: params.projectId,
          parentFolderId: params.parentFolderId
        }
      }
    ]
  });
};

const editFolder = ({ params, client }) => {
  client.mutate({
    mutation: EDIT_FOLDER_MUTATION,
    variables: {
      id: params.id,
      name: params.folderName ? params.folderName : "Sem Nome"
    },
    refetchQueries: () => [
      {
        query: GET_ROOT_FOLDERS_QUERY,
        variables: { projectId: params.projectId }
      }
    ]
  });
};

const changeFolder = ({ params, client }) => {
  client.mutate({
    mutation: CHANGE_FOLDER_MUTATION,
    variables: { id: params.contentId, folderId: params.folderId },
    refetchQueries: () => [
      {
        query: GET_ROOT_FOLDERS_QUERY,
        variables: { projectId: params.projectId }
      },
      {
        query: GET_CONTENT_FROM_FOLDER_QUERY,
        variables: {
          projectId: params.projectId,
          folderId: params.selectedFolderId
        }
      },
      {
        query: GET_CONTENT_FROM_FOLDER_QUERY,
        variables: { projectId: params.projectId, folderId: params.folderId }
      }
    ]
  });
};

const deleteFolder = ({ params, client }) => {
  client.mutate({
    mutation: DELETE_FOLDER_MUTATION,
    variables: { id: params.folderId },
    refetchQueries: () => [
      {
        query: GET_ROOT_FOLDERS_QUERY,
        variables: { projectId: params.projectId }
      }
    ]
  });
};

const editContent = ({ params, client }) => {
  client.mutate({
    mutation: EDIT_CONTENT_MUTATION,
    variables: {
      id: params.id,
      name: params.name,
      elementId: params.elementId,
      phaseId: params.phaseId
    },
    refetchQueries: () => [
      {
        query: GET_CONTENT_FROM_FOLDER_QUERY,
        variables: { projectId: params.projectId, name: params.name }
      },
      {
        query: GET_ROOT_FOLDERS_QUERY,
        variables: { projectId: params.projectId }
      }
    ]
  });
};

const CONTENT_SUBSCRIPTION = gql`
  subscription CONTENT_SUBSCRIPTION($folderId: ID!) {
    contentChange(folderId: $folderId) {
      id
      name
      type
      status
      juridicalStatus
      juridical
      folder {
        id
      }
      project {
        id
      }
      latestVersion {
        comments {
          id
        }
        name
        id
        fileKey
        count
        creationDate
        uploader {
          id
          name
          image {
            id
            imageUrl
          }
        }
      }
    }
  }
`;

export {
  addFolder,
  editFolder,
  editStatus,
  editContent,
  deleteFolder,
  changeFolder,
  deleteContent,
  downloadContent,
  changeManyFolders,
  deleteManyContents,
  ADD_FOLDER_MUTATION,
  CONTENT_SUBSCRIPTION,
  downloadManyContents,
  moveContentToJuridical,
  GET_ROOT_FOLDERS_QUERY,
  DELETE_CONTENT_MUTATION,
  editManyContentsMutation,
  moveManyContentsToJuridical,
  GET_CONTENT_FROM_FOLDER_QUERY
};
