import { ListItem, withStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import Tabs from "components/Tabs/Tabs.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Trello from "components/Trello/Trello";
import Gantt from "components/Gantt/Gantt.jsx";
import React, { Component } from "react";
import PlanningStyle from "./PlanningStyle.js";
import Loading from "components/Loading/Loading";
import { editPhases } from "views/Panel/DashboardUtils";
import Calendar from "components/Calendar/Calendar";
import convertDate from "utils/convertDate";
import {
  moveBoard,
  GET_BOARDS_BY_PROJECT,
  deleteBoard,
  deleteCard,
  editBoard,
  moveCard,
  addCard,
  WALL_CHANGE_SUBSCRIPTION,
  addBoard,
  editCard,
  GET_PROJECT_QUERY
} from "./PlanningQueries.js";
import InputDateRangeTable from "components/InputDateRangeTable/InputDateRangeTable.jsx";
import "assets/scss/yearly-calendar.scss";
import { Query, withApollo } from "react-apollo";
import moment from "moment";

class Planning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: ["ETAPAS", "CALENDÁRIO", "TAREFAS"], //, 'RÉGUA'
      icons: ["menu", "today", "view_week", "alarm_clock"],
      currentTab: "ETAPAS",
      currentPhase: "",
      dragStartTask: "",
      dragEndTask: "",
      microView: false
    };
    this.phases = [];
  }

  setCurrentPhase = currentPhase => this.setState({ currentPhase });

  onDragGantt = task => {
    if (!task) return;
    this.setState({
      dragStartTask: moment(task.start_date).format("DD/MM/YYYY"),
      dragEndTask: moment(task.end_date).format("DD/MM/YYYY")
    });
  };

  toggleMicroView() {
    this.setState({ microView: !this.state.microView });
  }

  steps() {
    const {
      classes,
      match: { params }
    } = this.props;
    const { currentPhase, dragEndTask, dragStartTask, microView } = this.state;
    const ganttColor = [
      "rgb(255, 102, 102)",
      "rgb(140, 102, 255)",
      "rgb(255, 140, 102)",
      "rgb(255, 102, 179)",
      "rgb(110, 202, 80)",
      "rgb(102, 102, 255)",
      "rgb(102, 179, 255)"
    ];
    return (
      <Query
        query={GET_PROJECT_QUERY}
        variables={{ projectId: params.projectId }}
      >
        {({ data, loading, error }) => {
          if (loading || !data.project) return <Loading />;
          if (error) {
            console.error("error ", error);
            return null;
          }
          let phases = data.project.schedule.phases.map((phase, index) => ({
            ...phase,
            phaseNumber: index,
            elements: phase.elements.map(element => ({
              ...element,
              phaseNumber: index
            }))
          }));
          this.phases = data.project.schedule.phases;
          let phasesWithSubPhases = [];
          phases.forEach(phase => {
            phasesWithSubPhases.push(phase);
            phase.elements.forEach(element =>
              phasesWithSubPhases.push({ ...element, type: "row" })
            );
          });
          if (currentPhase || currentPhase === 0)
            phases = [
              phases[currentPhase],
              ...phases[currentPhase].elements.map(element => ({
                ...element,
                type: "row"
              }))
            ]; //if has currentPhase only select elements of currentPhase
          if (microView) phases = phasesWithSubPhases;

          const savePhases = phase => {
            const {
              client,
              match: { params }
            } = this.props;
            const _phases = this.phases
              .map(row => {
                if (phase.id === row.id) {
                  return {
                    id: row.id,
                    name: row.name,
                    startDate: moment(phase.startDate).format("DD/MM/YYYY"),
                    endDate: moment(phase.endDate).format("DD/MM/YYYY")
                  };
                }
                return {
                  id: row.id,
                  name: row.name,
                  startDate: row.startDate,
                  endDate: row.endDate
                };
              })
              .filter(row => phase.id === row.id);
            editPhases({
              params: { projectId: params.projectId, phases: _phases },
              client
            });
          };

          phases = phases.filter(
            phase =>
              phase &&
              phase.endDate &&
              (phase.startDate || phase.type === "row")
          );

          const steps = {
            data: phases.map(phase => ({
              id: phase.id,
              type: phase.type,
              text: phase.name,
              start_date:
                phase.type === "row"
                  ? convertDate(phase.endDate)
                  : phase.startDate !== ""
                  ? convertDate(phase.startDate)
                  : new Date(), //if type is row theres no startDate so I create a startDate - 10 endDate
              end_date:
                phase.endDate !== "" ? convertDate(phase.endDate) : new Date(),
              progress: phase.completed ? 1 : 0,
              color: ganttColor[phase.phaseNumber % ganttColor.length]
            }))
          };

          return (
            <GridContainer className={classes.steps}>
              <GridItem
                style={{ height: "100%", padding: "0 8px" }}
                xs={12}
                md={6}
                lg={5}
              >
                <InputDateRangeTable
                  projectId={params.projectId}
                  phases={data.project.schedule.phases}
                  elements={data.project.schedule.elements}
                  setCurrentPhase={this.setCurrentPhase}
                  updateGantt={() => this.setState({ update: phases })}
                />
              </GridItem>
              <GridItem
                style={{ height: "100%", minHeight: "400px" }}
                xs={12}
                md={6}
                lg={7}
              >
                <Gantt
                  setMicroView={() => {
                    this.toggleMicroView();
                  }}
                  microView={microView}
                  onDrag={this.onDragGantt}
                  hideGrid
                  tasks={steps}
                  editRow={(id, startDate, endDate) =>
                    savePhases(id, startDate, endDate)
                  }
                  dragStartTask={dragStartTask}
                  dragEndTask={dragEndTask}
                />
              </GridItem>
            </GridContainer>
          );
        }}
      </Query>
    );
  }

  onDatePicked = date => {
    alert(date);
  };

  yearlyCalendar = data => {
    const { Calendar } = require("react-yearly-calendar");

    if (!data || !data.schedule) return null;

    const phases = data.schedule.phases;
    var date = new window.Date();
    const markedDays = phases.map(phase => {
      return moment(convertDate(phase.startDate)).format("YYYY-MM-DD");
    });
    return (
      <Calendar
        customClasses={{ markedDays }}
        year={date.getFullYear()}
        onPickDate={this.onDatePicked}
      />
    );
  };

  render() {
    const {
      classes,
      match: { params }
    } = this.props;
    const { tabs, icons, currentTab } = this.state;
    const projectId = params ? params.projectId : "";

    return (
      <div className={classes.root}>
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <span className={classes.planningTitle}> Planejamento </span>
          </ListItem>
          <ListItem className={classes.listItem + " " + classes.tabsWrapper}>
            <Tabs
              tabs={tabs}
              icons={icons}
              currentTab={currentTab}
              setCurrentTab={tab => this.setState({ currentTab: tab })}
            />
          </ListItem>
        </List>
        <div className={classes.body}>
          {currentTab === tabs[0] && this.steps()}
          {currentTab === tabs[1] && <Calendar projectId={projectId} />}
          {currentTab === tabs[2] && (
            <Trello
              projectId={projectId}
              moveBoard={moveBoard}
              BOARD_SUBSCRIPTION={WALL_CHANGE_SUBSCRIPTION}
              GET_BOARDS={GET_BOARDS_BY_PROJECT}
              deleteBoard={deleteBoard}
              deleteCard={deleteCard}
              editBoard={editBoard}
              addBoard={addBoard}
              editCard={editCard}
              moveCard={moveCard}
              addCard={addCard}
            />
          )}
          {/* {currentTab === tabs[3] &&
            <div>
              <Query query={GET_PROJECT_QUERY} variables={{projectId: params.projectId}}>
                {({ error, data, loading }) => {
                  if (error) return error;
                  if (loading) return <Loading/>
                  return this.yearlyCalendar(data.project)
                }}
              </Query>
          </div> }*/}
        </div>
      </div>
    );
  }
}

export default withApollo(withStyles(PlanningStyle)(Planning));
