import React, { Component } from 'react';
import { withStyles, Card } from '@material-ui/core';
import InputDateRangeTableStyle from "./InputDateRangeTableStyle";
import ReactTable from "react-table";
import Button from "components/CustomButtons/Button.jsx";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { Delete, MenuOpen, ChevronRight } from "@material-ui/icons";
import SwitchesTableStyle from "components/SwitchesTable/SwitchesTableStyle";
import { withApollo } from 'react-apollo';
import { editPhases, editElements } from 'views/Panel/DashboardUtils';
import StepsDropdown from "components/StepsDropdown/StepsDropdown.jsx";
import BottomButton from 'components/BottomButton/BottomButton';
// import { editElements } from './InputDateRangeTableQueries';
import DatePickerWrapper from 'components/DatePickWrapper/DatePickerWrapper';
import animateScrollTo from 'animated-scroll-to';
import VerificationDialog from "components/VerificationDialog/VerificationDialog";
import moment from 'moment';

class InputDateRangeTable extends Component {
  constructor (props) {
    super(props);
    this.state = {
      switchChecked: [],
      rows: undefined,
      phaseIndex: -1,
      addingPhase: false,
      sweetAlert: false,
      idToDelete: 0
    }
    this.debounce = undefined;
  }

  setSwitchChecked = (id) => {
    const { rows } = this.state;
    let _rows = rows;
      const index = _rows.findIndex(row => row.id === id);
      if (_rows && _rows[index]) {
        _rows[index] = {..._rows[index], completed: !_rows[index].completed, updated:true};
        this.savePhases(_rows.filter(row => row.id === id));
      }
  }

  setText = (data, text) => {
    const id = data.id;
    const { rows } = this.state;
    let _rows = [...rows];
    const index = _rows.findIndex(row => row.id === id);
    if (_rows && _rows[index]) {
      _rows[index] = {..._rows[index], name: text};
      this.setState({rows: _rows});
      if (id !== '') this.savePhases(_rows.filter(row => row.id === id));
    }
  }

  setStartDate = (id, startDate) => {
    const { rows } = this.state;
    let _rows = [...rows];
    const index = _rows.findIndex(row => row.id === id);
    if (_rows && _rows[index]) {
        _rows[index] = {..._rows[index], startDate: startDate};
        this.setState({rows: _rows});
        if (id !== '') this.savePhases(_rows.filter(row => row.id === id));
      }
  }

  setEndDate = (id, endDate) => {
    const { rows } = this.state;
    let _rows = rows;
    const index = _rows.findIndex(row => row.id === id);
    if (_rows && _rows[index]) {
        _rows[index] = {..._rows[index], endDate: endDate};
        this.setState({rows: _rows});
        if (id !== '') this.savePhases(_rows.filter(row => row.id === id));
      }
  }

  removeRow = (id) => {
    let { rows } = this.state;
    let _rows = rows;
    let deleteRows = rows.slice();
    
    const index = _rows.findIndex(row => row.id === id);
    if (_rows && _rows[index]) {
        _rows.splice(index, 1);
        deleteRows[index] = {...deleteRows[index], delete: true}; 
        this.savePhases(deleteRows.filter(row => row.id === id));
        this.setState({rows: deleteRows, sweetAlert: false});
    }
  }

  addRow = () => {
    let { rows } = this.state;
    let _rows = rows;
    this.savePhases(_rows);
  }

  getPhases = (phases) => {
    const { currentStep } = this.state;
    const { updateGantt } = this.props;

    this.setState({addingPhase: false, rows: currentStep ?  phases[this.findPhase(currentStep)].elements : phases})
    if (updateGantt && !currentStep) updateGantt(phases);
  }


  addNew = () => {
    const { phases } = this.props;
    
    const _row = {
      id: '',
      step: '',
      name: '',
      startDate: '',
      endDate: '',
      completed: false,
      elements: [],
    };
    this.setState({ rows: [...phases, _row], addingPhase: true})
    // this.scrollBottom();
  }

  addNewSubPhase = () => {
    const { rows } = this.state;
    const _row = {
      id: '',
      name: '',
      endDate: '',
      completed: false,
      content: null,
    };
    this.setState({ rows: [...rows, _row], addingPhase: true})
    // this.scrollBottom(); 
  }


  getElements = (elements) => { // this function elimates unecessary data (like grpahql typedef)
    return elements.map(element => ({
      id: element.id,
      name: element.name,
      startDate: element.startDate,
      endDate: element.endDate,
      completed: element.completed,
      delete: element.delete
    }))
  }

  scrollBottom = () => {

    const scrollTo = 5000;
    const options = {
        elementToScroll: document.querySelector('.rt-tbody'),
        maxDuration: 1000,
        minDuration: 350,
        speed: 1000,
        verticalOffset: 5000,
    };
    setTimeout( () => animateScrollTo(scrollTo, options), 50)
}


  savePhases = (rows) => {
    const { client, projectId} = this.props;
    const { currentStep, phaseId } = this.state;

    if (currentStep) {
     return editElements({client, params: {projectId, phaseId, elements: this.getElements(rows)}, getPhases: this.getPhases})
    }

    const phases = rows.filter(row => row.name !== 'Selecione' && row.name !== undefined && (row.name !== " " || row.delete)).map(row => ({
      id: row.id,
      name: row.name,
      startDate: row.startDate,
      endDate: row.endDate,
      completed: row.completed,
      delete: row.delete,
    }))
    editPhases({params: {projectId, phases}, client, getPhases: this.getPhases});
  }

  findPhase = (phase) => {
    const { phases } = this.props;
    return phases.map(phase => phase.name).indexOf(phase);
  }

  findPhaseById = (phaseId) => {
    const { phases } = this.props;
    let phase = phases.filter(phase => phase.id === phaseId);
    if(phase[0] === null) 
      throw Error("Selected phase not found within phases prop, either phaseId or phases array is botched")
    return phase[0];
  }

  onClickOpenSteps = (phase, id, rowPosition) => {
    const { phases, setCurrentPhase } = this.props;
    setCurrentPhase(rowPosition);
    this.setState({ addingPhase: false})
    if (!phase) {
      return this.setState({rows: phases, currentStep: undefined, addingPhase:false, phaseId: null})
    }

    const _rows = phases[this.findPhase(phase)].elements.map(row => ({
      id: row.id,
      name: row.name,
      startDate: row.startDate,
      endDate: row.endDate,
      completed: row.completed,
      elements: row.elements
    }));   
    this.setState({currentStep: phase, rows: _rows, phaseId: id});
  }
  
  onClickBottom = () => {
    const { addingPhase, phaseId, rows } = this.state;
    if (addingPhase) return this.setState({addingPhase: false, rows: rows.filter(row => row.id !== "")});
    if (phaseId) return this.addNewSubPhase(phaseId);
    // if (currentStep) return this.onClickOpenSteps(undefined);
    return this.addNew();
  }

  bottomText = () => {
    const { addingPhase, currentStep } = this.state;
    if (addingPhase) return 'Cancelar criação de Etapa';
    if (currentStep) return 'Nova sub-etapa';
    return 'Nova Etapa';
  }

    
  pickBottomIcon = () => {
    const {  addingPhase } = this.state;
    
    if (addingPhase) return 'close';
    // if (currentStep) return 'chevron_left';
    return 'add_circle';
  }
  pickAction = (row) => {
    const { classes } = this.props;
    
    let btn = null;
    if (row.id === '') {
      btn = <div className={classes.action + ' ' + classes.save}> <Button color="purple" onClick={() => this.addRow()}> Salvar </Button> </div>;
    } else {
      btn = <Delete classes={{root: classes.deleted}} onClick={() => this.setState({idToDelete: row.id, sweetAlert: true})}/>;
    }

    return (<Button onClick={() => { }} classes={{ root: classes.actionWrapper  }} color='white' justIcon>{btn}</Button>)
  }

  rowDateSort = (a, b) => {
    let dateComparison = moment(b.obj.startDate, 'DD-MM-YYYY').isAfter(moment(a.obj.startDate, 'DD-MM-YYYY')); 
    if (dateComparison) {
        return -1;
    } else if (!dateComparison) {
        return 1;
    }
    return 0;
  }

  phaseDateSort = (a, b) => {
    let dateComparison = moment(b.startDate, 'DD-MM-YYYY').isAfter(moment(a.startDate, 'DD-MM-YYYY'));
    if(!a.startDate && !b.startDate) return this.rowDateSort(a, b);  
    if (dateComparison) {
        return -1;
    } else if (!dateComparison) {
        return 1;
    }
    return 0;
  }


  renderPhases = () => {
    const { classes, phases } = this.props;
    let { currentStep, rows } = this.state;
    
    if (!rows) {
      rows = phases;
      this.setState({rows})
    }

    return rows.filter(row => !row.delete).map((row, rowPosition) => ({
      step: <StepsDropdown 
          classes={{dropDownButtonText: classes.dropDownButtonText, dropDown: classes.dropdownSteps}} 
          elements={rows.map(row => row.name)} 
          row={row} 
          onClick={this.setText}
          key={'step' + row.id + row.name*9999}
          autoFocus={row.id === '' ? true : false}
        />,
      start: (
      <FormControl classes={{root: classes.formControlDate}} fullWidth>
        <DatePickerWrapper
          id={'start' + row.id}
          key={'start' + row.id + row.name*9999}
          dateFormat="DD/MM/YYYY"
          timeFormat={false}
          defaultValue={row.startDate}
          placeholder='Data de início'
          onChange={ (date) => {
             if (date._isAMomentObject) this.setStartDate(row.id, date.format('DD/MM/YYYY'));
          }}
        />
      </FormControl>
      ),
      end: (
        <FormControl classes={{root: classes.formControlDate}} fullWidth>
          <DatePickerWrapper
            closeOnSelect
            closeOnTab
            id={'end' + row.id}
            key={'end' + row.id + row.name*9999}
            dateFormat="DD/MM/YYYY"
            timeFormat={false}
            defaultValue={row.endDate}
            placeholder='Data de Término'
            onChange={ (date) => {
               if (date._isAMomentObject) this.setEndDate(row.id, date.format('DD/MM/YYYY'));
            }}
          />
        </FormControl>
        ),
      completed: row.id === '' ? '' : <div className={classes.switchWrapper}>  <Checkbox
        checked={row.completed}
        tabIndex={-1}
        onClick={() => this.setSwitchChecked(row.id)}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{
          checked: classes.checked,
          root: classes.checkRoot
        }}
      /></div>,
    open: !currentStep ? (
      <Button onClick={() => this.onClickOpenSteps(row.name, row.id, rowPosition)} classes={{ root: classes.indent, label:{display:"none"}  }} color='white' justIcon>
            <div className={classes.action}>{ <MenuOpen classes={{root:  classes.indent}} />} 
          </div> </Button>) : null,
    action: this.pickAction(row),
    obj: row,
    }))
  }


  render() {
    const { classes, phases } = this.props;
    let { currentStep, rows, idToDelete, sweetAlert } = this.state;
    if (!rows) {
      rows = phases;
      this.setState({rows: phases})
    }
    const data = this.renderPhases().sort(this.phaseDateSort);
    
    let columns = [   
      { accessor: "open", maxWidth: 70, Header: '' },         
      { 
        accessor: "step", Header: 'Nome', minWidth: currentStep ? 200 : 100,
      },
      { accessor: "end", Header:'Entrega', minWidth: 100},
      { accessor: "completed", Header:"Concluído", minWidth: 80},
      { accessor: "action", minWidth: 30},
    ]
    
    if (!currentStep) {
      // columns = [ { accessor: "open", maxWidth: 45, Header: '' }, ...columns]
      columns.splice(2, 0,  { accessor: "start", Header: 'Início', minWidth: 100, maxWidth: 200})
    }
  
    return (
      <Card className={classes.stepsCard + ' ' + classes.tableWrapper}>
        <div className={classes.stepsTree}>
          <div onClick={() => this.onClickOpenSteps(undefined)}>Etapas Macro da Produção </div>  {currentStep && <> <ChevronRight/>  <div> <b> {currentStep} </b> </div> </>}
        </div>
        {sweetAlert && <VerificationDialog
              open={sweetAlert}
              title='Você tem certeza que deseja deletar esta etapa?'
              handleClose={() => this.setState({ sweetAlert: false })}
              onAccept={() => {this.removeRow(idToDelete);}}
          />}
        <ReactTable
          data={data}
          noDataText='Tabela Vazia'
          sortable={false}
          showPagination={false}
          resizable={false}
          getTheadProps={() => {
          return {
              style: {
              height: '10px',
              }
          }
          }}
          getTfootProps={() => {
          return {
              style: {
                boxShadow: 'none'
              }
          }
          }}
          columns={columns}
          minRows={data.length}
          showPaginationBottom={false}
          pageSize={data.length}
        />
            <div className={classes.btnWrapper}> 
              <BottomButton 
              onClick={() => this.onClickBottom()} 
              text={this.bottomText()}
              icon={this.pickBottomIcon()}
            />
          </div>
      </Card>
    );
  }
}

export default withApollo(withStyles({ ...SwitchesTableStyle,...InputDateRangeTableStyle})(InputDateRangeTable));
