import React from "react"
import StatusColor from "./utils/StatusColor.js";
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import { Mutation } from 'react-apollo'
import {
  GET_ALL_COMMENTS_BY_VERSIONS,
} from "views/Comments/CommentQueries.js";
import {
  EDIT_DOCUMENT_STATUS_MUTATION,
} from './AttachmentQueries.js'
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

export default function DocumentStatusDropdown({ status, changeStatus, versionId }){
  return <Mutation mutation={EDIT_DOCUMENT_STATUS_MUTATION} refetchQueries={(versionId ? [{query:GET_ALL_COMMENTS_BY_VERSIONS, variables:{versionId}}]:[])}>
              {(editDocumentStatus) => {
                return(
                  <CustomDropdown
                    classes={{
                      button: 'buttonDropDown', 
                      buttonIcon: 'circleIcon ' + StatusColor(status),
                      iconDropddown: 'iconDropdDown',
                      dropdown: 'statusDropdown'
                    }}
                    buttonIcon={CircleIcon}
                    buttonProps={{style:{alignSelf:"center", backgroundColor: "#1f2229"}}}
                    hoverColor="danger"
                    buttonText={ status }
                    onClick={(e)=> changeStatus(editDocumentStatus, e.text.props.children)}
                    dropdownList={[
                      {
                        icon: <CircleIcon className='buttonOrange' />, 
                        text: <span>
                                Pendente
                              </span>
                      },
                      {
                        icon: <CircleIcon className='buttonGreen' />, 
                        text: <span >
                                Aprovado
                              </span>
                      },
                    ]}
                  />
                );
              }}
            </Mutation>
}
