import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Person from "@material-ui/icons/Person";
import PersonOutline from "@material-ui/icons/PersonOutline";

import { InputAdornment, IconButton } from "@material-ui/core";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import defaultUserRegistrationAvatar from "assets/img/defaultUserRegistrationAvatar.png";
import organizationRegistrationStyle from "../organizationRegistrationStyle";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.updateFile = this.updateFile.bind(this);
    this.state = {
      orgType: "",
      orgDescription: "",
      name: "",
      file: null,
      verificationMsgs: []
    };
  }

  sendState() {
    return this.state;
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  isValidated() {
    //TODO:this is a placeholder validation. must be transitioned to better ones
    const { orgType, file, verificationMsgs } = this.state;
    let confirm = true;
    let typeMsg = `Tipo da organização obrigatório`;
    let imgMsg = `Imagem da Organização obrigatória`;
    let addImgErr = false;
    let addTypeErr = false;
    let tempMsgs;

    if (file === null) {
      if (!verificationMsgs.includes(imgMsg)) addImgErr = true;

      confirm = false;
    } else if (verificationMsgs.includes(imgMsg)) {
      tempMsgs = verificationMsgs.filter(msg => !(msg === imgMsg));
      this.setState({ verificationMsgs: tempMsgs });
    }

    if (orgType === "") {
      if (!verificationMsgs.includes(typeMsg)) addTypeErr = true;
      this.setState({ orgTypeState: "error" });
      confirm = false;
    } else if (verificationMsgs.includes(typeMsg)) {
      tempMsgs = verificationMsgs.filter(msg => !(msg === typeMsg));
      this.setState({ verificationMsgs: tempMsgs });
    }
    if (confirm) {
      this.setState({ verificationMsgs: [] });
      return true;
    }

    if (addTypeErr && addImgErr) {
      this.setState({ verificationMsgs: [imgMsg, typeMsg] });
    } else if (addTypeErr) {
      this.setState({ verificationMsgs: [typeMsg] });
    } else if (addImgErr) {
      this.setState({ verificationMsgs: [imgMsg] });
    }

    return false;
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }
  updateFile(file) {
    this.setState({ file: file });
  }

  render() {
    const { classes } = this.props;
    const { name, orgType, orgDescription, image } = this.state;
    return (
      <GridContainer>
        <GridItem sm={7}>
          <GridContainer>
            <GridItem style={{ paddingLeft: "0" }} sm={12}>
              <CustomInput
                success={this.state.nameState === "success"}
                error={this.state.nameState === "error"}
                classes={{ labelRoot: classes.inputLabel }}
                labelText={<span>Nome da organização</span>}
                id="name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: name,
                  onChange: event => this.change(event, "name", "length", 3),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <Person />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </GridItem>
            <GridItem sm={12}>
              <CustomInput
                classes={{ labelRoot: classes.inputLabel }}
                labelText={<span>Descrição</span>}
                id="orgDescription"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  multiline: true,
                  rows: 4,
                  type: "text",
                  value: orgDescription,
                  onChange: event =>
                    this.change(event, "orgDescription", "length", 3),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <PersonOutline />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </GridItem>
            <GridItem sm={12}>
              <CustomDropdown
                buttonText={orgType ? orgType : "Tipo da organização"}
                success={this.state.orgTypeState === "success"}
                error={this.state.orgTypeState === "error"}
                onClick={e => this.setState({ orgType: e })}
                buttonProps={{
                  style: { backgroundColor: "#3F2C78" }
                }}
                classes={{
                  button: classes.dropDown,
                  butonText: classes.dropDownButtonText,
                  buttonTextWrapper: classes.buttonTextWrapper,
                  manager: classes.restrainInputWidth
                }}
                dropdownList={[
                  //todo: make the list not hardcoded
                  "Canal de TV",
                  "Produtora Indepente",
                  "Agência",
                  "Creator",
                  "Outro"
                ]}
              />
            </GridItem>
            {this.state.verificationMsgs
              ? this.state.verificationMsgs.map((errorMsg, index) => (
                  <p key={index} style={{ marginLeft: "20px" }}>
                    {errorMsg}
                  </p>
                ))
              : null}
          </GridContainer>
          <br />
        </GridItem>
        <GridItem xs={5} className={classes.imgUploadWrapper}>
          <ImageUpload
            updateFile={this.updateFile}
            classes={{ imgUpload: classes.imgUpload }}
            defaultImg={image ? image : defaultUserRegistrationAvatar}
            addPhotoButtonText="Logo"
            addButtonProps={{
              color: "purple",
              classes: { root: classes.imageUploadButton + " imgUploadButton" }
            }}
            avatar
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles({
  ...regularFormsStyle,
  ...organizationRegistrationStyle
})(Step3);
