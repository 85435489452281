import { gql } from "apollo-boost";
import moment from "moment";
import axios from "axios";
import client from "index.js";

let formatFilename = filename => {
  const date = moment().format("YYYYMMDD");
  const randomString = Math.random()
    .toString(36)
    .substring(2, 7);
  const cleanFileName = filename.toLowerCase().replace(/[^a-z0-9]/g, "-");
  const newFilename = `images/${date}-${randomString}-${cleanFileName}`;
  return newFilename.substring(0, 60);
};

let submitImage = async (file, context) => {
  let mutationObject = {
    mutation: s3SignMutation,
    variables: {
      name: formatFilename(file.type ? file.type : file.name),
      imageType: file.type
    }
  };

  if (context) mutationObject.context = context;
  // TODO: Better error handling
  try {
    const res = await client.mutate(mutationObject);
    const { request, imageUrl, id } = res.data.uploadImage;

    await uploadToS3(file, request);

    const imageId = id;

    return { imageId, imageUrl };
  } catch (e) {
    console.log("Error uploading image: ", e);
  }
};

let uploadToS3 = async (file, signedRequest) => {
  const params = {
    headers: {
      "Content-Type": file.type
    }
  };

  await axios.put(signedRequest, file, params);
};

const s3SignMutation = gql`
  mutation s3SignMutation($name: String!, $imageType: String!) {
    uploadImage(name: $name, imageType: $imageType) {
      imageUrl
      request
      id
    }
  }
`;

export default submitImage;
