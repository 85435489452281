import React, {Component} from 'react'
import PropTypes from 'prop-types'
import AccessTime from '@material-ui/icons/AccessTime';
import CardStyle from "./styles/Card";
import {
  MovableCardWrapper,
  CardHeader,
  CardRightContent,
  CardTitle,
  Footer,
  DateWrapper,
  Dropdown
} from './styles/Base'
import DeleteButton from './DeleteButton'
import moment from 'moment';
import { withStyles } from '@material-ui/core';

class Card extends Component {
  onDelete = e => {
    this.props.onDelete()
    e.stopPropagation()
  }
  pickColor = (status) => {
    const { classes } = this.props;

    if (status === 'TODO') {
      return classes.purple;
    } else if (status === 'DOING') {
      return classes.borderOrange;
    } else if (status === 'DONE') {
      return classes.borderGreen;
    }
  }
  pickColorDropdown = (status) => {
    const { classes } = this.props;

    if (status === 'TODO') {
      return '';
    } else if (status === 'DOING') {
      return classes.dropdownOrange;
    } else if (status === 'DONE') {
      return classes.dropdownGreen;
    }
  }


  render()  {
    const {
      showDeleteButton,
      style,
      onClick,
      className,
      id,
      title,
      label,
      cardDraggable,
      metadata,
      classes,
      status,
      onChangeStatus,
    } = this.props
    return (
      <MovableCardWrapper
        data-id={id}
        onClick={onClick}
        style={style}
        className={className + ' ' + this.pickColor(status)}
      >
        <CardHeader>
          <CardTitle draggable={cardDraggable}>{title}</CardTitle>
          <CardRightContent>{label}</CardRightContent>
          {showDeleteButton && <DeleteButton onClick={this.onDelete} />}
        </CardHeader>
        <Footer>
          
            <DateWrapper> 
            <AccessTime/>{ metadata && metadata.endDate &&  <div> {moment(metadata.endDate, "DD/MM/YYYY").format('ll').split("de 20")[0]}  </div>}
            </DateWrapper>
        
          {status && <div onClick={(e) => {
                if (e) e.stopPropagation();
              }}>
            <Dropdown
              buttonText={status} 
              dropdownList={['TO-DO', 'DOING', 'DONE']}
              onClick={(status) => {
                onChangeStatus(id, status);
              }}
              classes={{
                button: classes.dropDownButton + ' ' + this.pickColorDropdown(status), 
                dropdown: classes.dropdown,
                butonText: classes.dropDownButtonText, 
                buttonTextWrapper: classes.buttonTextWrapper, 
                manager: classes.restrainInputWidth,
                caret: classes.caret,
                pooperResponsive: classes.pooper,
              }}
            />
          </div>}
        </Footer>
      </MovableCardWrapper>
      )
  }
}

Card.propTypes = {
  showDeleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  style: PropTypes.object,
  tagStyle: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.array,
}

Card.defaultProps = {
  showDeleteButton: true,
  onDelete: () => {},
  onClick: () => {},
  style: {},
  tagStyle: {},
  title: 'no title',
  description: '',
  label: '',
  tags: [],
  className: ''
}

export default withStyles(CardStyle)(Card);
