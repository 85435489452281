import { editProjectMutation } from "queries/queries";
import { gql } from "apollo-boost";

const GET_PROJECTS_QUERY = gql`
  query GET_PROJECTS_QUERY {
    projects {
      name
      id
      startDate
      endDate
      description
      genre
      archived
      owner {
        id
        name
      }
      image {
        id
        imageUrl
      }
      collaborativeOrgs {
        id
        organization {
          id
          name
        }
        role {
          id
          name
          permissions
        }
      }
      organization {
        id
      }
    }
  }
`;
const DELETE_PROJECT_MUTATION = gql`
  mutation DELETE_PROJECT_MUTATION($id: ID!) {
    deleteProject(id: $id) {
      id
    }
  }
`;

const MY_USER_QUERY = gql`
  query MY_USER_QUERY {
    myUser {
      id
      name
      image {
        id
        imageUrl
      }
      role {
        id
        permissions
      }
      organization {
          id
      }
    }
  }
`;

function deleteProject({ projectId, client }) {
  client.mutate({
    mutation: DELETE_PROJECT_MUTATION,
    variables: {
      id: projectId
    },
    refetchQueries: () => [
      {
        query: GET_PROJECTS_QUERY
      }
    ]
  });
}

function archiveProject({ projectId, archived, client }) {
  client.mutate({
    mutation: editProjectMutation,
    variables: {
      projectId,
      archived
    },
    refetchQueries: () => [
      {
        query: GET_PROJECTS_QUERY
      }
    ]
  });
}

export { GET_PROJECTS_QUERY, MY_USER_QUERY, archiveProject, deleteProject };
