export default {
  popperListRoot:{ 
    width:"15vw", 
    height:"20vh", 
    padding:"8px 6px 8px 6px", 
    overflow:"auto !important" 
  },
  popperBtn:{ 
    fontSize:"1em", 
    marginTop:"4px", 
    marginLeft:"5px",
    borderColor:"#6E51C9",
    border:"solid",
    borderWidth: "thin",
    borderRadius:"3px", 
    display:"flex", 
    flexDirection:"column", 
    alignItems:"center", 
    alignContent:"center"
  },
  ellipsisOnOverflow:{
    textOverflow:"ellipsis",
    whiteSpace:"nowrap",
    overflow:"hidden"
  },
  flatTag: {
    width: "fit-content", 
    padding:"1px 6px 3px 6px", 
    borderRadius:"4px", 
    minWidth:"45px", 
    marginLeft:"3px", 
    marginRight:"3px",
    whiteSpace:"nowrap",
    height:"80%",
    display:"flex",
    color:"white",
    fontFamily: "Montserrat,sans-serif",
    fontSize:"0.7em",
  },
  flatTagsWrapper:{ 
    display: "flex", 
    justifyContent:"space-between", 
    padding:"2px 2px 2px 2px", 
    marginLeft:"15px"
  },
  tagListWrapper:{ 
    display: "flex", 
    justifyContent:"space-between" 
  }
}