import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withStyles, TextField } from "@material-ui/core";
import {
  ADD_TAGS_TO_DOCUMENT_MUTATION,
  ADD_DOCUMENT_TAGS_MUTATION,
  GET_TAGS_FROM_PROJECT_QUERY,
  GET_DOCUMENT_WITH_TAGS
} from "views/Juridical/DocumentTagQueries";
import { withApollo, Query } from "react-apollo";
import LocalOffer from "@material-ui/icons/LocalOfferOutlined";
import TagPopperStyle from "./TagPopperStyle";
import CustomPopper from "components/CustomPopper/CustomPopper.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Search } from "@material-ui/icons";
import Loading from "components/Loading/Loading";
import { GET_DOCUMENTS_FROM_PROJECT } from "components/ListAllDocuments/ListAllDocumentsQueries";
import TagList from "components/DocumentTags/TagList.jsx";
import TagCreator from "components/DocumentTags/TagCreator.jsx";

function TagFilter({ handleInput, tagFilter }) {
  const parseEventAndCallHandler = e => {
    handleInput(e.target.value);
  };
  return (
    <TextField
      variant={"outlined"}
      fullWidth={true}
      onMouseDown={e => e.stopPropagation()}
      onChange={parseEventAndCallHandler}
      value={tagFilter}
      InputProps={{
        //inputProps != InputProps, they control diferent layers inside textField
        endAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        )
      }}
      inputProps={{
        style: {
          padding: "10px 4px 10px 4px"
        }
      }}
    />
  );
}

function PopperRoot({
  tags,
  classes,
  disablePortal,
  enableClickAway,
  addTagsToProject,
  handleWithObject,
  addTagsToDocument,
  customButton
}) {
  const [tagCreationOpen, setTagCreationOpen] = useState(false);
  const [tagFilter, setTagFilter] = useState("");
  let togglePopperRef = React.useRef(null);
  const closePopper = () => togglePopperRef.current.click();
  const togglesTagCreation = () => setTagCreationOpen(!tagCreationOpen);
  let popperButton = () => (
    <div ref={togglePopperRef}>
      <Button variant="contained" color="primary">
        <LocalOffer />
      </Button>
    </div>
  );
  if (customButton)
    // eslint-disable-next-line react/display-name
    popperButton = () => <div ref={togglePopperRef}>{customButton()}</div>;

  return (
    <CustomPopper
      disablePortal={disablePortal}
      enableClickAway={enableClickAway}
      buttonComponent={popperButton}
    >
      <Paper className={classes.popperRootPaper}>
        {tagCreationOpen && (
          <TagCreator
            addTagsToProject={addTagsToProject}
            togglesTagCreation={togglesTagCreation}
            onClose={closePopper}
            onBack={togglesTagCreation}
          />
        )}
        {!tagCreationOpen && (
          <div style={{ height: "40vh" }}>
            <TagFilter tagFilter={tagFilter} handleInput={setTagFilter} />
            <TagList
              className={classes.tagList}
              tags={tags.filter(
                tag => tag && tag.name && tag.name.includes(tagFilter)
              )}
              onAddTag={tag => addTagsToDocument(tag)}
              handleWithObject={handleWithObject}
              closePopper={closePopper}
            />
          </div>
        )}
        {!tagCreationOpen && (
          <Button
            style={{ marginTop: "5px" }}
            variant="outlined"
            fullWidth
            onClick={togglesTagCreation}
          >
            Criar nova Tag
          </Button>
        )}
      </Paper>
    </CustomPopper>
  );
}

class TagPopper extends React.Component {
  notifyError(error) {
    let errorMessageForUser = "Erro ao adicionar tag";
    console.error(error);
    if (error.message === "GraphQL error: Tag name already in use.")
      errorMessageForUser =
        "O nome desta Tag já está em uso. Use outro nome ou remova a Tag existente do projeto.";
    window.alert(errorMessageForUser);
  }

  executeMutation({ mutation, variables, refetchQueries, callback }) {
    const { client } = this.props;
    client
      .mutate({ mutation, variables, refetchQueries })
      .then(res => {
        if (res.data && callback) callback(res.data);
      })
      .catch(error => {
        console.log(error);
        this.notifyError(error);
      });
  }

  buildAddTagToDocument(tagIds, projectId) {
    let callback = () => {};
    if (this.props.refetch) callback = this.props.refetch;
    if (tagIds)
      return this.executeMutation({
        mutation: ADD_TAGS_TO_DOCUMENT_MUTATION,
        variables: {
          tagIds: tagIds.length > 0 ? tagIds : [tagIds],
          id: this.props.documentId
        },
        refetchQueries: () => [
          {
            query: GET_DOCUMENTS_FROM_PROJECT,
            variables: { projectId }
          },
          {
            query: GET_DOCUMENT_WITH_TAGS,
            variables: { id: this.props.documentId }
          }
        ],
        callback
      });
  }

  addTagsToDocumentCallback(data, addedTags) {
    const tagIds = data.addDocumentTags.documentTags.reduce((tagIds, tag) => {
      if (
        addedTags.length > 0 &&
        addedTags.map(addedTag => addedTag.name).includes(tag.name)
      )
        tagIds.push(tag.id);
      else if (addedTags.name === tag.name) tagIds.push(tag.id);
      return tagIds;
    }, []);

    this.buildAddTagToDocument(tagIds, this.props.projectId);
  }

  buildAddDocumentTag(tags, projectId) {
    let callback = () => {};
    if (this.props.documentId)
      callback = data => {
        this.addTagsToDocumentCallback(data, tags);
      };
    if (this.props.refetch) callback = this.props.refetch;
    if (tags)
      return this.executeMutation({
        mutation: ADD_DOCUMENT_TAGS_MUTATION,
        variables: {
          tags: tags.length > 0 ? tags : [tags],
          id: this.props.projectId
        },
        refetchQueries: () => [
          {
            query: GET_TAGS_FROM_PROJECT_QUERY,
            variables: { id: projectId }
          }
        ],
        callback
      });
  }

  compareName(a, b) {
    if (!a) return -1;
    if (!b) return 1;
    if (a.name && b.name) {
      if (a.name.trim().toUpperCase() > b.name.trim().toUpperCase()) return 1;
      else return -1;
    }
  }

  render() {
    const { projectId, customAddTagsToDocument, customButton } = this.props;
    const defaultAddTagsToDocument = tagIds =>
      this.buildAddTagToDocument(tagIds, projectId);

    return (
      <Query query={GET_TAGS_FROM_PROJECT_QUERY} variables={{ id: projectId }}>
        {({ data, loading, error }) => {
          if (error) throw new Error(error);
          if (loading) return <Loading />;
          let projectTags;
          if (data.project)
            projectTags = data.project.documentTags.sort(this.compareName);
          return (
            <PopperRoot
              customButton={customButton}
              className="not-draggable"
              tags={projectTags ? projectTags : []}
              addTagsToProject={tags =>
                this.buildAddDocumentTag(tags, projectId)
              }
              addTagsToDocument={
                customAddTagsToDocument
                  ? customAddTagsToDocument
                  : defaultAddTagsToDocument
              }
              {...this.props}
            />
          );
        }}
      </Query>
    );
  }
}

export default withApollo(withStyles(TagPopperStyle)(TagPopper));
