import React, { useState } from "react"
import { withStyles } from "@material-ui/core";
import CollaborationInviteModalStyle from '../CollaborationInviteModalStyle';
import Button from "components/CustomButtons/Button.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import RoleConfig from "components/Role/RoleConfig/RoleConfig";
import ExpandableInfo from "components/ExpandableInfo/ExpandableInfo";
import { SEND_NEW_ORG_EMAIL_MUTATION } from "components/Organization/collaborationInviteModal/CollaborationModalQueries"
import CollaborationInvite from "../../../CollaborationInvite/CollaborationInvite";

async function sendEmail({orgs, projectId, roleId, client, onClose, showAlert}){
  let sentEmails = [];
  if(orgs && projectId && roleId){
    await Promise.all(orgs.map(org => {
      return client.mutate({mutation:SEND_NEW_ORG_EMAIL_MUTATION,
        variables:{
          destination:org.email,
          projectsIds: [projectId],
          rolesIds: [roleId]
        }
      }).then((res) => {
        if(res.data.sendNewOrganizationEmail === "Ok!"){
          sentEmails.push({ destination:org.email, sent:true })
        } else{
          sentEmails.push({ destination:org.email, sent:false })
        } 
      })
    }))
    let confirmed = sentEmails.filter(sentEmail => sentEmail.sent);
    let unconfirmed = sentEmails.filter(sentEmail => !sentEmail.sent);

    if(confirmed.length > 0 && unconfirmed.length > 0) showAlert('Alguns convites não foram enviados', unconfirmed, "danger");
    if(confirmed.length > 0 && unconfirmed.length === 0) showAlert('Todos os convites enviados com sucesso',[] , "success");
    if(confirmed.length === 0 && unconfirmed.length > 0) showAlert('Nenhum convite foi enviado', [], "danger");

    onClose();
  }
  else{
    window.alert("Todos os campos devem ser preenchidos")
  }
}

const Tab1 = ({ classes, onClose, client, roles, projectId, inviteCollab, showAlert}) => {
  const [role, setRole] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const texts = [
    {
      title: 'Gestor de Organização',
      text: 'Principal responsável pela Produção da empresa. Tem acesso total e pode realizar alterações em qualquer projeto da organização'
    },
    {
      title: 'Gerente de Projetos',
      text: 'Responsável pela Gestão de um ou mais Projetos. Pode realizar alterações somente dentro dos Projetos dos quais faça parte.'
    },
    {
      title: 'Outros Cargos',
      text: <div> Caso precise criar novos cargos com outras permissões,  <span className={classes.link} onClick={(e) => {e.preventDefault(); setIsModalOpen(true)}}> clique aqui </span> ou vá à área de Organização e permissões da sua Organização após a criação do projeto.</div>
    }
  ]
 
  let roleList= roles.map((role) => {return( role ? {name: role.name, obj:true, roleId:role.id}: {})})
  return (
    <div>
      {isModalOpen && <RoleConfig open={isModalOpen} toggleModal={() => {setIsModalOpen( !isModalOpen )}} />}
      <div className={classes.scrollableContent}>
        <div className={classes.info}>
          Convide novas organizações para colaborar no seu projeto. 
          <span> Importante: </span> Convide apenas um usuário por organização.
        </div>
        <div style={{ paddingLeft:"0", marginLeft: 'auto', marginRight: 'auto', width: '90%' }}>
          <CollaborationInvite hideAdd setOrgs={setOrgs}/>
        </div>
        <div style={{display:"flex", justifyContent:"center"}}>
          <CustomDropdown
            buttonText={role? role.name :'Nome do Cargo'}
            onClick={(role) => setRole(role)}
            classes={{ button: classes.dropDown, butonText: classes.dropDownButtonText, buttonTextWrapper: classes.buttonTextWrapper }}
            dropdownList={roleList}
          />
        </div>
        <ExpandableInfo 
          title='Sobre Cargos e Convites' 
          texts={texts}
          classes={{expandable: classes.expandable}}
        />
      </div>
      <div className={classes.footerButtonWrapper}>
          <Button 
              color="gray"
              onClick={() => onClose()} 
              >
              Fechar
          </Button>
          <Button 
            color="purple"
            onClick={() => sendEmail({ orgs, roleId: role ? role.roleId : null, client, projectId, onClose, showAlert })}
          >
            Convidar
          </Button>
      </div>
    </div>
  )
}
export default withStyles({ ...regularFormsStyle, ...CollaborationInviteModalStyle })(Tab1);