import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import {
    Brush,
    Description,
    LiveTv,
    Search }  from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import deliveryTemplateStyle from "./DeliveryTemplateStyle.js";

// @material-ui/icons
import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import DateVerification from "utils/DateVerification";
import NumberSelector from "components/NumberSelector/NumberSelector";
import defaultDeliveryPhases from 'utils/phases';

class DeliveryTemplateStep extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
          numEps: this.props.stepProps ? this.props.stepProps.numEps : 6,
          selected: [],
          endDateState: "",
          endDates: {},
          deliveryPhases: this.props.stepProps ? 
          this.props.stepProps.deliveryPhases 
            : 
                defaultDeliveryPhases,
        };
        if( this.props.stepProps && this.props.stepProps.selectedPhases ) {
            this.state.selected = [...this.props.stepProps.selectedPhases];
        } else {
            this.state.selected = [defaultDeliveryPhases[defaultDeliveryPhases.length - 2], defaultDeliveryPhases[defaultDeliveryPhases.length - 1]]
        }

        let _endDates = {};
        for(let phase of this.state.selected){
            _endDates = {..._endDates, [phase]: ""}
        }
        this.state.endDates = _endDates;

    }

    selectIcon( { phase, selected, classes } ) {
        switch( phase ) {
            case defaultDeliveryPhases[3]: 
                return (
                    <div onClick={() => {this.setSelected(phase)}} className={classes.icon}>
                        <Brush className={selected ? classes.iconRose : classes.iconWhite}/>
                    </div>  
                ); 
            case defaultDeliveryPhases[2]:
                return (
                    <div onClick={() => {this.setSelected(phase)}} className={classes.icon}>
                        <LiveTv className={selected ? classes.iconRose : classes.iconWhite}/>
                    </div>  
                ); 
            case defaultDeliveryPhases[1]:
                return (
                    <div onClick={() => {this.setSelected(phase)}} className={classes.icon}>
                        <Search className={selected ? classes.iconRose : classes.iconWhite}/>
                    </div>  
                ); 
            case defaultDeliveryPhases[0]:
                return (
                    <div onClick={() => {this.setSelected(phase)}} className={classes.icon}>
                        <Description className={selected ? classes.iconRose : classes.iconWhite}/>
                    </div>  
                ); 
            default:
                return (
                    <div onClick={() => {this.setSelected(phase)}} className={classes.icon}>
                        <Icon className={selected ? classes.iconRose : classes.iconWhite}> weekend </Icon>
                    </div>  
                ); 
        }
    }

    setSelected( phase ) {
        const { selected } = this.state;
        let { deliveryPhases } = this.state;
        let newSelected = selected;
        if( selected.includes( phase ))
            newSelected = selected.filter( p => p !== phase );
        else 
            newSelected = [...selected, phase];
        
        this.setState({ selected: deliveryPhases.filter(p => newSelected.includes(p)) });
    }

    sendState() {
        return this.state;
    }

    setNumEps( val ) {
        if( val > 500 ) val = 500;
        if( val < 1 ) val = 1;
        this.setState({numEps: val});
    }

    setDate( event, phase ) {
        if ( event.target.value.length >= 5 ) {
            this.setState({ endDateState: "success" });
        } else {
            this.setState({ endDateState: "error" });
        }

        this.setState({ endDates: { ...this.state.endDates, [phase]: event.target.value }});
    }
    
    async componentDidMount(){
        const { endDates } = this.state;
        this.setState({ selected: this.props.stepProps ? this.props.stepProps.selectedPhases : [] })
        if(this.props.stepProps){
            for(let phase of this.props.stepProps.phaseObjects){
                if(!endDates[phase.name] && phase.endDate && phase.endDate !== "") 
                     await this.setDate({target:{value: phase.endDate}}, phase.name)
            }
        }
    }

    isValidated(){
        if(this.state.selected.length > 0) return true;
        else {
            window.alert("Nenhuma entrega foi selecionada")
            return false
        };
    }
    render() {
        const { classes, projectData } = this.props; 
        // projectData is the data pertaining to the project being edited. 
        // included for expansion of editing functionality. 
        let { deliveryPhases } = this.state;
        const { selected, endDateState, numEps } = this.state;
        return (
            <div className={classes.container}>
                <GridContainer justify="left">
                    <GridItem xs={12} sm={12} md={12}>
                        <h2 className={classes.title}>Selecione as entregas do seu projeto</h2>
                        <h5 className={classes.description}>
                            Essas são as entregas que você pode subir na TVManager.
                        </h5>
                    </GridItem>
                    {/* shows or hides number of episodes counter on project editing because 
                        there is no straightforward way to change that right now.
                        we need to implement a way to edit the elements themselves (such as renaming episodes and whatnot)
                        because changing number of episodes without the proper backend handling of the data changes such as 
                        deletingor orphaning the extraneous episodes will blow in our faces
                    */}
                    
                </GridContainer>
                <GridContainer justify="center">
                    {deliveryPhases.map( phase => {
                        let endDate = this.state.endDates[phase];
                        return (
                        <GridItem xs={12} sm={12} md={3}>
                            <Card selection 
                                raised={selected.includes(phase)} 
                            >
                                <CardBody selection 
                                    raised={selected.includes(phase)} 
                                >
                                    { this.selectIcon( { phase, classes, selected: selected.includes(phase) } ) }
                                    <br/>
                                    <Button round color={selected.includes(phase)? "purple" : "white"}
                                        onClick={() => {this.setSelected(phase)}}
                                    >
                                        {phase}
                                    </Button>
                                    <FormControl className={classes.dateInput} >
                                        <Datetime
                                            closeOnSelect
                                            closeOnTab
                                            dateFormat="DD/MM/YYYY"
                                            timeFormat={false}
                                            className={classes.formControlDate}
                                            value={endDate}
                                            onChange={ (date) => {
                                                let formatedDate = DateVerification(date);
                                                if(formatedDate) this.setDate({target: {value: formatedDate}}, phase) 
                                                else if(endDateState !== "error") this.setState({ endDateState: "error" })
                                            }}
                                        />
                                    </FormControl>
                                    <h3 className={`${classes.cardTitleWhite}`} >
                                        <InputLabel 
                                            classes={{root: classes.inputLabel}} 
                                            error = {endDateState === "error"}
                                            >
                                            Prazo
                                        </InputLabel>
                                    </h3>
                                </CardBody>
                            </Card>
                        </GridItem>
                    )}
                    )}
                    
                </GridContainer>
                <GridContainer>
                {!projectData &&
                        <GridItem style={{ display: 'flex', marginLeft: '32px', justifyContent: 'left', marginBottom: "15px"}} xs={12} sm={12} md={8}>
                            <span className={classes.numberEps}> Número de Episódios: </span>
                            <div style={{marginTop: '14px'}}> <NumberSelector getValue={this.setNumEps.bind(this)} value={numEps}/> </div>
                        </GridItem>
                    }  
                </GridContainer>
            </div>
        );
    }
}

DeliveryTemplateStep.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(deliveryTemplateStyle)(DeliveryTemplateStep);
