import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core';

const style = {
  backdropBackground: {
    right: 15,
  },
  fakeBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    height: '100%',
    width: '15px',
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: -1,
    pointerEvents: 'none'
  },
  modalWrapperRoot: {
    height: '100%',
    width: '100%',
    pointerEvents: 'none'
  },
  children: {
    pointerEvents: 'none',
    width: 'auto',
    height: 'auto',
    margin: 'auto',
    position: 'absolute',
    top: '0', 
    left: '0', 
    bottom: '0', 
    right: '0',
    '& div':{
      pointerEvents: 'auto',
    },
    '& > div:first-child':{
      borderRadius:"6px !important"
    }
  },

};

class ModalWrapper extends Component {
  render() {
    const { children, classes, ...rest } = this.props;
    return (
      <Modal 
        {...rest} 
        classes={{root: classes.backdropBackground}} 
        BackdropProps={{classes: {root: classes.backdropBackground}}}
      >
        <div className={classes.modalWrapperRoot}> 
          <div className={classes.children}> {children} </div>
          <div className={classes.fakeBackground} > </div>
        </div>
      </Modal>
    );
  }
}

export default withStyles(style)(ModalWrapper);
