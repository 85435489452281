import React from "react";
import { Tooltip } from "@material-ui/core";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
const Style = {
    root: {
      minHeight: '40px',
      minWidth: '40px',
      borderRadius: '50%',
      backgroundColor: '#9c92b8',
      color: 'white',
      fontWeight: 'normal',
      paddingTop: '10px',
      textAlign: 'center'
    },
    tooltip: {
      fontSize: '15px',
      backgroundColor: 'black'
    }
  };

class BedgePlaceHolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
  }
    
  render() {
    const { classes, text, tooltipText = '', style } = this.props;
    let  bedgeText = text.replace(/[a-z ]/g, '').slice(0,2);
    bedgeText = bedgeText.length === 0 ? text.charAt(0).toUpperCase() : bedgeText;
    return (
      <Tooltip
          id="tooltip-top"
          title={ tooltipText + ' ' + text}
          placement="top"
          classes={{ 
            tooltip: classes.tooltip
          }}
        >
          <div style={{...style}} className={classes.root}>
              {text ? bedgeText : ''}
          </div>
        </Tooltip>
    );
  }
}

export default withStyles(Style)(BedgePlaceHolder);
