export default {
  headerTitle: {
    marginTop: '10px'
  },
  modalRoot: {
    zIndex: 9999
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-20px'
  }
}