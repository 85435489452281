const style = {
  list: {
    backgroundColor: "white",
    height: "61px",
    width: "100%",
    marginTop: "41px",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.11)",
    marginBottom: "50px"
  },
  listItem: {
    padding: "0 10px !important",
    "&:nth-child(1)": {
      display: "flex",
      justifyContent: "space-between"
    }
  },
  teamAndCollab: {
    fontFamily: "montserrat, sans-serif",
    fontWeight: "normal",
    fontSize: "16px"
  },
  newEquip: {
    backgroundColor: "#3F2C78",
    height: "41px",
    minWidth: "142px",
    fontFamily: "Montserrato, sans-serif",
    fontSize: "14px",
    lineHeight: "9px",
    padding: "10px 12px",
    borderRadius: "6px",
    boxShadow: "0 4px 23px 0 rgba(0,0,0,0.12)",
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "#796aa9",
      boxShadow: "none"
    },
    "&:active": {
      backgroundColor: "#3F2C78",
      boxShadow: "none"
    },
    "&:focus": {
      backgroundColor: "#3F2C78",
      boxShadow: "none"
    },
    "& svg": {
      color: "white"
    }
  },
  underline: {
    "&:after": {
      borderColor: "#5A4B88 !important"
    },
    "& input": {
      color: "black"
    }
  },
  newEquipPlus: {
    fontSize: "27px",
    backgroundColor: "white",
    borderRadius: "50%",
    width: "14px",
    marginRight: "5px",
    height: "12px",
    "& svg": {
      color: "#5a4b88",
      marginTop: "-3px",
      marginLeft: "-2px"
    }
  },
  tabsWrapper: {
    width: "400px",
    minWidth: "417px",
    marginLeft: 20
  }
};

export default style;
