import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import ProjectScheduleTable from "./ScheduleTable/ProjectScheduleTable.jsx";
import DashboardCard from "components/DashboardCard/DashboardCard.jsx";
import Loading from "components/Loading/Loading.jsx";
import { GET_PROJECT_WITH_DELIVERY_SCHEDULE_QUERY } from "views/Planning/PlanningQueries.js";
import { Query } from "react-apollo";
import { GET_CONTENT_FROM_FOLDER_QUERY } from "../Content/ContentView/ContentDirectoryQueries";

class DashboardProject extends React.Component {
  onlyUnique(value, index, self) {
    return self.indexOf(value) === index && value.element;
  }
  render() {
    const {
      match: {
        params: { projectId }
      }
    } = this.props;
    return (
      <Query
        query={GET_PROJECT_WITH_DELIVERY_SCHEDULE_QUERY}
        variables={{ projectId }}
      >
        {({ data: project, loadingProject }) => {
          return (
            <Query
              query={GET_CONTENT_FROM_FOLDER_QUERY}
              variables={{ projectId }}
            >
              {({ data: contents, loading: loadingContents }) => {
                if (
                  !contents ||
                  loadingProject ||
                  loadingContents ||
                  !project ||
                  !contents.contents ||
                  !project.project
                )
                  return <Loading />;

                project = project.project;
                if (!contents) return null;
                contents = contents.contents.filter(content => {
                  if (content.element) {
                    return true;
                  }
                  return false;
                });

                const completedProjects = contents.filter(
                  content => content.status === "Versão Final"
                ).length;
                const lateProjects = contents.filter(project =>
                  project ? project.status === "Em Revisão" : ""
                ).length;
                const ongoingProjects = contents.filter(project =>
                  project ? project.status === "Em Andamento" : ""
                ).length;

                return (
                  <GridContainer justify="center" style={{ marginTop: "50px" }}>
                    {contents.length === 0 && (
                      <GridItem md={12}>
                        <SnackbarContent
                          message={
                            "Nenhum conteúdo associado. Associe conteúdos às entregas para preencher o Quadro de Entregas."
                          }
                          color="warning"
                        />
                      </GridItem>
                    )}
                    <GridItem xs={3}>
                      <DashboardCard
                        number={lateProjects}
                        color="red"
                        title="Entregas"
                        subtitles="Precisam de Revisão"
                      />
                    </GridItem>
                    <GridItem xs={3}>
                      <DashboardCard
                        number={ongoingProjects}
                        title="Entregas"
                        subtitle="Em Andamento"
                        color="orange"
                      />
                    </GridItem>
                    <GridItem xs={3}>
                      <DashboardCard
                        footer={true}
                        number={completedProjects}
                        title="Entregas"
                        subtitle="Em Versão Final"
                        color="green"
                      />
                    </GridItem>
                    <GridItem xs={3}>
                      <DashboardCard
                        footer={false}
                        number={`${contents.length}/${project.deliverySchedule
                          .phases.length *
                          project.deliverySchedule.elements.length}`}
                        title="Total de"
                        subtitle="Entregas"
                        color="purple"
                      />
                    </GridItem>
                    <GridContainer style={{ minHeight: "150px" }}>
                      <GridItem md={12}>
                        <ProjectScheduleTable
                          title="Episódios"
                          phases={project.deliverySchedule.phases}
                          elements={project.deliverySchedule.elements}
                          columnWidth="120px"
                        />
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

export default DashboardProject;
