import contentListing from "utils/ContentListing";

import {
  addFolder,
  deleteFolder,
  deleteManyContents,
  deleteContent,
  editFolder,
  editContent,
  downloadContent,
  editStatus,
  editManyContentsMutation,
  changeManyFolders
} from "../ContentView/ContentDirectoryQueries.js";

function openVerifyContentDialog(action, params) {
  if (action === "deleteContent") {
    this.setState({
      verificationDialogCall: () => {
        this.setState({ isVerificationDialogOpen: false });
        deleteContent({ ...params });
      }
    });
  } else if (action === "deleteFolder") {
    this.setState({
      verificationDialogCall: () => {
        this.setState({ isVerificationDialogOpen: false });
        deleteFolder({ ...params });
      }
    });
  } else if (action === "deleteMany") {
    this.setState({
      verificationDialogCall: () => {
        this.setState({ isVerificationDialogOpen: false });
        deleteManyContents({ ...params });
      }
    });
  }
  this.setState({ isVerificationDialogOpen: true });
}

function editManyContents(status) {
  const {
    client,
    match: {
      params: { projectId }
    }
  } = this.props;
  const { checkedRows } = this.state;
  editManyContentsMutation({
    params: { ids: checkedRows, projectId, status },
    client
  });
}

//root content folder
function createFoldersContentData({
  dataContent,
  toggleTag,
  folderId,
  projectId,
  cbJuridical,
  userPermissions
}) {
  const {
    classes,
    client,
    match: { params }
  } = this.props;
  const {
    moreVertOpen,
    anchorMoreVert,
    checkedRows,
    modalOpen,
    selectedSubFolder,
    associateModalOpen
  } = this.state;
  let tableData = [];
  dataContent.forEach((content, currentRow) => {
    let row = contentListing.call(this, {
      content,
      userPermissions,
      toggleTag,
      toggleAssociateModal: ({ id, phase, element }) =>
        this.setState({
          associateModalOpen: associateModalOpen
            ? false
            : { id, phase, element }
        }),
      editStatus: ({ status, id, folderId }) => {
        editStatus({
          client,
          params: {
            folderId: folderId,
            status,
            id,
            projectId: params.projectId
          }
        });
      },
      deleteContent: folderId =>
        openVerifyContentDialog.call(this, "deleteContent", {
          contentId: content.id,
          client,
          params: { projectId: params.projectId, folderId }
        }),
      renameContent: contentId => {
        this.setState({
          currentEditingId: contentId,
          currentEditingName: content.name,
          RenameBoxOpen: true,
          renameType: "editContent"
        });
      },
      downloadContent: contentId =>
        downloadContent({
          contentId,
          client,
          cb: fileURL => downloadFile(fileURL)
        }),
      moreVertOpen,
      projectId,
      anchorMoreVert,
      toggleRow: rowId => this.toggleRow(rowId),
      currentRow,
      folderId,
      selectedSubFolder,
      classes,
      checkedRows,
      modalOpen,
      cbJuridical: cbJuridical,
      setState: obj => {
        this.setState(obj);
      }
    });
    tableData.push(row);
  });
  return tableData;
}

function downloadFile(fileURL) {
  const link = document.createElement("a");
  link.href = fileURL;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const downloadManyFiles = async filesURL => {
  filesURL.map((fileURL, index) => {
    return setTimeout(() => {
      downloadFile(fileURL);
    }, 1500 * index);
  });
};

function rename(name) {
  const {
    match: { params },
    client
  } = this.props;
  const { parentFolderId, renameType, currentEditingId } = this.state;
  if (renameType === "addFolder") {
    addFolder({
      params: { ...params, parentFolderId, folderName: name },
      client
    });
  } else if (renameType === "editFolder") {
    editFolder({
      params: { ...params, id: currentEditingId, folderName: name },
      client
    });
  } else if (renameType === "editContent") {
    editContent({
      params: { ...params, id: currentEditingId, name: name },
      client
    });
  }

  this.setState({ RenameBoxOpen: false });
}

function toggleAll(checked, contents) {
  const checkedArr = [];
  if (checked) {
    contents.map((content, index) => (checkedArr[index] = content.id));
  }
  this.setState({ checkedRows: checkedArr });
}

function selectRenameBoxPropsUsingRenameType() {
  const { renameType } = this.state;

  if (renameType === "addFolder") {
    return { modalName: "Nova pasta", placeholder: "Digite o nome da pasta" };
  }
  if (renameType === "editFolder") {
    return {
      modalName: "Renomear pasta",
      placeHolder: "Digite o nome da pasta"
    };
  }
  if (renameType === "editContent") {
    return {
      modalName: "Renomear conteúdo",
      placeholder: "Digite o nome do conteúdo"
    };
  }
}
function setFolderIdUrl({ id, isSubfolder, parentId }) {
  let locationWithFolderId = String(window.location);
  if (isSubfolder) {
    locationWithFolderId = locationWithFolderId.split("/");
    locationWithFolderId[locationWithFolderId.length - 1] = id;
    locationWithFolderId[locationWithFolderId.length - 2] = parentId;
    locationWithFolderId = String(locationWithFolderId).replace(/,/g, "/");
    window.history.pushState(null, null, locationWithFolderId);
  } else {
    locationWithFolderId = locationWithFolderId.split("/");
    locationWithFolderId[locationWithFolderId.length - 2] = id;
    locationWithFolderId[locationWithFolderId.length - 1] = 0;
    locationWithFolderId = String(locationWithFolderId).replace(/,/g, "/");
    window.history.pushState(null, null, locationWithFolderId);
  }
}

function removeFolderFromUrl() {
  let locationWithFolderId = String(window.location);
  locationWithFolderId = locationWithFolderId.split("/");
  locationWithFolderId[locationWithFolderId.length - 2] = 0;
  locationWithFolderId[locationWithFolderId.length - 1] = 0;
  locationWithFolderId = String(locationWithFolderId).replace(/,/g, "/");
  window.history.pushState(null, null, locationWithFolderId);
}

function setSelectedFolder({
  selectedFolder,
  selectedSubFolder,
  id,
  hasSubFolders,
  isSubfolder,
  parentId
}) {
  if ((selectedSubFolder || selectedSubFolder === 0) && id) {
    setFolderIdUrl({ id, isSubfolder, parentId });
    return this.setState({
      selectedSubFolder,
      selectedFolderId: id,
      selectedFolder: this.state.selectedFolder,
      checkedRows: []
    });
  } else if (!(selectedSubFolder || selectedSubFolder === 0) && id) {
    if (hasSubFolders || !isSubfolder) {
      if (this.state.openFolders.some(folder => folder === selectedFolder)) {
        let filteredFolders = this.state.openFolders.filter(folder => {
          return folder !== selectedFolder;
        });
        this.setState({ openFolders: filteredFolders });
        removeFolderFromUrl();
      } else {
        this.setState({
          openFolders: [...this.state.openFolders, selectedFolder]
        });
      }
    }
    this.setState({
      selectedFolder,
      selectedSubFolder: null,
      selectedFolderId: id,
      checkedRows: []
    });

    setFolderIdUrl({ id, isSubfolder, parentId });
  }
}

function onDrop(ids, folderId) {
  const { selectedFolderId } = this.state;
  const {
    match: { params },
    client
  } = this.props;
  changeManyFolders({
    client,
    params: {
      ids: ids,
      folderId: folderId,
      projectId: params.projectId,
      selectedFolderId: selectedFolderId
    }
  });
}

export {
  onDrop,
  setSelectedFolder,
  selectRenameBoxPropsUsingRenameType,
  toggleAll,
  rename,
  downloadManyFiles,
  downloadFile,
  createFoldersContentData,
  editManyContents,
  openVerifyContentDialog
};
