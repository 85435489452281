import { gql } from "apollo-boost";

const MEMBERS_INFO_FRAG = gql`
  fragment membersFrag on User {
    id
    name
    organization {
      name
      id
    }
    role {
      id
      name
      permissions
    }
    image {
      id
      imageUrl
    }
  }
`;

const GROUPS_WITH_MEMBERS_FRAG = gql`
  ${MEMBERS_INFO_FRAG}
  fragment groupsMembersFrag on Group {
    id
    name
    default
    defaultProjectGroup
    members {
      ...membersFrag
    }
    collaboration {
      id
      organization {
        id
        owner {
          id
          name
          role {
            id
            name
          }
          image {
            id
            imageUrl
          }
        }
      }
    }
  }
`;

const GET_GROUPS_QUERY = gql`
  ${GROUPS_WITH_MEMBERS_FRAG}
  ${MEMBERS_INFO_FRAG}
  query GET_GROUPS_QUERY($id: ID!) {
    project(id: $id) {
      id
      name
      owner {
        id
        name
        image {
          id
          imageUrl
        }
        role {
          id
          name
        }
      }
      members {
        ...membersFrag
      }
      groups {
        ...groupsMembersFrag
      }
      organization {
        id
      }
      collaborativeOrgs {
        id
        organization {
          id
          name
          owner {
            id
            name
          }
        }
        role {
          id
          name
        }
      }
    }
  }
`;

const NEW_GROUP_MUTATION = gql`
  ${GROUPS_WITH_MEMBERS_FRAG}
  mutation NEW_GROUP_MUTATION(
    $name: String!
    $projectId: ID!
    $membersIds: [ID]
  ) {
    addGroup(name: $name, projectId: $projectId, membersIds: $membersIds) {
      ...groupsMembersFrag
    }
  }
`;

const ADD_MEMBERS_TO_GROUP_MUTATION = gql`
  mutation ADD_MEMBERS_TO_GROUP_MUTATION($id: ID!, $memberdsIds: [ID]!) {
    addGroupMembers(id: $id, membersIds: $memberdsIds) {
      ...groupsMembersFrag
    }
  }
`;

const REMOVE_ORG = gql`
  mutation REMOVE_COLLABORATION($id: ID!, $organizationId: ID!) {
    removeCollaboration(id: $id, organizationId: $organizationId) {
      id
    }
  }
`;
const GET_ORGANIZATIONS_WITH_MEMBERS_QUERY = gql`
  query GET_ORGANIZATIONS_WITH_MEMBERS_QUERY {
    organizations {
      owner {
        id
        name
        role {
          id
          name
        }
      }
      id
      name

      # members {
      #   ...membersFrag
      # }
      # roles {
      #   id
      #   name
      # }
    }
  }
`;

const DELETE_GROUP_MUTATION = gql`
  ${GROUPS_WITH_MEMBERS_FRAG}
  mutation DELETE_GROUP_MUTATION($id: ID!) {
    deleteGroup(id: $id) {
      ...groupsMembersFrag
    }
  }
`;

const DELETE_PROJECT_MEMBER_MUTATION = gql`
  mutation DELETE_PROJECT_MEMBER_MUTATION($id: ID!, $membersIds: [ID]!) {
    removeProjectMembers(id: $id, membersIds: $membersIds) {
      id
      name
    }
  }
`;

const DELETE_GROUP_MEMBERS_MUTATION = gql`
  mutation DELETE_GROUP_MEMBERS_MUTATION($id: ID!, $membersIds: [ID]!) {
    removeGroupMembers(id: $id, membersIds: $membersIds) {
      id
      name
    }
  }
`;

async function deleteMemberProject({ params, client, onError }) {
  await client
    .mutate({
      mutation: DELETE_PROJECT_MEMBER_MUTATION,
      variables: { id: params.projectId, membersIds: [params.userId] },
      refetchQueries: () => [
        {
          query: GET_GROUPS_QUERY,
          variables: { id: params.projectId }
        }
      ]
    })
    .catch(e => {
      console.error("error ", e);
      e = e.toString();
      onError(e);
      setTimeout(() => onError(undefined), 5000);
      window.alert("Usuário não foi excluído do projeto");
    });
}

async function deleteMemberGroup({ params, client, onError }) {
  await client
    .mutate({
      mutation: DELETE_GROUP_MEMBERS_MUTATION,
      variables: { id: params.groupId, membersIds: [params.userId] },
      refetchQueries: () => [
        {
          query: GET_GROUPS_QUERY,
          variables: { id: params.projectId }
        }
      ]
    })
    .catch(e => {
      console.error("error ", e);
      e = e.toString();
      onError(e);
      setTimeout(() => onError(undefined), 5000);
      window.alert("Usuário não foi excluído do grupo");
    });
}

async function deleteCollab({ params, client }) {
  await client.mutate({
    mutation: REMOVE_ORG,
    variables: { id: params.projectId, organizationId: params.organizationId },
    refetchQueries: () => [
      {
        query: GET_GROUPS_QUERY,
        variables: { id: params.projectId }
      }
    ]
  });
}
async function addMembersMutation(membersIds, groupId, client) {
  let data = await client.mutate({
    mutation: ADD_MEMBERS_TO_GROUP_MUTATION,
    variables: {
      id: groupId,
      membersIds
    }
  });
  // This returns the group with it's members, so you can update the data without refetch
  return data.addGroupMembers;
}

async function createGroupMutation(name, projectId, membersIds, client) {
  let data = await client.mutate({
    mutation: NEW_GROUP_MUTATION,
    variables: {
      name,
      projectId,
      membersIds
    }
  });
  // This returns the group with it's members, so you can update the data without refetch
  return data.addGroup;
}
const deleteGroup = ({ params, client }) => {
  client.mutate({
    mutation: DELETE_GROUP_MUTATION,
    variables: { id: params.id },
    refetchQueries: () => [
      {
        query: GET_GROUPS_QUERY,
        variables: { id: params.projectId }
      }
    ]
  });
};

export {
  GET_GROUPS_QUERY,
  GET_ORGANIZATIONS_WITH_MEMBERS_QUERY,
  createGroupMutation,
  addMembersMutation,
  deleteGroup,
  deleteMemberProject,
  deleteMemberGroup,
  deleteCollab
};
