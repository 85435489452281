import React from "react";
import { Switch, withStyles } from "@material-ui/core";
import SwitchesTableStyle from "components/SwitchesTable/SwitchesTableStyle";

const SwitchWrapper = ({checked, onChange, value, classes}) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      value={value}
      classes={{icon: classes.switchIcon,
        switchBase: classes.switchBase,
        bar: classes.switchBarRegular,
        root: classes.switchChecked,
        checked: classes.switchChecked}}
      />
  )
}

export default withStyles(SwitchesTableStyle)(SwitchWrapper);