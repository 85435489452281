import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
const style = {

}
const OpenFolder = ({classes}) => {
	return (
		//<div className={classes.root}>
			<svg width="16px" height="12px" viewBox="0 0 16 12">
				<title>Shape</title>
				<desc>Created with Sketch.</desc>
				<g id="Page-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<g id="Todos-os-Projetos---Conteúdo" transform="translate(-153.000000, -267.000000)" fill="#5A4B88" fillRule="nonzero">
								<g id="icons8-opened-folder" transform="translate(143.000000, 267.000000)">
										<path d="M12.5521593,0 C11.9602184,0 11.480773,0.488181818 11.480773,1.09090909 L11.480773,2.72727273 L11.480773,3.81818182 L21.6589429,3.81818182 L24.3374087,3.81818182 L24.3374087,2.18181818 C24.3374087,1.57909091 23.8579633,1.09090909 23.2660224,1.09090909 L16.4087316,1.09090909 L16.0781083,0.529474418 C15.884723,0.201110782 15.5360703,0 15.1594783,0 L12.5521593,0 Z M11.2139727,4.90909091 C10.6734583,4.90909091 10.2863949,5.44222145 10.44496,5.96803964 L12.0352991,11.2297587 C12.1740437,11.6873951 12.5892644,12 13.059603,12 L17.9090909,12 L22.7585785,12 C23.2289171,12 23.6441382,11.6873951 23.7828827,11.2297587 L25.3732218,5.96803964 C25.531787,5.44222145 25.1447235,4.90909091 24.6042091,4.90909091 L17.9090909,4.90909091 L11.2139727,4.90909091 Z" id="Shape"></path>
								</g>
						</g>
				</g>
			</svg>
		//</div>
	);
}

export default withStyles(style)(OpenFolder);
