import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import Email from "@material-ui/icons/Email";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import userRegistrationStyle from "../userRegistrationStyle";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";

const GET_USER_BY_EMAIL_QUERY = gql`
  query GET_USER_BY_EMAIL_QUERY($email: String!) {
    userByEmail(email: $email) {
      id
      name
    }
  }
`;

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      image: props.stepProps ? props.stepProps.image : "",
      showPassword: false,
      passwordValid: "",
      email: "",
      password: "",
      passConfirm: "",
      isEmailInUse: false
    };
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  onClickDropdown = genre => {
    this.setState({ genreState: "success", genre: genre });
  };

  sendState() {
    return this.state;
  }

  async checkEmailAvailability({ email }) {
    const { isEmailInUse } = this.state;
    try {
      await this.props.client.query({
        query: GET_USER_BY_EMAIL_QUERY,
        variables: { email }
      });
    } catch (error) {
      if (error.message === "GraphQL error: User Not Found.") {
        if (isEmailInUse) this.setState({ isEmailInUse: false });
        return true;
      } else {
        if (!isEmailInUse) this.setState({ isEmailInUse: true });
        return false;
      }
    }
    if (!isEmailInUse) this.setState({ isEmailInUse: true });
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  async change(event, stateName, type) {
    event.persist();
    await this.setState({ [stateName]: event.target.value.trim() });
    switch (type) {
      case "email":
        if (
          this.verifyEmail(event.target.value) &&
          (await this.checkEmailAvailability({ email: event.target.value.trim() }))
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "password":
        if (this.state.password !== this.state.passConfirm)
          this.setState({
            passwordErrorMsg: "Senha e confirmação são diferentes"
          });
        else this.setState({ passwordErrorMsg: null });
        if (this.verifyLength(event.target.value, 6)) {
          this.setState({ passwordState: "success" });
        } else {
          this.setState({ passwordState: "error" });
        }
        break;
      case "passwordConfirm":
        if (this.state.password !== this.state.passConfirm)
          this.setState({
            passwordErrorMsg: "Senha e confirmação são diferentes"
          });
        else this.setState({ passwordErrorMsg: null });
        if (
          this.verifyLength(event.target.value, 6) &&
          this.state.password === event.target.value
        ) {
          this.setState({ passConfirmState: "success" });
        } else {
          this.setState({ passConfirmState: "error" });
        }
        break;

      default:
        break;
    }
  }

  isValidated() {
    if (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.passConfirm.length > 0 &&
      (this.state.passConfirmState === "success" &&
        this.state.emailState === "success" &&
        this.state.passwordState === "success" &&
        this.state.password === this.state.passConfirm)
    ) {
      this.setState({ passwordErrorMsg: null });
      return true;
    } else if (this.state.password !== this.state.passConfirm) {
      this.setState({ passwordErrorMsg: "Senha e confirmação são diferentes" });
    }
    return false;
  }

  render() {
    const { classes } = this.props;

    const {
      password,
      passConfirm,
      email,
      showPassword,
      isEmailInUse
    } = this.state;

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={7}>
          <CustomInput
            success={this.state.emailState === "success"}
            error={this.state.emailState === "error"}
            classes={{ labelRoot: classes.inputLabel }}
            labelText={<span>E-Mail</span>}
            id="email"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: email,
              onChange: event => this.change(event, "email", "email"),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="Mostrar ou esconder senha">
                    <Email />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {isEmailInUse && <span>Email já cadastrado</span>}
          <br />
          <CustomInput
            success={this.state.passwordState === "success"}
            error={this.state.passwordState === "error"}
            classes={{ labelRoot: classes.inputLabel }}
            labelText={<span>senha</span>}
            id="password"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: password,
              type: showPassword ? "text" : "password",
              onChange: event => this.change(event, "password", "password", 6),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Mostrar ou esconder senha"
                    onClick={() =>
                      this.setState({ showPassword: !showPassword })
                    }
                  >
                    {this.state.showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <br />
          <CustomInput
            success={this.state.passConfirmState === "success"}
            error={this.state.passConfirmState === "error"}
            classes={{ labelRoot: classes.inputLabel }}
            labelText={<span>Confirmar senha</span>}
            id="passConfirm"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: showPassword ? "text" : "password",
              value: passConfirm,
              onChange: event =>
                this.change(event, "passConfirm", "passwordConfirm", 6),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Mostrar ou esconder senha"
                    onClick={() =>
                      this.setState({ showPassword: !showPassword })
                    }
                  >
                    {this.state.showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {this.state.passwordErrorMsg && passConfirm !== password
            ? this.state.passwordErrorMsg
            : null}
        </GridItem>
      </GridContainer>
    );
  }
}

export default withApollo(withStyles(userRegistrationStyle)(Step1));
