import React, { Component } from "react";
import ReactTable from "react-table";
import AttachmentTableStyle from "./AttachmentTableStyle.js";
import { withStyles } from "@material-ui/core";
import pickRowIcon from "../../utils/pickRowIcon.jsx";
import CustomMenu from "components/CustomMenu/CustomMenu.jsx";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import {
  editDocumentStatus,
  deleteDocumentMutation
} from "./AttachmentTableQueries.js";
import { withApollo } from "react-apollo";
import DocumentModal from "views/Content/DocumentModal.jsx";
import moment from "moment";
import avatar from "assets/img/avatar.png";
import Avatar from "components/Avatar/Avatar.jsx";
import { downloadFile } from "../../views/Juridical/utils/utils.js";
import VerificationDialog from "components/VerificationDialog/VerificationDialog";
import DocumentTags from "components/DocumentTags/DocumentTags";
import { compare } from "natural-orderby";

class AttachmentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVerificationDialogOpen: false,
      anchorEl: null,
      optionsOpen: -1,
      attachIsOpen: false,
      selectedParamsForDeleteDoc: {}
    };
  }

  setIsVerificationDialogOpen = value =>
    this.setState({ isVerificationDialogOpen: value });

  pickStatusColor = status => {
    const { classes } = this.props;

    switch (status.toLowerCase()) {
      case "pendente":
        return classes.statusOrange;
      case "aprovado":
        return classes.statusGreen;
      case "em revisão":
        return classes.statusRed;
      default:
        return classes.statusGreen;
    }
  };

  customSort = (a, b) => {
    a = a.props["data-name"];
    b = b.props["data-name"];

    if (a > b) {
      return 1;
    } else if (b > a) {
      return -1;
    }
    return 0;
  };

  getColumns = () => {
    const { standAlone } = this.props;
    let columns = [
      {
        accessor: "icon",
        sortable: false,
        width: 30
      },
      {
        Header: "Título",
        accessor: "name",
        minWidth: 260,
        sortMethod: (a, b) => {
          return compare()(a.props["data-name"], b.props["data-name"]);
        }
      },
      {
        Header: "Status",
        accessor: "status",
        maxWidth: 200,
        minWidth: 170,
        sortMethod: (a, b) => {
          return compare()(a.props["data-name"], b.props["data-name"]);
        }
      },
      {
        accessor: "comment",
        maxWidth: 95,
        minWidth: 60
      },
      {
        Header: "Upload",
        accessor: "upload",
        maxWidth: 130,
        sortMethod: this.customSort
      },
      {
        Header: "Por",
        accessor: "por",
        maxWidth: 50,
        sortMethod: (a, b) => {
          return compare()(a.props["data-name"], b.props["data-name"]);
        }
      },
      {
        accessor: "option",
        maxWidth: 50,
        sortable: false
      }
    ];
    if (standAlone) columns = columns.filter(col => col.accessor !== "comment");
    return columns;
  };

  toggleModal = () => {
    this.setState({ attachIsOpen: !this.state.attachIsOpen });
  };

  getDocuments = () => {
    const { classes, client, projectId } = this.props;
    const { optionsOpen, anchorEl } = this.state;
    let { data } = this.props;

    let options = [
      {
        name: "Status",
        func: () => {},
        icon: "donut_large",
        subOptions: [
          {
            name: "Pendente",
            colorIcon: "orange",
            icon: "fiber_manual_record",
            func: ({ id }) => {
              editDocumentStatus({
                params: { id, status: "Pendente", projectId },
                client
              });
            }
          },
          {
            name: "Aprovado",
            colorIcon: "green",
            icon: "fiber_manual_record",
            func: ({ id }) => {
              editDocumentStatus({
                params: { id, status: "Aprovado", projectId },
                client
              });
            }
          }
        ]
      },
      {
        name: "Baixar Documento",
        icon: "cloud_download",
        func: ({ fileURL, id }) => {
          downloadFile(fileURL, id);
        }
      },
      {
        name: "Deletar Documento",
        icon: "delete",
        func: ({ id }) => {
          this.setState({ selectedParamsForDeleteDoc: { id, projectId } });
          this.setIsVerificationDialogOpen(true);
        }
      }
    ];
    return data.map((d, currentRow) => {
      const creationDate = moment(d.creationDate).format("DD/MM/YYYY");
      return {
        ...d,
        name: (
          <div className={classes.titleWrapper} data-name={d.name}>
            <div className={classes.nameWrapper}>{d.name}</div>
            <DocumentTags documentId={d.id} tags={d.tags} />
          </div>
        ),
        icon: pickRowIcon("attach"),
        upload: (
          <span className={classes.date} data-name={creationDate}>
            {" "}
            {creationDate}{" "}
          </span>
        ),
        status: (
          <div className={classes.statusWrapper} data-name={d.status}>
            <CircleIcon classes={{ root: this.pickStatusColor(d.status) }} />
            <span>{d.status}</span>
          </div>
        ),
        comment: <div className={classes.noComments} />,
        doc: d,
        por: (
          <Avatar
            data-name={d.uploader ? d.uploader.name :""}
            classes={{ root: classes.avatar }}
            img={
              d && d.uploader && d.uploader.image
                ? d.uploader.image.imageUrl
                : avatar
            }
            userId={d && d.uploader ? d.uploader.id : 0}
          />
        ),
        option: (
          <div>
            <IconButton
              aria-label="More"
              aria-haspopup="true"
              onClick={e => {
                if (e)
                  this.setState({
                    anchorEl: e.currentTarget,
                    optionsOpen: currentRow
                  });
                e.stopPropagation();
              }}
              classes={{ root: classes.iconButton }}
            >
              <div className="">
                <MoreVertIcon />
              </div>
            </IconButton>
            {anchorEl && (
              <CustomMenu
                options={options}
                callBackArgs={{
                  id: d.id,
                  fileURL: d.downloadLink,
                  type: d.type
                }}
                isOpen={optionsOpen === currentRow}
                onClose={e => {
                  this.setState({ optionsOpen: -1, anchorEl: null });
                  e.stopPropagation();
                }}
                anchorEl={anchorEl}
                style={{
                  style: {
                    maxHeight: 45 * 10,
                    width: 210,
                    marginTop: 40,
                    marginLeft: -40,
                    textAlign: "center",
                    borderRadius: "15px"
                  }
                }}
              />
            )}
          </div>
        )
      };
    });
  };
  render() {
    const { classes, client, standAlone } = this.props;
    const {
      attachIsOpen,
      attachment,
      isVerificationDialogOpen,
      selectedParamsForDeleteDoc
    } = this.state;
    let { data } = this.props;

    data = this.getDocuments();
    return (
      <div
        className={classes.root + " " + (standAlone ? classes.standAlone : "")}
      >
        {attachIsOpen && (
          <DocumentModal
            projectId={this.props.projectId}
            document={attachment}
            modalOpen={attachIsOpen}
            toggleModal={this.toggleModal.bind(this)}
            documentsRefetch={this.props.documentsRefetch}
          />
        )}
        {isVerificationDialogOpen && (
          <VerificationDialog
            title={"Tem certeza que deseja deletar esse documento?"}
            open={isVerificationDialogOpen}
            handleClose={() => this.setIsVerificationDialogOpen(false)}
            onAccept={() => {
              deleteDocumentMutation({
                params: selectedParamsForDeleteDoc,
                client
              });
              this.setIsVerificationDialogOpen(false);
            }}
          />
        )}
        <ReactTable
          data={data}
          showPagination={false}
          minRows={data.length}
          pageSize={data.length}
          stripped={false}
          resizable={false}
          columns={this.getColumns()}
          noDataText='Nenhum documento foi encontrado'
          defaultSorted={[{
            id: 'name',
            desc: false,
          }]}
          getTheadProps={() => {
            return {
              style: { display: "none" }
            };
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                this.setState({
                  attachIsOpen: true,
                  attachment: rowInfo.original.doc
                });
                if (handleOriginal) {
                  handleOriginal();
                }
              },
              style: {
                cursor: "pointer"
              }
            };
          }}
        />
      </div>
    );
  }
}

export default withApollo(withStyles(AttachmentTableStyle)(AttachmentTable));
