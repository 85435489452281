const PdfReaderStyle = {
  markerWrapper: {
    position: 'relative'
  },
  markerCanvas: {
    height: '100%', 
    zIndex:999, 
    width: '100%', 
    backgroundColor: 'transparent', 
    top: 0, 
    left: 0, 
    position: 'absolute'
  },
  modalDocumentWrapper: {
    marginTop: "51px",
  },
  documentWrapper: {
    maxHeight: "calc(100vh - 187px)",
    position: "relative",
    overflowY: "scroll !important",
    overflowX: "auto !important",
    marginLeft: "8%",
    marginRight: "8%",
    "@media (min-width: 1680px)":{
      maxHeight: "calc(100vh - 205px)",
    },
    "@media (max-width: 700px)":{
      maxHeight:"calc(100vh - 225px)", 
    },
  },
  centeredPage:{
    display:"flex",
    justifyContent:"center"
  },
  zoomWrapper:{
    display: "flex", 
    width:"60px", 
    position:"absolute", 
    justifyContent:"space-between", 
    top:"calc(100vh - 220px)", 
    left:"48%", 
    "@media (max-width: 700px)":{
      top:"calc(100vh - 250px)", 
    },
    "@media (min-width: 1680px)":{
      top: "calc(100vh - 250px)",
    }
  },
  zoomRight:{
    zIndex:9999,
    opacity:"0.3",
    "&:hover":{
      opacity:"0.8",
    }
  },
  zoomLeft:{
    zIndex:9999,
    opacity:"0.3",
    "&:hover":{
      opacity:"0.8",
    }
  },
  ballMarker: {
    height: '40px', 
    backgroundColor: 'yellow',
    opacity: '0.5', 
    width: '40px', 
    borderRadius: '50%',
    zIndex: 1000, 
    position: 'absolute', 
    border: '1px solid black'
  },
  displayNone: {
    display:"none"
  },
  documentDataLoading: {
    margin:"auto",
    fontSize:"2em",
    display:"flex",
    alignItems:"center",
    '& > p':{
      margin:"auto"
    }
  }
}

export default PdfReaderStyle;