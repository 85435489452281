import React from "react";
import PropTypes from "prop-types";
import { Query, withApollo } from "react-apollo";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import AdminCharts from "views/AdminPage/AdminCharts";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import projectsStyle from "../../assets/jss/material-dashboard-pro-react/views/projectsStyle";
import { ALL_ORGANIZATIONS_QUERY } from './adminPageQueries.js';
import ProjectCard from "components/ProjectCard/ProjectCard";
import Loading from "components/Loading/Loading";
import { ContextConsumer, GlobalContext } from "ContextController.js"

class AdminPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            searchText: '',
            sweetAlertOpen: false,
            projectId: ''
        };
        this.openDeleteProjectMessage = this.openDeleteProjectMessage.bind(this);
    }
    static contextType = GlobalContext;
    handleChange = (event, value) => {
        this.setState({ value });
    };
    handleChangeIndex = index => {
        this.setState({ value: index });
    };

    openDeleteProjectMessage({sweetAlertOpen, projectId}) {
        this.setState({sweetAlertOpen, projectId});
    };
    
    render() {
        return (
            <Query query={ALL_ORGANIZATIONS_QUERY}>
            {({ loading, error, data }) => {
                if (loading) 
                return (
                    <Loading/>
                );
                if (error) {
                    console.log(`Error! ${error.message}`);
                    //return null;
                };
                if( !data ) return null;
                return (
                    <>
                    <AdminCharts />
                    { data.organizations.map( (org, index) => {
                        let projects = org.projects;
                        return (
                            <>
                            <div key={index} style={{fontSize:30, padding: 10}}> {org.name} </div>
                            <GridContainer key={index}>
                                <ContextConsumer>
                                {
                                    context => {
                                        if(!this.state.loggedUser)
                                            this.setState({loggedUser: context.getUser()});
                                    }
                                }
                                </ContextConsumer>
                                {projects && projects.map((project, index) => {
                                    return (
                                        <GridItem xs={12} sm={12} md={4} lg={3} key={index}>
                                            <ProjectCard project={project} />
                                        </GridItem>
                                    );
                                })}
                            </GridContainer>
                            </>
                        )
                    })}
                    </>
                )
            }}
            </Query>
        )
    }
}

AdminPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withApollo(withStyles({...dashboardStyle, ...sweetAlertStyle, ...projectsStyle})(AdminPage));
