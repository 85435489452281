import { gql } from "apollo-boost";

const GET_PROJECT_QUERY = gql`
  query GET_PROJECT_QUERY($id: ID!) {
    project(id: $id) {
      id
      name
      collaborativeOrgs {
        organization {
          name
          id
        }
      }
    }
  }
`;

const SEND_NEW_USER_EMAIL = gql`
  mutation SEND_NEW_USER_EMAIL(
    $destination: String!
    $roleId: ID!
    $projectId: ID
    $groupId: ID
  ) {
    sendNewUserEmail(
      destination: $destination
      roleId: $roleId
      projectId: $projectId
      groupId: $groupId
    )
  }
`;

const sendEmails = async ({ client, params }) => {
  //here
  for (let i = 0; i < params.emails; i++) {
    client.mutate({
      mutation: SEND_NEW_USER_EMAIL,
      variables: {
        destination: params.emails[i],
        roleId: params.roles[i]
      }
    });
  }
};

const GET_ORGANIZATION_QUERY = gql`
  query GET_ORGANIZATIONS_QUERY {
    organizations {
      id
      name
    }
  }
`;

const ADD_PROJECT_MUTATION = gql`
  mutation ADD_PROJECT_MUTATION(
    $name: String!
    $description: String
    $genre: String
    $projectTemplateId: ID
    $deliveryTemplatePhases: [PhaseInput]
    $deliveryEpisodeCount: Int
    $startDate: String
    $endDate: String
    $imageId: ID
  ) {
    addProject(
      name: $name
      description: $description
      genre: $genre
      projectTemplateId: $projectTemplateId
      deliveryTemplatePhases: $deliveryTemplatePhases
      deliveryEpisodeCount: $deliveryEpisodeCount
      startDate: $startDate
      endDate: $endDate
      imageId: $imageId
    ) {
      name
      description
      id
      startDate
      endDate
      deliverySchedule {
        id
        phases {
          id
          name
          endDate
        }
      }
      image {
        id
        imageUrl
      }
    }
  }
`;

export {
  GET_PROJECT_QUERY,
  sendEmails,
  GET_ORGANIZATION_QUERY,
  SEND_NEW_USER_EMAIL,
  ADD_PROJECT_MUTATION
};
