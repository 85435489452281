import { grayColor, title } from "assets/jss/material-kit-pro-react.jsx";

import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.jsx";

const sectionCommentsStyle = (theme) => ({
  ...tooltipsStyle,
  section: {
    backgroundposition: "50%",
    backgroundSize: "cover",
    padding: "0 0"
  },
  tooltipHide: {
    display: "none",
  },
  customTooltip: {
    backgroundColor: 'black',
    zIndex:0,
  },
  title: {
    ...title,
    marginBottom: "30px",
    textAlign: "center"
  },
  footerButtons: {
    float: "right"
  },
  footerIcons: {
    width: "1.1rem",
    height: "1.1rem",
    position: "relative",
    display: "inline-block",
    top: "0",
    marginTop: "-1em",
    marginBottom: "-1em",
    marginRight: "3px",
    verticalAlign: "middle"
  },
  optionText: {
    fontSize: "0.8125rem",
  },
  color555: {
    "&,& *": {
      color: grayColor[15] + " !important"
    }
  },
  timePosted: {
   fontSize: "11px",
   marginLeft: "5px",
   color: "#636882",
   marginTop: "1px"
  },
  gridComments: {
    padding: "0 !important",
  },
  bodyComment: {
    display:"flex",
    justifyContent:"flex-start",
    marginTop: "8px",
    width: 'Calc(100% + 40px)',
    marginLeft: "-45px",
    '& @media (max-width: 1500px)': {
      width: 'Calc(100% + 70px) !important',
      marginLeft: "-40px",
    },

  },
  mediaBody: {
    flex: 1,
    paddingRight:0,
  },
  editComment:{
    marginLeft:"0 !important"
  },
  replySection: {
    width: '100%',
    transition: 'all 1s linear',
    '& textarea': {
      flexDirection:"column",
      fontSize: "17px !important",
      width: 'Calc(100% + 48px) !important',
      minHeight: '70px',
      height:"fit-content",
      resize: 'none',
      marginLeft: '-47px',
      marginTop: '5px',
      marginBottom: '5px',
      overflow: 'hidden !important',
      borderRadius: '5px',
      backgroundColor: '#0f1014',
      border: '1px solid rgba(91,83,255,.75)',
      color: 'white',
      padding: '10px 10px',
      display: 'none',
      visibility: 'hidden',
      opacity: '0',
      transition: 'all 2s linear',
      overflow:"visible",
      '&::-webkit-scrollbar': {
        width: "5px",
        height: "2px",
      },
      '&::-webkit-scrollbar-button': {
        width: "0px",
        height: '0px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: "#878787",
        border: "0px none #ffffff",
        borderRadius: "2px",
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: "#ffffff",
      },
      '&::-webkit-scrollbar-thumb:active': {
        background: "#000000",
      },
      '&::-webkit-scrollbar-track': {
        background: "#281c49",
        border: "0px none #ffffff",
        borderRadius: "0px",
      },
      '&::-webkit-scrollbar-track:hover': {
        background: "#807683",
      },
      '&::-webkit-scrollbar-track:active': {
        background: "#333333",
      },
      '&::-webkit-scrollbar-corner': {
        background: "transparent",
      }
    },
    '& .displayTextarea': {
      display: 'block !important',
      opacity: '1 !important',
      visibility: 'visible !important',
    },
  },
  mediaAvatarSmall: {
    height: '42px !important',
    width: '42px !important',
    marginTop: '0px !important',
    backgroundColor: 'grey',
    marginRight: '5px !important',
    border: 'solid 2px #20222b',
    boxShadow: 'none !important',
  },
  mediaLinkAvatar: {
    paddingRight: '2px !important',
  },
  headerApp: {
    height: '50px',
    borderLeft: '1px solid #0f1014',
    borderBottom: '1px solid #0f1014',
    paddingRight: '10px',
  },
  subInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap:"wrap",
    marginTop: '4px',
  },
  attachmentCrudWrapper: {
    display: 'flex',
    '& .delete': {
      marginTop: '7px',
      marginLeft:"-37px"
    }
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "15px",
    marginTop: '2px',
  },
  edit: {
    marginTop: '2px',
    marginLeft: '8px',
    display: 'flex',
    color: '#dadada',
    transition: 'all 0.5 ease-out',
    '& svg': {
      fontSize: "0.8125rem",
      color: '#dadada',
      transition: 'all 0.5 ease-out',
    },
    '& div': {
      marginTop: '0px',
      marginLeft: '3px',
      fontSize: '11px',
    },
    '&:hover': {
      color: '#929ac6',
      '& svg': {
        color: '#929ac6',
      }
    },
  },
  circleCheckWrapper: {
    visibility: 'visible',
    height: '34px',
    width: '30px',
    marginLeft:"-46px",
    color: '#8861FF',
    '& div': {
      pointerEvents: 'none',
    },
    "&:hover": {
      '& div:first-child': {
        display: "none",
      },
      '& div:nth-child(2)': {
        display: "block",
      }
    }
  },
  footerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  attachmentsDeleteRow: {
    marginTop: "9px",
  },
  delete: {
    width: '45px',
    display: "flex",
    '& svg': {
      color: '#636882',
      fontSize: '16px',
      transition: 'all 0.5s linear',
      marginRight: '5px',
      '&:hover': {
        color: '#838aad',
      }
    },
    zIndex:10
  },
  circle: {
    marginTop: "2px",
    color: '#8861FF',
    height: '25px',
  },
  replyButton:{
    marginLeft: "-43px"
  },
  CheckCircleOutline: {
    fontSize: '18px',
    marginTop: "2px",
    height: '25px',
    width: '25px',
    color: '#8861FF',
    display: "none",
  },
  sortDropdown: {
    backgroundColor: 'transparent !important',
    color: '#b6bfcd !important',
    padding: '6px 0 !important',
    marginTop: '5px !important',
  },
  moreContainerComment: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: '26px',
    backgroundColor: '#20222b',
    width: '30px',
    paddingLeft: '5px',
    paddingTop: '1px',
    borderRadius: '5px',
  },
  mediaHeadingSmall: {
    color: '#f1f3f6 !important',
    fontSize: '16px',
    lineHeight: '16px',
    fontWeight: '500 !important',
    marginTop: '12px !important',
    marginBottom: '0px !important',
    fontFamily: "Roboto, Times New Roman !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  commentName: {
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "Roboto, sans-serif",
    color: "#B8C1CF",
  },
  filterWrapper: {
    width: "100%",
    position:"sticky",
    top:"0",
    borderBottom: "1px solid black",
    height: "45px",
    color: "#afb5c3",
    backgroundColor:"#20222B",
    zIndex: '5',
  },
  moreIcon: {
    color: "white",
    position: "absolute",
  },
  dropDownSort: {
    marginLeft: '-60px',
  },
  iconButton: {
    padding: 0,
    marginRight: '5px',
  },
  footerSlick: {
    '& button': {
      marginBottom: "10px !important",
      marginRight: '5px',
    },
    marginBottom: "0px !important",
    paddingTop: "0px !important",
    width: '98%'
  },
  csvLink: {
    color:"black",
  }
});

export default sectionCommentsStyle;
