import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import { NavLink } from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";


// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";

import avatar from "assets/img/avatar.png";
import { Link } from 'react-router-dom';

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  render() {
    const { className, user, headerLinks, links, bottomLinks } = this.props;
    return (
      <div className={className} ref="sidebarWrapper">
        {user}
        {headerLinks}
        {links}
        {bottomLinks}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openAvatar: props.openAvatar,
      miniActive: true,
      ...this.getCollapseStates(props.routes)
    };
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    let path = this.props.location.pathname.split('/');
    let routes = routeName.split('/');
    let verificationIndex;

    for(let i in routes){
      if(routes[routes.length - i -1][0] !== ":"){
        verificationIndex = i ;
        break;
      }  
    }
    return (path[path.length - 1 - verificationIndex] === routes[routes.length -1 - verificationIndex]) ? "active" : "";
  };
  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    const { classes, color, rtlActive } = this.props;
    let topRoutes = routes.filter( prop => !prop.bottom );
    return topRoutes.filter(prop => !prop.user).map((prop, key) => {
      if (prop.onlyOn) {
        let location = '/' + prop.onlyOn + '/';
        let href = window.location.href;
        if (!href.includes(location)) {
          return null;
        }
        // else if ( this.state.openAvatar )
        //  this.setState({openAvatar: false})
      }
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        const navLinkClasses =
          classes.itemLink +
          " " +
          cx({
            [" " + classes.collapseActive]: this.getCollapseInitialState(
              prop.views
            )
          });
        const itemText =
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive
          });
        const collapseItemText =
          classes.collapseItemText +
          " " +
          cx({
            [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive
          });
        const itemIcon =
          classes.itemIcon +
          " " +
          cx({
            [classes.itemIconRTL]: rtlActive
          });
        const caret =
          classes.caret +
          " " +
          cx({
            [classes.caretRTL]: rtlActive
          });
        const collapseItemMini =
          classes.collapseItemMini +
          " " +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive
          });
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                    <prop.icon className={itemIcon} />
                  )
              ) : (
                  <span className={collapseItemMini}>
                    {rtlActive ? prop.rtlMini : prop.mini}
                  </span>
                )}
              <ListItemText
                primary={rtlActive ? prop.rtlName : prop.name}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      (this.state[prop.state] ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(prop.path)
        });
      const collapseItemMini =
        classes.collapseItemMini +
        " " +
        cx({
          [classes.collapseItemMiniRTL]: rtlActive
        });
      const navLinkClasses =
        classes.itemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(prop.path)
        });
      let itemText;
      if( prop.bottom ) 
        itemText = classes.itemTextBottom +
        " " +
        cx({
          [classes.itemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.itemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.itemTextRTL]: rtlActive
        });
      else 
        itemText = classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive
          });
      const collapseItemText =
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextRTL]: rtlActive
        });
      const itemIcon =
        classes.itemIcon +
        " " +
        cx({
          [classes.itemIconRTL]: rtlActive
        });
      const id = window.location.href.split(prop.onlyOn + '/')[1].split('/')[0];
      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined }
          )}
        >
          <NavLink
            to={prop.layout + prop.path.replace(':' + prop.onlyOn + 'Id', id)}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined }
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === "string" ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                  <prop.icon className={itemIcon} />
                )
            ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
              )}
            <ListItemText
              primary={rtlActive ? prop.rtlName : prop.name}
              disableTypography={true}
              className={cx(
                { [itemText]: prop.icon !== undefined },
                { [collapseItemText]: prop.icon === undefined }
              )}
            />
          </NavLink>
        </ListItem>
      );
    });
  };

  createBottomLinks = routes => {
    const { classes, color, rtlActive } = this.props;
    let bottomRoutes = routes.filter( prop => prop.bottom );
    return bottomRoutes.filter(prop => !prop.user).map((prop, key) => {
      if (prop.onlyOn) {
        let location = '/' + prop.onlyOn + '/';
        let href = window.location.href;
        if (!href.includes(location)) {
          return null;
        }
        // else if ( this.state.openAvatar )
        //  this.setState({openAvatar: false})
      }
      if (prop.redirect) {
        return null;
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(prop.path)
        });
      const collapseItemMini = classes.collapseItemMini;
      const navLinkClasses =
        classes.itemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(prop.path)
        });
        
      let itemText = 
        classes.itemTextBottom +
        " " +
        cx({
          [classes.itemTextMini]:
            this.props.miniActive && this.state.miniActive
        });

      const collapseItemText =
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive,
        });
      const itemIcon = classes.itemIconBottom;
      const id = window.location.href.split(prop.onlyOn + '/')[1].split('/')[0];
      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.collapseItemBottom]: prop.icon === undefined },
            { [classes.itemBottom]: prop.icon !== undefined }
          )}
        >
          <NavLink
            to={prop.layout + prop.path.replace(':' + prop.onlyOn + 'Id', id)}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined }
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === "string" ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                  <prop.icon className={itemIcon} />
                )
            ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
              )}
            <ListItemText
              primary={rtlActive ? prop.rtlName : prop.name}
              disableTypography={true}
              className={cx(
                { [itemText]: prop.icon !== undefined },
                { [collapseItemText]: prop.icon === undefined }
              )}
            />
          </NavLink>
        </ListItem>
      );
    });
  }

  
  render() {
    const {
      classes,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      loggedUser,
      color,
      rtlActive,
    } = this.props;
    const itemText =
      classes.itemText +
      " " +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        [classes.itemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.itemTextRTL]: rtlActive
      });

    const userWrapperClass =
      classes.user +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });
    const caret =
      classes.caret +
      " " +
      cx({
        [classes.caretRTL]: rtlActive
      });

    const photo =
      classes.photo +
      " " +
      cx({
        [classes.photoRTL]: rtlActive
      });
    var user = (
      <div className={userWrapperClass}>
        <div className={photo}>
          <img src={loggedUser && loggedUser.organization && loggedUser.organization.image ? loggedUser.organization.image.imageUrl : avatar} className={classes.avatarImg} alt="..." />
        </div>
        <List className={classes.list + " " + classes.listUser}>
          <ListItem className={classes.item + " " + classes.userItem}>
            <NavLink
              to={"#"}
              className={classes.itemLink + " " + classes.userCollapseButton}
              onClick={() => this.openCollapse("openAvatar")}
            >

              <ListItemText
                primary={loggedUser && loggedUser.organization ? loggedUser.organization.name : ''}
                classes={{ primary: classes.primaryTextLogo }}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      classes.userCaret +
                      " " +
                      (this.state.openAvatar ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={itemText + " " + classes.userItemText + " " + classes.listText}
              />

            </NavLink>
            <Collapse in={this.state.openAvatar} classes={{ wrapper: classes.wrapperCollapse, container: classes.wrapperContainer }} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList + " " + classes.collapseUser}>
                {routes.filter(prop => prop.user).map((prop, key) => {
                  if (prop.redirect) {
                    return null;
                  }
                  if (prop.collapse) {
                    var st = {};
                    st[prop["state"]] = !this.state[prop.state];
                    const navLinkClasses =
                      classes.itemLink +
                      " " +
                      cx({
                        [" " + classes.collapseActive]: this.getCollapseInitialState(
                          prop.views
                        )
                      });
                    const itemText =
                      classes.itemText +
                      " " +
                      cx({
                        [classes.itemTextMini]:
                          this.props.miniActive && this.state.miniActive,
                        [classes.itemTextMiniRTL]:
                          rtlActive && this.props.miniActive && this.state.miniActive,
                        [classes.itemTextRTL]: rtlActive
                      });
                    const collapseItemText =
                      classes.collapseItemText +
                      " " +
                      cx({
                        [classes.collapseItemTextMini]:
                          this.props.miniActive && this.state.miniActive,
                        [classes.collapseItemTextMiniRTL]:
                          rtlActive && this.props.miniActive && this.state.miniActive,
                        [classes.collapseItemTextRTL]: rtlActive
                      });
                    const itemIcon =
                      classes.itemIcon +
                      " " +
                      cx({
                        [classes.itemIconRTL]: rtlActive
                      });
                    const caret =
                      classes.caret +
                      " " +
                      cx({
                        [classes.caretRTL]: rtlActive
                      });
                    const collapseItemMini =
                      classes.collapseItemMini +
                      " " +
                      cx({
                        [classes.collapseItemMiniRTL]: rtlActive
                      });
                    return (
                      <ListItem
                        key={key}
                        className={cx(
                          { [classes.item]: prop.icon !== undefined },
                          { [classes.collapseItem]: prop.icon === undefined }
                        )}
                      >
                        <NavLink
                          to={"#"}
                          className={navLinkClasses}
                          onClick={e => {
                            e.preventDefault();
                            this.setState(st);
                          }}
                        >
                          {prop.icon !== undefined ? (
                            typeof prop.icon === "string" ? (
                              <Icon className={itemIcon}>{prop.icon}</Icon>
                            ) : (
                                <prop.icon className={itemIcon} />
                              )
                          ) : (
                              <span className={collapseItemMini}>
                                {rtlActive ? prop.rtlMini : prop.mini}
                              </span>
                            )}
                          <ListItemText
                            primary={rtlActive ? prop.rtlName : prop.name}
                            secondary={
                              <b
                                className={
                                  caret +
                                  " " +
                                  (this.state[prop.state] ? classes.caretActive : "")
                                }
                              />
                            }
                            disableTypography={true}
                            className={cx(
                              { [itemText]: prop.icon !== undefined },
                              { [collapseItemText]: prop.icon === undefined }
                            )}
                          />
                        </NavLink>
                        <Collapse in={this.state[prop.state]} unmountOnExit>
                          <List className={classes.list + " " + classes.collapseList}>
                            {this.createLinks(prop.views)}
                          </List>
                        </Collapse>
                      </ListItem>
                    );
                  }
                  const innerNavLinkClasses =
                    classes.collapseItemLink +
                    " " +
                    cx({
                      [" " + classes[color]]: this.activeRoute(prop.path)
                    });
                  const collapseItemMini =
                    classes.collapseItemMini +
                    " " +
                    cx({
                      [classes.collapseItemMiniRTL]: rtlActive
                    });
                  const navLinkClasses =
                    classes.itemLink +
                    " " +
                    cx({
                      [" " + classes[color]]: this.activeRoute(prop.path)
                    });
                  const itemText =
                    classes.itemText +
                    " " +
                    cx({
                      [classes.itemTextMini]:
                        this.props.miniActive && this.state.miniActive,
                      [classes.itemTextMiniRTL]:
                        rtlActive && this.props.miniActive && this.state.miniActive,
                      [classes.itemTextRTL]: rtlActive
                    });
                  const collapseItemText =
                    classes.collapseItemText +
                    " " +
                    cx({
                      [classes.collapseItemTextMini]:
                        this.props.miniActive && this.state.miniActive,
                      [classes.collapseItemTextMiniRTL]:
                        rtlActive && this.props.miniActive && this.state.miniActive,
                      [classes.collapseItemTextRTL]: rtlActive
                    });
                  const itemIcon =
                    classes.itemIcon +
                    " " +
                    cx({
                      [classes.itemIconRTL]: rtlActive
                    });
                  return (
                    <ListItem
                      key={key}
                      className={cx(
                        { [classes.item]: prop.icon !== undefined },
                        { [classes.collapseItem]: prop.icon === undefined }
                      )}
                    >
                      <NavLink
                        to={prop.layout + prop.path}
                        className={cx(
                          { [navLinkClasses]: prop.icon !== undefined },
                          { [innerNavLinkClasses]: prop.icon === undefined }
                        )}
                      >
                        {prop.icon !== undefined ? (
                          typeof prop.icon === "string" ? (
                            <Icon className={itemIcon}>{prop.icon}</Icon>
                          ) : (
                              <prop.icon className={itemIcon} />
                            )
                        ) : (
                            <span className={collapseItemMini}>
                              {rtlActive ? prop.rtlMini : prop.mini}
                            </span>
                          )}
                        <ListItemText
                          primary={rtlActive ? prop.rtlName : prop.name}
                          disableTypography={true}
                          className={cx(
                            { [itemText]: prop.icon !== undefined },
                            { [collapseItemText]: prop.icon === undefined }
                          )}
                        />
                      </NavLink>
                    </ListItem>
                  )
                })}
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    );
    var links = (
      <List className={classes.list}>{this.createLinks(routes)}</List>
    );
    
    var bottomLinks = (
      <List className={classes.listBottom}>{this.createBottomLinks(routes)}</List>
    );

    const logoNormal =
      classes.logoNormal +
      " " +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive
      });
    const logoMini =
      classes.logoMini +
      " " +
      cx({
        [classes.logoMiniRTL]: rtlActive
      });
    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });
    var brand = (
      <div className={logoClasses}>
        <Link to="/admin/projects" className={logoMini}>
          <img src={logo} alt="logo" className={classes.img} />
        </Link>
        <Link to="/admin/projects" className={logoNormal}>
          {logoText}
        </Link>
      </div>
    );
    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
      });
    return (
      <div ref="mainPanel">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              headerLinks={<AdminNavbarLinks rtlActive={rtlActive} />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
              bottomLinks={bottomLinks}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue",
  openAvatar: true,
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue", "purple"]),
  rtlActive: PropTypes.bool,
  openAvatar: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose"
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(sidebarStyle)(Sidebar);
