import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import defaultAvatar from "assets/img/avatar.png";
import { Link } from "react-router-dom";

const avatarStyle = {
  root: {
      '& img': {
        borderRadius: '50%',
        height: '42px',
        width: '42px',
        border: "1px solid #3f39a6",
      },
      height: '42px',
      width: '42px',
      marginTop: '-7px'
    },
}

const Avatar = ({classes, img, userId, style}) => {
  let redirectVar = userId ? userId : "";
  if(userId) 
    return (
      <Link to={`/admin/perfil/${redirectVar}`}>
        <div  className={classes.root} style={style}>
          <img alt='avatar' src={img ? img : defaultAvatar}/>
        </div>
      </Link>  
    )
  else 
    return(
      <div  className={classes.root} style={style}>
        <img alt='avatar' src={img ? img : defaultAvatar}/>
      </div>
    )
}

export default withStyles(avatarStyle)(Avatar);
