import React from "react";
import { withApollo } from "react-apollo";
import Rating from "react-rating";
import withStyles from "@material-ui/core/styles/withStyles";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import TextField from "@material-ui/core/TextField";
import Modal from "components/ModalWrapper/ModalWrapper";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { ContextConsumer } from "ContextController";
import { ADD_REVIEW_MUTATION } from "./ReviewQueries";
import ReviewStyles from "./ReviewStyles";

class ReviewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedUser: null,
      ratings: [],
      reviewText: "",
      orgIndex: 0
    };
    this.state.ratings = [5, 5, 5, 5];
  }

  changeRating(index, rating) {
    let ratings = this.state.ratings;
    ratings[index] = rating;
    this.setState({ ratings });
  }

  changeReviewText(text) {
    this.setState({ reviewText: text });
  }

  onSubmit() {
    const { archiveProject, toggleModal, project, client } = this.props;
    const { ratings, reviewText, orgIndex } = this.state;
    client.mutate({
      mutation: ADD_REVIEW_MUTATION,
      variables: {
        projectId: project.id,
        organizationId: project.collaborativeOrgs[orgIndex].organization.id,
        ratings: [
          {
            name: "comunication",
            rating: ratings[0]
          },
          {
            name: "punctuality",
            rating: ratings[1]
          },
          {
            name: "quality",
            rating: ratings[2]
          },
          {
            name: "co-production",
            rating: ratings[3]
          }
        ],
        reviewNotes: reviewText
      }
    });
    archiveProject();
    toggleModal();
  }

  getReviewedOrgName(project, orgIndex) {
    if(!project || !project.collaborativeOrgs || !project.collaborativeOrgs[orgIndex]) 
      return "";
    else return project.collaborativeOrgs[orgIndex].organization? project.collaborativeOrgs[orgIndex].organization.name : "missing";
  }

  render() {
    const { classes, open, toggleModal, project } = this.props;
    const { loggedUser, ratings, reviewText, orgIndex } = this.state;
    return (
      <ContextConsumer>
        {context => {
          if (!loggedUser) {
            let _loggedUser = context.getUser();
            this.setState({ loggedUser: _loggedUser });
          }
          return (
            <Modal
              open={open}
              onBackdropClick={toggleModal}
              onClose={toggleModal}
            >
              <GridContainer className={classes.modal} justify="center">
                <GridItem style={{ padding: 0 }} xs={12} sm={12}>
                  <h3 className={classes.title}>Avaliação e Feedback</h3>
                </GridItem>
                <GridItem style={{ padding: 0 }} xs={12} sm={12}>
                  <div className={classes.reviewRow}>
                    <p className={classes.mainDescription}>
                      Faça aqui sua avaliação de como foi a performance da
                      organização {this.getReviewedOrgName(project, orgIndex)} ao decorrer do projeto.
                    </p>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.reviewRow}>
                    <h4 className={classes.reviewName}>COMUNICAÇÃO</h4>
                    <Rating
                      initialRating={ratings[0]}
                      onChange={rating => {
                        this.changeRating(0, rating);
                      }}
                      className={classes.reviewStar}
                      emptySymbol={<StarBorderRoundedIcon fontSize="inherit" />}
                      fullSymbol={<StarRoundedIcon fontSize="inherit" />}
                    />
                  </div>
                  <p className={classes.description}>
                    Como foi a comunicação com a produtora?
                  </p>
                  <hr />
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.reviewRow}>
                    <h4 className={classes.reviewName}>PONTUALIDADE</h4>
                    <Rating
                      initialRating={ratings[1]}
                      onChange={rating => {
                        this.changeRating(1, rating);
                      }}
                      className={classes.reviewStar}
                      emptySymbol={<StarBorderRoundedIcon fontSize="inherit" />}
                      fullSymbol={<StarRoundedIcon fontSize="inherit" />}
                    />
                  </div>
                  <p className={classes.description}>
                    A produção terminou no cronograma previsto?
                  </p>
                  <hr />
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.reviewRow}>
                    <h4 className={classes.reviewName}>QUALIDADE</h4>
                    <Rating
                      initialRating={ratings[2]}
                      onChange={rating => {
                        this.changeRating(2, rating);
                      }}
                      className={classes.reviewStar}
                      emptySymbol={<StarBorderRoundedIcon fontSize="inherit" />}
                      fullSymbol={<StarRoundedIcon fontSize="inherit" />}
                    />
                  </div>
                  <p className={classes.description}>
                    Qual a sua avaliação sobre o resultado final?
                  </p>
                  <hr />
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.reviewRow}>
                    <h4 className={classes.reviewName}>COPRODUÇÃO</h4>
                    <Rating
                      initialRating={ratings[3]}
                      onChange={rating => {
                        this.changeRating(3, rating);
                      }}
                      className={classes.reviewStar}
                      emptySymbol={<StarBorderRoundedIcon fontSize="inherit" />}
                      fullSymbol={<StarRoundedIcon fontSize="inherit" />}
                    />
                  </div>
                  <p className={classes.description}>Como foi a experiência?</p>
                  <hr />
                </GridItem>
                <GridItem xs={12}>
                  <TextField
                    Outlined
                    label="Deixe um feedback sobre o projeto"
                    multiline
                    variant="outlined"
                    style={{ marginTop: 25, marginBottom: 15 }}
                    value={reviewText}
                    onChange={event =>
                      this.changeReviewText(event.target.value)
                    }
                    fullWidth
                    rows={4}
                    value={this.state.commentText}
                    disableUnderline
                    classes={classes.reviewText}
                  />
                </GridItem>
                <GridItem xs={12} className={classes.footer}>
                  <Button color="transparent" onClick={toggleModal}>
                    Cancelar
                  </Button>
                  <Button color="purple" onClick={() => this.onSubmit()}>
                    Enviar
                  </Button>
                </GridItem>
              </GridContainer>
            </Modal>
          );
        }}
      </ContextConsumer>
    );
  }
}

export default withApollo(withStyles(ReviewStyles)(ReviewModal));
