import { gql } from "apollo-boost";

const DOCUMENTS_FRAG = gql`
  fragment documentsFrag on Document {
    id
    name
    status
    fileKey
    downloadLink
    creationDate
    tags {
      id
      name
      color
    }
    uploader {
      id
      name
      image {
        id
        imageUrl
      }
    }
  }
`;

const VERSIONS_FRAG = gql`
  fragment versionsFrag on Version {
    juridicalComments {
      id
    }
    name
    id
    fileKey
    count
    creationDate
    uploader {
      id
      name
      image {
        id
        imageUrl
      }
    }
  }
`;

const GET_CONTENT_FROM_FOLDER_QUERY = gql`
  ${VERSIONS_FRAG}
  ${DOCUMENTS_FRAG}
  query GET_CONTENT_FROM_FOLDER_QUERY($projectId: ID!, $folderId: ID){
    juridicalContents(projectId:$projectId, folderId:$folderId){
      id
      name
      type
      juridical
      element {
        id
        name
      }
      phase {
        id
        name
      }
      juridicalElement {
        id
        name
      }
      juridicalPhase {
        id
        name
      }
      status
      juridicalStatus
      finalVersionDependsOn
      project {
        id
      }
      folder {
        id
      }
      juridicalFolder{
        id
      }
      latestVersion {
        ...versionsFrag
      }
      documents {
        ...documentsFrag
      }
    }
  }
`;


const EDIT_MANY_JURIDICAL_CONTENTS = gql`
mutation EDIT_MANY_JURIDICAL_CONTENTS($ids: [ID!]!, $folderId: ID!) {
    editManyJuridicalContents(ids: $ids, folderId: $folderId) { 
        id
        name
    }
}
`;

const CHANGE_JURIDICAL_FOLDER_MUTATION = gql`
  mutation CHANGE_JURIDICAL_FOLDER_MUTATION($id: ID!, $folderId:ID!) {
    editJuridicalContent(id:$id, folderId:$folderId) {
      id
      name
    }
  }
`;


const GET_JURIDICAL_FOLDERS_QUERY = gql`
  query GET_JURIDICAL_FOLDERS_QUERY($projectId: ID!) {
    juridicalFolders(projectId:$projectId) {
      id
      name
      contents {
        id
        name
      }
      subFolders {
        id
        name
        parentFolder{
          id
        }
      }
    }
  }
`;

const EDIT_JURIDICAL_FOLDER_MUTATION = gql`
  mutation EDIT_JURIDICAL_FOLDER_MUTATION($id: ID!, $name: String, $parentFolderId: ID, $secret: Boolean) {
    editJuridicalFolder(id: $id, name: $name, parentFolderId: $parentFolderId, secret: $secret){
      id
    }
  }
`;

const ADD_JURIDICAL_FOLDER_MUTATION = gql`
  mutation ADD_JURIDICAL_FOLDER_MUTATION($projectId: ID!, $name: String!, $parentFolderId: ID) {
    addJuridicalFolder(projectId:$projectId, name: $name, parentFolderId: $parentFolderId) {
      id
      name
    }
  }
`;
const DELETE_JURIDICAL_FOLDER_MUTATION = gql`
  mutation DELETE_JURIDICAL_FOLDER_MUTATION($id: ID!) {
    deleteJuridicalFolder(id: $id) {
      id
      name
    }
  }
`
const DELETE_CONTENT_FROM_JURIDICAL_MUTATION = gql`
  mutation DELETE_CONTENT_FROM_JURIDICAL_MUTATION($id: ID!, $juridical: Boolean) {
    editContent(id:$id, juridical: $juridical) {
      id
      name
    }
  }
`;

const EDIT_CONTENT_STATUS = gql`
  mutation EDIT_CONTENT_STATUS($id:ID!, $status: String!){ 
    editContentStatus(id:$id, status: $status){
      status
    }
  }
`;

const EDIT_JURIDICAL_STATUS = gql`
  mutation EDIT_JURIDICAL_STATUS($id:ID!, $status: String!){ 
    editJuridicalContentStatus(id:$id, status: $status){
      status
    }
  }
`;

const DELETE_MANY_CONTENTS_MUTATION = gql`
mutation DELETE_MANY_CONTENTS_MUTATION($ids: [ID!]!) {
    editManyContents(ids: $ids, juridical: false) {
        id
        name
    }
}
`;

const EDIT_MANY_JURIDICAL_STATUS_MUTATION = gql`
  mutation EDIT_MANY_JURIDICAL_STATUS_MUTATION($ids: [ID!]!, $status: String!) {
    editManyJuridicalContentsStatus(ids: $ids, status: $status) {
      id
      name
      status
    }
  }
`;

const GET_MANY_CONTENTS_DOWNLOAD_LINK = gql`
  query GET_MANY_CONTENTS_DOWNLOAD_LINK($ids: [ID]!){
    downloadManyContents(ids: $ids)
}`;
const GET_CONTENT_DOWNLOAD_LINK = gql`
  query GET_CONTENT_DOWNLOAD_LINK($id: ID!){
    downloadContent(id: $id)
}`;

const deleteManyContents = ({ids, client, params}) => {
  ids = ids.filter((data) => data)
  client.mutate({
    mutation: DELETE_MANY_CONTENTS_MUTATION,
    variables: { ids },
    refetchQueries: () => [{
      query: GET_CONTENT_FROM_FOLDER_QUERY,
      variables: { projectId: params.projectId, folderId: params.folderId }
    },
    {
      query: GET_JURIDICAL_FOLDERS_QUERY,
      variables: { projectId: params.projectId }
    }]
  })
}

const editJuridicalFolder = ({params, client}) => {
  client.mutate({
    mutation: EDIT_JURIDICAL_FOLDER_MUTATION,
    variables: { projectId: params.projectId, id: params.id ,name: params.folderName ? params.folderName : "Sem Nome" },
    refetchQueries: () => [{
      query: GET_JURIDICAL_FOLDERS_QUERY,
      variables: { projectId: params.projectId }
    }]
  })
}


const addJuridicalFolderMutation = ({params, client}) => {
  client.mutate({
    mutation: ADD_JURIDICAL_FOLDER_MUTATION,
    variables: {projectId: params.projectId, name: params.folderName, parentFolderId: params.parentFolderId },
    refetchQueries: () => [{
      query: GET_JURIDICAL_FOLDERS_QUERY,
      variables: {
        projectId: params.projectId
      },
    },
    {
      query: GET_JURIDICAL_FOLDERS_QUERY,
      variables: { projectId: params.projectId }
    }]
  })
}

const editStatus = ({params, client}) => {
  client.mutate({
    mutation: EDIT_CONTENT_STATUS,
    variables: {id: params.id, status: params.status},
    refetchQueries: () => [{
      query: GET_CONTENT_FROM_FOLDER_QUERY,
      variables: { projectId: params.projectId, folderId: params.folderId }
    }]
  })
}

const editJuridicalStatus = ({params, client}) => {
  client.mutate({
    mutation: EDIT_JURIDICAL_STATUS,
    variables: {id: params.id, status: params.status},
    refetchQueries: () => [{
      query: GET_CONTENT_FROM_FOLDER_QUERY,
      variables: { projectId: params.projectId, folderId: params.folderId }
    }]
  })
}

const editManyJuridicalStatusMutation = ({client, params}) => {
  const ids = params.ids.filter((data) => data);
  client.mutate({
    mutation: EDIT_MANY_JURIDICAL_STATUS_MUTATION,
    variables: { ids: ids, status: params.status },
    refetchQueries: () => [{
      query: GET_CONTENT_FROM_FOLDER_QUERY,
      variables: { projectId: params.projectId, folderId: params.folderId }
    }]
  })
}

const deleteContent = ({contentId, client, params}) => {
  client.mutate({
    mutation: DELETE_CONTENT_FROM_JURIDICAL_MUTATION,
    variables: { id: contentId, juridical: false },
    refetchQueries: () => [{
      query: GET_CONTENT_FROM_FOLDER_QUERY,
      variables: { projectId: params.projectId, folderId: params.folderId }
    },
    {
      query: GET_JURIDICAL_FOLDERS_QUERY,
      variables: { projectId: params.projectId }
    }]
  })
}


const downloadContent = ({contentId, client, cb}) => {
  client.query({
    query: GET_CONTENT_DOWNLOAD_LINK,
    variables: { id: contentId },
  }).then(res => {
    cb(res.data.downloadContent);
  })
}

const downloadManyContents = ({contentsId, client, cb}) => {
  contentsId = contentsId.filter((id) => !!id)
  client.query({
    query: GET_MANY_CONTENTS_DOWNLOAD_LINK,
    variables: { ids: contentsId },
  }).then(res => {
    cb(res.data.downloadManyContents);
  })
}

const changeManyJuridicalFolders = ({client, params}) => {
  const ids = params.ids.filter((data) => data);
  client.mutate({
    mutation: EDIT_MANY_JURIDICAL_CONTENTS,
    variables: { ids: ids, folderId: params.folderId },
    refetchQueries: () => [{
      query: GET_CONTENT_FROM_FOLDER_QUERY,
      variables: { projectId: params.projectId, folderId: params.selectedFolderId }
    },{
      query: GET_CONTENT_FROM_FOLDER_QUERY,
      variables: { projectId: params.projectId, folderId: params.folderId }
    },
    {
      query: GET_JURIDICAL_FOLDERS_QUERY,
      variables: { projectId: params.projectId }
    }]
  })
}


const addJuridicalFolder = ({params, client}) => {
  client.mutate({
    mutation: ADD_JURIDICAL_FOLDER_MUTATION,
    variables: { projectId: params.projectId, name: params.folderName ? params.folderName : "Sem Nome", parentFolderId: params.parentFolderId },
    refetchQueries: () => [{
      query: GET_JURIDICAL_FOLDERS_QUERY,
      variables: { projectId: params.projectId, parentFolderId: params.parentFolderId }
    }]
  })
}

const changeJuridicalFolder = ({params, client}) => {
  client.mutate({
    mutation: CHANGE_JURIDICAL_FOLDER_MUTATION,
    variables: { id: params.contentId, folderId: params.folderId, juridical: true },
    refetchQueries: () => [{
      query: GET_JURIDICAL_FOLDERS_QUERY,
      variables: { projectId: params.projectId }
    }, {
      query: GET_CONTENT_FROM_FOLDER_QUERY,
      variables: { projectId: params.projectId, folderId: params.selectedFolderId }
    }, {
      query: GET_JURIDICAL_FOLDERS_QUERY,
      variables: { projectId: params.projectId }
    }]
  })
}

// const sendToJuridicalFolder = ({params, client}) => {
//   client.mutate({
//     mutation: CHANGE_JURIDICAL_FOLDER_MUTATION,
//     variables: { id: params.contentId, folderId: params.folderId, juridical: true },
//     refetchQueries: () => [{
//       query: GET_CONTENT_FROM_FOLDER_QUERY,
//       variables: { projectId: params.projectId, folderId: params.selectedFolderId }
//     }]
//   })
// }


const deleteJuridicalFolder = ({params, client}) => {
  client.mutate({
    mutation: DELETE_JURIDICAL_FOLDER_MUTATION,
    variables: { id: params.folderId },
    refetchQueries: () => [{
      query: GET_JURIDICAL_FOLDERS_QUERY,
      variables: { projectId: params.projectId }
    }]
  })
}

const JURIDICAL_CONTENT_SUBSCRIPTION = gql`
  ${VERSIONS_FRAG}
  ${DOCUMENTS_FRAG}
  subscription JURIDICAL_CONTENT_SUBSCRIPTION($juridicalFolderId: ID!){
    contentChange(folderId:$juridicalFolderId){
      id 
      name
      type
      juridical
      status
      juridicalStatus
      project {
        id
      }
      folder {
        id
      }
      latestVersion {
        ...versionsFrag
      }
      documents {
        ...documentsFrag
      }
    }
  }
`;

export {
  editStatus,
  deleteContent,
  downloadContent,
  addJuridicalFolder,
  deleteManyContents,
  editJuridicalStatus,
  editManyJuridicalStatusMutation,
  editJuridicalFolder,
  downloadManyContents,
  deleteJuridicalFolder,
  changeJuridicalFolder,
  changeManyJuridicalFolders,
  addJuridicalFolderMutation,
  GET_JURIDICAL_FOLDERS_QUERY,
  GET_CONTENT_FROM_FOLDER_QUERY,
  EDIT_JURIDICAL_FOLDER_MUTATION,
  JURIDICAL_CONTENT_SUBSCRIPTION,
}
