import React, { Component } from "react";
import { gql } from "apollo-boost";
import { graphql, Mutation } from "react-apollo";
import { Redirect } from "react-router-dom";

import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { hashPassword } from "utils/hashPassword";
import Logo from "../../shared/Logo";
import authenticationBg from "../../shared/authenticationBg.svg";
import { ApolloConsumer } from "react-apollo";

const changeUserPassword = gql`
  mutation changeUserPassword($password: String!, $passwordSalt: String!) {
    changeUserPassword(password: $password, passwordSalt: $passwordSalt) {
      id
    }
  }
`;

class RequestPassword extends Component {
  constructor(props) {
    super(props);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.state = {
      redirect: false,
      error: null,
      password: undefined,
      rePassword: undefined
    };
  }

  async sendPassword(changePassword) {
    const { match } = this.props;
    if (!this.state.password)
      return this.setState({ error: "Digite uma senha" });
    if (this.state.password !== this.state.rePassword)
      return this.setState({ error: "Senhas diferentes" });
    const { salt, password } = await hashPassword(this.state.password);

    const context = { headers: { newpassword: match.params.token } };
    try {
      let res = await changePassword({
        context,
        variables: {
          password: password,
          passwordSalt: salt
        }
      });
      if (!res.error) this.setState({ redirect: true });
    } catch (e) {
      console.log("Error:", e);
      window.alert("Erro mudando a senha");
    }
  }

  handleChange(prop, event) {
    this.setState({ [prop]: event.target.value });
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    const classes = this.props.classes;

    return (
      <main className={classes.main}>
        {this.state.redirect && <Redirect from="/" to="/login" />}
        <Paper className={classes.paper}>
          <div className={classes.logo}>
            <Logo />
          </div>
          <Typography component="h1" variant="h5">
            {this.props.title}
          </Typography>
          <ApolloConsumer>
            {client => (
              <Mutation mutation={changeUserPassword}>
                {sendPassword => (
                  <form
                    className={classes.form}
                    onSubmit={async event => {
                      event.preventDefault();
                      this.sendPassword(sendPassword, client);
                    }}
                  >
                    <div className={classes.title}> Trocar Senha </div>
                    <div className={classes.subtitle}>
                      {" "}
                      Digite a nova senha e confirme no campo abaixo
                    </div>
                    {this.state.error ? (
                      <div style={{ color: "RED" }}> {this.state.error} </div>
                    ) : (
                      ""
                    )}
                    <TextField
                      id="password"
                      label="Senha"
                      name="password"
                      autoComplete=""
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      type="password"
                      autoFocus
                      required
                      value={this.state.password}
                      onChange={event => this.handleChange("password", event)}
                    />
                    <TextField
                      id="rePassword"
                      label="Repita a Senha"
                      name="repassword"
                      autoComplete=""
                      margin="normal"
                      variant="outlined"
                      type="password"
                      fullWidth
                      autoFocus
                      required
                      value={this.state.rePassword}
                      onChange={event => this.handleChange("rePassword", event)}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      size="large"
                      fullWidth
                    >
                      Mudar Senha
                    </Button>
                  </form>
                )}
              </Mutation>
            )}
          </ApolloConsumer>
        </Paper>
      </main>
    );
  }
}

RequestPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

const styles = theme => ({
  form: {
    width: "100%",
    marginTop: theme.spacing.unit
  },
  title: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "montserrat, sans-serif",
    margintop: 10,
    marginBottom: 10
  },
  subtitle: {
    marginBottom: 20
  },
  submit: {
    marginTop: theme.spacing.unit
  },
  link: {
    marginTop: theme.spacing.unit * 3,
    display: "block",
    textDecoration: "none"
  },
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "100vw",
    minHeight: "100vh",
    backgroundColor: "#ffd600",
    backgroundImage: `url(${authenticationBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center center"
  },
  paper: {
    textAlign: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400
    }
  },
  logo: {
    width: "128px",
    height: "128px",
    margin: "0 auto",
    marginBottom: theme.spacing.unit * 3
  }
});

const StyledRequestPassword = withStyles(styles)(RequestPassword);

export default graphql(changeUserPassword)(StyledRequestPassword);
