import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tabs from "components/Tabs/Tabs.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import avatar from "assets/img/avatar.png";

import UsersAndPermissionsStyle from "./UsersAndPermissionsStyle.js";
import OrgsTable from "./Tables/OrgsTable.jsx";
import UsersTable from "./Tables/UsersTable.jsx";
import PermissionsTable from "./Tables/PermissionsTable.jsx";
import { Query } from "react-apollo";
import { MY_ORG_QUERY_WITH_ROLES_MEMBERS_AND_COLLABORATORS } from "queries/organizationQueries";
import Loading from "components/Loading/Loading.jsx";
import { ContextConsumer } from "ContextController.js";

class UsersAndPermissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "USUÁRIOS",
      openNewTeam: false,
      tabs: ["USUÁRIOS", "PERMISSÕES"],
      icons: ["account_circle", "lock"],
      openUserInvite: false
    };
    this.successAlert = this.successAlert.bind(this);
  }

  toggleModal = () => {
    this.setState({ openNewTeam: !this.state.openNewTeam });
  };

  successAlert(msg) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={msg ? msg : "Convites bem sucedido!"}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.purpleButton + " " + this.props.classes.success
          }
        >
          {msg ? (
            <span>{msg}</span>
          ) : (
            <span> Convites enviados com sucesso </span>
          )}
        </SweetAlert>
      )
    });
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  render() {
    const { classes } = this.props;
    const { tabs, currentTab, icons, alert } = this.state;
    return (
      <div>
        {alert}
        <ContextConsumer>
          {context => {
            if (!this.state.loggedUser)
              this.setState({ loggedUser: context.getUser() });
            return (
              <Query query={MY_ORG_QUERY_WITH_ROLES_MEMBERS_AND_COLLABORATORS}>
                {({ error, loading, data, refetch }) => {
                  if (error) return <h3>Erro:{error}</h3>;
                  if (loading) return <Loading />;
                  let myOrg = data.myOrganization;

                  if (!data.myOrganization) {
                    refetch();
                    return <Loading />;
                  }

                  let partnerOrgs = [];
                  /** checks every collaboration of each project and if it does not match any
                   * from collaborativeOrgs array above the collaboration is pushed to the array */
                  data.myOrganization.projects.forEach(project =>
                    project.collaborativeOrgs.forEach(collab => {
                      if (!collab.organization) {
                        return null;
                      }
                      if (
                        !partnerOrgs.some(
                          existingCollab =>
                            existingCollab.name === collab.organization.name
                        )
                      )
                        partnerOrgs.push({
                          name: collab.organization.name,
                          img: collab.organization.image
                            ? collab.organization.image.imageUrl
                            : ""
                        });
                    })
                  );

                  return (
                    <>
                      <GridItem style={{ padding: "0", marginTop: 45 }} xs={12}>
                        <List className={classes.list}>
                          <ListItem className={classes.listItem}>
                            <span className={classes.title}>
                              Organização e Permissões
                            </span>
                          </ListItem>
                          <ListItem
                            className={
                              classes.listItem + " " + classes.tabsWrapper
                            }
                          >
                            <Tabs
                              tabs={tabs}
                              icons={icons}
                              currentTab={currentTab}
                              setCurrentTab={tab =>
                                this.setState({ currentTab: tab })
                              }
                            />
                          </ListItem>
                        </List>
                      </GridItem>

                      <div className={classes.root}>
                        {currentTab === tabs[0] ? (
                          <>
                            <OrgsTable
                              partnerOrgs={partnerOrgs}
                              myOrgImg={
                                myOrg.image ? myOrg.image.imageUrl : avatar
                              }
                              orgName={myOrg.name}
                              myOrgId={myOrg.id}
                              orgMembers={myOrg.members.length}
                              successAlert={this.successAlert}
                              loggedUser={this.state.loggedUser}
                            />
                            <UsersTable
                              successAlert={this.successAlert}
                              loggedUser={this.state.loggedUser}
                            />
                          </>
                        ) : (
                          <PermissionsTable
                            roleData={data.myOrganization.roles}
                          />
                        )}
                      </div>
                    </>
                  );
                }}
              </Query>
            );
          }}
        </ContextConsumer>
      </div>
    );
  }
}

export default withStyles({ ...UsersAndPermissionsStyle, ...sweetAlertStyle })(
  UsersAndPermissions
);
