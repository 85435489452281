const style = {
    root: {
        backgroundColor: 'white',
        margin: '0 auto',
        top: '50px',
        zIndex: '131',
        position: 'relative',
        padding: '15px 25px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '6px',
        width:"40vw", 
        minHeight:"140px", 
        height:"auto", 
        overflowY:"visible"
    },
    switchWrapper: {
        display: 'flex',
        marginTop: 5,
        marginBottom: 20,
        '& > div': {
            marginTop: 8
        }
    },
    title: {
        fontSize: '20px',
        fontWeight: 400,
        marginLeft: 30
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
    },
    projectName: {
        marginRight: 15,
        marginTop: 13
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    
    input: {
        width: '100%'
    },
    modalName: {
        fontSize: '20px'
    },
    close: {
        cursor: 'pointer',
    },
    scrollable: {
        overflow: 'auto'
    }
}

export default style;