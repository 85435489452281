import React from "react";
import { withStyles } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ReactTable from "react-table";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import AddCircle from "@material-ui/icons/AddCircle";
import Avatar from "components/Avatar/Avatar.jsx";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";

import UsersAndPermissionsStyle from "../UsersAndPermissionsStyle.js";
import UserInviteModal from "../../../components/User/UserInviteModal.jsx";
import gql from "graphql-tag";
import { Query, withApollo } from "react-apollo";
import moment from "moment";
import Loading from "components/Loading/Loading.jsx";
import RoleBadge from "../../../components/Role/RoleBadge/RoleBadge.jsx";
import { Redirect } from "react-router-dom";
import CustomMenu from "components/CustomMenu/CustomMenu.jsx";

import VerificationDialog from "components/VerificationDialog/VerificationDialog";

const MY_ORG_QUERY = gql`
  query MY_ORG_QUERY {
    myOrganization {
      id
      name
      roles {
        id
        name
        higherThanUser
      }
      members {
        id
        name
        lastLogin
        role {
          id
          name
          color
        }
        image {
          id
          imageUrl
        }
      }
    }
  }
`;

const EDIT_USER_ROLE = gql`
  mutation EDIT_USER_ROLE($id: ID!, $roleId: ID!) {
    changeUserRole(id: $id, roleId: $roleId) {
      name
      id
      role {
        id
        name
      }
    }
  }
`;

const DELETE_USER_MUTATION = gql`
  mutation DELETE_USER_MUTATION($id: ID!) {
    deleteUser(id: $id) {
      id
      name
    }
  }
`;

class UsersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInviteOpen: false,
      moreVertOpen: false,
      anchorMoreVert: undefined,
      isRedirected: false,
      roles: null
    };
  }
  getAvatarHeader = (name, img, userId) => {
    const { classes } = this.props;
    return (
      <div className={classes.avatarHeaderWrapper} user-name={name}>
        <Avatar img={img} userId={userId} />
        <span className={classes.tableCollumn}> {name} </span>
      </div>
    );
  };

  getOptions = ({ currentRow, rowUser, loggedUser }) => {
    const { classes } = this.props;
    const { moreVertOpen, anchorMoreVert } = this.state;
    let roles = this.state.roles ? this.state.roles : [];
    const moreOptions = [
      {
        icon: "account_circle",
        name: "Acessar Perfil",
        func: () => {
          this.setState({ isRedirected: "/admin/perfil/" + rowUser.id });
        }
      }
    ];

    if (loggedUser && loggedUser.role.permissions.includes("edit-user"))
      moreOptions.push(
        {
          icon: "edit",
          name: "Editar cargo",
          func: () => {},
          subOptions: roles.map(role => {
            return {
              name: role.name,
              colorIcon: role.color,
              icon: "fiber_manual_record",
              func: ({ id }) => {
                this.props.client.mutate({
                  mutation: EDIT_USER_ROLE,
                  variables: { roleId: role.id, id },
                  refetchQueries: () => [
                    {
                      query: MY_ORG_QUERY
                    }
                  ]
                });
              }
            };
          })
        },
        {
          icon: "delete",
          name: "Excluir usuário",
          func: async () => {
            this.setState({ deletingWarning: currentRow });
          }
        }
      );

    return (
      <div>
        <IconButton
          aria-label="More"
          aria-haspopup="true"
          classes={{ root: classes.iconButton }}
        >
          <div className="">
            <MoreVertIcon
              onClick={e => {
                e.stopPropagation();
                if (e) {
                  this.setState({
                    moreVertOpen: currentRow,
                    anchorMoreVert: e.currentTarget
                  });
                }
              }}
            />
          </div>
        </IconButton>
        {this.state.deletingWarning === currentRow && (
          <VerificationDialog
            open={this.state.deletingWarning === currentRow}
            title={"Você tem certeza que quer deletar o usuário?"}
            handleClose={async () => {
              this.setState({ deletingWarning: "" });
            }}
            onAccept={async () => {
              await this.props.client.mutate({
                mutation: DELETE_USER_MUTATION,
                variables: { id: rowUser.id },
                refetchQueries: () => [
                  {
                    query: MY_ORG_QUERY
                  }
                ]
              });
              this.setState({ deletingWarning: "" });
            }}
          />
        )}
        {moreVertOpen === currentRow && loggedUser && (
          <CustomMenu
            options={moreOptions}
            callBackArgs={{ id: rowUser.id }}
            isOpen={moreVertOpen === currentRow}
            onClose={e => {
              this.setState({ moreVertOpen: -1 });
              e.stopPropagation();
            }}
            anchorEl={anchorMoreVert}
            style={{
              style: {
                maxHeight: 45 * 10,
                width: 210,
                marginTop: 0,
                marginLeft: -40,
                textAlign: "center",
                borderRadius: "15px"
              }
            }}
          />
        )}
      </div>
    );
  };

  defaultSortTable(a, b) {
    if (a.props["user-name"]) {
      //sortName
      a = a.props["user-name"].toLowerCase();
      b = b.props["user-name"].toLowerCase();
    } else if (a.props["org-name"]) {
      //sort org-name
      a = a.props["org-name"].toLowerCase();
      b = b.props["org-name"].toLowerCase();
    } else if (a.props["last-access"] !== undefined) {
      //sort date
      a = moment(a.props["last-access"], "DD/MM/YYYY");
      b = moment(b.props["last-access"], "DD/MM/YYYY");
    } else if (a.props["permission-name"]) {
      //sort author
      a = a.props["permission-name"].toLowerCase();
      b = b.props["permission-name"].toLowerCase();
    }
    if (a > b) return 1;
    else if (a < b) return -1;
    return 0;
  }

  render() {
    const { classes } = this.props;
    const { searchText } = this.state;

    return (
      <Card classes={{ card: classes.card }}>
        {this.state.isRedirected && <Redirect to={this.state.isRedirected} />}
        {this.state.userInviteOpen && (
          <Query query={MY_ORG_QUERY}>
            {({ error, loading, data }) => {
              if (loading) return <Loading />;
              if (error) return <div>Error:{error}</div>;
              if (!this.state.roles) {
                this.setState({ roles: data.myOrganization.roles });
              }

              return (
                <UserInviteModal
                  open={this.state.userInviteOpen}
                  onClose={() => this.setState({ userInviteOpen: false })}
                  onClick={() => {}}
                  value={""}
                  modalName={"Convidar para " + data.myOrganization.name}
                  placeholder={"Digite o e-mail do usuário"}
                  successAlert={this.props.successAlert}
                />
              );
            }}
          </Query>
        )}
        <GridItem className={classes.headerWrapper} xs={12} md={12}>
          <Button
            onClick={() => {
              this.setState({ userInviteOpen: !this.state.userInviteOpen });
            }}
            classes={{ root: classes.uploadContent }}
            color="primary"
          >
            <span>
              <AddCircle />
            </span>
            Novo Usuário
          </Button>
          <div className={classes.inputWrapper}>
            <CustomInput
              classes={{
                labelRoot: classes.inputLabel,
                formControl: classes.restrainInputWidth,
                underline: classes.underlineInput
              }}
              labelText={<span>Buscar Usuário</span>}
              id="firstname"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event =>
                  this.setState({ searchText: event.target.value }),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Search className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
          </div>
        </GridItem>
        <CardBody>
          <div className={classes.contentViewTable}>
            <Query query={MY_ORG_QUERY}>
              {({ error, loading, data }) => {
                if (error) return <h3>Erro:{error}</h3>;
                if (loading) return <Loading />;
                if (!this.state.roles) {
                  this.setState({ roles: data.myOrganization.roles });
                }
                let _orgName = data.myOrganization.name;
                let _userData = data.myOrganization.members.map(
                  (user, index) => {
                    let lastLogin = user.lastLogin
                      ? moment(parseInt(user.lastLogin)).format("DD/MM/YY")
                      : "N/A";
                    return {
                      name: this.getAvatarHeader(
                        user.name,
                        user.image ? user.image.imageUrl : "",
                        user.id
                      ),
                      permission: (
                        <div permission-name={user.role.name}>
                          {" "}
                          <RoleBadge
                            style={{
                              height: "30px",
                              width: "30px",
                              backgroundColor: "#d8d8d8",
                              paddingTop: "5px",
                              color: "#3F2C78",
                              border: "1px solid #3F2C78"
                            }}
                            role={user.role}
                          />
                        </div>
                      ),
                      org: (
                        <div
                          className={classes.tableCollumn}
                          org-name={_orgName}
                        >
                          {" "}
                          {_orgName}
                        </div>
                      ),
                      lastAccess: (
                        <div
                          className={classes.tableCollumn}
                          last-access={lastLogin}
                        >
                          {lastLogin}
                        </div>
                      ),
                      option: this.getOptions({
                        currentRow: index,
                        rowUser: user,
                        loggedUser: this.props.loggedUser
                      })
                    };
                  }
                );

                if (searchText)
                  _userData = _userData.filter(d => {
                    return d.name.props["user-name"]
                      .toLowerCase()
                      .includes(searchText.toLowerCase());
                  });

                return (
                  <ReactTable
                    data={_userData}
                    defaultSorted={[
                      {
                        id: "lastAccess",
                        desc: true
                      }
                    ]}
                    resizable={false}
                    showPagination={false}
                    pageSize={_userData.length}
                    noDataText="Não foram encontrados usuários"
                    columns={[
                      {
                        Header: (
                          <div className={classes.titleHeader}>
                            <div>Nome</div>
                          </div>
                        ),
                        accessor: "name",
                        sortMethod: this.defaultSortTable,
                        maxWidth: 260
                      },
                      {
                        Header: (
                          <div className={classes.titleHeader}>
                            <div>Cargo</div>
                          </div>
                        ),
                        accessor: "permission",
                        sortMethod: this.defaultSortTable,
                        width: 75
                      },
                      {
                        Header: (
                          <div className={classes.titleHeader}>
                            <div>Organização</div>
                          </div>
                        ),
                        accessor: "org",
                        sortMethod: this.defaultSortTable,
                        maxWidth: 150
                      },
                      {
                        Header: (
                          <div className={classes.titleHeader}>
                            <div>Último Acesso</div>
                          </div>
                        ),
                        accessor: "lastAccess",
                        sortMethod: this.defaultSortTable,
                        maxWidth: 200
                      },
                      {
                        accessor: "option",
                        maxWidth: 50
                      }
                    ]}
                    defaze={10}
                    minRows={4}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                );
              }}
            </Query>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default withApollo(withStyles(UsersAndPermissionsStyle)(UsersTable));
