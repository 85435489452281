import { gql } from "apollo-boost";

const NOTIFICATIONS_QUERY = gql`
  query NOTIFICATIONS_QUERY($offset: Int, $limit: Int) {
    notifications(offset: $offset, limit: $limit) {
      id
      text
      targetUser {
        id
        name
        image {
          id
          imageUrl
        }
      }
      type
      clicked
      creationDate
      project {
        id
        name
        image {
          id
          imageUrl
        }
      }
      agent {
        id
        name
        image {
          id
          imageUrl
        }
      }
      content {
        id
        name
        folder {
          id
          parentFolder {
            id
          }
        }
        juridicalFolder {
          id
          parentFolder {
            id
          }
        }
      }
      juridical
      comment {
        id
        text
      }
      image {
        id
        imageUrl
      }
    }
  }
`;

const CLICK_NOTIFICATION_MUTATION = gql`
  mutation CLICK_NOTIFICATION_MUTATION($id: ID!) {
    clickNotification(id: $id) {
      id
    }
  }
`;
const SEE_NOTIFICATION_MUTATION = gql`
  mutation SEE_NOTIFICATION_MUTATION {
    seeNotifications
  }
`;

const NOTIFICATION_SUBSCRIPTION = gql`
  subscription NOTIFICATION_SUBSCRIPTION {
    newNotification {
      id
      text
      clicked
      targetUser {
        id
        name
        image {
          id
          imageUrl
        }
      }
      type
      creationDate
      project {
        id
        name
        image {
          id
          imageUrl
        }
      }
      agent {
        id
        name
        image {
          id
          imageUrl
        }
      }
      content {
        id
        name
        folder {
          id
          parentFolder {
            id
          }
        }
        juridicalFolder {
          id
          parentFolder {
            id
          }
        }
      }
      juridical
      comment {
        id
        text
      }
      image {
        id
        imageUrl
      }
    }
  }
`;

export {
  SEE_NOTIFICATION_MUTATION,
  CLICK_NOTIFICATION_MUTATION,
  NOTIFICATION_SUBSCRIPTION,
  NOTIFICATIONS_QUERY
};
