import React from 'react';
import { withStyles, Button, Paper } from '@material-ui/core';
import TagCreator from 'components/DocumentTags/TagCreator.jsx';
import CustomPopper from "components/CustomPopper/CustomPopper.jsx";
import { AddCircle } from '@material-ui/icons';
import { Mutation } from 'react-apollo';
import { ADD_DOCUMENT_TAGS_MUTATION, GET_TAGS_FROM_PROJECT_QUERY } from '../../views/Juridical/DocumentTagQueries';

const tagCreatorStyles = {
  root:{
    padding: "2px 4px 4px 4px"
  },
  tagsAnchorBtn:{
    color:"white",
    backgroundColor: '#3F2C78',
    height: '46px',
    minWidth: '93px',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '14px',
    lineHeight: '14px',
    padding: '11px 10px',
    borderRadius: '6px',
    boxShadow: '0 4px 23px 0 rgba(0,0,0,0.12)',
    display: 'flex',
    fontWeight: '500',
    marginBottom: '45px',
    marginTop: '26px',
    marginRight: "15px",
    "&:hover": {
      backgroundColor: '#796aa9',
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: '#3F2C78',
      boxShadow: "none",
    },
    '& svg': {
      color: "white",
      height: '24px'
    },
  },
}

function StandaloneTagCreator({ classes, projectId }) {
    function notifyError(error){
      let errorMessageForUser = "Erro ao adicionar tag"
      console.log(error);
      if(error.message === "GraphQL error: Tag name already in use.")
        errorMessageForUser = "O nome desta Tag já está em uso. Use outro nome ou remova a Tag existente do projeto."
      window.alert(errorMessageForUser);
    }
    let popperToggleRef = React.useRef(null);
    const closePopper = () => popperToggleRef.current.click();
    return (
      <Mutation mutation={ADD_DOCUMENT_TAGS_MUTATION}>
        {(addDocumentTags)=>{
          const buildAddDocTags = (tags) => {
            const mutationVars = { tags: tags.length > 0 ? tags : [tags], id:projectId };
            addDocumentTags({ variables: mutationVars, refetchQueries:() => [{
              query: GET_TAGS_FROM_PROJECT_QUERY,
              variables: { id:projectId }
            }], })
            .catch(error => {
              notifyError(error);
            });
          }
          const popperButtonId = `standaloneTagCreatorButton`;
          return(
              <CustomPopper 
                key={`standaloneTagCreatorPopper`}
                enableClickAway
                buttonComponent={()=> 
                  <div ref={popperToggleRef}>
                    <Button
                      variant="contained" 
                      color="primary"
                      classes={{ root: classes.tagsAnchorBtn }} 
                      id={popperButtonId}
                      >
                      <span>
                        <AddCircle />
                      </span>
                      <span style={{ marginLeft:"8px" }}>Tag</span>
                    </Button>
                  </div>
                  }
              >
                <Paper className={classes.root}>
                  <TagCreator
                    addTagsToProject={buildAddDocTags}
                    onSubmit={closePopper}
                    onClose={closePopper}
                  /> 
                </Paper>
              </CustomPopper>
          );
        }}
      </Mutation>);
}
export default withStyles(tagCreatorStyles)(StandaloneTagCreator);