import React from 'react';
import { compareName } from "components/DocumentTags/DocumentTags";
import { withStyles } from '@material-ui/core';
import VerificationDialog from 'components/VerificationDialog/VerificationDialog';
import { Cancel } from '@material-ui/icons';

const defaultStyles = {
  flatTag: {
    cursor:"pointer",
    width: "fit-content", 
    padding:"3px 6px 3px 6px", 
    borderRadius:"4px", 
    minWidth:"45px", 
    marginLeft:"4px", 
    marginRight:"4px",
    marginTop:"5px",
    color:"white",
    fontFamily: "Montserrat,sans-serif",
    fontSize:"0.7em",
    display:"flex",
    justifyContent:"center"
  },
  flatTagsWrapper: { 
    display: "flex", 
    flexWrap: "wrap",
    justifyContent:"flex-start", 
    padding:"2px 2px 2px 2px", 
    marginLeft:"15px"
  },
  highlight: {
    borderStyle: "none none none solid",
    borderWidth: "5px",
    filter: "brightness(0.8) !important",
    borderColor: "#6E51C9"
  },
  tagFilterWrapper: { 
    display:"flex", 
    alignItems:"center" 
  },
  cancelRoot:{
    fontSize:"15px"
  },
  deleteBtn: {
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    opacity:"0.7",
    marginLeft:"10px",
    "&:hover":{
      opacity:"1"
    },
    
  }
};

function checkIfIsSelected({ selectedClass, selectedTag , tag }) {
  if (tag.name && (tag.name === selectedTag))
    return selectedClass;
  else
    return "";
}

function FlatTagList({ tags, maxTagWidth, classes, overrideClasses, handleClick, selectedTag, onRemove, deleteDialogText}){
  const [openDeleteCheck, setOpenDeleteCheck] = React.useState("");
  const [hoveredTag, setHoveredTag] = React.useState("");

  const flatTagClasses = (overrideClasses) ?
                            overrideClasses : classes ;
  return (
    <div className={flatTagClasses.flatTagsWrapper} onClick={(e) => e.stopPropagation()}>
      {tags.sort(compareName).map((tag, index) => {
          if(!tag || !tag.name) return null;
          const selectedHighlightClass = checkIfIsSelected({ 
            selectedClass: flatTagClasses.highlight, 
            selectedTag, 
            tag });
          let flatTagStyle = { 
            backgroundColor:tag.color, 
            maxWidth:maxTagWidth ? maxTagWidth : "", 
            filter:`drop-shadow(1px 1px 1px ${tag.color})` 
          };
          return(
            <>
            { openDeleteCheck === tag.id && 
              <VerificationDialog
                open={openDeleteCheck === tag.id}
                title={deleteDialogText ? deleteDialogText : 'Você tem certeza que deseja remover esta tag do documento?'}
                handleClose={() => setOpenDeleteCheck("")}
                onAccept={() => {
                  if(onRemove)
                    onRemove([tag.id]);
                  setOpenDeleteCheck("");
                }}
              />
            }
            <div 
              style={flatTagStyle} 
              className={flatTagClasses.flatTag + " " + selectedHighlightClass}
              onClick={() => {
                if(handleClick)
                  handleClick(tag)
              }}
              onMouseEnter={()=> setHoveredTag(tag.id)}
              onMouseLeave={()=> setHoveredTag("")}
            >
              <b>
                {tag.name.trim().toUpperCase()}
              </b>
              { hoveredTag && hoveredTag === tag.id && onRemove && 
                <div 
                  className={classes.deleteBtn}
                  color="primary"
                  onClick={(e)=>{
                    e.stopPropagation();
                    setOpenDeleteCheck(tag.id)
                  }}
                >
                    <Cancel classes={{ root: classes.cancelRoot }}/>
                </div>
              }
            </div>
            </>
          );
      })} 
    </div>);
}

export default withStyles(defaultStyles)(FlatTagList);