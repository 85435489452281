import React from "react";
// used for making the prop types of this component
import { withStyles } from "@material-ui/core";
import { withApollo } from "react-apollo";
import { REGISTER_ORGOWNER_MUTATION } from "./organizationQueries.js";
import axios from "axios";

import jwt from "jsonwebtoken";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import userRegistrationStyle from "./organizationRegistrationStyle.js";
import wizardStyles from "assets/jss/material-dashboard-pro-react/components/wizardStyle";
import Step1 from "views/Register/WizardSteps/Step1.jsx";
import Step2 from "views/Register/WizardSteps/Step2.jsx";
import Step3 from "views/Organization/WizardSteps/Step3.jsx";
import submitImage from "utils/uploadImage.js";
import Logo from "../../shared/Logo";
import { hashPassword } from "utils/hashPassword.js";

import InvalidTokenPage from "../ErrorPages/InvalidTokenPage.jsx";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      password: "",
      email: "",
      passConfirm: "",
      orgName: "",
      subtitle: "Preencha o seu cadastro para começar a usar a TVManager"
    };
    this.setSubtitle = this.setSubtitle.bind(this);
  }

  async emailVerifier(email) {
    //this verification can breakdown if the api is down and should not be used or
    //used only after checking a known good email first
    const verified = await axios({
      url: `https://api.trumail.io/v2/lookups/json?email=${email}`, //email verification open api
      method: "GET"
    }).then((response, error) => {
      if (error) console.log(error);
      return response.data;
    });
    if (
      verified.deliverable &&
      !verified.disposable &&
      verified.hostExists &&
      verified.validFormat
    ) {
      this.setState({ email });
    }
  }

  tokenVerification(token) {
    var decoded = jwt.decode(token);
    // exp is in seconds, while Date.now() returns in milliseconds
    // thus we need to divite Date.now() by 1000
    return decoded.exp > Date.now() / 1000;
  }

  setSubtitle(stepCount) {
    switch (stepCount) {
      case 0:
        this.setState({
          subtitle: "Preencha o seu cadastro para começar a usar a TVManager"
        });
        break;
      case 1:
        this.setState({ subtitle: "Preencha as informações do seu perfil" });
        break;
      case 2:
        this.setState({
          subtitle: "Crie a sua organização pra concluir o cadastro"
        });
        break;
      default:
        break;
    }
  }

  async handleFinish(wizardData) {
    try {
      const { token } = this.props.match.params;
      const context = {
        headers: { newOrgToken: `${token}`, registerOrg: true }
      };
      const { imageId: userImageId } = wizardData.Informações.file
        ? await submitImage(wizardData.Informações.file, context)
        : { userImageId: null };
      const { imageId: orgImageId } = wizardData.Organização.file
        ? await submitImage(wizardData.Organização.file, context)
        : { orgImageId: null };
      let { salt, password } = await hashPassword(wizardData.Cadastro.password);

      let addOrgVariables = {
        name:
          wizardData.Informações.name + " " + wizardData.Informações.surname,
        phoneNumber: String(wizardData.Informações.phone),
        cellphoneNumber: String(wizardData.Informações.cellphone),
        orgName: wizardData.Organização.name,
        orgDescription: wizardData.Organização.orgDescription,
        email: wizardData.Cadastro.email,
        password,
        passwordSalt: salt,
        imageId: userImageId,
        orgImageId
      };
      await this.props.client.mutate({
        mutation: REGISTER_ORGOWNER_MUTATION,
        context,
        variables: addOrgVariables
      });
      window.location.href = "/login";
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { classes } = this.props;
    const { token } = this.props.match.params;
    if (!this.tokenVerification(token)) {
      return <InvalidTokenPage classes={classes} />;
    }

    return (
      <div className={classes.wizzardContainer}>
        <div className={classes.logo}>
          <Logo /> <span> manager </span>
        </div>
        <Wizard
          setSubtitle={this.setSubtitle}
          validate
          classes={{
            wizardHeader: classes.wizardHeader,
            wizardContainer: classes.wizardContainer + " " + classes.root,
            content: classes.wizardContent,
            card: classes.wizardCard,
            footer: classes.wizardFooter,
            left: classes.wizardLeft,
            right: classes.wizardRight
          }}
          steps={[
            { stepName: "Cadastro", stepComponent: Step1, stepId: "Cadastro" },
            {
              stepName: "Informações",
              stepComponent: Step2,
              stepId: "Informações"
            },
            {
              stepName: "Organização",
              stepComponent: Step3,
              stepId: "Organização"
            }
          ]}
          title="Luz, câmera, ação!"
          subtitle={this.state.subtitle}
          finishButtonClick={e => {
            this.handleFinish(e);
          }}
          nextButtonText="Próximo"
          finishButtonText="Concluir Cadastro"
        />
      </div>
    );
  }
}

let RegisterWithStyles = withStyles({
  ...wizardStyles,
  ...userRegistrationStyle
})(Register);
export default withApollo(RegisterWithStyles);
