export default {
  root: {
    backgroundColor: 'white',
    width: '600px',
    minHeight: '500px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '10px 30px',
    marginTop: '50px',
    position: 'relative',
    cursor: 'default',
    borderRadius: "6px"
  },
  modal: {
    overflow: 'auto'
  },
  title: {
    textAlign: 'center',
    marginTop: '25px',
    marginBottom: '10px',
    display: 'flex',
    marginLeft: '30px',
    '& svg': {
      color: '#3F2C78'
    }
  },
  titleInput: {
    marginTop: '-7px',
  },
  subtitle: {
    marginLeft: '30px',
    marginBottom: '40px',
    fontSize: '15px',
    display: 'flex',
    color: '#3C4858'
  },
  section: {
    marginLeft: '30px',
    color: '#AAAAAA',
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '.04em',
    marginTop: '16px',
    textTransform: 'uppercase',
    display: 'block',
    lineHeight: '20px',
    margin: '0 8px 4px 0',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'visible',
  },
  formControlDate: {
    marginRight: '50px',
    width: '100px',
    fontWeight: '#AAAAAA',
    fontSize: '14px'
  },
  dateWrapper: {
    display: 'flex',
    marginTop: 0,
  },
  userWrapper: {
    width: '80px',
    marginRight: '100px',
    marginTop: '-18px',
    color: '#5E6C84',
    fontSize: '13px',
    '& img': {
    }
  },
  avatar: {
    marginTop: '5px',
    marginBottom: '25px'
  },
  close: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    cursor: 'pointer'
  },
  body: {
    padding: '10px 30px',
    textAlign: 'left',
    marginTop: '40px',
    '& h4': {
      fontSize: '14px'
    }
  },
  dropdownWrapper: {
    display: 'flex',
    overflow: 'visible',
    marginBottom: '40px'
  },
  footer: {
    width: '100%',
    padding: '0 25px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '20px',
    marginTop: '50px',
  },
  titleText: {
    fontSize: '25px',
    color: '#3F2C78',
    textAlign: 'left',
    marginLeft: '15px',
    marginTop: '-2px',
    fontFamily: 'Roboto, sans-serif',
    minHeight: '27px !important',
    '&:hover::before': {
      border: 'none !important'
    }
  },
  descriptionText: {
    width: '500px',
    '&::placeholder': {
      fontWeight: '500',
      fontSize: '14px'
    }
  },
  noUnderline: {
    '&::after': {
      border: 'none !important'
    },
    '&::before': {
      border: 'none !important'
    }
  },
  underline: {
    '&::after': {
      borderBottom: '1px solid #D2D2D2 !important'
    },
    '&::before': {
      borderBottom: '1px solid #D2D2D2 !important'
    }
  },
  dropDown: {
    backgroundColor: 'transparent',
    color: '#5c5c5c',
    boxShadow: 'none',
    padding: "10px 5px 5px 0px",
    borderBottom: '1px solid #D2D2D2',
    maxWidth: '400px',
    borderRadius: 0,
    width: '100%',
    marginBottom: '20px',
    textTransform: 'initial',
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: '#5c5c5c',
      boxShadow: 'none',
    },
  },
  dropDownButtonText: {
    fontFamily: "Roboto, sans-serif !important",
    fontSize: '14px',
    color: '#5E6C84',
    fontWeight: '500'
  },
  buttonTextWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%',
  },
  restrainInputWidth: {
    width: '250px',
    marginTop: '-13px',
  },
}