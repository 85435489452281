import React from "react";

// core components
import Wizard from "components/Wizard/Wizard.jsx";

import ProjectInformation from "./WizardSteps/ProjectInformationStep.jsx";
//import PlanningTemplate from "./WizardSteps/PlanningTemplateStep.jsx";
import DeliveryTemplate from "./WizardSteps/DeliveryTemplateStep.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { withApollo } from "react-apollo";
import submitImage from "utils/uploadImage.js";
import { ADD_PROJECT_MUTATION } from "./ProjectTemplateQueries";
import addProjectWizardStyle from "./addProjectWizardStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import wait from "waait";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { Redirect } from "react-router-dom";
import { GET_PROJECTS_QUERY } from "views/Project/ProjectsQueries.js";
import { ContextConsumer } from "ContextController.js";

class AddProject extends React.Component {
  state = {
    open: true,
    alert: null
  };

  successAlert() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Projeto Criado!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          Projeto criado com sucesso.
        </SweetAlert>
      )
    });
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  toggleModal = () => this.setState({ open: !this.state.open });

  async handleFinish(wizardData) {
    const { client } = this.props;
    try {
      let addProjectVariables = {};
      let deliveryPhases = wizardData.Entregas.selected.map(phase => {
        return {
          name: phase,
          endDate: wizardData.Entregas.endDates[phase]
        };
      });

      addProjectVariables = {
        name: wizardData.Sobre.projectName,
        description: wizardData.Sobre.description,
        genre: wizardData.Sobre.genre,
        startDate: wizardData.Sobre.startDate,
        endDate: wizardData.Sobre.endDate,
        //projectTemplateId: wizardData.Modelo.projectTemplateId,
        deliveryTemplatePhases: deliveryPhases,
        deliveryEpisodeCount: parseInt(wizardData.Entregas.numEps)
      };

      if (wizardData.Sobre.file && wizardData.Sobre.file !== "") {
        const { imageId } = await submitImage(wizardData.Sobre.file);
        addProjectVariables = { ...addProjectVariables, imageId: imageId };
      }

      await client.mutate({
        mutation: ADD_PROJECT_MUTATION,
        variables: addProjectVariables,
        refetchQueries: () => [{ query: GET_PROJECTS_QUERY }]
      });

      this.successAlert();
      await wait(3000);
      this.setState({ isRedirected: "/admin/projects" });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { classes } = this.props;
    const { isRedirected } = this.state;
    return (
      <div className={classes.wizzardContainer}>
        {isRedirected && <Redirect to={isRedirected} />}
        {this.state.alert}
        <ContextConsumer>
          {context => {
            if (!this.state.loggedUser)
              this.setState({ loggedUser: context.getUser() });
          }}
        </ContextConsumer>
        {this.state.loggedUser && (
          <Wizard
            validate
            classes={{
              wizardHeader: classes.wizardHeader,
              wizardContainer: classes.wizardContainer,
              content: classes.wizardContent,
              card: classes.wizardCard,
              footer: classes.wizardFooter
            }}
            finishButtonText="Salvar"
            headerNavigationDisabled={true}
            steps={[
              {
                stepName: "Sobre",
                stepComponent: ProjectInformation,
                stepId: "Sobre"
              }, // to add props to step component use stepProps:{prop:value}
              {
                stepName: "Entregas",
                stepComponent: DeliveryTemplate,
                stepId: "Entregas"
              }
              //{ stepName: "Modelo", stepComponent: PlanningTemplate, stepId: "Modelo"}
            ]}
            title="Novo Projeto"
            subtitle="Siga os passos abaixo para criar o seu projeto."
            finishButtonClick={e => {
              this.handleFinish(e);
            }}
            nextButtonText="Próximo"
          />
        )}
      </div>
    );
  }
}
let addProjectWithStyles = withStyles({
  ...addProjectWizardStyle,
  ...sweetAlertStyle
})(AddProject);

export default withApollo(addProjectWithStyles);
