export default {
  modal: {
    overflow: 'auto',
    width: '520px',
    padding: '0px 40px 40px 40px',
    marginTop: '15px',
    marginBottom: '15px',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'white',
    maxHeight:"95vh",
  },
  title: {
    marginTop: '35px',
    marginBottom: '35px',
    textAlign: 'center',
    fontFamily: 'montserrat, sans-serif',
    fontWeight: '400',
    fontSize: '1.825em',
  },
  description: {
    textAlign: 'center',
    marginBottom: '10px',
  },
  mainDescription: {
    textAlign: 'center',
    marginBottom: '35px',
  },
  reviewRow: {
    display: 'flex',
    alignItems: 'center',
  },
  reviewName: {
    width: '220px',
    padding: '4px 10px',
    borderRadius: '25px',
    backgroundColor: '#3F2C78',
    color: 'white',
    textAlign: 'center',
    fontFamily: 'montserrat, sans-serif',
    fontWeight: '400',
    fontSize: '16px',
  },
  reviewStar: {
    paddingLeft: '10px',
    color: '#ffb400',
    fontSize: '30px',
  },
  reviewText: {
    borderStyle: 'solid',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
}
