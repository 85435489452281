import { gql } from "apollo-boost";

export const ADD_REVIEW_MUTATION = gql`
  mutation ADD_REVIEW_MUTATION(
    $projectId: ID!
    $organizationId: ID!
    $ratings: [RatingInput!]!
    $reviewNotes: String!
  ) {
    addReview(
      projectId: $projectId
      organizationId: $organizationId
      ratings: $ratings
      reviewNotes: $reviewNotes
    ) {
      id
    }
  }
`;
