import gql from "graphql-tag";

const SEND_NEW_ORG_EMAIL_MUTATION = gql`
  mutation SEND_NEW_ORG_EMAIL_MUTATION(
      $destination: String!, 
      $projectsIds: [ID], 
      $rolesIds: [ID]){
    sendNewOrganizationEmail(
      destination:$destination,
      projectsIds: $projectsIds,
      rolesIds: $rolesIds
    )
  }
`;

export { SEND_NEW_ORG_EMAIL_MUTATION };