function timeCodeConvert(timeCode) {
  if (!timeCode) return "";
  const subStrings = timeCode.split(".");
  let seconds = subStrings[0];
  let minutes = 0;
  let hours = 0;

  while (seconds >= 60) {
    minutes++;
    seconds -= 60;
  }
  while (minutes >= 60) {
    hours++;
    minutes -= 60;
  }

  minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
  hours = hours.toString().length === 1 ? "0" + hours : hours;
  seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;

  if (parseInt(hours) === 0) return minutes + ":" + seconds;
  else return hours + ":" + minutes + ":" + seconds;
}
export default timeCodeConvert;
