import React, { useState } from "react";
import StepsDropdownStyle from "./StepsDropdownStyle";
import CustomInput from "../CustomInput/CustomInput"
import { withStyles } from "@material-ui/core";

const StepsDropdown = ({row, id, elements, classes, onClick, index, autoFocus}) => {
  const [placeholderText, setPlaceholderText] = useState(typeof row === 'object' && row !== null ? row.name : row)
  return (<div id={id}> 
    <CustomInput
      inputProps={{
        style:{ marginTop:"-24px"},
        value: placeholderText ,
        onChange: (e) =>{
          setPlaceholderText(e.target.value)
        },
        onBlur: (e) => {
          onClick(row, e.target.value, index)
        },
        onKeyPress: (e) => {
          if( e.key === 'Enter' ) {
            onClick(row, e.target.value, index);
          }
        },
        autoFocus
      }}
    />
  </div>)
}

export default withStyles(StepsDropdownStyle)(StepsDropdown);