export default {
  root: {

  },
  footer: {
    minHeight: '120px',
    width: 510,
    backgroundColor: '#eeeeee',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  title: {
    marginTop: 20,
    marginBottom: 15,
    fontWeight: 'normal',
    fontSize: 18
  },
  dropDown: {
    backgroundColor: 'transparent',
    color: '#AAAAAA',
    boxShadow: 'none',
    padding: "10px 5px 5px 0px",
    borderBottom: '1px solid #D2D2D2',
    maxWidth: '400px',
    borderRadius: 0,
    width: '100%',
    marginBottom: '20px',
    textTransform: 'initial',
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: '#AAAAAA',
      boxShadow: 'none',
    },
  },
  btnUpload: {
    float: 'right',
    marginRight: '2%',
    marginTop: '15px',
    paddingTop: '5px',
    paddingBottom: '5px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
    marginBottom: '20px',
  },
  dropDownError: {
    backgroundColor: 'red',
    color: 'red',
    boxShadow: 'none',
    padding: "10px 5px 5px 0px",
    borderBottom: '1px solid #D2D2D2',
    maxWidth: '400px',
    borderRadius: 0,
    width: '100%',
    marginBottom: '20px',
    textTransform: 'initial',
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: '#AAAAAA',
      boxShadow: 'none',
    },
  },
  dropDownNoMargin: {
    marginBottom: '5px',
  },
  buttonTextWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%',
    fontWeight: 400,
    color: '#5b5b5b'
  },
  dropDownButtonText: {
    fontFamily: "Roboto, sans-serif !important",
    fontWeight: "400",
    fontSize: '14px'
  },
  restrainInputWidth: {
    width: '100px',
  },
}