import React from "react";
import WithStyle from "@material-ui/core/styles/withStyles";
import Modal from 'components/ModalWrapper/ModalWrapper';

import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";
import UserInviteModalStyle from './UserInviteModalStyle';
import { withApollo, Query } from "react-apollo";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Switch from "@material-ui/core/Switch";
import gql from "graphql-tag";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import UserInvite from 'components/User/UserInvite';
import OrganizationSelectTable from "components/Organization/OrganizationTable/oldOrganizationTable.jsx";
import Loading from "../Loading/Loading";

import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const SEND_NEW_USER_EMAIL_MUTATION = gql`
  mutation SEND_NEW_USER_EMAIL_MUTATION($destination: String!, $roleId: ID!, $projectsIds: [ID]){
    sendNewUserEmail( destination: $destination , roleId: $roleId, projectsIds: $projectsIds )
  }
`;

const MY_ORG_QUERY = gql`
  query MY_ORG_QUERY{
    myOrganization{
      id
      name
      projects {
        name
        id
      }
      roles{
        id
        name
        higherThanUser
      }
    }
  }
`;

async function sendEmails({ client, projectsIds, sentEmails, onClose, successAlert }) {
  if (client && sentEmails) {
    await Promise.all(sentEmails.map(async (emailVars) => {
      if (emailVars) {
        return (
          await client.mutate({
            mutation: SEND_NEW_USER_EMAIL_MUTATION,
            variables: {...emailVars, projectsIds}
          })
        )
      }
    }))
    onClose();
    successAlert();
  }
}

class InviteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sentEmails: [],
      checkedRow: [],
      switchToggled: false,
    }
  }

  onClickRow = (id) => {
    const { checkedRow } = this.state;

    let tmp = checkedRow;
    if (checkedRow.indexOf(id) >= 0) tmp.splice(checkedRow.indexOf(id), 1);
    else tmp.push(id);

    this.setState({ checkedRow: tmp });
  }
  sendEmails = ({ client, sentEmails, onClose, successAlert }) => {
    const { checkedRow } = this.state;
    sendEmails({ client, sentEmails, onClose, successAlert, projectsIds: checkedRow});
  }
  render() {

    const {
      classes,
      open,
      onClose = {},
      modalName = 'Renomear',
      client,
      successAlert } = this.props;

    const { checkedRow, sentEmails, switchToggled } = this.state;

    return (
      <Modal
        
        open={open}
        onClose={() => onClose()}
        classes={{
          root: classes.scrollable
        }}
      >
        <div className={classes.root}>
          <div className={classes.header}>
            <div className={classes.modalName}> {modalName} </div>
            <Close
              onClick={() => onClose()}
              classes={{ root: classes.close }}
            />
          </div>
          <div className={classes.body}>
            <Query query={MY_ORG_QUERY}>
              {({ error, loading, data }) => {
                if (loading) return <Loading />;
                if (error) return <div>Error:{error}</div>;
                let rolesData = data.myOrganization.roles.map((role) => { return { obj: true, name: role.name, id: role.id, higherThanUser: role.higherThanUser } })
                const projects = data.myOrganization.projects.map(project => ({
                  name: (<div className={classes.tableDataWrapper} style={{ display: "flex", justifyContent: "flex-start" }}>
                    <Checkbox
                      checked={checkedRow.indexOf(project.id) >= 0}
                      tabIndex={-1}
                      onClick={() => this.onClickRow(project.id)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                    <span className={classes.projectName}>{project.name} </span>
                  </div>),
                  _name: project.name,
                  id: project.id,
                }))

                return (
                  <div>
                    <UserInvite
                      classes={classes}
                      rolesData={rolesData}
                      setSentEmails={async (emailVars) => await this.setState({ sentEmails: emailVars })}
                    />
                    <div className={classes.switchWrapper}>
                      <h3 className={classes.title}> Deseja dar acesso a algum projeto existente? </h3>
                     <div> 
                       <Switch
                          checked={this.state.switchToggled}
                          onChange={() => this.setState({switchToggled: !switchToggled})}
                          value="sidebarMini"
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            icon: classes.switchIcon,
                            iconChecked: classes.switchIconChecked,
                            bar: classes.switchBar
                          }}
                        />
                        </div>
                    </div>
                    
                    {switchToggled && <div className={classes.TeamRoleTableWrapper}>
                      <OrganizationSelectTable showAddUser={false} data={projects} />
                    </div>}
                  </div>
                );
              }}
            </Query>
          </div>
          <div className={classes.footer} style={{ justifyContent: "space-between" }}>
            <Button
              color="transparent"
              onClick={() => onClose()}
            >
              Cancelar
          </Button>
            <Button
              color="purple"
              onClick={() => this.sendEmails({ client, sentEmails, onClose, successAlert })}
            >
              Enviar
          </Button>
          </div>
        </div>
      </Modal>
    )
  }
}


export default withApollo(WithStyle({ ...regularFormsStyle, ...customCheckboxRadioSwitch, ...UserInviteModalStyle })(InviteModal));