import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import "./index.css";
//graphql/Apollo imports
import { ApolloProvider } from "react-apollo";

import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
//import { gql } from 'apollo-boost';

import "assets/scss/material-dashboard-pro-react.scss?v=1.5.0";

//components import
import AdminLayout from "layouts/Admin.jsx";
import ContentLayout from "layouts/Content.jsx";
import ProjectLayout from "layouts/Project.jsx";

import AdminPage from "views/AdminPage/AdminPage";

import Login from "views/Login/Login.jsx";
import Logout from "components/Logout/Logout.jsx";
import Register from "views/Register/UserRegistrationView.jsx";
import RegisterOrg from "views/Organization/OrganizationRegistration.jsx";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import dotenv from "dotenv";
import RequestPassword from "./views/RequestPassword/RequestPassword";

import ForgotPassword from "./views/ForgotPassword/ForgotPassword";
import ContextController from "ContextController.js";

dotenv.config();

let uri;
let wsUri;

if (process.env.REACT_APP_ENV === "development") {
  uri = "https://api.hml.tvmanager.com.br/graphql";
  wsUri = "wss://api.hml.tvmanager.com.br/graphql";
} else if (process.env.REACT_APP_ENV === "production") {
  uri = "https://api.prod.tvmanager.com.br/graphql";
  wsUri = "wss://api.prod.tvmanager.com.br/graphql";
} else if (process.env.REACT_APP_ENV === "local") {
  uri = "http://localhost:3003/graphql";
  wsUri = "ws://localhost:3003/graphql";
}

const httpLink = createHttpLink({ uri });

// const GET_TOKEN = gql`
// {
//   token @client
// }`;

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists -- TODO: SECURE THIS!!!!
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `bearer ${token}` : ""
    }
  };
});

const wsLink = new WebSocketLink({
  uri: wsUri,
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        Authorization: localStorage.getItem("token")
          ? `bearer ${localStorage.getItem("token")}`
          : ""
      }
    }
  }
});

const client = new ApolloClient({
  link: split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    authLink.concat(httpLink)
  ),
  cache: new InMemoryCache()
});

const hist = createBrowserHistory();

class ProtectedRoute extends React.Component {
  render() {
    const { component: Component, ...props } = this.props;

    return (
      <Route
        {...props}
        render={props =>
          localStorage.getItem("token") ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  }
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <ContextController>
      <Router history={hist}>
        <Switch>
          <Route path="/adminPage" component={AdminPage} />
          <ProtectedRoute path="/admin" component={AdminLayout} />
          <ProtectedRoute path="/content" component={ContentLayout} />
          <Route path="/register-organization/:token" component={RegisterOrg} />
          <Route path="/register/:token" component={Register} />
          <ProtectedRoute path="/project" component={ProjectLayout} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/password-reset/:token" component={RequestPassword} />
          <Redirect from="/" to="/admin/projects" />
        </Switch>
      </Router>
    </ContextController>
  </ApolloProvider>,
  document.getElementById("root")
);

// Hot reload makes changes in the code appear faster, so it's used for development.
// it may cause problems in production so we just export the client without it in that case.
let hotClient;

if (
  process.env.REACT_APP_ENV === "development" ||
  process.env.REACT_APP_ENV === "local"
) {
  let { hot } = require("react-hot-loader/root");
  hotClient = hot(client);
} else {
  hotClient = client;
}
export { client };
export default hotClient;
