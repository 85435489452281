const style = {
  root: {
      backgroundColor: 'white',
      margin: '0 auto',
      top: '50px',
      zIndex: '131',
      position: 'relative',
      padding: '15px 25px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      borderRadius: '6px',
      minHeight:"350px", 
      width:"500px", 
      overflow: 'visible',
  },
  headerExpander: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    textAlign: 'center',
    color: '#3F2C78',
  },
  info: {
    height: '40px',	
    width: '367px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '20px',	
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: '10px',
    fontWeight: 400,
    '& span': {
      color: '#3F2C78',
    }
  },
  expandWrapper: {
      marginTop: '5px',
      cursor: 'pointer',
      width: '30px'
  },
  expandable: {
    backgroundColor: 'rgba(63,44,120,0.1)',
    color:'#3F2C78'
  },
  textInfo: {
    flexDirection: 'column',
    marginTop: '15px',
    visibility: 'hidden',
    display: 'none',
    opacity: '0',
  },
  headerInfoWrapper: {
    width: '100% !important',
    backgroundColor: 'rgba(63,44,120,0.1) !important',
    borderRadius: '8px',
    padding: '10px 20px',
    fontWeight: 'normal',
    fontSize: '16px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
    marginTop: '20px',
    marginBottom: '10px',
    flexDirection: 'column'
  },
  footer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px',
      marginTop: 'auto'
  },
  switchForm: {
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    '& div': {
      textAlign: 'center',
      fontSize: '18px',
      marginTop: '13px',
      marginRight: '20px',
      fontWeight: 'normal'
    }
  },
  input: {
      width: '100%'
  },
  modalName: {
      fontSize: '20px',
      width: '100%'
  },
  modal: {
    overflow: 'auto',
  },
  close: {
      cursor: 'pointer',
  },
  inviteEmail: {
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}

export default style;