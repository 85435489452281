import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import Loading from "components/Loading/Loading";

import MetricsChart from "components/MetricsChart/MetricsChart";

import { Query } from "react-apollo";
import { 
  USER_COUNTS_QUERY, 
  CONTENT_COUNTS_QUERY, 
  PROJECT_COUNTS_QUERY, 
  ORGANIZATION_COUNTS_QUERY
} from './adminPageQueries.js';
import { getDateSteps } from './utils';

import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";

class AdminCharts extends React.Component {
  render() {
    const startDate = "01/01/2019";
    const endDate = "01/05/2021";
    const stepInDays = 60;
    const { endDates } = getDateSteps(startDate, endDate, stepInDays); 
    const labels = endDates.map( endDate => endDate.format("MM/YY"));
    return (
      <div>
        <GridContainer>
          <Query query={USER_COUNTS_QUERY} variables={{ startDate, endDate, stepInDays }} >
          {({ loading, error, data }) => {
            if (loading) 
            return (
                <Loading/>
            );
            if (error) {
                console.log(`Error! ${error.message}`);
                //return null;
            };
            if( !data ) return null;

            const series = data.userCounts;

            return (
              <MetricsChart labels={labels} series={series} title={"Usuarios no Sistema"} />
            );
          }}
          </Query>
          <Query query={CONTENT_COUNTS_QUERY} variables={{ startDate, endDate, stepInDays }} >
          {({ loading, error, data }) => {
            if (loading) 
            return (
                <Loading/>
            );
            if (error) {
                console.log(`Error! ${error.message}`);
                //return null;
            };
            if( !data ) return null;
            
            const series = data.contentCounts;

            return (
              <MetricsChart labels={labels} series={series} title={"Conteudos no Sistema"} />
            );
          }}
          </Query>
        
          <Query query={PROJECT_COUNTS_QUERY} variables={{ startDate, endDate, stepInDays }} >
          {({ loading, error, data }) => {
            if (loading) 
            return (
                <Loading/>
            );
            if (error) {
                console.log(`Error! ${error.message}`);
                //return null;
            };
            if( !data ) return null;

            const series = data.projectCounts;

            return (
              <MetricsChart labels={labels} series={series} title={"Metricas de Projetos"} />
            );
          }}
          </Query>
          <Query query={ORGANIZATION_COUNTS_QUERY} variables={{ startDate, endDate, stepInDays }} >
          {({ loading, error, data }) => {
            if (loading) 
            return (
                <Loading/>
            );
            if (error) {
                console.log(`Error! ${error.message}`);
                //return null;
            };
            if( !data ) return null;

            const series = data.organizationCounts;

            return (
              <MetricsChart labels={labels} series={series} title={"Metricas de Organizações"} />
            );
          }}
          </Query>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(chartsStyle)(AdminCharts);
