export default function (status) {
  switch (status.toLowerCase()) {
    case 'pendente':
      return ' buttonOrange';
    case 'aprovado':
      return 'buttonGreen';
    case 'em revisão':
      return 'red';
    default:
      return 'orange';
  }
}