import React, { Component } from 'react';
import { Card, LinearProgress } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import DashboardCardStyle from './DashboardCardStyle';
import List from "@material-ui/icons/List";

class DashboardCard extends Component {
  pickColor = () => {
    const { color, classes } = this.props;
    if (color === 'red') {
      return classes.numberCardRed;
    }
    if (color === 'orange') {
      return classes.numberCardOrange;
    }
    if (color === 'green') {
      return classes.numberCardGreen;
    }
    if (color === 'purple') {
      return classes.numberCardPurple;
    }
  }
  render() {
    const { classes, footerText, title='Projetos', subtitle = 'Atrasados', number = '08', linearValue, footer = true } = this.props;
    return (
      <Card classes={{root: classes.card}}>
        <div className={this.pickColor()}> 
          <div className={classes.number}> {number} </div>
        </div>
        <div className={classes.cardInfo}> 
          <div className={classes.title}> {title} </div>
          <div className={classes.subtitle}> {subtitle} </div>
        </div>
        {footer && <div className={classes.footer}>
          {!footerText && (linearValue  || linearValue === 0 ? <div className={classes.linearProgress}> <LinearProgress variant='determinate' value={linearValue}/> </div> : <> <List/> <div> Ver lista Filtrada </div> </>)}
          <span className={classes.footerText}> {footerText} </span>
        </div>}
      </Card>
    );
  }
}

export default withStyles(DashboardCardStyle)(DashboardCard);
