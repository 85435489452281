import { gql } from "apollo-boost";

const GET_ROLES_QUERY = gql`
  query GET_ROLES_QUERY {
    roles {
      id
      name
      higherThanUser
    }
  }
`
// const SEND_NEW_USER_EMAIL = gql `
//     mutation SEND_NEW_USER_EMAIL($destination: String!, $roleId: ID!){
//         sendNewUserEmail(destination: $destination, roleId:$roleId )
//     }
// `;


export {
  GET_ROLES_QUERY
}