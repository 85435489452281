import React from 'react';
import { List, ListItem, withStyles, Button } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { withApollo } from 'react-apollo';
import { REMOVE_DOCUMENT_TAG_MUTATION } from "views/Juridical/DocumentTagQueries";
import VerificationDialog from 'components/VerificationDialog/VerificationDialog';
import { GET_DOCUMENTS_FROM_PROJECT } from 'views/Juridical/DocumentsQueries';
import Portal from "components/Portal/Portal"
const tagListStyles = {
  listItem: { 
    padding:"4px 3px 4px 3px"
  },
  tagBtnWrapper: { 
    filter:"drop-shadow(2px 2px 2px black)", 
    width:"100%", 
    borderRadius:"4px" 
  },
  tagNameWrapper: { 
    width: "inherit",
  },
  ellipsisOnOverflow: {
    whiteSpace: "nowrap",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  btnLabel: {
    display:"grid",
    justifyContent:"flex-start"
  },
  deleteBtn: {
    height: "auto",
    position:"absolute",
    left:"calc(88%)",
    opacity:"0.2",
    "&:hover":{
      opacity:"1"
    },
    "@media (max-width:1200px)":{
      left:"calc(85%) !important"
    }
  }
};

function removeProjectTag({ client, id, tagIds }){
  client.mutate({ 
    mutation: REMOVE_DOCUMENT_TAG_MUTATION, 
    variables:{ id, tagIds },
    refetchQueries: () => [{
      query: GET_DOCUMENTS_FROM_PROJECT,
      variables: { projectId:id }
    }],
  });
}

function DeleteTagDialog({ openDeleteCheck, setOpenDeleteCheck, tagId, mutationVars, onRemove }){
    
    let title = 'Você tem certeza que deseja remover';
    title += onRemove ? 'esta tag do documento?' : "esta tag do projeto?"
    return(
      <Portal>
        <VerificationDialog
            className="not-draggable"
            open={openDeleteCheck === tagId}
            title={title}
            handleClose={() => setOpenDeleteCheck("")}
            onAccept={() => {
              if(!onRemove)
                removeProjectTag(mutationVars);
              else
                onRemove([tagId])
              setOpenDeleteCheck("");
            }}
          />
      </Portal>
    );

}

function TagList({ tags, onAddTag, standalone, handleWithObject, classes, closePopper, client, onRemove }){
  const projectId =  window.location.pathname.split('/')[2];
  const [openDeleteCheck, setOpenDeleteCheck] = React.useState();
  return  <List id="tagList" style={{ overflow:"auto", maxHeight:`calc(100% - ${standalone ? "0px" : "36px"})` }} className="not-draggable">
            {tags.map( tag => {
              if(tag === null) return null;
              if(!tag.name) return null;
              return(
                <ListItem key={tag.color+tag.name+Math.random()} className={classes.listItem}>
                  <DeleteTagDialog 
                    openDeleteCheck={openDeleteCheck}
                    setOpenDeleteCheck={setOpenDeleteCheck}
                    tagId={tag.id}
                    mutationVars={{ client, id:projectId, tagIds:[tag.id]}}
                    onRemove={onRemove}
                  />
                  <div className={classes.tagBtnWrapper} style={{backgroundColor:tag.color}}>
                    <Button 
                      onClick={() => {
                        onAddTag(tag.id);
                        if(handleWithObject) 
                          handleWithObject(tag);
                        if(closePopper)
                          closePopper();
                      }}
                      classes={{label: classes.btnLabel}} 
                      color={"inherit"}
                      fullWidth={true}
                      >
                      <div className={classes.tagNameWrapper + " " + classes.ellipsisOnOverflow}>
                        {tag.name}
                      </div>
                      <div 
                        className={classes.deleteBtn}
                        color="primary"
                        onClick={(e)=>{
                          e.stopPropagation();
                          setOpenDeleteCheck(tag.id)
                        }}
                      >
                          <Cancel />
                      </div>
                    </Button>
                  </div> 
                </ListItem>
              );} )}
          </List>
}

export default withApollo(withStyles(tagListStyles)(TagList));