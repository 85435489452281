import React from "react";
import { withApollo } from 'react-apollo';

import TeamRoleTableStyle from "components/TeamRoleTable/TeamRoleTableStyle.js";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddCircle from "@material-ui/icons/AddCircle";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import EmailIcon from "@material-ui/icons/Email";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import _ from 'lodash'
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";

class InviteUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      role: null,
      sentEmails:[],
      inputCount:1,
      roles:[],
      emails:[]
    };
  }

  inputContainer(last, key){
    const { classes, rolesData, setSentEmails } = this.props;
    let inputAcessKey = key;
    return(<div>
      <GridContainer style={{ height: '100%', minHeight:"55px" }} direction='row'>
        <GridItem xs={6} md={6} sm={6}>
          <CustomInput
            id="email"
            classes={{ labelRoot: classes.labelNormal, formControl: classes.noPaddingInput }}
            labelText={
              <span>
                Convidar novo usuário por e-mail
              </span>
            }

            formControlProps={{
              fullWidth: true
            }}

            inputProps={{
              onBlur:async e => {
                let _emails = [...this.state.emails];
                _emails[inputAcessKey] = e.target.value;
                await this.setState({ emails: _emails });
                let emailVars = this.state.emails.map((email, i) => {
                  if(this.state.roles[i] && this.state.emails[i]){
                    let vars = { destination: email, roleId : this.state.roles[i].id};
                    return vars;
                  }
                  return '';
                }) 
                setSentEmails(emailVars);
              },
              endAdornment: (
              <InputAdornment
                position="end"
                className={classes.inputAdornment}
              >
                <EmailIcon
                  className={classes.inputAdornmentIcon}
                />
              </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={5} md={5} sm={5}>
          <CustomDropdown
            buttonText={this.state.roles[inputAcessKey] ? this.state.roles[inputAcessKey].name :  'Papel'}
            classes={{ 
              button: classes.dropDown, 
              butonText: classes.dropDownButtonText, 
              buttonTextWrapper: classes.buttonTextWrapper, 
              manager: classes.manager 
            }}
            dropdownList={ rolesData }
            onClick={ async (role) =>{
              let _roles = [...this.state.roles];
              _roles[inputAcessKey] = role;
              await this.setState({ roles: _roles });
              let emailVars = this.state.emails.map((email, i) => {
                if(this.state.roles[i] && this.state.emails[i])
                  return { destination: email, roleId : this.state.roles[i].id};
                  return '';

              }) 
              setSentEmails(emailVars);
            }}
          />
        </GridItem>
        <GridItem xs={1} md={1} sm={1}>
        { 
          !last && 
          <Button onClick={() => this.setState({ inputCount: this.state.inputCount + 1 })} classes={{ root: classes.sendMailWrapper }} color='white' round justIcon>
            <span className={classes.sendMail}> 
              <AddCircle/> 
            </span>
          </Button>
        }
        </GridItem>
      </GridContainer>
    </div>);
  }

  render() {
    return (
      <div style={{marginTop:"30px"}}>
      {
        _.times(this.state.inputCount, (i) =>{
          return(
            this.inputContainer((i < this.state.inputCount - 1), i)
          );
        })
      }
      </div>      
    );
  }
}

InviteUser.propTypes = {
  email: PropTypes.string,
  classes: PropTypes.object.isRequired,
  setSentEmails: PropTypes.func.isRequired,
  rolesData: PropTypes.arrayOf(PropTypes.object).isRequired, 
};

let InviteWithStyle = withStyles({...regularFormsStyle, ...TeamRoleTableStyle})(InviteUser)

export default withApollo(InviteWithStyle);