export default {
  startDate: {
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  endDate: {
    position: 'absolute',
    right: 10,
    bottom: 0,
  },
  root: {
    position: 'relative',
    height: 25,
    color: "#a2a2a2a2"
  }
}