import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import propTypes from "prop-types"; // ES6
import ReactTable from "react-table";
import ProjectStatusBox from "./ProjectStatusBox.jsx";
import { Card } from "@material-ui/core";
import style from "components/StatusTable/StatusTableStyle";

class ProjectScheduleTable extends React.Component {
  render() {
    const { phases, elements, classes, title = "Etapas" } = this.props;
    let data = [];
    let columns = [];
    // This is the first column listing the name of the elements
    columns.push({
      Header: <div className={classes.title}> {title} </div>,
      accessor: "rows",
      width: 200
    });
    for (let i = 0; i < phases.length; i++) {
      columns.push({
        phase: phases[i],
        accessor: phases[i].name,
        Header: phases[i].name,
        width: 120
      });
    }
    elements.forEach((element, elementIndex) => {
      // It starts with the row name
      var _data = {
        rows: <div className={classes.step}> {element.name} </div>
      };
      phases.forEach(phase => {
        let currentElement = phase.elements[elementIndex];
        if (!currentElement)
          _data = {
            ..._data,
            [phase.name]: <ProjectStatusBox element={element} />
          };
        else
          _data = {
            ..._data,
            [phase.name]: <ProjectStatusBox element={currentElement} />
          };
      });
      data.push(_data);
    });
    return (
      <div className={classes.new}>
        <Card
          classes={{ root: classes.tablePlanning + " " + classes.tableWrapper }}
        >
          <ReactTable
            data={data}
            noDataText="Não foram encontrados eventos"
            sortable={false}
            showPagination={false}
            pageSize={data.length}
            resizable={false}
            columns={columns}
            getTheadThProps={() => {
              return {
                style: {
                  fontSize: "16px",
                  whiteSpace: "noWrap"
                }
              };
            }}
            minRows={data.length}
            showPaginationBottom={false}
          />
        </Card>
      </div>
    );
  }
}

ProjectScheduleTable.propTypes = {
  elements: propTypes.object,
  phases: propTypes.object
};

ProjectScheduleTable.defaultProps = {
  header: [],
  rows: [],
  columnWidth: 120
};

export default withStyles(style)(ProjectScheduleTable);
