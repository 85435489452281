import {} from "assets/jss/material-dashboard-pro-react.jsx";

import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const style = {
  wizardContainer: {
    maxWidth: "700px",
    marginLeft: "auto",
    marginRight: "auto"
  },
  wizardContent: {
    paddingLeft: "0",
    paddingRight: "0",
    marginTop: "0px",
    padding: "30px 10px 5px 10px"
  },
  close: {
    cursor: "pointer",
    zIndex: 99999999999999,
    position: "relative"
  },
  wizardFooter: {
    padding: "10px 20px"
  },
  templateModal: {
    width: "800px",
    backgroundColor: "white",
    marginTop: "40px",
    marginLeft: "auto",
    marginRight: "auto",
    overflow: "auto",
    marginBottom: "15px",
    zIndex: 999999999,
    position: "relative"
  },
  leader: {
    backgroundColor: "#f9f9f9",
    padding: "10px 30px",
    fontSize: "20px",
    fontWeight: 400,
    color: "#57606e",
    width: 600,
    marginLeft: "auto",
    marginRight: "auto",
    margin: "10px 0"
  },
  btnNewModel: {
    border: "1px solid #3f2c78",
    color: "#3f2c78",
    backgroundColor: "white",
    "&:hover": {
      color: "#3f2c78",
      backgroundColor: "white"
    },
    "&:active": {
      color: "#3f2c78",
      backgroundColor: "white"
    },
    "&:focus": {
      color: "#3f2c78",
      backgroundColor: "white"
    }
  },
  formControlDate: {
    "& input": {
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px !important"
    }
  },
  hideNavigation: {
    display: "none"
  },
  inputModelName: {
    width: "500px",
    marginBottom: 25
  },
  editProjectIcon: {
    marginTop: 0
  },
  scrollableModal: {
    overflowY: "auto"
  },
  newModelInputWrapper: {
    display: "flex",
    justifyContent: "left",
    marginLeft: "50px",
    "& input": {
      "&::placeholder": {
        color: "black !important"
      }
    }
  },
  displayOrg: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row"
  },
  imgUploadWrapper: {
    display: "flex",
    justifyContent: "center"
  },
  newModelInput: {
    color: "black !important",
    "&::placeholder": {
      color: "black !important"
    }
  },
  switchWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: "10px",
    marginBottom: "10px"
  },
  modalBackDrop: {
    position: "absolute",
    overflowY: "auto",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "3px",
      height: "5px",
      paddingTop: "5px",
      marginBottom: "10px"
    },
    "&::-webkit-scrollbar-button": {
      width: "0px",
      height: "0px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#e1e1e1",
      border: "0px none #ffffff",
      borderRadius: "50px"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#ffffff"
    },
    "&::-webkit-scrollbar-thumb:active": {
      background: "#000000"
    },
    "&::-webkit-scrollbar-track": {
      background: "#666666",
      border: "0px none #ffffff",
      borderRadius: "50px"
    },
    "&::-webkit-scrollbar-track:hover": {
      background: "#666666"
    }
  },
  role: {
    fontSize: 14,
    fontWeight: "normal",
    color: "#3C4858"
  },
  roleWrapper: {
    fontSize: 14,
    fontWeight: "normal",
    color: "#3C4858",
    display: "flex",
    "& span": {
      marginTop: "10px",
      fontSize: 14,
      marginLeft: "10px",
      letterSpacing: "0"
    }
  },
  gridNoPadding: {
    padding: "0"
  },
  dateInput: {
    color: "#AAAAAA",
    fontWeight: "600"
  },
  searchInput: {
    width: "100px",
    paddingTop: "10px",
    "&::-webkit-input-placeholder": {
      color: "black"
    }
  },
  restrainInputWidth: {
    width: "250px"
  },
  customInput: {
    fontSize: "14px",
    fontFamily: "Robot, sans-serif"
  },
  imgUpload: {
    border: "3px solid #9E94BC",
    boxShadow: "none !important"
  },
  dropDown: {
    backgroundColor: "transparent",
    color: "#AAAAAA",
    boxShadow: "none",
    padding: "10px 5px 5px 0px",
    borderBottom: "1px solid #D2D2D2",
    maxWidth: "400px",
    borderRadius: 0,
    width: "100%",
    marginBottom: "20px",
    textTransform: "initial",
    "&:hover,&:focus": {
      backgroundColor: "transparent",
      color: "#AAAAAA",
      boxShadow: "none"
    }
  },
  dropDownError: {
    backgroundColor: "red",
    color: "red",
    boxShadow: "none",
    padding: "10px 5px 5px 0px",
    borderBottom: "1px solid #D2D2D2",
    maxWidth: "400px",
    borderRadius: 0,
    width: "100%",
    marginBottom: "20px",
    textTransform: "initial",
    "&:hover,&:focus": {
      backgroundColor: "transparent",
      color: "#AAAAAA",
      boxShadow: "none"
    }
  },
  dropDownNoMargin: {
    marginBottom: "5px"
  },
  wizardHeader: {
    padding: "30px 0 30px"
  },
  numberEpisodes: {
    width: "90px",
    marginLeft: "15px"
  },
  numberEpisodeText: {
    paddingTop: "14px"
  },
  checkRoot: {
    padding: "8px 10px",
    marginRight: "10px"
  },
  imageUploadButton: {
    backgroundColor: "#9E94BC"
  },
  checkboxLabel: {
    fontWeight: "300"
  },
  buttonTextWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  dropDownButtonText: {
    fontFamily: "Roboto, sans-serif !important",
    fontWeight: "600",
    fontSize: "14px"
  },
  hide: {
    display: "none"
  },
  tableWrapper: {
    backgroundColor: "#F9F9F9",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
    borderRadius: "8px",
    padding: "10px",
    paddingTop: 0,
    margin: "10px 0",
    width: "600px",
    border: "none",
    "& .ReactTable": {
      border: "none"
    },
    "& .ReactTable .rt-tbody": {
      maxHeight: "350px"
    },
    "& .ReactTable .rt-tbody .rt-td": {
      border: "none"
    },
    "& .ReactTable .rt-thead.-header": {
      boxShadow: "none"
    },
    "& .ReactTable .rt-tbody .rt-tr-group .rt-tr": {
      border: "none",
      borderBottom: "solid 1px rgba(210, 210, 210, 0.7)"
    },
    "& .ReactTable .rt-thead .rt-th input, .ReactTable .rt-thead .rt-th input::placeholder, .ReactTable .rt-thead .rt-td input::placeholder": {
      color: "#3C4858",
      fontWeight: "normal",
      opacity: 1,
      fontSize: "18px"
    },
    "& .ReactTable .rt-tbody .rt-tr-group": {
      borderBottom: "none",
      border: "none !important"
    },
    "& .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td": {
      border: "none"
    }
  },
  removeUser: {
    marginTop: "0px",
    backgroundColor: "transparent",
    fontSize: "36px",
    borderRadius: "50%",
    width: "19px",
    lineHeight: "16px",
    color: "#aeaeae"
  },
  tableSteps: {
    backgroundColor: "#F9F9F9",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
    borderRadius: "8px",
    padding: "10px",
    width: "100%",
    margin: "10px 0",
    "& .ReactTable .rt-tbody .rt-tr-group .rt-tr": {
      borderBottom: "solid 1px rgba(210, 210, 210, 0.7)"
    },
    "& .ReactTable .rt-tbody .rt-tr-group": {
      borderBottom: "none"
    }
  },
  tablePlanning: {
    backgroundColor: "#F9F9F9",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
    borderRadius: "8px",
    padding: "10px",
    width: "100%",
    margin: "10px 0",
    "& .ReactTable .rt-tbody .rt-tr-group .rt-tr": {
      borderBottom: "solid 1px rgba(210, 210, 210, 0.7)",
      border: "none"
    },
    "& .ReactTable .rt-tbody .rt-tr-group": {
      borderBottom: "none",
      border: "none"
    },
    "& .ReactTable .rt-thead .rt-resizable-header:last-child": {
      textAlign: "left"
    }
  },
  statusBox: {
    backgroundColor: "#c6c2d1",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
    height: "25px",
    width: "80%",
    maxWidth: "80px",
    borderRadius: "6px"
  },
  header: {
    fontSize: "18px",
    fontFamily: "roboto",
    textAlign: "center",
    paddingLeft: "15px",
    fontWeight: "normal",
    marginTop: "0"
  },
  switchTableWrapper: {
    color: "#4D4D4D",
    backgroundColor: "#f9f9f9",
    display: "flex",
    height: "18px",
    justifyContent: "space-between",
    width: "100%",
    borderRadius: "3px",
    "& label": {
      height: "18px"
    }
  },
  blackLabel: {
    fontWeight: "300",
    fontFamily: "Roboto, sans-serif !important",
    fontSize: "14px",
    textTransform: "initial",
    color: "black !important"
  },
  newUser: {
    backgroundColor: "#F9F9F9",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
    borderRadius: "8px",
    paddingTop: "15px",
    marginTop: "15px"
  },
  noPaddingInput: {
    marginTop: "-19px"
  },
  infoWrapper: {
    backgroundColor: "#f2f1f6",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "30px",
    "& h4": {
      fontWeight: "normal",
      fontSize: "16px !important",
      "& strong": {
        color: "#3F2C78",
        fontWeight: "normal"
      }
    }
  },
  switchChecked: {
    "& + $switchBarSmall": {
      backgroundColor: "#3F2C78 !important",
      opacity: "1 !important"
    },
    "& + $switchBarRegular": {
      backgroundColor: "#3F2C78 !important",
      opacity: "1 !important"
    }
  },
  TeamRoleTable: {
    width: "600px"
  },
  switchBarSmall: {
    ...customCheckboxRadioSwitch.switchBar,
    width: "20px",
    height: "11px",
    marginTop: "-5px",
    marginLeft: "-10px",
    opacity: "1!important"
  },
  switchBarRegular: {
    ...customCheckboxRadioSwitch.switchBar,
    opacity: "1!important"
  },
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  switchCheck: {
    backgroundColor: "#3F2C78 !important"
  },
  switchSmallIcon: {
    height: "15px",
    width: "15px",
    marginLeft: "-2px",
    backgroundColor: "white",
    border: "1px solid #3F2C78"
  },
  inputAdornmentIcon: {
    color: "#999999 !important",
    marginTop: "4px"
  },
  labelNormal: {
    fontWeight: "normal"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  sectionTitle: {
    fontSize: "16px",
    fontWeight: "normal",
    textAlign: "center",
    marginBottom: "15px",
    marginTop: "15px"
  },
  title: {
    textAlign: "center",
    fontFamily: "montserrat, sans-serif",
    fontWeight: "400",
    fontSize: "1.825em"
  },
  titleNewModel: {
    textAlign: "center",
    fontFamily: "montserrat, sans-serif",
    fontWeight: "400",
    fontSize: "1.825em",
    marginTop: "35px",
    marginBottom: "35px"
  },
  lightText: {
    fontSize: "16px",
    fontWeight: "300",
    textAlign: "center",
    marginBottom: "15px",
    marginTop: "15px"
  },
  centerContent: {
    display: "flex",
    justifyContent: "center"
  },
  label: {
    fontSize: "16px !important",
    fontWeight: "normal",
    textAlign: "center",
    color: "black"
  },
  inputLabel: {
    color: "#AAAAAA",
    fontWeight: "bold",
    fontSize: "14px"
  },
  inputLabelTextArea: {
    fontWeight: "bold",
    fontSize: "14px",
    zIndex: "50"
  },
  inputLabelNewModel: {
    color: "#666666",
    fontWeight: "normal",
    fontSize: "14px",
    zIndex: "50",
    marginTop: 15,
    pointerEvents: "none"
  },
  inputTextareaFormControl: {
    fontSize: "14px",
    transition: "all 1s",
    backgroundColor: "white",
    "& div textarea:focus": {
      backgroundColor: "#f7f7f7"
    },
    "& textarea": {
      fontSize: "14px",
      lineHeight: "12px",
      height: "20px"
    }
  },
  formControlRoot: {
    display: "flex",
    justifyContent: "center"
  }
};

export default style;
