import "./LoadingStyle.css";
import React, { Component } from 'react';

export class Loading extends Component {
  render() {
    return (
      <div className="loader-content" style={this.props.style ? this.props.style : {} }>
        <div className="loader-index">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}

export default Loading;
