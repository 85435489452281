import { gql } from "apollo-boost";


const GET_DOCUMENTS_FROM_PROJECT = gql`
  query GET_DOCUMENTS_FROM_PROJECT($projectId: ID!){
    project(id: $projectId) {
      id 
      documents {
        id
        fileKey
        status
        name
        type
        creationDate
        tags {
          id
          name
          color
        }
        uploader {
          id
          name
          image {
            id
            imageUrl
          }
        }
      }
    }
  }
`

export {
  GET_DOCUMENTS_FROM_PROJECT
};