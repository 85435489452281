import React, { useState } from 'react';
import { withStyles, Button, TextField } from '@material-ui/core';
import { ChevronLeft, Close } from '@material-ui/icons';
import ColorPicker from 'components/ColorPicker/ColorPicker';


const tagCreatorStyles = {
  root: {
    display:"flex", 
    flexDirection:"column"
  },
  header: {
    display:"flex",
    justifyContent:"space-between", 
    padding:"4px 4px", 
    alignItems:"center",
    borderBottomWidth:"1px",
    borderBottomColor:"grey",
    borderBottomStyle:"solid",
    marginBottom:"20px",
    color:"black"
  },
  clickable: {
    cursor:"pointer" 
  }
}

function TagInput({ handleInput, setTagName, tagName }){
  const parseEventAndCallHandler = (e) => {
    if(e.target.value !== "") {
      handleInput(tagName.trim()); 
      setTagName("")
    }
  }

  const inputRef = React.useRef(null);
  React.useEffect(()=>{
    inputRef.current.focus();
  },[])
  
  return ( 
      <TextField
        variant={"outlined"} 
        fullWidth={true}
        value={tagName}
        label={"Nome"}
        onMouseDown={e => e.stopPropagation()}
        onChange={e => setTagName(e.target.value)}
        onKeyUp={(e) => {if(e.key === "Enter") parseEventAndCallHandler(e) }}
        style={{ marginBottom: "5px" }}
        inputProps={{
          style:{
            padding:"10px 4px 10px 4px"
          },
          ref:inputRef
        }}
        InputLabelProps={{
          shrink:true
        }} 
      />      
  )
}

export function TagCreator({ addTagsToProject, classes, onSubmit, onBack, onClose }){
  const [ color, setColor ] = useState("#29abe2");
  const [ tagName, setTagName ] = useState("");
  const colorPallet = [
    "#29abe2",
    "#a86cc1",
    "#7a234b",
    "#ff80ce",
    "#b6bbbf", 
    "#42548e",
    "#f2d600", 
    "#212121",
  ];

  const handleIpunt = ()=> {
    addTagsToProject({ name:tagName, color });
    if(onSubmit)
      onSubmit();  
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          <span>
            {onBack && <ChevronLeft onClick={onBack} className={classes.clickable}/>}
          </span>
          <span style={{ marginBottom:"5px"}}>Crie uma Tag</span>
          <span>
            {onClose && <Close onClick={onClose} className={classes.clickable}/>}
          </span>
        </div>
        <TagInput handleInput={handleIpunt} tagName={tagName} setTagName={setTagName}/>
      </div>
      <ColorPicker setColor={setColor} pallet={colorPallet}/>
      <div className={classes.footer}>
        <Button style={{ marginTop:"5px" }} variant="outlined" fullWidth onClick={handleIpunt}>
          Criar Tag
        </Button>
      </div>
    </>);
}
export default withStyles(tagCreatorStyles)(TagCreator);