import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.jsx";
import {
  tooltip,
  cardTitle,
} from "assets/jss/material-dashboard-pro-react.jsx";
const style = {
  tooltip,
  ...hoverCardStyle,
    cardHover: {
      "&:hover": {
        "& $cardHeaderHover": {
          transform: "translate3d(0, -50px, 0)"
        }
      }
    },
    cardHeaderHover: {
      transition: "all 300ms cubic-bezier(0.34, 1.61, 0.7, 1)"
    },
    underChart: {
      display: 'flex',
      marginTop: "10px",
      color: "#999999",
      '& span': {
        fontWeight: "400",
        color: "#999999",
        marginLeft: "5px",
      },
      '& svg': {
        width: "17px",
        height: "17px",
        marginRight: "5px",
      },
      "& p": {
        color: "#adadad",
        marginBottom: "0px",
      },
    },
    productTitleTwoLines: {
      marginTop: 4,
    },
    cardHoverUnder: {
      position: "absolute",
      zIndex: "1",
      top: "-50px",
      width: "calc(100% - 30px)",
      left: "17px",
      right: "17px",
      textAlign: "center"
    },
    cardProjectFooter: {
      display: 'flex',
      flexDirection: 'column',
      width: "100%",
      height: '100%'
    },
    underChartWrapper: {
      borderBottom: "1px solid #eee",
      paddingBottom: "10px",
      marginBottom: "10px",
      height: '137px'
    },
    underChartIconsProjects: {
      width: "17px",
      height: "17px",
      marginRight: "5px",
      color: "#3F2C78",
    },
    archivedProject: {
      backgroundColor: 'rgba(63,44,120,0.85)',
      height:"220px",
      width:"100%",
      position: 'absolute',
      borderRadius: '6px',
      top: 0,
      display: 'flex',
      justifyContent: 'center',
    },
    archivedProjectVertical: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    archivedProjectIcon: {
      color: 'white !important',
      fontSize: '70px',
      zIndex: 1000,
    },
    cardBody: {
      flex: 0,
      marginTop: 10
    },
    cardProjects: {
      minHeight: '400px',
      marginBottom: '45px'
    },
    projectImg: {
      height:"220px",
      width:"100%",
      backgroundSize: 'cover !important',
      borderRadius: '6px',
      boxShadow: '0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px !important',
      color: '#dadada'
    },
    cardProductTitleProject: {
      ...cardTitle,
      marginTop: "0px",
      marginBottom: "3px",
      textAlign: "left",
      fontWeight: "400 !important",
      "& a": {
        color: "#3F2C78 !important",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: '400 !important',
        fontSize: '18px',
        lineHeight: '24px'
      }
    },
    cardProductTitle: {
      ...cardTitle,
      marginTop: "0px",
      marginBottom: "3px",
      textAlign: "left",
      fontSize: "20px",
      color: "#3F2C78"
    },
}

export default style;