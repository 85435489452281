import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Tooltip } from "@material-ui/core";
const Style = {
    root: {
      minHeight: '40px',
      minWidth: '40px',
      borderRadius: '50%',
      backgroundColor: '#9c92b8',
      color: 'white',
      fontWeight: 'normal',
      paddingTop: '10px !important',
      textAlign: 'center'
    },
  };

class RoleBadge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
  }
    
  render() {
    const { classes, style, role } = this.props;
    let  badgeText = role.name.replace(/[a-z ]/g, '').slice(0,2);
    badgeText = badgeText.length === 0 ? role.name.charAt(0).toUpperCase() : badgeText;
    
    let badgeStyle = role.color ? {backgroundColor: role.color } : {};
    return (
      <Tooltip
          id="tooltip-top"
          title={ role.name }
          placement="top"
        >
          <div style={{...style, ...badgeStyle}} className={classes.root}>
            {role.name ? badgeText : ''}
          </div>
        </Tooltip>
        
    );
  }
}

export default withStyles(Style)(RoleBadge);