import React from "react"
import Modal from 'components/ModalWrapper/ModalWrapper';

import { withStyles } from "@material-ui/core";
import { withApollo, Query } from "react-apollo";
import NewMembersTable from "./NewMembersTable.jsx";
import EditTeamModalStyle from "../EditTeamModal/EditTeamModalStyle.js";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { addProjectMembers } from "queries/projectQueries";
import { GET_MY_ORGANIZATION_QUERY } from "queries/organizationQueries";
import Loading from "../Loading/Loading.jsx";

class AddMembersModal extends React.Component {
  constructor (props) {

    super(props);
    const membersIds = props.currentGroup ? props.currentGroup.members.map((member) => member.id) : [];
    this.state = {
      checkRowTab: membersIds,
      name: props.currentGroup ? props.currentGroup.name : props.currentGroup,
    }
  }

  onClickRowTab = RowIdClicked => {
    const { checkRowTab } = this.state;
    let tmp = checkRowTab;
    if (checkRowTab.indexOf(RowIdClicked) >= 0) tmp.splice(checkRowTab.indexOf(RowIdClicked), 1);
    else tmp.push(RowIdClicked);
    
    this.setState({checkRowTab: tmp})
  }

  addProjectMembers = async () => {
    const { client, projectId } = this.props;
    const { checkRowTab } = this.state;

    let res = await addProjectMembers(
    {
      params: {
        membersIds: checkRowTab, 
        id: projectId
      }, 
      client
    });
    return res;
  }

  render () {
    const { classes, projectMembers, open, onClose, loading, showAlert } = this.props;
    const { checkRowTab } = this.state;

    if (!open) return null;
    if (loading) {
      return (
        <Modal
        
        open={open}
        onBackdropClick={() => onClose()}
        classes={{root: classes.scrollableModal}}
      >
          <div className={classes.root}>
            <div className={classes.header}>
              <span className={classes.title}>
                Editar Equipe
              </span>
            </div>
            <div className={classes.body}>
              <Loading/>
            </div>
          </div>
        </Modal>
      )
    }

    return (
      <Modal
        open={open}
        onBackdropClick={() => onClose()}
      >
          <div className={classes.root + " " + classes.scrollableModal}>
            <div className={classes.header}>
              <span className={classes.title}>
                Novos Membros
              </span>
            </div>

            <div className={classes.body}>
                <div className={classes.instructions}> Selecione ou convide usuários da sua organização para participar </div>
            </div>

            <Query query={GET_MY_ORGANIZATION_QUERY}>
              {({data, loading, error}) => {
                if ( error ) {
                  console.log("Error: ", error);
                }
                if ( loading ) {
                  return <div className={classes.loading}> <Loading/> </div>;
                }
                // Filtra os membros da organização retirando os que já estão no projeto
                let members = data.myOrganization.members.filter(member => !projectMembers.some(projectMember => projectMember && projectMember.id === member.id));
                return (<NewMembersTable 
                    members={members}
                    checkRowTab={checkRowTab}
                    onClickRowTab={(id) => this.onClickRowTab(id)}
                    addProjectMembers={() => this.addProjectMembers()}
                    projectId={this.props.projectId}
                    onClose={() => onClose()}
                    showAlert={showAlert}
                />);
              }
            }
            </Query>

          </div>
      </Modal>
    )
  }
}

export default withApollo(withStyles({...regularFormsStyle, ...EditTeamModalStyle})(AddMembersModal));