import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import propTypes from 'prop-types'; // ES6
import ReactTable from "react-table";
import StatusBox from 'components/StatusBox/StatusBox.jsx';
import { Card } from "@material-ui/core";
import Avatar from "components/Avatar/Avatar";
import style from "./StatusTableStyle";
import LinearProgressWrapper from "../LinearProgressWrapper/LinearProgressWrapper";
import { Link } from "react-router-dom";

class StatusTable extends React.Component {
   pickHeader = ({newHeader, projects, contents, i, projectIndex, element, color, demo, old, classes}) => {
    if (i === 0) {
      if(!old && element === projects[projectIndex].name) 
        return <Link to={`/project/${projects[projectIndex].id}/contents/0/0`}><div className={classes.step}> {element} </div></Link> 
      return <div className={classes.step}> {element} </div>
     }
     if (projects) {
       if (newHeader[i] === 'Líder' && projects && projects[projectIndex]) {
         const projectOwner = projects[projectIndex].owner;
         let image = projectOwner && projectOwner.image ? 
            projectOwner.image.imageUrl : "";
         let userId =  projectOwner ? projectOwner.id : "";
         return <Avatar classes={{root: classes.avatar}} img={image} userId={userId}/>;
       }
       if (newHeader[i] === 'Timeline' && projects && projects[projectIndex]) {
        return  <LinearProgressWrapper 
          startDate={projects[projectIndex].startDate} 
          endDate={projects[projectIndex].endDate} 
          variant='determinate' 
          classes={{root: classes.linearProgress}} 
          value={projects[projectIndex].timeline}
        />;
      }
     }
     return <StatusBox old={old} demo={demo} status={color}/>
  }

  pickWidth = ({i, demo, newHeader}) => {
    if (i === 0) return 200;
    if (newHeader[i] === 'Líder') return 120;
    if (newHeader[i] === 'Timeline') return 250; 
    else if (demo) return 140;
    else return 120;
  }

  isCustom = ({newHeader, i}) => {
    if (i === 0) return true;
    else if (newHeader[i] === 'Líder' || newHeader[i] === 'Timeline') return true;
    return false;
  }

  pickColor = (status) => {
    if (status === 'Em Andamento') return 'orange';
    if (status === 'Versão Final') return 'green';
    if (status === 'Em Revisão') return 'red';
  }

  render() {
    const { classes, header, old, rows, projects, contents, demo, title = 'Entregas' } = this.props;
    let data = [];
    let columns = [];
    let newHeader = [ "rows", ...header ];
    for (let i = 0; i < newHeader.length + 1; i++) {
        if (i === newHeader.length) continue;
        columns.push({
            Header: (this.isCustom({i, newHeader}) ? <div className={classes.title}> {i === 0 ? title : newHeader[i]} </div> : newHeader[i]),
            width: this.pickWidth({i, demo, newHeader}),
            accessor: newHeader[i],
        })
    }
    let color='';
    rows.map((element, index) => {
      var _data = {};
      newHeader.map((currentHeader, headerIndex) => {
         if (contents) { //if table is content based
          color = '';
          contents.forEach(content => {
            if (content.phase.name === currentHeader && element === content.element.name)
              color = this.pickColor(content.status);
          });
        } else if (projects) { //if table data is based on projects obj
            const pos =  projects[index].deliverySchedule.phases.map(phase => phase.name).indexOf(currentHeader);
            if (projects[index].deliverySchedule.phases[pos]) {
                color = projects[index].deliverySchedule.phases[pos].color; 
            } else color = '';
        } 
        _data = {..._data, [currentHeader]: this.pickHeader({newHeader, i: headerIndex, projects, contents, projectIndex: index, element: element, color, demo, old, classes})}
        return null;
      })
      data.push(_data);
      return null;
    });

    return (
      <div className={old ? classes.old : classes.new}>
        <Card classes={{root: classes.tablePlanning + ' ' + classes.tableWrapper}}>
            <ReactTable
                data={data}
                showPagination={false}
                pageSize={data.length}
                noDataText='Não foram encontrados eventos'
                sortable={false}
                resizable={false}
                columns={columns}
                getTheadThProps={() => {
                    return {
                    style: {
                        fontSize: '16px',
                        whiteSpace: 'noWrap',
                    }
                    }
                }}
                minRows={data.length}
                showPaginationBottom={false}
            />
        </Card>
      </div>
    );
  }
}

StatusTable.propTypes = {
  header: propTypes.object,
  rows: propTypes.object,
};

StatusTable.defaultProps = {
    header: [],
    rows: [],
    columnWidth: 120,
}

export default withStyles(style)(StatusTable);
