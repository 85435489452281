import React from "react";
import Avatar from "components/Avatar/Avatar.jsx";
import { AccessTime } from "@material-ui/icons";
import { withApollo, Mutation } from "react-apollo";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import notificationsStyle from "components/Notification/notificationsStyle.jsx";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { Redirect } from "react-router-dom";
import { CLICK_NOTIFICATION_MUTATION } from "components/Notification/notificationQueries.js";
class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false
    };
    this.unsubscribe = null;
  }

  /** [
        'project-invite',
        'project-removal',
        'user-change',
        'content-change',
        'content-addition',
        'content-comment',
        'juridical-change',
        'juridical-addition',
        'juridical-comment',
        'document-addition',
    ] */
  createNotificationTimestamp(notification) {
    return moment(notification.creationDate).format("DD/MM/YY hh:mm");
  }
  notificationRedirect(notification) {
    let redirectUrl = "";
    // Comentado por hora porque nenhuma acao gera uma notificacao que precise ir para
    // lista de conteudos, mas é possivel que isso mude.
    // let contentSubfolder = "0";
    // let contentFolder = "0";

    // if(!notification.juridical && notification.content.folder.parentFolder){
    //     contentSubfolder = notification.content.folder.id;
    //     contentFolder = notification.content.folder.parentFolder.id;
    // } else if(!notification.juridical){
    //     contentFolder = notification.content.folder.id;
    // }

    // if(notification.juridical && notification.content.juridicalFolder.parentFolder){
    //     contentSubfolder = notification.content.juridicalFolder.id;
    //     contentFolder = notification.content.juridicalFolder.parentFolder.id;
    // } else if(notification.juridical){
    //     contentFolder = notification.content.juridicalFolder.id;
    // }

    switch (notification.type) {
      case "project-invite":
        redirectUrl = `/project/${notification.project.id}/contents/0/0`;
        break;
      case "project-removal":
        redirectUrl = "/admin/projects";
        break;
      case "user-change":
        redirectUrl = "/admin/perfil";
        break;
      case "content-change":
        redirectUrl = `/content/view/${notification.content.id}`;
        break;
      case "content-addition":
        redirectUrl = `/content/view/${notification.content.id}`;
        break;
      case "content-comment":
        redirectUrl = `/content/view/${notification.content.id}`;
        break;
      case "juridical-change":
        redirectUrl = `/content/juridical-view/${notification.content.id}`;
        break;
      case "juridical-addition":
        redirectUrl = `/content/juridical-view/${notification.content.id}`;
        break;
      case "juridical-comment":
        redirectUrl = `/content/juridical-view/${notification.content.id}`;
        break;
      case "document-addition":
        redirectUrl = `/content/juridical-view/${notification.content.id}`;
        break;
      default:
        break;
    }
    this.setState({ isRedirected: redirectUrl });
  }

  render() {
    const {
      classes,
      notification,
      notificationRefetch,
      refetchLimit
    } = this.props;
    const { isRedirected, clicked } = this.state;

    let unseenNotificationClass =
      notification.clicked || clicked ? "" : classes.unseenNotification;
    let notificationImage = "";

    if (notification.agent && notification.agent.image)
      notificationImage = notification.agent.image.imageUrl;
    else if (notification.image)
      notificationImage = notification.image.imageUrl;

    return (
      <Mutation mutation={CLICK_NOTIFICATION_MUTATION}>
        {clickNotification => {
          return (
            <div
              className={
                classes.notificationBody + " " + unseenNotificationClass
              }
            >
              {isRedirected && <Redirect from={"/"} to={isRedirected} />}
              <span className={classes.notificationImage}>
                <Avatar
                  img={notificationImage}
                  userId={notification.agent ? notification.agent.id : null}
                />
              </span>
              <div
                className={classes.notificationWrapper}
                onClick={async e => {
                  //stops event from bubbling to other components and triggering their functions
                  e.stopPropagation();
                  await clickNotification({
                    variables: { id: notification.id }
                  });
                  //       ||||
                  /** this VVVV marks the notification as read optimisticaly on front-end only for responsiveness*/
                  this.setState({ clicked: true });
                  this.notificationRedirect(notification);
                  //       ||||
                  /** this VVVV updates the notification list after marking it as read */
                  notificationRefetch({ limit: refetchLimit });
                }}
              >
                <div>
                  {/* Type of notification */}
                  {notification.project && (
                    <span className={classes.projectName}>
                      <b>{notification.project.name + " :"}</b> <br />
                    </span>
                  )}
                  {/* Notification body */}
                  <div className={classes.notificationText}>
                    {notification.type !== "project-removal" && (
                      <b style={{ color: "black" }}>
                        {notification.agent ? notification.agent.name + " :" : ""}
                      </b>
                    )}
                    <span className={classes.notificationDescription}>
                      {" "}
                      {notification.text}{" "}
                    </span>
                  </div>
                </div>
                <div
                  className={classes.notificationFooter}
                  key={notification.id + clicked}
                >
                  <AccessTime classes={{ root: classes.accessTime }} />
                  {this.createNotificationTimestamp(notification)}
                  {!notification.clicked && !clicked && (
                    <VisibilityOutlinedIcon
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        clickNotification({
                          variables: { id: notification.id }
                        }).then(() => {
                          this.setState({ clicked: true });
                        });
                      }}
                      className={classes.seenIcon}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </Mutation>
    );
  }
}
export default withApollo(withStyles(notificationsStyle)(Notification));
