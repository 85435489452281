import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Search from "@material-ui/icons/Search";
import ReactTable from "react-table";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddCircle from "@material-ui/icons/AddCircle";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import EmailIcon from "@material-ui/icons/Email";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import TeamRoleTableStyle from "./TeamRoleTableStyle.js";
import emailVerification from 'utils/emailVerification.js';
import { withStyles } from "@material-ui/core";
import { Query, withApollo } from "react-apollo";
import { GET_ROLES_QUERY } from "./TeamRoleTableQueries.js";

class TeamRoleTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      email: "",
      emails: props.addedUsers ? props.addedUsers.map((vars)=> vars.email) : [],
      emailVerification: '',
      inputRef: React.createRef(),
    }
  }

  searchBar = () => {
    const { classes } = this.props;
    const { inputRef } = this.state;
    return (
      <div>
        <CustomInput
          classes={{ formControl: classes.searchInput }}
          disableUnderline
          formControlProps={{
            className: classes.top + " " + classes.search
          }}
          inputProps={{
            placeholder: "Nome",
            onChange: (e) => this.setState({ search: e.target.value }),
            inputProps: {
              ref: (ref) => this.setState({inputRef: ref}),
              "aria-label": "Nome",
              className: classes.searchInput
            }
          }}
        />
        <Button
          onClick={() => inputRef ? inputRef.focus() : null}
          color="white"
          aria-label="edit"
          justIcon
          round
        >
          <Search
            className={classes.headerLinksSvg + " " + classes.searchIcon}
          />
        </Button>
      </div>)
  }

  checkIfNewInvite(addedUsers, index){
    return addedUsers[index].email === '' && addedUsers[index].role === '';
  }

  newInvite() {
    const { getUsers, addedUsers } = this.props;
    getUsers([...addedUsers, {email:"", role:"", emailVerification:false}]);
  }

  emailInput ({email, role, index}) {
    const { classes, getUsers, addedUsers } = this.props;
    let isLast = (index === addedUsers.length - 1) ? true : false;
    let isNew = this.checkIfNewInvite(addedUsers, index);
    return (
      <GridContainer style={{ height: '55px' }} direction='row'>
        <GridItem xs={6}>
          <CustomInput
            error={!isNew && !addedUsers[index].emailVerification}
            id="invite"
            autocomplete="my-field-name1" 
            classes={{ labelRoot: classes.labelNormal, formControl: classes.noPaddingInput }}
            labelText={
              <span>
                Convidar usuário por e-mail
              </span>
            }
            formControlProps={{
              fullWidth: true
            }}
            labelProps={{
              classes: { shrink: classes.shrink }
            }}
            inputProps={{
              value: email,
              onChange: (e) => {
                const _addedUsers = addedUsers;
                _addedUsers[index].email = e.target.value;
                _addedUsers[index].emailVerification = emailVerification(e.target.value);
                this.setState({ email:e.target.value });
                getUsers(_addedUsers);
              },
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <EmailIcon
                    className={classes.inputAdornmentIcon}
                  />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={5}>
          <Query query={GET_ROLES_QUERY}>
            {({data: roleData, loading, error}) => {
              let roles;
              if (loading || roleData === undefined) roles = [];
              else roles = roleData.roles.filter( role => role.higherThanUser === false );
              if(error) return <h3>error</h3>;
              return (
                <CustomDropdown
                  error={!isNew && addedUsers[index].role === ""}
                  buttonText={role ? role.name : 'Papel'}
                  onClick={(role) => {
                    const _addedUsers = addedUsers;
                    _addedUsers[index].role = role;
                    this.setState({ role });
                    getUsers(_addedUsers);

                  }}
                  classes={{ button: classes.dropDown, butonText: classes.dropDownButtonText, buttonTextWrapper: classes.buttonTextWrapper, manager: classes.manager }}
                  dropdownList={roles.map(role => ({name: role.name, id: role.id, obj: true}))}//isNew might not be necessary here
                />
              )
            }}
          </Query>
        </GridItem>
        {isLast && <GridItem xs={1}>
          <Button onClick={() => { }} classes={{ root: classes.sendMailWrapper }} color='white' round justIcon>
            <span className={classes.sendMail}>
              <AddCircle
                onClick={() => this.newInvite()}
              />
            </span>
          </Button>
        </GridItem>}
      </GridContainer>)
  }

  render() {
    const { classes, data, organizations, showAddUser = true, addedUsers } = this.props;
    const { search } = this.state;
    let collumns = [{
      Header: (this.searchBar()),
      accessor: "name",
    },
    {
      Header: <div className={classes.header}> Papel </div>,
      accessor: "role",
      width: organizations ? 70 : 250,
    }];

    if (organizations) {
      const collumn = {
        Header: <div className={classes.header}> Organização </div>,
        accessor: "organization",
      };
      collumns = [collumns[0], collumn, collumns[1]]
    }
    const _data = data.filter((member) => member._name.toLowerCase().includes(search.toLowerCase()))
    return (
      <div className={classes.tableWrapper}>
        <ReactTable
          data={_data}
          sortable={false}
          showPagination={false}
          pageSize={data.length}
          noDataText='Não foram encontrados membros'
          resizable={false}
          getTheadProps={() => {
            return {
              style: {
                height: '70px',
              }
            }
          }}
          columns={collumns}
          defaultPageSize={_data.length}
          showPaginationBottom={false}
        />
        {showAddUser && 
          <div>
            {
              addedUsers.map((inviteVaribles, index) => this.emailInput({email:inviteVaribles.email, role:inviteVaribles.role, index}))
            } 
          </div>
        }
      </div>
    )
  }
}

export default withApollo(withStyles({ ...regularFormsStyle, ...TeamRoleTableStyle })(TeamRoleTable));