import React from "react";
// react component used to create a calendar with events on it
import BigCalendar from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";
// react component used to create alerts
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import "moment/locale/pt-br";
// core components
import CalendarStyle from "./CalendarStyle.js";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import messages from "./messages";
import Loading from "components/Loading/Loading";
import { withApollo, Query } from "react-apollo";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import ModalCard from "components/ModalCard/ModalCard.jsx";
import { editPhases, editElements } from 'views/Panel/DashboardUtils';
import { addCard, editCard, GET_BOARDS_BY_PROJECT } from "views/Planning/PlanningQueries";
import { GET_PROJECT_QUERY } from "../../views/Planning/PlanningQueries.js";
import './style/style.scss'; // if using DnD

const DnDCalendar = withDragAndDrop(BigCalendar);

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      open: false,
      slot: undefined,
      card: undefined,
    };
    this.hideAlert = this.hideAlert.bind(this);
  }

  selectedEvent(event) {
    this.setState({card: event.task, open: true})
  }

  addNewEventAlert(slotInfo) {
    this.setState({
      open: true,
      slot: slotInfo,
    });
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  eventColors(event, start, end, isSelected) {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  }

  oncardAdd = ({ boardId, title, status, description, startDate, endDate }) => {
    const { client, projectId } = this.props;
    addCard({ params: { boardId, projectId, title, status, description, startDate, endDate }, client, onSucess: () => this.setState({ open: false, card: undefined }) });
  }

  onCardEdit = ({id, boardId, title, status, description, startDate, endDate }) => {
    const { client, projectId } = this.props;
    editCard({params: {id, boardId, projectId, title, description, status, startDate, endDate}, client,  onSucess: () => this.setState({ open: false, card: undefined })});
  }
  onEventResize = (type, { event, start, end, allDay }) => {
    this.setState(state => {
      state.events[0].start = start;
      state.events[0].end = end;
      return { events: state.events };
    });
  };

  changeElementDeadline({ event, start, end, phases }){
    const { client, projectId } = this.props;
    const endDate = moment(end).format('DD/MM/YYYY');
    const phaseId = event.phase.id;
    let newElements = [{
      id: event.id,
      endDate: endDate,
    }]; 
  
    editElements({ params: { projectId, phaseId, elements:newElements  }, client, getPhases: () => {} });
  }

  changePhaseDate({ event, start, end, phases }){
    const { client, projectId } = this.props;
    const startDate = moment(start).format('DD/MM/YYYY');
    const endDate = moment(end).format('DD/MM/YYYY');
    phases = phases.map(phase => {
      if( event.type === "phase_start" ){
        return {
          id: phase.id,
          name: phase.name,
          completed: phase.completed,
          startDate: (phase.id !== event.phase.id) ? phase.starDate : startDate,
          endDate: phase.endDate,
        }
      }
      if( event.type === "phase_end" ){
        return {
          id: phase.id,
          name: phase.name,
          completed: phase.completed,
          startDate: phase.starDate,
          endDate: (phase.id !== event.phase.id) ? phase.endDate : endDate,
        }
      }
      return null;
    })
    editPhases({params: {projectId, phases}, client, getPhases: () => {}});
  }

  onEventDrop = ({ event, start, end, phases }) => {
    if(event.isPhase) this.changePhaseDate({ event, start, end, phases });
    if(!event.isPhase) this.changeElementDeadline({ event, start, end, phases });
  };


  render() {
    const { classes, projectId } = this.props;
    const { open, slot, card } = this.state;
    const localizer = BigCalendar.momentLocalizer(moment);

    return (
      <div>
        {open && <ModalCard
          projectId={projectId}
          newTask={!!card}
          open={open}
          GET_BOARDS={GET_BOARDS_BY_PROJECT}
          onSave={card ? this.onCardEdit : this.oncardAdd}
          onClose={() => this.setState({ open: false, card: undefined })}
          card={card ? card : {
            startDate: slot ? moment(slot.start, 'DD/MM/YYYY').format('DD/MM/YYYY') : '',
            endDate: slot ? moment(slot.end, 'DD/MM/YYYY').format('DD/MM/YYYY') : '',
          }}
        />}
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={11}>
            <Card classes={{card: classes.card}}>
              <CardBody calendar>
                <div className={classes.calendar}>
                  <Query query={GET_PROJECT_QUERY} variables={{ projectId }}>
                    {({ data, loading, error }) => {
                      if (loading) return <Loading />
                      if (error) console.error('error ', error);
                      const phases = data.project.schedule.phases;
                      let evts = [];
                      phases.map((phase, index) => {
                        if (phase.startDate && phase.endDate) {
                          //push both startDate and endDate into separate evts
                          evts.push({
                            title: 'Início - Etapa ' + phase.name,
                            allDay: false,
                            phase: phase,
                            id: phase.id,
                            start: new Date(moment(phase.startDate, 'DD/MM/YYYY')),
                            end: new Date(moment(phase.startDate, 'DD/MM/YYYY')),
                            color: 'blue',
                            isPhase: true,
                            type:"phase_start"
                          });
                          evts.push({
                            title: 'Fim - Etapa ' + phase.name,
                            allDay: false,
                            phase: phase,
                            id: phase.id,
                            start: new Date(moment(phase.endDate, 'DD/MM/YYYY')),
                            end: new Date(moment(phase.endDate, 'DD/MM/YYYY')),
                            color: 'blue',
                            isPhase: true,
                            type:"phase_end"

                          });
                          phase.elements.map(element => {
                            evts.push({
                              title: phase.name + ' - ' + element.name,
                              allDay: false,
                              phase,
                              id: element.id,
                              start: new Date(moment(element.endDate, 'DD/MM/YYYY')),
                              end: new Date(moment(element.endDate, 'DD/MM/YYYY')),
                              isPhase: false,
                              type:'subPhase_end'
                            });
                            return element;
                          })
                        }
                        return phase;
                      });

                      return (
                        <DnDCalendar
                          selectable
                          resizable
                          localizer={localizer}
                          events={evts}
                          defaultView="month"
                          startAccessor="start"
                          endAccessor="end"
                          defaultDate={new Date()}
                          onEventDrop={(obj) => this.onEventDrop({...obj, phases})}
                          onEventResize={this.onEventResize}
                          culture="pt-br"
                          messages={messages}
                          eventPropGetter={event => ({className: event.isPhase ? 'event-phase' : 'event-element' })}
                          />
                      )
                    }}
                  </Query>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withApollo(withStyles(CalendarStyle)(Calendar));
