export default {
  juridicalContentWrapper:{
    overflow:"auto !important"
  },
  draggableIcon: {
    display:'block', 
    pointerEvents: 'none', 
    position: 'fixed',
    backgroundColor: '#3f2c77c4', 
    border: '2px dashed #3f2c77', 
    width: '150px', 
    height: '150px', 
    borderRadius: '6px', 
    zIndex: '9999', 
    animation: 'all 0.5 linear',
    '& svg, span': {
      color: 'white',
      marginLeft: 'Calc(50% - 35px)',
      marginTop: 'Calc(50% - 40px)',
      width: '70px',
      height: '70px',
      fontSize: '62px'
    }
  },
  draggablePlaceholder: {
    border: '1px dashed #3f2c77', 
    backgroundColor: '#3f2c7710',
    borderRadius: '2px',
    height: '45px',
    width: '100%',
    position: 'absolute',
    pointerEvents: 'none'
  },
  trWrapper: {
    cursor: 'default'
  }
}