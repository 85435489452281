import React from "react"
import { withStyles } from "@material-ui/core";
import OrganizationSelectTable from "components/Organization/OrganizationTable/organizationTable.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import ExpandableInfo from "components/ExpandableInfo/ExpandableInfo";

import CollaborationInviteModalStyle from '../CollaborationInviteModalStyle';
const Tab2 = ({ classes, data, setNextTab, handleClose, roles, setRoles, selectedRole, inviteCollab }) => {
  let roleList= roles.map((role) => {return({name: role.name, obj:true, roleId:role.id})})

  const texts = [
    {
      title: '',
      text: 'Acima selecione o cargo da organização convidada. Lembrando: este conjunto de permissões irá limitar o que a organização convidada pode fazer dentro do projeto'
    },
  ]

  return (
    <div>
      <div className={classes.scrollableContent}>
        <div className={classes.info}>
          Selecione uma organização com que você já tenha trabalhado no passado. 
          Ou clique na outra aba para convidar uma nova organização.
        </div>
        <div className={classes.TeamRoleTableWrapper}>
          <OrganizationSelectTable 
            autocomplete 
            showAddUser={false} 
            noDatatext='Esta organização ainda não está cadastrada, clique na aba ao lado para convidar.'
            hide
            data={data} 
            defaultPageSize={4}
            header="Nome da Organização"
          />
        </div>
      
        <div style={{display:"flex", justifyContent:"center"}}>
          <CustomDropdown
            buttonText={selectedRole.name ? selectedRole.name :'Nome do Cargo'}
            onClick={(role) => setRoles(role)}
            classes={{ button: classes.dropDown, butonText: classes.dropDownButtonText, buttonTextWrapper: classes.buttonTextWrapper }}
            dropdownList={roleList}
          />
        </div>
        <ExpandableInfo 
          title='Sobre Permissões Externas' 
          texts={texts}
          classes={{expandable: classes.expandable}}
        />
      </div>
      <div className={classes.footerButtonWrapper}>
          <Button 
              color="gray"
              onClick={() => handleClose()} 
              >
              Fechar
          </Button>
          <Button 
            color="purple"
            onClick={() => inviteCollab()}
          >
            Convidar
          </Button>
      </div>
    </div>
  )
}

export default withStyles(CollaborationInviteModalStyle)(Tab2);