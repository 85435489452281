import React from "react";
import { withStyles } from "@material-ui/core";

import Menu from "@material-ui/core/Menu";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Icon from "@material-ui/core/Icon";

import CustomMenuStyle from "./CustomMenuStyle.js";

class CustomMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOption: null
    };
  }

  handleBackToRoot = () => {
    this.setState({ currentOption: null });
  };

  position = () => {
    setTimeout(() => {
      const picker = document.querySelector("#long-menu div:nth-child(2)");
      if (picker) {
        let top = picker.getBoundingClientRect().top;
        let left = picker.getBoundingClientRect().left;
        if (top + 200 > window.innerHeight) {
          top = window.innerHeight - 250;
        }
        picker.setAttribute(
          "style",
          "top: " + top + "px; left: " + left + "px !important"
        );
      }
    }, 100);
  };

  render() {
    const {
      options,
      onClose,
      classes,
      isOpen,
      anchorEl,
      callBackArgs,
      style
    } = this.props;
    const { currentOption } = this.state;
    if (currentOption && currentOption[0].isComponent) {
      const propsToSendToNewComponent = {
        ...this.props,
        classes: {},
        back: () => this.handleBackToRoot(),
        close: e => onClose(e),
        anchorEl,
        ...callBackArgs
      };
      const ClonedElem = React.cloneElement(
        currentOption[0].component,
        propsToSendToNewComponent
      );
      return <div style={{ position: "relative" }}> {ClonedElem} </div>;
    }

    this.position();

    return (
      <Menu
        id="long-menu"
        open={isOpen}
        className="long-menu"
        anchorEl={anchorEl}
        classes={{ paper: classes.paper }}
        onClose={e => {
          setTimeout(() => this.handleBackToRoot(), 500);
          onClose(e);
          e.stopPropagation();
        }}
        MenuListProps={{
          classes: { root: classes.menu }
        }}
        PaperProps={{
          ...style,
          classes: {}
        }}
      >
        <MenuList classes={{ root: classes.menuList }}>
          <>
            {currentOption && (
              <>
                <MenuItem
                  onClick={e => {
                    this.handleBackToRoot();
                    e.stopPropagation();
                  }}
                  className={classes.menuListBack}
                  classes={{ root: classes.menuItem }}
                  key={0}
                >
                  <div className={classes.back}>
                    <ChevronLeft />
                    <span> Voltar </span>
                  </div>
                </MenuItem>
                <hr />
              </>
            )}
            {(currentOption ? currentOption : options).map((option, index) =>
              option.type === "hr" ? (
                <hr />
              ) : (
                <MenuItem
                  classes={{ root: classes.menuItem }}
                  key={index + 1}
                  disabled={option.disabled}
                  onClick={e => {
                    option.func({ ...callBackArgs, name: option.name });
                    if (!option.subOptions) {
                      onClose(e);
                      setTimeout(() => this.handleBackToRoot(), 500);
                    } else this.setState({ currentOption: option.subOptions });
                    e.stopPropagation();
                  }}
                >
                  <div style={{ display: "flex", width: "100%" }}>
                    {typeof option.icon === "string" ? (
                      <Icon
                        className={classes.iconMenuList}
                        style={{
                          color: option.colorIcon ? option.colorIcon : "#808080"
                        }}
                      >
                        {option.icon}
                      </Icon>
                    ) : (
                      <option.icon
                        color={option.colorIcon ? option.colorIcon : "#808080"}
                        width="30px"
                        height="18px"
                        classes={{ root: classes.iconMenuList }}
                      />
                    )}
                    <div className="optionText"> {option.name} </div>
                    {option.subOptions ? (
                      <ChevronRight classes={{ root: classes.options }} />
                    ) : null}
                  </div>
                </MenuItem>
              )
            )}
          </>
        </MenuList>
      </Menu>
    );
  }
}

export default withStyles(CustomMenuStyle)(CustomMenu);
