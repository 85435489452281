import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import JuridicoIcon from "assets/icons/Juridico";

const style = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 180
  },
  statusWrapper: {
        display: 'flex',
        justifyContent: 'left',
        '& div': {
          width: '130px',
          fontSize: '12px',
          borderRadius: '13px',
          color: 'white',
          padding: '0 20px',
          fontWeight: 'normal',
          display: 'flex',
          justifyContent: 'space-around'
        }
      },
      statusOrange: {
        '& div': {
          backgroundColor: '#FF994A',
        }
      },
      statusRed: {
        '& div': {
          backgroundColor: '#FF574A',
        }
      },
      statusGreen: {
        '& div': {
          backgroundColor: '#0bc23f',
        }
      },
  juridicalWrapper: {
    '& div': {
      width: '22px !important',
      height: '22px !important',
      marginRight: '10px',
      padding: '0 2px',
      borderRadius: '50%'
    }
  },
  juridicalIcon: {
    marginRight: '0px !important',
    '& svg': {
      height: '13px !important',
      marginTop: '4px'
    }
  },
  fill: {
    width: '100%',
    marginRight: '15px',
    '& div': {
      width: '130px'
    }
  }
};

 const StatusBedge = ({classes, status, contentJuridical, juridical, juridicalStatus}) => {
    const pickStatusColor = (status) => {
        switch (status.toLowerCase()) {
          case 'em andamento':
          case 'pendente':
            return classes.statusOrange;
          case 'versão final':
          case 'aprovado':
            return classes.statusGreen;
          case 'em revisão':
            return classes.statusRed;
          default:
            return classes.statusRed;
        }
      }
    return (
        <div className={classes.root}>
            <div id="statusWrapper" className={classes.statusWrapper + ' ' + ( juridical ? pickStatusColor(juridicalStatus) : pickStatusColor(status) ) + ' ' + (juridical ? '' : classes.fill )}> 
              <div id='status'> {juridical ? juridicalStatus : status } </div> 
            </div>
            {(contentJuridical && !juridical) && <div id="juridical" className={classes.statusWrapper + ' ' + classes.juridicalWrapper+ ' ' + pickStatusColor(juridicalStatus)}>
              <div> <JuridicoIcon classes={{root: classes.juridicalIcon}}/> </div>
            </div>}
      </div>
    )
}
  
export default withStyles(style)(StatusBedge);