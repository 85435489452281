import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
const style = {
  root: {
    float: 'left',
    marginRight: '3px'
  }
}
const Juridico = ({classes, color, width = "27px", height = "21px"}) => {
	return (
		<div className={classes.root}>
      <svg width={width} height={height} viewBox="0 0 22 21">
          <g id="Page-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g className="icon" transform="translate(-27.000000, -274.000000)" fill={color ? color :"#FFFFFF"} fillRule="nonzero">
                  <g id="scale-balance" transform="translate(27.187500, 274.000000)">
                      <path d="M20.2244793,2.58552632 L20.2244793,4.04605263 L17.9140481,4.04605263 L21.3422112,12.3017074 C21.8272686,14.0707236 20.5459624,15.0986842 17.8768302,15.0986842 C15.2414396,15.0986842 14.0032705,14.0873432 14.4304552,12.2424895 L17.6241752,4.56486533 L17.8396123,4.04605263 L13.6082279,4.04605263 L13.5175016,4.29063012 C13.2064315,5.12920368 12.5776896,5.76730035 11.7531616,6.08232931 L11.5120022,6.17446952 L11.5120022,19.1644737 L21.3135389,19.1644737 L21.3135389,20.625 L0.282346306,20.625 L0.282346306,19.1644737 L10.083883,19.1644737 L10.083883,6.18837983 L9.84635149,6.09478966 C9.01890521,5.7687662 8.38804188,5.1285166 8.06615101,4.28706781 L7.97395218,4.04605263 L3.75627294,4.04605263 L7.18443597,12.3017074 C7.66949343,14.0707236 6.38818717,15.0986842 3.71905501,15.0986842 C1.08366446,15.0986842 -0.154504738,14.0873432 0.27268002,12.2424895 L3.46639996,4.56486533 L3.68183708,4.04605263 L1.37140594,4.04605263 L1.37140594,2.58552632 L7.9941425,2.58552632 L8.08130481,2.33278591 C8.48650306,1.15785169 9.57469253,0.375 10.7979426,0.375 C12.0202704,0.375 13.1104108,1.15727474 13.5263754,2.33537752 L13.6146979,2.58552632 L20.2244793,2.58552632 Z M10.7979426,1.83552632 C9.98811498,1.83552632 9.33388298,2.49949228 9.33388298,3.31578947 C9.33388298,4.13208667 9.98811498,4.79605263 10.7979426,4.79605263 C11.6077702,4.79605263 12.2620022,4.13208667 12.2620022,3.31578947 C12.2620022,2.49949228 11.6077702,1.83552632 10.7979426,1.83552632 Z M3.71905501,6.99047547 L1.53458879,12.5328947 L5.90352123,12.5328947 L3.71905501,6.99047547 Z M17.8768302,6.99047547 L15.692364,12.5328947 L20.0612964,12.5328947 L17.8768302,6.99047547 Z" id="Combined-Shape"></path>
                  </g>
              </g>
          </g>
      </svg>
		</div>
	);
}

export default withStyles(style)(Juridico);
