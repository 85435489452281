import gql from "graphql-tag";

const GET_PROJECTS = gql`
  query GET_PROJECTS {
    projects {
      id
      name
    }
  }
`

const GET_MY_ORGANIZATION_QUERY = gql`
  query GET_MY_ORGANIZATION_QUERY {
    myOrganization {
      id
      name
      roles{
        id
        name
      }
    }
  }
`;

export { GET_PROJECTS, GET_MY_ORGANIZATION_QUERY }