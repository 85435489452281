import React, { useState } from "react"
import { withStyles, Card } from "@material-ui/core";
import TeamRoleTable from "components/TeamRoleTable/TeamRoleTable.jsx";
import EditTeamModalStyle from 'components/EditTeamModal/EditTeamModalStyle';
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import Check from "@material-ui/icons/Check";
import RoleConfig from "components/Role/RoleConfig/RoleConfig";
import Checkbox from "@material-ui/core/Checkbox";
import Bedge from "components/BedgePlaceHolder/BedgePlaceHolder.jsx";
import Button from "components/CustomButtons/Button.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { sendEmail } from "components/Organization/OrganizationTable/TeamRoleTableQueries.js";
import { withApollo } from "react-apollo";

function checkValidInputs(userInvites){
  return (
    !userInvites.some((inviteVars)=>{
      if(inviteVars.email !== "" && !inviteVars.emailVerification ) 
        return true;
      
      if(inviteVars.email !== "" && inviteVars.role === "") 
        return true;
      
      return false;  
    })
  );
}

const NewMembersTable = ({ classes, setPreviousTab, checkRowTab, projectId, onClickRowTab, addProjectMembers, members, client, onClose, showAlert }) => {
  const [isAboutRolesOpen, setIsAboutRolesOpen] = useState(false);
  const [userInvites, setUserInvites] = useState([{email:"", role:"", emailVerification:false}]);
  const [isModalOpen, setIsModalOpen ] = useState(false);
  let orgData = [];
  
  if (members) {
    orgData = members.map((member) => {
      if (!member) return {};
      let memberRow = {
        _name: member.name,
        name: <div className={classes.tableDataWrapper}>
          <Checkbox
            checked={checkRowTab.indexOf(member.id) >= 0}
            tabIndex={-1}
            onClick={() => onClickRowTab(member.id)}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />
          {member.name}
        </div>,
        role: member.role ? <div className={classes.roleWrapper}> <Bedge text={member.role.name}> </Bedge> <span> {member.role.name} </span> </div> : "",
        organization: <div className={classes.tableDataWrapperOrganization}> <p> {member.organization.name} </p> </div>
      };
      return memberRow;
    });
  }
  return (
    <div>
      {isModalOpen && <RoleConfig open={isModalOpen} toggleModal={() => {setIsModalOpen( !isModalOpen )}} />}
      <div>
        <div className={classes.TeamRoleTableWrapper}>
          { 
            members && 
            <TeamRoleTable data={orgData} 
              getUsers={(users) => {
                setUserInvites(users);
              }} 
              addedUsers={userInvites}
            />
          }
        </div>
      </div>
      <div onClick={() => setIsAboutRolesOpen(!isAboutRolesOpen)} className={classes.permissionsWrapper}>
        <Card classes={{ root: classes.permissions + ' ' + (isAboutRolesOpen ? classes.permissionsOpen : '' )}}>
          <div className={classes.permissionsHeader}  >
            <div> Sobre Cargos </div>
            {!isAboutRolesOpen && <ExpandMore/>}
            {isAboutRolesOpen && <ExpandLess/>}
          </div>
          <div className={classes.permissionText}>
            <span> Gestor de Organização - </span>  Principal responsável pela Produção da empresa. Tem acesso total e pode realizar alterações em qualquer projeto da organização
          </div>
          <div className={classes.topLine}/>
          <div className={classes.permissionText}>
            <span> Gerente de Projetos - </span> Responsável pela Gestão de um ou mais Projetos. Pode realizar alterações somente dentro dos Projetos dos quais faça parte.
          </div>
          <div className={classes.topLine}/>
          <div className={classes.permissionText}>
            <span> Outros Cargos - </span> Caso precise criar novos cargos com outras permissões,<span className={classes.link} onClick={(e) => {e.preventDefault(); setIsModalOpen(true)}}>  clique aqui </span> ou vá à área de Organização e permissões da sua Organização após a criação do projeto.
          </div>
        </Card>
      </div>
      <div className={classes.footerOnlyOneButton}>
        {setPreviousTab && <Button
          color="gray"
          onClick={() => setPreviousTab()}
        >
          Anterior
        </Button>}
        <Button
          color="purple"
          onClick={async () => {
            let invitesVerified = checkValidInputs(userInvites);
            if(invitesVerified){
              await addProjectMembers();
              let emailsSent = [];
              if(userInvites.length > 0 ){//filters any blank invite
                let _userInvites = userInvites.filter( variables => variables.email !== "" );
                for( let inviteVars of _userInvites ){
                  let confirmed = await sendEmail({
                    client, 
                    params:{
                      destination: inviteVars.email,
                      roleId: inviteVars.role.id,
                      projectId: projectId 
                    }
                  });
                  if(confirmed) emailsSent.push({ destination: inviteVars.email, sent:true });
                  else emailsSent.push({ destination: inviteVars.email, sent:false });
                }
                let confirmedEmails = emailsSent.filter(email => email.sent);
                let notConfirmedEmails = emailsSent.filter(email => !email.sent);
                
                if(confirmedEmails.length > 0 && notConfirmedEmails.length > 0) showAlert('Alguns convites não foram enviados', notConfirmedEmails, "danger");
                if(confirmedEmails.length > 0 && notConfirmedEmails.length === 0) showAlert('Todos os convites enviados com sucesso', [], 'success');
                if(confirmedEmails.length === 0 && notConfirmedEmails.length > 0) showAlert('Nenhum convite foi enviado', [], "danger");

                onClose();
              }
            }
            else
              window.alert("Todos os convites por e-mail devem ser preenchidos corretamente ou deixados em branco");
          }}
        >
          Finalizar
        </Button>
      </div>
    </div>
  )
}

export default withApollo(withStyles({ ...regularFormsStyle, ...EditTeamModalStyle })(NewMembersTable));