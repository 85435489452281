import React, { Component } from 'react';
import { withStyles, Card } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandableInfoStyle from './ExpandableInfoStyle';
class ExpandableInfo extends Component {
  constructor (props) {
    super(props);
    this.state = {
      open: false,
    }
  }
  
  setOpen = (open) => this.setState({open});

  render() {
    const { classes, texts, title } = this.props;
    const { open } = this.state;
    return (
      <div onClick={() => this.setOpen(!open)} className={classes.expandableWrapper}>
        <Card classes={{ root: classes.expandable + ' ' + (open ? classes.expandableOpen : '' )}}>
          <div className={classes.expandableHeader}>
            <div> {title} </div>
            {!open && <ExpandMore />}
            {open && <ExpandLess />}
          </div>
          {texts.map((text, i) => {
            return (
              <>
              <div  onClick={e => e.preventDefault()} className={classes.expandableText}>
                <span> {text.title} - </span>  {text.text}
                </div>
                {i !== texts.length -1 && <div className={classes.topLine}/>}
              </>
            )
          })}
        </Card>
      </div>
    );
  }
}

export default withStyles(ExpandableInfoStyle)(ExpandableInfo);