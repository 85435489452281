import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Logo extends PureComponent {
  render() {
    if (this.props.full) {
      return(
        <svg
          xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" height="100%" width="100%" version="1.1" viewBox="0 0 756.35045 222.99217">
          <path fill="#4a148c" d="m174.69876 200.699-5.39733 4.90933c-25.60534 23.28934-67.23733 23.16134-92.988-.284l-56.922665-51.82933c-25.7506664-23.44533-25.8693331-61.333329-0.264-84.622662l56.606665-51.487999c25.60533-23.2893323 67.23733-23.1626657 92.988.284l5.42 4.934667c54.19466 49.345332 54.44533 129.081334.55733 178.095994"/>
          <path fill="#e91e63" d="m193.36463 79.517671c0 15.661333-23.74267 15.661333-23.74267 0 0-15.664 23.74267-15.664 23.74267 0"/>
          <path fill="#ffffff" d="m143.27583 89.740471-9.89333 24.007999-11.716 32.18-11.87334-32.504-9.73733-23.470666v-.265333h-20.521334v-24.691999l-17.075999 5.350666v19.341333h-9.818667v14.509329h9.818667v32.80934c0 17.37066 9.552 27.10533 26.017333 26.49333 5.286666-.14133 9.511999-.984 14.43467-2.892l-4.700004-14.37333c-2.76 1.26-5.885333 2.00133-8.504 2.00133-6.464 0-10.324-4.19733-10.324-11.22933v-32.80934h8.446667l24.509337 57.61734h18.65333l30.51466-72.074669z"/>
          <path fill="#ffffff" d="m344.30902 113.5966v48.68h-12.58667v-47.61867c0-18.65333-18.80533-25.325329-31.544-11.07066l.30267 58.68933h-12.73733l-.304-51.41067v-.30266c0-12.89067-15.468-18.805336-31.392-12.132003l.304 63.845333h-12.73867l-.152-71.579999c13.496-6.066667 29.42-7.734667 39.42933-3.792 4.54934 1.821333 7.73334 3.792 10.16 6.218666 7.43067-6.976 18.04667-9.706666 29.26934-7.278666 13.49733 2.577333 21.98933 13.193333 21.98933 27.751999"/>
          <path fill="#ffffff" d="m369.18142 139.52887c0 11.98 13.80133 16.37733 34.57733 11.37333v-22.596c-13.19466-2.27466-34.57733-5.004-34.57733 11.22267m-3.184 19.25867c-12.73867-9.09867-13.80133-25.32534-2.73067-35.48534 12.436-10.616 32.15067-7.128 40.492-4.09466v-7.584c0-9.856-6.52133-15.770669-17.44-15.770669-7.58266 0-12.73866 2.729333-16.076 8.946669l-12.132-4.7c5.764-10.616002 15.31734-15.924002 28.208-15.924002 19.25867 0 30.93734 10.312 30.93734 26.993332v48.984c-8.18934 2.73067-19.412 4.55067-27.90534 4.55067-9.25066 0-16.984-1.82-23.35333-5.916"/>
          <path fill="#ffffff" d="m479.43275 162.2762v-46.40533c0-17.742668-16.68133-24.416002-38.064-15.468v61.87333h-12.74v-71.275998c33.97067-15.165333 63.24-3.942667 63.24 24.111998v47.164z"/>
          <path fill="#ffffff" d="m515.22435 139.52887c0 11.98 13.80133 16.37733 34.57733 11.37333v-22.596c-13.19466-2.27466-34.57733-5.004-34.57733 11.22267m-3.18533 19.25867c-12.73734-9.09867-13.8-25.32534-2.72934-35.48534 12.436-10.616 32.15067-7.128 40.492-4.09466v-7.584c0-9.856-6.52133-15.770669-17.44-15.770669-7.584 0-12.73866 2.729333-16.076 8.946669l-12.132-4.7c5.764-10.616002 15.31734-15.924002 28.208-15.924002 19.25867 0 30.93734 10.312 30.93734 26.993332v48.984c-8.19067 2.73067-19.412 4.55067-27.90534 4.55067-9.25066 0-16.984-1.82-23.35466-5.916"/>
          <path fill="#ffffff" d="m608.33902 95.853137c-12.89067 0-20.77733 6.974663-20.77733 18.349333l.304 16.83333c0 17.28934 13.49733 23.20267 36.244 16.53067v-49.894666c-4.39734-1.212-9.09867-1.818667-15.77067-1.818667m28.66133-6.066667v74.764c0 18.80533-11.828 30.63466-30.33066 30.63466-12.132 0-21.68667-3.94266-29.572-12.284l9.252-8.03733c5.45866 5.76267 11.98 8.492 20.01733 8.492 16.22667 0 17.74267-11.828 17.74267-18.80533v-5.45867c-6.21734 1.364-8.94667 2.27467-14.86134 2.27467-21.38266 0-33.81866-10.92-34.27333-30.33067l-.30267-17.13733c-.304-16.681333 14.55867-29.418666 34.272-29.418666 9.1 0 18.95734 1.818667 28.056 5.306666"/>
          <path fill="#ffffff" d="m697.35715 119.81407v-7.73467c0-9.25066-7.73333-16.226663-17.89467-16.226663-10.91866 0-18.35066 6.521333-18.35066 16.074663v7.43067zm-36.396 14.55867c0 11.67733 6.67333 18.19733 18.50133 18.19733 8.18934 0 13.19467-2.42667 16.53067-7.88533l11.98 5.308c-5.76267 9.40266-14.25467 13.19333-28.51067 13.19333-19.41066 0-31.088-11.82933-31.088-31.544v-19.56267c0-16.074663 12.43467-26.690662 31.088-26.690662 16.53067 0 30.63467 12.131999 30.63467 26.690662v16.68134l-49.136-.304z"/>
          <path fill="#ffffff" d="m732.69315 112.07967v50.196h-12.73867v-52.32c0-12.586666 7.58267-20.926666 22.29334-24.414666 3.48666-.76 9.55333-.76 14.10266-.153333v11.375999c-14.25466-1.669333-23.65733 4.24533-23.65733 15.316"/>
        </svg>
      );
    }

    return(
      <svg
        xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" version="1.1" viewBox="0 0 214.95166 222.99217">
        <path fill="#4a148c" d="m174.69876 200.699-5.39733 4.90933c-25.60534 23.28934-67.23733 23.16134-92.987998-.284l-56.922665-51.82933c-25.7506663-23.44533-25.869333-61.33333-0.264-84.622663l56.606665-51.487999c25.605328-23.2893328 67.237328-23.1626662 92.987998.284l5.42 4.934667c54.19466 49.345332 54.44533 129.081335.55733 178.095995"/>
        <path fill="#e91e63" d="m193.36463 79.51767c0 15.661333-23.74267 15.661333-23.74267 0 0-15.664 23.74267-15.664 23.74267 0"/>
        <path fill="#ffffff" d="m143.27583 89.74047-9.89333 24.008-11.716 32.18-11.87334-32.504-9.73733-23.470667v-.265333h-20.521332v-24.691999l-17.075999 5.350666v19.341333h-9.818667v14.50933h9.818667v32.80934c0 17.37066 9.552 27.10533 26.017333 26.49333 5.286666-.14133 9.511999-.984 14.434668-2.892l-4.700002-14.37333c-2.76 1.26-5.885333 2.00133-8.504 2.00133-6.464 0-10.324-4.19733-10.324-11.22933v-32.80934h8.446667l24.509335 57.61734h18.65333l30.51466-72.07467z"/>
      </svg>
    );
  }
}

Logo.propTypes = {
  full: PropTypes.bool,
};

export default Logo;