import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import propTypes from 'prop-types'; // ES6
import AddCircle from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";

const style = {
  root: {
    display: 'flex',
    borderBottom: '1px solid #D2D2D2',
    marginLeft: '10px',
    height: '18px',
    '& input': {
      width: '25px',
      margin: '0px 5px',
      border: 'none',
      backgroundColor: 'transparent',
      fontSize: '13px',
      textAlign: 'center'
    },
  },
  icon: {
    width: '11px',
    height: '11px',
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '8px',
    padding: '1px 0',
    color: 'white',
    marginTop: '0px',
    cursor: 'pointer',
    '& svg': {
      fontSize: '15px',
      color: '#AAAAAA',
    }
  }
};

class NumberSelector extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  sum = () => {
    const { value } = this.state;

    this.setState({value: value + 1});
    this.props.getValue(value + 1);
  }
  
  sub = () => {
    const { value } = this.state;

    this.setState({value: (value - 1) < 0 ? value : (value - 1) });
    this.props.getValue(value - 1);
  } 
  
  static getDerivedStateFromProps(nextProps) {
    return {
      value: nextProps.value,
    };
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <div className={classes.root}>
          <div onClick={this.sub.bind(this)} className={classes.icon}> <RemoveCircle/> </div> 
          <input onChange={(e) => this.props.getValue(e.target.value)} value={value}/> 
          <div onClick={this.sum.bind(this)} className={classes.icon}> <AddCircle/> </div>
      </div>
    );
  }
}

NumberSelector.propTypes = {
  getValue: propTypes.func,
  value: propTypes.number,
};

NumberSelector.defaultProps = {
  getValue: () => {},
  value: 0,
}

export default withStyles(style)(NumberSelector);
