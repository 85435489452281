import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import AudioPlayerStyle from "./AudioPlayerStyle.jsx";
class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.audioPlayer = React.createRef();
  }

  async componentDidMount() {
    const { setAudioPlayer } = this.props;
    if (this.audioPlayer) {
      setAudioPlayer(this.audioPlayer);
    }
  }

  render() {
    const { classes, fileUrl } = this.props;
    return (
      <div className={classes.audioContent}>
        <audio ref={this.audioPlayer} src={fileUrl} controls />
      </div>
    );
  }
}

export default withStyles(AudioPlayerStyle)(AudioPlayer);
