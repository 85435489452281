import { gql } from "apollo-boost";
import { GET_GROUPS_QUERY } from "views/Team/TeamQueries";


const EDIT_COLLAB = gql`
  mutation EDIT_COLLAB($projectId: ID!, $collaborationId: ID!, $roleId: ID!){
    editCollaboration(projectId:$projectId,collaborationId: $collaborationId, roleId: $roleId){
      id
    }
  }
`;


const editCollab = async ({params, client}) => {
  await client.mutate({
    mutation: EDIT_COLLAB,
    variables: { projectId: params.projectId, collaborationId: params.collaborationId, roleId: params.roleId },
    refetchQueries: () => {
     return [{
      query: GET_GROUPS_QUERY,
      variables: { id: params.projectId }
      },
    ]
    }
  })
}
 
export { editCollab };