import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { Query } from "react-apollo";
import withStyles from "@material-ui/core/styles/withStyles";
import logStyle from "./ProjectLogStyle";
import LogLine from "./LogLine";
import classNames from "classnames";
// import Loading from 'components/Loading/Loading';
import { LOGS_SUBSCRIPTION, LOGS_QUERY } from "./logQueries";
import Loading from "../../components/Loading/Loading";
import { Button } from "@material-ui/core";
class ProjectLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.unsubscribe = null;
  }
  /** [
        'project-invite',
        'project-removal',
        'user-change',
        'content-change',
        'content-addition',
        'content-comment',
        'juridical-change',
        'juridical-addition',
        'juridical-comment',
        'document-addition',
    ] */

  render() {
    const {
      match: { params },
      classes
    } = this.props;
    const dropdownItem = classNames(classes.dropdownItem);
    let logsToShow = 30;

    return (
      <Query
        query={LOGS_QUERY}
        variables={{ limit: logsToShow, projectId: params.projectId }}
      >
        {({ data, error, loading, subscribeToMore, fetchMore, refetch }) => {
          if (error) return <span>ERRO: {error.message}</span>;
          if (loading) return <Loading />;
          if (!this.unsubscribe) {
            this.unsubscribe = subscribeToMore({
              document: LOGS_SUBSCRIPTION,
              // Na maioria dos casos não é usado, ainda precisamos estudar mais.
              // É recomendado manter mesmo sendo aparentemente desnecessário
              updateQuery: (prev, { subscriptionData }) => {
                this.pushUnseenNotifiction();
                if (!subscriptionData.data) return prev;
                let { newLog } = subscriptionData.data;
                return {
                  logs: [
                    newLog,
                    ...prev.logs.filter(log => log.id !== newLog.id)
                  ]
                };
              }
            });
          }
          if (data.logs) {
            return (
              <>
                <div className={classes.titleWrapper}>
                  <span className={classes.title}>Histórico de Atividades</span>
                </div>
                <div className={classes.logListWrapper}>
                  <MenuList role="menu">
                    <div key={data.logs.length * 999}>
                      {data.logs.map((log, index) => {
                        return (
                          <MenuItem
                            onClick={this.handleClose}
                            className={dropdownItem}
                            classes={{ primaryHover: classes.primaryHover }}
                            key={index * 999 + log.type}
                          >
                            <LogLine
                              log={log}
                              logRefetch={params => refetch(params)}
                              refetchLimit={data.logs.length}
                            />
                          </MenuItem>
                        );
                      })}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around"
                        }}
                      >
                        <Button
                          size={"small"}
                          variant="outlined"
                          onClick={() => {
                            fetchMore({
                              variables: {
                                offset: data.logs.length,
                                limit: logsToShow,
                                projectId: params.projectId
                              },
                              updateQuery: (prev, { fetchMoreResult }) => {
                                if (!fetchMoreResult) return prev;
                                return Object.assign({}, prev, {
                                  logs: [...prev.logs, ...fetchMoreResult.logs]
                                });
                              }
                            });
                          }}
                        >
                          Mais
                        </Button>
                      </div>
                    </div>
                  </MenuList>
                </div>
              </>
            );
          } else return null;
        }}
      </Query>
    );
  }
}
export default withStyles(logStyle)(ProjectLog);
