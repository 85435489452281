import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Query, withApollo } from "react-apollo";
import contentDirectoryViewStyle from "../contentStyle";
import FoldersTable from "components/FoldersTable/FoldersTable.jsx";
import FoldersContentTable from "components/FoldersContentTable/FoldersContentTable.jsx";
import RenameBox from "components/RenameBox/RenameBox.jsx";
import AssociateFile from "components/AssociateFile/AssociateFile.jsx";
import "react-table/react-table.css";
import VerificationDialog from "components/VerificationDialog/VerificationDialog";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import markChecked from "utils/markCheckedRow";

import {
  GET_ROOT_FOLDERS_QUERY,
  GET_CONTENT_FROM_FOLDER_QUERY,
  downloadManyContents,
  moveManyContentsToJuridical,
  CONTENT_SUBSCRIPTION,
  editContent
} from "./ContentDirectoryQueries.js";
import { myUser } from "queries/queries.js";

import {
  onDrop,
  setSelectedFolder,
  selectRenameBoxPropsUsingRenameType,
  toggleAll,
  rename,
  downloadManyFiles,
  createFoldersContentData,
  editManyContents,
  openVerifyContentDialog
} from "../utils/utils";
import Loading from "components/Loading/Loading";
import { Modal } from "@material-ui/core";

class ContentDirectoryView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      moreVertOpen: -1,
      anchorMoreVert: null,
      selectedFolder: 0,
      selectedSubFolder: null,
      openFolders: [],
      RenameBoxOpen: false,
      parentFolderId: null,
      checkedRows: [],
      renameType: "", //types folderEdit, contentEdit, folderAdd
      currentEditingName: "", //renameBox state
      currentEditingId: "", //renameBox state
      modalOpen: false,
      isVerificationDialogOpen: false,
      verificationDialogCall: () => {},
      isDraggin: false,
      juridicalSuccess: false,
      juridicalError: false,
      expandedRows: [],
      selectedFolderId: "",
      isTagToggled: false,
      associateModalOpen: false,
      element: undefined,
      phase: undefined
    };
    this.unsubscribe = null;
  }

  cbJuridical = error => {
    console.log("error ", error);
    if (error) {
      this.setState({ juridicalError: true });
    } else {
      this.setState({ juridicalSuccess: true });
    }
  };

  setPhase = phase => this.setState({ phase });
  setElement = element => this.setState({ element });

  clearRows = () => this.setState({ checkedRows: [] });

  toggleRow = row => {
    const { expandedRows } = this.state;
    const _expandedRows = expandedRows;
    _expandedRows[row] = _expandedRows[row] ? false : true;
    this.setState({ expandedRows: _expandedRows });
  };

  toggleTag = () => {
    this.setState({ isTagToggled: !this.state.isTagToggled });
  };

  editContent = async ({ client, params }) => {
    await editContent({ client, params });
    this.setState({
      associateModalOpen: false,
      element: undefined,
      phase: undefined
    });
  };

  render() {
    const { classes } = this.props;
    const {
      selectedFolder,
      selectedFolderId,
      selectedSubFolder,
      RenameBoxOpen,
      checkedRows,
      currentEditingName,
      isVerificationDialogOpen,
      verificationDialogCall,
      juridicalSuccess,
      juridicalError,
      expandedRows,
      openFolders,
      isTagToggled,
      associateModalOpen,
      element,
      phase
    } = this.state;
    const {
      match: { params },
      client
    } = this.props;

    return (
      <div className={classes.root}>
        {RenameBoxOpen && (
          <RenameBox
            open={RenameBoxOpen}
            onClose={() => this.setState({ RenameBoxOpen: false })}
            onClick={rename.bind(this)}
            value={currentEditingName}
            {...selectRenameBoxPropsUsingRenameType.bind(this)()}
          />
        )}
        {isVerificationDialogOpen && (
          <VerificationDialog
            open={isVerificationDialogOpen}
            handleClose={() =>
              this.setState({ isVerificationDialogOpen: false })
            }
            onAccept={() => verificationDialogCall()}
          />
        )}
        <Query query={myUser}>
          {({ data, loading }) => {
            if (loading) {
              return null;
            }
            if (!data) {
              window.location.href = "/login";
            }
            let userPermissions = data.myUser.role.permissions;
            return (
              <Query
                query={GET_ROOT_FOLDERS_QUERY}
                variables={{ projectId: params.projectId }}
              >
                {({ loading: loadingFolder, error: errorFolder, data }) => {
                  if (loadingFolder) return <Loading />;
                  if (errorFolder) return null;
                  const rootFolders = data ? data.rootFolders : []; //get root folder
                  let folderId;
                  let urlFolder;
                  let urlSubFolder;
                  let newOpenFolders;

                  // If the id in the url does not match any of the folders currently displayed then selected folder id is cleansed
                  if (
                    rootFolders &&
                    !rootFolders.some(
                      folder =>
                        folder.id === selectedFolderId ||
                        folder.subFolders.some(
                          subFolder => subFolder.id === selectedFolderId
                        )
                    )
                  ) {
                    if (this.state.selectedFolderId)
                      this.setState({ selectedFolderId: "" });
                  }

                  //if there is no selected id searches the displayed folders for the ids in the url
                  if (!this.state.selectedFolderId && rootFolders.length > 0) {
                    urlFolder = rootFolders.find((rootFolder, index) => {
                      if (rootFolder.id === params.folderId) {
                        newOpenFolders = [...openFolders, index];
                        return true;
                      }
                      return false;
                    });

                    //if there is a folderid on the url tries to set the subfolder id, if there is no subfolder id it defaults to first folder
                    if (urlFolder) {
                      urlSubFolder = urlFolder.subFolders.find(
                        subFolder => subFolder.id === params.subFolderId
                      );
                      if (urlSubFolder) folderId = urlSubFolder.id;
                      else folderId = urlFolder.id;
                    } else folderId = rootFolders[0] ? rootFolders[0].id : "";

                    if (newOpenFolders)
                      this.setState({
                        selectedFolderId: folderId,
                        openFolders: newOpenFolders
                      });
                    else this.setState({ selectedFolderId: folderId });
                  } else {
                    // if there is a selected folder that is one of the displayed currently sets the contents being displayed to those inside the selected folder
                    folderId = selectedFolderId;
                  }
                  return (
                    <Query
                      query={GET_CONTENT_FROM_FOLDER_QUERY}
                      variables={{ projectId: params.projectId, folderId }}
                    >
                      {({
                        loading: loadingContent,
                        error: errorContent,
                        data,
                        subscribeToMore
                      }) => {
                        let tableData = [];
                        if (errorContent)
                          return <h2> Error {errorContent} </h2>;
                        if (!loadingContent)
                          tableData = createFoldersContentData.call(this, {
                            toggleTag: isTagToggled,
                            dataContent: data ? data.contents : [],
                            folderId,
                            projectId: params.projectId,
                            cbJuridical: this.cbJuridical,
                            userPermissions
                          }); //create content from selected folder

                        if (!this.unsubscribe) {
                          this.unsubscribe = subscribeToMore({
                            document: CONTENT_SUBSCRIPTION,
                            variables: { folderId: folderId },
                            updateQuery: (prev, { subscriptionData }) => {
                              if (!subscriptionData.data) return prev;
                              const { contentChange } = subscriptionData.data;
                              return {
                                ...prev,
                                juridicalContents: contentChange
                              };
                            }
                          });
                        }

                        return (
                          <>
                            <Snackbar
                              open={juridicalSuccess || juridicalError}
                              place="tc"
                              close={true}
                              color={juridicalError ? "error" : "success"}
                              message={
                                juridicalError
                                  ? "Ocorreu um erro ao enviar para jurídico."
                                  : "Sucesso ao enviar item para jurídico."
                              }
                              closeNotification={() =>
                                this.setState({
                                  juridicalError: false,
                                  juridicalSuccess: false
                                })
                              }
                            />
                            {associateModalOpen && (
                              <Modal
                                open={associateModalOpen}
                                onBackdropClick={() =>
                                  this.setState({ associateModalOpen: false })
                                }
                              >
                                <AssociateFile
                                  standalone
                                  save={() =>
                                    this.editContent({
                                      client,
                                      params: {
                                        id: associateModalOpen.id,
                                        elementId: element.id,
                                        phaseId: phase.id,
                                        projectId: params.projectId
                                      }
                                    })
                                  }
                                  projectId={params.projectId}
                                  contentId={associateModalOpen.id}
                                  uploads={[]}
                                  setElement={this.setElement}
                                  setPhase={this.setPhase}
                                  phase={phase || associateModalOpen.phase}
                                  element={
                                    element || associateModalOpen.element
                                  }
                                />
                              </Modal>
                            )}
                            <FoldersTable
                              openFolders={openFolders}
                              selectedFolder={selectedFolder}
                              selectedFolderId={selectedFolderId}
                              selectedSubFolder={selectedSubFolder}
                              onDrop={onDrop.bind(this)}
                              checkedRows={checkedRows}
                              rootFolders={rootFolders ? rootFolders : []}
                              deleteFolder={folderId =>
                                openVerifyContentDialog.bind(this)(
                                  "deleteFolder",
                                  {
                                    params: {
                                      folderId,
                                      projectId: params.projectId
                                    },
                                    client
                                  }
                                )
                              }
                              addFolder={parentFolderId =>
                                this.setState({
                                  RenameBoxOpen: true,
                                  parentFolderId,
                                  renameType: "addFolder",
                                  currentEditingName: ""
                                })
                              }
                              editFolder={(folderId, folderName) =>
                                this.setState({
                                  RenameBoxOpen: true,
                                  currentEditingId: folderId,
                                  renameType: "editFolder",
                                  currentEditingName: folderName
                                })
                              }
                              setExpand={setSelectedFolder.bind(this)}
                            />
                            <FoldersContentTable
                              params={this.props.match.params}
                              data={loadingContent ? [] : tableData}
                              userPermissions={userPermissions}
                              isEmpty={
                                rootFolders ? rootFolders.length > 0 : false
                              }
                              moveManyContentsToJuridical={
                                moveManyContentsToJuridical
                              }
                              loading={loadingContent}
                              toggleRow={this.toggleRow}
                              markChecked={markChecked.bind(this)}
                              checkedRows={checkedRows}
                              toggleTag={this.toggleTag}
                              isTagToggled={isTagToggled}
                              clearRows={this.clearRows.bind(this)}
                              expandedRows={expandedRows}
                              editManyContents={obj => {
                                this.clearRows();
                                editManyContents.call(this, obj);
                              }}
                              cbJuridical={this.cbJuridical.bind(this)}
                              downloadManyContents={() => {
                                this.clearRows();
                                downloadManyContents({
                                  client,
                                  contentsId: checkedRows,
                                  cb: downloadManyFiles.bind(this)
                                });
                              }}
                              addFolder={parentFolderId =>
                                this.setState({
                                  RenameBoxOpen: true,
                                  parentFolderId,
                                  renameType: "addFolder",
                                  currentEditingName: ""
                                })
                              }
                              selectedFolderId={folderId}
                              deleteManyContents={folderId =>
                                openVerifyContentDialog.bind(this)(
                                  "deleteMany",
                                  {
                                    ids: checkedRows,
                                    client,
                                    params: {
                                      folderId,
                                      projectId: params.projectId
                                    }
                                  }
                                )
                              }
                              toggleAllChecked={toggleAll.bind(this)}
                            />
                          </>
                        );
                      }}
                    </Query>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default withApollo(
  withStyles(contentDirectoryViewStyle)(ContentDirectoryView)
);
