export default {
  colorPicker:{
    height: '19px',
    width: '19px',
    borderRadius: '50%',
    backgroundColor: 'gray',
    marginRight: '2px',
    marginLeft: '7px',
    marginTop:"7px",
    cursor: 'pointer'
  },
  tagList:{
    marginTop: "5px",
    paddingRight: "7px",
    marginRight: "-5px"
  },
  popperRootPaper:{
    width:"20vw",
    padding:"8px 6px 8px 6px" 
  },
}