import { gql } from "apollo-boost";


const GET_DOCUMENTS_FROM_PROJECT = gql`
  query GET_DOCUMENTS_FROM_PROJECT($projectId: ID!){
    project(id: $projectId) {
      id
      documentTags {
        id
        name
        color
      } 
      documents {
        id
        fileKey
        status
        name
        type
        creationDate
        downloadLink
        tags {
          id
          name
          color
        }
        uploader {
          id
          name
          image {
            id
            imageUrl
          }
        }
      }
    }
  }
`;


const ADD_DOCUMENT_MUTATION = gql`
  mutation ADD_DOCUMENT_MUTATION($projectId: ID!, $fileKeys: [String]!, $fileNames: [String]!, $fileTypes: [String]!, $tagIds: [ID!]){
    addDocumentsToProject(projectId: $projectId, fileKeys: $fileKeys, fileNames: $fileNames, fileTypes: $fileTypes, tagIds:$tagIds) {
        id
        fileKey
        status
        name
        type
        tags{
          id
          name
          color
        }
        creationDate
        uploader {
            id
            name
            image {
              id
              imageUrl
            }
        }
    }
  }
`;

export {
  GET_DOCUMENTS_FROM_PROJECT,
  ADD_DOCUMENT_MUTATION
};