import React from "react";
import { Query, withApollo } from "react-apollo";
import { CSVLink } from "react-csv";
import TextareaResize from "react-autosize-textarea";
import moment from "moment";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Media from "components/Media/Media.jsx";
import Header from "components/Header/Header";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import UploadAttachments from "components/Upload/UploadAttachment";
import Attachments from "components/Attachments/Attachments";
import Loading from "components/Loading/Loading";

// @material-ui/icons components
import Paperclip from "@material-ui/icons/AttachFile";
import Edit from "@material-ui/icons/Edit";
import Brush from "@material-ui/icons/Brush";
import LocalOffer from "@material-ui/icons/LocalOfferOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Circle from "@material-ui/icons/FiberManualRecordOutlined";
import FilterNone from "@material-ui/icons/FilterNone";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";

import { commentsToCSV, headerByFileType } from "./utils/formatComments";
import {
  applyFilter,
  compareTimeCode,
  compareCreationDateAscending,
  compareCreationDateDescending
} from "./utils/sort&filter.js";
import timeCodeConvert from "./utils/timeCodes";

import {
  DELETE_COMMENT,
  EDIT_COMMENT,
  GET_ALL_COMMENTS_BY_VERSIONS,
  ADD_REPLY_MUTATION,
  MY_USER_QUERY
} from "./CommentQueries.js";

import sectionCommentsStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionCommentsStyle.jsx";
import "views/Comments/SectionComments.css";

class SectionComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentIndexClicked: -1,
      replyCommentClicked: -1,
      moreOpen: false,
      anchorEl: undefined,
      currentVersionId: this.props.versionId,
      versionFilterOn: false,
      versionName: "",
      showAll: false,
      sortedComments: false,
      comments: [],
      attachIsOpen: false,
      fileNames: [],
      fileKeys: [],
      userId: ""
    };
    this.onClick = this.onClick.bind(this);
    this.editAreaRef = null;
  }

  componentDidMount() {
    if (!this.state.currentVersionId)
      this.setState({ currentVersionId: this.props.versionId });
    if (this.state.refetch)
      this.state.refetch({ versionId: this.state.versionId });
    if (this.state.userId === "") {
      this.props.client
        .query({
          query: MY_USER_QUERY
        })
        .then(res =>
          this.setState({
            userId: res.data.myUser.id
          })
        );
    }
  }

  onClick(item) {
    this.setState({ versionFilterOn: true });
    if (item.version === null) {
      this.setState({ versionName: "", showAll: true });
    } else {
      this.setState({
        versionName: item.version.name,
        currentVersionId: item.version.id,
        showAll: false
      });
    }
  }

  async handleCheck(id, checked, event, refetch) {
    if (!event.isPropagationStopped()) {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
    }
    await this.props.client.mutate({
      mutation: EDIT_COMMENT,
      variables: {
        id,
        checked: !checked
      }
    });
    refetch({ versionId: this.state.currentVersionId });
  }

  async handleReply(target, parentId) {
    let text = target.value;
    const versionId = this.props.versionId;
    await this.props.client.mutate({
      mutation: ADD_REPLY_MUTATION,
      variables: {
        text,
        parentId
      },
      refetchQueries: () => [
        { query: GET_ALL_COMMENTS_BY_VERSIONS, variables: { versionId } }
      ]
    });
    this.setState({ replyCommentClicked: "" });
    target.value = "";
  }

  setAnchorEl = value => this.setState({ anchorEl: value });
  setMoreOpen = value => this.setState({ moreOpen: value });
  setCommentIndexClicked = value =>
    this.setState({ commentIndexClicked: value });
  setReplyCommentClicked = value =>
    this.setState({ replyCommentClicked: value });
  setEditCommentClicked = value => this.setState({ editCommentClicked: value });
  setFilter = ({ name, value }) => {
    if (name === null) {
      this.setState({ filter: null });
      return;
    }
    if (this.state.filter && value === this.state.filter.value)
      this.setState({ filter: null });
    else this.setState({ filter: { value, name } });
  };

  handleEdit = (newText, id, attachmentsIds) => {
    const versionId = this.state.currentVersionId;
    this.props.client.mutate({
      mutation: EDIT_COMMENT,
      variables: {
        id,
        text: newText,
        documentsIds: attachmentsIds
      },
      refetchQueries: () => [
        { query: GET_ALL_COMMENTS_BY_VERSIONS, variables: { versionId } }
      ]
    });
    this.setState({ editCommentClicked: "" });
  };

  timeCodeRedirect = (timestamp, commentIndex) => {
    this.setCommentIndexClicked(commentIndex);
    this.props.setPlaybackTime(timestamp);
  };

  async deleteCommentMethod(id) {
    await this.props.client.mutate({
      mutation: DELETE_COMMENT,
      variables: {
        id
      },
      refetchQueries: () => [
        {
          query: GET_ALL_COMMENTS_BY_VERSIONS,
          variables: { versionId: this.state.currentVersionId }
        }
      ]
    });
  }

  componentDidUpdate() {
    if (
      !this.state.versionFilterOn &&
      this.state.currentVersionId !== this.props.versionId
    ) {
      this.setState({ showAll: false });
      this.setState({ currentVersionId: this.props.versionId });
    }
  }
  toggleAttachUploadModal(id) {
    const { toggleCommentsAttachmentOpen } = this.props;
    toggleCommentsAttachmentOpen(id ? id : false);
    this.setState({ attachIsOpen: id ? id : false });
  }
  handleClickComment(comment) {
    const { setCommentCanvas } = this.props;

    let canvasDimensions = comment.canvasHeight
      ? { height: comment.canvasHeight, width: comment.canvasWidth }
      : {};
    if (comment.timeStamp) {
      if (comment.canvasDraw) {
        setCommentCanvas(comment.canvasDraw, canvasDimensions);
      }
      this.timeCodeRedirect(comment.timeStamp, comment.id);
    } else {
      this.setCommentIndexClicked(comment.id);
      if (comment.canvasDraw) {
        setCommentCanvas(comment.canvasDraw, canvasDimensions);
      }
    }
  }

  listComments = (comments, allowChild, refetch, activeBrush, reverse) => {
    //todo: separate this into a second file
    if (!this.state.sortedComments)
      comments = comments.sort(compareCreationDateAscending);
    else comments = comments.sort(this.state.sortingFunction);
    const { classes, content, contentStatus, projectId } = this.props;
    const {
      commentIndexClicked,
      replyCommentClicked,
      editCommentClicked,
      filter
    } = this.state;
    let versionId = this.state.currentVersionId;
    let maxAttachments = 3;
    if (allowChild) maxAttachments = 2;
    comments = !reverse ? comments : comments.reverse();
    return comments.map((comment, i) => {
      const isCommentSelected = comment.id === commentIndexClicked;
      const isRepplyActive = comment.id === replyCommentClicked;
      const editActive = comment.id === editCommentClicked;
      const creatorIdcheck = comment.creator && (comment.creator.id === this.state.userId);
      
      if (
        (!comment.parent || allowChild) &&
        (!filter ||
          applyFilter(comment[filter.name], filter.value, this.state.filter))
      )
        return (
          //allowChild prevents the comment from being rendered in outside map, only inside innermedia
          <div key={i}>
            <Media
              onClick={() => this.handleClickComment(comment)}
              classes={{
                mediaAvatar: classes.mediaAvatarSmall,
                mediaHeading: classes.mediaHeadingSmall,
                mediaFooter: classes.footerSlick,
                mediaBody: classes.mediaBody,
                media:
                  "media" +
                  (isCommentSelected ? " borderLeftComment" : " noBorderLeft"),
                mediaLink: classes.mediaLinkAvatar
              }}
              style={{
                borderBottom: !allowChild ? "1px solid #0e1118" : "none"
              }} //needs to be passed into a css class??
              avatar={
                (comment.creator && comment.creator.image)
                  ? comment.creator.image.imageUrl
                  : "http://www.accdpa.org/wp-content/uploads/2015/09/Placeholder-42x42.jpg"
              } //{comment.creator.avatar}
              key={(comment.creator ? comment.creator.id :"") + comment.text}
              width="100%"
              title={
                <div>
                  <div className={classes.headerContainer}>
                    <div>
                      <span className={classes.commentName}>
                        {" "}
                        {comment.creator ? comment.creator.name : ""}{" "}
                      </span>
                      <span className={classes.timePosted}>
                        <b style={{ color: "#8861FF" }}>
                          {this.state.versionFilterOn &&
                            "V." + comment.version.count + " "}
                        </b>
                        {moment(comment.creationDate).format("DD/MM/YY")}
                      </span>
                    </div>
                    <div>
                      <div
                        className={
                          classes.circleCheckWrapper + " circleCheckWrapper"
                        }
                        onClick={e =>
                          this.handleCheck(
                            comment.id,
                            comment.checked,
                            e,
                            refetch
                          )
                        }
                      >
                        {!comment.checked ? (
                          <div className={classes.Circle}>
                            {" "}
                            <Circle />{" "}
                          </div>
                        ) : (
                          <div
                            className={classes.Circle}
                            style={{ marginTop: "2px" }}
                          >
                            {" "}
                            {/*needs to be passed into a css class*/}
                            <CheckCircleOutline />
                          </div>
                        )}
                        <div
                          className={classes.CheckCircleOutline}
                          style={{ marginTop: "2px" }}
                        >
                          {" "}
                          {/*needs to be passed into a css class*/}
                          <CheckCircleOutline />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.subInfo}>
                    <div
                      style={{
                        fontSize: "1.4em",
                        marginTop: "2px",
                        fontWeight: "700",
                        display: "flex"
                      }}
                    >
                      {comment.timeStamp !== "" && comment.timeStamp && (
                        <div className="timeStamp">
                          {timeCodeConvert(comment.timeStamp)}
                        </div>
                      )}
                      {activeBrush &&
                        (comment.canvasDraw !==
                          "N4IgNglgdgpgziAXAbQLoBoQHcIBMAuAFkgAyaEwQDmh+pAvkA==" &&
                          comment.canvasDraw !==
                            `"{\\"lines\\":[],\\"width\\":0,\\"height\\":0}"`) && (
                          <div className="canvas">
                            <Brush />
                          </div>
                        )}
                    </div>
                    {comment.tags &&
                      comment.tags.length > 0 &&
                      comment.tags.map((commentTag, index) => (
                        <div
                          key={index}
                          className="tag"
                          onClick={() => {
                            this.setFilter({ name: "tags", value: commentTag });
                          }}
                          style={{
                            marginRight: "5px",
                            cursor: "pointer",
                            whiteSpace: "nowrap"
                          }} /*needs to be passed into a css class*/
                        >
                          {commentTag}
                        </div>
                      ))}
                  </div>
                </div>
              }
              body={
                <div className={classes.bodyComment}>
                  <p
                    style={{
                      fontWeight: "400",
                      fontSize: "15px",
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word"
                    }}
                  >
                    {/*needs to be passed into a css class*/}
                    {editActive && (
                      <div className={classes.replySection}>
                        <TextareaResize
                          ref={ref => this.editAreaRef = ref}
                          id="editTextArea"
                          onBlur={() =>
                            this.setState({ editCommentClicked: "" })
                          }
                          className={
                            classes.replySectionInput +
                            " displayTextarea " +
                            classes.editComment
                          }
                          onKeyUp={async e => {
                            if (e.keyCode === 13 && !e.shiftKey)
                              await this.handleEdit(
                                e.target.value,
                                comment.id,
                                versionId
                              );
                          }}
                        >
                          {comment.text}
                        </TextareaResize>
                      </div>
                    )}
                    {!editActive && comment.text}
                  </p>
                </div>
              }
              footer={
                <div className={classes.footerWrapper}>
                  {contentStatus !== "Versão Final" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      {/*needs to be passed into a css class*/}
                      <div className="replyComment" rows="5">
                        <span
                          className={classes.replyButton}
                          onClick={async () => {
                            await this.setReplyCommentClicked(comment.id);
                            const area = await document.getElementById("replyTextArea")
                            if(area) area.focus();
                          }}
                        >
                          Responder
                        </span>
                        {creatorIdcheck && (
                          <Paperclip
                            classes={{ root: "attach" }}
                            onClick={() => {
                              if (!this.state.attachIsOpen)
                                this.toggleAttachUploadModal(comment.id);
                            }}
                          />
                        )}
                        <UploadAttachments
                          classes={classes}
                          commentId={comment.id}
                          versionId={versionId}
                          contentId={content.id}
                          projectId={this.props.projectId}
                          open={this.state.attachIsOpen === comment.id}
                          toggleModal={this.toggleAttachUploadModal.bind(this)}
                          editComment={this.handleEdit.bind(this)}
                        />
                      </div>
                      <span className={classes.delete + " delete"}>
                        {(comment.attachments.length > maxAttachments ||
                          comment.attachments.length === 0) &&
                          creatorIdcheck && (
                            <span>
                              <Edit
                                onClick={async (e) => {
                                  console.log(e)
                                  await this.setEditCommentClicked(comment.id);
                                  if(this.editAreaRef) this.editAreaRef.focus();
                                }}
                              />
                            </span>
                          )}
                        {(comment.attachments.length > maxAttachments ||
                          comment.attachments.length === 0) &&
                          creatorIdcheck && (
                            <span>
                              <DeleteOutline
                                onClick={() => {
                                  this.deleteCommentMethod(comment.id);
                                }}
                              />
                            </span>
                          )}
                      </span>
                    </div>
                  )}
                  <div className={classes.replySection}>
                    <TextareaResize
                      id="replyTextArea"
                      placeholder="reply"
                      onBlur={() => this.setState({ replyCommentClicked: "" })}
                      className={
                        classes.replySectionInput +
                        " " +
                        (isRepplyActive ? "displayTextarea" : "")
                      }
                      onKeyUp={async e => {
                        if (e.keyCode === 13 && !e.shiftKey) {
                          await this.handleReply(
                            e.target,
                            comment.parent ? comment.parent.id : comment.id
                          );
                        }
                      }}
                    />
                  </div>
                  <div className={classes.attachmentCrudWrapper}>
                    <Attachments
                      versionId={versionId}
                      projectId={projectId}
                      attachments={comment.attachments}
                      maxAttachments={maxAttachments}
                      commentRefetch={() => {
                        refetch();
                      }}
                    />
                    <span className={classes.delete + " delete"}>
                      {comment.attachments.length <= maxAttachments &&
                        comment.attachments.length !== 0 &&
                        creatorIdcheck && (
                          <span
                            onClick={async () => {
                              await this.setEditCommentClicked(comment.id);
                              document.getElementById("editTextArea").focus();
                            }}
                          >
                            <Edit />
                          </span>
                        )}
                      {comment.attachments.length <= maxAttachments &&
                        comment.attachments.length !== 0 &&
                        creatorIdcheck && (
                          <span
                            onClick={() => {
                              this.deleteCommentMethod(comment.id);
                            }}
                          >
                            <DeleteOutline />
                          </span>
                        )}
                    </span>
                  </div>
                </div>
              }
              innerMedias={
                comment.subComments && comment.subComments.length > 0
                  ? this.listComments(
                      comment.subComments,
                      true, //allowChild = true, allow rendering of children,
                      null,
                      false,
                      true
                    )
                  : []
              }
            />
          </div>
        );
      return null;
    });
  };

  render() {
    const { classes, juridical, tags, content } = this.props;
    const { anchorEl, moreOpen } = this.state;
    let versionId = this.state.currentVersionId;

    return (
      <div style={{ height: "100%" }}>
        <Query
          query={GET_ALL_COMMENTS_BY_VERSIONS}
          variables={{ versionId }}
          pollInterval={30000}
        >
          {({ loading, error, data, refetch }) => {
            if (!this.state.refetch) {
              // this sets the refetch function in the local state and in the parent component so that
              // we can refetch outside sectionComments.
              // it should be updated to set it to the global store.
              this.setState({ refetch });
              this.props.setCommentRefetch(refetch);
            }

            if (loading || !data || !data.version) {
              return (
                <div className="loadingWrapper">
                  {" "}
                  <Loading />{" "}
                </div>
              );
            }

            if (error) return `${error}`;
            let versions = data.version.content.versions;
            let comments = [];

            if (juridical) {
              if (this.state.showAll) {
                versions.forEach(
                  version =>
                    (comments = [...comments, ...version.juridicalComments])
                );
                if (this.state.comments.length !== comments.length)
                  this.setState({ comments });
              } else {
                comments = versions.filter(
                  version => version.id === versionId
                )[0].juridicalComments;
                if (this.state.comments !== comments)
                  this.setState({ comments });
              }
            } else {
              if (this.state.showAll) {
                versions.forEach(
                  version => (comments = [...comments, ...version.comments])
                );
                if (this.state.comments.length !== comments.length)
                  this.setState({ comments });
              } else {
                comments = versions.filter(
                  version => version.id === versionId
                )[0].comments;
                if (this.state.comments !== comments)
                  this.setState({ comments });
              }
            }

            return (
              <div
                className={classes.section}
                style={{
                  border: "0px",
                  padding: "0 !important",
                  top: "0",
                  height: "100%"
                }}
              >
                {/*needs to be passed into a css class*/}
                <GridContainer
                  justify="center"
                  style={{ padding: "0 !important", height: "100%" }}
                >
                  {/*needs to be passed into a css class*/}
                  <GridItem
                    classes={{ grid: classes.gridComments }}
                    style={{ height: "100%", padding: "0 !important" }}
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    {/*needs to be passed into a css class*/}
                    <Header
                      customStyle={{
                        marginBottom: "0px",
                        boxShadow: "none",
                        backgroundColor: "#15161c",
                        height: "50px"
                      }}
                      noLiPadding={true}
                      classes={{ container: classes.headerApp }}
                      links={
                        <List
                          className="navList removePadding"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between"
                          }}
                        >
                          {/*needs to be passed into a css class*/}
                          <ListItem
                            className="navItem"
                            style={{
                              width: "40%",
                              justifyContent: "flex-start",
                              paddingLeft: "2px",
                              paddingTop: "23px"
                            }}
                          >
                            {/*needs to be passed into a css class*/}
                            <div className="commentCounter">
                              {" "}
                              {comments.length} comentários{" "}
                            </div>
                          </ListItem>
                          <ListItem
                            className="navItem"
                            style={{
                              width: "33%",
                              justifyContent: "flex-end",
                              paddingRight: 0
                            }}
                          >
                            {/*needs to be passed into a css class*/}
                            <div style={{ display: "flex" }}>
                              <IconButton
                                aria-label="More"
                                aria-haspopup="true"
                                classes={{ root: classes.iconButton }}
                                onClick={e => {
                                  if (e) this.setAnchorEl(e.currentTarget);
                                  this.setMoreOpen(true);
                                }}
                              >
                                <MoreVertIcon
                                  classes={{ root: classes.moreIcon }}
                                />
                                <div className={classes.moreContainerComment} />
                              </IconButton>
                              <Menu
                                id="long-menu"
                                open={moreOpen}
                                onClose={() => this.setMoreOpen(false)}
                                anchorEl={anchorEl}
                                PaperProps={{
                                  style: {
                                    maxHeight: 45 * 4.5,
                                    width: 200,
                                    marginTop: 30
                                  }
                                }}
                              >
                                <MenuItem>
                                  <div className={classes.optionText}>
                                    <CSVLink
                                      className={classes.csvLink}
                                      key="CSV"
                                      headers={headerByFileType(content.type)}
                                      data={commentsToCSV({
                                        comments: this.state.comments,
                                        content
                                      })}
                                      filename={`${content.project.name} - ${
                                        content.name
                                      } - comments.csv`}
                                    >
                                      Exportar Comentários em CSV
                                    </CSVLink>
                                  </div>
                                </MenuItem>
                              </Menu>
                              <div className="close" />
                            </div>
                          </ListItem>
                        </List>
                      }
                    />
                    <div id="commentWrapper" className={classes.commentWrapper}>
                      <div className={classes.filterWrapper}>
                        <List
                          className="navList removePadding"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            paddingLeft: "5px"
                          }}
                        >
                          {/*needs to be passed into a css class*/}
                          <ListItem
                            className="navList "
                            classes={{
                              root: "listSmallPadding sortDropdownWrapper"
                            }}
                          >
                            {this.state.comments && (
                              <CustomDropdown
                                onClick={e => {
                                  this.setState({
                                    comments: comments.sort(e.sortFunc),
                                    sortedComments: true,
                                    sortingFunction: e.sortFunc
                                  });
                                }}
                                buttonText="Ordenar"
                                classes={{
                                  button:
                                    "buttonDropDown " + classes.sortDropdown
                                }}
                                dropdownList={[
                                  {
                                    name: "Timecode",
                                    obj: true,
                                    sortFunc: compareTimeCode
                                  }, //make this into a function
                                  {
                                    name: "Mais Antigo",
                                    obj: true,
                                    sortFunc: compareCreationDateDescending
                                  },
                                  {
                                    name: "Mais Recente",
                                    obj: true,
                                    sortFunc: compareCreationDateAscending
                                  },
                                  { divider: true },
                                  "Usuário"
                                ]}
                              />
                            )}
                          </ListItem>
                          <ListItem
                            className="navItem"
                            style={{
                              justifyContent: "flex-end",
                              marginRight: 5
                            }} /*needs to be passed into a css class*/
                            classes={{ root: "sectionCommentsMenu" }}
                          >
                            <div
                              style={{
                                //needs to be passed into a css class
                                display: "flex",
                                flex: 1,
                                justifyContent: "space-between",
                                marginRight: "40px",
                                maxWidth: "170px"
                              }}
                            >
                              <div
                                className="sectionCommentsMenuFilter"
                                onClick={() => {
                                  this.setState({
                                    versionFilterOn: false,
                                    versionName: "",
                                    showAll: false
                                  });
                                  this.setFilter({ name: null });
                                }}
                                style={{
                                  cursor:
                                    this.state.filter ||
                                    this.state.versionFilterOn
                                      ? "pointer"
                                      : "default",
                                  position: "relative"
                                }} //needs to be passed into a css class
                              >
                                {this.state.filter ||
                                this.state.versionFilterOn ? (
                                  <span style={{ marginLeft: "-22px" }}>
                                    Remover
                                  </span>
                                ) : (
                                  <span>Filtrar</span>
                                )}
                              </div>
                              <Tooltip
                                id="tooltip-top"
                                key={"version filter tooltip"}
                                title={"Filtrar por versão"}
                                enterDelay={1000}
                                placement="top"
                                classes={{
                                  tooltip:
                                    classes.tooltip +
                                    " " +
                                    classes.customTooltip
                                }}
                              >
                                <div className="sectionCommentsMenuIcon">
                                  <CustomDropdown
                                    buttonIcon={FilterNone}
                                    buttonProps={{ size: "sm" }}
                                    classes={{
                                      button: "buttonDropDown buttonIcon ",
                                      manager: "buttonIcon",
                                      pooperResponsive: classes.dropDownSort,
                                      buttonIcon: "buttonDropDownIcon",
                                      caret: "noCaret"
                                    }}
                                    dropdownList={[
                                      {
                                        obj: true,
                                        name: "Todas",
                                        version: null
                                      },
                                      ...versions.map(version => {
                                        return {
                                          obj: true,
                                          name: "Versão " + version.count,
                                          version
                                        };
                                      })
                                    ]}
                                    onClick={this.onClick}
                                  />
                                </div>
                              </Tooltip>
                              <Tooltip
                                id="tooltip-top"
                                key={"tags filter tooltip"}
                                title={"Filtrar por tag"}
                                enterDelay={1000}
                                placement="top"
                                classes={{
                                  tooltip:
                                    classes.tooltip +
                                    " " +
                                    classes.customTooltip
                                }}
                              >
                                <div className="sectionCommentsMenuIcon">
                                  <CustomDropdown
                                    buttonIcon={LocalOffer}
                                    onClick={e =>
                                      this.setFilter({
                                        name: "tags",
                                        value: e.props.children
                                      })
                                    }
                                    classes={{
                                      button: "buttonDropDown buttonIcon ",
                                      manager: "buttonIcon",
                                      pooperResponsive: classes.dropDownSort,
                                      buttonIcon: "buttonDropDownIcon",
                                      caret: "noCaret"
                                    }}
                                    dropdownList={tags}
                                  />
                                </div>
                              </Tooltip>
                              <Tooltip
                                id="tooltip-top"
                                key={"approval filter tooltip"}
                                title={"Filtrar por aprovação"}
                                enterDelay={1000}
                                placement="top"
                                classes={{
                                  tooltip:
                                    classes.tooltip +
                                    " " +
                                    classes.customTooltip
                                }}
                              >
                                <div className="sectionCommentsMenuIcon">
                                  <CustomDropdown
                                    onClick={e => e.filterFunc()}
                                    buttonIcon={CheckCircleOutline}
                                    classes={{
                                      button: "buttonDropDown buttonIcon ",
                                      manager: "buttonIcon",
                                      pooperResponsive: classes.dropDownSort,
                                      buttonIcon: "buttonDropDownIcon",
                                      caret: "noCaret"
                                    }}
                                    dropdownList={[
                                      {
                                        name: "Todos",
                                        value: true,
                                        obj: true,
                                        filterFunc: () =>
                                          this.setFilter({ name: null })
                                      },
                                      {
                                        name: "Aprovados",
                                        value: true,
                                        obj: true,
                                        filterFunc: () =>
                                          this.setFilter({
                                            name: "checked",
                                            value: true
                                          })
                                      },
                                      {
                                        name: "Não aprovados",
                                        value: true,
                                        obj: true,
                                        filterFunc: () =>
                                          this.setFilter({
                                            name: "checked",
                                            value: false
                                          })
                                      } // para juridico usar { divider:true }
                                    ]}
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </ListItem>
                        </List>
                      </div>
                      {this.state.comments &&
                        this.listComments(
                          this.state.comments,
                          false,
                          refetch,
                          true
                        ) // false = dont render children. Only parentless comments
                      }
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

let SectionCommentsWithStyles = withStyles(sectionCommentsStyle)(
  SectionComments
);

export default withApollo(SectionCommentsWithStyles);
