export default {
    tablePlanning: {

    },
    tableWrapper: {

    },
    title: {

    },
    step: {
        color: "#3f2c78",
        fontWeight: 500,
        whiteSpace: "nowrap",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        OTextOverflow:"ellipsis",
        MozBinding:"url('bindings.xml#ellipsis')",//issue for Firefox
    },
    linearProgress: {
        paddingTop: 15,
        paddingLeft: 10,
        height: '100%',
        borderLeft: '1px solid #b2b2b2',
        paddingRight: 10,
    },
    avatar: {
        marginTop: 0, 
        paddingTop: 5,
        paddingLeft: 45,
        height: '100%',
        borderLeft: '1px solid #b2b2b2'
    },
    old: {
        '& $tablePlanning': {
            backgroundColor: '#F9F9F9',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
            borderRadius: '8px',
            padding: '10px',
            width: '100%',
            margin: '10px 0',
            '& .ReactTable .rt-tbody .rt-tr-group .rt-tr': {
                borderBottom: 'solid 1px rgba(210, 210, 210, 0.7)',
                border: 'none'
            },
            '& .ReactTable .rt-tbody .rt-tr-group': {
                borderBottom: 'none',
                border: 'none'
            },
            '& .ReactTable .rt-thead .rt-th:last-child': {
                textAlign: 'left',
            }
            },
        '& $tableWrapper': {
            backgroundColor: '#F9F9F9',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
            borderRadius: '8px',
            padding: '10px',
            paddingTop: 0,
            margin: '10px 0',
            maxWidth: '100%',
            maxHeight: 300,
            overflow: 'auto',
            border: 'none',
            '& .ReactTable': {
                border: 'none',
                width: 'fit-content',
                overflow: 'hidden'
            },
            '& .ReactTable .rt-tbody': {
                overflow: 'hidden'
            },
            '& .ReactTable .rt-tbody .rt-td': {
                border: 'none',
                textOverflow: 'initial',
                whiteSpace: 'pre-wrap',
            },
            '& .ReactTable .rt-thead.-header': {
                boxShadow: 'none'
            },
            '& .ReactTable .rt-tbody .rt-tr-group .rt-tr': {
                border: 'none',
                borderTop: 'solid 1px rgba(210, 210, 210, 0.7)',
            },
            '& .rt-th': {
                fontWeight: 'bold !important',
                color: '#3C4858',
            },
            '& .ReactTable .rt-thead .rt-th input, .ReactTable .rt-thead .rt-th input::placeholder, .ReactTable .rt-thead .rt-td input::placeholder': {
                color: '#3C4858',
                fontWeight: 'normal',
                opacity: 1,
                fontSize: '18px',
            },
            '& .ReactTable .rt-tbody .rt-tr-group': {
                borderTop: 'none',
                border: 'none !important',
            },
            '& .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td': {
                border: 'none'
            }
        },
    },
    new: {
        '& $tablePlanning': {
            padding: '10px',
            width: '100%',
            paddingTop: '0 !important',
            marginTop: '0 !important',
            height: '100%',
            margin: '10px 0',
            '& .rt-th, .rt-td': {
                margin: '0 !important',
                padding: '0',
            },
            '& .rt-th': {
                textAlign: 'center !important',
                borderBottom: '1px solid #b2b2b2 !important',
                height: '50px'
            },
            '& .ReactTable .rt-tbody .rt-tr-group .rt-tr': {
                borderBottom: 'solid 1px rgba(210, 210, 210, 0.7)',
                border: 'none'
            },
            '& .ReactTable .rt-tbody .rt-tr-group': {
                borderBottom: 'none',
                border: 'none'
            },
            '& .ReactTable .rt-thead .rt-th:last-child': {
                textAlign: 'left',
            }
        },
        '& $title': {
            color: '#3f2c78',
        },
        '& $step': {
            fontWeight: 500,
            paddingTop: 12,
        },
        '& $tableWrapper': {
            backgroundColor: 'white',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
            borderRadius: '8px',
            padding: '10px',
            margin: '10px 0',
            paddingLeft: 10,
            width: 'auto',
            border: 'none',
            paddingTop: '20px !important',
            display: 'flex',
            justifyContent: 'flex-start',
            '& .ReactTable': {
                border: 'none',
                width: 'auto',
                overflow: 'auto'
            },
            '& .ReactTable .rt-tbody': {
                minHeight: '50px',
                borderRight: '2px solid #0d177e63',
                marginTop: -2,
                overflow: 'hidden',
            },
            '& .ReactTable .rt-tbody .rt-td': {
                border: 'none',
                textOverflow: 'initial',
                whiteSpace: 'pre-wrap',
            },
            '& .ReactTable .rt-td': {
                margin: '0 !important',
            },
            '& .ReactTable .rt-thead.-header': {
                boxShadow: 'none'
            },
            '& .ReactTable .rt-tbody .rt-tr-group .rt-tr': {
                border: 'none',
                borderTop: 'solid 1px #b2b2b2',
            },
            '& .rt-th': {
                fontWeight: 'bold !important',
                color: '#3C4858',
            },
            '& .rt-tr .rt-th': {
                marginBottom: '1px !important'
            },
            '& .ReactTable .rt-thead .rt-td': {
                margin: '0 !important'
            },
            '& .ReactTable .rt-thead .rt-th input, .ReactTable .rt-thead .rt-th input::placeholder, .ReactTable .rt-thead .rt-td input::placeholder': {
                color: '#3C4858',
                fontWeight: 'normal',
                opacity: 1,
                fontSize: '18px',
            },
            '& .ReactTable .rt-tbody .rt-tr-group': {
                borderTop: 'none',
                border: 'none !important',
            },
            '& .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td': {
                border: 'none'
            }
        },
    }
};
