import React from "react";
import classnames from "classnames";
import Icon from "@material-ui/core/Icon";
import withStyles from "@material-ui/core/styles/withStyles";

const style = {
    currentTab: {
        backgroundColor: '#3F2C78',
        color: 'white !important',
    },
    tabs: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%'
    },
    tab: {
        color: '#3F2C78',
        padding: '10px 25px',
        borderRadius: '23px',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 'normal',
        fontSize: '13px',
        cursor: 'pointer',
        transition: 'all 0.5s',
        border: '1px solid transparent',
        marginRight: '2px',
        boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px #3f2c7766',
        display: 'flex',
        '& .material-icons': {
            marginRight: '10px',
        },
        '&:hover': {
            border: '1px solid #3f2c78',
        }
    },
}
const Tabs = ({classes, tabs, currentTab, icons, setCurrentTab}) => {
    return (
        <div className={classes.tabs}>
            {tabs.map((tab, index) => {
                const isTabSingle = tabs.length === 1;
                return (
                    <div
                        onClick={() => setCurrentTab(tab)}
                        className={
                            classnames(({
                                [classes.tab]: true,
                                [classes.currentTab]: currentTab === tab || isTabSingle, 
                            }))}
                    >
                         {(icons || (icons && icons.length === 0)) && <Icon className={classes.tabIcon}>{icons[index]}</Icon>}
                        {tab}
                    </div>
                )
            })}
        </div>
    )
}

export default withStyles(style)(Tabs);