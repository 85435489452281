import React from "react";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import DraggableTable from "components/DraggableTable/DraggableTable";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomMenu from "components/CustomMenu/CustomMenu";
import InputAdornment from "@material-ui/core/InputAdornment";
import SwitchWrapper from "components/SwitchWrapper/SwitchWrapper";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import AttachmentTable from "components/AttachmentTable/AttachmentTable.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import JuridicoIcon from "assets/icons/Juridico";
// // @material-ui/icons
import { compare } from "natural-orderby";
import Delete from "@material-ui/icons/Delete";
import Check from "@material-ui/icons/Check";
import CloudDownload from "@material-ui/icons/CloudDownload";
import DonutLarge from "@material-ui/icons/DonutLarge";
import Search from "@material-ui/icons/Search";
import emptyFolder from "assets/img/bg-empty-conteudo.png";
import emptyFolderJuridical from "assets/img/bg-empty-juridico.png";
import UploadContent from "components/Upload/UploadContent.jsx";
import ModalFolders from "../ModalFolders/ModalFolders";
import { GET_JURIDICAL_FOLDERS_QUERY } from "../../views/Juridical/JuridicalQueries";
import { moveManyContentsToJuridical } from "../../views/Content/ContentView/ContentDirectoryQueries";
import { withStyles } from "@material-ui/core";
import FoldersContentTableStyle from "./FoldersContentTableStyle.js";
import { ArrowDropDown } from "@material-ui/icons";
import Loading from "../Loading/Loading";

class FoldersContentTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      searchType: "",
      checkedAll: false,
      achorEl: null,
      currentMenu: [],
      isDragging: false,
      sendToJuridicalAnchor: null,
      expandedRows: [],
      isRedirected: false
    };
  }

  searchType = type => {
    if (type === "Limpar Filtro") {
      return "";
    } else if (type === "Vídeo") {
      return "vimeo";
    } else if (type === "Imagem") {
      return "image";
    } else if (type === "Documento") {
      return "onlyoffice";
    } else if (type === "Audio") {
      return "audio";
    }
    return type;
  };

  toggleAllChecked = () => {
    const { checkedAll } = this.state;
    const { data, toggleAllChecked } = this.props;

    toggleAllChecked(!checkedAll, data);
    this.setState({ checkedAll: !checkedAll });
  };

  customSort = (a, b) => {
    a = a.props["data-name"];
    b = b.props["data-name"];

    if (a > b) {
      return 1;
    } else if (b > a) {
      return -1;
    }
    return 0;
  };

  dateSort = (a, b) => {
    a = a.props["data-date"].unix();
    b = b.props["data-date"].unix();
    if (a > b) {
      return 1;
    } else if (b > a) {
      return -1;
    }
    return 0;
  };

  sort(a, b) {
    const filterA = a.filter ? a.filter.toLowerCase() : "";
    const filterB = b.filter ? b.filter.toLowerCase() : "";

    if (filterA > filterB) {
      return 1;
    }
    if (filterB > filterA) {
      return -1;
    }
    return 0;
  }

  render() {
    const {
      classes,
      params,
      selectedFolderId,
      deleteManyContents,
      editManyContents,
      downloadManyContents,
      addFolder,
      isEmpty,
      checkedRows,
      juridical,
      cbJuridical,
      loading,
      markChecked,
      expandedRows,
      userPermissions,
      toggleTag,
      isTagToggled
    } = this.props;

    const {
      currentMenu,
      anchorEl,
      sendToJuridicalAnchor,
      isDragging,
      isRedirected
    } = this.state;
    let { data } = this.props;
    const { searchText, searchType } = this.state;
    const downloadMenu = [
      {
        name: "Baixar Arquivos",
        icon: "cloud_download",
        func: () => {
          downloadManyContents();
        }
      },
      { name: "comentários - CSV", icon: "cloud_download" }
    ];

    const menuStatus = [
      {
        name: "Em Revisão",
        colorIcon: "red",
        icon: "fiber_manual_record",
        func: () => editManyContents("Em Revisão")
      },
      {
        name: "Em Andamento",
        colorIcon: "orange",
        icon: "fiber_manual_record",
        func: () => editManyContents("Em Andamento")
      },
      {
        name: "Versão Final",
        colorIcon: "green",
        icon: "fiber_manual_record",
        func: () => editManyContents("Versão Final")
      }
    ];
    const iconClass =
      !checkedRows || checkedRows.length === 0
        ? ` ${classes.iconDisabled}`
        : "";
    const emptyState = juridical ? emptyFolderJuridical : emptyFolder;
    data = data.filter(d => {
      return (
        d.filter.includes(searchText) &&
        d.type.includes(this.searchType(searchType))
      );
    });
    let pageLength = data.length;
    data = data.map(d => {
      if (
        expandedRows.includes(d.id) &&
        d.documents &&
        d.documents.length > 0
      ) {
        return {
          ...d,
          arrow: (
            <ArrowDropDown
              style={{ cursor: "pointer" }}
              onClick={e => {
                e.stopPropagation();
              }}
            />
          )
        };
      } else {
        return d;
      }
    });
    if (loading)
      return (
        <Card
          classes={
            juridical
              ? { card: classes.card + " " + classes.juridicalCard }
              : { card: classes.card + " " + classes.contentCard }
          }
        >
          <Loading />
        </Card>
      );
    // let defaultExpandedRows =  data.map((row, index) => ({ index:false }));
    return (
      <Card
        classes={
          juridical
            ? { card: classes.card + " " + classes.juridicalCard }
            : { card: classes.card + " " + classes.contentCard }
        }
      >
        {isEmpty > 0 ? (
          <>
            <GridItem className={classes.headerWrapper} xs={12} md={12}>
              {!juridical ? (
                <UploadContent
                  projectId={params.projectId}
                  folderId={selectedFolderId}
                  contentType=""
                />
              ) : (
                <div />
              )}
              {sendToJuridicalAnchor && (
                <ModalFolders
                  projectId={params.projectId}
                  folderId={selectedFolderId}
                  id={checkedRows.filter(cr => cr)}
                  moveToFolder={obj => {
                    this.props.clearRows();
                    moveManyContentsToJuridical({
                      ...obj,
                      cb: error => cbJuridical(error)
                    });
                  }}
                  addFolders={() => {}}
                  anchorEl={sendToJuridicalAnchor}
                  foldersQuery={GET_JURIDICAL_FOLDERS_QUERY}
                  close={() => this.setState({ sendToJuridicalAnchor: null })}
                  back={false}
                />
              )}
              <div className={classes.inputWrapper}>
                <div>
                  <span className={classes.tagLabel}> Tags </span>
                  <SwitchWrapper
                    checked={isTagToggled}
                    onChange={toggleTag}
                    value="checkedA"
                  />
                </div>
                <CustomInput
                  classes={{
                    labelRoot: classes.inputLabel,
                    formControl: classes.restrainInputWidth,
                    underline: classes.underlineInput
                  }}
                  labelText={<span>Nome do Arquivo</span>}
                  id="firstname"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event =>
                      this.setState({ searchText: event.target.value }),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.inputAdornment}
                      >
                        <Search className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomDropdown
                  buttonText={"Filtrar por Tipo"}
                  onClick={type => this.setState({ searchType: type })}
                  classes={{
                    manager: classes.filterDropDownManager,
                    button: classes.dropDown,
                    butonText: classes.dropDownButtonText,
                    buttonTextWrapper: classes.buttonTextWrapper
                  }}
                  dropdownList={[
                    //todo: make the list not hardcoded
                    "Limpar Filtro",
                    "Vídeo",
                    "Documento",
                    "Imagem",
                    "Audio"
                  ]}
                />
                <div className={classes.headerIconWrapper}>
                  {((!juridical &&
                    userPermissions.includes("edit-content-status")) ||
                    (juridical &&
                      userPermissions.includes("edit-juridical-status"))) && (
                    <div
                      className={classes.headerIcon + iconClass}
                      onClick={e =>
                        this.setState({
                          anchorEl: e.currentTarget,
                          currentMenu: menuStatus
                        })
                      }
                    >
                      <DonutLarge />
                    </div>
                  )}
                  {!juridical && userPermissions.includes("edit-content") && (
                    <div
                      onClick={e => {
                        if (e) {
                          this.setState({
                            sendToJuridicalAnchor: e.currentTarget
                          });
                        }
                      }}
                      className={classes.headerIcon + iconClass}
                      style={{ paddingLeft: "1px" }}
                    >
                      <JuridicoIcon />
                    </div>
                  )}
                  <div
                    className={classes.headerIcon + iconClass}
                    onClick={e =>
                      this.setState({
                        anchorEl: e.currentTarget,
                        currentMenu: downloadMenu
                      })
                    }
                  >
                    <CloudDownload />
                  </div>
                  {((!juridical && userPermissions.includes("edit-content")) ||
                    (juridical &&
                      userPermissions.includes("edit-juridical"))) && (
                    <div className={classes.headerIcon + iconClass}>
                      {" "}
                      <Delete
                        onClick={() => deleteManyContents(selectedFolderId)}
                      />{" "}
                    </div>
                  )}
                </div>
              </div>
            </GridItem>
            <CardBody style={{ height: "Calc(100% - 45px)" }}>
              <div
                className={classes.contentViewTable + " " + classes.tableDiv}
              >
                {isRedirected && <Redirect to={isRedirected} />}
                <DraggableTable
                  data={data}
                  showPagination={false}
                  pageSize={pageLength}
                  resizable={false}
                  juridical={juridical}
                  setDraggin={isDragging => this.setState({ isDragging })}
                  isSomeRowDraggin={isDragging}
                  draggingIds={checkedRows.filter(cr => cr)}
                  toggleRow={data.map(() => true)}
                  expandedRows={expandedRows}
                  // defaultExpanded={defaultExpandedRows}
                  onClickRow={markChecked}
                  defaultSorted={[{
                    id: 'name',
                    desc: false,
                  }]}
                  onDbClickRow={(index, id) =>
                    this.setState({
                      isRedirected:
                        "/content/" +
                        (juridical ? "juridical-" : "") +
                        "view/" +
                        id
                    })
                  }
                  columns={[
                    {
                      Header: <span />,
                      accessor: "arrow",
                      minWidth: 20,
                      maxWidth: 20
                    },
                    {
                      Header: (
                        <div style={{ marginLeft: 8 }}>
                          {" "}
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => {
                              this.toggleAllChecked();
                            }}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        </div>
                      ),
                      accessor: "checkbox",
                      maxWidth: 30,
                      sortable: false
                    },
                    {
                      Header: (
                        <div className={classes.titleHeader}>
                          <div>Título</div>
                        </div>
                      ),
                      accessor: "name",
                      maxWidth: 800,
                      minWidth: 200,
                      sortMethod: (a, b) => {
                        return compare()(
                          a.props["data-name"],
                          b.props["data-name"]
                        );
                      }
                    },
                    {
                      accessor: "_name",
                      maxWidth: 0,
                      minWidth: 0,
                      style: {
                        display: "none",
                        width: 0
                      }
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                      maxWidth: 230,
                      minWidth: 180,
                      sortMethod: (a, b) => {
                        return compare()(
                          a.props["data-name"],
                          b.props["data-name"]
                        );
                      }
                    },
                    {
                      Header: "Coment.",
                      accessor: "comment",
                      maxWidth: 100,
                      sortMethod: this.customSort
                    },
                    {
                      Header: "Upload",
                      accessor: "upload",
                      maxWidth: 130,
                      sortMethod: this.dateSort
                    },
                    {
                      Header: "Por",
                      accessor: "por",
                      maxWidth: 50,
                      sortMethod: this.customSort
                    },
                    {
                      accessor: "option",
                      maxWidth: 50
                    }
                  ]}
                  defaze={10}
                  SubComponent={row => {
                    if (
                      row &&
                      row.original.documents &&
                      row.original.documents.length > 0
                    )
                      return (
                        <AttachmentTable
                          projectId={params.projectId}
                          data={row.original.documents}
                        />
                      );
                  }}
                  defaultSorting={[
                    {
                      id: "name",
                      desc: true
                    }
                  ]}
                  minRows={pageLength}
                  showPaginationTop={false}
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </div>
              <CustomMenu
                options={currentMenu}
                isOpen={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => {
                  this.setState({ anchorEl: null });
                }}
                style={{
                  style: {
                    marginTop: "45px"
                  }
                }}
              />
            </CardBody>
          </>
        ) : (
          <div className={classes.emptyState}>
            <img alt="" onClick={() => addFolder()} src={emptyState} />
          </div>
        )}
      </Card>
    );
  }
}

export default withStyles(FoldersContentTableStyle)(FoldersContentTable);
