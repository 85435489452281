import { gql } from "apollo-boost";
//LEGACY, USING SEPARATE FILES FOR SEMANTIC CLARITY.

const GET_PROJECT_WITH_COLLABORATIONS = gql`
  query GET_PROJECT_WITH_COLLABORATIONS($projectId: ID!) {
    project(id: $projectId) {
      id
      name
      organization {
        id
        name
      }
      collaborativeOrgs {
        id
        organization {
          id
          name
        }
        role {
          id
          name
          permissions
        }
      }
    }
  }
`;

const getUserAuthMutation = gql`
  mutation getUserAuthMutation($email: String!, $password: String!) {
    authUser(email: $email, password: $password)
  }
`;

const getContentByType = gql`
  query getContentByType($projectId: ID!, $type: String) {
    contents(projectId: $projectId, type: $type) {
      finalVersionDependsOn
      name
      id
    }
  }
`;

const editProjectMutation = gql`
  mutation editProjectMutation($projectId: ID!, $archived: Boolean) {
    editProject(id: $projectId, archived: $archived) {
      archived
      id
    }
  }
`;

// const GET_PROJECT_QUERY = gql`
//   query GET_PROJECT_QUERY($id: ID!){
//     project(id: $id){
//       id
//       name
//       startDate
//       endDate
//     }
//   }
// `

const getLatestVersionFile = gql`
  query getLatestVersionFile($projectId: ID!, $type: String) {
    contents(projectId: $projectId, type: $type) {
      finalVersionDependsOn
      name
      id
      latestVersion {
        name
        fileKey
      }
    }
  }
`;

const getTargetVersionFile = gql`
  query getTargetVersionFile($projectId: ID!, $type: String) {
    contents(projectId: $projectId, type: $type) {
      finalVersionDependsOn
      name
      id
      versions {
        id
        fileKey
      }
    }
  }
`;

const vimeoToken = gql`
  query vimeoToken {
    vimeoToken
  }
`;

const addCommentMutation = gql`
  mutation addCommentMutation(
    $text: String!
    $versionId: ID!
    $timeStamp: String
    $parentId: ID
  ) {
    addComment(
      text: $text
      versionId: $versionId
      timeStamp: $timeStamp
      parentId: $parentId
    ) {
      id
      text
      timeStamp
      parent {
        id
        text
      }
    }
  }
`;

const getComments = gql`
  query getComments($versionId: ID!) {
    comments(versionId: $versionId) {
      id
      timeStamp
      text
      creator {
        id
        name
      }
      creationDate
      version {
        id
        name
      }
      checked
      attachments {
        fileKey
        id
      }
      tags
      parent {
        id
        creator {
          id
          name
        }
      }
      subComments {
        id
        checked
        timeStamp
        text
        creator {
          id
          name
        }
        parent {
          id
        }
        tags
        creationDate
        version {
          id
          name
        }
      }
    }
  }
`;

const myUser = gql`
  query myUser {
    myUser {
      id
      name
      organization {
        id
        name
        image {
          id
          imageUrl
        }
      }
      image {
        id
        imageUrl
      }
      role {
        id
        name
        permissions
      }
      lastLogin
      unseenNotifications
    }
  }
`;

export {
  GET_PROJECT_WITH_COLLABORATIONS,
  getTargetVersionFile,
  getLatestVersionFile,
  editProjectMutation,
  getUserAuthMutation,
  addCommentMutation,
  getContentByType,
  getComments,
  vimeoToken,
  myUser
};
