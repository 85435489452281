import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.jsx";
const style = {
  ...tooltipsStyle,
  invisibleSvg: {
    '& svg': {
      visibility: 'hidden !important',
    }
  },
  simpleTable: {
    paddingBottom: '0 !important',
    '& .rt-tr-group, .rt-tr -odd': {
      height: '27px !important',
      backgroundColor: 'white !important',
      paddingTop: '0 !important',
      border: '0 !important'
    },
    '& .rt-td': {
      backgroundColor: 'white !important',
      paddingTop: 0,
    },
    '& .rt-tbody': {
      height: 'fill-available'
    }
  },
    folderIcon: {
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'space-between',
        paddingLeft: '15px',
        padding: '5px 15px',
        width: '100%',
        paddingRight: '0px',
        userSelect: 'none',
        border: '1px solid white',
        animation: 'all 1s ease',
        '& div div': {
          marginLeft: '5px',
          minWidth: "95px",
          maxWidth: "160px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          display: "block",
        },
        '&:hover div div': {
          width: "95px",
        },
        '& div div:nth-child(2)': {
          justifyContent: 'space-between',
        },
        '& div': {
          display: 'flex',
          marginRight: '5px'
        },
        '&:hover': {
          backgroundColor: '#f7f7f7',
        },
        '& div svg': {
          color: '#949494',
          width: '17px',
          height: '24px',
          cursor: 'pointer',
          visibility: 'hidden',
          opacity: '0',
          transition: 'opacity 0.3s',
          marginRight: '0px'
        },
        '& div:first-child svg': {
          color: '#5A4B88',
          fill: '#5A4B88',
          width: '17px',
          height: '20px',
          visibility: 'visible',
          opacity: '1',
          marginRight: '0px'
        },
        '&:hover div svg': {
          visibility: 'visible',
          opacity: '1',
        },
      },
      editDeleteOptions: {
        marginTop: '0px',
        '& svg': {
          height: '17px !important',
          width: '17px !important',
          marginRight: '5px !important'
        },
    },
      folderSelected: {
        backgroundColor: '#f7f7f7',
    },
    subFolder: {
      paddingLeft: '35px !important',
    },
    hovered: {
      border: '1px dashed #3F2C78',
      borderRadius: '4px',
      animation: 'all 1s linear',
      backgroundColor: '#3f2c781a',
    },
    contentCard: {
      height: '100%'
    },
    juridicalCard: {
      height: 'Calc(100% - 40px)'
    },
    card: {
      overflow: 'auto',
      marginTop: '40px',
      marginBottom: '0',
      maxWidth: '235px',
       marginRight: '15px', 
      '& .rt-table': {
        width: '100%',
        overflowX: 'hidden',
        '& ::-webkit-scrollbar': {
          width: '9px',
          height: '9px',
        },
        '& ::-webkit-scrollbar-button': {
          width: '0px',
          height: '0px',
        },
        '& ::-webkit-scrollbar-thumb': {
          background: '#a5abba',
          border: '0px none #ffffff',
          borderRadius: '3px',
        },
        '& ::-webkit-scrollbar-thumb:hover': {
          background: '#a5abba',
        },
        '& ::-webkit-scrollbar-thumb:active': {
          background: '#a5abba',
        },
        '& ::-webkit-scrollbar-track': {
          background: '#d8d8d8',
          border: '0px none #ffffff',
          borderRadius: '3px',
        },
        '& ::-webkit-scrollbar-track:hover': {
          background: '#babcd6',
        },
        '& ::-webkit-scrollbar-track:active': {
          background:'#babcd6',
        },
        '& ::-webkit-scrollbar-corner': {
          background: 'transparent',
        }
      },
    },
    newFolderWrapper: {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 1,
      width: '100%',
      height: 80,
      maxHeight: 80,
      maxWidth: '100%',
      backgroundColor: 'rgba(255,255,255,0.8)',
    },
    newFolder: {
        backgroundColor: '#5A4B88',
        height: '41px',
        minWidth: '50px',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '14px',
        lineHeight: '14px',
        padding: '11px 10px',
        borderRadius: '6px',
        boxShadow: '0 4px 23px 0 rgba(0,0,0,0.12)',
        fontWeight: '500',
        justifyContent: 'space-between',
        marginBottom: '45px',
        marginTop: '6px',
        marginLeft: '5px',
        "&:hover": {
          backgroundColor: '#796aa9',
          boxShadow: "none",
        },
        "&:focus": {
          backgroundColor: '#5A4B88',
          boxShadow: "none",
        },
        '& svg': {
          color: "white",
          height: '24px',
          width: '24px'
        },
      },
      folderTable: {
        padding: '0',
        paddingBottom: '25px',
        height: '100%',
        '& .ReactTable': {
          border: 'none !important',
          maxHeight: '100%',
          overflow: 'auto',
        },
        '& .ReactTable .rt-td': {
          padding: '0px',
          backgroundColor: 'white !important'
        },
        '& .rt-td': {
          paddingBottom: '0 !important',
          margin: 0,
        },
        '& .ReactTable .rt-table': {
          overflowX: 'hidden',
          overflow: 'hidden',
        },
        '& .ReactTable .rt-tbody .rt-tr-group': {
          border: 'none',
          overflow: 'hidden'
        },
        '& .rt-noData': {
          display: 'none'
        },
        '& .rt-expandable': {
          display: 'none !important',
        }
      },
      customTooltip: {
        backgroundColor: 'black',
        fontSize: "15px",
        zIndex:0,
    },
};

export default style;