export default {
  root: {
    backgroundColor: '#efefef',
    width: '350px',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 50,
    borderRadius: 6,
    position: 'fixed',
    top: 0,
    left: '-315px',
    zIndex: 99999,
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    '& h4': {
      marginTop: '10px',
      marginBottom: '15px',
      color: '#5A4B88',
      textAlign: 'center'
    },
    '& button': {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '13px',
      marginLeft: 'auto',
      marginRight: 'auto',
      borderRadius: '6px',
      padding: '10px 10px !important',
      height: '45px'
    }
  },
  bgOverlay: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 9999,
    cursor: 'initial'
  },
  chevronWrapper: {
    height: '100%',
    width: '50px',
    paddingTop: '11px',
    paddingLeft: '10px',
    '& svg': {
      height: '26px !important',
      width: '26px !important',
      fontSize: '30px'
    }
  },
  closeWrapper: {
    height: '100%',
    width: '50px',
    paddingTop: '15px',
    paddingRight: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleWrapper: {
    width: '100%',
    flex: 1,
    display: 'flex',
    paddingTop: '15px',
    justifyContent: 'center'
  },
  createNewFolderWrapper: {
    height: '100%',
    width: '100%',
    paddingTop: '11px',
    paddingLeft: '10px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '50px',
    borderBottom: '1px solid #9f94bf',
    '& svg': {
      cursor: 'pointer',
      color: '#5A4B88',
      '&:last-child': {
        height: '20px',
        marginTop: '2px',
      }
    },
    '& span': {
      fontSize: '16px'
    },
  },
  send: {
    height: '100%',
    width: '70px',
    textAlign: 'right',
    paddingRight: '11px',
    paddingTop: '11px'
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '50px',
    borderTop: '1px solid #9f94bf',
    '& svg': {
      cursor: 'pointer',
      color: '#5A4B88'
    }
  },
  foldersTable: {
    maxWidth: '350px',
    height: '150px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0',
    borderRadius: 0,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
  }
}