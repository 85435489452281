import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import AddCircle from "@material-ui/icons/AddCircle";
import Button from "components/CustomButtons/Button.jsx";
import ListItem from "@material-ui/core/ListItem";
import { Query, withApollo } from "react-apollo";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import TeamTable from "components/TeamTable/TeamTable.jsx";
import {
  GET_GROUPS_QUERY,
  GET_ORGANIZATIONS_WITH_MEMBERS_QUERY,
  deleteGroup,
  deleteMemberProject,
  deleteMemberGroup
} from "./TeamQueries.js";
import { myUser } from "queries/queries.js";
import AddMembersModal from "components/AddMembersModal/AddMembersModal.jsx";
import CollaborationInviteModal from "components/Organization/collaborationInviteModal/CollaborationInviteModal.jsx";
import TeamStyle from "./TeamStyle.js";
import Loading from "components/Loading/Loading.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

class Equipe extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: "EXIBIR TIMES",
      openNewTeam: false,
      openEditTeam: false,
      openCollabModal: false,
      currentGroup: null,
      alert: null
    };

    this.showAlert = this.showAlert.bind(this);
  }

  isCollaboration = (name, collabs) => {
    let isCollab = false;
    let selectedCollab = {};
    if (collabs)
      collabs.map(collab => {
        if (collab.organization.name === name) {
          selectedCollab = collab;
          isCollab = true;
        }
        return collab;
      });

    return { isCollab, selectedCollab };
  };

  showAlert(msg, emails, type) {
    this.setState({
      alert: (
        <SweetAlert
          success={type === "success" || !type} //!type so that defaults to success alert, for backwards compatibility
          danger={type === "danger"}
          warning={type === "warning"}
          style={{ display: "block", marginTop: "-100px" }}
          title={msg ? msg : "Convites bem sucedido!"}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.purpleButton + " " + this.props.classes.success
          }
        >
          <div>{emails && emails.map(email => email.destination)}</div>
        </SweetAlert>
      )
    });
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }
  addMemberButtonCheck({ group, userId }) {
    let isInGroup = group.members.some(member => member.id === userId);
    return group.default && isInGroup;
  }

  render() {
    const {
      classes,
      match: {
        params: { projectId }
      },
      client
    } = this.props;
    const {
      openNewTeam,
      openEditTeam,
      currentGroup,
      openCollabModal,
      alert
    } = this.state;
    return (
      <Query query={myUser}>
        {({ data, loading }) => {
          if (loading) {
            return null;
          }
          if (!data) {
            window.location.href = "/login";
          }
          let userPermissions = data.myUser.role.permissions;
          let myUserId = data.myUser.id;
          let myUserOrgId = data.myUser.organization.id;
          return (
            <div className={classes.root}>
              <Query query={GET_GROUPS_QUERY} variables={{ id: projectId }}>
                {({ loading, data, error }) => {
                  if (loading)
                    return (
                      <h2>
                        {" "}
                        <Loading />{" "}
                      </h2>
                    );
                  if (error) return <h2> error </h2>;
                  if (data && data.project.groups.length === 0)
                    return <h2> Este projeto ainda não tem grupos.</h2>;
                  if(!this.state.ownerOrgId || !this.state.myUserOrgId) this.setState({ ownerOrgId: data.project.organization.id, myUserOrgId })
                  return (
                    <div>
                      <Query query={GET_ORGANIZATIONS_WITH_MEMBERS_QUERY}>
                        {({ loading, data: orgData, error }) => {
                          if (error) return <h2> error </h2>;

                          return (
                            <>
                              {(openNewTeam || openEditTeam) && (
                                <AddMembersModal
                                  open={openNewTeam || openEditTeam}
                                  loading={loading}
                                  projectMembers={data.project.members}
                                  projectId={projectId}
                                  organizations={orgData.organizations}
                                  onClose={() =>
                                    this.setState({
                                      openNewTeam: false,
                                      openEditTeam: false,
                                      currentGroup: null
                                    })
                                  }
                                  showAlert={this.showAlert}
                                />
                              )}

                              {openCollabModal && (
                                <CollaborationInviteModal
                                  open={openCollabModal}
                                  loading={loading}
                                  currentGroup={currentGroup}
                                  projectMembers={data.project.members}
                                  projectId={projectId}
                                  organizations={orgData.organizations}
                                  onClose={() =>
                                    this.setState({
                                      openCollabModal: false,
                                      currentGroup: null
                                    })
                                  }
                                  showAlert={this.showAlert}
                                />
                              )}
                            </>
                          );
                        }}
                      </Query>
                      <List className={classes.list}>
                        {alert}
                        <ListItem
                          className={
                            classes.listItem + " " + classes.tabsWrapper
                          }
                        >
                          {this.state.ownerOrgId === this.state.myUserOrgId && <Button
                            onClick={() =>
                              this.setState({ openCollabModal: true })
                            }
                            className={classes.newEquip}
                          >
                            <div className={classes.newEquipPlus}>
                              {" "}
                              <AddCircle />{" "}
                            </div>
                            <div> ORGANIZAÇÃO EXTERNA </div>
                          </Button>}
                        </ListItem>
                      </List>
                      <GridContainer
                        className={classes.templateModal}
                        justify="start"
                      >
                        {data.project.groups.map((group, userIndex) => {
                          const {
                            isCollab,
                            selectedCollab
                          } = this.isCollaboration(
                            group.name,
                            data.project.collaborativeOrgs
                          );
                          if (loading) return null;
                          if (error) return null;
                          const showMoreOptions =
                            String(data.project.organization.id) ===
                            String(myUserOrgId);

                          return (
                            <GridItem
                              style={{
                                marginBottom: "20px",
                                padding: "0 20px"
                              }}
                              sm={12}
                              md={4}
                              xl={3}
                              key={userIndex}
                            >
                              <TeamTable
                                deleteGroup={() => {
                                  deleteGroup({
                                    params: {
                                      id: group.id,
                                      projectId: projectId
                                    },
                                    client
                                  });
                                }}
                                deleteMemberProject={(userId, onError) =>
                                  deleteMemberProject({
                                    params: { projectId, userId },
                                    client,
                                    onError
                                  })
                                }
                                deleteMemberGroup={(userId, onError) =>
                                  deleteMemberGroup({
                                    params: {
                                      projectId,
                                      groupId: group.id,
                                      userId
                                    },
                                    client,
                                    onError
                                  })
                                }
                                openEditTeam={id =>
                                  this.setState({
                                    openEditTeam: id,
                                    currentGroup: group
                                  })
                                }
                                id={group.id}
                                defaultGroup={group.default}
                                projectId={projectId}
                                users={group.members}
                                leader={
                                  !group.collaboration && group.default
                                    ? data.project.owner
                                    : null
                                }
                                title={group.name}
                                userPermissions={userPermissions}
                                onClickBottom={() =>
                                  this.setState({ openNewTeam: true })
                                }
                                showBottomButton={this.addMemberButtonCheck({
                                  group,
                                  userId: myUserId
                                })}
                                client={client}
                                showBedge={isCollab}
                                collab={selectedCollab}
                                showMoreOptions={showMoreOptions}
                              />
                            </GridItem>
                          );
                        })}
                      </GridContainer>
                    </div>
                  );
                }}
              </Query>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withApollo(
  withStyles({ ...TeamStyle, ...sweetAlertStyle })(Equipe)
);
