const style = {
  primaryHover: {
    "&:hover": {
      backgroundColor: "white !important"
    }
  },
  dropdownItem: {
    padding: "0px 0px",
    height: "auto"
  },
  titleWrapper: {
    backgroundColor: "white",
    height: "61px",
    width: "100%",
    marginTop: "25px",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.11)"
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "20px",
    fontWeight: "normal",
    color: "#3F2C78",
    marginTop: "0px",
    alignSelf: "center",
    marginLeft: "20px"
  },
  logListWrapper: {
    marginTop: 20
  },
  logBody: {
    display: "flex",
    width: "100%",
    paddingTop: 10,
    "& b": {
      color: "#3f2c78"
    },
    "&:hover": {
      backgrounColor: "#e3e3e3",
      color: "#333333",
      "& b": {
        color: "#7464a4"
      },
      "& $projectName": {
        color: "#b3b2b3"
      }
    },
    borderBottom: "1px solid #3f2c785e",
    borderRadius: "6px"
  },
  logImage: {
    marginLeft: 10,
    marginTop: 10
  },
  logFooter: {
    display: "flex",
    color: "#aca8b9",
    marginTop: 5,
    paddingBottom: 5,
    marginLeft: "-10px"
  },
  logWrapper: {
    marginLeft: "5px",
    marginTop: "0px",
    width: "100%",
    borderRadius: "6px"
  },
  logsWrapper: {
    overflowY: "auto",
    maxHeight: "50vh"
  },
  logText: {
    whiteSpace: "normal"
  },
  logDescription: {
    fontSize: "14px"
  },
  logIcon: {
    marginTop: "1px !important"
  },
  accessTime: {
    height: "12px",
    marginTop: 4
  },
  seenIcon: {
    position: "absolute",
    zIndex: 900,
    fontSize: "large",
    marginTop: 5,
    right: 10
  }
};
export default style;
