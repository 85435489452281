import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./StatusBoxStyle";
import { Link } from 'react-router-dom';

const pickColor = (status, demo) => {
    if (demo) return '';

    if (status === 'orange') {
        return 'Em Andamento';
    }
    if (status === 'red') {
        return 'Atrasado';
    }
    if (status === 'green') {
        return 'Completo';
    }
    if (status === 'grey') {
        return 'Não Iniciado';
    }
    if (status === '' ) {
        return 'N/A';
    }

    return 'Não Iniciado';
}
const StatusBox = ({classes, old, status, demo, contentId}) => {
    if ( contentId ) {
        return (
        <Link to={`/content/view/${contentId}`}>
            <div className={old ? classes.old : classes.new}>
                <div className={classes.root + ' ' + classes['status'+status]}>  {pickColor(status, demo)} </div>
            </div>
        </Link>);
    }
    return (
    <div className={old ? classes.old : classes.new}>
        <div className={classes.root + ' ' + classes['status'+status]}>  {pickColor(status, demo)} </div>
    </div>);
}
  
export default withStyles(style)(StatusBox);