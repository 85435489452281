import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { Query, withApollo } from 'react-apollo';
import FoldersTable from '../FoldersTable/FoldersTable';
import ModalFoldersStyle from './ModalFoldersStyle';
import { ChevronLeft, Close, CreateNewFolder } from '@material-ui/icons';
import Loading from '../Loading/Loading';

export class JuridicalFolderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFolder: '',
      selectedFolderId: '',
      folderId: props.folderId,
    }
  }
  addFolder = () => {
    const { client, addFolder, projectId } = this.props;
    addFolder({params: {name: 'placeholder', projectId}, client})
  }
  
  handleBack = (e) => {
    e.stopPropagation(); 
    this.props.back();
  }
  handleClose = (e) => {
    e.stopPropagation(); 
    this.props.close(e);
  }

  moveToFolder = async (e) => {
    let { moveToFolder, client, id } = this.props;
    const { selectedFolderId } = this.state;
    e.stopPropagation();
    if(!Array.isArray(id)) {
      id = [id]; 
    }
    await moveToFolder({params: {contentIds: id, folderId: selectedFolderId, projectId:this.props.projectId}, client});
    this.props.close(e);
  }
  
  render() {  
    const { foldersQuery, classes, anchorEl, projectId, back } = this.props;
    const { selectedFolder, selectedFolderId } = this.state;
    let anchorElStyle = {}
    if (anchorEl) {
      anchorElStyle = {
        top: anchorEl.offsetTop + 90,
        left: anchorEl.offsetLeft + 70,
      }
    }
    return (
      <div>
        <div onClick={this.handleClose} className={classes.bgOverlay}/>
        <div className={classes.root} style={anchorElStyle}>
          <div className={classes.header}>
            <div onClick={e => e.stopPropagation()} className={classes.chevronWrapper}> 
              {back && <ChevronLeft onClick={this.handleBack}/> }
            </div>
            <div className={classes.titleWrapper} onClick={e => e.stopPropagation()}> Mover para jurídico </div> 
            <div onClick={e => e.stopPropagation()} className={classes.closeWrapper}> 
              <Close onClick={this.handleClose}/> 
            </div>
          </div>
          <Query query={foldersQuery} variables={{ projectId }}>
            {({data, loading, error}) => {
              if (loading) return <Loading/>;
              return (
                <FoldersTable
                  disableEdit
                  classes={{card: classes.foldersTable}}
                  openFolders={[]}
                  selectedFolder={selectedFolder}
                  selectedFolderId={selectedFolderId}
                  selectedSubFolder={() => {}}
                  onDrop={() => {}}
                  checkedRows={() => {}}
                  rootFolders={data.juridicalFolders}
                  deleteFolder={(folderId) => {}}
                  addFolder={(parentFolderId) => this.addFolder(parentFolderId)}
                  editFolder={(folderId, folderName) => {}}
                  setExpand={({selectedFolder, id}) => {this.setState({selectedFolder, selectedFolderId: id})}}
                />
              )
            }}
          </Query>
          <div className={classes.footer}>
            <div onClick={e => e.stopPropagation()} className={classes.createNewFolderWrapper}>
              <CreateNewFolder/> 
            </div>
            <div className={classes.send} onClick={this.moveToFolder} style={{cursor:"pointer"}}> 
              Enviar 
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withApollo(withStyles(ModalFoldersStyle)(JuridicalFolderModal));
