const style = {
  tableWrapper: {
    backgroundColor: '#F9F9F9',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
    borderRadius: '8px',
    padding: '10px',
    paddingTop: 0,
    margin: '10px 0',
    width: '100%',
    border: 'none',
    '& .ReactTable': {
      border: 'none'
    },
    '& .ReactTable .rt-tbody': {
      maxHeight: '350px'
    },
    '& .rt-noData': {
      color: 'red'
    },
    '& .ReactTable .rt-tbody .rt-td': {
      border: 'none'
    },
    '& .ReactTable .rt-thead.-header': {
      boxShadow: 'none'
    },
    '& .ReactTable .rt-tbody .rt-tr-group .rt-tr': {
      border: 'none',
      borderBottom: 'solid 1px rgba(210, 210, 210, 0.7)',
      
    },
    '& .ReactTable .rt-thead .rt-th input, .ReactTable .rt-thead .rt-th input::placeholder, .ReactTable .rt-thead .rt-td input::placeholder': {
      color: '#3C4858',
      fontWeight: 'normal',
      opacity: 1,
      fontSize: '18px',
    },
    '& .ReactTable .rt-tbody .rt-tr-group': {
      borderBottom: 'none',
      border: 'none !important',
    },
    '& .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td': {
      border: 'none'
    }
  },
  hide: {
    '& .ReactTable .rt-tbody': {
      maxHeight: '0'
    },
    '& .rt-noData': {
      display: 'none',
    },
    '& .rt-table': {
      overflow: 'hidden !important'
    }
  },
  shrink: {
    display: 'none',
  },
  dropDownButtonText: {
    fontFamily: "Roboto, sans-serif !important",
    fontWeight: "600",
    fontSize: '14px'
  },
  dropDown: {
    backgroundColor: 'transparent',
    color: '#AAAAAA',
    boxShadow: 'none',
    padding: "10px 5px 5px 0px",
    borderBottom: '1px solid #D2D2D2',
    maxWidth: '400px',
    borderRadius: 0,
    width: '100%',
    marginBottom: '20px',
    textTransform: 'initial',
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: '#AAAAAA',
      boxShadow: 'none',
    },
  },
  sendMailWrapper: {
    backgroundColor: 'transparent',
    fontSize: '36px',
    borderRadius:'50%',
    width: '35px',
    height: '35px',
    lineHeight: '16px',
    color: '#aeaeae',
    minWidth: 0,
    minHeight: 0,
    paddingTop: '7px',
    marginTop: '10px',
    marginLeft: '-15px'
  },
  sendMail: {
    marginTop: '0px',
    borderRadius:'50%',
    width: '30px',
    '& svg': {
      height: '20px',
      width: '20px'
    }
  },
  header: {
    fontSize: '18px',
    fontFamily: 'roboto',
    textAlign: 'left',
    fontWeight: 'normal',
    marginTop: '0'
  },
  buttonTextWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%',
  },
  noPaddingInput: {
    marginTop: '-19px',
  },
  equipRoleTableWrapper: {
    width: '300px'
  },
  searchInput: {
    width: '90%',
    paddingTop: '5px',
    '&::-webkit-input-placeholder': {
      color: 'black'
    }
  },
}

export default style;