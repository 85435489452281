import React, { Component } from 'react';
import style from "./style";
import { withStyles } from '@material-ui/core';
import Icon from "@material-ui/core/Icon";

class AddMoreButton extends Component {
  render() {
    const { classes, onClick, text, icon } = this.props;

    return (
      <div  onClick={() => onClick()} className={classes.root}>
         <Icon>{icon}</Icon>
         <div className={classes.moreEpisodes}> {text} </div>
      </div>
    );
  }
}

export default withStyles(style)(AddMoreButton);
