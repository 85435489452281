import { gql } from "apollo-boost";

const ADD_TAGS_TO_DOCUMENT_MUTATION = gql`
  mutation ADD_TAGS_TO_DOCUMENT_MUTATION($id: ID!, $tagIds: [ID!]!){
    addTagsToDocument(id:$id, tagIds:$tagIds){
      id
      name
      tags {
        id
        name
        color
      }
    }
  }
`;

const ADD_DOCUMENT_TAGS_MUTATION = gql`
  mutation ADD_DOCUMENT_TAGS_MUTATION($id: ID!, $tags: [DocumentTagInput]!){
    addDocumentTags(id:$id, tags:$tags){
      id
      name
      documentTags {
        id
        name
      }
    }
  }
`;

const REMOVE_DOCUMENT_TAG_MUTATION = gql`
  mutation REMOVE_DOCUMENT_TAG_MUTATION($id: ID!, $tagIds: [ID!]!){
    removeDocumentTags(id:$id, tagIds:$tagIds){
      id
      name
      documentTags {
        id
        name
      }
    }
  }
`;

const REMOVE_TAG_FROM_DOCUMENT_MUTATION = gql`
  mutation REMOVE_TAG_FROM_DOCUMENT_MUTATION($id: ID!, $tagIds: [ID!]!){
    removeTagsFromDocument(id:$id, tagIds:$tagIds){
      id
      name
      tags {
        id
        name
      }
    }
  }
`;

const GET_TAGS_FROM_PROJECT_QUERY = gql`
  query GET_TAGS_FROM_PROJECT_QUERY($id: ID!){
    project(id:$id){
      id
      name
      documentTags {
        id
        name
        color
      }
    }
  }
`;


const GET_DOCUMENT_WITH_TAGS = gql`
  query GET_DOCUMENT_WITH_TAGS($id: ID!){
    document(id:$id){
      id
      name
      tags {
        id
        name
        color
      }
    }
  }
`;


export {
  REMOVE_TAG_FROM_DOCUMENT_MUTATION,
  ADD_TAGS_TO_DOCUMENT_MUTATION,
  REMOVE_DOCUMENT_TAG_MUTATION,
  GET_TAGS_FROM_PROJECT_QUERY,
  ADD_DOCUMENT_TAGS_MUTATION,
  GET_DOCUMENT_WITH_TAGS
};