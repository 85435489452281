import React from 'react';
import { withStyles } from '@material-ui/core';
import FlatTagList from 'components/DocumentTags/FlatTagList';
import { REMOVE_DOCUMENT_TAG_MUTATION } from "views/Juridical/DocumentTagQueries";
import { withApollo } from 'react-apollo';
import { GET_DOCUMENTS_FROM_PROJECT } from 'views/Juridical/DocumentsQueries';

const tagFilterStyles = {
  flatTag: {
    cursor:"pointer",
    width: "fit-content", 
    padding:"3px 8px 3px 8px", 
    borderRadius:"4px", 
    minWidth:"45px", 
    marginLeft:"3px", 
    marginRight:"3px",
    marginTop:"5px",
    color:"white",
    fontFamily: "Montserrat,sans-serif",
    fontSize:"0.7em",
    display:"flex",
    justifyContent:"center"
  },
  flatTagsWrapper: { 
    display: "flex", 
    flexWrap: "wrap",
    justifyContent:"flex-start", 
    padding:"2px 2px 2px 2px", 
    marginLeft:"15px"
  },
  highlight: {
    borderStyle: "none none none solid",
    borderWidth: "5px",
    filter: "brightness(0.8) !important",
    borderColor: "#6E51C9"
  },
  tagFilterWrapper: { 
    display:"flex", 
    alignItems:"center" 
  }
}


class DocumentTagsFilter extends React.Component {
  removeProjectTag({ tagIds }){
    this.props.client.mutate({ 
      mutation: REMOVE_DOCUMENT_TAG_MUTATION, 
      variables:{ id:this.props.projectId, tagIds },
      refetchQueries: () => [{
        query: GET_DOCUMENTS_FROM_PROJECT,
        variables: { projectId:this.props.projectId }
      }],
    });
  }

  render(){
    const { 
      classes,
      tags,
      handleClick,
      tagFilter } = this.props;
    return(
      <div id="tagFilterWrapper" className={classes.tagFilterWrapper}>
        <FlatTagList 
          overrideClasses={classes} 
          tags={tags} 
          handleClick={handleClick}
          selectedTag={tagFilter}
          onRemove={(tagIds) => this.removeProjectTag({ tagIds })}
          deleteDialogText="Você tem certeza que deseja remover esta tag do projeto?"
        />
      </div>
    );
  }
}

export default withApollo(withStyles(tagFilterStyles)(DocumentTagsFilter));

