import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Person from "@material-ui/icons/Person";
import PersonOutline from "@material-ui/icons/PersonOutline";
import LocalPhone from "@material-ui/icons/LocalPhone";
import StayPrimaryPortrait from "@material-ui/icons/StayPrimaryPortrait";

import { InputAdornment, IconButton } from "@material-ui/core";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import defaultUserRegistrationAvatar from "assets/img/defaultUserRegistrationAvatar.png";
import userRegistrationStyle from "../userRegistrationStyle";

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.updateFile = this.updateFile.bind(this);
    this.state = {
      phone: "",
      cellphone: "",
      name: "",
      surname: "",
      file: null
    };
  }

  sendState() {
    return this.state;
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  isValidated() {
    const { cellphone, name, surname } = this.state;
    let error = false;
    if (!this.verifyLength(cellphone, 9)) {
      error = true;
      this.setState({ verificationMsg: "", cellphoneState: "error" });
    }
    if (!this.verifyLength(name, 1)) {
      error = true;
      this.setState({
        verificationMsg: "Nome e Sobrenome devem ter pelo menos 1 caractere ",
        nameState: "error"
      });
    }
    if (!this.verifyLength(surname, 1)) {
      error = true;
      this.setState({
        verificationMsg: "Nome e Sobrenome devem ter pelo menos 1 caractere ",
        surnameState: "error"
      });
    }
    if (error) return false;
    return true;
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  formatTel(tel) {
    let formatedTel = this.clearPhoneString(tel.slice(0, 11));
    if (formatedTel.length >= 3) {
      formatedTel =
        "(" +
        formatedTel.slice(0, 2) +
        ") " +
        formatedTel.slice(2, formatedTel.length);
      return formatedTel;
    } else {
      return formatedTel;
    }
  }

  clearPhoneString(val) {
    let tempStr = val
      .replace("(", "")
      .replace(")", "")
      .replace("-", "")
      .replace(" ", "")
      .replace("+", "");
    return tempStr;
  }

  change(event, stateName, type, stateNameEqualTo) {
    let clearVal = this.clearPhoneString(event.target.value);
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "phone":
        if (
          !isNaN(parseInt(clearVal)) &&
          this.verifyLength(clearVal, stateNameEqualTo)
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "cellphone":
        if (
          !isNaN(parseInt(clearVal)) &&
          this.verifyLength(clearVal, stateNameEqualTo)
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    if (type === "phone" && clearVal.length >= 4) {
      this.setState({ [stateName]: clearVal });
    } else this.setState({ [stateName]: clearVal });
  }

  updateFile(file) {
    this.setState({ file: file });
  }

  render() {
    const { classes } = this.props;
    const { name, phone, surname, image, cellphone } = this.state;
    return (
      <GridContainer>
        <GridItem sm={7}>
          <GridContainer>
            <GridItem style={{ paddingLeft: "0" }} sm={6}>
              <CustomInput
                success={this.state.nameState === "success"}
                error={this.state.nameState === "error"}
                classes={{ labelRoot: classes.inputLabel }}
                labelText={
                  <span
                    style={{
                      color: this.state.nameState === "error" ? "red" : "gray"
                    }}
                  >
                    Nome
                  </span>
                }
                id="name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: name,
                  onChange: event => this.change(event, "name", "length", 1),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <Person />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </GridItem>
            <GridItem sm={6}>
              <CustomInput
                success={this.state.surnameState === "success"}
                error={this.state.surnameState === "error"}
                classes={{ labelRoot: classes.inputLabel }}
                labelText={
                  <span
                    style={{
                      color:
                        this.state.surnameState === "error" ? "red" : "gray"
                    }}
                  >
                    Sobrenome
                  </span>
                }
                id="surname"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: surname,
                  onChange: event => this.change(event, "surname", "length", 1),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <PersonOutline />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </GridItem>
          </GridContainer>
          <br />
          <CustomInput
            success={this.state.phoneState === "success"}
            error={this.state.phoneState === "error"}
            classes={{ labelRoot: classes.inputLabel }}
            labelText={
              <span
                style={{
                  color: this.state.phoneState === "error" ? "red" : "gray"
                }}
              >
                {phone ? this.formatTel(phone) : `(YY) XXXXX-XXXX`}
              </span>
            }
            id="phone"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.formatTel(phone),
              type: "tel",
              onChange: event => this.change(event, "phone", "phone", 10),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <LocalPhone />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <br />
          <CustomInput
            success={this.state.cellphoneState === "success"}
            error={this.state.cellphoneState === "error"}
            classes={{ labelRoot: classes.inputLabel }}
            labelText={
              <span
                style={{
                  color: this.state.cellphoneState === "error" ? "red" : "gray"
                }}
              >
                {cellphone ? this.formatTel(cellphone) : `(YY) XXXXX-XXXX`}
              </span>
            }
            id="cellphone"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.formatTel(cellphone),
              type: "tel",
              onChange: event =>
                this.change(event, "cellphone", "cellphone", 10),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <StayPrimaryPortrait />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <br />
        </GridItem>
        <GridItem xs={5} className={classes.imgUploadWrapper}>
          <ImageUpload
            updateFile={this.updateFile}
            classes={{ imgUpload: classes.imgUpload }}
            defaultImg={image ? image : defaultUserRegistrationAvatar}
            addPhotoButtonText="Adicionar Foto"
            addButtonProps={{
              color: "purple",
              classes: { root: classes.imageUploadButton + " imgUploadButton" }
            }}
            avatar
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles({ ...regularFormsStyle, ...userRegistrationStyle })(
  Step2
);
