import React, { Component } from 'react';
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import contentStyle from './contentStyle';
import "views/Content/OnlyOfficeCss.css"

class CleanOnlyOffice extends Component
{
    constructor(props) {
        super(props);
        this.scriptLoaded = this.scriptLoaded.bind(this);
    }

    setOnlyofficeType( extension ) {
        let text = [ "doc", "docm", "docx", "dot", "dotm", "dotx", "epub", "fodt", "htm", "html", "mht", "odt", "ott", "pdf", "rtf", "txt", "djvu", "xps"];
        let spreadsheet = [ "csv", "fods", "ods", "ots", "xls", "xlsm", "xlsx", "xlt", "xltm", "xltx" ];
        let presentation = ["fodp", "odp", "otp", "pot", "potm", "potx", "pps", "ppsm", "ppsx", "ppt", "pptm", "pptx" ];
        if( presentation.includes(extension) )
            return "presentation";
        if ( spreadsheet.includes(extension) )
            return "spreadsheet";
        if ( text.includes(extension) )
            return "text";
        throw new Error(`Unsupported Onlyoffice format: ${extension}`);
    }

    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://doc.server.tvmanager.com.br/web-apps/apps/api/documents/api.js";
        script.async = true;
        script.onload = async () => await this.scriptLoaded();  
        document.body.appendChild(script);
    }

    scriptLoaded() {
        let fileExtension = this.props.fileName.split('.').pop();
        let onlyofficeType = this.setOnlyofficeType( fileExtension );
        new window.DocsAPI.DocEditor("placeholder", {
            "document": {
                "fileType": fileExtension,
                "key": (Math.random()*999999).toString(),
                "title": this.props.fileName,
                "url": this.props.fileKey
            },
            "documentType": onlyofficeType,
            "editorConfig": {
                "mode": this.props.OnlyOfficeMode ? this.props.OnlyOfficeMode : "view",
                "customization": {
                    "chat": false,
                    "comments": false,
                    "help": false,
                    "zoom": -2
                }
            },
            "type":this.props.OnlyOfficeType ? this.props.OnlyOfficeType : "desktop"
        });
    }
  
    render() {
        const { classes } = this.props;
        return(
          <div id="placeholder" className={classes.placeholder}/>
        );
    }
}

export default withStyles({...contentStyle(), ...regularFormsStyle})(CleanOnlyOffice);
        