export default {
  templateModal: {
    width: '600px',
    backgroundColor: 'white',
    marginTop: '15px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'auto',
    marginBottom: '15px',
  },
  scrollableModal: {
    overflowY: 'auto',
  },
  titleNewModel: {
    textAlign: 'center',
    fontFamily: 'montserrat, sans-serif',
    fontWeight: '400',
    fontSize: '1.825em',
    marginTop: '35px',
    marginBottom: '35px',
  },
  infoWrapper: {
    backgroundColor: '#f2f1f6',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '10px',
    marginBottom: '20px',
    '& h4': {
      fontWeight: 'normal',
      fontSize: '16px !important',
      '& strong': {
        color: '#3F2C78',
        fontWeight: 'normal'
      }
    }
  },
  newModelInputWrapper: {
    display: 'flex',
    justifyContent: 'left',
    position: 'relative',
    marginLeft: '50px',
    '& input': {
      '&::placeholder': {
        color: 'black !important'
      }
    }
  },
  colorPickerWrapper: {
    position: 'absolute',
    zIndex: 9999,
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center'
  },
  colorPickerCircle: {
    height: '15px',
    width: '15px',
    borderRadius: '50%',
    backgroundColor: 'gray',
    marginRight: '5px',
    cursor: 'pointer'
  },
  pickAColor: {
    fontWeight: 'normal',
    fontSize: '14px',
    marginTop: '-2px'
  },
  colorpickerWrapper: {
    display: "flex",
    justifyContent: 'space-between',
    marginBottom: '20px'
  },
  flex: {
    display: 'flex'
  },
  formControl: {
    paddingTop: 0
  },
  inputModelName: {
    width: '500px',
    marginBottom: 0,
  },
  inputLabelNewModel: {
    color: '#666666 !important',
    fontWeight: 'normal',
    fontSize: '14px',
    zIndex: '50',
    marginTop: '-8px',
    pointerEvents: 'none'
  },
  dropDown: {
    backgroundColor: 'transparent',
    color: '#AAAAAA',
    boxShadow: 'none',
    padding: "10px 5px 5px 0px",
    borderBottom: '1px solid #D2D2D2',
    maxWidth: '400px',
    borderRadius: 0,
    width: '100%',
    marginBottom: '20px',
    textTransform: 'initial',
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: '#AAAAAA',
      boxShadow: 'none',
    },
  },
}