import moment from "moment";

const getDateSteps = (startDate, endDate, stepInDays) => {
  const _startDate = moment( startDate, 'DD/MM/YYYY' );
  const _endDate = moment( endDate, 'DD/MM/YYYY' );
  let startDates = [];
  let endDates = [];
  let currentDate = moment(_startDate);
  while(currentDate.isBefore(_endDate)) {
    startDates.push(_startDate);
    currentDate = currentDate.add(stepInDays, 'd');
    endDates.push(moment(currentDate));
  }
  return { startDates, endDates };
}

export { getDateSteps };
