import React, { Component } from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import StarsIcon from '@material-ui/icons/StarsOutlined';
import { withStyles } from '@material-ui/core';
const style = {
  tooltip: {
    backgroundColor: 'black',
    fontSize: 16
  }
}
class Stars extends Component {
  render() {
    const { classes } = this.props;

    return (
        <Tooltip classes={{tooltip: classes.tooltip}} placement="top" title="Líder do Projeto"> 
          <StarsIcon/>  
        </Tooltip> 
    );
  }
}

export default withStyles(style)(Stars);
