import React, { useState, useEffect } from "react";
import WithStyle from "@material-ui/core/styles/withStyles";
import Modal from "components/ModalWrapper/ModalWrapper";

import Input from "@material-ui/core/Input";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";
import RenameBoxStyle from "./RenameBoxStyle";

const RenameBox = ({
  classes,
  open,
  onClose = {},
  modalName = "Renomear",
  onClick = {},
  value,
  placeholder = "Digite o nome da pasta"
}) => {
  const [name, setName] = useState("");

  useEffect(() => {
    setName(value);
  }, [value]); //componentDidMount

  return (
    <Modal open={open} onClose={() => onClose()}>
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.modalName}> {modalName} </div>
          <Close onClick={() => onClose()} classes={{ root: classes.close }} />
        </div>
        <div className={classes.body}>
          <Input
            autoFocus
            classes={{ root: classes.input }}
            placeholder={placeholder}
            onChange={e => setName(e.target.value)}
            value={name}
            onKeyUp={async e => {
              if (e.keyCode === 13 && !e.shiftKey) {
                await onClick(name);
              }
            }}
          />
        </div>
        <div className={classes.footer}>
          <Button color="transparent" onClick={() => onClose()}>
            Cancelar
          </Button>
          <Button
            color="purple"
            onClick={() => onClick(name)}
            className={classes.ok}
          >
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default WithStyle(RenameBoxStyle)(RenameBox);
