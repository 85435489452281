import React from 'react';
import { Paper, withStyles } from '@material-ui/core';
import { withApollo, Query } from "react-apollo";
import DocumentTagsStyle from 'components/DocumentTags/documentTagsStyle';
import TagList from "components/DocumentTags/TagList.jsx"
import CustomPopper from "components/CustomPopper/CustomPopper";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { GET_DOCUMENT_WITH_TAGS, REMOVE_TAG_FROM_DOCUMENT_MUTATION } from "views/Juridical/DocumentTagQueries";
import FlatTagList from "components/DocumentTags/FlatTagList";

export function compareName(a, b){
  if(!a) return -1;
  if(!b) return 1;
  if(a.name && b.name){
    if(a.name.trim().toUpperCase() > b.name.trim().toUpperCase()) return 1;
    else return -1;
  }
}

class DocumentTags extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      numberOfTags: 0,
      tagListOpen: false,
      wrapperId: `documentTagsWrapper ${props.id} ${props.documentId}`,
      awaitResize: false,
      tags: props.tags ? props.tags : []
    }
  }

  async removeTagsFromDocument({ tagIds }){
    this.props.client.mutate({ 
      mutation:   REMOVE_TAG_FROM_DOCUMENT_MUTATION,
      variables:{ id:this.props.documentId, tagIds },
      refetchQueries: () => [],
    });
  }

  async setNumberOfTagsDisplay(){
    const { tags } = this.state;
    const totalWidth = await this.checksAvaibleWidth();
    let newNumberOfTags = this.state.numberOfTags;
    const currWidth = document.getElementById(this.state.wrapperId).clientWidth;
    if(currWidth < totalWidth){
      newNumberOfTags++;
      if(tags && newNumberOfTags <= tags.length) 
        this.setState({ numberOfTags: newNumberOfTags, lastResizeOperation:"+" });
    }
    else if(currWidth >= totalWidth && newNumberOfTags > 0){ 
      newNumberOfTags--;
      if(!this.state.awaitResize){
        if(this.state.lastResizeOperation === "+")
          this.setState({ numberOfTags: newNumberOfTags, awaitResize:true, lastResizeOperation:"-" });
        else
          this.setState({ numberOfTags: newNumberOfTags, lastResizeOperation:"-" });
      }
      
    }
  }

  getSiblingsWidth(){
    let siblingOffset = 50;
    const siblings = document.getElementById(this.state.wrapperId).parentNode.childNodes;
    for(let sibling of siblings)
      if(sibling.id !== this.state.wrapperId && sibling.clientWidth)
        siblingOffset += sibling.clientWidth;
    return siblingOffset;
  }

  getParentWidth(){
    const currNode = document.getElementById(this.state.wrapperId);
    return currNode.parentNode.clientWidth; 
  }

  checksAvaibleWidth(){
    if(!document.getElementById(this.state.wrapperId)) return 0;
    const moreButtonOffset = 30; 
    const siblingOffset = this.getSiblingsWidth();
    const parentWidth = this.getParentWidth();
    return  parentWidth - siblingOffset - moreButtonOffset;
  }

  setFlatListResizeListener(){
    const context = this;
    function handleResize() {
      context.setState({ awaitResize: false });
    }
    window.addEventListener('resize', handleResize);
  }

  componentDidUpdate(prevProps){
    if(this.props.tags && this.props.tags !== prevProps?.tags)
      this.setState({ tags:this.props.tags })
    this.setNumberOfTagsDisplay();
  }

  componentDidMount(){
    this.setFlatListResizeListener();
    this.setNumberOfTagsDisplay();
  }

  renderPopperButton(){
    return (
      <div className={this.props.classes.popperBtn}>
        <MoreHorizIcon fontSize="inherit" style={{ color:"#6E51C9" }}/>
      </div>)
  }

  renderTagList() {
    const { numberOfTags, tags } = this.state;
    const { tagCallback, classes } = this.props;
    return(
      <>
        {numberOfTags > 0 && 
          <FlatTagList
            overrideClasses={classes}
            tags={tags.slice(0, numberOfTags)}
            onRemove={(tagIds) => this.removeTagsFromDocument({ tagIds })}
          />
        }
        {tags && numberOfTags < tags.length &&
          <CustomPopper enableClickAway key={this.props.documentId} buttonComponent={()=> this.renderPopperButton()}>
            <Paper className={classes.popperListRoot}>
              <TagList 
                standalone 
                tags={tags.slice(numberOfTags)} 
                onAddTag={(e)=>{if(tagCallback) tagCallback(e)}} 
                onRemove={(tagIds) => this.removeTagsFromDocument({ tagIds })}/>
            </Paper>
          </CustomPopper>
        }
      </>);
  }

  renderTagsWithQueryWrapper(){
    const { tags } = this.state;
    return<Query 
            query={GET_DOCUMENT_WITH_TAGS}
            variables={{ id: this.props.documentId }}
          >
            {({ data, loading, error }) => {
              if (loading) return null;
              if (error) console.error('error ', error);
              if (data && tags !== data.document.tags)
                this.setState({ tags: data.document.tags })
              return this.renderTagList(tags)
            }}
          </Query>
  }


  render(){
    const { wrapperId } = this.state;
    const { classes } = this.props;
     return (
      <div id={wrapperId} className={classes.tagListWrapper}>
        {
          this.props.tags ? 
          this.renderTagList()
          :
          this.renderTagsWithQueryWrapper()
        }
      </div>
    );
  }
}

export default withApollo(withStyles(DocumentTagsStyle)(DocumentTags));

