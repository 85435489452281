import { gql } from 'apollo-boost';

const ALL_ORGANIZATIONS_QUERY = gql`
    query ALL_ORGANIZATIONS_QUERY{
        organizations {
            id
            name
            projects {
                id
                name
                startDate
                endDate
                owner {
                    id
                    name
                }
                image {
                    id
                    imageUrl
                }
            }
        }
    }
`;

const USER_COUNTS_QUERY = gql`
    query USER_COUNTS_QUERY($startDate: String!, $endDate: String!, $stepInDays: Int) {
        userCounts(startDate: $startDate, endDate: $endDate, stepInDays: $stepInDays)
    }
`;

const CONTENT_COUNTS_QUERY = gql`
    query CONTENT_COUNTS_QUERY($startDate: String!, $endDate: String!, $stepInDays: Int) {
        contentCounts(startDate: $startDate, endDate: $endDate, stepInDays: $stepInDays)
    }
`;

const PROJECT_COUNTS_QUERY = gql`
    query PROJECT_COUNTS_QUERY($startDate: String!, $endDate: String!, $stepInDays: Int) {
        projectCounts(startDate: $startDate, endDate: $endDate, stepInDays: $stepInDays)
    }
`;

const ORGANIZATION_COUNTS_QUERY = gql`
    query ORGANIZATION_COUNTS_QUERY($startDate: String!, $endDate: String!, $stepInDays: Int) {
        organizationCounts(startDate: $startDate, endDate: $endDate, stepInDays: $stepInDays)
    }
`;

export {
    ALL_ORGANIZATIONS_QUERY,
    USER_COUNTS_QUERY,
    CONTENT_COUNTS_QUERY,
    PROJECT_COUNTS_QUERY,
    ORGANIZATION_COUNTS_QUERY
}