const style = {
  primaryHover: {
    "&:hover": {
      backgroundColor: "white !important"
    }
  },
  dropdownItem: {
    padding: "0px 0px",
    height: "auto"
  },
  titleWrapper: {
    backgroundColor: "white",
    height: "61px",
    width: "100%",
    marginTop: "5px",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.11)"
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "20px",
    fontWeight: "normal",
    color: "#3F2C78",
    marginTop: "0px",
    alignSelf: "center",
    marginLeft: "20px"
  },
  notificationListWrapper: {
    marginTop: 40
  }
};
export default style;
