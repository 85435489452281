export default {
  wrapper: {
    backgroundColor: '#EEEEEE',
    borderRadius: '6px',
    width: '550px',
    minHeight: '210px',
    height: 'auto',
    maxHeight: '800px !important',
    margin: '0 auto',
    marginTop: '140px',
    display: 'flex',
    flexDirection: 'column',
    zIndex: '99999',
  },
  mask: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: '999'
  },
  title: {
    marginTop: 20,
    marginBottom: 15,
    fontWeight: 'normal',
    fontSize: 18
  },
  adjustedMargin:{
    marginTop: "30px"
  },
  dropDown: {
    backgroundColor: 'transparent',
    color: '#AAAAAA',
    boxShadow: 'none',
    padding: "10px 5px 5px 0px",
    borderBottom: '1px solid #D2D2D2',
    maxWidth: '400px',
    borderRadius: 0,
    width: '100%',
    marginBottom: '20px',
    textTransform: 'initial',
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: '#AAAAAA',
      boxShadow: 'none',
    },
  },
  dropDownError: {
    backgroundColor: 'red',
    color: 'red',
    boxShadow: 'none',
    padding: "10px 5px 5px 0px",
    borderBottom: '1px solid #D2D2D2',
    maxWidth: '400px',
    borderRadius: 0,
    width: '100%',
    marginBottom: '20px',
    textTransform: 'initial',
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: '#AAAAAA',
      boxShadow: 'none',
    },
  },
  dropDownNoMargin: {
    marginBottom: '5px',
  },
  buttonTextWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%',
    fontWeight: 400,
    color: '#5b5b5b'
  },
  dropDownButtonText: {
    fontFamily: "Roboto, sans-serif !important",
    fontWeight: "400",
    fontSize: '14px'
  },
  uploadContent: {
    backgroundColor: '#3F2C78',
    height: '41px',
    minWidth: '50px',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '14px',
    lineHeight: '14px',
    padding: '11px 10px',
    borderRadius: '6px',
    boxShadow: '0 4px 23px 0 rgba(0,0,0,0.12)',
    display: 'flex',
    fontWeight: '500',
    justifyContent: 'space-between',
    marginBottom: '45px',
    marginTop: '6px',
    "&:hover": {
      backgroundColor: '#796aa9',
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: '#3F2C78',
      boxShadow: "none",
    },
    '& svg': {
      color: "white",
      height: '24px'
    },
  },
  modalBody: {
    width: '90%',
    marginLeft: '5%',
    zIndex: '99999', 

  },
  plusCount: {
    height: 'auto'
  },
  linearProgress: {
    width: '90%',
    margin: '5px 5%'
  },
  restrainInputWidth: {
    width: '100px',
  },
  btnIcon: {
    height: '22px !important',
    width: '22px !important',
    marginRight: '10px !important',
    fontSize: '20px !important'
  },
  fileWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      marginTop: '7px',
      fontSize: '16px',
      height: '15px',
      cursor: 'pointer'
    },
    '& span': {
      padding: '0 10px !important'
    }
  },
  loader: {
    height: 20,
    marginTop: 1
  },
  listUploads: {
    width: '100%',
    maxHeight: '150px',
    minHeight: 100,
    height: 'auto',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '9px',
      height: '9px',
    },
    '&::-webkit-scrollbar-button': {
      width: '0px',
      height: '0px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#a5abba',
      border: '0px none #ffffff',
      borderRadius: '3px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#a5abba',
    },
    '&::-webkit-scrollbar-thumb:active': {
      background: '#a5abba',
    },
    '&::-webkit-scrollbar-track': {
      background: '#d8d8d8',
      border: '0px none #ffffff',
      borderRadius: '3px',
    },
    '&::-webkit-scrollbar-track:hover': {
      background: '#babcd6',
    },
    '&::-webkit-scrollbar-track:active': {
      background:'#babcd6',
    },
    '&::-webkit-scrollbar-corner': {
      background: 'transparent',
    }
  },
  header: {
    borderRadius:"6px 6px 0px 0px",
    paddingTop: '25px',
    marginBottom: '5px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#eeeeee',
    zIndex: '999', 
  },
  tabWrapper: {
    width: '180px'
  },
  listUploadsWrapper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '7px',
    width: '96%',
    minHeight: '50px',
    marginLeft: '2%',
    marginTop: '35px'
  },
  uploadedFile:{
    color: '#3C4858',
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'space-between',
    '& > div:first-child': {
      padding: '5px 10px',
      lineHeight: '30px',
      display: 'flex',
    justifyContent: 'space-between',
    },
    '& svg': {
      color: '#8272b3',
      marginTop: '10px',
      marginRight: '15px'
    }
  },
  inputNone: {
    display: 'none'
  },
  btnUpload: {
    float: 'right',
    marginRight: '2%',
    marginTop: '15px',
    paddingTop: '5px',
    paddingBottom: '5px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
    marginBottom: '20px',
    paddingRight: '20px',
    paddingLeft: '20px'
  },
  btnUploadLoading: {
    paddingRight: 5
  },
  footer: {
    height: '80px'
  },
  divisor: {
    height: '1px',
    backgroundColor: '#AAAAAA',
    width: '98%',
    marginLeft: '1%'
  }
}