import React from "react";

// core components
import Wizard from "components/Wizard/Wizard.jsx";

import ProjectInformation from "./WizardSteps/ProjectInformationStep.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { gql } from "apollo-boost";
import { withApollo } from "react-apollo";
import submitImage from "utils/uploadImage";
import DeliveryTemplate from "views/ProjectTemplate/WizardSteps/DeliveryTemplateStep.jsx";
import addProjectWizardStyle from "./addProjectWizardStyle.jsx";
import defaultDeliveryPhases from '../../utils/phases';

const EDIT_DELIVERY_SCHEDULE_MUTATION = gql`
  mutation EDIT_DELIVERY_SCHEDULE_MUTATION(
    $projectId: ID!
    $phases: [PhaseInput]
    $elements: [ElementInput]
  ) {
    editProjectDeliverySchedule(
      phases: $phases
      elements: $elements
      projectId: $projectId
    ) {
      name
      description
      genre
      id
      startDate
      endDate
      deliverySchedule {
        id
        phases {
          id
          name
          elements {
            id
            name
          }
        }
        elements {
          id
          name
        }
      }
      image {
        id
        imageUrl
      }
    }
  }
`;

const EDIT_DELIVERY_SCHEDULE_PHASE_MUTATION = gql`
  mutation EDIT_DELIVERY_SCHEDULE_PHASE_MUTATION(
    $projectId: ID!
    $phaseId: ID!
    $elements: [ElementInput]
  ) {
    editProjectDeliveryPhaseElements(
      phaseId: $phaseId
      elements: $elements
      projectId: $projectId
    ) {
      name
      description
      genre
      id
      startDate
      endDate
      image {
        id
        imageUrl
      }
      deliverySchedule {
        id
        phases {
          id
          elements {
            id
            name
          }
        }
        elements {
          id
          name
        }
      }
    }
  }
`;

const EDIT_PROJECT_MUTATION = gql`
  mutation EDIT_PROJECT_MUTATION(
    $name: String!
    $projectId: ID!
    $description: String
    $genre: String
    $startDate: String
    $endDate: String
    $imageId: ID
  ) {
    editProject(
      name: $name
      description: $description
      genre: $genre
      startDate: $startDate
      endDate: $endDate
      imageId: $imageId
      id: $projectId
    ) {
      name
      description
      genre
      id
      startDate
      endDate
      image {
        id
        imageUrl
      }
    }
  }
`;

const GET_PROJECT_QUERY = gql`
  query GET_PROJECT_QUERY($projectId: ID!) {
    project(id: $projectId) {
      id
      name
      genre
      owner {
        id
        name
        image {
          id
          imageUrl
        }
      }
      image {
        id
        imageUrl
      }
      deliverySchedule {
        id
        name
        elements {
          id
          name
        }
        phases {
          id
          type
          name
          completed
          startDate
          endDate
        }
      }
      startDate
      endDate
      description
    }
  }
`;

async function executeEditPhaseElementsMutation(
  editedPhases,
  numEps,
  projectId,
  client
) {
  if (editedPhases)
    for (let phase of editedPhases) {
      const newElements = buildNewElements({
        numEps,
        currentElements: phase.elements
      });

      let editPhaseVars = {
        projectId,
        phaseId: phase.id,
        elements: newElements
      };
      await client.mutate({
        mutation: EDIT_DELIVERY_SCHEDULE_PHASE_MUTATION,
        variables: editPhaseVars
      });
    }
}

function buildNewElements({ numEps, currentElements }) {
  let newElements = [...currentElements];
  const epsDiff = numEps - currentElements.length;

  if (epsDiff > 0)
    for (let i = 0; i < epsDiff; i++)
      newElements.push({ name: `Episódio ${currentElements.length + i + 1}` });

  if (epsDiff < 0)
    for (let i = 0; i < epsDiff * -1; i++)
      newElements[newElements.length - 1 - i].delete = true;

  return newElements.map(el => {
    const { __typename, ...untypedElement } = el;
    return untypedElement;
  });
}

const editDeliverySchedule = async ({
  projectId,
  phases,
  numEps,
  client,
  currentElements
}) => {
  let editDeliveryScheduleVars = {
    projectId,
    phases
  };
  if (numEps !== currentElements.length) {
    editDeliveryScheduleVars.elements = buildNewElements({
      numEps,
      currentElements
    });
  }

  const { data } = await client.mutate({
    mutation: EDIT_DELIVERY_SCHEDULE_MUTATION,
    variables: editDeliveryScheduleVars
  });

  return data.editProjectDeliverySchedule;
};

const editProject = async ({ imageId, mutationVars, projectId, client }) => {
  let EditProjectVariables = {
    name: mutationVars.projectName,
    description: mutationVars.description,
    genre: mutationVars.genre,
    startDate: mutationVars.startDate,
    endDate: mutationVars.endDate,
    projectId
  };

  if (imageId) EditProjectVariables.imageId = imageId;

  await client.mutate({
    mutation: EDIT_PROJECT_MUTATION,
    variables: EditProjectVariables
  });
};

const getSelectedDeliveryPhases = ({ wizardData, deliveryPhases }) => {
  let _deliveryPhases = [];
  wizardData.Entregas.selected.forEach((phase, index) => {
    let selectedPhaseId = deliveryPhases.filter(
      oldPhase => oldPhase.name === phase
    );
    selectedPhaseId = selectedPhaseId[0] ? selectedPhaseId[0].id : null;
    let newPhase = {
      name: phase,
      endDate: wizardData.Entregas.endDates[phase]
    };
    if (selectedPhaseId) {
      newPhase.delete = false;
      newPhase.id = selectedPhaseId;
    }
    _deliveryPhases.push({ ...newPhase, order: index });
  });
  deliveryPhases.forEach(phase => {
    if (!wizardData.Entregas.selected.includes(phase.name)) {
      let deletedPhase = phase;
      deletedPhase.delete = true;
      delete deletedPhase.__typename;
      _deliveryPhases.push(deletedPhase);
    }
  });
  return _deliveryPhases;
};

class EditProject extends React.Component {
  state = {
    open: true,
    loading: true
  };

  toggleModal = () => this.setState({ open: !this.state.open });

  getClient = async () => await require("index.js");

  async handleFinish(wizardData) {
    const {
      match: {
        params: { projectId }
      },
      client
    } = this.props;
    const { currentElements, deliveryPhases } = this.state;
    try {
      const { imageId } = wizardData.Sobre.file
        ? await submitImage(wizardData.Sobre.file)
        : {};
      const numEps = parseInt(wizardData.Entregas.numEps);

      const _deliveryPhases = getSelectedDeliveryPhases({
        wizardData,
        deliveryPhases
      });
      const targetProject = await editDeliverySchedule({
        projectId,
        phases: _deliveryPhases,
        currentElements,
        numEps,
        client
      });

      const editedPhases = targetProject.deliverySchedule.phases;
      const newPhaseElements = targetProject.deliverySchedule.elements;

      await executeEditPhaseElementsMutation(
        editedPhases,
        numEps,
        projectId,
        client,
        newPhaseElements
      );
      await editProject({
        imageId,
        mutationVars: wizardData.Sobre,
        projectId,
        client
      });

      window.location.href = "/admin/projects";
    } catch (error) {
      console.log(error);
    }
  }

  async componentDidMount() {
    const {
      match: {
        params: { projectId }
      }
    } = this.props;

    let res = await this.props.client.query({
      query: GET_PROJECT_QUERY,
      variables: { projectId }
    });

    if (res.error) {
      throw new Error(" Error getting Project", res.error);
    }

    this.setState({
      projectName: res.data.project.name,
      leader: res.data.project.owner,
      image: res.data.project.image ? res.data.project.image : "",
      genre: res.data.project.genre,
      startDate: res.data.project.startDate,
      endDate: res.data.project.endDate,
      description: res.data.project.description,
      imageId: res.data.project.image ? res.data.project.image.id : "",
      loading: false,
      deliveryPhases: res.data.project.deliverySchedule.phases,
      currentElements: res.data.project.deliverySchedule.elements.map(
        element => {
          delete element.__typename;
          return element;
        }
      )
    });
  }

  render() {
    const { classes } = this.props;
    const {
      projectName,
      image,
      startDate,
      endDate,
      genre,
      description,
      loading,
      deliveryPhases,
      currentElements
    } = this.state;
    let _deliveryPhases = defaultDeliveryPhases;
    let selectedPhases = [];

    if (deliveryPhases)
      for (let phase of deliveryPhases)
        if (!selectedPhases.includes(phase.name) && phase.delete !== false)
          selectedPhases.push(phase.name);

    if (loading) return <h2> Loading ... </h2>;
    return (
      <div className={classes.wizzardContainer}>
        <Wizard
          validate
          finishButtonText="Salvar"
          classes={{
            wizardHeader: classes.wizardHeader,
            wizardContainer: classes.wizardContainer,
            content: classes.wizardContent,
            card: classes.wizardCard,
            footer: classes.wizardFooter
          }}
          steps={[
            {
              stepName: "Sobre",
              stepComponent: ProjectInformation,
              stepId: "Sobre",
              stepProps: {
                projectName: projectName,
                image: image ? image.imageUrl : "",
                startDate: startDate,
                genre: genre,
                endDate: endDate,
                description: description
              }
            },
            {
              stepName: "Entregas",
              stepComponent: DeliveryTemplate,
              stepId: "Entregas",
              stepProps: {
                deliveryPhases: _deliveryPhases,
                numEps: currentElements.length,
                selectedPhases,
                phaseObjects: deliveryPhases
              }
            }
          ]}
          title="Editar Projeto"
          subtitle="Siga os passos abaixo para editar o seu projeto."
          finishButtonClick={e => {
            this.handleFinish(e);
          }}
          nextButtonText="Próximo"
        />
      </div>
    );
  }
}

let editProjectWithStyles = withStyles(addProjectWizardStyle)(EditProject);

export default withApollo(editProjectWithStyles);
