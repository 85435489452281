export default {
  calendar: {
    height: 670,
    boxShadow:'none',
    '& .rbc-btn-group button': {
      boxShadow:'none',
      '&:hover': {
        boxShadow:'none',
      },
      '&:active': {
        boxShadow:'none',
      },
    }
  },
  card: {
    maxWidth: 1400,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}