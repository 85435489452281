export default {
  standaloneBadge:{
    marginRight:"20px"
  },
  tableWrapper: {
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: '10px',
      paddingTop: 0,
      marginTop: 20,
      margin: '10px 0',
      width: '94%',
      marginLeft: '3%',
      border: 'none',
      marginBottom: '5px',
      paddingBottom: '5px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      overflow: 'hidden',
      '& .ReactTable': {
          border: 'none'
      },
      '& .ReactTable .rt-tbody': {
          maxHeight: '367px',
          height: ''
      },
      '& .ReactTable .rt-tbody .rt-td': {
          border: 'none'
      },
      '& .ReactTable .rt-thead.-header': {
          boxShadow: 'none'
      },
      '& .ReactTable .rt-tbody .rt-tr-group .rt-tr': {
          border: 'none',
          borderBottom: 'solid 1px rgba(210, 210, 210, 0.7)',
          minHeight: '49px'
      },
      '& .ReactTable .rt-thead .rt-th input, .ReactTable .rt-thead .rt-th input::placeholder, .ReactTable .rt-thead .rt-td input::placeholder': {
          color: '#3C4858',
          fontWeight: 'normal',
          opacity: 1,
          fontSize: '18px',
      },
      '& .ReactTable .rt-tbody .rt-tr-group': {
          borderBottom: 'none',
          border: 'none !important',
      },
      '& .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td': {
          border: 'none'
      }
  },
  bottomButton: {
    marginBottom: 0,
    marginTop: 'auto',
  },
  tooltip: {
    color: 'black'
  },
  owner: {
    width: 300,
    display: 'flex',
    '& svg': {
      width: 30,
      marginTop: 0,
      color: '#7c6ac0',
      marginLeft: 20,
      fontSize: '30px !important'
    }
  },
  leaderName: {
    fontWeight: '400',
    lineHeight: '30px'
  },
  bedgeHeader: {
    backgroundColor: '#dadbd9',
    border: '1px solid #a197b5',
    color: '#a197b5',
    fontWeight: 500,
    paddingTop: '5px',
    minWidth: '35px',
    minHeight: '35px',
  },
  userName: {
    fontSize: 15,
    fontWeight: 400
  },
  
  TeamTable: {
      backgroundColor: '#F9F9F9',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
      borderRadius: '8px',
      padding: '10px',
      width: '100%',
      margin: '10px 0',
      '& .rt-tbody': {
        minWidth: 'unset',
        maxHeight: '350px',
        overflow: 'hidden',
        overflowY: 'auto',
      },
      '& .ReactTable .rt-tbody .rt-tr-group .rt-tr': {
        borderBottom: 'solid 1px #DDDDDD',
      },
      '& .ReactTable .rt-tbody .rt-tr-group': {
        borderBottom: 'none',
      },
    },
    iconButton: {
        padding: 0,
        cursor: 'pointer',
        transition: 'all 0.5s linear',
        '&:hover': {
          backgroundColor: 'transparent',
          color: 'rgba(0, 0, 0, 0.77)'
        }
      },
    iconMenuList: {
        color: '#808080',
        fontSize: '16px',
        height: '18px',
        width: '18px',
        marginTop: '4px',
        marginLeft: '5px',
        marginRight: '20px',
        '& svg': {
          width: '19px'
        }
      },
    card: {
        minHeight: 250,
        minWidth: 280,
        shadow: 'none',
        height: '500px',
        maxHeight: '100%',
    },
    flex: {
        display: 'flex'
    },
    truncatedTitle:{
      width: 'inherit',
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      marginRight: "2px !important"
    },
    teamCardTitleWrapper: {
      display: 'flex',
      width: 'calc(90% - 18px)'
    },
    bedgeWrapper: {
      '& > div:first-child': {
          marginTop: '-6px'
      },
      paddingRight:"3px",
      marginBottom:"auto"
    },
    optionTitle: {
        color: 'white',
        padding: 0,
        marginRight: 0,
        height: 10,
        '& svg': {
            fontSize: '23px !important',
        }
    },
    title: {
        textAlign: 'left',
        width: '90%',
        minWidth: '250px',
        height: '50px',
        backgroundColor: '#3F2C78',
        marginTop: '-15px',
        paddingLeft: '15px',
        borderRadius: '6px',
        color: 'white',
        fontSize: '16px',
        padding: '12px',
        fontWeight: '600',
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        lineHeight: '25px',
        boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px #3f2c7766',
        marginLeft: '5%',
        paddingRight: 0,
        fontFamily: 'montserrat, sans-serif',
        '& svg:nth-child(3)': {
            float: 'right',
            marginLeft:'auto',
            cursor: 'pointer',
            marginRight: '15px',
            marginTop: '3px',
            fontSize: '18px',
        },
        '& svg:last-child': {
            marginRight: '-15px',
            marginTop: '3px',
            cursor: 'pointer',
            fontSize: '18px',
        },
        '& svg:nth-child(1)': {
            lineHeight: '15px',
            marginRight: '10px',
            marginLeft:'0',
            float: 'left'
        },
        '& svg': {
        }
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: '10px',
        width:"inherit"
    }
}