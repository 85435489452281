import React from "react"
import { Card, withStyles, Tooltip } from "@material-ui/core";
import SwitchesTableStyle from "./SwitchesTableStyle";
import Switch from "@material-ui/core/Switch";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// "read-project",
// "edit-project",'Projetos'://['Criar / Editar', 'Arquivar'
// "edit-group",'Equipe'://['Criar / Editar'
// "invite-user", equipe , 'Convidar']
// "read-content",//conteudo ['Acessar'
// "edit-content",conteudo  'Editar/Deletar']
// "create-content", conteudo 'Criar',
// "read-juridical", 'Jurídico'://['Acessar']
// "edit-juridical", 'Jurídico'://['Criar / Editar']
// "edit-user",
// "edit-organization",
// "read-secret" 'Pastas Privadas'://['Acessar']

// This is the permission to see all projects inside the organization,
// // even if the user is not a member of the project.
// 'read-project', 
// // This is the permission to change the project date, description, image,
// // name, etc. To delete or archive the project and to create new projects.
// // To invite new organizations to the project.
// 'edit-project', 
// // This is the permission to create new groups, change groups names and to add 
// // or remove members from existing groups.
// 'edit-group', 
// // This is the permission to invite new users to the organization and to a project.
// 'invite-user',
// // This is the permission to access the content area of the project.
// 'read-content', 
// // This is the permission to change the status of the content 
// // and to delete a content.
// 'edit-content',
// // This is the permission to create new content and add versions to existing contents.
// // Also to create folders in the content area.
// 'create-content',
// // This is the permission to access the juridical area of the project.
// 'read-juridical', 
// // This is the permission to create new documents, to attach documents to 
// // contents and to delete documents. Also to create folders on the juridical area.
// 'edit-juridical',
// // This is the permission to edit users permissions. In theory to also change
// // users name, email, password and any other info. Maybe this ability is not
// // needed.
// 'edit-user',
// // This is the permission to edit the users own organization and to create new
// // organizations by inviting new users to it.
// 'edit-organization',
// // This is the permission to access the confidential area of the system.
// 'read-secret'

class SwitchesTable extends React.Component {
  //we need centralized permission names
  translatePermissions(permission){
    let { title } = this.props;
    switch(title){
      case 'Projetos':
        if(permission === 'Criar / Editar')
          return "edit-project";
        break;
      case 'Equipe':
        if(permission === 'Criar / Editar')
          return "edit-group";
        break;
      case 'Conteúdo':
        if(permission === 'Acessar')
          return "read-content";
        if(permission === 'Criar / Versionar')
          return "create-content";
        if(permission === 'Aprovar')
          return "edit-content-status";
        if(permission === 'Editar / Excluir')
          return "edit-content";
        break;
      case 'Jurídico':
        if(permission === 'Acessar / Criar')
          return "read-juridical";
        if(permission === 'Editar / Excluir')
          return "edit-juridical";
        if(permission === 'Aprovar')
          return "edit-juridical-status";
        break;
      case 'Pastas Privadas':
        if(permission === 'Acessar')
          return "read-secret";
        break;
      case 'Planejamento':
        if(permission === 'Acessar')
          return "read-planning";
        if(permission === 'Editar')
          return "edit-planning";
        break;
      case 'Organização':
        if(permission === 'Excluir Usuário')
          return "edit-user"
        if(permission === 'Alterar Cargo')
          return "edit-organization";
        break;

      default: 
          return '';
    }
  }

  getTooltipDescription(permission){
    let { title } = this.props;
    switch(title){
      case 'Projetos':
        if(permission === 'Criar / Editar')
          return "Permite criar deletar e alterar projetos";
        break;
      case 'Equipe':
        if(permission === 'Criar / Editar')
          return "Permite criar deletar e editar grupos";
        break;
      case 'Conteúdo':
        if(permission === 'Acessar')
          return "Permite acessar a area de conteúdo";
        if(permission === 'Criar / Versionar')
          return "Permite a criação de novos conteúdos";
        if(permission === 'Editar / Excluir')
          return "Permite a alterar e remover conteúdos";
        if(permission === 'Aprovar')
          return "Permite alterar o status de conteúdos";
        break;
      case 'Jurídico':
        if(permission === 'Acessar / Criar')
          return "Permite acesso ao setor juridico e a criação de documentos";
        if(permission === 'Editar / Excluir')
          return "Permite alterar e remover documentos";
        if(permission === 'Aprovar')
          return "Permite alterar o status de documentos";
        break;
      case 'Pastas Privadas':
        if(permission === 'Acessar')
          return "Permite visualizar pastas secretas da organização";
        break;
      case 'Organização':
        if(permission === 'Excluir Usuário')
          return "Permite remover usuários da organização";
        if(permission === 'Alterar Cargo')
          return "Permite atribuir um cargo diferente ao usuário";
        break;
      case 'Planejamento':
        if(permission === 'Acessar')
          return "Permite acessar are de planejamento";
        if(permission === 'Editar')
          return "Permite criar e alterar etapas, eventos e marcos em planejamento";
        break;
      default: 
        return '';
    }
  }
  
  render() {
    const { classes, title, permissions, onChange } = this.props;
    return (
      <Card classes={{ root: classes.card }}>
        <div className={classes.title}> {title} </div>
        <div className={classes.divider} />
        {permissions && permissions.map((permission, index) => {
          return (
            <>
              <div className={classes.switchWrapper} key={index}>
                <div className={classes.label}> {permission} </div>
                <div>
                  <Tooltip
                    id="tooltip-top"
                    title={this.getTooltipDescription(permission)}
                    placement="top"
                    classes={{ tooltip: classes.tooltip + " " + classes.customTooltip }}
                  >
                    <Switch
                      onChange={() => onChange(this.translatePermissions(permission))}
                      value="checkedB"
                      checked={this.props.checked ? this.props.checked.includes(this.translatePermissions(permission)) : null}
                      classes={{
                        icon: classes.switchIcon,
                        // iconChecked:classes.switchIconChecked,
                        switchBase: classes.switchBase,
                        bar: classes.switchBarRegular,
                        root: classes.switchChecked,
                        checked: classes.switchChecked,
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
              {(index < (permissions.length - 1)) && <div className={classes.divider} />}
            </>
          )
        })}
      </Card>
    )
  }
}

export default withStyles({ ...regularFormsStyle, ...SwitchesTableStyle })(SwitchesTable);