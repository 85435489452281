import React, { Component } from "react";
import { withStyles } from "@material-ui/core";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Delete from "@material-ui/icons/Delete";
import Ballot from "@material-ui/icons/Ballot";
import Stars from "@material-ui/icons/Stars";

import DateRange from "@material-ui/icons/DateRange";
import Edit from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import LinesEllipsis from "react-lines-ellipsis";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.jsx";
import placeholderImage1 from "assets/img/card-2.jpeg";
import placeholderImage2 from "assets/img/card-3.jpeg";
import placeholderImage3 from "assets/img/card-1.jpeg";
import ProjectCardStyle from "./ProjectCardStyle";
import { archiveProject } from "../../views/Project/ProjectsQueries";
import { withApollo } from "react-apollo";
import ReviewModal from "components/ReviewModal/ReviewModal";

export class ProjectCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false
    };
  }

  chooseImage = (project, index) => {
    if (project.image) return project.image.imageUrl;
    return index % 2 ? (index % 3 ? placeholderImage1 : placeholderImage2) : placeholderImage3;
  };

  toggleModal = () => {
    const { isModalOpen } = this.state;
    this.setState({ isModalOpen: !isModalOpen });
  };

  onArchive = () => {
    const { project, client } = this.props;
    if (project.archived || project.collaborativeOrgs.length === 0) {
      archiveProject({
        projectId: project.id,
        archived: !project.archived,
        client
      });
    } else {
      this.toggleModal();
    }
  };

  generateLink = ({ project, permissions, user }) => {
    if(!permissions) return '';
    const userOrgId = user.organization.id;
    const projectOwnerOrgId = project.organization ? project.organization.id : null;
    let collaboration;
    if( userOrgId !== projectOwnerOrgId ) { 
      // If the user is from a collaboration, filters the routes again
      collaboration = project.collaborativeOrgs.find( col => col.organization.id === userOrgId );
    }
    if( (!collaboration || collaboration.role.permissions.includes('read-content')) &&
        permissions.includes('read-content')) {
        return `/project/${project.id}/contents/0/0`;
    }
    if( (!collaboration || collaboration.role.permissions.includes('read-juridical')) &&
        permissions.includes('read-juridical')) {
        return `/project/${project.id}/juridico/0/0`;
    }
    if( (!collaboration || collaboration.role.permissions.includes('read-planning')) &&
        permissions.includes('read-planning')) {
        return `/project/${project.id}/planejamento`;
    }
    return `/project/${project.id}/equipe`;
  }

  render() {
    const {
      classes,
      project,
      index,
      client,
      openDeleteAlert,
      userPermissions
    } = this.props;
    const { isModalOpen } = this.state;
    return (
      <>
        <ReviewModal
          open={isModalOpen}
          toggleModal={this.toggleModal}
          archiveProject={() =>
            archiveProject({
              projectId: project.id,
              archived: !project.archived,
              client
            })
          }
          project={project}
        />
        <Card
          product
          className={
            classes.cardHover +
            " " +
            classes.cardProjects +
            " " +
            (project.archived ? classes.cardArchived : "")
          }
        >
          <CardHeader image className={classes.cardHeaderHover}>
            <Link to={"/project/" + project.id + "/contents/0/0"}>
              <div
                className={classes.projectImg}
                style={{
                  background:
                    "url(" +
                    this.chooseImage(project, index) +
                    ") no-repeat center center"
                }}
              />
              {project.archived && (
                <div className={classes.archivedProject}>
                  <div className={classes.archivedProjectVertical}>
                    <CheckCircle
                      classes={{ root: classes.archivedProjectIcon }}
                    />
                  </div>
                </div>
              )}
              {/* <img src={ this.chooseImage(project, index) } alt="..." /> */}
            </Link>
          </CardHeader>
          <CardBody classes={{ cardBody: classes.cardBody }}>
            {(!userPermissions || userPermissions.includes("edit-project")) && (
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id="tooltip-top"
                  title="Editar"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    disabled={project.archived}
                    color="danger"
                    simple
                    justIcon
                  >
                    <Link to={"/admin/edit-project/" + project.id}>
                      <Edit className={classes.underChartIconsProjects} />
                    </Link>
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title={project.archived ? "Desarquivar" : "Arquivar"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  {client && (
                    <Button
                      color="success"
                      onClick={() => this.onArchive()}
                      simple
                      justIcon
                    >
                      <CheckCircle
                        className={classes.underChartIconsProjects}
                      />
                    </Button>
                  )}
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Deletar"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    disabled={project.archived}
                    color="transparent"
                    simple
                    justIcon
                  >
                    <Delete
                      onClick={() =>
                        openDeleteAlert({
                          sweetAlertOpen: true,
                          projectId: project.id
                        })
                      }
                      className={classes.underChartIconsProjects}
                    />
                  </Button>
                </Tooltip>
              </div>
            )}
            <h4
              className={
                classes.cardProductTitle + " " + classes.productTitleTwoLines
              }
              style={{
                minHeight:"2.8em",
                display:"flex"
              }}
            >
              <div style={{ alignSelf: "center"}}>
                <LinesEllipsis
                  id="nameWrapper"
                  text={project.name}
                  maxLine="2"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </div>
            </h4>
          </CardBody>
          <CardFooter product>
            <div className={classes.cardProjectFooter}>
              <div className={classes.underChartWrapper}>
                <div id="date" className={classes.underChart}>
                  <DateRange />{" "}
                  {project.startDate || project.endDate ? (
                    <span>
                      {" "}
                      {project.startDate} a {project.endDate}
                    </span>
                  ) : (
                    <span> Sem data </span>
                  )}
                </div>
                <div className={classes.underChart}>
                  <Ballot /> Gênero: <span id="genre"> {project.genre} </span>
                </div>
                {project.collaborativeOrgs &&
                  project.collaborativeOrgs.length > 0 && (
                    <div className={classes.underChart}>
                      <Stars /> <p> Colaboração: </p>
                      <span>
                        <LinesEllipsis
                          text={project.collaborativeOrgs
                            .map(collaboration =>
                              collaboration.organization
                                ? collaboration.organization.name
                                : "deleted"
                            )
                            .join(", ")}
                          maxLine="2"
                          ellipsis="..."
                          trimRight
                          basedOn="letters"
                        />
                      </span>
                    </div>
                  )}
              </div>
              {project.owner && (
                <div className={classes.cardProjectBottomFooter}>
                  Criado por <span> {project.owner.name} </span>
                </div>
              )}
            </div>
          </CardFooter>
        </Card>
      </>
    );
  }
}

export default withApollo(withStyles(ProjectCardStyle)(ProjectCard));
