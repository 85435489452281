import React from "react";
import PropTypes from "prop-types";
import { Query, withApollo } from "react-apollo";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import List from "@material-ui/core/List";
import Search from "@material-ui/icons/Search";
import AddCircle from "@material-ui/icons/AddCircle";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Button from "components/CustomButtons/Button.jsx";
import ListItem from "@material-ui/core/ListItem";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import projectsStyle from "../../assets/jss/material-dashboard-pro-react/views/projectsStyle";
import {
  deleteProject,
  GET_PROJECTS_QUERY,
  MY_USER_QUERY
} from "./ProjectsQueries.js";
import { Link } from "react-router-dom";
import ProjectCard from "components/ProjectCard/ProjectCard";
import Loading from "components/Loading/Loading";
import { ContextConsumer, GlobalContext } from "ContextController.js";

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      searchText: "",
      sweetAlertOpen: false,
      projectId: ""
    };
    this.openDeleteProjectMessage = this.openDeleteProjectMessage.bind(this);
  }
  static contextType = GlobalContext;
  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  openDeleteProjectMessage({ sweetAlertOpen, projectId }) {
    this.setState({ sweetAlertOpen, projectId });
  }

  sortProjects = (projectA, projectB) => {
    if (projectA.archived === projectB.archived) {
      if (projectA.name > projectB.name) {
        return 1;
      } else if (projectA.name < projectB.name) {
        return -1;
      }
    }
    if (projectA.archived) {
      return 1;
    }
    if (projectB.archived) {
      return -1;
    }
    return 0;
  };
  
  render() {
    const { classes, client } = this.props;
    const { searchText, sweetAlertOpen, projectId } = this.state;
    const searchButton = classes.top + " " + classes.searchButton;
    return (
      <div id="projects">
        <Query query={MY_USER_QUERY}>
          {({ loading, error, data: myUserData }) => {
            if (loading) return <Loading />;
            if (error) {
              console.log(`Error! ${error.message}`);
              return null;
            }

            let userPermissions = myUserData.myUser.role.permissions;

            return (
              <>
                {sweetAlertOpen && (
                  <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-250px" }}
                    title="Você tem certeza que deseja deletar o projeto?"
                    onConfirm={() => {
                      deleteProject({ projectId, client });
                      this.setState({ sweetAlertOpen: false });
                    }}
                    onCancel={() => this.setState({ sweetAlertOpen: false })}
                    confirmBtnCssClass={
                      classes.button +
                      " " +
                      classes.danger +
                      " " +
                      classes.whiteBtn
                    }
                    cancelBtnCssClass={
                      classes.button +
                      " " +
                      classes.success +
                      " " +
                      classes.purpleBtn
                    }
                    confirmBtnText="Sim"
                    cancelBtnText="Não"
                    showCancel
                  >
                    O projeto não poderá ser recuperado depois.
                  </SweetAlert>
                )}
                <Query query={GET_PROJECTS_QUERY}>
                  {({ loading, error, data }) => {
                    if (loading) return <Loading />;
                    if (error) {
                      console.log(`Error! ${error.message}`);
                      return null;
                    }
                    return (
                      <GridContainer>
                        <ContextConsumer>
                          {context => {
                            if (!this.state.loggedUser)
                              this.setState({ loggedUser: context.getUser() });
                          }}
                        </ContextConsumer>
                        <List className={classes.list}>
                          {(!userPermissions ||
                            userPermissions.includes("edit-project")) && (
                            <ListItem className={classes.listItem}>
                              <Link to="/admin/add-project">
                                <Button className={classes.newProject}>
                                  <span className={classes.newProjectPlus}>
                                    {" "}
                                    <AddCircle />{" "}
                                  </span>
                                  NOVO PROJETO
                                </Button>
                              </Link>
                            </ListItem>
                          )}
                          <ListItem className={classes.listItem}>
                            <CustomInput
                              classes={{ underline: classes.underline }}
                              formControlProps={{
                                className: classes.top + " " + classes.search
                              }}
                              inputProps={{
                                onChange: e =>
                                  this.setState({ searchText: e.target.value }),
                                placeholder: "Buscar Projeto",
                                inputProps: {
                                  "aria-label": "Buscar Projeto",
                                  className: classes.searchInput
                                }
                              }}
                            />
                            <Button
                              color="white"
                              aria-label="edit"
                              justIcon
                              round
                              className={searchButton}
                            >
                              <Search
                                className={
                                  classes.headerLinksSvg +
                                  " " +
                                  classes.searchIcon
                                }
                              />
                            </Button>
                          </ListItem>
                        </List>
                        {data.projects &&
                          data.projects
                            .filter(project =>
                              project.name
                                .toUpperCase()
                                .includes(searchText.toUpperCase())
                            )
                            .sort((a, b) => this.sortProjects(a, b))
                            .map((project, index) => {
                              return (
                                <GridItem
                                  xs={12}
                                  sm={12}
                                  md={4}
                                  lg={3}
                                  key={index}
                                >
                                  <ProjectCard
                                    project={project}
                                    openDeleteAlert={
                                      this.openDeleteProjectMessage
                                    }
                                    userPermissions={
                                      myUserData.myUser.role.permissions
                                    }
                                  />
                                </GridItem>
                              );
                            })}
                      </GridContainer>
                    );
                  }}
                </Query>
              </>
            );
          }}
        </Query>
      </div>
    );
  }
}

Projects.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withApollo(
  withStyles({ ...dashboardStyle, ...sweetAlertStyle, ...projectsStyle })(
    Projects
  )
);
