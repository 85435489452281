import React from "react";
import Button from "components/CustomButtons/Button.jsx";
import propTypes from 'prop-types';
import { Tooltip, withStyles } from "@material-ui/core";
import Paperclip from '@material-ui/icons/AttachFile';
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import DocumentModal from "views/Content/DocumentModal.jsx"
import statusColor from "views/Content/utils/StatusColor.js";
import AttachmentsStyle from "./AttachmentsStyle" 

class Attachments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attachIsOpen:false,
      openAttachment: undefined,
    };
  }

  toggleModal(){
    this.setState({ attachIsOpen:!this.state.attachIsOpen });
  }
  fetchMoreAttachments({ attachments, maxAttachmentsToDisplay, conditional }){
    const { classes } = this.props;
    if(!conditional) return attachments.slice(maxAttachmentsToDisplay).map(attachment => {
      return (
                <Tooltip
                  id="tooltip-top"
                  key={attachment.id}
                  title={attachment.name}
                  placement="top"
                  classes={{ tooltip: classes.tooltip + " " + classes.customTooltip + ' ' + (this.state.attachIsOpen ? classes.tooltipHide : '') }}
                >
                  <span 
                    onClick={()=> this.setState({ openAttachment: attachment, attachIsOpen:true })}
                    style={{display: "flex"}}>
                    <div>
                      <CircleIcon className={classes.attachmentButtonCircleIcon + ' ' + statusColor(attachment.status)}/>
                    </div>
                    <span className={classes.moreAttachmentButtonText}> {attachment.name} </span>
                  </span>
                </Tooltip>
                
              )
    });
  }


  render(){
    const { classes, versionId } = this.props;
    const { openAttachment, attachIsOpen } = this.state;
    const maxAttachmentsToDisplay = this.props.maxAttachments;
    let attachments = this.props.attachments;
    return(
      <div id='root' className={classes.attachmentWrapper}>
        <div className={classes.attachmentButtonWrapper}>
          {
            attachIsOpen && 
            openAttachment && 
            <div id='DocumentModal'>
              <DocumentModal 
                versionId = {versionId}
                projectId = {this.props.projectId}
                document = {openAttachment}
                modalOpen = {attachIsOpen}
                toggleModal = {this.toggleModal.bind(this)}
              />
            </div> 
          }
          {this.props.attachments &&
          
            this.props.attachments.slice(0, maxAttachmentsToDisplay).map((attachment, index) => {
              return (
                <Tooltip
                  id="tooltip-top"
                  key={index}
                  title={attachment.name}
                  placement="top"
                  classes={{ tooltip: classes.tooltip + " " + classes.customTooltip + ' ' + (attachIsOpen ? classes.tooltipHide : '') }}
                >
                  <Button
                    id={'buttonOpenAttachment'}
                    onClick={()=>{
                      this.setState({ openAttachment: attachment })
                      if(!attachIsOpen)
                      this.setState({ attachIsOpen: true })
                      
                    }}
                    aria-label={attachment.name}
                    aria-haspopup="true"
                    classes={{ button: classes.attachmentButton }}
                  >
                    <span style={{display: "flex"}}>
                      <Paperclip className={classes.paperclip}/>
                      <span className={classes.attachmentButtonText}> {attachment.name} </span>
                      <div>
                        <CircleIcon className={classes.attachmentButtonCircleIcon + ' ' + statusColor(attachment.status)}/>
                      </div>
                    </span>
                  </Button>
                </Tooltip>
              )
            })}
          </div>
          
          { 
            attachments && attachments.length > maxAttachmentsToDisplay && 
            <div className={classes.attachmentDropdownWrapper}>
              <CustomDropdown
                buttonText="Mais"
                classes={{button: classes.attachmentDropDown, buttonIcon: classes.attachmentDropdownIcon, pooperResponsive: classes.dropDownSort, butonText: classes.dropdownMore}}
                dropdownList={this.fetchMoreAttachments({ attachments, maxAttachmentsToDisplay })}//todo: change this to rreturn a tag with link to content of atachment == 
              />
            </div> 
          }
      </div>
    );
  }
}

Attachments.propTypes = {
  attachments: propTypes.arrayOf(Boolean).isRequired,
}

let attachmentsWithStyles = withStyles(AttachmentsStyle)(Attachments);

export default attachmentsWithStyles;
