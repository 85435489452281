import React, { Component } from 'react';
import { Query, withApollo } from 'react-apollo';
import Board from 'react-trello';
import Loading from 'components/Loading/Loading';
import ModalCard from 'components/ModalCard/ModalCard.jsx';
import Card from "./components/Card";
import NewCardForm from "./components/NewCardForm";
import tranlationPt from "./translationpt.json";
import _get from  "lodash/get";

const createTranslate = TABLE => key => (0, _get)(TABLE, key);

const translate = (0, createTranslate)(tranlationPt);
 
var eventBus = undefined;
class Trello extends Component {
  constructor (props) {
    super(props);
    this.state = {
      openCardModal: false,
      cardMetadata: undefined,
      boards: null,
      loading: true,
      isDragging: false,
    };
    this.isDragging = false;
    this.debounce = null;
    this.unsubscribe = null;

  }
   setEventBus = (handle) => {
    eventBus = handle;
    this.setState({teste: ''})
  }

  componentWillUnmount ( ) {
    if (this.debounce) clearTimeout(this.debounce);
    this.debounce = null;
  }
  getTasks = (cards, board) => {
    return cards.map(task => ({
      id: task.id,
      title: task.title,
      description: task.description,
      laneId: board ? board.id : '',
      metadata: {...task, board: board},
      status: task.status,
      onChangeStatus: (id, status) => this.onCardEdit(id, undefined, undefined, status)
    }))
  }

  getBoards = (boards) => {
    if (!boards) return [];
    return boards.map((board) => {
      return ({
      title: board.title,
      laneId: board.id, 
      id: board.id, 
      laneDraggable: true,
      cards: this.getTasks(board.tasks, board),
      order: board.orderNumber,
    })
    }).sort(this.laneSort);
  }

  onLaneAdd = (title) => {
    const { client, projectId, addBoard } = this.props;
    addBoard({params: {projectId, title: title.title}, client});
  }

  onLaneDelete = (laneId) => {
    const { client, deleteBoard, projectId } = this.props;
    deleteBoard({params: {boardId: laneId, projectId }, client});
  }

  onCardAdd = (card, boarId) => {
    const { client, projectId, addCard } = this.props;
    addCard({params: {boardId: boarId, projectId, title: card.title, description: card.description}, client});
  }

  onCardDelete = (cardId, laneId) => {
    const { client, deleteCard, projectId } = this.props;
    deleteCard({params: {cardId, projectId}, client});
  }
  
  onLaneUpdate = (laneId, data) => {
    const { client, editBoard,projectId} = this.props;
    editBoard({params: {boardId: laneId, projectId, title: data.title}, client});
  }

  onCardClick	= (cardId, metadata, laneId) => {
    if(metadata) this.setState({openCardModal: cardId, cardMetadata: metadata})
  }

  onCardEdit = async (cardId, title, description, status, startDate, endDate) => {
    const { client, projectId, editCard } = this.props;
    await editCard({params: {id: cardId, title, description, status, startDate, endDate, projectId}, client});
    this.setState({openCardModal: false});
  }

  moveCard = (fromLaneId, toLaneId, cardId, index) => {
    const { client, moveCard, projectId } = this.props;
    moveCard({params: {boardId: toLaneId, projectId: projectId, taskId: cardId, order: index}, client});
  }

  onDragEnd = (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
      this.moveCard(sourceLaneId, targetLaneId, cardId, position);
      this.isDragging = false;
      if (this.debounce) clearTimeout(this.debounce);
    }

  sort (card1, card2) {
    const card1Order = parseFloat(card1.metadata.orderNumber);
    const card2Order = parseFloat(card2.metadata.orderNumber);

    if (card1Order > card2Order) return 1;
    else if (card2Order > card1Order) return -1;
    return 0;
  }

  laneSort (lane1, lan2) {
    const lane1Order = parseFloat(lane1.order);
    const lan2Order = parseFloat(lan2.order);
    if (lane1Order > lan2Order) return 1;
    else if (lan2Order > lane1Order) return -1;
    return 0;
  }

  onDragStart = () => {
    this.isDragging = true;
    if (this.debounce) clearTimeout(this.debounce);
  }

  handleLaneDragStart = () => {
    if (this.debounce) clearTimeout(this.debounce);
  }

  handleLaneDragEnd = (removedIndex, addedIndex, payload) => {
    const { client, moveBoard, projectId } = this.props;

    if (this.debounce) clearTimeout(this.debounce);
    moveBoard({params: {boardId: payload.laneId, projectId, order: addedIndex}, client});
  }

  render() {
    const { projectId, BOARD_SUBSCRIPTION, GET_BOARDS } = this.props;
    const { openCardModal, cardMetadata, boards, loading } = this.state;
    return (
      <div>
        {!!openCardModal && 
          <ModalCard 
          open={!!openCardModal} 
          card={cardMetadata}
          GET_BOARDS={GET_BOARDS}
          projectId={projectId}
          onSave={({id, title, description, status, startDate, endDate}) =>  this.onCardEdit(id, title, description, status, startDate, endDate)}
          onClose={() => this.setState({openCardModal: false})}
          />
        }
        <Query query={GET_BOARDS} variables={{projectId}} fetchPolicy='network-only'>
          {({data, loading: dataLoading, error, subscribeToMore}) => {
            if (!dataLoading && data && data.orgTrello) {
              data.boards = data.orgTrello.boards;
            }
            if (!dataLoading && !boards && data) {
              this.setState({boards: this.getBoards(data.boards)})
            }
            if (error) return <h2> Error ... </h2>
            if (loading && !dataLoading) this.setState({loading: false})
            if (!this.unsubscribe) {
              this.unsubscribe = subscribeToMore({
                document: BOARD_SUBSCRIPTION,
                variables: {projectId},
                // Na maioria dos casos não é usado, ainda precisamos estudar mais.
                // É recomendado manter mesmo sendo aparentemente desnecessário 
                updateQuery: (prev, {subscriptionData}) => {
                  if (!subscriptionData.data) return prev;
                  const { wallChange, orgTrelloChange } = subscriptionData.data;
                  return { ...prev, boards: wallChange, orgTrello: orgTrelloChange }; //if you add a new trello you have to put the new query name here and assume the other values are null
                }
              })
            }
            
            if(eventBus && !this.isDragging && data) {
              if (this.debounce) {
                this.debounce = setTimeout(() => {
                  eventBus.publish({type: 'UPDATE_LANES', lanes: this.getBoards(data.boards)});
                }, this.debounce ? 500 : 0); //0 so the first time goes instantly 
                clearTimeout(this.debounce);}
              else {
                eventBus.publish({type: 'UPDATE_LANES', lanes: this.getBoards(data.boards)});
              }
            }
            if (dataLoading || loading) return <Loading/>
            return null;
          }}
        </Query>
        
        {!loading && <Board 
          data={{lanes: []}} 
          style={
            {
            backgroundColor: '#eeeeee', 
            overflowX: 'auto',
            maxWidth: '100%',
            maxHeight: '100vh',
          }} 
          t={translate}
          laneStyle={{width: '300px'}}
          editable 
          canAddLanes 
          editLaneTitle 
          cardDraggable
          laneDraggable
          draggable
          components={{Card, NewCardForm}}
          handleLaneDragEnd={this.handleLaneDragEnd}
          handleLaneDragStart={this.handleLaneDragStart}
          handleDragEnd={this.onDragEnd}
          handleDragStart={this.onDragStart}
          onCardClick	={this.onCardClick}
          onLaneUpdate={this.onLaneUpdate}
          onLaneAdd={this.onLaneAdd}
          onLaneDelete={this.onLaneDelete}
          onCardAdd={this.onCardAdd}
          onCardDelete={this.onCardDelete}
          eventBusHandle={this.setEventBus}
        />    }
      </div>
    );
  }
}

export default withApollo(Trello);
