import { gql } from "apollo-boost";
import { GET_DOCUMENTS_FROM_PROJECT } from "components/ListAllDocuments/ListAllDocumentsQueries";

const EDIT_DOCUMENT_STATUS = gql`
  mutation EDIT_DOCUMENT_STATUS($id: ID!, $status: String!) {
    editDocumentStatus(id: $id, status: $status) {
      id
      status
      name
    }
  }
`;

const DELETE_DOCUMENT_MUTATION = gql`
  mutation DELETE_DOCUMENT_MUTATION($id: ID!) {
    deleteDocument(id: $id) {
      id
    }
  }
`;

const deleteDocumentMutation = ({ params, client }) => {
  client.mutate({
    mutation: DELETE_DOCUMENT_MUTATION,
    variables: { id: params.id },
    refetchQueries: () => [
      {
        query: GET_DOCUMENTS_FROM_PROJECT,
        variables: { projectId: params.projectId }
      }
    ]
  });
};

const editDocumentStatus = ({ params, client }) => {
  client.mutate({
    mutation: EDIT_DOCUMENT_STATUS,
    variables: { id: params.id, status: params.status },
    refetchQueries: () => [
      {
        query: GET_DOCUMENTS_FROM_PROJECT,
        variables: { projectId: params.projectId }
      }
    ]
  });
};

export { editDocumentStatus, deleteDocumentMutation };
