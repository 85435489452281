import { gql } from "apollo-boost";

const REGISTER_ORGOWNER_MUTATION = gql`
  mutation REGISTER_ORGOWNER_MUTATION(
    $name: String!
    $password: String!
    $passwordSalt: String!
    $email: String!
    $imageId: ID
    $phoneNumber: String
    $orgName: String!
    $orgDescription: String
    $orgImageId: ID
    $cellphoneNumber: String
  ) {
    registerOrganizationOwner(
      name: $name
      password: $password
      passwordSalt: $passwordSalt
      email: $email
      imageId: $imageId
      phoneNumber: $phoneNumber
      cellphoneNumber: $cellphoneNumber
      orgName: $orgName
      orgDescription: $orgDescription
      orgImageId: $orgImageId
    ) {
      id
      name
      cellphoneNumber
      organization {
        id
        name
      }
      role {
        id
        name
      }
    }
  }
`;

const SEND_NEW_ORG_EMAIL_MUTATION = gql`
  mutation SEND_NEW_ORG_EMAIL_MUTATION(
    $destination: String!
    $rolesIds: [ID]
    $projectsIds: [ID]
  ) {
    sendNewOrganizationEmail(
      destination: $destination
      rolesIds: $rolesIds
      projectsIds: $projectsIds
    )
  }
`;

export { REGISTER_ORGOWNER_MUTATION, SEND_NEW_ORG_EMAIL_MUTATION };
