const style = {
  root: {
    width: "570px",
    margin: "0 auto"
  },
  imgUpload: {
    border: "3px solid #9E94BC",
    boxShadow: "none !important"
  },
  imgUploadWrapper: {
    display: "flex",
    justifyContent: "center",
    paddingRight: "0",
    "& div": {
      marginLeft: "15px"
    }
  },
  wizardLeft: {
    display: "none"
  },
  wizardRight: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "30px",
    width: "100%"
  },
  wizardContent: {
    minHeight: "250px"
  },
  logo: {
    width: "250px",
    height: "73px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "row",
    marginTop: "100px",
    marginBottom: "50px",
    "& svg": {
      width: "73px",
      height: "73px"
    },
    "& span": {
      fontSize: "43px",
      marginTop: "20px",
      color: "#6D6E6D",
      marginLeft: "10px"
    }
  },
  inputLabel: {
    color: "#141414 !important",
    fontWeight: "normal",
    fontSize: "14px"
  }
};

export default style;
