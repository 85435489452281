import gql from "graphql-tag";

const TASKS_FRAGMENT = gql`
  fragment tasksFrag on Task {
    id
    title
    status
    description
    startDate
    endDate
    members {
      id
      name
      image {
        id
        imageUrl
      }
    }
    creator {
      id
      name
      image {
        id
        imageUrl
      }
    }
    board {
      id
      title
    }
  }
`;

const GET_TASKS_BY_PROJECT = gql`
  ${TASKS_FRAGMENT}
  query GET_TASKS_BY_PROJECT($projectId: ID!) {
    tasksByProject(projectId: $projectId) {
      ...tasksFrag
    }
  }
`;

const BOARDS_FRAGMENT = gql`
  ${TASKS_FRAGMENT}
  fragment boardsFrag on Board {
    id
    title
    tasks {
      ...tasksFrag
    }
  }
`;

const GET_BOARDS_BY_PROJECT = gql`
  ${BOARDS_FRAGMENT}
  query GET_BOARDS_BY_PROJECT($projectId: ID!) {
    boards(projectId: $projectId) {
      ...boardsFrag
    }
  }
`;

const GET_PROJECT_QUERY = gql`
  query GET_PROJECT_QUERY($projectId: ID!) {
    project(id: $projectId) {
      id
      name
      genre
      image {
        id
        imageUrl
      }
      schedule {
        id
        name
        elements {
          id
          name
          startDate
          endDate
          completed
        }
        phases {
          id
          name
          completed
          startDate
          endDate
          elements {
            id
            name
            startDate
            endDate
            completed
            content {
              id
              status
            }
          }
        }
      }
      startDate
      endDate
      description
    }
  }
`;

const GET_PROJECT_WITH_DELIVERY_SCHEDULE_QUERY = gql`
  query GET_PROJECT_WITH_DELIVERY_SCHEDULE_QUERY($projectId: ID!) {
    project(id: $projectId) {
      id
      name
      genre
      image {
        id
        imageUrl
      }
      deliverySchedule {
        id
        name
        elements {
          id
          name
          startDate
          endDate
          completed
          juridical
        }
        phases {
          id
          type
          name
          completed
          startDate
          endDate
          elements {
            id
            name
            startDate
            endDate
            completed
            juridical
            content {
              id
              status
              juridicalStatus
            }
          }
        }
      }
      startDate
      endDate
      description
    }
  }
`;

const ADD_BOARD_MUTATION = gql`
  ${BOARDS_FRAGMENT}
  mutation ADD_BOARD_MUTATION($projectId: ID!, $title: String!) {
    addBoard(projectId: $projectId, title: $title) {
      ...boardsFrag
    }
  }
`;

const ADD_CARD_MUTATION = gql`
  ${TASKS_FRAGMENT}
  mutation ADD_CARD_MUTATION(
    $boardId: ID!
    $projectId: ID!
    $title: String!
    $description: String
    $startDate: String
    $endDate: String
  ) {
    addTask(
      boardId: $boardId
      projectId: $projectId
      title: $title
      description: $description
      startDate: $startDate
      endDate: $endDate
    ) {
      ...tasksFrag
    }
  }
`;

const EDIT_CARD_MUTATION = gql`
  mutation EDIT_CARD_MUTATION(
    $id: ID!
    $projectId: ID!
    $boardId: ID
    $title: String
    $status: String
    $startDate: String
    $endDate: String
    $description: String
    $order: Int
  ) {
    editTask(
      id: $id
      boardId: $boardId
      projectId: $projectId
      title: $title
      status: $status
      description: $description
      startDate: $startDate
      endDate: $endDate
      order: $order
    ) {
      id
    }
  }
`;
const DELETE_BOARD_MUTATION = gql`
  ${BOARDS_FRAGMENT}
  mutation DELETE_BOARD_MUTATION($id: ID!, $projectId: ID!) {
    deleteBoard(id: $id, projectId: $projectId) {
      ...boardsFrag
    }
  }
`;

const DELETE_TASK_MUTATION = gql`
  ${TASKS_FRAGMENT}
  mutation DELETE_BOARD_MUTATION($id: ID!, $projectId: ID!) {
    deleteTask(id: $id, projectId: $projectId) {
      ...tasksFrag
    }
  }
`;

const WALL_CHANGE_SUBSCRIPTION = gql`
  ${BOARDS_FRAGMENT}
  subscription WALL_CHANGE_SUBSCRIPTION($projectId: ID!) {
    wallChange(projectId: $projectId) {
      ...boardsFrag
    }
  }
`;

const EDIT_BOARD_MUTATION = gql`
  ${BOARDS_FRAGMENT}
  mutation EDIT_BOARD_MUTATION(
    $id: ID!
    $title: String
    $order: Int
    $projectId: ID!
  ) {
    editBoard(id: $id, title: $title, order: $order, projectId: $projectId) {
      ...boardsFrag
    }
  }
`;

const moveCard = ({ params, client }) => {
  client
    .mutate({
      mutation: EDIT_CARD_MUTATION,
      variables: {
        id: params.taskId,
        projectId: params.projectId,
        boardId: params.boardId,
        order: params.order
      }
    })
    .catch(() => {
      console.error("Error");
    });
};

const getBoards = async ({ params, client, onSuccess }) => {
  const result = client
    .query({
      query: GET_BOARDS_BY_PROJECT,
      variables: { projectId: params.projectId }
    })
    .then(res => {
      onSuccess(res.data.boards);
    });
  await result;
  return result;
};

const addBoard = ({ params, client }) => {
  client.mutate({
    mutation: ADD_BOARD_MUTATION,
    variables: { projectId: params.projectId, title: params.title }
  });
};

const addCard = ({ params, client, onSucess }) => {
  client
    .mutate({
      mutation: ADD_CARD_MUTATION,
      variables: {
        boardId: params.boardId,
        projectId: params.projectId,
        title: params.title,
        description: params.description,
        startDate: params.startDate,
        endDate: params.endDate
      },
      refetchQueries: () => [
        {
          query: GET_TASKS_BY_PROJECT,
          variables: { projectId: params.projectId }
        }
      ]
    })
    .then(() => {
      if (onSucess) onSucess();
    });
};

const editCard = ({ params, client, onSucess }) => {
  client
    .mutate({
      mutation: EDIT_CARD_MUTATION,
      variables: {
        id: params.id,
        projectId: params.projectId,
        title: params.title,
        description: params.description,
        status: params.status,
        startDate: params.startDate,
        endDate: params.endDate
      },
      refetchQueries: () => [
        {
          query: GET_TASKS_BY_PROJECT,
          variables: { projectId: params.projectId }
        }
      ]
    })
    .then(() => {
      if (onSucess) onSucess();
    });
};

const deleteBoard = ({ params, client }) => {
  client.mutate({
    mutation: DELETE_BOARD_MUTATION,
    variables: { id: params.boardId, projectId: params.projectId }
  });
};

const editBoard = ({ params, client }) => {
  client.mutate({
    mutation: EDIT_BOARD_MUTATION,
    variables: {
      id: params.boardId,
      title: params.title,
      projectId: params.projectId
    }
  });
};

const moveBoard = ({ params, client }) => {
  client.mutate({
    mutation: EDIT_BOARD_MUTATION,
    variables: {
      id: params.boardId,
      projectId: params.projectId,
      order: params.order
    }
  });
};

const deleteCard = ({ params, client }) => {
  client.mutate({
    mutation: DELETE_TASK_MUTATION,
    variables: { id: params.cardId, projectId: params.projectId }
  });
};

export {
  addCard,
  editCard,
  moveCard,
  addBoard,
  getBoards,
  moveBoard,
  editBoard,
  deleteCard,
  deleteBoard,
  TASKS_FRAGMENT,
  GET_PROJECT_QUERY,
  GET_TASKS_BY_PROJECT,
  GET_BOARDS_BY_PROJECT,
  WALL_CHANGE_SUBSCRIPTION,
  GET_PROJECT_WITH_DELIVERY_SCHEDULE_QUERY
};
