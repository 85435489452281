import React, { Component, createRef } from 'react';
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
//import 'src/assets/jss/material-dashboard-pro-react/customSelectStyle.jsx'
import Modal from 'components/ModalWrapper/ModalWrapper';


import { gql } from 'apollo-boost';
// @material-ui/icons
import CloudDownload from '@material-ui/icons/CloudDownload';
// core components
import Button from "components/CustomButtons/Button.jsx";
//import  { vimeoToken } from "queries/queries";
import submitFile from "utils/uploadFile.js";
import UploadAttachmentStyle from "./UploadAttachmentStyle.js";
import { Card, LinearProgress, TextField } from '@material-ui/core';
import { withApollo, Query } from 'react-apollo';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Dropzone from 'components/Dropzone/Dropzone.jsx';
import Paperclip from '@material-ui/icons/AttachFile';
import Loading from '../Loading/Loading.jsx';
import VerificationDialog from "components/VerificationDialog/VerificationDialog";
import SearchIcon from '@material-ui/icons/Search';

import {
  GET_CONTENT_QUERY,
} from 'views/Vimeo/ContentViewQueries';

import {
  GET_ALL_COMMENTS_BY_VERSIONS,
} from "views/Comments/CommentQueries.js";

const ADD_DOCUMENTS_MUTATION = gql`
  mutation ADD_DOCUMENTS_MUTATION($commentId:ID!, $fileKeys: [ String]!, $fileNames: [String]!, $fileTypes: [String]!){
    addDocuments(commentId:$commentId, fileKeys: $fileKeys, fileNames: $fileNames, fileTypes:$fileTypes){
      id
      name
      fileKey
    }
  }
`;

const GET_DOCUMENTS_QUERY = gql`
  query GET_DOCUMENTS_QUERY($projectId:ID!){
    project(id:$projectId){
      id
      name
      documents{
        id
        name
      }
    }
  }
`;

const EDIT_COMMENT = gql`
  mutation EDIT_COMMENT($id:ID!, $attachmentsIds: [ID]){
    editComment(id:$id, attachmentsIds:$attachmentsIds){
      id
      attachments {
        id
      }
    }
  }
`;

class UploadAttachments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      uploadForm: {},
      modalOpen: false,
      progressPercentage: [],
      sweetAlert: false,
      done: false,
      event: null,
      fileStack: [],
      nextFile: null,
      completedFiles: [],
      isDragging: false,
      loading: false,
      uploads: {
        fileKeys: [],
        fileNames: [],
        fileTypes: [],
        commentId: "",
      },
      documentSelectionOn: false,
      attachedDocumentIds: [],
      documentSearchTerm: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.queueUpload = this.queueUpload.bind(this);
    this.onProgress = this.onProgress.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.emptyState = this.emptyState.bind(this);
    this.fileSelector = createRef();
    this.dragOver = null;
  }

  emptyState() {
    this.setState({
      modalOpen: false,
      completedFiles: [],
      fileStack: [],
      documentIds: [],
      attachedDocumentIds:[],
      uploads: {
        fileKeys: [],
        fileNames: [],
        fileTypes: [],
        commentId: "",
      }
    });
  }

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  async attachmentUpload(nextFile, fileType) {
    try {
      const fileUrl = nextFile ?  await submitFile(nextFile) : "";
      this.setState({
        uploads: {
          fileKeys: [...this.state.uploads.fileKeys, fileUrl],
          fileNames: [...this.state.uploads.fileNames, nextFile.name],
          commentId: this.props.commentId,
          fileTypes: [...this.state.uploads.fileTypes, fileType]
        }
      });
      this.onSuccess();
    } catch (error) {
      console.log(error);
      this.setState({ error: error.message });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { done } = prevState;
    if (done) {
      this.props.toggleModal();
      this.setState({ done: false });
    }
  }

  async queueUpload() {
    const { versionId, contentId } = this.props;
    const { loading } = true;
    let fileStack = [...this.state.fileStack];
    if (!loading) this.setState({loading: true});
    if (fileStack.length < 1 && this.props.commentId !== "") {      
      this.setState({ done: true });
      if(this.state.uploads.commentId !== "") 
        await this.props.client.mutate({
          mutation: ADD_DOCUMENTS_MUTATION,
          variables: this.state.uploads,
          refetchQueries: () => [{
            query: GET_CONTENT_QUERY,
            variables: { contentId: contentId }
          }, {
            query: GET_ALL_COMMENTS_BY_VERSIONS,
            variables: { versionId }
          }]
        });
        
      await this.submitSelectedDocumentIds();
      this.emptyState();
      const that = this;
      setTimeout(() => {
        this.setState({loading: false})
        that.props.toggleModal();
      }, 500)
      return;
    }

    else if ( fileStack.length < 1 ) {
      if( this.props.setFilesData )
        await this.props.setFilesData(this.state.uploads);
      await this.submitSelectedDocumentIds();
      this.emptyState();
      const that = this;
      setTimeout(() => {
        this.setState({loading: false})
        that.props.toggleModal();
      }, 500)
      return;
    }

    const nextFile = fileStack ? fileStack.pop() : null ;
    this.setState({ fileStack, nextFile, completedFiles: [...this.state.completedFiles, nextFile.name] });

    if (nextFile && (nextFile.type.split('/')[0] === 'text' || nextFile.type.split('/')[0] === 'application')) {
      await this.attachmentUpload(nextFile, "onlyoffice");
    } else if (nextFile && (nextFile.type.split('/')[0] === 'image')) {
      await this.attachmentUpload(nextFile, "image");
    } else {
      if(nextFile){
        console.log("File unsupported: ", nextFile.type);
      } else {
        console.log("nextFile is null");
      }
      window.alert("Tipo de arquivo não permitido");
    }
  }

  onProgress(bytesUploaded, bytesTotal) {
    let progress = (bytesUploaded / bytesTotal * 100).toFixed(2);
    let progressPercentage = { ...this.state.progressPercentage, [this.state.nextFile ? this.state.nextFile.name : ""]: progress }
    this.setState({ progressPercentage });
  }

  onSuccess(e) {
    let temp = [...this.state.progressPercentage];//gets list of progress % being tracked then removes it
    delete temp[this.state.nextFile ? this.state.nextFile.name : ""];
    temp.push(true);
    this.setState({ progressPercentage: temp });
    this.queueUpload();
  }

  handleChange(e) {
    let newFiles = Array.from(e.target.files);
    newFiles = newFiles.filter((file) => !this.state.fileStack.some(fileOnStack => fileOnStack.name === file.name));
    this.setState({
      fileStack: [...this.state.fileStack, ...newFiles]
    });
    this.onDragLeave();
    return false;
  }

  handleDocumentSelect(documentId) {
    if (!this.state.attachedDocumentIds.includes(documentId))
      this.setState({ attachedDocumentIds: [...this.state.attachedDocumentIds, documentId] });
    else
      this.setState({
        attachedDocumentIds: this.state.attachedDocumentIds.filter((docId) => docId !== documentId)
      });
  }

  documentSort(a, b){ 
    if (!a && b) return -1;
    if (a && !b) return 1;
    let nameA = a.name.toLowerCase();
    let nameB = b.name.toLowerCase();
    if( nameA > nameB ) return 1;
    else return -1
  }

  documentsPreRenderSetup(docs){
    let documents = docs;
    documents = documents.filter(d => {
      return d.name.toLowerCase().includes(this.state.documentSearchTerm.toLowerCase())
    })
    documents.sort(this.documentSort);
    return documents;
  }

  selectDocuments({ classes, projectId, versionId }) {
    return (
      <Query query={GET_DOCUMENTS_QUERY} variables={{ projectId }}>
        {({ error, loading, data, refetch }) => {//fetching the project from the props project id and displying it
          if (error)
            return <div>error fetching documents: {error} </div>
          if (loading) return <Loading />

          let documents = this.documentsPreRenderSetup(data.project.documents);
          return (
            <div className={classes.modalBody} style={{ height: "250px !important", overflow: "auto", paddingTop: 0 }}>
              
              <Card classes={{ root: classes.listUploadsWrapper }}>
              <form  className={classes.searchInput} noValidate autoComplete="off">
                <TextField 
                  InputProps={{
                    startAdornment: (
                        <SearchIcon 
                          style={{marginLeft:"-8px", marginRight:"5px", fontSize:"small"}} />
                    ),
                  }} 
                  id="searchField" 
                  variant="outlined" 
                  onChange={(e)=> this.setState({ documentSearchTerm: e.target.value })}/>
              </form>
                <div className={classes.listUploads}>
                  <>
                    {documents.map(document => {
                      return (
                        <>
                          <div key={document.id} className={classes.uploadedFile} onClick={() => this.handleDocumentSelect(document.id)}>
                            <span> {document.name} </span>
                            {
                              this.state.attachedDocumentIds && this.state.attachedDocumentIds.includes(document.id) &&
                              <CheckCircleOutline />
                            }
                          </div>
                          <div className={classes.divisor} />
                        </>
                      );
                    })}
                  </>
                </div>
              </Card>
            </div>
          );
        }}
      </Query>
    );
  }

  onDragOver = (e) => {
    const { isDragging } = this.state;
    e.preventDefault();
    clearTimeout(this.dragLeave);
    this.isDragging = setTimeout(() => {
      if (!isDragging) this.setState({ isDragging: true });
    }, 100);
  }

  onDragLeave = () => {
    const { isDragging } = this.state;

    clearTimeout(this.isDragging);
    this.dragLeave = setTimeout(() => {
      if (isDragging) this.setState({ isDragging: false });
    }, 500);
  }

  placeholder() {
    const { classes } = this.props;
    const { isDragging } = this.state;
    if (!isDragging) {
      return (
        <div
          onDragOver={() => this.onDragOver()}
          className={classes.placeholder}
        />
      )
    }
  }

  async submitSelectedDocumentIds() {
    if( this.state.attachedDocumentIds.length > 0 && this.props.commentId === "" ) {
      await this.props.setAttachedDocumentIds({ AttachedDocumentIds: this.state.attachedDocumentIds });
      this.emptyState();
    }
    else if ( this.state.attachedDocumentIds.length > 0 && this.props.commentId !== "" ) {
      this.props.client.mutate({
        mutation: EDIT_COMMENT,
        variables: {
          id: this.props.commentId,
          attachmentsIds: this.state.attachedDocumentIds
        },
        refetchQueries: () => [{
          query: GET_ALL_COMMENTS_BY_VERSIONS,
          variables: { versionId: this.props.versionId }
        }]
      }).then(
        this.emptyState()
      );
    }
  }

  onClose = () => {
    this.props.toggleModal();
    this.emptyState();
    this.setState({sweetAlert: false});
  }
  
render() {

  const { classes } = this.props;
  const { isDragging, sweetAlert } = this.state;
  return (
    <div onDrop={e => e.preventDefault()} onDragOver={(e) => e.preventDefault()}>
      {sweetAlert && <VerificationDialog
          open={sweetAlert}
          title='Você tem certeza que deseja fechar este modal?'
          handleClose={() => this.setState({sweetAlert: false})}
          onAccept={() => { 
            this.onClose();
            this.setState({sweetAlert: false});
          }}
        />}
      <div>
        {
          this.props.open &&
          <Modal
            onBackdropClick={(e) => { 
              if((this.state.fileStack.length > 0 ||
                this.state.attachedDocumentIds.length > 0) &&
               !this.state.sweetAlert) 
              {
                this.setState({ sweetAlert: true });
              }
              else this.onClose();
              e.stopPropagation(); 
          }}
            open={this.props.open}
          >
            <div className={classes.root}>
              <div onClick={() => 
                {
                  if((this.state.fileStack.length > 0 ||
                     this.state.attachedDocumentIds.length > 0) &&
                    !this.state.sweetAlert) 
                  {
                    this.setState({ sweetAlert: true });
                  }
                  else this.onClose();
                }} 
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => e.preventDefault()} 
                className={classes.mask} 
              />
              <div onDragOver={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()} className={classes.headerWrapper}>
                <div onDragOver={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()} className={classes.header}>
                  <div className={classes.tabWrapper}>
                    <Button
                      onClick={() => this.setState({ documentSelectionOn: false })}
                      color={!this.state.documentSelectionOn ? "circlePurple" : "circleWhite"}
                    >
                      <CloudDownload classes={{ root: classes.btnIcon }} />
                      Escolher do PC
                    </Button>
                  </div>
                </div>
                <div className={classes.header}>
                  <div className={classes.tabWrapper}>
                    <Button
                      onClick={() => this.setState({ documentSelectionOn: true }) }
                      color={this.state.documentSelectionOn ? "circlePurple" : "circleWhite"}
                    >
                      <Paperclip classes={{ root: classes.btnIcon }} />
                      Escolher do Sistema
                    </Button>
                  </div>
                </div>
              </div>
              {
                this.props.projectId && this.state.documentSelectionOn &&
                this.selectDocuments({ classes, projectId: this.props.projectId })
              }
              {
                !this.state.documentSelectionOn &&
                <div onDrop={e => e.preventDefault()} onDragOver={(e) => e.preventDefault()} className={classes.modalBody}>
                  <Card onDrop={e => e.preventDefault()} onDragOver={(e) => this.onDragOver(e)} classes={{ root: classes.listUploads + ' ' + classes.uploadedFiles }}>
                    {
                      this.state.completedFiles.map(fileName => {
                        return (
                          <div onDragOver={(e) => e.preventDefault()} onDrop={e => e.preventDefault()} key={fileName} style={{ display: 'block' }}  className={classes.uploadedFile}>
                            <div> {fileName} <CheckCircleOutline /></div>
                            <LinearProgress classes={{ root: classes.linearProgress }} value={this.state.progressPercentage[fileName]} variant='determinate' />
                            <div className={classes.divisor} />
                          </div>
                        )
                      })
                    }
                    {((this.state.fileStack.length > 0 && !isDragging) || this.state.fileStack.length === 0) ?
                      this.state.fileStack.map((file, index) => {
                        return (
                          <div onDragOver={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()} key={file + index} style={{ display: 'block' }} className={classes.uploadedFile}>
                            <div> {file.name} </div>
                            <LinearProgress classes={{ root: classes.linearProgress }} value={this.state.progressPercentage[file.name] ? this.state.progressPercentage[file.name] : 0} variant='determinate' />
                            <div className={classes.divisor} />
                          </div>
                        )
                      }) : this.placeholder()
                    }
                  </Card>
                  {((isDragging || this.state.fileStack.length === 0) && !this.state.loading) && <Dropzone onDragOver={this.onDragOver} onDragLeave={this.onDragLeave} handleChange={this.handleChange} />}
                </div>
              }
              {
                !this.state.documentSelectionOn ?
                  <div className={classes.footer}>
                    <Button
                      color="purple"
                      onClick={(e) => {
                        e.preventDefault();
                        this.queueUpload();
                      }}
                      style={{ zIndex:"1000" }}
                      classes={{ purple: classes.btnUpload }}
                      loading={this.state.loading}
                      disabled={this.state.loading}
                    >
                      Upload
                            </Button>
                  </div>
                  :
                  <div className={classes.footer}>
                    <Button
                      color="purple"
                      onClick={(e) => {
                        e.preventDefault();
                        this.queueUpload();
                      }}
                      style={{ zIndex:"1000" }}
                      classes={{ purple: classes.btnUpload }}
                    >
                      Anexar
                              </Button>
                  </div>
              }
            </div>
          </Modal>
        }
      </div>
    </div>
  );
}
}
const UploadAttachmentsWithStyles = withStyles({ ...modalStyle, ...UploadAttachmentStyle })(UploadAttachments)
export default withApollo(UploadAttachmentsWithStyles);
