const style = {
    notificationBody: {
        display: 'flex',
        width: '100%',
        paddingTop: 10,
        '& b': {
            color: '#3f2c78',
        },
        '&:hover': {
             backgrounColor: '#e3e3e3',
             color: '#333333',
            '& b': {
                color: '#7464a4',
            },
            '& $projectName': {
                color: '#b3b2b3',
            },
        },
        borderBottom: '1px solid #3f2c785e',
        borderRadius: '6px',
    },
    primaryHover: {
        '&:hover': {
            backgroundColor: 'white !important'
        }
    },
    notificationImage: {
        marginLeft: 10,
        marginTop:10
    },
    notificationFooter: {
        display: 'flex',
        color: '#aca8b9',
        marginTop: 5,
        paddingBottom: 5,
        marginLeft: '-10px',
    },
    notificationWrapper: {
        marginLeft: '5px',
        marginTop: '0px',
        width:"100%",
        borderRadius:"6px"
    },
    notificationsWrapper: {
        overflowY:"auto",
        maxHeight:"50vh",
    },
    notificationText: {
        whiteSpace: "normal"
    },
    notificationDescription: {
        fontSize: '14px'
    },
    notificationIcon: {
        marginTop: '1px !important',
    },
    accessTime: {
        height: '12px',
        marginTop: 4,
    },
    seenIcon: { 
        position:"absolute",
        zIndex:900,
        fontSize:"large", 
        marginTop: 5, 
        right:10
    },
    unseenNotification: {
        backgroundColor: "rgba(230, 222, 251, 0.8)"
    },
    

}

export default style;