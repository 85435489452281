import React, { Component } from 'react';
import { ListItem, withStyles } from '@material-ui/core';
import List from "@material-ui/core/List";
import JuridicalHubStyle from "./JuridicalHubStyle.js";
import Juridical from "./Juridical";
import Documents from "./Documents";
import Tabs from 'components/Tabs/Tabs.jsx';

class JuridicalHub extends Component {
  
  constructor (props) {
    super(props);
    this.state = {
      tabs: ['AUTORIZAÇÕES', 'DOCUMENTOS'],
      icons: ['library_add_check', 'copyright', 'today'],
      currentTab: 'AUTORIZAÇÕES',
    }
  }
 
  render() {
    const { classes, match, client } = this.props;
    const { tabs, icons, currentTab } = this.state;
    
    return (
      <div className={classes.root}>
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <span className={classes.planningTitle}> Jurídico </span>
            </ListItem>
            <ListItem className={classes.listItem + ' ' + classes.tabsWrapper}>
            <Tabs
                tabs={tabs}
                icons={icons}
                currentTab={currentTab}
                setCurrentTab={(tab) => this.setState({ currentTab: tab })}
              />
            </ListItem>
          </List>
          <div className={classes.body}>
            {currentTab === tabs[0] && <Juridical match={match} client={client}/>}
            {currentTab === tabs[1] && <Documents match={match} client={client}/>}
          </div>
      </div>
    );
  }
}

export default withStyles(JuridicalHubStyle)(JuridicalHub);
