export default {
  date: 'Data',
  time: 'Hora',
  event: 'Evento',
  allDay: 'Dia inteiro',
  week: 'Semana',
  work_week: 'Dia Útil',
  day: 'dia',
  month: 'mes',
  previous: 'anterior',
  next: 'próximo',
  yesterday: 'ontem',
  tomorrow: 'amanhã',
  today: 'hoje',
  agenda: 'agenda',
  showMore: (n) => `Mostrar mais ${n}`,
  wed: 'seg'
}
