import React from "react";
import { Dialog, DialogTitle, DialogContent, withStyles, Slide, DialogActions } from "@material-ui/core";
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import verificationDialogStyle from "./VerificationDialogStyle";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";

const VerificationDialog = ({classes, open, handleClose, hideButtonAccept, title = 'Você tem certeza de que deseja fazer isso?', btnAccepText = 'Sim', btnDeclineText =  'Não', onAccept}) => {
  const Transition = (props) => {
    return <Slide direction="down" {...props} />;
  }
  
  return (
    <Dialog
    classes={{
      root: classes.center + " " + classes.modalRoot,
      paper: classes.modal + " " + classes.modalSmall
    }}
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => handleClose()}
    aria-labelledby="small-modal-slide-title"
    aria-describedby="small-modal-slide-description"
  >
    <DialogTitle
      id="small-modal-slide-title"
      disableTypography
      classes={{root: classes.modalHeader}}
    >
      <Button
        justIcon
        className={classes.modalCloseButton}
        key="close"
        aria-label="Close"
        color="transparent"
        onClick={() => handleClose()}
      >
        <Close className={classes.modalClose} />
      </Button>
    </DialogTitle>
    <DialogContent
      id="small-modal-slide-description"
      className={
        classes.modalBody + " " + classes.modalSmallBody
      }
    >
      <h5>{title}</h5>
    </DialogContent>
    <DialogActions
      className={
        classes.modalFooter +
        " " +
        classes.modalFooterCenter
      }
    >
      <Button
        onClick={() => handleClose()}
        color="transparent"
        className={classes.modalSmallFooterFirstButton}
      >
        {btnDeclineText}
      </Button>
      {!hideButtonAccept && <Button
        onClick={() => onAccept()}
        color="transparent"
        simple
        className={
          classes.modalSmallFooterFirstButton +
          " " +
          classes.modalSmallFooterSecondButton
        }
      >
        {btnAccepText}
      </Button>}
    </DialogActions>
  </Dialog>
  )
}

export default withStyles({...verificationDialogStyle, ...notificationsStyle})(VerificationDialog);