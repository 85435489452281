import React from 'react';
import PropTypes from 'prop-types';
import { Cropper } from 'react-image-cropper';
import Modal from 'react-modal';
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";


class AvatarCropper extends React.Component {
  id  = 'AvatarCropper';
  
  static propTypes = {
    image: PropTypes.object.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    modalOpen: PropTypes.bool,
  }
  
  static defaultProps = {
    modalOpen: true,
    ratio: 240 / 210,
  }
  
  state = {
    imgHeight: undefined,
    imgWidth: undefined,
  }
  
  b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      
      let byteArray = new Uint8Array(byteNumbers);
      
      byteArrays.push(byteArray);
    }
    
    let blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
  componentDidMount () {
    this.checkImageHeight();
  }
  
  handleSave = (blob, url) => {
    const { image } = this.props;
    if (image) {
      this.props.handleSave({}, url, blob, true);
    }
  }
  
  handleClick (e, save = false) {
    //const compressor = require('image-compressor');
   // const { image } = this.props;
    //const { imgWidth } = this.state;
    //const _compressor = new ImageCompressor;
    // const compressorSettings = {
    //   mimeType: image.type,
    //   toWidth: Math.min(700, imgWidth ? imgWidth : 999999),
    //   mode: 'strict',
    //   quality: 0.2,
    //   speed: 'fast',
    // };
    
    if (save) {
      const base64Data = this.cropper.crop();
      const blob = this.b64toBlob(base64Data.split(',')[1], base64Data.split('data:')[1].split(';')[0]); //first argument = blob second = image type
      this.handleSave(blob, base64Data, false);

    } 
    return false;
  }

  checkImageHeight () {
    const { image } = this.props;
    const _img = document.createElement('img');
    _img.onload = () => this.setState({imgHeight: _img.height, imgWidth: _img.width});
    _img.src = image;
  }

  render = () => {
    const { image, modalOpen, handleCancel, ratio, classes } = this.props;
    const { imgHeight, imgWidth } = this.state;
    const maxModalWidth = Math.min(750, (imgWidth ? imgWidth : 999999999999)); //99999999999 so always be biggest number if imgWidth is undefined
    const currentModalWidth = Math.min(maxModalWidth, window.innerWidth * 0.8); //80% width modal
    const currentModalHeight = window.innerHeight * 0.8; // 80% height modal
    const resizedHeight = currentModalWidth / imgWidth * imgHeight; //height after resize
    const _recalculatedWidth = (window.innerHeight * 0.8) / imgHeight * imgWidth; //if resizedImgHeight is bigger than the current screen height this is the new width

    const customStyles = {
      overlay: {
        backgroundColor: "rgba(0,0,0, 0.8)",
        zIndex: 100000,
      },
      content: {
        maxWidth: (resizedHeight > (window.innerHeight * 0.8)) ? _recalculatedWidth : maxModalWidth + 'px',
        width: window.winnerWidth > 400 ? '80%' : '95%',
        height: 'auto',
        top: '50%',
        left: '50%',
        border: '0px',
        right: 'auto',
        backgroundColor: 'transparent',
        bottom: 'auto',
        marginRight: '-50%',
        padding: '0px 0px',
        transform: 'translate(-50%, -50%)',
        overflow: 'visible',
      },
    };
    if (!(resizedHeight > (window.innerHeight * 0.8)) &&  (resizedHeight <= currentModalHeight)) {
      customStyles.content.height = 'auto';
    } else {
      customStyles.content.height = '95%';
    }

    return (
      <div className={classes.root}>
        <Modal
          style={customStyles}
          isOpen={modalOpen}
          onRequestClose={ () => handleCancel() }
        >
          {imgWidth &&  <Cropper
              ref = { c => { this.cropper = c; } }
              src={image}
              ratio={ratio}
              fixedRatio
              minHeight={30}
              maxHeight={30}
              styles ={{
                move: {
                  minHeight: '50px',
                  minWidth: '50px'
                },
                frame: {
                  minHeight: '70px',
                  minWidth: '70px'
                },
              }}
              crop={{
                width:'50px',
                height:'50px'
              }}
              allowNewSelection={false}
              />}
          <div className={classes.buttonSave}>
            <div style={{display: 'flex', justifyContent: 'space-between', padding: '5px 0'}}>
              <Button
                  type='button'
                  className={classes.dangerButton}
                  onClick={ () => handleCancel() }
                >
                Cancelar
              </Button>
              <Button
                type='button'
                className={classes.successButton}
                onClick={ e => this.handleClick(e, true) }
              >
                Salvar
              </Button>
              </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withStyles(dashboardStyle)(AvatarCropper);
