import React, { Component, createRef } from 'react';
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
//import 'src/assets/jss/material-dashboard-pro-react/customSelectStyle.jsx'
import Modal from 'components/ModalWrapper/ModalWrapper';

// core components
import Button from "components/CustomButtons/Button.jsx";
//import  { vimeoToken } from "queries/queries";
import submitFile from "utils/uploadFile.js";
import UploadDocumentsStyle from "./UploadDocumentsStyle.js";
import { Card, LinearProgress } from '@material-ui/core';
import { withApollo } from 'react-apollo';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Dropzone from 'components/Dropzone/Dropzone.jsx';
import VerificationDialog from "components/VerificationDialog/VerificationDialog";
import TagPopper from "views/Juridical/TagPopper"
import { GET_DOCUMENTS_FROM_PROJECT, ADD_DOCUMENT_MUTATION } from './DocumentsQueries';
import FlatTagList from 'components/DocumentTags/FlatTagList';


const tagDisplayStyles = {
  flatTag: {
    cursor:"pointer",
    width: "fit-content", 
    padding:"3px 6px 3px 6px", 
    borderRadius:"4px", 
    minWidth:"45px", 
    marginLeft:"4px", 
    marginRight:"4px",
    marginTop:"5px",
    color:"white",
    fontFamily: "Montserrat,sans-serif",
    fontSize:"0.7em",
    display:"flex",
    justifyContent:"center"
  },
  flatTagsWrapper:{ 
    display: "flex", 
    flexWrap: "wrap",
    justifyContent:"flex-start", 
    padding:"2px 2px 2px 2px", 
    marginLeft:"15px"
  },
}

class UploadDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      uploadForm: {},
      modalOpen: false,
      progressPercentage: [],
      sweetAlert: false,
      done: false,
      event: null,
      fileStack: [],
      nextFile: null,
      completedFiles: [],
      isDragging: false,
      loading: false,
      uploads: {
        fileKeys: [],
        fileNames: [],
        fileTypes: [],
      },
      tagIds:[],
      addedTagObjects:[],
      attachedDocumentIds: [],

    };
    this.handleChange = this.handleChange.bind(this);
    this.queueUpload = this.queueUpload.bind(this);
    this.onProgress = this.onProgress.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.fileSelector = createRef();
    this.dragOver = null;
  }

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  async attachmentUpload(nextFile, fileType) {
    try {
      const fileUrl = nextFile ?  await submitFile(nextFile) : "";
      this.setState({
        uploads: {
          fileKeys: [...this.state.uploads.fileKeys, fileUrl],
          fileNames: [...this.state.uploads.fileNames, nextFile.name],
          fileTypes: [...this.state.uploads.fileTypes, fileType]
        }
      });
      this.onSuccess();
    } catch (error) {
      console.log(error);
      this.setState({ error: error.message });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { done } = prevState;
    if (done) {
      this.props.toggleModal();
      this.setState({ done: false });
    }
  }

  async queueUpload() {
    const { projectId } = this.props;
    const { tagIds } = this.state;
    const { loading } = true;
    let fileStack = [...this.state.fileStack];
    if (!loading) this.setState({loading: true})
    if (fileStack.length < 1 ) {
      this.setState({ done: true });
      await this.props.client.mutate({
        mutation: ADD_DOCUMENT_MUTATION,
        variables: { projectId, ...this.state.uploads, tagIds},
        refetchQueries: () => [{
          query: GET_DOCUMENTS_FROM_PROJECT,
          variables: { projectId }
        }]
      });
      this.setState({ fileStack: [], completedFiles: [] });
      return;
    }
    else if (fileStack.length < 1 && this.props.setFilesData) {
      await this.props.setFilesData(this.state.uploads);
      this.resetState();
      return;
    }

    const nextFile = fileStack ? fileStack.pop() : null ;
    this.setState({ fileStack, nextFile, completedFiles: [...this.state.completedFiles, nextFile.name] });

    if (nextFile && (nextFile.type.split('/')[0] === 'text' || nextFile.type.split('/')[0] === 'application')) {
      await this.attachmentUpload(nextFile, "onlyoffice");
    } else if (nextFile && (nextFile.type.split('/')[0] === 'image')) {
      await this.attachmentUpload(nextFile, "image");
    } else {
      if(nextFile){
        console.log("File unsupported: ", nextFile.type);
      } else {
        console.log("nextFile is null");
      }
      window.alert("Tipo de arquivo não permitido");
    }
  }

  onProgress(bytesUploaded, bytesTotal) {
    let progress = (bytesUploaded / bytesTotal * 100).toFixed(2);
    let progressPercentage = { ...this.state.progressPercentage, [this.state.nextFile ? this.state.nextFile.name : ""]: progress }
    this.setState({ progressPercentage });
  }

  onSuccess(e) {
    const { fileStack } = this.state;
    let temp = [...this.state.progressPercentage];//gets list of progress % being tracked then removes it
    delete temp[this.state.nextFile ? this.state.nextFile.name : ""];
    temp.push(true);
    this.setState({ progressPercentage: temp });
    this.queueUpload();
    if(fileStack && fileStack.length === 0) {
      const that = this;
      setTimeout(() => {
        this.setState({
          loading: false,
          uploads: {
            fileKeys: [],
            fileNames: [],
            fileTypes: [],
          },
          tagIds:[],
          addedTagObjects:[],
          attachedDocumentIds: [],
        })
        that.props.toggleModal();
      }, 1000)
    }    
  }

  handleChange(e) {
    let newFiles = Array.from(e.target.files);
    newFiles = newFiles.filter((file) => !this.state.fileStack.some(fileOnStack => fileOnStack.name === file.name));
    this.setState({
      fileStack: [...this.state.fileStack, ...newFiles]
    });
    this.onDragLeave();
    return false;
  }

  handleDocumentSelect(documentId) {
    if (!this.state.attachedDocumentIds.includes(documentId))
      this.setState({ attachedDocumentIds: [...this.state.attachedDocumentIds, documentId] });
    else
      this.setState({
        attachedDocumentIds: this.state.attachedDocumentIds.filter((docId) => docId !== documentId)
      });
  }

  onDragOver = (e) => {
    const { isDragging } = this.state;
    e.preventDefault();
    clearTimeout(this.dragLeave);
    this.isDragging = setTimeout(() => {
      if (!isDragging) this.setState({ isDragging: true });
    }, 100);
  }

  onDragLeave = () => {
    const { isDragging } = this.state;

    clearTimeout(this.isDragging);
    this.dragLeave = setTimeout(() => {
      if (isDragging) this.setState({ isDragging: false });
    }, 500);
  }

  placeholder() {
    const { classes } = this.props;
    const { isDragging } = this.state;
    if (!isDragging) {
      return (
        <div
          onDragOver={() => this.onDragOver()}
          className={classes.placeholder}
        />
      )
    }
  }


  resetState(){
    this.setState({
      completedFiles: [],
      sweetAlert: false,
      fileStack: [],
      documentIds: [],
      nextFile:null,
      uploads: {
        fileKeys: [],
        fileNames: [],
        fileTypes: [],
      },
      tagIds:[],
      addedTagObjects:[],
    })
  }

  onClose = () => {
    this.props.toggleModal();
    this.resetState()
  }
  
  addTagId = (tagIds) => this.setState({ tagIds:[...this.state.tagIds, tagIds] });
  addTagObject = (tag) => {
    if(!tag) return null;
    const { addedTagObjects } = this.state;
    if(!addedTagObjects.some(addedTag => tag.name === addedTag.name))
      this.setState({ addedTagObjects:[...addedTagObjects, tag]});
  }

  removeSelectedTag(tag){
    if(!tag || !tag.id) return null;
    this.setState({ 
      addedTagObjects: this.state.addedTagObjects.filter(addedTag => addedTag.id !== tag.id ), 
      tagIds: this.state.tagIds.filter(tagId => tagId !== tag.id) 
    })
  }

  closeModal(){
    if(!this.state.fileStack || this.state.fileStack.length === 0) 
      this.onClose()
    else 
      this.setState({ sweetAlert: true });
  }

  render() {

    const { classes } = this.props;
    const { isDragging, sweetAlert } = this.state;
    return (
      <div onDrop={e => e.preventDefault()} onDragOver={(e) => e.preventDefault()}>
        {sweetAlert && <VerificationDialog
            open={sweetAlert}
            title='Você tem certeza que deseja fechar este modal?'
            handleClose={() => this.setState({sweetAlert: false})}
            onAccept={() => this.onClose()}
          />}
        <div>
          {
            this.props.open &&
            <Modal
              onBackdropClick={() => this.closeModal()}
              open={this.props.open}
            >
              <div className={classes.root}>
                <div onDragOver={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()} className={classes.mask} />
                <div onDragOver={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()} className={classes.headerWrapper}>
                </div>

                {
                  <div onDrop={e => e.preventDefault()} onDragOver={(e) => e.preventDefault()} className={classes.modalBody}>
                    { this.state.addedTagObjects && this.state.addedTagObjects.length > 0 &&   
                        <FlatTagList 
                          classes={this.props.classes} 
                          tags={this.state.addedTagObjects} 
                          handleClick={(tag) => this.removeSelectedTag(tag)}/>
                    }
                    <Card onDrop={e => e.preventDefault()} onDragOver={(e) => this.onDragOver(e)} classes={{ root: classes.listUploads + ' ' + classes.uploadedFiles }}>
                      {
                        this.state.completedFiles.map(fileName => {
                          return (
                            <div onDragOver={(e) => e.preventDefault()} onDrop={e => e.preventDefault()} key={fileName} style={{ display: 'block' }}  className={classes.uploadedFile}>
                              <div> {fileName} <CheckCircleOutline /></div>
                              <LinearProgress classes={{ root: classes.linearProgress }} value={this.state.progressPercentage[fileName]} variant='determinate' />
                              <div className={classes.divisor} />
                            </div>
                          )
                        })
                      }
                      {((this.state.fileStack.length > 0 && !isDragging) || this.state.fileStack.length === 0) ?
                        this.state.fileStack.map((file, index) => {
                          return (
                            <div onDragOver={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()} key={file + index} style={{ display: 'block' }} className={classes.uploadedFile}>
                              <div> {file.name} </div>
                              <LinearProgress classes={{ root: classes.linearProgress }} value={this.state.progressPercentage[file.name] ? this.state.progressPercentage[file.name] : 0} variant='determinate' />
                              <div className={classes.divisor} />
                            </div>
                          )
                        }) : this.placeholder()
                      }
                    </Card>
                    {((isDragging || this.state.fileStack.length === 0) && !this.state.loading) && <Dropzone onDragOver={this.onDragOver} onDragLeave={this.onDragLeave} handleChange={this.handleChange} />}
                  </div>
                }
                  <div className={classes.footer}>
                    <TagPopper 
                      projectId={this.props.projectId}
                      customAddTagsToDocument={this.addTagId}
                      handleWithObject={this.addTagObject}
                      documentId={""}
                      disablePortal
                      enableClickAway
                    />
                    <Button
                        color="purple"
                        onClick={(e) => {
                          e.preventDefault();
                          if(this.state.fileStack.length === 0 ) {
                            window.alert("Nenhum arquivo selecionado");
                            return;
                          }
                          this.queueUpload();
                        }}
                        style={{ zIndex:"1000" }}
                        classes={{ purple: classes.btnUpload }}
                        loading={this.state.loading}
                        disabled={this.state.loading}
                    >
                      Upload
                    </Button>
                  </div>
              </div>
            </Modal>
          }
        </div>
      </div>
    );
  }
}
const UploadDocumentsWithStyles = withStyles({ ...modalStyle, ...UploadDocumentsStyle, ...tagDisplayStyles })(UploadDocuments)
export default withApollo(UploadDocumentsWithStyles);
