
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.jsx";
const AttachmentStyle = (theme) => ({
    ...tooltipsStyle,
    attachmentButtonText: {
        lineHeight: "15px",
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '42px',
        minWidth: "30px",
        whiteSpace: 'nowrap'
    },
    moreAttachmentButtonText: {
        marginTop:"3px",
        marginLeft:"7px",
        lineHeight: "15px",
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '80%',
        whiteSpace: 'nowrap'
    },
    attachmentButtonCircleIcon: {
        marginTop: "4px !important",
        fontSize: "13px !important"
    },
    attachmentWrapper: {
        marginLeft: "-55px",
        minWidth: "100%",
        display: "flex",
        marginBottom: "10px",
        justifyContent: "space-between",
        zIndex: '1',
        //backgroundColor: '#272933',
        '& @media (max-width: 1500px)': {
          maxWidth: 'Calc(100% + 63px) !important',
          marginLeft: "-47px",
        },
      },
      attachmentButtonWrapper: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "nowrap",
        width: "auto",
        marginLeft: "12px"
      },
      attachmentDropdownWrapper: {
        display: "flex",
        justifyContent: "flex-start",
      },
      attachmentDropdownIcon: {
        height: "12px !important",
        width: "14px !important",
        fontSize: '12px !important',
        marginTop: "1px !important",
        color: "#636882 !important",
        flexGrow: "1",
      },
      attachmentButton: {
        backgroundColor: "transparent !important",
        border: "1px solid #636882",
        color: "#636882",
        borderRadius: "6px",
        padding: '0px 0px !important',
        paddingRight: "2px !important",
        maxWidth: '85px',
        boxShadow: "none !important",
        fontSize: '10px !important',
        marginRight: "6px",
        marginLeft: "0px",
        marginBottom: "15px",
        height: "24px",
        "&:hover": {
          boxShadow: "none",
          backgroundColor: "rgba(255,255,255, 0.1)"
        },
        "& svg": {
          fontSize: "11px !important",
          height: "10px",
          marginRight: "2px",
          marginTop: '0px !important',
          width: "10px",
          marginLeft: "2px"
        },
      },
      dropdownMore: {
        marginLeft: "0",
        lineHeight: "0.8125rem",
        fontWeight: "600 !important",
      },
      dropDownSort: {
        marginLeft: '-132px',
      },
      paperclip: {
        color: "#636882 !important",
        height: "0.8125rem !important",
        marginTop: "1px !important",
        width: "15px !important",
        fontSize: '9px !important'
      },
      attachmentDropDown: {
        backgroundColor: "transparent !important",
        border: "1px solid #636882",
        color: "#636882",
        borderRadius: "6px",
        padding: '4px 10px !important',
        minWidth: '45px',
        boxShadow: "none !important",
        fontSize: '9px !important',
        height: "24px",
        "&:hover": {
          boxShadow: "none",
          backgroundColor: "rgba(255,255,255, 0.1)"
        },
        "& svg": {
          fontSize: "11px !important",
          height: "7px",
          marginRight: "2px",
          marginTop: '-1px',
          width: "7px",
          marginLeft: "2px"
        },
      },
    customTooltip: {
        backgroundColor: 'black',
        zIndex:0,
        maxWidth:"200px",
        wordWrap:"break-word",
    },
});

export default AttachmentStyle;
