import React, { Component } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { AddCircle, RemoveCircle } from "@material-ui/icons";
import CollaborationInviteStyle from './CollaborationInviteStyle';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import emailVerification from 'utils/emailVerification.js';
import { withStyles, Card } from '@material-ui/core';

export class CollaborationInvite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: true,
      org: '',
      email: '',
      addedOrgs: []
    }
  }


  removeOrg = (index) => {
    const { addedOrgs } = this.state;
    const { setOrgs } = this.props;
    let _orgs = addedOrgs;
    _orgs.splice(index, 1);
    this.setState({addedOrgs: _orgs})
    setOrgs(_orgs);
  }

  insertNewOrg = () => {
    const { addedOrgs, email, org } = this.state;
    const { setOrgs } = this.props;
    if (!org || !email) return;
    if (!emailVerification(email)) return this.setState({inserted: true});
    let _addedOrgs = addedOrgs;

    _addedOrgs[addedOrgs.length] = {email, org}; 
    this.setState({addedOrgs: _addedOrgs, email: '', org: '', inserted: false})
    setOrgs(_addedOrgs);
  }

  renderProjects = (isNew, index) => {
    const { classes, setOrgs, hideAdd } = this.props;
    const {  email, org, addedOrgs, inserted } = this.state;
    return (
      <GridContainer direction='row' style={{justifyContent: 'space-around'}}>
        <GridItem xs={5}>
          <CustomInput
            error={emailVerification(isNew ? email : addedOrgs[index].email) === false && inserted}
            id="email"
            classes={{ labelRoot: classes.labelNormal, formControl: classes.noPaddingInput }}
            labelText={
              <span>
                e-mail
              </span>
            }
            formControlProps={{
              fullWidth: true
            }}
            labelProps={{
              classes: { shrink: classes.shrink }
            }}
            inputProps={{
              value: isNew ? email : addedOrgs[index].email,
              onChange: (e) => {
                let _addedOrgs = addedOrgs;
                if (isNew) {
                  this.setState({ email: e.target.value, isEmailValid: emailVerification(email)})
                  setOrgs([...addedOrgs, {email: e.target.value, org}]);
                } else {
                  _addedOrgs[index] = {email: e.target.value, org: addedOrgs[index].org};
                  this.setState({addedOrgs: _addedOrgs});
                  setOrgs(_addedOrgs);
                }}
              }
            }
        />
        </GridItem>
        <GridItem xs={5}>
          <CustomInput
            id="org"
            classes={{ labelRoot: classes.labelNormal, formControl: classes.noPaddingInput }}
            labelText={
              <span>
              Nome da Organização
              </span>
            }
            formControlProps={{
              fullWidth: true
            }}
            labelProps={{
              classes: { shrink: classes.shrink }
            }}
            inputProps={{
              value: isNew ? org : addedOrgs[index].org,
              onChange: (e) => {
                if (isNew) {
                  this.setState({ org: e.target.value });
                  setOrgs([...addedOrgs, { org: e.target.value, email }]);
                } else {
                  const _addedOrgs = addedOrgs;
                  _addedOrgs[index].org = e.target.value;
                  this.setState({addedOrgs: _addedOrgs});
                  setOrgs(_addedOrgs);
                }}
              }
            }
          />
        </GridItem>
        {isNew && !hideAdd && <GridItem xs={1}>
          <Button onClick={() => { }} classes={{ root: classes.inviteWrapper }} color='white' round justIcon>
            <span className={classes.invite}>
              <AddCircle
                onClick={() => this.insertNewOrg()}
              />
            </span>
          </Button>
        </GridItem>}
        {!isNew && <GridItem xs={1}>
          <Button onClick={() => { }} classes={{ root: classes.inviteWrapper }} color='white' round justIcon>
            <span className={classes.invite}>
              <RemoveCircle
                onClick={() => this.removeOrg(index)}
              />
            </span>
          </Button>
        </GridItem>}
      </GridContainer>
    )
  }

  render() {
    const { classes } = this.props;
    const { addedOrgs } = this.state;
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          {addedOrgs.map((org, index) => this.renderProjects(false, index))}
          {this.renderProjects(true)}
        </Card>
      </div>
    );
  }
}

export default withStyles(CollaborationInviteStyle)(CollaborationInvite);