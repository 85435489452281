export default {
  iconMenuList: {
    color: '#808080',
    fontSize: '16px',
    height: '18px',
    width: '18px',
    marginTop: '4px',
    marginLeft: '5px',
    marginRight: '20px',
    '& svg': {
      width: '15px',
      marginTop: '-1px',
      position: 'absolute',
    }
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
    "& hr": {
      padding: 0,
      margin: 0,
    },

  },
  menu: {
    padding: 0,
    border: 15,
  },
  paper: {
    borderRadius: 15,
  },
  menuItem: {
    "&:focus":{
      backgroundColor: 'transparent'
    }
  },
  options: {
    color: '#935bff',
    marginRight: '5px',
    marginLeft: 'auto'
  },
  menuListBack: {
      padding: '8px 17px'
  },
  back: {
    color: '#808080',
    display: 'flex',
    '& svg': {
      marginLeft: '-5px',
      color: '#935bff'
    },
    '& span': {
      marginLeft: '20px',
      marginTop: 1,
    },
  }
}