
const style = {
  root: {
    minHeight: '350px',
    maxHeight: "75vh",
    padding: '10px 0',
    width: '600px',
    backgroundColor: 'white',
    margin: '0 auto',
    marginTop: '100px',
    overflow: 'auto'
  },
  header: {
    padding: '20px',
    width: '100%',
    textAlign: 'center'
  },
  title: {
    color: '#3F2C78',
    fontWeight: '300',
    fontSize: '26px',
  },
  link: {
    color: '#3F2C78',
    cursor: 'pointer'
  },
  newModelInputWrapper: {
    display: 'flex',
    justifyContent: 'left',
    marginLeft: '50px',
    '& input': {
      '&::placeholder': {
        color: 'black'
      }
    }
  },
  info: {
    height: '40px',	
    width: '429px',	
    fontFamily: 'Roboto',
    fontSize: '14px',	
    fontWeight: '300',
    lineHeight: '20px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '5px',
    marginBottom: '20px',
    '& span': {
      color: '#3F2C78',
    }
  },
  scrollableModal: {
    overflowY: 'auto',
  },
  dropDownButtonText: {
    fontWeight: 500,
    fontSize: 14,
  },
  expandable: {
    backgroundColor: 'rgba(63,44,120,0.1)',
    color: '#3F2C78',
  },
  scrollableContent:{
    overflowY:"auto",
    maxHeight:"300px"
  },
  infoWrapper: {
    backgroundColor: '#f2f1f6',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '10px',
    marginBottom: '10px',
    '& h4': {
      fontWeight: 'normal',
      fontSize: '16px !important',
      '& strong': {
        color: '#3F2C78',
        fontWeight: 'normal'
      }
    }
  },
  dropDown: {
    backgroundColor: 'transparent',
    color: '#4d4d4d',
    boxShadow: 'none',
    padding: "10px 5px 5px 0px",
    borderBottom: '1px solid #4d4d4d',
    maxWidth: '400px',
    borderRadius: 0,
    width: '100%',
    textTransform: 'initial',
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: '#4d4d4d',
      boxShadow: 'none',
    },
  },
  blackLabel: {
    fontWeight: '300',
    fontFamily: "Roboto, sans-serif !important",
    fontSize: '14px',
    textTransform: 'initial',
    color: 'black !important',
  },
  permissionsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    marginTop: '30px',
    padding: '10px 20px',
  },
  permissions: {
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'flex-start',
   padding: '10px 30px',
   boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
   borderRadius: '6px',
   maxHeight: '39px',
   transition: '1s all',
   transitionTimingFunction: 'ease-out', 
  },
  permissionsOpen: {
    maxHeight: '600px',
    height: 'auto'
  },
  permissionText: {
    fontWeight: 'normal',
    color: '#6F6F6F',
    '& span': {
      fontWeight: 'bold'
    }
  },
  topLine: {
    height: '1px',	
    width: '90%',	
    backgroundColor: '#DDDDDD',
    margin: '12px 10px'
  },
  permissionsHeader: {
    width: '100%',
    margin: '10 auto',
    display: 'flex',
    marginBottom: '25px',
    justifyContent: 'space-between',
    '& div': {
      fontWeight: 'bold',
      fontSize: '14px'
    },
    '& svg': {
      cursor: 'pointer'
    }
  },
  newModelInput: {
    color: 'black !important',
    '&::placeholder': {
      color: 'black !important'
    }
  },
  searchInput: {
    width: '100px',
    paddingTop: '10px',
    '&::-webkit-input-placeholder': {
      color: 'black'
    }
  },
  noPaddingInput: {
    marginTop: '-19px',
  },
  inputModelName: {
    width: '490px',
    marginBottom: 25,
    paddingTop: '5px'
  },
  inputLabelNewModel: {
    color: 'black !important',
    fontWeight: 'normal',
    fontSize: '14px',
    zIndex: '50',
    marginTop: -10,
    pointerEvents: 'none'
  },
  body: {
    display: 'flex',
    flexDirection: 'columns',
    justifyContent: 'center',
  },
  instructions: {
    textAlign: 'center'
  },
  tabs: {
    width: '470px',
    display: 'flex',
    justifyContent: 'center',
    margin: '20px auto',
    marginTop: '20px',
  },
  labelNormal: {
    fontWeight: 'normal'
  },
  TeamRoleTableWrapper: {
    width: '540px',
    margin:'10px auto',
    marginTop: '20px',
    '& h3': {
      textAlign: 'center'
    }
  },
  buttonTextWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%',
  },
  dropdownWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  footerButtonWrapper: {
    width: '540px',
    display: 'flex',
    margin: '10px auto',
    justifyContent: 'space-between',
  },
  roleWrapper: {
    display: 'flex',
    '& span': {
      marginTop: '10px',
      marginLeft: '10px',
      fontWeight: 'normal',
      fontSize: '14px'
    },
  },
  tableDataWrapper: {
    fontWeight: 'normal',
    fontSize: '14px',
    marginTop: '1px'
  },
  tableDataWrapperOrganization: {
    fontWeight: 'normal',
    fontSize: '14px',
    marginTop: '1px',
    marginLeft: '20px'
  },
  checkRoot: {
    padding: 0,
    marginRight: '15px'
  }
}

export default style;