import {
  cardTitle,
  grayColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const style = {
  cardTitle,
  container: {
    display: "flex",
    justifyContent: "center",
    "& label": {
      color: "black",
      float: "left",
      fontSize: "15px",
      position: "absolute"
    }
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400"
    }
  },
  infoWrapper: {
    "& div": {
      paddingRight: "0"
    },
    "& div:first-child": {
      paddingRight: "10px"
    }
  },
  cardCategory: {
    marginTop: "10px",
    color: grayColor[0] + " !important",
    textAlign: "center"
  },
  description: {
    color: grayColor[0]
  },
  updateProfileButton: {
    float: "right"
  },
  projects: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  noWrap: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: "auto",
    marginRight: "auto"
  },
  info: {
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 0,
    marginBottom: 30,
    "& div:first-child": {
      marginTop: "0"
    }
  },
  icon: {
    backgroundColor: "transparent",
    color: "#3f2c78",
    border: 0,
    boxShadow: "none",
    position: "absolute",
    right: 0,
    top: "-10px",
    height: "40px",
    width: "40px",
    float: "right",
    marginTop: "15px",
    marginRight: "15px",
    "& svg": {
      height: "32px",
      width: "32px"
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: "black",
      border: 0,
      boxShadow: "none"
    },
    "&:active": {
      "&:hover": {
        backgroundColor: "transparent",
        color: "black",
        border: 0,
        boxShadow: "none"
      },
      backgroundColor: "transparent",
      color: "black",
      border: 0,
      boxShadow: "none"
    }
  },
  buttonWrapperUpload: {
    width: "150px",
    marginLeft: "auto !important",
    marginRight: "auto",
    "& .fa-times": {
      marginTop: 10
    }
  },
  label: {
    color: "#777777 !important"
  },
  imageUploadButton: {
    top: "130px",
    position: "absolute",
    marginLeft: "-91px"
  },
  imgUpload: {
    marginBottom: "50px",
    marginLeft: "-20px",
    minWidth: 120,
    minHeight: 120
  },
  fileInput: {
    position: "absolute",
    zIndex: 999,
    width: "130px",
    top: "-50px",
    marginLeft: "Calc(50% - 65px)"
  }
};

export default style;
